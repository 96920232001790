// NavButton.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { accentColor } from '../../constants';

const NavButtonLink = styled(Link)`
  display: flex;
  text-decoration: none; 
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
  background: ${accentColor};
  width: 15vw;
  height: 4.5vw;
  color: #ffffff;   
  margin: 1.0vw 0 0.2vw 0 !important; /* top right bottom left */
  /* padding: 0.5vw 0 1.9vw 1.1vw !important; top right bottom left */
  padding: 0 0 0 2vw !important; /* top right bottom left  */
  border: none;
  border-radius: 0.75vw; /* was 10px */
`;

const NavButtonIcon = styled.img`
  display: inline-block;
  width: 1.8vw;
  height: 1.8vw;
  margin: 0 0 0 0 !important; // updated for 1.6vw svg images
  padding: 0 !important;
`;

const NavButtonTitleDiv = styled.div`
  display: inline-block;
  height: 1.8vw;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 1vw !important;
  font-size: 1.3vw;
`;

function NavButton(props) {

  let bgColor = "#ffffff";
  let titleColor = "#888888";
  let icon = props.icon;
  let iconOpacity = 0.4;
  let location = useLocation();
  let page = location.pathname;
  let active = false;

  // make sure history page keeps Members button lit up...
  if (page === props.linkTo) {
    active = true;
  } else if (page === '/history' && props.linkTo === '/members') {
    active = true;
  } else if (page === '/assessment' && props.linkTo === '/members') {
    active = true;
  }

  if (active) {
    bgColor = accentColor;
    titleColor = "#ffffff";
    icon = props.icon.split('.')[0] + "_active.svg"; // TODO: update to use whatever extension the original icon image had
    iconOpacity = 1.0;
  }

  return (
    <NavButtonLink to={props.linkTo} style={{ background: bgColor }} className="d-flex align-items-center justify-content-start">
      <NavButtonIcon src={icon} style={{ opacity: iconOpacity }}/>
      <NavButtonTitleDiv style={{ color: titleColor, paddingTop: "30px" }}>
        {props.title}
      </NavButtonTitleDiv>
      {/* <SiteButton icon={icon} title={props.title} /> */}
    </NavButtonLink>
  );
}

export default NavButton;