// ViewEditItem.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
// import { MDBContainer } from "mdb-react-ui-kit";
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';

import { createGroup, updateGroup, createMovement, updateMovement, createExercise, updateExercise, createProgram, updateProgram } from '../../services';
import { colors, iconSrc } from '../../constants';
import { capitalize } from '../../helpers';

import {
  AreaTitle,
  ItemFields,
  PrettyInput,
  PrettyInputLabel,
  Thumbnail,
  MouseOverVideoDiv,
  PrettyTextArea,
  EZSpacer 
} from './title';
import SiteButton from './SiteButton';
import ViewEditAssociatedItems from './ViewEditAssociatedItems';
import PickList from './PickList';
import OrderedItemList from './OrderedItemList';


const ViewEditItem = (props) => { // if this is multi-use & works for group, movement, exercise, program..., then:
  const visible = props.visible;
  const create = props.create;
  let edit = props.edit; // why let vs const here?
  
  const itemId = props.itemId;
  const modelInfo = props.modelInfo;
  const itemType = modelInfo ? modelInfo.type : props.itemType; // TODO: should not need ternary or props.itemType!
  const fields = modelInfo.displayFields;
  const item = props.item ? props.item : defaultItem(fields);
  console.log('~~~~~ ViewEditItem.js, ViewEditItem() - just after fields = modelInfo.displayFields, fields is *' + fields + '*');
  
  const typeTitle = itemType.charAt(0).toUpperCase() + itemType.slice(1).substring(0, itemType.length - 2); // e.g. 'Group', 'Movement', etc
  const onCreate = props.onCreate;
  const onUpdate = props.onUpdate;
  // const mounted = useRef(true);

  const videoRef = useRef(null);

  const handleMouseEnter = useCallback(() => { // bug where play/pause called to close together causes runtime error
    // The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22
    // videoRef.current.play();
    setTimeout(function () {      
      videoRef.current.play();
   }, 250);
  }, []);

  const handleMouseLeave = useCallback(() => {
    // videoRef.current.pause();
    setTimeout(function () {      
      videoRef.current.pause();
   }, 250);
  }, []);

  const [formItem, setFormItem] = useState(props.item);
  const [assocItemType, setAssocItemType] = useState(); // used to setup ViewEditAssociatedItems - these itemTypes will be listed there
  const [newItem, setNewItem] = useState({});
  const [editAssoc, setEditAssoc] = useState(false);
  const handleCloseEditAssoc = () => setEditAssoc(false);

  useEffect(() => {
    // mounted.current = true; // TODO: how is this useful?
    console.log('~~~~~ ViewEditItem.js, useEffect()');
    
    if (!item && !item.id_guid) {
      return;
    }

    // if(mounted.current) {
      setFormItem(item);
    // }

  }, [item])

  if (!item) { // should never happen, because ternary sets default as item  (line 36)
    console.log('~~~~~ ViewEditItem.js - there is no valid item, so we will set formItem to a default empty item');

    const mtItem = defaultItem(fields);
    // console.log('~~~~~ ViewEditItem.js, if (!item) conditional - item.accessor is *' + item.accessor + '*');
    // console.log('~~~~~ ViewEditItem.js, if (!item) conditional - mtItem.accessor is *' + mtItem.accessor + '*');

    setFormItem(mtItem);

    setTimeout(() => {
      console.log("~~~~~ formItem.name_string is *" + formItem.name_string + "*");
      console.log("~~~~~ formItem.name_string is *" + formItem.note_string + "*");
      console.log("~~~~~ formItem.id_guid is *" + formItem.id_guid + "*");
    }, 500);
  }

  function defaultItem(fields) {
    // console.log('~~~~~ ViewEditItem.js, defaultItem()');

    let emptyItem = {};
 
    fields.map((field) => {
      // console.log('~~~~~ ViewEditItem.js, defaultItem() item[' + field.accessor + '] will be *' + field.default + '*');
      emptyItem[field.accessor] = field.default;
      // console.log('~~~~~ ViewEditItem.js, defaultItem() emptyItem[' + field.accessor + '] is now *' + emptyItem[field.accessor] + '*');
    });

    return emptyItem;
  }

  function checkItemChanged() { // compare current values for all item attributes in State, to values in item found in items (in State)
    // for Groups we would check itemName and itemNotes vsv
    // we would also want to check the associated_id_guids for as many associations as we allow editing of in this ViewEdit
    // we should probably save those guids in one or more collections in State somewhere...
    // then we could check the collections versus the collections on the appropriate item in the items array 
    // and if there are differences in the item attributes or associated items collection -> setEditingItem(true), 
    
    // in the functions that handle Submit, we would parse the missing or added guids and hit an api to remove or add, as required
    // IN ADDITION to submitting a new item or updating the existing item in the database...
    console.log('~~~~~ ViewEditItem.js, checkItemChanged() 1');
    
    if (create) {
      console.log('~~~~~ ViewEditItem.js, checkItemChanged() 2');
      return;
    }
    console.log('~~~~~ ViewEditItem.js, checkItemChanged() 3');
    
    let changedFields = [];

    {fields.map((field) => {
      console.log('~~~~~ ViewEditItem.js, checkItemChanged() 4, field.accessor');
      if (field.required) {
        console.log('~~~~~ ViewEditItem.js, checkItemChanged() item[' + field.accessor + '] was *' + item[field.accessor] + '*');
        console.log('~~~~~ ViewEditItem.js, checkItemChanged() formItem[' + field.accessor + '] is  now *' + formItem[field.accessor] + '*');
        if (item[field.accessor] !== formItem[field.accessor]) {
          console.log('~~~~~ ViewEditItem.js, checkItemChanged() item has been changed!');
          changedFields.push(field.accessor);
        }
      }
    });

    if (changedFields.length > 0) {
      console.log('~~~~~ ViewEditItem.js, checkItemChanged() item has been changed, ' + changedFields.length + ' fields were modified');
      // code here to make button show
      edit = true;
    } else {
      console.log('~~~~~ ViewEditItem.js, checkItemChanged() item has not been changed, no field values were modified');
      // code here to make button hide
      edit = false;
    }
  



    // setTimeout(() => {
    //   console.log("Checking for changed attribute values delayed for 3 seconds.");
    //   console.log('~~~~~ Groups.js, checkItemChanged() after delay - item.name_string: ' + item.name_string);
    //   console.log('~~~~~ Groups.js, checkItemChanged() after delay - itemName: ' + itemName);
    //   if (item.name_string !== itemName || item.note_string !== itemNotes) {
    //     // put us in editingItem state, so 'Update Item' button will show
    //     edit = true;
    //   } else {
    //     edit = false;
    //   }
    // }, 3000);
  }
}
  
  function handleValueChange(event) {
    const accessor = event.target.id;
    const tvalue = event.target.value;
    let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem

    console.log('~~~~~ ViewEditItem.js, ViewEditItem() - handleValueChange() attribute changing is *' + accessor + '* and value is *' + tvalue + '*');
    console.log('~~~~~ ViewEditItem.js, ViewEditItem() - handleValueChange() - newItem[' + accessor + '] is *' + newItem[accessor] + '*');

    newItem[accessor] = tvalue;

    console.log('~~~~~ ViewEditItem.js, ViewEditItem() - handleValueChange() - after edit, newItem[' + accessor + '] is *' + newItem[accessor] + '*');
    
    setFormItem(newItem);
    
    // note that formItem starts out identical to the item passed in to ViewEditItem (or is it always the same???)
  
    checkItemChanged();

    console.log('~~~~~ ViewEditItem.js, handleValueChange() - after checkItemChanged()');
  }
  
  function getFieldNames(fieldsArray) {
    // console.log('~~~~~ ViewEditItem, fields.length is *' + fieldsArray.length + '*');
    // console.log(`~~~~~ Groups.js - ViewEditItem - fieldsLength for itemType ` + itemType + ' is ' + fieldsArray.length);
    let outputString = '';

    fieldsArray.forEach(field => {
      if (outputString.length > 0) {
        outputString = outputString + ", " + field.displayName;
      } else {
        outputString = field.displayName;
      }

    });

    return outputString;
  }

  function handleUpdateItemClick(event) {

    console.log('~~~~~ ViewEditItem.js - handleUpdateItemClick()');
    alert('~~~~~ ViewEditItem.js - handleUpdateItemClick()'); // won't be used until we add confirmation modals
  }

  function handleCreateItemClick(event) {

    console.log('~~~~~ ViewEditItem.js - handleCreateItemClick()');
    alert('~~~~~ ViewEditItem.js - handleCreateItemClick()'); // won't be used until we add confirmation modals

    // this function will just invoke the createItemConfirmModal (createGroupConfirm?)
    // the Create [itemType] button in the confirm modal will call createItemConfirm

  }

  function handleCreateItemConfirmClick() {
    console.log('~~~~~ ViewEditItem.js, handleCreateItemConfirmClick() - formItem.name_string is *' + formItem.name_string + '*');

    // TODO: put back some generalized input validation here, based on info from modelInfo
    // if (formItem === undefined || formItem.name_string === undefined || formItem.name_string === "") {
    //   alert('You must at least choose a Name for a ' + itemType + ' in order to create one!');
    //   // TODO: replace that ^^^ with a pretty dialog
    // } else {
      
    if (itemType === "group") {
      createGroup(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(thisItem => { // item is going to be what is returned from the service method... 

        let alertMsg = 'New ' + itemType + ' with Name ' + thisItem.name_string + ' has been created and added to your list';

        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
    } else if (itemType === "program") {
      createProgram(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(thisItem => { // item is going to be what is returned from the service method... 

        let alertMsg = 'New ' + itemType + ' with Name ' + thisItem.name_string + ' has been created and added to your list';

        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
    } else if (itemType === "exercise") {
      createExercise(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(thisItem => { // item is going to be what is returned from the service method... 

        let alertMsg = 'New ' + itemType + ' with Name ' + thisItem.name_string + ' has been created and added to your list';

        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
    } else {
      alert('itemType was *' + itemType + '*'); // should never get here...
    }

    // }
  }

  function handleUpdateItemConfirmClick() {
    console.log('~~~~~ ViewEditItem.js - handleUpdateItemConfirmClick()');

    // code here to call updateGroup(object) and handle the response
    // how will we pass the new itemObj into this method?
    // maybe have the previous method save it in state as newItem?

    if (itemType === "group") {
      updateGroup(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(thisItem => { // item is going to be what is returned from the service method... 

        let alertMsg = 'Updated ' + itemType + ' with id_guid ' + thisItem.id_guid + ' has been updated in your list';

        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
    } else if (itemType === "program") {
      updateProgram(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(thisItem => { // item is going to be what is returned from the service method... 

        let alertMsg = 'Updated ' + itemType + ' with id_guid ' + thisItem.id_guid + ' has been updated in your list';

        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
    }
  }

  function handleViewAssociatedClick(event) {
    event.stopPropagation();
    const words = event.target.innerText.split(' ');
    const lastWord = words[words.length - 1];
    let assocType = lastWord.substring(0, lastWord.length - 1).toLowerCase();
    if (assocType === "workout") { assocType = "program" }
    console.log('~~~~~ ViewEditItem.js, handleViewAssociatedClick() - assocType is ' + assocType);
    setAssocItemType(assocType); // TODO: get this from the link that was clicked, or the event...
    setEditAssoc(true); // show the ViewEditAssociatedItem component in a Modal >>>> WHERE do we set associated type? (athlete || program)
  }

  // function itemFromId(id_guid) {
  //   console.log('~~~~~ ViewEditItem.js - itemFromId() for id_guid ' + id_guid);

  //   const matchingObjsArray = items.filter((item) => {
  //     return item.id_guid === id_guid;
  //   });

  //   console.log('~~~~~ ViewEditItem.js - itemFromId() for id_guid ' + id_guid + ', matchingObjsArray.length is ' + matchingObjsArray.length);

  //   // console.log('~~~~~ ViewEditItem.js - itemFromId() for id_guid ' + id_guid + ', video_url_string is ' + matchingObjsArray[0].video_url_string);

  //   let formItemObj = matchingObjsArray[0];

  //   console.log('~~~~~ ViewEditItem.js - itemFromId() for id_guid ' + id_guid + ', matchingObjsArray[0].title_string is ' + formItemObj.title_string);
    
  //   setFormItem(formItemObj);
  // }

  function alertGuid(id_guid) { // TODO: rename this function to something that sounds more essential...
    alert(id_guid);

    // let accessor = "movement_id_guid";  // value when itemType !== "Program"
    // let tvalue = id_guid;               // value when itemType !== "Program"

    // if (itemType === "Program") {
    //   let updatedArray = formItem.exercise_array ?? [];
    //   let newExerciseObj = {
    //     "exercise_id_guid": id_guid,
    //     "note_string": "" // TODO: this attribute should eventually be pulled from some form input? (if we ever use this)
    //   }
    //   updatedArray.push(newExerciseObj);
    //   accessor = "exercise_array";
    //   tvalue = updatedArray;
    // }

    // let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem

    // console.log('~~~~~ ViewEditItem.js, ViewEditItem() - alertGuid() attribute changing is *' + accessor + '* and value is *' + tvalue + '*');
    // console.log('~~~~~ ViewEditItem.js, ViewEditItem() - alertGuid() - newItem[' + accessor + '] is *' + newItem[accessor] + '*');

    // newItem[accessor] = tvalue;

    // console.log('~~~~~ ViewEditItem.js, ViewEditItem() - alertGuid() - after edit, newItem[' + accessor + '] is *' + newItem[accessor] + '*');
    
    // setFormItem(newItem);
    
    // // note that formItem starts out identical to the item passed in to ViewEditItem (or is it always the same???)
  
    // checkItemChanged();

    console.log('~~~~~ ViewEditItem.js, alertGuid() - after checkItemChanged()');
  }

  function handleUpdateArray(updatedArray) {
    console.log('~~~~~ ViewEditItem.js, handleUpdateArray() - updated array is ' + updatedArray);
    console.log(updatedArray);

    formItem.exercise_array = updatedArray;
    setFormItem(formItem);
  }

  function createViewEditInputs(itemType, fields, formItem) {
    console.log(`~~~~~ ViewEditItem.js, createViewEditInputs() - fields are: ` + getFieldNames(fields));
    // TODO: use a nameAccessor to switch between items with a name_string, and those with title_string or something else
    // console.log(`~~~~~ ViewEditItem.js, createViewEditInputs() - formItem.title_string is: ` + formItem.title_string);

  return (
    <div>
      {fields.map((field) => {
      let value = (formItem && formItem[field.accessor]) ? formItem[field.accessor] : "";
      console.log('~~~~~ ViewEditItem.js, createViewEditInputs() - for accessor ' + field.accessor + ', the value is ' + value);
      let inputCode = 
        <PrettyInputLabel style={{ width: "100%", marginBottom: "10px" }} key={field.accessor + '_label'}>
          <p>{field.displayName}</p>
          <PrettyInput
            key={field.accessor} 
            id={field.accessor}
            name={field.displayName}
            placeholder={field.placeholder}
            type={field.type} 
            autoComplete="name" 
            value={value} 
            onChange={e => handleValueChange(e)} // pass in this function?
            style={{width: "100%"}} 
          />
        </PrettyInputLabel>;
      if (field.type === 'association') {
        const associatedItems = field.accessor.includes('_users') ? "Athletes" :  // _users means "Athletes"
           field.accessor.includes('_programs') ? "Workouts" :                    // _programs means "Workouts"         
           field.accessor.includes('_movements') ? "Movements" :                  // _movements means "Movements"
           field.accessor.includes('_exercises') ? "Exercises" :                  // _exercises means "Exercises"
           "Items";                                                               // default would be "Items" (should never happen)
        inputCode = 
        <PrettyInputLabel style={{ display: create ? "none" : "inline-block", width: "100%", marginBottom: "10px" }} key={field.accessor + '_label'}>
          <div>
            <div style={{display: "inline-block", float: "left"}}>{field.displayName}</div>
            <div  
              onClick={handleViewAssociatedClick} 
              title="something explanatory"
              style={{ display: "inline-block", float: "right", fontSize: "smaller", textDecoration: "underline", color: colors.accent, cursor: "pointer" }}
            >
              {'View/Edit associated ' + associatedItems}
            </div>
          </div>
        </PrettyInputLabel>;      
      }
      if (field.type === 'textarea') {
        inputCode = 
        <PrettyInputLabel style={{ width: "100%", marginBottom: "10px", overflow: "auto" }} key={field.accessor + '_label'}>
          <p>{field.displayName}</p>
          <PrettyTextArea
            key={field.accessor}
            id={field.accessor} 
            name={field.displayName}
            placeholder={field.placeholder}
            type={field.type} 
            autoComplete="name" 
            value={value} 
            onChange={e => handleValueChange(e)} // pass in this function?
            style={{width: "100%", height: "150px", fontSize: "smaller", verticalAlign: "top"}} 
          />
        </PrettyInputLabel>;      
      }
      if (field.type === 'image') { // will take a url for thumbnail if itemType is movement, exercise or program
        inputCode = 
        <>
          <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
          <p>{field.displayName}</p>
          <PrettyInput
            key={field.accessor}
            id={field.accessor} 
            name={field.displayName}
            placeholder={field.placeholder}
            type="text" 
            autoComplete="name" 
            value={value} 
            onChange={e => handleValueChange(e)} // pass in this function?
            style={{width: "100%", height: "45px", fontSize: "smaller", verticalAlign: "top"}} 
          /> 
          <Thumbnail src={value} style={{ height: "150px", marginTop: "-15px" }}/> {/* src="bars.svg" */}
          </PrettyInputLabel>
        </>;
      }
      if (field.type === 'video') { // will take a url for video if itemType is movement or exercise
        inputCode = 
        <>
          <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
            <p>{field.displayName}</p>
            {/* <PrettyInput
              key={field.accessor}
              id={field.accessor} 
              name={field.displayName}
              placeholder={field.placeholder}
              type="text" 
              autoComplete="name" 
              value={value} 
              onChange={e => handleValueChange(e)} // pass in this function?
              style={{width: "100%", height: "45px", fontSize: "smaller", verticalAlign: "top"}} 
            />  */}
            <MouseOverVideoDiv style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
              <video
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={videoRef}
                src={value}
                type="video/mp4"
                loop
                className="w-100"
                style={{ display: (value && value.length > 5) ? "flex" : "none" }}
              ></video>
            </MouseOverVideoDiv>
          </PrettyInputLabel>
        </>;
      }
      if (field.type === 'movement') { // show movements list, with tnails, then show tnail & mouseover video
        let movement_thumbnail_src = '';
        let movement_video_src = '';
        // the dropdown or picklist with the movements in it should provide a movementObj, or directly provide 
        // the thumbnail_url_string and the video_url_string for the consumption of the ViewEdit form...
        // so, a dropdown or list with an onchange that sets three things:
        // - the value for the field.assessor, so we have a movement_id_guid to submit to the API,
        // - movement_thumbnail_src from movementObj.thumbnail_url_string
        // - movement_video_src from movementObj.video_url_string

        inputCode = 
        <>
          <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
          <p>{field.displayName}</p>
          {/* <PrettyInput
            key={field.accessor}
            id={field.accessor} 
            name={field.displayName}
            placeholder={field.placeholder}
            type="hidden" 
            autoComplete="name" 
            value={value} 
            onChange={e => handleValueChange(e)} // pass in this function?
            style={{width: "100%", height: "5px", fontSize: "smaller", verticalAlign: "top"}} 
          />  */}
          <PickList 
            optionItemType="movement"
            onSelectChange={alertGuid}
            key={field.accessor}
            id={field.accessor} 
            value={value} 
            onChange={e => handleValueChange(e)} // pass in this function?
          />
          <Thumbnail src={formItem.thumbnail_url_string}  // this cannot really work, not without fetching the movementObj
            style={{ height: "150px", marginTop: "5px", display: (value && value.length > 20) ? "flex" : "none" }}
          />
          <MouseOverVideoDiv>
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={videoRef}
              src={formItem.video_url_string} // this cannot really work, not without fetching the movementObj
              type="video/mp4"
              loop
              className="w-100"
              style={{ display: (value && value.length > 20) ? "flex" : "none" }}
            ></video>
          </MouseOverVideoDiv>
          </PrettyInputLabel>
        </>;

      }
      if (field.type === 'exercise_array') { // show exercises dropdown, and ordered list of selected exercises

        /*
          name_string: programObj.name_string,
          interval_day_decimal: programObj.interval_day_decimal, 
          note_string: programObj.note_string,
          exercise_array: programObj.exercise_array
        */

          const fakeItemsArray = [
            {
              exercise_id_guid: "fake-exercise-xyz-id_guid",
              name_string: "XYZ",
              thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
              // indexInt: 1,
              note_string: "Notes for exercise XYZ"
            },
            {
              exercise_id_guid: "fake-exercise-abc-id_guid",
              name_string: "ABC",
              thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
              // indexInt: 2,
              note_string: "Notes for exercise ABC"
            },
            {
              exercise_id_guid: "fake-exercise-pqr-id_guid",
              name_string: "PQR",
              thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
              // indexInt: 3,
              note_string: "Notes for exercise PQR"
            }
          ]

          const fakeItemsArray2 = [
            {
              "id_guid": "f7921340-93bb-4b36-9ef3-b10b21f6485c",
              "created_datetime": "2023-08-25T05:15:41.309Z",
              "updated_datetime": "2023-08-25T05:15:41.309Z",
              "user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
              "name_string": "Test 5 Workout",
              "interval_day_decimal": "3.00",
              "note_string": "This is the test 5 workout",
              "public_boolean": null,
              "movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a77f7f41befbe1f57af1df32e17e990a4ead6b8/Quadruped%20Rock.png",
              "movement_thumbnail_url_string_array": [
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a77f7f41befbe1f57af1df32e17e990a4ead6b8/Quadruped%20Rock.png"
              ],
              "exercise_array": [
                  {
                      "program_exercise_id_guid": "d6e9962c-6767-4746-a2be-a63a5507c449",
                      "rank_integer": 0,
                      "note_string": "note_string1",
                      "exercise_id_guid": "58bd6a4c-7d0b-4a70-b317-dceba4510f1a",
                      "name_string": "Lying Knee Grabs",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "3b6a68e9-7b02-4200-b42f-b4b9152d25cc",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f21f2d89dff656d83854846176665aff63579023/137.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
                      "title_string": "Lying Knee Grabs",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "cb06b7a4-45b2-44a0-b8a6-4c81a3b73b60",
                      "rank_integer": 1,
                      "note_string": "note_string2",
                      "exercise_id_guid": "37288a52-ff6f-4e81-8caf-b453cce6d754",
                      "name_string": "TRX Lateral Lunge",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "233aaa29-8924-4226-968f-335461c67fac",
                      "video_url_string": null,
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png",
                      "title_string": "TRX Lateral Lunge",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "110acf72-b099-454a-ae42-cdf2f7a90167",
                      "rank_integer": 2,
                      "note_string": "note_string3",
                      "exercise_id_guid": "19614b0f-9082-4700-918c-43c4a34fbc70",
                      "name_string": "Quadruped Rock",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "d4410769-397d-4e03-a71b-54f3913f72be",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBNUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4bcb8aec72b181f135e0e6c289ed970bdc0c1f5c/98.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a77f7f41befbe1f57af1df32e17e990a4ead6b8/Quadruped%20Rock.png",
                      "title_string": "Quadruped Rock",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "8205e675-dd41-4d6c-9633-7aebffd1a739",
                      "rank_integer": 3,
                      "note_string": "note_string4",
                      "exercise_id_guid": "abb27b9a-3418-4c21-8044-6e2849ec5333",
                      "name_string": "Pike Toe Touch",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "785e7498-fe87-4812-8b0e-e75376aeba93",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a0f30563f11f637d96de3c5499b3c0e8ab841c75/92.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
                      "title_string": "Pike Toe Touch",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "6112984a-9ce9-4001-9c2b-acff9d8cc5d2",
                      "rank_integer": 4,
                      "note_string": "note_string5",
                      "exercise_id_guid": "630013b4-931e-41eb-9efa-ab93805f082d",
                      "name_string": "Butterfly Stretch",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "d7234863-1504-40c6-bb67-183ce10ba15c",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBKZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b0397d2f15dead7395a54b95f0416ab2c3442f58/88.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png",
                      "title_string": "Butterfly Stretch",
                      "subtitle_string": null
                  }
              ]
          },
          {
              "id_guid": "72697cb9-daed-4170-8178-db26693cfe30",
              "created_datetime": "2023-06-21T06:05:48.337Z",
              "updated_datetime": "2023-08-31T19:58:02.808Z",
              "user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
              "name_string": "Advanced Workout",
              "interval_day_decimal": "2.50",
              "note_string": "Advanced workout notes update!",
              "public_boolean": null,
              "movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png",
              "movement_thumbnail_url_string_array": [
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png"
              ],
              "exercise_array": [
                  {
                      "program_exercise_id_guid": "08a902ae-412e-4f4f-b6a6-a8bce3b67989",
                      "rank_integer": 0,
                      "note_string": "",
                      "exercise_id_guid": "42bde8ac-c6fb-4d14-840e-231de8b9e0dd",
                      "name_string": "Cat Cow",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "8d949f45-692a-4139-a982-5e77f9c1d6c3",
                      "video_url_string": null,
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png",
                      "title_string": "Cat Cow",
                      "subtitle_string": null
                  }
              ]
          },
          {
              "id_guid": "24444a70-fd5c-4491-9fb7-5047424eff1b",
              "created_datetime": "2023-08-31T20:31:50.798Z",
              "updated_datetime": "2023-08-31T20:31:50.798Z",
              "user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
              "name_string": "New Test Workout",
              "interval_day_decimal": "1.00",
              "note_string": "New Test Workout notes",
              "public_boolean": null,
              "movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
              "movement_thumbnail_url_string_array": [
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
                  "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png"
              ],
              "exercise_array": [
                  {
                      "program_exercise_id_guid": "fe428315-6f26-4b9e-9320-dc9f15b6f820",
                      "rank_integer": 0,
                      "note_string": "",
                      "exercise_id_guid": "42bde8ac-c6fb-4d14-840e-231de8b9e0dd",
                      "name_string": "Cat Cow",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "8d949f45-692a-4139-a982-5e77f9c1d6c3",
                      "video_url_string": null,
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79c53cf8e7bf4ad389aedc71583d2452c97cc771/Cat%20Cow.png",
                      "title_string": "Cat Cow",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "85612275-6a7a-4176-ba0a-8499fb7c1886",
                      "rank_integer": 1,
                      "note_string": "",
                      "exercise_id_guid": "abb27b9a-3418-4c21-8044-6e2849ec5333",
                      "name_string": "Pike Toe Touch",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "785e7498-fe87-4812-8b0e-e75376aeba93",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a0f30563f11f637d96de3c5499b3c0e8ab841c75/92.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
                      "title_string": "Pike Toe Touch",
                      "subtitle_string": null
                  },
                  {
                      "program_exercise_id_guid": "0dd4d946-13b5-462c-b36f-7893020c05de",
                      "rank_integer": 2,
                      "note_string": "",
                      "exercise_id_guid": "58bd6a4c-7d0b-4a70-b317-dceba4510f1a",
                      "name_string": "Lying Knee Grabs",
                      "duration_seconds_integer": 90,
                      "repetition_integer": 3,
                      "exercise_note_string": "This is a note",
                      "movement_id_guid": "3b6a68e9-7b02-4200-b42f-b4b9152d25cc",
                      "video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f21f2d89dff656d83854846176665aff63579023/137.mp4",
                      "thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
                      "title_string": "Lying Knee Grabs",
                      "subtitle_string": null
                  }
              ]
          }
        ];

        inputCode = 
        <>
          <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
            <p>{field.displayName}</p>
            <PickList 
              optionItemType="exercise"
              onSelectChange={alertGuid}
              key={field.accessor}
              id={field.accessor} 
              value={value} 
              onChange={e => handleValueChange(e)} // pass in this function?
            />
            <OrderedItemList 
              active={true} 
              mobile={false} 
              // array={fakeItemsArray}
              array={(formItem && formItem.exercise_array) ? formItem.exercise_array : fakeItemsArray} 
              updateArray={handleUpdateArray}
            />  
            {/* onWorkoutClick={} */}
          </PrettyInputLabel>
        </>;
      }
      if (field.type === 'exercise') { // show exercises list, with tnails, then show tnail & mouseover video w/reps & durations


      }
      if (field.type === 'upload') { // might allow thumbnail or video upload by admin for movement
        inputCode = '';
      }
      if (field.type === 'hidden') { // used for IDs at the moment, since they are not editable
        inputCode = '';
      }

      return inputCode;
     })}
    </div>
   );
  }

  function handleAssocModified() { // runs after add or remove associated item from Modal
    setEditAssoc(false);
    setTimeout(function() {
      console.log('~~~~~ ViewEditItems - handleAssocModified()!');
      setEditAssoc(true);
    }, 500);
  }

  return (
    <div style={{ display: visible ? "flex" : "none", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <AreaTitle style={{ padding: "20px" }}>{create ? "Create a New " : "View/Edit a "} {itemType === "program" ? "Workout" : capitalize(itemType)}</AreaTitle>
      
      <ItemFields>
        {createViewEditInputs(itemType, fields, formItem)}    {/* iterate through displayFields and build ViewEdit inputs */}

        <SiteButton
          style={{ marginTop: "20px" }}
          // hidden={(!edit && !create)}
          hidden={false}
          icon={iconSrc[itemType.toLowerCase() + 's_active']}
          title={create ? 'Add this New ' + itemType : 'Update This ' + itemType} 
          onClick={create ? handleCreateItemConfirmClick : handleUpdateItemConfirmClick} // find a way to key off of type...
        />
      </ItemFields>

      <Modal
        show={editAssoc}
        onHide={handleCloseEditAssoc}
        backdrop="static"
        keyboard={false}
        centered={true}
        style={{ maxHeight: "97vh", overflow: "hidden" }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px", height: "7vh", marginBottom: "0px" }}>
          {/* <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Remove {itemType}  <br /><b>{itemName}</b>?</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "85vh", overflow: "scroll", marginTop: "-10px", paddingTop: "0px" }}>
          <ViewEditAssociatedItems
            // itemType="athlete"      // (or "program") this is the itemType that the list will show
            // assocType="group"       // this is the itemType the association record will be filtered on to make the listArray
            itemType={assocItemType}   // ("athlete" or "program") this is the itemType that the list will show
            assocType={itemType.toLowerCase()}       // this is the itemType the association record will be filtered on to make the listArray
            assocId={itemId}           // this is the item.id_guid that will be used to filter the association records
            active={true}         // no services/associations methods will be called unless active is asserted
            onAssocModified={handleAssocModified}       
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewEditItem;
