// Workouts.js

import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import packageJson from '../../package.json';

import { getAthletePrograms, getAthleteMovements, getWorkoutExercises } from '../services'; // sort/filter Workouts by day/date on client side
import { iconSrc } from '../constants';

import {
  AreaTitle,
  BackLink,
  SplitTabContentWrapper,
  TabSectionLeft,
  TabSectionRight,
  TabContentWithHeaderWrapper,
  TabContentHeader,
  EZSpacer 
} from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import WorkoutsHeader from './styled/WorkoutsHeader'; // TODO: do we really need this? how much will be in here?
import WorkoutList from './styled/WorkoutList';
import MovementList from './styled/MovementList';
import Workout from './styled/Workout';
import Exercise from './styled/Exercise';
import NavButton from './styled/NavButton';

let fetchingWorkoutsNow = false;
let fetchingMovementsNow = false;


function Workouts(props) {
  // this tab is where Athletes can see their assigned Workouts for a (rolling?) week (or two calendar weeks?)
  // - there will be five primary components used in this tab:
  // -- WorkoutsHeader (date, athlete name, sport(s), coach(es)... ?)
  // -- WorkoutList (Google Calendar-style chronological list of Workouts with day/date, name & thumbnail)
  // -- Workout (Expanded list of exercises in the workout, with title & subtitle & thumbnail?)
  // -- Exercise (expanded view of Exercise with reps, duration, notes and video)
  //
  // - the Workout Header will always be visible
  // - the WorkoutList will be visible by default in the leftTabSection, with WorkoutsToday in the rightTabSection
  // -- when a Workout is clicked in the WorkoutList or WorkoutsToday components, WorkoutsToday will open in the leftTabSection
  // --- at the same time the Workout component will open in the rightTabSection, replacing the WorkoutsToday component
  // -- when an Exercise is clicked in the Workout section, the Workout component will *move* to the leftTabSection
  // --- at the same time, the Exercise component will open in the rightTabSection

  // const [width, setWidth] = useState(window.innerWidth); // useState<number>(window.innerWidth);

  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }
  
  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //       window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);

  const isMobile = props.isMobile

  console.log('~~~~~ Workouts.js, Workouts() - isMobile is *' + isMobile + '*');

  const athleteId = localStorage.getItem('loggedInUser');
  const [workouts, setWorkouts] = useState([]); // collection of Workouts assigned to this user [NOTE: CAN BE FETCHED ONCE, with useEffect()]
  const [movements, setMovements] = useState([]); // collection of Movements recommended for this user [NOTE: CAN BE FETCHED ONCE, with useEffect()]
  const [exercises, setExercises] = useState([]); // collection of Exercises fetched for this user (maybe just the ones in most recently selected Workout)

  // do we need, should we have selectedWorkout, selectedMovement, selectedExercise?

  const [itemId, setItemId] = useState([]);
  const [itemObj, setItemObj] = useState({});
  const [selectedWorkoutName, setSelectedWorkoutName] = useState("Automated");
  const [itemNotes, setItemNotes] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstSortDone, setFirstSortDone] = useState(false);
  

  const [formItem, setFormItem] = useState({});

  const [filterType, setFilterType] = useState("exercise"); // TODO: do we need this? can we pass in a string as prop for ItemsTable?
  const [filterId, setFilterId] = useState();

  const [config, setConfig] = useState("LW"); //

  // const [showRemoveItemConfirm, setShowRemoveItemConfirm] = useState(false);
  const [loaded, setLoaded ] = useState(true); // TODO: set back to false when there is a useEffect() that will set it to true after fetching
  const [searchParams, setSearchParams] = useSearchParams();

  // logic for switching components on and off
  //    COMPONENT          ELEMENT (box)       OnClick (box)   POSITIONS ENABLED         DATA/SOURCE
  // -- List               Workout Metadata    LM -> LW        Default/LM | left         WorkoutArray/internal fetch
  // -- MovementList       Movement Metadata   LM -> ME, ME    Default/LM, ME|left       MovementArray/internal fetch
  // -- Workout            Exercise Metadata   LW -> WE, WE    CW/right, WE/left         ExerciseArray/programId prop + fetch
  // -- Exercise           n/a                                 WE/right                  ExerciseItem/exerciseObj prop
  // -- Movement           n/a                                 ME/right                  ExerciseItem/exerciseObj prop

  // Possible Combinations:           CODE   HOW TO GET THERE                                             HOW TO GO BACK?
  // List         | MovementList       LM    Default, "List"?                                             n/a
  // MovementList | Exercise (mvt)     ME    Recommended Mvt onClick                                      <-BACK ?, HOME ?
  // List         | Workout            LW    List Workout onClick                                         Recommendations ?
  // Workout      | Exercise           WE    Workout Exercise onClick, Exercise or Recommended "Next->"   <-BACK ?

  // So:
  // - List always on the left if visible (LM, LW)
  // - Exercise / Movement always on the right if visible (ME, WE)
  // - Recommended can show on right or left (LM, ME)
  // - Workout can show on right or left (LW, WE)
  // -- We can create a State var for the panel config: [config, setConfig] = useState("LM")

  // Is there a reason to keep WorkoutArray & MovementArray & ExerciseArray in Workouts state?
  // - (vs fetching internally in List, Recommended & Workout?)
  
  // FOR EXTRA CREDIT:
  // -- We could set the config based on the window.location to enable deep-linking: e.g. /workouts/LR or workouts?c=LR
  // --- If we re-set the location when switching configs, would the entire tab reload???


  // SHOULD WE HAVE SOME UseEffect() methods here?
  // - to load all workouts assigned to this Athlete? -> pass workoutArray as a prop to WorkoutList
  // - to load all movements recommended for this Athlete? -> pass movementArray as a prop to MovementList
  // --- if the Exercises, Order and specifics are in the workoutArray, they could be fetched from there and passed to the 
  //      Exercise component as an itemObj { name_string, duration_integer, repetition_integer, thumbnail_url_string, video_url_string }
  // --- since the Movement specifics are passed in the movementsArray, they can be fetched from there and passed to the
  //      Exercise component as an itemObj { title_string, subtitle_string, thumbnail_url_string, video_url_string }

  

  const mounted = useRef(true);
  
  const urlPieces = window.location.toString().split("/");
  const lastPiece = urlPieces[urlPieces.length - 1];  // the last piece might have a queryString e.g groups?email=greg@physmodo
  const athleteEmail = searchParams.get("athleteEmail");
  const athleteName = searchParams.get("athleteName");

  // let selectedWorkoutName = "No Workout";
  let selectedWorkoutId = "no-guid-yet";
  // let selectedWorkoutName = "Automated";

  let selectedExerciseName = "No Exercise";
  let selectedExerciseId = "no-guid-yet";
  let selectedMovementName = "No Movement";
  let selectedMovementId = "no-guid-yet";

  let selectedItemName = "No Name Yet";
  let selectedItemId = "no-guid-yet";

  console.log('~~~~~ Groups.js - searchParams.get("athleteEmail"): ' + searchParams.get("athleteEmail"));

  const tabReference = lastPiece.split('?')[0]; // should be singular, so itemType below will remove last character
  const itemType = tabReference.charAt(0).toUpperCase() + tabReference.slice(1).substring(0, tabReference.length - 2);

  console.log('~~~~~ Workouts.js - itemType: ' + itemType);
  console.log('~~~~~ Workouts.js - tabReference: ' + tabReference);

  const itemInfo = require('../models/program').default; // { type, attributes, listColumns, displayFields, defaultSort }

  console.log('~~~~~ Workouts.js, Workouts() - itemInfo.type = *' + itemInfo.type + '*');

  function initialSort(itemsArray) { // the only sort we need is by date, ascending
    console.log('~~~~~ Workout, Workout() - initialSort() - itemsArray.length is ' + itemsArray.length);
    
    const sorted = itemsArray.sort(function(a,b){
      return a.exerciseOrder - b.exerciseOrder;
    });

    setTableData(sorted);

    return sorted;
  }

  // let fetchingNow = false;

  function workoutClickHandler(event, id) {
    // alert('~~~~~ Workouts.js, Workouts() - workoutClickHandler() event.type is *' + event.type + '*, and event.target.id is *' + event.target.id + '*');
    // alert('~~~~~ Workouts.js, Workouts() - workoutClickHandler() event.type is *' + event.type + '*, and event.currentTarget.id is *' + event.currentTarget.id + '*');
    const key = event.currentTarget.id;
    const workoutId = key.split('_')[0];
    // const workoutName = event.currentTarget.className;
    setSelectedWorkoutName(event.currentTarget.dataset["name"]);
    // selectedWorkoutName = event.currentTarget.dataset["name"];
    
    // alert('~~~~~ Workouts.js, Workouts() - workoutClickHandler() workoutId = *' + workoutId + '*, and name is *' + selectedWorkoutName + '*');
    setConfig("LW");
    // code here to load proper Workout (Exercise List) into Workout viewer (<Workout /> component)
    // - this would likely entail fetching program_exercises for a particular program id_guid
    // - so we are going to fetch program_exercises for a workout, and then setExercises(program_exercises)
    // - maybe check to see if this wokout is the one already loaded, and skip fetch?

    // set the workout name that will show up on the Workout component
    // - search through the loaded programs/workouts to find the matching id, or find it via the event? 

    getProgramExercises(workoutId);

    if (isMobile) {
      // alert('workoutClickHandler - inside isMobile');
      setTimeout(() => {

        const element = document.getElementById("workout_component"); // top of the Workout component
        if (element !== undefined && element !== null) {
          // alert('workoutClickHandler - inside element exists *' + element.id + '*');
          element.scrollIntoView();
        } else {
          alert('workoutClickHandler - workout_component was not found');
        }
      }, 3500)
    }

    // getWorkoutExercises(workoutId) // should there be a filter option? maybe public or not?
    // .then(itemsResp => {
    //   if(mounted.current) {
    //     let items = itemsResp;
    //     setExercises(items);
    //     let sortedItems = items;
    //     // if ([...items].length > 0 && !firstSortDone) {
    //     //   sortedItems = initialSort(items);
    //     //   setFirstSortDone(true);
    //     // }
    //     // setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
    //     // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
    //     // fetchingMovementsNow = false; // Now we have finished with the current fetch
    //     setLoading(false);
    //     console.log('~~~~~ Workouts() - useEffect() fetching Program Exercises - ' + items.length + ' Exercises were returned');
    //     // alert('~~~~~ Workouts() - useEffect() - ' + items.length + ' Movements were returned');
    //   }
    // })
  }

  function getProgramExercises(workoutId) {
    
    getWorkoutExercises(workoutId) // should there be a filter option? maybe public or not?
    .then(itemsResp => {
      if(mounted.current) {
        let items = itemsResp;
        setExercises(items);
        let sortedItems = items;
        // if ([...items].length > 0 && !firstSortDone) {
        //   sortedItems = initialSort(items);
        //   setFirstSortDone(true);
        // }
        // setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
        // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
        // fetchingMovementsNow = false; // Now we have finished with the current fetch
        setLoading(false);
        console.log('~~~~~ Workouts() - useEffect() fetching Program Exercises - ' + items.length + ' Exercises were returned');
        // alert('~~~~~ Workouts() - useEffect() - ' + items.length + ' Movements were returned');
      }
    })
  }

  function exerciseClickHandler(event, id) { // click on boxed row in Workout ExercisesList, OR a boxed movement in MovementList
    let type = "exercise";
    const key = event.currentTarget.id;
    const itemId = key.split('_')[0];

    // we need the exercise or movement name to pass
    // alert('~~~~~ Workouts.js, Workouts() - exerciseClickHandler() config is *' + config + '*');
    // could be a click on an Exercise in a Workout, or a click on a Movement in MovementList
    // setConfig("ME") if config is currently LM, or setConfig("WE") if config is currently LW
    if (config === "LM") { // default config, WorkoutList on left, MovementList on right
      
      type = "movement";
      selectedMovementId = itemId;
      console.log('~~~~~ Workouts.js, Workouts() - exerciseClickHandler() config is LM, setting to ME - selectedMovementId is *' + selectedMovementId);
      // selectedMovementId = itemId;
      setConfig("ME"); 
    } else if (config === "LW") { // looking at workout, WorkoutList on left, Workout (exerciseList) on right
      
      selectedExerciseId = itemId;
      console.log('~~~~~ Workouts.js, Workouts() - exerciseClickHandler() config is LW, setting to WE - selectedExerciseId is *' + selectedExerciseId);
      setConfig("WE");
    } else if (config === "WE") { // looking at exercise, Workout (exerciseList) on left, Exercise specifics on right
      selectedExerciseId = itemId;
      // alert('already in config WE - just need to change exercise! - selectedExerciseId is *' + selectedExerciseId);
    } else if (config === "ME") { // looking at movement, MovementList on left, Movement specifics on right
      type = "movement";
      selectedMovementId = itemId;
      // alert('already in config ME - just need to change movement! - selectedMovementId is *' + selectedMovementId);
    } else {
      alert('exerciseClickHandler() - current config is *' + config + '*');
    }

    // if (isMobile) {
    //   alert('handleExerciseClick for mobile case');
    // } else {
    //   alert('handleExerciseClick for NOT mobile case');
    // }
    
    // window.scrollTo({
    //   top: 1000, // find vh in px and scroll to there?  or scroll to an element found at the top of Exercise component?
    //   left: 0,
    //   behavior: "smooth",
    // });

    // setTimeout(() => {
    //   // alert('In setTimeout for scrollIntoView()');
    //   // const element = document.getElementById("exercise_component");
    //   // console.log('~~~~~ Workouts.js, Workouts() - element is *' + element + '*');
    //   // window.scrollTo(0, document.body.scrollHeight);
    //   const scrollingElement = (document.scrollingElement || document.body);
    //   scrollingElement.scrollTop = scrollingElement.scrollHeight + 3000;
    //   // element.scrollIntoView();
    // },2500);

    selectedItemId = itemId;
    setItemId(itemId);
    getItemObj(itemId);
  }

  function getItemObj(id_guid) {
    console.log('~~~~~ Workouts.js, Workouts() - getItemObj() - id_guid is *' + id_guid + '*');
    console.log('~~~~~ Workouts.js, Workouts() - getItemObj() - there are ' + exercises.length + ' exercises and ' + movements.length + ' movements to search');
    // we have an array of movements in the State var movements
    // we have an array of exercises from the most recent Workout in the State var exercises
    // the id_guid we have should only match one item across all items in both arrays
    // - the id_guid accessor is different for the exerciseId in exercises (exercise_id_guid), then for the movementId in movements (id_guid)
    // - we will want to filter each array by the appropriate accessor, trying to match the supplied id_guid
    // -- as soon as we find a match we want to setItem to the associated object
    
    const matchingExerciseObjsArray = exercises.filter((item) => {
      return item.exercise_id_guid === id_guid;
    });

    const matchingMovementObjsArray = movements.filter((item) => {
      return item.id_guid === id_guid;
    });

    if (matchingExerciseObjsArray.length > 0) {
      setItemObj(matchingExerciseObjsArray[0]);
    } else if (matchingMovementObjsArray.length > 0) {
      setItemObj(matchingMovementObjsArray[0]);
    } else {
      alert('No Exercise or Movement matched id_guid *' + id_guid + '*');
      console.log('~~~~~ Workouts.js, Workouts() - getItemObj() - no Exercise or Movement matched id_guid *' + id_guid + '*');
    }
  }

  useEffect(() => {
    if(fetchingWorkoutsNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
      console.log('~~~~~ Workouts.js, Workouts() - inside useEffect, fetchingWorkoutsNow true, skipping fetch!');
      return;
    }
    fetchingWorkoutsNow = true; // Now there is a fetch in progress
    setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
    mounted.current = true;

    console.log('~~~~~ Workouts.js, Workouts() - inside useEffect, proceeding with getPrograms()');
    getAthletePrograms(athleteId) // should there be a filter option? maybe public or not?
    .then(itemsResp => {
      if(mounted.current) {
        let items = itemsResp;
        setWorkouts(items);
        // setSelectedWorkoutName(items[0].name_string); // Name of Automated Workout is "Automated Workout"
        // selectedWorkoutName = "Automated";
        selectedWorkoutId = items[0].id_guid;
        let sortedItems = items;
        // if ([...items].length > 0 && !firstSortDone) {
        //   sortedItems = initialSort(items);
        //   setFirstSortDone(true);
        // }
        setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
        // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
        // fetchingWorkoutsNow = false; // Now we have finished with the current fetch
        setLoading(false);
        console.log('~~~~~ Workouts() - useEffect() fetching Workouts - ' + items.length + ' Workouts were returned');
        // alert('~~~~~ Workouts() - useEffect() - ' + items.length + ' Workouts were returned');
        setTimeout(function() {
          const element = document.getElementById("content_wrapper");
          console.log('~~~~~ Workouts.js, Workouts() - content_wrapper element is *' + element + '*');
          element.scrollIntoView();
          getProgramExercises(selectedWorkoutId);
        }, 200);
      }
    })
  }, [workouts])

  useEffect(() => {
    if(fetchingMovementsNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
      console.log('~~~~~ Workouts.js, Workouts() - inside useEffect, fetchingMovementsNow true, skipping fetch!');
      return;
    }
    fetchingMovementsNow = true; // Now there is a fetch in progress
    setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
    mounted.current = true;

    console.log('~~~~~ Workouts.js, Workouts() - inside useEffect, proceeding with getPrograms()');
    getAthleteMovements(athleteId) // should there be a filter option? maybe public or not?
    .then(itemsResp => {
      if(mounted.current) {
        let items = itemsResp;
        setMovements(items);
        let sortedItems = items;
        // if ([...items].length > 0 && !firstSortDone) {
        //   sortedItems = initialSort(items);
        //   setFirstSortDone(true);
        // }
        // setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
        // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
        // fetchingMovementsNow = false; // Now we have finished with the current fetch
        setLoading(false);

        console.log('~~~~~ Workouts() - useEffect() fetching Movements - ' + items.length + ' Movements were returned');
        // alert('~~~~~ Workouts() - useEffect() - ' + items.length + ' Movements were returned');
      }
    })

  }, [movements])

  function logout() {
    props.logout();
  }

  return ( 
    <div>
      {loaded ? '' : <LoadingDivLayer />} 
      <TabContentWithHeaderWrapper isMobile={isMobile}>
        
        { !isMobile &&
          <TabContentHeader>
            <WorkoutsHeader isMobile={isMobile} config={config} style={{ textAlign: "center" }} />
          </TabContentHeader> 
        }

        <SplitTabContentWrapper isMobile={isMobile} className="wrapper" loaded={loaded} id="content_wrapper">
          { isMobile && 
            <div style={{ width: "100%", height: "30px", zIndex: "1000" }}>
              { config === "WE" &&
                <div style={{ width: "320px", float: "left", zIndex: "1000", paddingLeft: "20px" }}>
                  <a href="#" onClick={(e) => {window.location.reload()}} style={{ fontSize: "20px", padding: "5px", zIndex: "1000" }}>Back to Assigned Workouts</a>
                </div>
              }
              <div style={{ width: "120px", float: "right", zIndex: "1000", paddingRight: "10px" }}>
                <a href="/#" onClick={logout} style={{ fontSize: "20px", padding: "5px", zIndex: "1000", float: "right" }}>Logout</a>
              </div>
            </div>
          }
          <TabSectionLeft isMobile={isMobile} style={{ paddingRight: "20px" }}> 

            <WorkoutList isMobile={isMobile} onWorkoutClick={workoutClickHandler} active={config === "LM" || config === "LW"} workouts={workouts} loading={loading} />
            <MovementList onMovementClick={exerciseClickHandler} active={config === "ME"} movements={movements} loading={loading} />
            <Workout isMobile={isMobile} onExerciseClick={exerciseClickHandler} active={config === "WE"} name={selectedWorkoutName} id_guid={selectedWorkoutId} exercises={exercises} />
          </TabSectionLeft>

          <TabSectionRight isMobile={isMobile}>
            <MovementList onMovementClick={exerciseClickHandler} active={config === "LM"} movements={movements} loading={loading} />
            <Workout isMobile={isMobile} onExerciseClick={exerciseClickHandler} active={config === "LW"} name={selectedWorkoutName} id_guid={selectedWorkoutId} exercises={exercises} loading={loading} />
            <Exercise
              isMobile={isMobile} 
              id="exercise_component"
              active={config === "WE" || config === "ME"} 
              config={config} 
              type={itemType} 
              id_guid={itemId}
              itemObj={itemObj}
              name={selectedItemName}
            />
          </TabSectionRight>

        </SplitTabContentWrapper>
        { isMobile &&
          <p id="version_p" style={{ textAlign: "center", width: "100vw", height: "7vw", fontSize: "1.6vw", padding: "3.0vw", opacity: "0.3" }}>
            Version: {packageJson.version}
          </p>
        } 

      </TabContentWithHeaderWrapper>
    </div>
  );
}

export default Workouts;