// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive'

import Login from './components/Login';

import SideBar from './components/styled/SideBar';
import Content from './components/styled/Content';

import { storageAvailable } from './helpers'; 

function App() {
  // const [loading, setLoading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  // const isPhone = useMediaQuery({ query: '(max-width: 1224px)' });
  const deviceInfo = {
    isDesktopOrLaptop: isDesktopOrLaptop,
    isBigScreen: isBigScreen,
    isTabletOrMobile: isTabletOrMobile,
    isPortrait: isPortrait,
    isRetina: isRetina
  };

  // deviceInfo.isTabletOrMobile

  // also have to change this in Login.js to test case where no localStorage
  // constlocalStorageExists = false; // use App.state as a backup if no localStorage?
  const localStorageExists = storageAvailable('localStorage');
  // const alreadyReloaded = localStorageExists && localStorage.getItem('alreadyReloaded');
  
  function storeToken(token_string) {
    localStorage.setItem('token', token_string);
  }

  function logout() {
    if (localStorageExists) {
      localStorage.setItem('token', null);
      localStorage.setItem('alreadyReloaded', false);
      window.location.reload();
    } else {
      setToken(null);
    }
  }

  let token = localStorage.getItem('token');
  let setToken = storeToken;

  // no way found yet to show a message only once per session if there is no localStorage,
  // so we are not giving the user a 'get a better browser' warning in that case :-(
  // if (!localStorageExists) {  
  //   [token, setToken] = useState();
  // }

  const [width, setWidth] = useState(window.innerWidth); // useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  // const isMobile = width <= 768;
  const isMobile = deviceInfo.isTabletOrMobile;
  // if we want this to realy mean "is tall and skinny"...  we could invent something that considered:
  // - deviceInfo.isMobileOrTablet
  // - deviceInfo.isRetina (because 'skinny' in pixels, is twice as big if display is retina)
  // - deviceInfo.isPortrait (because a phone in landscape might benefit from desktop version of layout)

  // so mobile should *always* mean deviceInfo.isPortrait?
  // - or could it be mobile even in landscape if max-width is less than some number?





  
  console.log('~~~~~ App.js, isMobile is *' + isMobile + '*');

  console.log('~~~~~ ' + window.location.href);

  if (window.location.href.includes('/accept') || window.location.href.includes('/signup')) {
    return (
      <Router>
        <div style={{ display: "flex", minHeight: "100%"}}>
          <Content deviceInfo={deviceInfo} isMobile={isMobile} />
        </div>
      </Router>
    );
  } else {

    if(!token || token.length < 10) {
      return <Login setToken={setToken} isMobile={isMobile} />
    } else {
      return (
        <Router>
          <div style={{ display: "flex", minHeight: "100%"}}>
            { !isMobile && <SideBar logout={logout} deviceInfo={deviceInfo} /> }
            <Content deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />
          </div>
        </Router>
      );
    }
  }
}

export default App;

