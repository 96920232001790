import React from 'react';
import styled from 'styled-components'

import ScoreDisplay from './ScoreDisplay';
import { Title } from './title';

const TableTitle = styled(Title)`
  letter-spacing: 0.15vw;
  padding: 0;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 1.5vw;
  font-size: 1.0vw;
`;

const StyledTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  /* &:hover { background: ghostwhite }; */ /* no hover state here */
`;

const TableBody = ({ tableData, maxRows, domain }) => {

  var domainData = tableData.filter((issue) => {
    return issue.domain === domain;
  });

  return (
    <tbody>
      {domainData.slice(0, maxRows).map((data) => {
        return (
          <StyledTableRow key={data.id}>
            <td>
              <ScoreDisplay data={data} key={"score-display-" + data.id}/>
            </td>
          </StyledTableRow>
        )})
      }
    </tbody>
  );
};

function PrimaryIssuesTable(props) {
  const {
    data,
    domain,
    maxIssues
  } = props;

  return (
    <>
      <TableTitle>PRIMARY ISSUES</TableTitle>
      <table style={{ minWidth: "18vw" }}>
        <thead><tr><th></th><th></th></tr></thead>
        <TableBody tableData={data} domain={domain} maxRows={maxIssues} />
      </table>
    </>
  );
}

export default PrimaryIssuesTable;
