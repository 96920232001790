import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { propTypes } from 'react-bootstrap/esm/Image';

import Overview from '../Overview';
import Members from '../Members';

import Groups from '../Groups';
import Programs from '../Programs';
import Exercises from '../Exercises';
import Movements from '../Movements';
import Workouts from '../Workouts';
import TestList from '../TestList';

import History from '../History';
import Assessment from '../Assessment';
import Accept from '../Accept';
import Signup from '../Signup';


import styled from 'styled-components'

const ContentDiv = styled.div`
  flex: 1;
  padding: 10px;
  padding-left: 0px; // for mobile only?
  padding-right: 0px; // for mobile only?
  background: #ffffff;
  height: 100vh;
  overflow-y: scroll;
`;

function Content(props) { // props: deviceInfo Object { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
  const isCoach = localStorage.getItem('coach') === 'true';
  const isAthlete = localStorage.getItem('athlete') === 'true';
  const isEnterprise = localStorage.getItem('enterprise') === 'true';
  const deviceInfo = props.deviceInfo; // deviceInfo.isMobileOrTablet
  const isMobile = props.isMobile;
  const logout = props.logout;

  console.log('~~~~~ Content.js, Content() - isMobile is *' + isMobile + '*');
  return (
    <ContentDiv>
        <Routes>
          <Route
            exact
            path="/"
            // element={isCoach ? <Members deviceInfo={props.deviceInfo} /> : <Overview deviceInfo={props.deviceInfo} />}
            element={
              isCoach ? <Navigate replace to={"/members"} /> :
              isAthlete ? <Navigate replace to={"/workouts"} /> :
              <Overview deviceInfo={props.deviceInfo} />
            }
            // element={
            //   isCoach ? <Members deviceInfo={props.deviceInfo} /> :
            //   isAthlete ? <Workouts deviceInfo={props.deviceInfo} /> :
            //   <Overview deviceInfo={props.deviceInfo} />
            // }
          />
          {/* <Route path='/' element={<Overview deviceInfo={props.deviceInfo} />} /> */}
          
          <Route path='/members' element={<Members deviceInfo={props.deviceInfo} />} />

          { isCoach && 
          <>
            <Route path='/groups' element={<Groups deviceInfo={props.deviceInfo} />} />
            <Route path='/programs' element={<Programs deviceInfo={props.deviceInfo} />} />
            <Route path='/exercises' element={<Exercises deviceInfo={props.deviceInfo} />} />
            <Route path='/movements' element={<Movements deviceInfo={props.deviceInfo} />} />
            <Route path='/workouts' element={<Workouts deviceInfo={props.deviceInfo} />} />
          </>
          }
          {
            isAthlete &&
            <>
              <Route path='/workouts' element={<Workouts deviceInfo={props.deviceInfo} isMobile={isMobile} logout={logout} />} />
              <Route path='/testlist' element={<TestList deviceInfo={props.deviceInfo} />} />
            </>
          }

          <Route path='/history' element={<History deviceInfo={props.deviceInfo} />} />
          <Route path='/assessment' element={<Assessment deviceInfo={props.deviceInfo} />} />
          <Route path='/accept' element={<Accept deviceInfo={props.deviceInfo} isMobile={isMobile} />} />
          <Route path='/signup' element={<Signup deviceInfo={props.deviceInfo} isMobile={isMobile} />} />
        </Routes>
    </ContentDiv>
  );

}

export default Content;