// TopLineScores.js

import React from 'react';

import styled from 'styled-components';
import ScoreCircle from './ScoreCircle';

const TopLineScoresDiv = styled.div`
  cursor: auto;
`;

const ScoreCircleAndTitleDiv = styled.div`
  display: inline-block;
  width: 6vw;
  min-width: 80px;
  margin-right: 1.5vw;
  text-align: center;
`;

const ScoreTitle = styled.h6`
  text-align: center;
`;

// return one scorecircle with a title below it
function ScoreCircleAndTitle(props) {
  const {
    score, // object with properties score, trend, title
    size
  } = props;

  return (
    <ScoreCircleAndTitleDiv>
      <ScoreCircle score={score.score} trend={score.trend} size={size} style={{ margin: "auto", marginTop: "20px", marginBottom: "20px" }} />
      <ScoreTitle>{score.title}</ScoreTitle>
    </ScoreCircleAndTitleDiv>
  )
}


function TopLineScores(props) {
  if (props && props.data && Array.isArray(props.data) && props.data.length > 4) {
    const scores = props.data;
    const compositeScoresArray = scores.filter((score) => {
      return score.title === "Composite";
    });
    // console.log("~~~~~ TopLineScores() - compositeScoresArray.length: " + compositeScoresArray.length);
    const compositeScore = compositeScoresArray[0];
    
    const mobilityScoresArray = scores.filter((score) => {
      return score.title === "Mobility";
    });
    const mobilityScore = mobilityScoresArray[0];
  
    const stabilityScoresArray = scores.filter((score) => {
      return score.title === "Stability" || score.title === "Activation";
    });
    const stabilityScore = stabilityScoresArray[0];
  
    const postureScoresArray = scores.filter((score) => {
      return score.title === "Posture";
    });
    const postureScore = postureScoresArray[0];
  
    const symmetryScoresArray = scores.filter((score) => {
      return score.title === "Symmetry";
    });
    const symmetryScore = symmetryScoresArray[0];
  
    return (
      <TopLineScoresDiv>
        <ScoreCircleAndTitle score={compositeScore} size={window.innerWidth*0.045} />
        <ScoreCircleAndTitle score={mobilityScore} size={window.innerWidth*0.045} />
        <ScoreCircleAndTitle score={stabilityScore} size={window.innerWidth*0.045} />
        <ScoreCircleAndTitle score={postureScore} size={window.innerWidth*0.045} />
        <ScoreCircleAndTitle score={symmetryScore} size={window.innerWidth*0.045} />
      </TopLineScoresDiv>
    );
  } else {
    return (
      <div />
    );
  }
}

export default TopLineScores;

