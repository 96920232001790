// import styled from 'styled-components'

import React from 'react';
import { accentColor } from '../../constants';

import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear, scaleBand } from '@visx/scale';

// TODO: update appropriate graph parts to their animated equivalents
// AnimatedAxis, // any of these can be non-animated equivalents
// AnimatedGrid,
// AnimatedLineSeries,
// AnimatedAreaSeries,

// Define the graph dimensions and margins
const width = parseInt(0.9 * 0.8 * window.innerWidth); // TODO: parseInt?
const height = parseInt(0.9 * 0.2 * window.innerWidth); // TODO: parseInt?
// const margin = { top: 20, bottom: 20, left: 10, right: 20 };
const margin = { top: 30, bottom: 30, left: 10, right: 20 };

// Then we'll create some bounds
const xMax = width - margin.left - margin.right;
const yMax = height - margin.top - margin.bottom;

// We'll make some helpers to get at the data we want
const x = d => d.daysAgo;
const y = d => +d.count;

// Finally we'll embed it all in an SVG, and then scale the graph by our data
function BarGraph(props) {

  // avoid all the calculations FAILS below if render starts before data available
  if (!props || !props.data || !props.data.length || props.data.length < 30 ) {
    return (
      <button>Huh?</button>
    );
  }

  const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: props.data.map(x),
    padding: 0.4,
  });

  const yDomainMax = Math.max(...props.data.map(y)) > 0 ? Math.max(...props.data.map(y)) : 10;
  
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    // domain: [0, Math.max(...props.data.map(y))]
    domain: [0, yDomainMax]
  });
  
  // Compose together the scale and accessor functions to get point functions
  const compose = (scale, accessor) => data => scale(accessor(data));
  const xPoint = compose(xScale, x);
  const yPoint = compose(yScale, y);
  
  return (
    <svg width={width} height={height} style={{ padding: "1vw" }}>
      <GridRows scale={yScale} width={xMax} height={yMax} stroke="#e0e0e0" />
      <AxisLeft
        left={margin.left}
        scale={yScale}
        numTicks={6}
        hideTicks={true}
        hideAxisLine={true}
        tickLabelProps={() => ({
          fill: "#888888",
          fontSize: 10,
          textAnchor: 'left',
          margin: '0 -15px 0 0'
        })}
      />
      {props.data.map((d, i) => {
        const barHeight = yMax - yPoint(d);
        return (
          <Group key={`bar-${i}`}>
            <Bar
              x={xPoint(d) + xScale.bandwidth() * 0.3} // center thin bars over labels
              y={yMax - barHeight}
              height={barHeight}
              width={xScale.bandwidth() * 0.6} // make bars thinner
              fill={accentColor}
            />
          </Group>
        );
      })}
      <AxisBottom
        top={yMax + margin.top}
        scale={xScale}
        numTicks={30}
        hideTicks={true}
        hideAxisLine={true}
        orientation="top"
        tickLabelProps={() => ({
          margin: "1vw 0 0 0",
          fill: "#888888",
          fontSize: 10,
          textAnchor: 'middle'
        })}
      />
    </svg>
  );
}

export default BarGraph;