import React from 'react';
import { accentColor } from '../../constants';
import styled from 'styled-components';

const H1 = styled.h1`
  margin-bottom: 0.4vw;
  padding-bottom: 0;
  font-size: 4.5vw;
  font-weight: 700;
  transform: scaleY(1.1);
  transform-origin: 0 0;
  color: ${accentColor};
  `;

const H2 = styled.h2`
  margin-top: -0.4vw;
  padding-top: 0;
  font-size: 1.35vw;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;
`;

const H3 = styled.h3`
  margin-top: -0.5vw;
  font-size: 1.0vw;
  color: #7a7c8d;
`;

const BigStatDiv = styled.div`
  display: inline-block;
  width: 17vw;
  margin: 0;
  padding: 1vw;
  vertical-align: text-top;
`;

function BigStat(props) {
  return (
    <BigStatDiv>
      <H1>{props.value}</H1> 
      <H2>{props.title}</H2>
      <H3>{props.subValue} {props.subtitle}</H3>
    </BigStatDiv>
  );
}


export default BigStat;