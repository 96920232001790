const kioskAssessmentCounts = [
  {
    "daysAgo": 1,
    "count": 366
  },
  {
    "daysAgo": 2,
    "count": 326
  },
  {
    "daysAgo": 3,
    "count": 198
  },
  {
    "daysAgo": 4,
    "count": 175
  },
  {
    "daysAgo": 5,
    "count": 280
  },
  {
    "daysAgo": 6,
    "count": 310
  },
  {
    "daysAgo": 7,
    "count": 250
  },
  {
    "daysAgo": 8,
    "count": 220
  },
  {
    "daysAgo": 9,
    "count": 265
  },
  {
    "daysAgo": 10,
    "count": 160
  },
  {
    "daysAgo": 11,
    "count": 370
  },
  {
    "daysAgo": 12,
    "count": 40
  },
  {
    "daysAgo": 13,
    "count": 220
  },
  {
    "daysAgo": 14,
    "count": 265
  },
  {
    "daysAgo": 15,
    "count": 150
  },
  {
    "daysAgo": 16,
    "count": 390
  },
  {
    "daysAgo": 17,
    "count": 250
  },
  {
    "daysAgo": 18,
    "count": 220
  },
  {
    "daysAgo": 19,
    "count": 265
  },
  {
    "daysAgo": 20,
    "count": 150
  },
  {
    "daysAgo": 21,
    "count": 300
  },
  {
    "daysAgo": 22,
    "count": 250
  },
  {
    "daysAgo": 23,
    "count": 225
  },
  {
    "daysAgo": 24,
    "count": 270
  },
  {
    "daysAgo": 25,
    "count": 110
  },
  {
    "daysAgo": 26,
    "count": 320
  },
  {
    "daysAgo": 27,
    "count": 250
  },
  {
    "daysAgo": 28,
    "count": 220
  },
  {
    "daysAgo": 29,
    "count": 265
  },
  {
    "daysAgo": 30,
    "count": 366
  }
];

export const kioskStatsMock =  { // this is fake data, as a fallback for now
  // "membersCount": 122,
  // "activeMembersCount": 76,
  // "membersCountChangeLastSevenDays": "+1",
  "thisWeekAssessmentsCount": 85,
  "AssessmentsCountLastWeekOnThisDay": 80,
  "last30daysAssessmentCounts": kioskAssessmentCounts
};