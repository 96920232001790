// members.js

const token = localStorage.getItem('token');
const coachId = localStorage.getItem('loggedInUser') ? localStorage.getItem('loggedInUser') : "no one logged in";

// alert('~~~~~ members service - coachId is ' + coachId);
console.log('~~~~~ members service - token is ' + token);

export async function editMemberLinking(assocId) { // initially we just revoke the linkage from athlete to coach, no editing possible
  console.log('~~~~~ members service - editMemberLinking() method invoked, coachId is ' + coachId + ' and assocId is ' + assocId);

  // So, as of 6/23/23 1720 it would appear we have a set of accesses to perform:
  // since all we know is the email or id_guid of the user to be de-associated, we would need to find the proper
  // coach_athlete_association record in order to modify it or delete it by it's id_guid
  // so that we need these steps:
  // 1. API GET all the coach_athlete_association records that have an athlete_id_guid matching our to-be-unlinked athlete
  // 2. FRONTEND search that collection for the id_guid of the (first?) record (or all the records) that have a coach_id matching our coach_id_guid
  // 3. API perform a PUT or DELETE request to app/user/[coachId]/association/[associationId]
  // 4. FRONTEND process the response to either update the local table and flash message success, OR dialog FAIL specifics

  // it would be much more convenient to just specify the athlete user we want to disconnect from by user_athlete_id_guid and DELETE:
  // 1. API perform a DELETE request to app/user/[coachId]/association/user/[athleteId]
  // 2. FRONTEND process the response to either update the local table and flash message success, OR dialog FAIL specifics
  //
  // it would be even more convenient for the API cal to be processed on the server using the coach_user from the authentication token
  // so that the API call could just be a DELETE to app/user/association/user/[athleteId]

  // NOTE: we would probably just get the coachId from localStorage instead of passing it in here...

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/association/user/' + assocId, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then(async response => { 
      let respStatus = response.status;
      if (respStatus !== 422) {
      
        console.log('~~~~~ services/members.js, editMemberLink() - response status code was ' + respStatus);
        // return await response.json(); 
      } else {
        console.log('~~~~~ services/members.js, addMemberLink() - OOPS! response status code was ' + respStatus + ' :-(');
      }
      return response;
    })  
  }
  catch (e) {
    console.log(e)
  }
}

export async function addMemberLink(memberEmail, memberName) {

  const coachId = localStorage.getItem('loggedInUser') ? localStorage.getItem('loggedInUser') : "no one logged in";
  console.log('~~~~~ members service - addMemberLink: ' + memberEmail + ', ' + memberName + ', for coachId ' + coachId);

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + coachId + '/association', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        user_id_guid: coachId, 
        athlete_user_email_string: memberEmail,
        // athlete_user_name_string: memberName, // NOTE: this not required the way we now use this method (Coach Accept)
        user_approval_boolean: true
      })
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/members.js, addMemberLink() - response status code was 200 or 201!');
      // return response.json(); 
    } else {
      console.log('~~~~~ services/members.js, addMemberLink() - OOPS! response status code was ' + response.status + ' :-(');
    }
    return response;
  })
  }
  catch (e) {
    console.log(e)
  }
}

export async function submitAthleteEmailList(athleteEmailList) {

  const coachId = localStorage.getItem('loggedInUser') ? localStorage.getItem('loggedInUser') : "no one logged in";
  console.log('~~~~~ members service - submitAthleteEmailList: ' + athleteEmailList + ', for coachId ' + coachId);

  try {
    // const response = await fetch('https://data.physmodo.net/api/v202203/app/user/' + coachId + '/association', {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + coachId + '/association', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        // user_id_guid: coachId, 
        athlete_user_email_list_string: athleteEmailList,
        // athlete_user_name_string: memberName,
        user_approval_boolean: true
      })
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/members.js, submitAthleteEmailList() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/members.js, submitAthleteEmailList() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export async function submitAthleteEmail(coachId, athleteEmail) {

  console.log('~~~~~ members service - submitAthleteEmail: ' + athleteEmail);

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + coachId + '/association', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        athlete_user_email_string: athleteEmail
      })
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/members.js, submitAthleteEmail() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/members.js, submitAthleteEmail() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export async function getAthleteSignupLink() {

  console.log('~~~~~ members service - getAthleteSignupLink()');

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + coachId + '/association/url', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
      // , 
      // body: JSON.stringify({ 
      //   athlete_user_email_string: athleteEmail
      // })
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/members.js, submitAthleteEmail() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/members.js, submitAthleteEmail() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export async function acceptCoachLink(inviteToken, athleteId, assocId) {
  console.log('~~~~~ services/members.js, acceptCoachLink() - token: *' + inviteToken + '*, athleteId: *' + athleteId + '*, assocId: ' + assocId);
  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + athleteId + '/association/' + assocId, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': inviteToken
      }, 
      body: JSON.stringify({ 
        id_guid: assocId, 
        athlete_user_approval_boolean: true
      })
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/members.js, acceptCoachLink() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/members.js, acceptCoachLink() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export function getMembers() { // this is really getMembers
  return fetch('https://data.physmodo.net/api/v202203/user/', { // we use this for StretchLab (smaller userObj)
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
  .then(data => makeMembersFromUsers(data))
}

export function getAthletes(group_id_guid) { // this is really getAthletes
  const filter_query = (group_id_guid && group_id_guid.length > 10) ? '?group_id_guid=' + group_id_guid : '';
  console.log('~~~~~ services/members, getAthletes() - group_id_guid is *' + group_id_guid + '*, and filter_query is *' + filter_query + '*');

  return fetch('https://data.physmodo.net/api/v202203/app/user/' + filter_query, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
  .then(data => makeAthletesFromUsers(data)) // this is updated (vs makeMembersFromUsers) to parse new user attributes
}

// used in History screen and Assessment Details screen(for Member name or email) 
export function getMember(memberid) {
  // return fetch('https://data.physmodo.net/api/v202203/user/', {
  return fetch('https://data.physmodo.net/api/v202203/app/user/', { // TODO: this works now, but should actually use userId & get just 1
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
    .then(data => data.json())
    .then(data => getMemberFromUsersArray(memberid, data)) // maybe not how we would want to do it? -> just get 1 user?
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max) + 1;
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toLocaleString();
}

// function makeOneMemberFromOneUser(respJson) {
//   // eventually might need this if/when we have a route that returns a single user object?
// }

function getMemberFromUsersArray(id_guid, respJson) {

  // console.log("~~~~~ getMemberFromUsersArray() - id_guid: " + id_guid);
  // console.log("~~~~~ getMemberFromUsersArray() - respJson.data[0].email_string: " + respJson.data[0].email_string);

  const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  const monthsSinceJoined = getRandomInt(24);

  const fakeMember = {
    id_guid: "unknown_user",
    email: "fake_user@websmith.us",
    lastAssessmentDate: randomAssessmentDate,
    AssessmentsCountThisMonth: 15,
    AssessmentsCountAllTime: 117
  };

  if (!id_guid || 
    id_guid.length < 32 || 
    !respJson || 
    !respJson.data ||
    !Array.isArray(respJson.data)) {
    // console.log("~~~~~ getMemberFromUsersArray() - bad data, returning fakeMember"); 
    return fakeMember;
  }

  const usersArray = respJson.data;

  // console.log("~~~~~ getMemberFromUsersArray() - usersArray: " + usersArray);

  const userObjectsMatching = usersArray.filter((user) => {
    return user.id_guid === id_guid;
  });

  // console.log("~~~~~ getMemberFromUsersArray() - userObjectsMatching: " + userObjectsMatching);
  
  if (userObjectsMatching.length === 0) {
    return fakeMember;
  }

  const userObject = userObjectsMatching[0];

  // console.log("~~~~~ getMemberFromUsersArray() - userObject.email_string: " + userObject.email_string);

  let thisMember = {
    id_guid: "unknown_user",
    email: "unknown_email",
    lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()),
    AssessmentsCountThisMonth: getRandomInt(14),
    AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14))
  };

  for (const [key, value] of Object.entries(userObject)) {
    // if (key === "id_guid" || key === "email_string") {
    //   if (key === "id_guid") {
    //     thisMember.id_guid = value;
    //   } else if (key === "email_string") {
    //     thisMember.email = value;
    //   } 
    // }

    switch (key) {
      case "id_guid":
        thisMember.id_guid = value;
        break;
      case "email_string":
        thisMember.email = value;
        break;
      case "assessment_last_datetime":
        thisMember.lastAssessmentDate = new Date(value).toLocaleString(); // not really used here, at present
        break;
      case "assessment_month_count_integer":
        thisMember.AssessmentsCountThisMonth = value; // not really used here, at present
        break;
      case "assessment_total_count_integer":
        thisMember.AssessmentsCountAllTime = value; // not really used here, at present
        break;
      default:
        // do nothing
    }
  };

  return thisMember;
}

const membersWithoutAssessments = [
'bones@physmodo.com',
'orange.robot@physmodo.com',
'joseph@physmodo.com',
'mail@stevenray.com',
'toccata6767@yahoo.com',
'steve@thebashapp.com',
'jgoodrick@physmodo.com',
'support@physmodo.com',
'nathanreyes.me@gmail.com',
'tester003@gmail.com',
'robyn@robynmenter.com',
'physmodo@physmodo.com',
'sgoodrickjr@gmail.com',
'cred-test@physmodo.com',
'cred-test2@physmodo.com',
'trainfasst@gmail.com',
'tester006@physmodo.com',
'tester004@physmodo.com',
'test002@physmodo.com',
'dannyjeee@yahoo.com',
'mike3@physmodo.com',
'mike4',
'mike4@physmodo.com',
'mike5@physmodo.com',
'mike6@physmodo.com',
'mike7@physmodo.com',
'mike8@physmodo.com',
'mike9@physmodo.com',
'bob7@physmodo.com',
'bob2@physmodo.com',
'adlsjkfa;lsdj@;alksdjf;al.com',
'example1234@some.client',
'asdfasdfas@kjhsdkfj.lkjsd',
'chantellegroenewoud@gmail.com',
'joseph@pearsports.com',
'weegesantoro@physmodo.com'
]

// will parse users to generate members array for StretchLab-style minimal userObject
function makeMembersFromUsers(respJson) { // { data: [ {user}, {user}, ..., {user} ] }

  const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  
  let fakeMember = {
    id_guid: "unknown_user",
    email: "fake_user@websmith.us",
    lastAssessmentDate: randomAssessmentDate,
    AssessmentsCountThisMonth: 15,
    AssessmentsCountAllTime: 117
  }
  
  let memberStats = { };

  let allMembersArray = [fakeMember];

  if (respJson && respJson.data && Array.isArray(respJson.data) && respJson.data.length > 0) {
    
    allMembersArray.pop(); // there are members, remove the fake user record

    respJson.data.forEach((user) => {
      const monthsSinceJoined = getRandomInt(24);

      let thisMember = {
        id_guid: "unknown_user",
        email: "unknown_email",
        lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()),
        AssessmentsCountThisMonth: getRandomInt(14),
        AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14))
      };

      for (const [key, value] of Object.entries(user)) {
        // console.log("~~~~~ makeMembersFromUsers() - key = " + key);
        if (key === "id_guid" || key === "user_id_guid") {
          // console.log("~~~~~ makeMembersFromUsers() - id_guid = " + value);
          thisMember.id_guid = value;
        } else if (key === "email_string") {
          // console.log("~~~~~ makeMembersFromUsers() - email_string = " + value);
          thisMember.email = value;
        } else if (key === "assessment_last_datetime" || key === "assessment_last_datatime") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_last_datetime = " + value);
          thisMember.lastAssessmentDate = new Date(value).toLocaleString();
        } else if (key === "assessment_total_count_integer") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_total_count_integer = " + value);
          thisMember.AssessmentsCountAllTime = value;
        } else if (key === "assessment_month_count_integer") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_month_count_integer = " + value);
          thisMember.AssessmentsCountThisMonth = value;
        }
      };

      // console.log("~~~~~ makeMembersFromUsers() - thisMember.lastAssessmentDate: " + thisMember.lastAssessmentDate);

      if (!membersWithoutAssessments.includes(thisMember.email)) {
        allMembersArray.push(thisMember);

        if (thisMember.email.includes("stretchlab")) {
          console.log("~~~~~ " + thisMember.email);
        }
      }
    });

    // console.log("~~~~~ makeMembersFromUsers() - respJson.statistics: " + respJson.statistics);
    // console.log("~~~~~ makeMembersFromUsers() - respJson.data: " + respJson.data);
    
    memberStats = {
      totalUsers: respJson.pagination && respJson.pagination.rows_total ?
       respJson.pagination.rows_total : 777,
      activeUsers: respJson.statistics && respJson.statistics.count_active_integer ?
       respJson.statistics.count_active_integer : 77,
      newUsers: respJson.statistics && respJson.statistics.count_this_week_integer ?
       respJson.statistics.count_this_week_integer :7,
      thisWeekScans: 84,  // TODO: move to KioskStats?
      lastWeekScans: 81   // TODO: move to KioskStats?
    }
  } 
    
  return {
    members: allMembersArray,
    memberStats: memberStats
  }
}

// will parse users to generate athletes array with Northside-style fleshed-out athlete attributes
function makeAthletesFromUsers(respJson) { // { data: [ {user}, {user}, ..., {user} ] }

  const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  
  let fakeAthlete = {
    id_guid:"unknown_user",
    email_string: "athlete@physmodo.com",
    full_name_string: "Athlete Name",
    user_associaion_user_approval_boolean: true,
    user_association_athlete_user_approval_boolean: true,
    user_association_athlete_user_name_string: "Athlete Name",
    first_name_string: null,
    last_name_string: null,
    enterprise_assessment_total_count_integer: null,
    enterprise_assessment_last_month_count_integer: null,
    enterprise_assessment_last_datetime: null,
    athlete_assessment_total_count_integer: null,
    athlete_assessment_last_month_count_integer: null,
    athlete_assessment_last_datetime: null,
    assessment_total_count_integer: null,
    assessment_last_month_count_integer: null,
    assessment_last_datetime: null,
    group_list_string: "fakegroup0, fakegroup1, fakegroup2",
    program_list_string: "fakeprogram0, fakeprogram1, fakeprogram2",
    program_count_integer: "100",
    email: "fake_user@websmith.us",
    lastAssessmentDate: randomAssessmentDate,
    AssessmentsCountThisMonth: 15,
    AssessmentsCountAllTime: 117
  }
  
  let athleteStats = { };

  // let allAthletesArray = [fakeAthlete];
  let allAthletesArray = [];

  if (respJson && respJson.data && Array.isArray(respJson.data) && respJson.data.length > 0) {
    
    // console.log('~~~~~ services/members, makeAthletesFromUsers() - respJson.data.length is ' + respJson.data.length);
    // allAthletesArray.pop(); // there are athletes, remove the fake user record

    respJson.data.forEach((user) => {
      const monthsSinceJoined = getRandomInt(24);
      const pendingAthleteAccept = !user.user_association_athlete_user_approval_boolean && user.user_association_user_approval_boolean;
      const pendingCoachAccept = user.user_association_athlete_user_approval_boolean && !user.user_association_user_approval_boolean;
      const pendingBothAccept = !user.user_association_athlete_user_approval_boolean && !user.user_association_user_approval_boolean;

      let thisAthlete = {
        id_guid: "unknown_user",
        email_string: "unknown_email",
        full_name_string: "",
        user_associaion_user_approval_boolean: true,
        user_association_athlete_user_approval_boolean: true,
        user_association_athlete_user_name_string: "Unknown Name",
        first_name_string: null,
        last_name_string: null,
        enterprise_assessment_total_count_integer: null,
        enterprise_assessment_last_month_count_integer: null,
        enterprise_assessment_last_datetime: null,
        athlete_assessment_total_count_integer: null,
        athlete_assessment_last_month_count_integer: null,
        athlete_assessment_last_datetime: null,
        assessment_total_count_integer: null,
        assessment_last_month_count_integer: null,
        assessment_last_datetime: null,
        group_list_string: "no groups yet",
        program_list_string: "no programs yet",
        program_count_integer: "100",
        email: "unknown_email",
        lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()), // gets replaced with actual data from API call
        AssessmentsCountThisMonth: getRandomInt(14), // gets replaced with actual data from API call
        AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14)) // gets replaced with actual data from API call
      };

      for (const [key, value] of Object.entries(user)) {
        // console.log("~~~~~ makeAthletesFromUsers() - key = " + key);
        if (key === "id_guid" || key === "user_id_guid") {
          // console.log("~~~~~ makeAthletesFromUsers() - id_guid = " + value);
          thisAthlete.id_guid = value;
        } else if (key === "email_string") {
          // console.log("~~~~~ makeAthletesFromUsers() - email_string = " + value);
          thisAthlete.email = value;
          thisAthlete.email_string = value;
        } else if (key === "user_association_athlete_user_name_string") {
          // console.log("~~~~~ makeAthletesFromUsers() - user_association_athlete_user_name_string = " + value);
          thisAthlete.user_association_athlete_user_name_string = value; 
          if (value) {
            thisAthlete.full_name_string = value;
          }

        } else if (key === "first_name_string") {
          // console.log("~~~~~ makeAthletesFromUsers() - first_name_string = " + value);
          thisAthlete.first_name_string = value; 
          if (value) {
            thisAthlete.full_name_string = value;
          } 
          
        } else if (key === "last_name_string") {
          // console.log("~~~~~ makeAthletesFromUsers() - last_name_string = " + value);
          thisAthlete.last_name_string = value; 
          if (value && thisAthlete.full_name_string === thisAthlete.first_name_string) {
            thisAthlete.full_name_string = thisAthlete.first_name_string + ' ' + value;
          } 

        } else if (key === "athlete_assessment_last_datetime") { // athlete app assessment, could be Northside...   
          // thisAthlete.lastAssessmentDate = pendingAthleteAccept ? "pending_athlete" : 
          // pendingCoachAccept ? "pending_coach" : new Date(value).toLocaleString();
          thisAthlete.lastAssessmentDate = pendingAthleteAccept ? "Pending Athlete Accept" : 
          pendingCoachAccept ? "Pending Coach Accept" : new Date(value).toLocaleString();
        } else if (key === "enterprise_assessment_last_datetime") { // enterprise last datetime
          thisAthlete.lastAssessmentDate = pendingAthleteAccept ? "Pending Athlete Accept" : 
          pendingCoachAccept ? "Pending Coach Accept" : new Date(value).toLocaleString();

        } else if (key === "assessment_last_datetime" || key === "assessment_last_datatime") { // composite last datetime
          thisAthlete.lastAssessmentDate = pendingAthleteAccept ? "Pending Athlete Accept" : 
          pendingCoachAccept ? "Pending Coach Accept" : new Date(value).toLocaleString();
        
        } else if (key === "athlete_assessment_total_count_integer") { // athlete app total count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - athlete_assessment_total_count_integer = " + value);
          thisAthlete.athlete_assessment_total_count_integer = value;
          thisAthlete.AssessmentsCountAllTime = value;        
        
        } else if (key === "enterprise_assessment_total_count_integer") { // enterprise app total count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - enterprise_assessment_total_count_integer = " + value);
          thisAthlete.enterprise_assessment_total_count_integer = value;
          thisAthlete.AssessmentsCountAllTime = value;
        
        } else if (key === "assessment_total_count_integer") { // composite total count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - assessment_total_count_integer = " + value);
          thisAthlete.assessment_total_count_integer = value;
          thisAthlete.AssessmentsCountAllTime = value;
        
        } else if (key === "athlete_assessment_last_month_count_integer") { // athlete app last 30 days count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - athlete_assessment_last_month_count_integer = " + value);
          thisAthlete.athlete_assessment_last_month_count_integer = value;
          thisAthlete.AssessmentsCountThisMonth = value;        

        } else if (key === "enterprise_assessment_last_month_count_integer") { //  enterprise app last 30 days count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - enterprise_assessment_last_month_count_integer = " + value);
          thisAthlete.enterprise_assessment_last_month_count_integer = value;
          thisAthlete.AssessmentsCountThisMonth = value;        
        
        } else if (key === "assessment_last_month_count_integer") { // composite last 30 days count
          // console.log("~~~~~ services/members, makeAthletesFromUsers() - assessment_last_month_count_integer = " + value);
          thisAthlete.AssessmentsCountThisMonth = value;
        } else {
          thisAthlete[key] = value;
        }
      };

      // if (typeof user.user_association_user_approval_boolean === undefined || user.user_association_user_approval_boolean) { // only show this athlete if coach has invited them
      //   // if (!user.email_string.split('utk').includes('.edu') || coachId !== 'a9a7c1a1-9f39-48b4-aedc-dde17b7ed999') { // remove jrumley1@@vols.utk.edu

      //   if (user.email_string !== 'jrumley1@@vols.utk.edu' || coachId !== 'a9a7c1a1-9f39-48b4-aedc-dde17b7ed999') { // remove jrumley1@@vols.utk.edu
      //     allAthletesArray.push(thisAthlete);
      //   }
      // }

      // [default] rule to show athlete on coaches list: neither bool is false, at least one is true
      let noReject = user.user_association_athlete_user_approval_boolean !== false &&
        user.user_association_user_approval_boolean !== false;
      let someAccept = user.user_association_athlete_user_approval_boolean === true ||
      user.user_association_user_approval_boolean === true;

      // if (!pendingBothAccept) {
      if (someAccept && noReject) {
        if (user.email_string !== 'jrumley1@@vols.utk.edu' || coachId !== 'a9a7c1a1-9f39-48b4-aedc-dde17b7ed999') { // remove jrumley1@@vols.utk.edu
          allAthletesArray.push(thisAthlete);
        }
      }

    });

    athleteStats = {
      // totalUsers: respJson.pagination && respJson.pagination.rows_total ?
      //  respJson.pagination.rows_total : 0,
      totalUsers: respJson.statistics && respJson.statistics.count_total_integer ?
        respJson.statistics.count_total_integer : 0,
      activeUsers: respJson.statistics && respJson.statistics.count_active_integer ?
       respJson.statistics.count_active_integer : 0,
      newUsers: respJson.statistics && respJson.statistics.count_this_week_integer ?
       respJson.statistics.count_this_week_integer :0,
      thisWeekScans: 84,  // TODO: move to KioskStats?
      lastWeekScans: 81   // TODO: move to KioskStats?
    }
  } 
    
  return {
    items: allAthletesArray,
    itemStats: athleteStats
  }
}
