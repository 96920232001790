// components/styled/OrderedItemList.js

// import React from 'react';
import React, { useState, useRef, useEffect, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import {
  DndContext,
  closestCenter
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { SortableItem } from './SortableItem';
import styled from 'styled-components';
import { RedSpan, Thumbnail, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
// import { iconSrc, colors } from '../../constants';

import { getAthletes, getGroups, getMovements, getExercises, getPrograms } from '../../services'; // TODO: improve???
// import { getItems } from '../../services';

function OrderedItemList(props) {

  // const [items, setItems] = useState(props.array); // this should be an array with no indexInt attributes
  const [items, setItems] = useState(props.array.map((ex, index) => ({ ...ex, indexInt: index + 1 })));

  const itemIds = useMemo(() => items.map((item) => item.indexInt), [items]); // itemIds is an array of Ints

  const exerciseArray = items.map(({indexInt, ...keepAttrs}) => keepAttrs); // exerciseArray loses indexInt attribute
  let orderedArray = []
  let outputArray = [];

  console.log('~~~~~ OrderedItemList() - items.length is *' + items.length + '*');
  console.log(items);
  // console.log('~~~~~ OrderedItemList() - indexIntArray.length is *' + indexIntArray.length + '*');
  // console.log(indexIntArray);
  console.log('~~~~~ OrderedItemList() - itemIds.length is *' + itemIds.length + '*');
  console.log(itemIds);
  console.log('~~~~~ OrderedItemList() - exerciseArray.length is *' + exerciseArray.length + '*');
  console.log(exerciseArray);


  // const itemIds = [];
  // const [tableData, setTableData] = useState(props.workouts);
  // const [tableData, setTableData] = useState(fakeItemsArray);
  const [loading, setLoading] = useState(false);
  // const [firstSortDone, setFirstSortDone] = useState(false);
  const mounted = useRef(true);
  // const onWorkoutClick = props.onWorkoutClick;
  const active = true; // use props.active?
  const mobile = false; // use props.mobile?
  // const updateExerciseArray = props.updateExerciseArray;
  // let itemsEmpty = true;

  // if (itemsEmpty && props.array && props.array.length > 0) { 
  //   setItems(props.array);
  //   itemsEmpty = false; 
  // }
  
  let fetchingNow = false;

  // const ListBody = ({ 
  //   tableData, 
  //   onRowClick, 
  //   loading
  // }) => {
  
  //   const noItemsMsg = "There are no Exercises in this Workout yet...";
  //   let rowIndex = 0;
    
  //   return (
  //     <>
  //       <div style={{ display: loading ? "flex" : "none", flexDirection: "row" }}>
  //         <div style={{ width: "100%", padding: "20px" }}>
  //           <RedSpan>Loading Exercises...</RedSpan>
  //         </div>
  //       </div>
  //       <div style={{ display: (!loading  && tableData && tableData.length === 0) ? "flex" : "none", flexDirection: "row" }}>
  //         <div style={{ width: "100%", padding: "20px" }}>
  //           <RedSpan>{noItemsMsg}</RedSpan>
  //         </div>
  //       </div>
  //       <ScrollingListContainer>
  //         <SortableContext
  //           items={itemIds}
  //           strategy={verticalListSortingStrategy}
  //         >
  //           {tableData.map((data) => {
  //             const id_guid = data.exercise_id_guid; // TODO: make this change based on itemType?
  //             const name = data.name_string;
  //             const note = data.note_string;
  //             const tnail = data.thumbnail_url_string;
  //             const indexInt = data.indexInt;
  //             rowIndex++;
  //             console.log('~~~~~ OrderedItemList.js, TableBody() - data.exercise_id_guid is *' + id_guid + '* and rowIndex is *' + rowIndex + '*'); //  id={id_guid} <- from down there in ListContentRow... id="ListContentRow" 
  //             // TODO: give outer div below a maxHeight and have overflow scroll vertically if required...
  //             return(
  //               // <ListContentRow data-name={name} id={id_guid + '_' + rowIndex} style={{ display: loading ? "none" : "flex" }} key={id_guid + '_' + rowIndex} onClick={(e, id_guid) => onRowClick(e, id_guid)}>
  //               //   <ListContentBox className="ListContentBox" id="ListContentBox">
  //               //     <div className="ExerciseMetaDiv" style={{ width: "65%", minHeight: "8vh", textAlign: "left" }}>
  //               //       Exercise Name: <b>{name}</b><br />
  //               //       Exercise Note: <b>{note}</b><br />
  //               //     </div>
  //               //     <div className="ExerciseThumbnailDiv" data-id="tnail" style={{ width: "25%", minHeight: "8vh" }}>
  //               //       <Thumbnail src={tnail}  style={{ height: "80px", marginTop: "0px" }}/>
  //               //     </div>
  //               //   </ListContentBox>
  //               // </ListContentRow>

  //             )
  //           })}
  //         </SortableContext>
  //       </ScrollingListContainer>
  //     </>
  //   );
  // };

  // useEffect(() => {
  //   if(fetchingNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
  //     console.log('~~~~~ OrderedItemList, OrderedItemList() - inside useEffect, fetchingNow true, skipping fetch!');
  //     return;
  //   }
  //   fetchingNow = true; // Now there is a fetch in progress
  //   setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
  //   mounted.current = true;

    // console.log('~~~~~ OrderedItemList, OrderedItemList() - inside useEffect, proceeding with getPrograms()');
    // getPrograms() // should there be a filter option? maybe public or not?
    // .then(itemsResp => {
    //   if(mounted.current) {
    //     let items = itemsResp.data;
    //     setItems(items);
    //     let sortedItems = items;
    //     if ([...items].length > 0 && !firstSortDone) {
    //       sortedItems = initialSort(items);
    //       setFirstSortDone(true);
    //     }
    //     setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
    //     // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
    //     fetchingNow = false; // Now we have finished with the current fetch
    //     setLoading(false);
    //     console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Programs were returned');
    //   }
    // })

  // }, [items])

  // setTableData(props.workouts);

  return (
    <DndContext
    collisionDetection={closestCenter}
    onDragEnd={handleDragEnd}
    >
      <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
        <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px" }}>
          Drag Exercises to change their order.
        </AreaTitle>

          {/* <ListBody 
            tableData={items} 
            onRowClick={() => alert('onRowClick()')} 
            loading={loading}
          /> */}
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          {items.map(item => <SortableItem key={item.exercise_id_guid.toString()} id={item.indexInt} item={item} /> )}
        </SortableContext>
      </div>
    </DndContext>
  );

  function handleDragEnd(event) {
    const eCTgt = event.activatorEvent.srcElement; // this element is a div child of <Card />
    const cardTgtId = event.activatorEvent.srcElement.parentElement.id; // this returns the id of the <Card /> element
    
    const {active, over} = event;
    if (active.id === over.id) {
      return;
    }
    setItems((items) => {
      
      const oldIndex = items.findIndex((item) => item.indexInt === active.id);
      const newIndex = items.findIndex((item) => item.indexInt === over.id);
      orderedArray = arrayMove( items, oldIndex, newIndex);
      return orderedArray;
    });

    setTimeout(() => {
      console.log('~~~~~ OrderedItemList() - after drag, orderedArray[0].name_string is *' + orderedArray[0].name_string + '*, and orderedArray[0].indexInt is *' + orderedArray[0].indexInt + '*');
      console.log('~~~~~ OrderedItemList() - orderedArray.length is *' + orderedArray.length + '*');
      console.log(orderedArray);
      outputArray = orderedArray.map(({indexInt, ...keepAttrs}) => keepAttrs); // orderedArray loses indexInt attribute
      console.log(outputArray);
      props.updateArray(outputArray);
    }, 500);
  }

}

export default OrderedItemList;