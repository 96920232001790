// kiosks.js

import { kioskStatsMock } from './mocks';

const token = localStorage.getItem('token');


// export async function getKioskStats() {
//   try {
//     const response = await fetch('https://data.physmodo.net/api/v202203/assessment?limit=1', {
//       method: 'GET',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token
//       }})
//     console.log("~~~~~ getKioskStats() - response.status: " + response.status);
//     if (response.status !== 200) { return kioskStatsMock; }
//     return response.body;
//   }
//   catch (e) {
//     console.log(e)
//     return kioskStatsMock;
//   }
// }


export function getKioskStats() {
  return fetch('https://data.physmodo.net/api/v202203/assessment?limit=1', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
  .then(data => makeStatsFromResponse(data))
}


function makeDailyAssessmentTotals(last30daysAssessmentCountsArray) {
  const respCounts = last30daysAssessmentCountsArray;
  let countsArray = [];
  let daysAgoIndex = 0;

  respCounts.sort(function(a,b){
    return new Date(b.datetime) - new Date(a.datetime);
  });

  respCounts.forEach((dayCountObj) => {
    let newObj = {
      "count": dayCountObj.count_integer,
      "daysAgo": daysAgoIndex + 1
    }
    if (countsArray.length < 30) {
      countsArray.push(newObj);
      daysAgoIndex += 1;
    }
  });

  return countsArray;
}

function makeStatsFromResponse(respJson) {
  if (!respJson || 
    !respJson.statistics ||
    !Array.isArray(respJson.statistics.count_daily_array)) {
      return kioskStatsMock;
  }
  
  return {
    "thisWeekAssessmentsCount": respJson.statistics.count_this_week_integer,
    "lastWeekAssessmentsCount": respJson.statistics.count_last_week_integer,
    "last30daysAssessmentCounts": makeDailyAssessmentTotals(respJson.statistics.count_daily_array)
  }
}

/*
respObj = {
  "data": [
    { oneAssessmentJSON }
  ],
  "pagination": {
    "page_current": 1,
		"page_total": 124,
		"rows_current": 0,
		"rows_total": 124 
  },
  "statistics": {
    "count_this_week_integer": 1,
		"count_last_week_integer": 1,
		"count_daily_array": [
			{
				"count_integer": 65,
				"datetime": "2022-05-22T14:43:26.733Z"
			},
      {
				"count_integer": 63,
				"datetime": "2022-05-23T14:43:26.733Z"
			},
			{
				"count_integer": 9,
				"datetime": "2022-05-24T14:43:26.733Z"
			},
			{
				"count_integer": 62,
				"datetime": "2022-05-25T14:43:26.733Z"
			},
			{
				"count_integer": 6,
				"datetime": "2022-05-26T14:43:26.733Z"
			},
			{
				"count_integer": 57,
				"datetime": "2022-05-27T14:43:26.733Z"
			},
			{
				"count_integer": 20,
				"datetime": "2022-05-28T14:43:26.733Z"
			},
			{
				"count_integer": 51,
				"datetime": "2022-05-29T14:43:26.733Z"
			},
			{
				"count_integer": 57,
				"datetime": "2022-05-30T14:43:26.733Z"
			},
			{
				"count_integer": 89,
				"datetime": "2022-05-31T14:43:26.733Z"
			},
			{
				"count_integer": 67,
				"datetime": "2022-06-01T14:43:26.733Z"
			},
			{
				"count_integer": 47,
				"datetime": "2022-06-02T14:43:26.733Z"
			},
			{
				"count_integer": 46,
				"datetime": "2022-06-03T14:43:26.733Z"
			},
			{
				"count_integer": 32,
				"datetime": "2022-06-04T14:43:26.733Z"
			},
			{
				"count_integer": 47,
				"datetime": "2022-06-05T14:43:26.733Z"
			},
			{
				"count_integer": 8,
				"datetime": "2022-06-06T14:43:26.733Z"
			},
			{
				"count_integer": 20,
				"datetime": "2022-06-07T14:43:26.733Z"
			},
			{
				"count_integer": 38,
				"datetime": "2022-06-08T14:43:26.733Z"
			},
			{
				"count_integer": 80,
				"datetime": "2022-06-09T14:43:26.733Z"
			},
			{
				"count_integer": 17,
				"datetime": "2022-06-10T14:43:26.733Z"
			},
			{
				"count_integer": 42,
				"datetime": "2022-06-11T14:43:26.733Z"
			},
			{
				"count_integer": 23,
				"datetime": "2022-06-12T14:43:26.733Z"
			},
			{
				"count_integer": 34,
				"datetime": "2022-06-13T14:43:26.733Z"
			},
			{
				"count_integer": 94,
				"datetime": "2022-06-14T14:43:26.733Z"
			},
			{
				"count_integer": 8,
				"datetime": "2022-06-15T14:43:26.733Z"
			},
			{
				"count_integer": 94,
				"datetime": "2022-06-16T14:43:26.733Z"
			},
			{
				"count_integer": 28,
				"datetime": "2022-06-17T14:43:26.733Z"
			},
			{
				"count_integer": 82,
				"datetime": "2022-06-18T14:43:26.733Z"
			},
			{
				"count_integer": 60,
				"datetime": "2022-06-19T14:43:26.733Z"
			},
			{
				"count_integer": 25,
				"datetime": "2022-06-20T14:43:26.733Z"
			},
			{
				"count_integer": 5,
				"datetime": "2022-06-21T14:43:26.733Z"
			}
		]
  }
}
*/


