// components/styled/Exercise.js

import React from 'react';
import { useState, useCallback, useRef, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan, Thumbnail, AreaTitle, MouseOverVideoDiv, ContentBox } from './title';
// import { iconSrc, colors } from '../../constants';
import { capitalize } from '../../helpers';

import { getExercise, getMovement } from '../../services';



function Exercise(props) {

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);

  const mounted = useRef(true);
  // const onExerciseClick = props.onExerciseClick;
  const isMobile = props.isMobile;
  const active = props.active;
  const type = props.type;
  const id_guid = props.id_guid;
  const config = props.config;
  const name = props.itemObj.title_string; // what is it for type === movement?
  const note = props.itemObj.exercise_note_string; // what is it for type === movement?
  const duration = props.itemObj.duration_seconds_integer;
  const repetitions = props.itemObj.repetition_integer;
  const description = props.itemObj.description_text;
  const reason = description && description.length > 5 ? description.split(': ')[1] : "";
  const thumbnail_url = props.itemObj.thumbnail_url_string;
  const video_url = props.itemObj.video_url_string; 
  const typeTitle = props.config.includes("W") ? "Exercise" : "Movement"; // equivalent to props.config === "WE"

  const hasDuration = duration && duration > 0;
  const hasRepetitions = repetitions && repetitions > 0;
  const hasNotes = note && note.length > 0;
  const hasReason = reason && reason.length > 0;

  const videoRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    videoRef.current.play();
  }, []);

  const handleMouseLeave = useCallback(() => {
    videoRef.current.pause();
    if (isMobile && config === "WE") { // 'WE' means the Exercise component should be in view
      setTimeout(() => {
        const element = document.getElementById("content_wrapper");
        console.log('~~~~~ Exercise.js, Exercise() - content_wrapper element is *' + element + '*');
        element.scrollIntoView();
      }, 500)
    }
  }, []);

  // const handleVideoPause = useCallback(() => {
  //   alert('handleVideoPause - via webkitendfullscreen');
  //   if (isMobile && config === "WE") { // 'WE' means the Exercise component should be in view
  //     setTimeout(() => {
  //       const element = document.getElementById("content_wrapper"); // top of the Workouts component
  //       element.scrollIntoView();
  //     }, 500)
  //   }
  // }, []);

  const handleVideoExitFullscreen = useCallback(() => {
    // alert('handleVideoExitFullscreen');
    // if (isMobile && config === "WE") { // 'WE' means the Exercise component should be in view
    if (isMobile) {
      // alert('handleVideoExitFullscreen - inside isMobile');
      setTimeout(() => {

        const element = document.getElementById("content_wrapper"); // top of the Workouts component
        if (element !== undefined && element !== null) {
          element.scrollIntoView();
        } else {
          alert('handleVideoExitFullscreen - content_wrapper was not found');
        }
      }, 500)
    }
  }, []);

  if (isMobile && config === "WE") { // 'WE' means the Exercise component should be in view
    var vid = document.getElementById("exercise_video");

    if (vid !== undefined && vid !== null) {
      vid.addEventListener('webkitendfullscreen', function (e) { 
        handleVideoExitFullscreen();
      });
    } else {
      alert('document.getElementById("exercise_video") found nothing!');
      console.log('~~~~~ Exercise.js, Exercise() - document.getElementById("exercise_video") found nothing!');
    }
  }


  useEffect(() => {
    if (isMobile && config === "WE") { // 'WE' means the Exercise component should be in view
      const element = document.getElementById("version_p");
      console.log('~~~~~ Exercise.js, Exercise() - element is *' + element + '*');
      element.scrollIntoView();
    }
    console.log("~~~~~ Exercise() loaded");
  });


  
  return (
    <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
      <AreaTitle style={{ display: "inline-block", marginBottom: "0px", fontSize: isMobile ? "26px" : "13px" }}>{typeTitle}: <b>{name}</b></AreaTitle><br />
      {/* <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px", color: "white" }}>.</AreaTitle> */}
      <ContentBox isMobile={isMobile} style={{ marginTop: "5px" }}>
        <div style={{ width: "100%", minHeight: "8vh", textAlign: "left", fontSize: isMobile ? "26px" : "13px" }}>
          {/* Name: <b>{name}</b><br /> */}
          <div style={{ width: "50%", float: "left", textAlign: "left" }}>
            {hasDuration && <>Duration, in seconds: <b>{duration}</b><br /></>}
            {hasNotes && <>Notes: <b>{note}</b><br /></>}
          </div>
          <div style={{ width: "50%", float: "right", textAlign: "right" }}> 
            {hasRepetitions && <>Repetitions: <b>{repetitions}</b><br /></>}
          </div>
          {/* {hasReason && <>Should help with: <b>{reason}</b><br /></>} */}
        </div>
        {/* <div style={{ width: "25%", minHeight: "8vh" }}>
          <Thumbnail src={thumbnail_url}  style={{ height: "120px", marginTop: "0px" }}/>
        </div> */}
      </ContentBox>
      <RedSpan style={{ fontSize: isMobile ? "26px" : "13px"}}>mouse over the video below to play it, mouse off of it to pause</RedSpan>
      <MouseOverVideoDiv style={{ width: "80%", marginLeft: "auto", marginRight: "auto", background: "white" }}>
        <video
          id="exercise_video"
          poster={thumbnail_url}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          // onpause={handleVideoPause}
          ref={videoRef}
          src={video_url}
          type="video/mp4"
          loop
          className="w-100"
          style={{ display: (video_url && video_url.length > 5) ? "flex" : "none" }}
        ></video>
      </MouseOverVideoDiv>
    </div>
  );
}

export default Exercise;