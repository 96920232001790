import React from 'react';
import styled from 'styled-components'

import { Title } from './title';
import ScoreDisplay from './ScoreDisplay';

const TableTitle = styled(Title)`
  letter-spacing: 0.15vw;
  padding: 0;
  margin: 0;
  margin-top: 2vw;
  margin-bottom: 1.5vw;
  font-size: 1.0vw;
`;

const ScoresContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
`;

function SubDomainScoresTable(props) {
  const {
    data,       /* a scores object */
    domain      /* "mobility" | "stability" | "posture" | "symmetry" */
  } = props;

  var domainData = data.filter((score) => {
    return score.domain === domain;
  });

  return (
    <>
      <TableTitle className="tabletitle">SUBDOMAINS</TableTitle>
      <ScoresContainerDiv>
        {domainData.map(data => {
          return (<ScoreDisplay data={data} key={"score-display-" + data.id}/>)
        })}
      </ScoresContainerDiv>
    </>
  );
}

export default SubDomainScoresTable;
