// models/movement.js

import { iconSrc, colors } from '../constants';

const displayFields = [
  { // title
    type: "text",                           // could be "text", "textarea", "association", "image"?, "video"?
    displayName: "Title",                   // any string up to a maxLength of 20 characters
    placeholder: "Enter the title of the Movement",                        // a string that will show in the text input until something is entered there
    accessor: "title_string",               // attribute name when item delivered from API
    default: "",                            // value of this attribute for a brand new 'blank' item
    maxChars: "20",                         // an integer as a string, if we are going to try to limit this...
    minChars: "2",                          // an integer as a string, if we are going to try to limit this, or use as empty check
    pickListInfo: "",                       // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: true                          // should this field be submitted to the api for a create or update access?
  },
  { // subtitle
    type: "text",
    displayName: "Subtitle",
    placeholder: "Enter a subtitle, if desired", 
    accessor: "subtitle_string",
    default: "",
    maxChars: "20",
    minChars: "2",
    pickListInfo: "",
    required: true
  },
  { // video
    type: "video",
    displayName: "Video (URL) [mouseover video to play, once URL entered]",
    placeholder: "Enter something like https://ourdomain.com/videos/move_1.mp4",
    accessor: "video_url_string",
    default: iconSrc.movements,             // TODO: put an asset in src/public to show when no url provided
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: true 
  },
  { // thumbnail
    type: "image",
    displayName: "Thumbnail (URL)",
    placeholder: "Enter something like https://ordomain.com/thumbnails/move_1.png",
    accessor: "thumbnail_url_string",
    default: iconSrc.movements_active,      // TODO: put an asset in src/public to show when no url provided
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: true 
  },
  { // tags
    type: "text",
    displayName: "Tags",
    placeholder: "Enter tags, separated by commas",
    accessor: "tag_list_string",
    default: "",
    maxChars: "64",
    minChars: "2",
    pickListInfo: "", 
    required: true
  },
  { // id
    type: "hidden",
    displayName: "ID",
    accessor: "id_guid",
    default: "",                            // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-id_guid")
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  },
  { // user_id
    type: "hidden",
    displayName: "User ID",
    accessor: "user_id_guid",               // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-user_id_guid")
    default: "",                        
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  },
  { // movement_id
    type: "hidden",
    displayName: "Movement ID",
    accessor: "movement_id_guid",           // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-movement_id_guid")
    default: "",
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  }
];

const listColumns = [ // the columns list for the main table for this itemType
  { label: "Movement Title", accessor: "title_string", align: "left", type: "text", color: colors.darkGreyText, sortable: true, minWidth: "150px" },
  { label: "Thumbnail", accessor: "thumbnail_url_string", align: "left", type: "tnail", color: colors.darkGreyText, sortable: false, minWidth: "100px", maxWidth: "100px" },
  { label: "Public", accessor: "public_boolean", align: "center", type: "check", color: colors.lightGreyText, sortable: true, title: "This Movement is Public", iconSrc: iconSrc.checked },
  { label: "Remove", accessor: "removeLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Delete this Movement, remove it from your lists", iconSrc: iconSrc.remove_active }
];

const attributes = [ // these are the attributes for a movement
  "id_guid",
  "user_id_guid",
  "movement_id_guid",
  "video_url_string",
  "thumbnail_url_string",     // list column 2 -> show the image from the string URL as 20x30px image (rollover/hover larger?)
  "title_string",             // list column 1 -> show the string as HTML text
  "subtitle_string",
  "tag_list_string",
  "public_boolean",           // list column 3 -> show a "true" boolean value with a checkmark icon, or blank for false
  "created_datetime",
  "updated_datetime"
];

/* // example of a movement object retrieved from the server via an api request:
		{
			"id_guid": "3b6a68e9-7b02-4200-b42f-b4b9152d25cc",
			"created_datetime": "2023-06-19T00:27:37.422Z",
			"updated_datetime": "2023-06-19T00:27:37.422Z",
			"user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f21f2d89dff656d83854846176665aff63579023/137.mp4",
			"thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
			"title_string": "Lying Knee Grabs",
			"subtitle_string": null,
			"public_boolean": true,
			"movement_id_guid": "3b6a68e9-7b02-4200-b42f-b4b9152d25cc",
			"tag_list_string": "Lying, Knee, Grabs"
		},
*/

const defaultSort = {
  field: "title_string",
  order: "asc"
}

const strings = {
  noItemsMsg: "You have no Movements yet - create some! (or talk to an Admin who can)",
  noFilteredItemsMsg: "",
  itemClickEnabledBooleanAccessor: "",
  itemClickDisabledMessage: ""
}

export default {
  type: "movement",
  attributes,
  listColumns,
  displayFields,
  defaultSort,
  strings
}