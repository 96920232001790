// Login.js

import React, { useState } from "react";
import packageJson from '../../package.json';
import "./Login.css";
import PropTypes from 'prop-types';

import { loginUser, forgotPassword } from '../services';

import { storageAvailable } from '../helpers';
import { logoSrc } from '../constants';

import {
  PrettyInput,
  PrettyInputLabel,
  ForgotPasswordLink,
  LoginForm,
  LoginButton
} from './styled/title';

export default function Login({ setToken, isMobile }) {
  const [email_string, setUserName] = useState();
  const [password_string, setPassword] = useState();
  const [forgot_bool, setForgot] = useState(false);
  const [sent_message_bool, setSentMessage] = useState(false);
  const localStorageExists = storageAvailable("localStorage");
  // TODO: add messaging if !storageAvailable ("modern browser required")

  function handleForgotPassword() {
    console.log("~~~~~ ForgotPasswordLink - link clicked!");
    if (!forgot_bool) {
      setForgot(true);
    }
    else
    {
      setForgot(false);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    
    if (!forgot_bool) {
      const loginUserObject = await loginUser({ email_string, password_string }); // returns { token: [str], user: { name: [str], email: [str], id_guid: [str]} }
      const token = loginUserObject.token;
      const userObj = loginUserObject.user;
      const userGuid = userObj.id_guid;

      setToken(token); // sets whatever was returned, even if null or "authFail" (401 case)
      
      console.log("~~~~~ Login() - token: " + token);
      console.log("~~~~~ Login() - userGuid: " + userGuid);  
      console.log("~~~~~ Login() - user_authorization_athlete: " + userObj.user_authorization_athlete);
      console.log("~~~~~ Login() - user_authorization_enterprise: " + userObj.user_authorization_enterprise);
      console.log("~~~~~ Login() - user_authorization_coach: " + userObj.user_authorization_coach);
      console.log("~~~~~ Login() - user_authorization_administrator: " + userObj.user_authorization_administrator);
      
      if (localStorageExists) {
        localStorage.setItem('loggedInUser', userGuid);
      
        if (userObj.user_authorization_athlete === true) {
          localStorage.setItem('athlete', 'true');
        } else {
          localStorage.setItem('athlete', 'false');
        }
  
        if (userObj.user_authorization_enterprise === true) {
          localStorage.setItem('enterprise', 'true');
        } else {
          localStorage.setItem('enterprise', 'false');
        }
  
        if (userObj.user_authorization_coach === true) {
          localStorage.setItem('coach', 'true');
        } else {
          localStorage.setItem('coach', 'false');
        }
  
        if (userObj.user_authorization_administrator === true) {
          localStorage.setItem('administrator', 'true');
        } else {
          localStorage.setItem('administrator', 'false');
        }
  
        if (email_string.includes("stretchlab")) {
          localStorage.setItem('stretchlab', 'true');
        } else {
          localStorage.setItem('stretchlab', 'false');
        }
  
        if (email_string.includes("physmodo")) {
          localStorage.setItem('physmodo', 'true');
        } else {
          localStorage.setItem('physmodo', 'false');
        }  
      }
      else
      {
        alert('Please try accessing the Physmodo Dashboard again, - with a modern browser!');
      }

      // extra steps if login worked and localStorageExists
      if (token && token.length > 10 && localStorageExists) {
        let reloaded = localStorage.getItem('alreadyReloaded');

        if (reloaded !== "true") { // perform a reload to recognize token
          localStorage.setItem('alreadyReloaded', "true");
          window.location.reload(true);
        }
      } else {
        if (token && token === "authFail") {
          alert ("Login Failed - combination of email and password not found")
        }
      } 
    }

    if (forgot_bool) {
      console.log("~~~~~ handleSubmit() - forgot_bool - email_string: " + email_string);
      // const forgot = await forgotPassword({ email_string });
      forgotPassword({ email_string });
      setSentMessage(true);
      setTimeout(function(){
        setSentMessage(false);
        setForgot(false);
      }, 10000);
    }
  }
  
  return(
    <div className="login-wrapper">
      <img src={logoSrc} alt="Stretch Lab logo" style={{ width: "12vw" }} />
      <LoginForm onSubmit={handleSubmit}>
        <PrettyInputLabel>
          <p>Email</p>
          <PrettyInput name="email" type="text" autoComplete="email" onChange={e => setUserName(e.target.value)} />
        </PrettyInputLabel>
        <br />
        
        { !forgot_bool && 
        <PrettyInputLabel style={{ height: "7vw"}}>
          <p>Password</p>
          <PrettyInput type="password" autoComplete="current-password" onChange={e => setPassword(e.target.value)} />
        </PrettyInputLabel>
        }

        { forgot_bool && !sent_message_bool &&
        <div style={{ textAlign: "center", width: "60vw", height: "7vw", fontSize: "1.2vw", paddingTop: "1.2vw" }}>
          Submit this form to get a Reset Password link via email that you can use to choose a new password.
          <p style={{ marginTop: "1vw", color: "red" }}>
            After choosing a new password you will need to sign-in to your mobile device or kiosk again.
          </p>
        </div>
        } 

        { sent_message_bool && 
        <div style={{ textAlign: "center", width: "60vw", height: "7vw", fontSize: "1.5vw", paddingTop: "1.0vw" }}>
          You should receive an email with a Reset Password link shortly!
          <p style={{ marginTop: "1vw", fontSize: "1.2vw", color: "red" }}>
            After choosing a new password you will need to sign-in to your mobile device or kiosk again.
          </p>
        </div>
        } 
        
        <div>
          <LoginButton type="submit" disabled={sent_message_bool}>
            { !forgot_bool ? "Sign in" : "Reset Password" }
          </LoginButton>
        </div>
        <ForgotPasswordLink href="#" onClick={handleForgotPassword}>
          { forgot_bool ? "Cancel" : "Forgot Password" }
        </ForgotPasswordLink>
      </LoginForm>
      <p style={{ textAlign: "center", width: "60vw", height: "7vw", fontSize: "0.8vw", paddingTop: "15.0vw" }}>
        Version: {packageJson.version}
      </p>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};