// services/mocks/assessments.js

export const assessmentMock = {
  "id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
  "created_datetime": "2022-03-16T23:36:41.458Z",
  "updated_datetime": "2022-03-16T23:36:41.458Z",
  "submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
  "athlete_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
  "external_id_guid": "c0bc1da8-abb9-4ad9-adff-217e975a1610",
  "attribute_hash": {
    "ios_datetime": {
      "id_guid": "7f302678-fcf2-4468-8e33-bcb17b9adcff",
      "created_datetime": "2022-03-16T23:36:41.462Z",
      "updated_datetime": "2022-03-16T23:36:41.462Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "ios_datetime",
      "value_string": "2022-03-16T23:39:30.318Z",
      "unit_string": null
    },
    "ios_app_version": {
      "id_guid": "6f59d19d-175e-452c-b099-ed2731371a5c",
      "created_datetime": "2022-03-16T23:36:41.466Z",
      "updated_datetime": "2022-03-16T23:36:41.466Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "ios_app_version",
      "value_string": "iOS v1.0.5",
      "unit_string": null
    },
    "measurement_mobility_elbow_left": {
      "id_guid": "1b43807a-1e4e-454b-8628-af092d6976b1",
      "created_datetime": "2022-03-16T23:36:41.469Z",
      "updated_datetime": "2022-03-16T23:36:41.469Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_elbow_left",
      "value_string": "-116.09526997380448",
      "unit_string": "°"
    },
    "measurement_mobility_elbow_right": {
      "id_guid": "523e1eac-c947-4de1-ac2b-23aade2d56f8",
      "created_datetime": "2022-03-16T23:36:41.473Z",
      "updated_datetime": "2022-03-16T23:36:41.473Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_elbow_right",
      "value_string": "48.90711420647182",
      "unit_string": "°"
    },
    "measurement_mobility_shoulder_left": {
      "id_guid": "98d9e264-cc40-48df-9535-275c32b013cf",
      "created_datetime": "2022-03-16T23:36:41.476Z",
      "updated_datetime": "2022-03-16T23:36:41.476Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_shoulder_left",
      "value_string": "-76.3117425563212",
      "unit_string": "°"
    },
    "measurement_mobility_shoulder_right": {
      "id_guid": "c59f16d9-7c87-4136-9df2-c50d7e2c88bf",
      "created_datetime": "2022-03-16T23:36:41.479Z",
      "updated_datetime": "2022-03-16T23:36:41.479Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_shoulder_right",
      "value_string": "150.53086814271313",
      "unit_string": "°"
    },
    "measurement_mobility_hip_left": {
      "id_guid": "ac42010d-0453-40ea-bdac-d49f50cac20e",
      "created_datetime": "2022-03-16T23:36:41.482Z",
      "updated_datetime": "2022-03-16T23:36:41.482Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_hip_left",
      "value_string": "76.221410992127",
      "unit_string": "°"
    },
    "measurement_mobility_hip_right": {
      "id_guid": "c95e4bd1-20bc-419d-802f-57f6f1fb826c",
      "created_datetime": "2022-03-16T23:36:41.486Z",
      "updated_datetime": "2022-03-16T23:36:41.486Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_hip_right",
      "value_string": "120.47687618788063",
      "unit_string": "°"
    },
    "measurement_mobility_femur_left": {
      "id_guid": "187f10e5-2b27-41fc-8765-655a6c80ccf6",
      "created_datetime": "2022-03-16T23:36:41.489Z",
      "updated_datetime": "2022-03-16T23:36:41.489Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_femur_left",
      "value_string": "35.35936907583684",
      "unit_string": "°"
    },
    "measurement_mobility_femur_right": {
      "id_guid": "5667b4b1-04ab-4912-bf5b-4092b6002dfa",
      "created_datetime": "2022-03-16T23:36:41.492Z",
      "updated_datetime": "2022-03-16T23:36:41.492Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_femur_right",
      "value_string": "-145.98446097699707",
      "unit_string": "°"
    },
    "measurement_mobility_knee_left": {
      "id_guid": "94ec3200-1d69-476e-8efb-897c24bb1cab",
      "created_datetime": "2022-03-16T23:36:41.495Z",
      "updated_datetime": "2022-03-16T23:36:41.495Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_knee_left",
      "value_string": "120.28570769725394",
      "unit_string": "°"
    },
    "measurement_mobility_knee_right": {
      "id_guid": "0da1f8c2-4266-4e35-9522-02217cc47a3f",
      "created_datetime": "2022-03-16T23:36:41.498Z",
      "updated_datetime": "2022-03-16T23:36:41.498Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_knee_right",
      "value_string": "58.79121439800542",
      "unit_string": "°"
    },
    "measurement_mobility_tibia_left": {
      "id_guid": "7fdbac0b-09fb-4435-b787-7207027915cf",
      "created_datetime": "2022-03-16T23:36:41.502Z",
      "updated_datetime": "2022-03-16T23:36:41.502Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_tibia_left",
      "value_string": "7.322449104722978",
      "unit_string": "°"
    },
    "measurement_mobility_tibia_right": {
      "id_guid": "7e240e1b-bbe8-4f3b-aba0-2852e59f64f1",
      "created_datetime": "2022-03-16T23:36:41.510Z",
      "updated_datetime": "2022-03-16T23:36:41.510Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_mobility_tibia_right",
      "value_string": "-170.96333347772102",
      "unit_string": "°"
    },
    "measurement_stability_scap_left": {
      "id_guid": "3c37d2a7-518b-4539-8e3f-6f6cfa66b570",
      "created_datetime": "2022-03-16T23:36:41.513Z",
      "updated_datetime": "2022-03-16T23:36:41.513Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_stability_scap_left",
      "value_string": "2.7576738778844003",
      "unit_string": "°"
    },
    "measurement_stability_scap_right": {
      "id_guid": "f9cb7cb2-5f9f-446a-a44f-72d82eb0edf8",
      "created_datetime": "2022-03-16T23:36:41.516Z",
      "updated_datetime": "2022-03-16T23:36:41.516Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_stability_scap_right",
      "value_string": "34.65768870804351",
      "unit_string": "°"
    },
    "measurement_stability_spine_center_yz": {
      "id_guid": "2edde961-0217-40b0-b230-fdd5ac8b0d6f",
      "created_datetime": "2022-03-16T23:36:41.519Z",
      "updated_datetime": "2022-03-16T23:36:41.519Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_stability_spine_center_yz",
      "value_string": "123.85319404324582",
      "unit_string": "°"
    },
    "measurement_stability_tibia_left_xy": {
      "id_guid": "87deeebc-4a6c-454f-a73f-62c0d2147535",
      "created_datetime": "2022-03-16T23:36:41.522Z",
      "updated_datetime": "2022-03-16T23:36:41.522Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_stability_tibia_left_xy",
      "value_string": "33.50200988284169",
      "unit_string": "°"
    },
    "measurement_stability_tibia_right_xy": {
      "id_guid": "aca220a7-6744-4f3a-be64-b45ba82c72b3",
      "created_datetime": "2022-03-16T23:36:41.526Z",
      "updated_datetime": "2022-03-16T23:36:41.526Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_stability_tibia_right_xy",
      "value_string": "160.14536089163158",
      "unit_string": "°"
    },
    "measurement_posture_shoulder_level": {
      "id_guid": "691d0f0d-0564-40c0-86d3-a0f51558da46",
      "created_datetime": "2022-03-16T23:36:41.529Z",
      "updated_datetime": "2022-03-16T23:36:41.529Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_posture_shoulder_level",
      "value_string": "-20.723175610319913",
      "unit_string": "°"
    },
    "measurement_posture_spine_center_xy": {
      "id_guid": "c1c32a21-abd9-4335-98ae-04ee5157a646",
      "created_datetime": "2022-03-16T23:36:41.532Z",
      "updated_datetime": "2022-03-16T23:36:41.532Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_posture_spine_center_xy",
      "value_string": "66.47832089964714",
      "unit_string": "°"
    },
    "measurement_symmetry_elbow": {
      "id_guid": "69a66100-1631-498f-bb4f-34ee689556e2",
      "created_datetime": "2022-03-16T23:36:41.535Z",
      "updated_datetime": "2022-03-16T23:36:41.535Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_elbow",
      "value_string": "93.31103454064072",
      "unit_string": "°"
    },
    "measurement_symmetry_shoulder": {
      "id_guid": "d25891e7-4fcd-4436-986d-9c06bb09bf0c",
      "created_datetime": "2022-03-16T23:36:41.538Z",
      "updated_datetime": "2022-03-16T23:36:41.538Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_shoulder",
      "value_string": "-97.89567827114482",
      "unit_string": "°"
    },
    "measurement_symmetry_spine_center_xy": {
      "id_guid": "44611e05-4d15-463f-8cbf-76e35213b86e",
      "created_datetime": "2022-03-16T23:36:41.541Z",
      "updated_datetime": "2022-03-16T23:36:41.541Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_spine_center_xy",
      "value_string": "31.155938142271424",
      "unit_string": "°"
    },
    "measurement_symmetry_hip": {
      "id_guid": "bb0a39e9-38c4-4eca-8205-d5d973e84e41",
      "created_datetime": "2022-03-16T23:36:41.544Z",
      "updated_datetime": "2022-03-16T23:36:41.544Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_hip",
      "value_string": "96.91212198380828",
      "unit_string": "°"
    },
    "measurement_symmetry_femur": {
      "id_guid": "3b7fbe14-4482-4c9b-b0a8-83a00ac25389",
      "created_datetime": "2022-03-16T23:36:41.548Z",
      "updated_datetime": "2022-03-16T23:36:41.548Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_femur",
      "value_string": "44.01670153236387",
      "unit_string": "°"
    },
    "measurement_symmetry_knee": {
      "id_guid": "ba04e317-7add-4203-83c4-1c03a6452847",
      "created_datetime": "2022-03-16T23:36:41.551Z",
      "updated_datetime": "2022-03-16T23:36:41.551Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_knee",
      "value_string": "-48.54801590856417",
      "unit_string": "°"
    },
    "measurement_symmetry_tibia": {
      "id_guid": "c6b81413-34cb-4dbe-9a2d-adbf6ddd0c6f",
      "created_datetime": "2022-03-16T23:36:41.554Z",
      "updated_datetime": "2022-03-16T23:36:41.554Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "measurement_symmetry_tibia",
      "value_string": "130.82528712432537",
      "unit_string": "°"
    },
    "score_composite": {
      "id_guid": "d8365a4c-68eb-40aa-a7d9-f1ae38f01774",
      "created_datetime": "2022-03-16T23:36:41.557Z",
      "updated_datetime": "2022-03-16T23:36:41.557Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_composite",
      "value_string": "0.0261668315587423",
      "unit_string": null
    },
    "score_mobility": {
      "id_guid": "3cf09762-f3f0-4d53-8a4c-fe84f789d1bf",
      "created_datetime": "2022-03-16T23:36:41.560Z",
      "updated_datetime": "2022-03-16T23:36:41.560Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility",
      "value_string": "0.3806356724639112",
      "unit_string": null
    },
    "score_stability": {
      "id_guid": "172d5b18-d593-4fee-8353-1208dc4754f6",
      "created_datetime": "2022-03-16T23:36:41.563Z",
      "updated_datetime": "2022-03-16T23:36:41.563Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability",
      "value_string": "0.6611163128503423",
      "unit_string": null
    },
    "score_posture": {
      "id_guid": "d9292649-092c-4357-9743-ce3604b71ace",
      "created_datetime": "2022-03-16T23:36:41.566Z",
      "updated_datetime": "2022-03-16T23:36:41.566Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_posture",
      "value_string": "0.3242677161534256",
      "unit_string": null
    },
    "score_symmetry": {
      "id_guid": "7fcc340c-c4a0-4914-bd12-34550686381c",
      "created_datetime": "2022-03-16T23:36:41.569Z",
      "updated_datetime": "2022-03-16T23:36:41.569Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry",
      "value_string": "0.054725626278102936",
      "unit_string": null
    },
    "score_mobility_elbow_left": {
      "id_guid": "3faafffa-a240-4683-8ed1-68216b66b9ce",
      "created_datetime": "2022-03-16T23:36:41.572Z",
      "updated_datetime": "2022-03-16T23:36:41.572Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_elbow_left",
      "value_string": "0.19131777977659611",
      "unit_string": "°"
    },
    "score_mobility_elbow_right": {
      "id_guid": "380a7082-8fa9-478f-8e12-57541b1146f8",
      "created_datetime": "2022-03-16T23:36:41.575Z",
      "updated_datetime": "2022-03-16T23:36:41.575Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_elbow_right",
      "value_string": "0.5221094978128493",
      "unit_string": "°"
    },
    "score_mobility_shoulder_left": {
      "id_guid": "6f3d61f1-fc90-43d5-8213-268a8af02c45",
      "created_datetime": "2022-03-16T23:36:41.578Z",
      "updated_datetime": "2022-03-16T23:36:41.578Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_shoulder_left",
      "value_string": "0.2083196567654473",
      "unit_string": "°"
    },
    "score_mobility_shoulder_right": {
      "id_guid": "075b35f5-c70a-4d16-bdd8-eec9b5df396d",
      "created_datetime": "2022-03-16T23:36:41.581Z",
      "updated_datetime": "2022-03-16T23:36:41.581Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_shoulder_right",
      "value_string": "0.7522620391469589",
      "unit_string": "°"
    },
    "score_mobility_hip_left": {
      "id_guid": "9cbfa81b-7d0e-4c32-8894-ffd29877ca91",
      "created_datetime": "2022-03-16T23:36:41.584Z",
      "updated_datetime": "2022-03-16T23:36:41.584Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_hip_left",
      "value_string": "0.2776785022994335",
      "unit_string": "°"
    },
    "score_mobility_hip_right": {
      "id_guid": "c83b90aa-5bb4-48d3-8272-43fd852876fb",
      "created_datetime": "2022-03-16T23:36:41.587Z",
      "updated_datetime": "2022-03-16T23:36:41.587Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_hip_right",
      "value_string": "0.26461995437098057",
      "unit_string": "°"
    },
    "score_mobility_femur_left": {
      "id_guid": "0941dbf1-e80a-4a37-94bf-9a17dd7faf2d",
      "created_datetime": "2022-03-16T23:36:41.590Z",
      "updated_datetime": "2022-03-16T23:36:41.590Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_femur_left",
      "value_string": "0.5277494643987659",
      "unit_string": "°"
    },
    "score_mobility_femur_right": {
      "id_guid": "35b507fa-7804-44d1-a90e-3285f2d99987",
      "created_datetime": "2022-03-16T23:36:41.594Z",
      "updated_datetime": "2022-03-16T23:36:41.594Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_femur_right",
      "value_string": "0.40956158353159716",
      "unit_string": "°"
    },
    "score_mobility_knee_left": {
      "id_guid": "c8676617-1446-4dfe-bb86-684e84e6f250",
      "created_datetime": "2022-03-16T23:36:41.597Z",
      "updated_datetime": "2022-03-16T23:36:41.597Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_knee_left",
      "value_string": "0.8867086964638561",
      "unit_string": "°"
    },
    "score_mobility_knee_right": {
      "id_guid": "1c4c9dee-4a79-4d57-a181-6ee4296a3a3f",
      "created_datetime": "2022-03-16T23:36:41.600Z",
      "updated_datetime": "2022-03-16T23:36:41.600Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_knee_right",
      "value_string": "0.5431331198451939",
      "unit_string": "°"
    },
    "score_mobility_tibia_left": {
      "id_guid": "a7438d33-52c8-4f71-914b-b744e641f421",
      "created_datetime": "2022-03-16T23:36:41.603Z",
      "updated_datetime": "2022-03-16T23:36:41.603Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_tibia_left",
      "value_string": "0.43586131099250486",
      "unit_string": "°"
    },
    "score_mobility_tibia_right": {
      "id_guid": "7ddc5ed8-4b93-4c60-849c-cae2957a9e49",
      "created_datetime": "2022-03-16T23:36:41.606Z",
      "updated_datetime": "2022-03-16T23:36:41.606Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_mobility_tibia_right",
      "value_string": "0.14258157344602534",
      "unit_string": "°"
    },
    "score_stability_scap_left": {
      "id_guid": "fc5445e5-5a9b-4793-8f63-43122065e526",
      "created_datetime": "2022-03-16T23:36:41.609Z",
      "updated_datetime": "2022-03-16T23:36:41.609Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability_scap_left",
      "value_string": "0.3246566503725177",
      "unit_string": "°"
    },
    "score_stability_scap_right": {
      "id_guid": "b5e35103-8f9b-4eca-9924-aa69d55049da",
      "created_datetime": "2022-03-16T23:36:41.612Z",
      "updated_datetime": "2022-03-16T23:36:41.612Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability_scap_right",
      "value_string": "0.04007082665779419",
      "unit_string": "°"
    },
    "score_stability_spine_center_yz": {
      "id_guid": "219793ba-5bd4-4483-b8e4-a85e3c7b26f8",
      "created_datetime": "2022-03-16T23:36:41.615Z",
      "updated_datetime": "2022-03-16T23:36:41.615Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability_spine_center_yz",
      "value_string": "0.22599510727878355",
      "unit_string": "°"
    },
    "score_stability_tibia_left_xy": {
      "id_guid": "6af87b5f-3ad9-46af-b535-7ccd9a2a07c9",
      "created_datetime": "2022-03-16T23:36:41.618Z",
      "updated_datetime": "2022-03-16T23:36:41.618Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability_tibia_left_xy",
      "value_string": "0.7977839164820328",
      "unit_string": "°"
    },
    "score_stability_tibia_right_xy": {
      "id_guid": "db2cddef-8f4b-4da6-bde7-9f3b97cacd0c",
      "created_datetime": "2022-03-16T23:36:41.621Z",
      "updated_datetime": "2022-03-16T23:36:41.621Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_stability_tibia_right_xy",
      "value_string": "0.8235685625121054",
      "unit_string": "°"
    },
    "score_posture_shoulder_level": {
      "id_guid": "c18a8288-3559-41ee-aecb-c387333e92ca",
      "created_datetime": "2022-03-16T23:36:41.624Z",
      "updated_datetime": "2022-03-16T23:36:41.624Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_posture_shoulder_level",
      "value_string": "0.8994780455731963",
      "unit_string": "°"
    },
    "score_posture_spine_center_xy": {
      "id_guid": "adf12223-62f7-4247-9514-89a39b6fdc91",
      "created_datetime": "2022-03-16T23:36:41.627Z",
      "updated_datetime": "2022-03-16T23:36:41.627Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_posture_spine_center_xy",
      "value_string": "0.8831025882547797",
      "unit_string": "°"
    },
    "score_symmetry_elbow": {
      "id_guid": "98094295-fc43-4254-a051-8426bcf14b5b",
      "created_datetime": "2022-03-16T23:36:41.630Z",
      "updated_datetime": "2022-03-16T23:36:41.630Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_elbow",
      "value_string": "0.968353965496469",
      "unit_string": "°"
    },
    "score_symmetry_shoulder": {
      "id_guid": "e6546d50-5cac-42bf-9d45-b58d2311e926",
      "created_datetime": "2022-03-16T23:36:41.633Z",
      "updated_datetime": "2022-03-16T23:36:41.633Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_shoulder",
      "value_string": "0.6827124529419206",
      "unit_string": "°"
    },
    "score_symmetry_spine_center_xy": {
      "id_guid": "160de59c-2f2c-42a2-83ed-39793cbedee3",
      "created_datetime": "2022-03-16T23:36:41.636Z",
      "updated_datetime": "2022-03-16T23:36:41.636Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_spine_center_xy",
      "value_string": "0.05258975797615818",
      "unit_string": "°"
    },
    "score_symmetry_hip": {
      "id_guid": "e172e7d3-cb7d-4137-9b40-005a0aea9fe9",
      "created_datetime": "2022-03-16T23:36:41.640Z",
      "updated_datetime": "2022-03-16T23:36:41.640Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_hip",
      "value_string": "0.06110064878226688",
      "unit_string": "°"
    },
    "score_symmetry_femur": {
      "id_guid": "09c03a8c-e607-40dc-ba7e-b9600a1a580c",
      "created_datetime": "2022-03-16T23:36:41.643Z",
      "updated_datetime": "2022-03-16T23:36:41.643Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_femur",
      "value_string": "0.8426496857071883",
      "unit_string": "°"
    },
    "score_symmetry_knee": {
      "id_guid": "58376be1-de6d-4963-8da5-3526f98b371a",
      "created_datetime": "2022-03-16T23:36:41.646Z",
      "updated_datetime": "2022-03-16T23:36:41.646Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_knee",
      "value_string": "0.7386932126285651",
      "unit_string": "°"
    },
    "score_symmetry_tibia": {
      "id_guid": "49845340-49ca-4b71-af35-f7ec43a20f85",
      "created_datetime": "2022-03-16T23:36:41.649Z",
      "updated_datetime": "2022-03-16T23:36:41.649Z",
      "assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
      "name_string": "score_symmetry_tibia",
      "value_string": "0.01504383488375328",
      "unit_string": "°"
    }
  }
};


export const assessmentsArrayMock = [
		{
      "id": 77,
			"id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
			"created_datetime": "2022-03-16T23:36:15.591Z",
			"updated_datetime": "2022-03-16T23:36:15.591Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "fdbc3b28-1980-434f-80ff-c5d6d8377eec",
			"external_id_guid": "3a3ef81f-757e-4df2-93dc-ffd2ff1318be",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "abae3865-318d-4c2d-839f-a4aa56a25e50",
					"created_datetime": "2022-03-16T23:36:15.597Z",
					"updated_datetime": "2022-03-16T23:36:15.597Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "ios_datetime",
					"value_string": "2022-03-16T23:39:04.467Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "74307b9d-1420-4e58-a0c1-6c6a187e41fe",
					"created_datetime": "2022-03-16T23:36:15.602Z",
					"updated_datetime": "2022-03-16T23:36:15.602Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "30101956-618d-4fe4-a41b-196462eb4226",
					"created_datetime": "2022-03-16T23:36:15.606Z",
					"updated_datetime": "2022-03-16T23:36:15.606Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-144.08032057499554",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "bc8f6bea-2018-4033-87fe-15882fa13b75",
					"created_datetime": "2022-03-16T23:36:15.611Z",
					"updated_datetime": "2022-03-16T23:36:15.611Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-61.69763757644439",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "d98fe636-82a6-4e32-b206-c4aa49ce4a5e",
					"created_datetime": "2022-03-16T23:36:15.615Z",
					"updated_datetime": "2022-03-16T23:36:15.615Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "15.77511024259212",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "982b5d2e-8f77-4a27-8554-5db739924a89",
					"created_datetime": "2022-03-16T23:36:15.620Z",
					"updated_datetime": "2022-03-16T23:36:15.620Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "22.837167981810637",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "f6f20848-2c5c-4da4-bdd1-09c873f31504",
					"created_datetime": "2022-03-16T23:36:15.624Z",
					"updated_datetime": "2022-03-16T23:36:15.624Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "113.27773022061001",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "76aeb7d9-ee8d-4add-81ea-6e9a84728b70",
					"created_datetime": "2022-03-16T23:36:15.629Z",
					"updated_datetime": "2022-03-16T23:36:15.629Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-110.19934896613623",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "75b14458-061f-497c-b9c2-36b5dd2040d4",
					"created_datetime": "2022-03-16T23:36:15.633Z",
					"updated_datetime": "2022-03-16T23:36:15.633Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-26.422274346893914",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "c41a2fb5-69b6-4748-b519-6e63e11631e1",
					"created_datetime": "2022-03-16T23:36:15.637Z",
					"updated_datetime": "2022-03-16T23:36:15.637Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "133.55095715646638",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "cd0d96b0-4ebb-42dd-9d9f-163807572379",
					"created_datetime": "2022-03-16T23:36:15.642Z",
					"updated_datetime": "2022-03-16T23:36:15.642Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "158.57710323209523",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "97a084d4-5e87-4d71-8b32-cd8584aed666",
					"created_datetime": "2022-03-16T23:36:15.645Z",
					"updated_datetime": "2022-03-16T23:36:15.645Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "127.70848521251997",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "1d2f970a-feb5-47a4-9cdc-aaa1fab25ecd",
					"created_datetime": "2022-03-16T23:36:15.650Z",
					"updated_datetime": "2022-03-16T23:36:15.650Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "32.462595619078456",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "ccf834e1-61e1-4b37-a358-ba3bd3c4d819",
					"created_datetime": "2022-03-16T23:36:15.654Z",
					"updated_datetime": "2022-03-16T23:36:15.654Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-142.82691363389026",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "7d9ae048-ac59-4088-a40d-78f1fbcc8e25",
					"created_datetime": "2022-03-16T23:36:15.660Z",
					"updated_datetime": "2022-03-16T23:36:15.660Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-21.85227383846933",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "02c266d3-88e0-41bf-877f-206e18b14a7b",
					"created_datetime": "2022-03-16T23:36:15.666Z",
					"updated_datetime": "2022-03-16T23:36:15.666Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_stability_scap_right",
					"value_string": "163.45982748312196",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "5e44b3ef-0b27-4435-89a0-8fd2ac0f2b3d",
					"created_datetime": "2022-03-16T23:36:15.669Z",
					"updated_datetime": "2022-03-16T23:36:15.669Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-34.646194303562964",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "9f6d61ad-c0b5-4958-b0f1-29eeb47c134c",
					"created_datetime": "2022-03-16T23:36:15.672Z",
					"updated_datetime": "2022-03-16T23:36:15.672Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-173.68934428536826",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "a862fc4c-83ef-4657-b30c-df5f69d5572e",
					"created_datetime": "2022-03-16T23:36:15.675Z",
					"updated_datetime": "2022-03-16T23:36:15.675Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "73.28540988184602",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "01a256e1-0d89-4c8d-931e-561c2e3391f3",
					"created_datetime": "2022-03-16T23:36:15.678Z",
					"updated_datetime": "2022-03-16T23:36:15.678Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-22.020079537968996",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8171fb05-b4f1-4727-856b-0660a8e09f45",
					"created_datetime": "2022-03-16T23:36:15.682Z",
					"updated_datetime": "2022-03-16T23:36:15.682Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-62.349861246324366",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "40dada27-71cf-437b-ab8f-462ae6388599",
					"created_datetime": "2022-03-16T23:36:15.685Z",
					"updated_datetime": "2022-03-16T23:36:15.685Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-83.45716781381127",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "01e324d9-e881-47cd-aa1f-7454ef7cc372",
					"created_datetime": "2022-03-16T23:36:15.688Z",
					"updated_datetime": "2022-03-16T23:36:15.688Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "173.46830715948573",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "1592ebc8-4ff7-405f-97c7-5076c3bdd9cb",
					"created_datetime": "2022-03-16T23:36:15.692Z",
					"updated_datetime": "2022-03-16T23:36:15.692Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "72.5996867818472",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "479f3c36-562f-4f76-89fe-0e9790169738",
					"created_datetime": "2022-03-16T23:36:15.695Z",
					"updated_datetime": "2022-03-16T23:36:15.695Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-155.31508248461924",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "d432bf4c-866d-4892-b421-edc663bddf81",
					"created_datetime": "2022-03-16T23:36:15.698Z",
					"updated_datetime": "2022-03-16T23:36:15.698Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-86.99347274568173",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "9652e576-ed63-4a87-8e3d-742aad52753b",
					"created_datetime": "2022-03-16T23:36:15.701Z",
					"updated_datetime": "2022-03-16T23:36:15.701Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_knee",
					"value_string": "166.95149453594848",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "3fe24b3b-2607-4337-9fba-d61dd0079283",
					"created_datetime": "2022-03-16T23:36:15.704Z",
					"updated_datetime": "2022-03-16T23:36:15.704Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "99.00343204334911",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "cab079d3-3f33-4149-83bf-6bef912d5b97",
					"created_datetime": "2022-03-16T23:36:15.707Z",
					"updated_datetime": "2022-03-16T23:36:15.707Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_composite",
					"value_string": "0.07266890351903027",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "8070758e-1218-4ab9-b7a8-1e8eaecb5fd8",
					"created_datetime": "2022-03-16T23:36:15.710Z",
					"updated_datetime": "2022-03-16T23:36:15.710Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility",
					"value_string": "0.6543054332973681",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "9e7b63c2-223d-45e4-a2bb-44da4e8439b8",
					"created_datetime": "2022-03-16T23:36:15.713Z",
					"updated_datetime": "2022-03-16T23:36:15.713Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability",
					"value_string": "0.5863841214351158",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "cd6bb9cf-1e5b-4e8f-924d-be71082b39ad",
					"created_datetime": "2022-03-16T23:36:15.716Z",
					"updated_datetime": "2022-03-16T23:36:15.716Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_posture",
					"value_string": "0.10766294621841585",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "ec5a92a3-7469-43b7-b76f-f71f00c7ddde",
					"created_datetime": "2022-03-16T23:36:15.719Z",
					"updated_datetime": "2022-03-16T23:36:15.719Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry",
					"value_string": "0.35435533755251336",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "aa8a84d5-18b2-4577-a0fe-b12c2618186a",
					"created_datetime": "2022-03-16T23:36:15.722Z",
					"updated_datetime": "2022-03-16T23:36:15.722Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.07739612917997869",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "0a784d96-7d33-461f-ac3e-644ffe9b111c",
					"created_datetime": "2022-03-16T23:36:15.726Z",
					"updated_datetime": "2022-03-16T23:36:15.726Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.3133204953334274",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "9aea9cb8-c3d7-4bdd-9725-d3510801d801",
					"created_datetime": "2022-03-16T23:36:15.729Z",
					"updated_datetime": "2022-03-16T23:36:15.729Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.5155835832463942",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "53681e7a-80f1-41ae-a333-81f15d3f9ec3",
					"created_datetime": "2022-03-16T23:36:15.732Z",
					"updated_datetime": "2022-03-16T23:36:15.732Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.6311578386780284",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "de0b80f8-7145-4350-8eca-e53ff701dab8",
					"created_datetime": "2022-03-16T23:36:15.734Z",
					"updated_datetime": "2022-03-16T23:36:15.734Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.46832247378311126",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "fce7b978-ed40-44f9-9c0f-349e91b0a412",
					"created_datetime": "2022-03-16T23:36:15.737Z",
					"updated_datetime": "2022-03-16T23:36:15.737Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5282731721497056",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "0c49e690-be4c-4712-992a-8327a5697bb2",
					"created_datetime": "2022-03-16T23:36:15.740Z",
					"updated_datetime": "2022-03-16T23:36:15.740Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.08406998491807761",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "ac22ffea-de36-40ff-b139-0120df2b22a4",
					"created_datetime": "2022-03-16T23:36:15.744Z",
					"updated_datetime": "2022-03-16T23:36:15.744Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.23290587948237013",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "2a04c69d-0718-4533-b441-1c6cf110c210",
					"created_datetime": "2022-03-16T23:36:15.747Z",
					"updated_datetime": "2022-03-16T23:36:15.747Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8284402813861981",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "9763ef5b-df09-43ab-8070-7e19db5f93b7",
					"created_datetime": "2022-03-16T23:36:15.750Z",
					"updated_datetime": "2022-03-16T23:36:15.750Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.011703140216994016",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "a6cd67ca-b97a-49ad-a958-1478393ae7da",
					"created_datetime": "2022-03-16T23:36:15.753Z",
					"updated_datetime": "2022-03-16T23:36:15.753Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.9108057292648662",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "7338a3a7-8c17-4303-9953-af59e3366f48",
					"created_datetime": "2022-03-16T23:36:15.756Z",
					"updated_datetime": "2022-03-16T23:36:15.756Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.041211064787662045",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "5a257d35-ada1-488d-811c-a8a44f0c2987",
					"created_datetime": "2022-03-16T23:36:15.759Z",
					"updated_datetime": "2022-03-16T23:36:15.759Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability_scap_left",
					"value_string": "0.1693592940300872",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "d487469b-5650-4ef7-8ebb-30437a0721ff",
					"created_datetime": "2022-03-16T23:36:15.762Z",
					"updated_datetime": "2022-03-16T23:36:15.762Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability_scap_right",
					"value_string": "0.1755458917469108",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "0bc20e56-42a0-487f-9994-6bea4471e87c",
					"created_datetime": "2022-03-16T23:36:15.765Z",
					"updated_datetime": "2022-03-16T23:36:15.765Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.26748953601076453",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "220edece-6392-4fae-b740-0656f93e87ee",
					"created_datetime": "2022-03-16T23:36:15.769Z",
					"updated_datetime": "2022-03-16T23:36:15.769Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.6932784267823037",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "d0ab3194-af01-4ff1-8d77-0aa54f470d16",
					"created_datetime": "2022-03-16T23:36:15.772Z",
					"updated_datetime": "2022-03-16T23:36:15.772Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.1562433370103687",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "b5118669-cbaf-4a98-989b-e15ce42bb63a",
					"created_datetime": "2022-03-16T23:36:15.774Z",
					"updated_datetime": "2022-03-16T23:36:15.774Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.6697690643065979",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "eda7e34b-49c9-46a0-88e8-76ad1c7920e4",
					"created_datetime": "2022-03-16T23:36:15.777Z",
					"updated_datetime": "2022-03-16T23:36:15.777Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.4080963042468013",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "9e10f64f-3037-4dca-84e2-72201cf708f9",
					"created_datetime": "2022-03-16T23:36:15.780Z",
					"updated_datetime": "2022-03-16T23:36:15.780Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.598700937953195",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "35a9a0f2-e3d7-46cd-a125-4d141f88c819",
					"created_datetime": "2022-03-16T23:36:15.783Z",
					"updated_datetime": "2022-03-16T23:36:15.783Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8224022360663349",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "075f8810-cf12-4d4f-a440-4f5875fd2150",
					"created_datetime": "2022-03-16T23:36:15.787Z",
					"updated_datetime": "2022-03-16T23:36:15.787Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.8307110128032423",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "8d0cd677-ca08-482a-a910-850156537b07",
					"created_datetime": "2022-03-16T23:36:15.790Z",
					"updated_datetime": "2022-03-16T23:36:15.790Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_hip",
					"value_string": "0.31786349803120845",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "7e77d60c-aa8d-4b2f-8b59-198019fd7291",
					"created_datetime": "2022-03-16T23:36:15.793Z",
					"updated_datetime": "2022-03-16T23:36:15.793Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7112250707553047",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "a73f21e0-d6bb-4b9f-ac0d-a196b29f8af1",
					"created_datetime": "2022-03-16T23:36:15.796Z",
					"updated_datetime": "2022-03-16T23:36:15.796Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_knee",
					"value_string": "0.15634495033384796",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "7a538513-4500-4aac-b26b-3de814159b86",
					"created_datetime": "2022-03-16T23:36:15.799Z",
					"updated_datetime": "2022-03-16T23:36:15.799Z",
					"assessment_id_guid": "9c3d81e0-7c78-4267-bd7c-f89f25efff32",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.33278466789670913",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
			"created_datetime": "2022-03-16T23:36:41.458Z",
			"updated_datetime": "2022-03-16T23:36:41.458Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"external_id_guid": "c0bc1da8-abb9-4ad9-adff-217e975a1610",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "7f302678-fcf2-4468-8e33-bcb17b9adcff",
					"created_datetime": "2022-03-16T23:36:41.462Z",
					"updated_datetime": "2022-03-16T23:36:41.462Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "ios_datetime",
					"value_string": "2022-03-16T23:39:30.318Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "6f59d19d-175e-452c-b099-ed2731371a5c",
					"created_datetime": "2022-03-16T23:36:41.466Z",
					"updated_datetime": "2022-03-16T23:36:41.466Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "1b43807a-1e4e-454b-8628-af092d6976b1",
					"created_datetime": "2022-03-16T23:36:41.469Z",
					"updated_datetime": "2022-03-16T23:36:41.469Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-116.09526997380448",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "523e1eac-c947-4de1-ac2b-23aade2d56f8",
					"created_datetime": "2022-03-16T23:36:41.473Z",
					"updated_datetime": "2022-03-16T23:36:41.473Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "48.90711420647182",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "98d9e264-cc40-48df-9535-275c32b013cf",
					"created_datetime": "2022-03-16T23:36:41.476Z",
					"updated_datetime": "2022-03-16T23:36:41.476Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-76.3117425563212",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "c59f16d9-7c87-4136-9df2-c50d7e2c88bf",
					"created_datetime": "2022-03-16T23:36:41.479Z",
					"updated_datetime": "2022-03-16T23:36:41.479Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "150.53086814271313",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "ac42010d-0453-40ea-bdac-d49f50cac20e",
					"created_datetime": "2022-03-16T23:36:41.482Z",
					"updated_datetime": "2022-03-16T23:36:41.482Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "76.221410992127",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "c95e4bd1-20bc-419d-802f-57f6f1fb826c",
					"created_datetime": "2022-03-16T23:36:41.486Z",
					"updated_datetime": "2022-03-16T23:36:41.486Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "120.47687618788063",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "187f10e5-2b27-41fc-8765-655a6c80ccf6",
					"created_datetime": "2022-03-16T23:36:41.489Z",
					"updated_datetime": "2022-03-16T23:36:41.489Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "35.35936907583684",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "5667b4b1-04ab-4912-bf5b-4092b6002dfa",
					"created_datetime": "2022-03-16T23:36:41.492Z",
					"updated_datetime": "2022-03-16T23:36:41.492Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-145.98446097699707",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "94ec3200-1d69-476e-8efb-897c24bb1cab",
					"created_datetime": "2022-03-16T23:36:41.495Z",
					"updated_datetime": "2022-03-16T23:36:41.495Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "120.28570769725394",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "0da1f8c2-4266-4e35-9522-02217cc47a3f",
					"created_datetime": "2022-03-16T23:36:41.498Z",
					"updated_datetime": "2022-03-16T23:36:41.498Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "58.79121439800542",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "7fdbac0b-09fb-4435-b787-7207027915cf",
					"created_datetime": "2022-03-16T23:36:41.502Z",
					"updated_datetime": "2022-03-16T23:36:41.502Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "7.322449104722978",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "7e240e1b-bbe8-4f3b-aba0-2852e59f64f1",
					"created_datetime": "2022-03-16T23:36:41.510Z",
					"updated_datetime": "2022-03-16T23:36:41.510Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-170.96333347772102",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "3c37d2a7-518b-4539-8e3f-6f6cfa66b570",
					"created_datetime": "2022-03-16T23:36:41.513Z",
					"updated_datetime": "2022-03-16T23:36:41.513Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_stability_scap_left",
					"value_string": "2.7576738778844003",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "f9cb7cb2-5f9f-446a-a44f-72d82eb0edf8",
					"created_datetime": "2022-03-16T23:36:41.516Z",
					"updated_datetime": "2022-03-16T23:36:41.516Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_stability_scap_right",
					"value_string": "34.65768870804351",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "2edde961-0217-40b0-b230-fdd5ac8b0d6f",
					"created_datetime": "2022-03-16T23:36:41.519Z",
					"updated_datetime": "2022-03-16T23:36:41.519Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "123.85319404324582",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "87deeebc-4a6c-454f-a73f-62c0d2147535",
					"created_datetime": "2022-03-16T23:36:41.522Z",
					"updated_datetime": "2022-03-16T23:36:41.522Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "33.50200988284169",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "aca220a7-6744-4f3a-be64-b45ba82c72b3",
					"created_datetime": "2022-03-16T23:36:41.526Z",
					"updated_datetime": "2022-03-16T23:36:41.526Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "160.14536089163158",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "691d0f0d-0564-40c0-86d3-a0f51558da46",
					"created_datetime": "2022-03-16T23:36:41.529Z",
					"updated_datetime": "2022-03-16T23:36:41.529Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-20.723175610319913",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "c1c32a21-abd9-4335-98ae-04ee5157a646",
					"created_datetime": "2022-03-16T23:36:41.532Z",
					"updated_datetime": "2022-03-16T23:36:41.532Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "66.47832089964714",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "69a66100-1631-498f-bb4f-34ee689556e2",
					"created_datetime": "2022-03-16T23:36:41.535Z",
					"updated_datetime": "2022-03-16T23:36:41.535Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "93.31103454064072",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "d25891e7-4fcd-4436-986d-9c06bb09bf0c",
					"created_datetime": "2022-03-16T23:36:41.538Z",
					"updated_datetime": "2022-03-16T23:36:41.538Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-97.89567827114482",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "44611e05-4d15-463f-8cbf-76e35213b86e",
					"created_datetime": "2022-03-16T23:36:41.541Z",
					"updated_datetime": "2022-03-16T23:36:41.541Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "31.155938142271424",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "bb0a39e9-38c4-4eca-8205-d5d973e84e41",
					"created_datetime": "2022-03-16T23:36:41.544Z",
					"updated_datetime": "2022-03-16T23:36:41.544Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_hip",
					"value_string": "96.91212198380828",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "3b7fbe14-4482-4c9b-b0a8-83a00ac25389",
					"created_datetime": "2022-03-16T23:36:41.548Z",
					"updated_datetime": "2022-03-16T23:36:41.548Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_femur",
					"value_string": "44.01670153236387",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ba04e317-7add-4203-83c4-1c03a6452847",
					"created_datetime": "2022-03-16T23:36:41.551Z",
					"updated_datetime": "2022-03-16T23:36:41.551Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-48.54801590856417",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "c6b81413-34cb-4dbe-9a2d-adbf6ddd0c6f",
					"created_datetime": "2022-03-16T23:36:41.554Z",
					"updated_datetime": "2022-03-16T23:36:41.554Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "130.82528712432537",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "d8365a4c-68eb-40aa-a7d9-f1ae38f01774",
					"created_datetime": "2022-03-16T23:36:41.557Z",
					"updated_datetime": "2022-03-16T23:36:41.557Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_composite",
					"value_string": "0.0261668315587423",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "3cf09762-f3f0-4d53-8a4c-fe84f789d1bf",
					"created_datetime": "2022-03-16T23:36:41.560Z",
					"updated_datetime": "2022-03-16T23:36:41.560Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility",
					"value_string": "0.3806356724639112",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "172d5b18-d593-4fee-8353-1208dc4754f6",
					"created_datetime": "2022-03-16T23:36:41.563Z",
					"updated_datetime": "2022-03-16T23:36:41.563Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability",
					"value_string": "0.6611163128503423",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "d9292649-092c-4357-9743-ce3604b71ace",
					"created_datetime": "2022-03-16T23:36:41.566Z",
					"updated_datetime": "2022-03-16T23:36:41.566Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_posture",
					"value_string": "0.3242677161534256",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "7fcc340c-c4a0-4914-bd12-34550686381c",
					"created_datetime": "2022-03-16T23:36:41.569Z",
					"updated_datetime": "2022-03-16T23:36:41.569Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry",
					"value_string": "0.054725626278102936",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "3faafffa-a240-4683-8ed1-68216b66b9ce",
					"created_datetime": "2022-03-16T23:36:41.572Z",
					"updated_datetime": "2022-03-16T23:36:41.572Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.19131777977659611",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "380a7082-8fa9-478f-8e12-57541b1146f8",
					"created_datetime": "2022-03-16T23:36:41.575Z",
					"updated_datetime": "2022-03-16T23:36:41.575Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5221094978128493",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "6f3d61f1-fc90-43d5-8213-268a8af02c45",
					"created_datetime": "2022-03-16T23:36:41.578Z",
					"updated_datetime": "2022-03-16T23:36:41.578Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.2083196567654473",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "075b35f5-c70a-4d16-bdd8-eec9b5df396d",
					"created_datetime": "2022-03-16T23:36:41.581Z",
					"updated_datetime": "2022-03-16T23:36:41.581Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.7522620391469589",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "9cbfa81b-7d0e-4c32-8894-ffd29877ca91",
					"created_datetime": "2022-03-16T23:36:41.584Z",
					"updated_datetime": "2022-03-16T23:36:41.584Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.2776785022994335",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "c83b90aa-5bb4-48d3-8272-43fd852876fb",
					"created_datetime": "2022-03-16T23:36:41.587Z",
					"updated_datetime": "2022-03-16T23:36:41.587Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.26461995437098057",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "0941dbf1-e80a-4a37-94bf-9a17dd7faf2d",
					"created_datetime": "2022-03-16T23:36:41.590Z",
					"updated_datetime": "2022-03-16T23:36:41.590Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.5277494643987659",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "35b507fa-7804-44d1-a90e-3285f2d99987",
					"created_datetime": "2022-03-16T23:36:41.594Z",
					"updated_datetime": "2022-03-16T23:36:41.594Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.40956158353159716",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "c8676617-1446-4dfe-bb86-684e84e6f250",
					"created_datetime": "2022-03-16T23:36:41.597Z",
					"updated_datetime": "2022-03-16T23:36:41.597Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8867086964638561",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "1c4c9dee-4a79-4d57-a181-6ee4296a3a3f",
					"created_datetime": "2022-03-16T23:36:41.600Z",
					"updated_datetime": "2022-03-16T23:36:41.600Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.5431331198451939",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "a7438d33-52c8-4f71-914b-b744e641f421",
					"created_datetime": "2022-03-16T23:36:41.603Z",
					"updated_datetime": "2022-03-16T23:36:41.603Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.43586131099250486",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "7ddc5ed8-4b93-4c60-849c-cae2957a9e49",
					"created_datetime": "2022-03-16T23:36:41.606Z",
					"updated_datetime": "2022-03-16T23:36:41.606Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.14258157344602534",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "fc5445e5-5a9b-4793-8f63-43122065e526",
					"created_datetime": "2022-03-16T23:36:41.609Z",
					"updated_datetime": "2022-03-16T23:36:41.609Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability_scap_left",
					"value_string": "0.3246566503725177",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "b5e35103-8f9b-4eca-9924-aa69d55049da",
					"created_datetime": "2022-03-16T23:36:41.612Z",
					"updated_datetime": "2022-03-16T23:36:41.612Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability_scap_right",
					"value_string": "0.04007082665779419",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "219793ba-5bd4-4483-b8e4-a85e3c7b26f8",
					"created_datetime": "2022-03-16T23:36:41.615Z",
					"updated_datetime": "2022-03-16T23:36:41.615Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.22599510727878355",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "6af87b5f-3ad9-46af-b535-7ccd9a2a07c9",
					"created_datetime": "2022-03-16T23:36:41.618Z",
					"updated_datetime": "2022-03-16T23:36:41.618Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.7977839164820328",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "db2cddef-8f4b-4da6-bde7-9f3b97cacd0c",
					"created_datetime": "2022-03-16T23:36:41.621Z",
					"updated_datetime": "2022-03-16T23:36:41.621Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.8235685625121054",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "c18a8288-3559-41ee-aecb-c387333e92ca",
					"created_datetime": "2022-03-16T23:36:41.624Z",
					"updated_datetime": "2022-03-16T23:36:41.624Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.8994780455731963",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "adf12223-62f7-4247-9514-89a39b6fdc91",
					"created_datetime": "2022-03-16T23:36:41.627Z",
					"updated_datetime": "2022-03-16T23:36:41.627Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.8831025882547797",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "98094295-fc43-4254-a051-8426bcf14b5b",
					"created_datetime": "2022-03-16T23:36:41.630Z",
					"updated_datetime": "2022-03-16T23:36:41.630Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.968353965496469",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "e6546d50-5cac-42bf-9d45-b58d2311e926",
					"created_datetime": "2022-03-16T23:36:41.633Z",
					"updated_datetime": "2022-03-16T23:36:41.633Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.6827124529419206",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "160de59c-2f2c-42a2-83ed-39793cbedee3",
					"created_datetime": "2022-03-16T23:36:41.636Z",
					"updated_datetime": "2022-03-16T23:36:41.636Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.05258975797615818",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "e172e7d3-cb7d-4137-9b40-005a0aea9fe9",
					"created_datetime": "2022-03-16T23:36:41.640Z",
					"updated_datetime": "2022-03-16T23:36:41.640Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_hip",
					"value_string": "0.06110064878226688",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "09c03a8c-e607-40dc-ba7e-b9600a1a580c",
					"created_datetime": "2022-03-16T23:36:41.643Z",
					"updated_datetime": "2022-03-16T23:36:41.643Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8426496857071883",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "58376be1-de6d-4963-8da5-3526f98b371a",
					"created_datetime": "2022-03-16T23:36:41.646Z",
					"updated_datetime": "2022-03-16T23:36:41.646Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_knee",
					"value_string": "0.7386932126285651",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "49845340-49ca-4b71-af35-f7ec43a20f85",
					"created_datetime": "2022-03-16T23:36:41.649Z",
					"updated_datetime": "2022-03-16T23:36:41.649Z",
					"assessment_id_guid": "f831a1b2-4b03-4289-8e67-43c902db7125",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.01504383488375328",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
			"created_datetime": "2022-03-16T23:41:07.027Z",
			"updated_datetime": "2022-03-16T23:41:07.027Z",
			"submitter_user_id_guid": "5ebf4b0b-8aeb-40ae-99e2-31db7bd84d38",
			"athlete_user_id_guid": "c0294105-1f92-4750-bc91-1ee3846a5e7b",
			"external_id_guid": "c7c9311c-2035-4017-baad-292963cac481",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "042a3241-32cb-4891-ba43-626ea23c8d03",
					"created_datetime": "2022-03-16T23:41:07.031Z",
					"updated_datetime": "2022-03-16T23:41:07.031Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "ios_datetime",
					"value_string": "2022-03-16T23:43:55.877Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "ad49db26-7873-4429-8c82-84d4f9dd9e67",
					"created_datetime": "2022-03-16T23:41:07.034Z",
					"updated_datetime": "2022-03-16T23:41:07.034Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f63af160-ef33-408f-9db5-897104e69a06",
					"created_datetime": "2022-03-16T23:41:07.038Z",
					"updated_datetime": "2022-03-16T23:41:07.038Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-20.473515673767764",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "3d5e0221-3b5e-4b8e-b5d5-c707a01f6f8f",
					"created_datetime": "2022-03-16T23:41:07.042Z",
					"updated_datetime": "2022-03-16T23:41:07.042Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "159.57897915893489",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "36379ca1-f552-461b-8d77-941d18421a12",
					"created_datetime": "2022-03-16T23:41:07.046Z",
					"updated_datetime": "2022-03-16T23:41:07.046Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "38.78374347166559",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "c0c94c32-1cba-4840-abd5-17066e8416a8",
					"created_datetime": "2022-03-16T23:41:07.050Z",
					"updated_datetime": "2022-03-16T23:41:07.050Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "114.17932551871138",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "3479ae6c-5963-4231-9170-07bbee36522e",
					"created_datetime": "2022-03-16T23:41:07.054Z",
					"updated_datetime": "2022-03-16T23:41:07.054Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-102.92856804990647",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "53af0acc-4fab-46bc-b27c-7facf6cb6900",
					"created_datetime": "2022-03-16T23:41:07.058Z",
					"updated_datetime": "2022-03-16T23:41:07.058Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "25.414158397171576",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "50222c0d-6c5e-4b8e-990b-610de5e72a84",
					"created_datetime": "2022-03-16T23:41:07.061Z",
					"updated_datetime": "2022-03-16T23:41:07.061Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "164.0901475772185",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "ae61bc4b-eb98-4934-a8bb-097e1f26d8ad",
					"created_datetime": "2022-03-16T23:41:07.065Z",
					"updated_datetime": "2022-03-16T23:41:07.065Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "121.36905385103961",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "50f4fecd-94aa-4a09-833f-dba421772689",
					"created_datetime": "2022-03-16T23:41:07.068Z",
					"updated_datetime": "2022-03-16T23:41:07.068Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-17.004382574972908",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "5950f3c5-147f-41b8-802f-6dc0473b534b",
					"created_datetime": "2022-03-16T23:41:07.071Z",
					"updated_datetime": "2022-03-16T23:41:07.071Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-154.53681327157346",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "26a8603e-8c20-4df6-8346-a1e143e28a14",
					"created_datetime": "2022-03-16T23:41:07.075Z",
					"updated_datetime": "2022-03-16T23:41:07.075Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "44.27634138909633",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "c19cdcbd-4168-495e-830b-a2ed29504581",
					"created_datetime": "2022-03-16T23:41:07.078Z",
					"updated_datetime": "2022-03-16T23:41:07.078Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "154.15369725091625",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "a05df806-07bc-41ef-941f-720b5542960a",
					"created_datetime": "2022-03-16T23:41:07.082Z",
					"updated_datetime": "2022-03-16T23:41:07.082Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-84.84697298506704",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "68849259-44b5-4587-b990-6aaf31899983",
					"created_datetime": "2022-03-16T23:41:07.085Z",
					"updated_datetime": "2022-03-16T23:41:07.085Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_stability_scap_right",
					"value_string": "22.84522189471278",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "5414a8d5-3d2e-43ff-9cbf-91818e3ce2ef",
					"created_datetime": "2022-03-16T23:41:07.089Z",
					"updated_datetime": "2022-03-16T23:41:07.089Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-174.64934077558834",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "87abb4b3-fa9d-462b-834d-c88e63544d43",
					"created_datetime": "2022-03-16T23:41:07.093Z",
					"updated_datetime": "2022-03-16T23:41:07.093Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-66.36883827215863",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "a34a2280-cd69-4c1a-90a5-7f9f07e513b1",
					"created_datetime": "2022-03-16T23:41:07.098Z",
					"updated_datetime": "2022-03-16T23:41:07.098Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-67.95358461983591",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "fc065cea-0667-401f-9f25-9a771c998d39",
					"created_datetime": "2022-03-16T23:41:07.101Z",
					"updated_datetime": "2022-03-16T23:41:07.101Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "112.8575433904291",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "dada4a31-c65e-45f2-a6e9-76fb3efef0fc",
					"created_datetime": "2022-03-16T23:41:07.105Z",
					"updated_datetime": "2022-03-16T23:41:07.105Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "156.6102989075839",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "722fdc66-abb1-47c4-b30a-3e1a9cc11127",
					"created_datetime": "2022-03-16T23:41:07.108Z",
					"updated_datetime": "2022-03-16T23:41:07.108Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "149.55291365326502",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "29370c21-658b-4a39-9178-63df9f9b23d0",
					"created_datetime": "2022-03-16T23:41:07.111Z",
					"updated_datetime": "2022-03-16T23:41:07.111Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-60.7703683007566",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "431c27e7-93e2-48cb-8cb2-183ed3734d6c",
					"created_datetime": "2022-03-16T23:41:07.115Z",
					"updated_datetime": "2022-03-16T23:41:07.115Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-122.29574862375114",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "4d3f3ae9-c973-4481-9266-a967886e3a5e",
					"created_datetime": "2022-03-16T23:41:07.119Z",
					"updated_datetime": "2022-03-16T23:41:07.119Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_hip",
					"value_string": "40.06435662768678",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "beaa5fad-c11e-40b8-9069-a4ff17b7d2ca",
					"created_datetime": "2022-03-16T23:41:07.122Z",
					"updated_datetime": "2022-03-16T23:41:07.122Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_femur",
					"value_string": "102.4673311554966",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "cf007b3b-c3c4-4143-9170-1e884dcca296",
					"created_datetime": "2022-03-16T23:41:07.125Z",
					"updated_datetime": "2022-03-16T23:41:07.125Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-162.67210553829568",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "a791ada2-b698-435a-85a6-c4f87523107c",
					"created_datetime": "2022-03-16T23:41:07.129Z",
					"updated_datetime": "2022-03-16T23:41:07.129Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "1.9525462706038184",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "d77b7ddb-4297-4955-ab16-d5c92b9a2dd9",
					"created_datetime": "2022-03-16T23:41:07.132Z",
					"updated_datetime": "2022-03-16T23:41:07.132Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_composite",
					"value_string": "0.7818497940121171",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "a2ea00f6-ed4b-4988-9952-0301c2432674",
					"created_datetime": "2022-03-16T23:41:07.136Z",
					"updated_datetime": "2022-03-16T23:41:07.136Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility",
					"value_string": "0.0008521440509965772",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "f5199498-0c7b-4e63-9663-29d0ddaa5e1d",
					"created_datetime": "2022-03-16T23:41:07.139Z",
					"updated_datetime": "2022-03-16T23:41:07.139Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability",
					"value_string": "0.2455238847164431",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "960902f6-919b-4a61-bb98-9fb732e31ec2",
					"created_datetime": "2022-03-16T23:41:07.143Z",
					"updated_datetime": "2022-03-16T23:41:07.143Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_posture",
					"value_string": "0.6488089589616068",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "5ddf883f-fc62-472d-80af-058b01f75dc2",
					"created_datetime": "2022-03-16T23:41:07.146Z",
					"updated_datetime": "2022-03-16T23:41:07.146Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry",
					"value_string": "0.5048637683331326",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "7985b003-8b77-4db2-9cd7-67e02c706f2c",
					"created_datetime": "2022-03-16T23:41:07.150Z",
					"updated_datetime": "2022-03-16T23:41:07.150Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.38971822505331016",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "3a5cfca1-c15a-4e80-add9-dd59c6962929",
					"created_datetime": "2022-03-16T23:41:07.154Z",
					"updated_datetime": "2022-03-16T23:41:07.154Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.22590636051028176",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "7a997a2b-3295-4e2a-b715-7d9b02f45e24",
					"created_datetime": "2022-03-16T23:41:07.157Z",
					"updated_datetime": "2022-03-16T23:41:07.157Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.5021727351571748",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "699b30ac-3499-40e2-abe0-5c0c84f59e47",
					"created_datetime": "2022-03-16T23:41:07.160Z",
					"updated_datetime": "2022-03-16T23:41:07.160Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.9492655832127367",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "b25433b2-6e80-4dbc-b4a0-f236e1bca6bf",
					"created_datetime": "2022-03-16T23:41:07.164Z",
					"updated_datetime": "2022-03-16T23:41:07.164Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.37106601141481216",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "c232de68-f49a-4589-ba08-11f9b6c83b10",
					"created_datetime": "2022-03-16T23:41:07.167Z",
					"updated_datetime": "2022-03-16T23:41:07.167Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.7458935479306279",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "be4cc697-a4be-4d52-9db6-2a9f0ab04201",
					"created_datetime": "2022-03-16T23:41:07.170Z",
					"updated_datetime": "2022-03-16T23:41:07.170Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.18960221639511604",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "0b18c8c6-9174-4dd0-aff7-ebfd9b4245c0",
					"created_datetime": "2022-03-16T23:41:07.174Z",
					"updated_datetime": "2022-03-16T23:41:07.174Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8251906622452377",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "b3ad049c-84d6-4a91-8c6a-3983bef5f25b",
					"created_datetime": "2022-03-16T23:41:07.177Z",
					"updated_datetime": "2022-03-16T23:41:07.177Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.6564414970737192",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "9c2c354f-9e24-481a-80f3-273038404350",
					"created_datetime": "2022-03-16T23:41:07.180Z",
					"updated_datetime": "2022-03-16T23:41:07.180Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.6883947342727358",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "bcc98671-0956-4ae8-9894-952f775a85eb",
					"created_datetime": "2022-03-16T23:41:07.184Z",
					"updated_datetime": "2022-03-16T23:41:07.184Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.53065214093878",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "91f4a1cf-e7f9-4045-98bf-e1618df094cb",
					"created_datetime": "2022-03-16T23:41:07.188Z",
					"updated_datetime": "2022-03-16T23:41:07.188Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.5119617783542296",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "593d941d-9833-40df-9798-89adf7758c29",
					"created_datetime": "2022-03-16T23:41:07.191Z",
					"updated_datetime": "2022-03-16T23:41:07.191Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability_scap_left",
					"value_string": "0.43679797681591825",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "bab32e61-c647-40eb-a136-414b42b33888",
					"created_datetime": "2022-03-16T23:41:07.198Z",
					"updated_datetime": "2022-03-16T23:41:07.198Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability_scap_right",
					"value_string": "0.4155986815857487",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "9639979e-a130-4f1d-a613-2b3c9a31bfac",
					"created_datetime": "2022-03-16T23:41:07.201Z",
					"updated_datetime": "2022-03-16T23:41:07.201Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.4381909775747971",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "1246bbe4-b96e-4090-b6db-8ea3cecf3adf",
					"created_datetime": "2022-03-16T23:41:07.204Z",
					"updated_datetime": "2022-03-16T23:41:07.204Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.1337737301961138",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "ef7f1ee3-5f4a-4a01-98bc-21da16919f35",
					"created_datetime": "2022-03-16T23:41:07.210Z",
					"updated_datetime": "2022-03-16T23:41:07.210Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.022388274968735185",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "394b333e-4f44-4e64-982a-c9b7a4f7f52b",
					"created_datetime": "2022-03-16T23:41:07.212Z",
					"updated_datetime": "2022-03-16T23:41:07.212Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.11246835731623435",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "eacd7f8a-a3a1-416c-8055-3ad59ba9d5c2",
					"created_datetime": "2022-03-16T23:41:07.215Z",
					"updated_datetime": "2022-03-16T23:41:07.215Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.4777857923045014",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "c4e45f22-1af1-4227-b08b-616c5aab7e2f",
					"created_datetime": "2022-03-16T23:41:07.218Z",
					"updated_datetime": "2022-03-16T23:41:07.218Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.10929965054175222",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "f50714f4-ce25-472a-b138-c1e4445c33ff",
					"created_datetime": "2022-03-16T23:41:07.221Z",
					"updated_datetime": "2022-03-16T23:41:07.221Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.6706721900728584",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "f311f63d-6eda-4d42-ab72-9943461cfc3a",
					"created_datetime": "2022-03-16T23:41:07.223Z",
					"updated_datetime": "2022-03-16T23:41:07.223Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.24469524041630675",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "0be022bf-0ae9-48b6-b592-9a97907befe1",
					"created_datetime": "2022-03-16T23:41:07.226Z",
					"updated_datetime": "2022-03-16T23:41:07.226Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8767939788213163",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "c2eaff4b-b116-43ec-8e44-c32f59892c73",
					"created_datetime": "2022-03-16T23:41:07.229Z",
					"updated_datetime": "2022-03-16T23:41:07.229Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8790801169270109",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "bb7be700-8634-4b18-9c65-32c26ad12475",
					"created_datetime": "2022-03-16T23:41:07.232Z",
					"updated_datetime": "2022-03-16T23:41:07.232Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_knee",
					"value_string": "0.058649593294357774",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "e46aa296-ba46-4902-b76a-024776649ad9",
					"created_datetime": "2022-03-16T23:41:07.237Z",
					"updated_datetime": "2022-03-16T23:41:07.237Z",
					"assessment_id_guid": "e8d7367e-c83f-4c7b-be13-5fce227b9a72",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.6703281217270138",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
			"created_datetime": "2022-03-16T23:41:13.842Z",
			"updated_datetime": "2022-03-16T23:41:13.842Z",
			"submitter_user_id_guid": "5ebf4b0b-8aeb-40ae-99e2-31db7bd84d38",
			"athlete_user_id_guid": "c11cee63-5697-46ab-a2da-1f7e4542114f",
			"external_id_guid": "d033cea9-f845-4b78-98f5-cbd707a25617",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "4d2dcace-3b9f-45a0-8f1e-9e5c5c9f3dde",
					"created_datetime": "2022-03-16T23:41:13.846Z",
					"updated_datetime": "2022-03-16T23:41:13.846Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "ios_datetime",
					"value_string": "2022-03-16T23:44:02.684Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "dda45ce8-0bf4-4de4-a935-8502dc550315",
					"created_datetime": "2022-03-16T23:41:13.850Z",
					"updated_datetime": "2022-03-16T23:41:13.850Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "5c1545e7-0fd5-4391-abce-4f70ed651563",
					"created_datetime": "2022-03-16T23:41:13.853Z",
					"updated_datetime": "2022-03-16T23:41:13.853Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-58.90718919527495",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "7eee86fb-8189-423f-aac8-2229f5a7c4af",
					"created_datetime": "2022-03-16T23:41:13.857Z",
					"updated_datetime": "2022-03-16T23:41:13.857Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "149.72528809896681",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "fa7d2d88-1cfd-4598-be75-fd7648c386f7",
					"created_datetime": "2022-03-16T23:41:13.860Z",
					"updated_datetime": "2022-03-16T23:41:13.860Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "32.286395236585946",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "f1b58b61-0690-4faf-b8f4-75e2b74ed75e",
					"created_datetime": "2022-03-16T23:41:13.863Z",
					"updated_datetime": "2022-03-16T23:41:13.863Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "30.887390328097467",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "03a2d582-a213-4d56-a535-eba08946ccdb",
					"created_datetime": "2022-03-16T23:41:13.867Z",
					"updated_datetime": "2022-03-16T23:41:13.867Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-53.43548977012033",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "8f250dea-2ace-436f-9be1-75b5504e7f2e",
					"created_datetime": "2022-03-16T23:41:13.870Z",
					"updated_datetime": "2022-03-16T23:41:13.870Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-79.0632307442873",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "40045dd8-6285-4dcb-a3d1-a1f596effb96",
					"created_datetime": "2022-03-16T23:41:13.874Z",
					"updated_datetime": "2022-03-16T23:41:13.874Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "85.75404563655803",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "4ca3e1d4-78d7-41af-baf8-eccfb128abb9",
					"created_datetime": "2022-03-16T23:41:13.877Z",
					"updated_datetime": "2022-03-16T23:41:13.877Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-47.09954040212389",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "123f7c63-4ae1-4e8d-849a-428f898b2cc6",
					"created_datetime": "2022-03-16T23:41:13.880Z",
					"updated_datetime": "2022-03-16T23:41:13.880Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-46.591024490227596",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "03bbb953-7aab-48cc-a66c-79c2deb220a0",
					"created_datetime": "2022-03-16T23:41:13.884Z",
					"updated_datetime": "2022-03-16T23:41:13.884Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-148.19137797444014",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "5bfcfd18-8bb0-48c1-8b5d-57c934ad2aaf",
					"created_datetime": "2022-03-16T23:41:13.887Z",
					"updated_datetime": "2022-03-16T23:41:13.887Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "69.12256965154114",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "bd501c41-c50c-4ed6-849d-9a056b8b6cc9",
					"created_datetime": "2022-03-16T23:41:13.892Z",
					"updated_datetime": "2022-03-16T23:41:13.892Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "177.5334031493577",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "602a289c-0c46-40ce-88d0-e372f694c5e3",
					"created_datetime": "2022-03-16T23:41:13.897Z",
					"updated_datetime": "2022-03-16T23:41:13.897Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-100.62795644432038",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "23ca125d-e9e0-4336-8d47-fd80c571800b",
					"created_datetime": "2022-03-16T23:41:13.900Z",
					"updated_datetime": "2022-03-16T23:41:13.900Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-26.874855142582305",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "dc8afaa4-51cd-4196-a0bb-406ba548c371",
					"created_datetime": "2022-03-16T23:41:13.904Z",
					"updated_datetime": "2022-03-16T23:41:13.904Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "60.43810562678513",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "e34ebb59-4861-4610-b9fc-0126fbee40e2",
					"created_datetime": "2022-03-16T23:41:13.908Z",
					"updated_datetime": "2022-03-16T23:41:13.908Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "30.066704069387356",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "2a724dab-681c-4a44-9deb-8f6b802b6980",
					"created_datetime": "2022-03-16T23:41:13.911Z",
					"updated_datetime": "2022-03-16T23:41:13.911Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "48.604786067025145",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "5c602867-63c9-4e0b-9d18-09e1d4881561",
					"created_datetime": "2022-03-16T23:41:13.924Z",
					"updated_datetime": "2022-03-16T23:41:13.924Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "140.50035645047313",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8211bb42-701e-44d9-9562-4baccdacdbb4",
					"created_datetime": "2022-03-16T23:41:13.927Z",
					"updated_datetime": "2022-03-16T23:41:13.927Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-100.8432909915468",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "614501c0-3ab9-4d15-8d35-2bf72d79a822",
					"created_datetime": "2022-03-16T23:41:13.930Z",
					"updated_datetime": "2022-03-16T23:41:13.930Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-178.34289400491295",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "4d502673-9651-4230-b311-e348adf16698",
					"created_datetime": "2022-03-16T23:41:13.933Z",
					"updated_datetime": "2022-03-16T23:41:13.933Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "94.0715574116499",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "2b8a9e81-5e40-408f-8251-826538672235",
					"created_datetime": "2022-03-16T23:41:13.938Z",
					"updated_datetime": "2022-03-16T23:41:13.938Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "11.907699282234631",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "212cb0af-7c97-4644-af13-89efa47fe49a",
					"created_datetime": "2022-03-16T23:41:13.941Z",
					"updated_datetime": "2022-03-16T23:41:13.941Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-13.140422301937463",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "389781dd-ef24-4383-82fc-3329883f5a3b",
					"created_datetime": "2022-03-16T23:41:13.944Z",
					"updated_datetime": "2022-03-16T23:41:13.944Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-85.10781096706886",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "c2c59b7f-a938-45dc-8b3d-c5e283e2dd8f",
					"created_datetime": "2022-03-16T23:41:13.946Z",
					"updated_datetime": "2022-03-16T23:41:13.946Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_knee",
					"value_string": "65.64863056534406",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "3578d671-39ad-4016-b13d-2570a4d21f1b",
					"created_datetime": "2022-03-16T23:41:13.949Z",
					"updated_datetime": "2022-03-16T23:41:13.949Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-148.15946260771028",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "a3af1593-927e-487b-abd6-9d73fb24a2d7",
					"created_datetime": "2022-03-16T23:41:13.952Z",
					"updated_datetime": "2022-03-16T23:41:13.952Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_composite",
					"value_string": "0.8006612652880382",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "03cf8cb2-ef7e-408e-ad1e-214688dd3b20",
					"created_datetime": "2022-03-16T23:41:13.956Z",
					"updated_datetime": "2022-03-16T23:41:13.956Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility",
					"value_string": "0.5181455038890671",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "941503b9-f874-4d77-a0db-907ab5d17e05",
					"created_datetime": "2022-03-16T23:41:13.959Z",
					"updated_datetime": "2022-03-16T23:41:13.959Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability",
					"value_string": "0.5867596273706334",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "93b0ee44-2445-4a86-9492-cf321055d193",
					"created_datetime": "2022-03-16T23:41:13.961Z",
					"updated_datetime": "2022-03-16T23:41:13.961Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_posture",
					"value_string": "0.43840146495933074",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "76c138da-7dab-4fc0-a953-a530df3b4a28",
					"created_datetime": "2022-03-16T23:41:13.965Z",
					"updated_datetime": "2022-03-16T23:41:13.965Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry",
					"value_string": "0.24602692052443692",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "595204ad-b59d-407f-84ea-380c0bd91c63",
					"created_datetime": "2022-03-16T23:41:13.967Z",
					"updated_datetime": "2022-03-16T23:41:13.967Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.41604530825300523",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "094537bc-ad33-45cb-b5de-7863887a13a6",
					"created_datetime": "2022-03-16T23:41:13.970Z",
					"updated_datetime": "2022-03-16T23:41:13.970Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5276213809404527",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "b31c6237-91b2-4ff6-bc12-af492c1aea74",
					"created_datetime": "2022-03-16T23:41:13.973Z",
					"updated_datetime": "2022-03-16T23:41:13.973Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.3917965591123399",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "7702bd56-2238-4301-a457-764c9682141d",
					"created_datetime": "2022-03-16T23:41:13.976Z",
					"updated_datetime": "2022-03-16T23:41:13.976Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.1304408312874673",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "a825521e-85f7-4823-9ff8-e608037193c7",
					"created_datetime": "2022-03-16T23:41:13.979Z",
					"updated_datetime": "2022-03-16T23:41:13.979Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.4993996026548283",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "322d2939-e625-4f6f-a5d2-0f2d2171da6a",
					"created_datetime": "2022-03-16T23:41:13.982Z",
					"updated_datetime": "2022-03-16T23:41:13.982Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5517464635422297",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "58936014-1e2d-4d78-b2df-0596da7783ab",
					"created_datetime": "2022-03-16T23:41:13.984Z",
					"updated_datetime": "2022-03-16T23:41:13.984Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.47089927451015695",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "2414abcd-29c9-4c96-82c7-985a5026da3b",
					"created_datetime": "2022-03-16T23:41:13.987Z",
					"updated_datetime": "2022-03-16T23:41:13.987Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.718825153854525",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "657c9b73-b43f-4e0a-862f-459c14d50f9d",
					"created_datetime": "2022-03-16T23:41:13.990Z",
					"updated_datetime": "2022-03-16T23:41:13.990Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9004067525704795",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "fb3e9372-9500-4ef2-a0dc-e308a6766bf1",
					"created_datetime": "2022-03-16T23:41:13.996Z",
					"updated_datetime": "2022-03-16T23:41:13.996Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.010665774572256839",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "e2d42edd-1580-42b9-9682-1af56c1490a5",
					"created_datetime": "2022-03-16T23:41:13.999Z",
					"updated_datetime": "2022-03-16T23:41:13.999Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.60248067437643",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "9dd4e434-868d-4f15-9216-462d7d263ec2",
					"created_datetime": "2022-03-16T23:41:14.004Z",
					"updated_datetime": "2022-03-16T23:41:14.004Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.4168293985266227",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "874dff5c-9090-4875-8c9d-631a1deb9bf2",
					"created_datetime": "2022-03-16T23:41:14.009Z",
					"updated_datetime": "2022-03-16T23:41:14.009Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability_scap_left",
					"value_string": "0.25815273028157304",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "15eb4cf7-9c5a-4948-9d07-b7ce1b58fd6a",
					"created_datetime": "2022-03-16T23:41:14.012Z",
					"updated_datetime": "2022-03-16T23:41:14.012Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8991062963020285",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "9e798233-5bf8-4741-b249-a378c21d4063",
					"created_datetime": "2022-03-16T23:41:14.015Z",
					"updated_datetime": "2022-03-16T23:41:14.015Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.11302652596357557",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "e33eee02-46e6-4d3b-b3f1-79f0e8a5f7d4",
					"created_datetime": "2022-03-16T23:41:14.020Z",
					"updated_datetime": "2022-03-16T23:41:14.020Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.4343254025786191",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "6f3cd7fc-3d32-4fe7-a7ad-68185d43655e",
					"created_datetime": "2022-03-16T23:41:14.023Z",
					"updated_datetime": "2022-03-16T23:41:14.023Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.2988943510584578",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "a7c19e56-109b-4628-9573-39d917d9f96e",
					"created_datetime": "2022-03-16T23:41:14.026Z",
					"updated_datetime": "2022-03-16T23:41:14.026Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.1588714281351621",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "973f007e-884f-446e-bc7b-3b8b09664665",
					"created_datetime": "2022-03-16T23:41:14.029Z",
					"updated_datetime": "2022-03-16T23:41:14.029Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.3971641521085688",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "0647dd3f-546d-4a25-a872-6b4b0b31b7b2",
					"created_datetime": "2022-03-16T23:41:14.036Z",
					"updated_datetime": "2022-03-16T23:41:14.036Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.3543011849641066",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "79b1f60e-8270-48ca-950d-473b40de4a2e",
					"created_datetime": "2022-03-16T23:41:14.039Z",
					"updated_datetime": "2022-03-16T23:41:14.039Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.3512839257840791",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "c863ae23-6bc3-4ab1-84de-e5098666889a",
					"created_datetime": "2022-03-16T23:41:14.043Z",
					"updated_datetime": "2022-03-16T23:41:14.043Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.8979267590789528",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "2e4de741-ba21-4df0-9571-283f0106402b",
					"created_datetime": "2022-03-16T23:41:14.046Z",
					"updated_datetime": "2022-03-16T23:41:14.046Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9397133966329263",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "eddf00f8-9e0d-4693-9a6d-a7bd05886af7",
					"created_datetime": "2022-03-16T23:41:14.050Z",
					"updated_datetime": "2022-03-16T23:41:14.050Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_femur",
					"value_string": "0.876881870668491",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "410c140e-c1ea-4bb3-8f1d-4167828a9890",
					"created_datetime": "2022-03-16T23:41:14.053Z",
					"updated_datetime": "2022-03-16T23:41:14.053Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_knee",
					"value_string": "0.03139663497687075",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "79600e4d-f656-4a2e-b961-e2622e7a62a2",
					"created_datetime": "2022-03-16T23:41:14.056Z",
					"updated_datetime": "2022-03-16T23:41:14.056Z",
					"assessment_id_guid": "1bbe0ec6-8b78-477c-8136-d3ed084747b0",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.7373791268135004",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
			"created_datetime": "2022-03-17T01:00:29.228Z",
			"updated_datetime": "2022-03-17T01:00:29.228Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "6918961f-1325-4e75-aa88-54b15ba28d5c",
			"external_id_guid": "027c91e4-fc37-468a-aada-c2b4ec093596",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c0b25878-1daf-4d07-9099-f7eadd9c9ca0",
					"created_datetime": "2022-03-17T01:00:29.232Z",
					"updated_datetime": "2022-03-17T01:00:29.232Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T01:03:18.038Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "c3497741-e593-4edb-b8fd-1f23a6c4df88",
					"created_datetime": "2022-03-17T01:00:29.239Z",
					"updated_datetime": "2022-03-17T01:00:29.239Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "cf6bb878-8e13-4410-87bc-b4a5f1d1ccdb",
					"created_datetime": "2022-03-17T01:00:29.242Z",
					"updated_datetime": "2022-03-17T01:00:29.242Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "42.431167158733814",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "17b179cb-aca8-49c4-884c-74a57519ce3a",
					"created_datetime": "2022-03-17T01:00:29.246Z",
					"updated_datetime": "2022-03-17T01:00:29.246Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-167.92252451089888",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "ba50e4ee-6fc8-40bd-8bbe-a962dcd0be31",
					"created_datetime": "2022-03-17T01:00:29.249Z",
					"updated_datetime": "2022-03-17T01:00:29.249Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "139.35806304763207",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "9b89d3e4-3815-408b-828c-65890c5983e3",
					"created_datetime": "2022-03-17T01:00:29.253Z",
					"updated_datetime": "2022-03-17T01:00:29.253Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-168.43245570115369",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "8808284d-84f7-46db-a290-d9a709f60c83",
					"created_datetime": "2022-03-17T01:00:29.256Z",
					"updated_datetime": "2022-03-17T01:00:29.256Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "161.13409538731213",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "97e04370-b809-47e5-9135-0e75436ffdeb",
					"created_datetime": "2022-03-17T01:00:29.259Z",
					"updated_datetime": "2022-03-17T01:00:29.259Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-96.63561296924982",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "5e3f45e4-e012-4cf3-b8a2-0a942a525087",
					"created_datetime": "2022-03-17T01:00:29.262Z",
					"updated_datetime": "2022-03-17T01:00:29.262Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "77.14942943877054",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "a81db42b-73a4-4244-a6a7-3fc999060e39",
					"created_datetime": "2022-03-17T01:00:29.266Z",
					"updated_datetime": "2022-03-17T01:00:29.266Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "172.4767078562241",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "b2dbaa5a-f757-4b5d-b5e0-dbc2c42ba9bd",
					"created_datetime": "2022-03-17T01:00:29.269Z",
					"updated_datetime": "2022-03-17T01:00:29.269Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-174.96356304184238",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "f2cbe378-f10f-4e83-b3c3-1c2eba26b5c2",
					"created_datetime": "2022-03-17T01:00:29.272Z",
					"updated_datetime": "2022-03-17T01:00:29.272Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "86.36578298799247",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "60318098-db63-4447-b2f3-c971eda8bc97",
					"created_datetime": "2022-03-17T01:00:29.276Z",
					"updated_datetime": "2022-03-17T01:00:29.276Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-110.03268540036052",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "19cbd423-6a5b-450a-a583-47e95c236aae",
					"created_datetime": "2022-03-17T01:00:29.279Z",
					"updated_datetime": "2022-03-17T01:00:29.279Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-122.89232077665417",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6ab6b613-3df4-4791-94b8-b949a1376c14",
					"created_datetime": "2022-03-17T01:00:29.282Z",
					"updated_datetime": "2022-03-17T01:00:29.282Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-173.4132402741914",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "374ffef2-aa1e-42b7-9aae-846ac5800d4d",
					"created_datetime": "2022-03-17T01:00:29.286Z",
					"updated_datetime": "2022-03-17T01:00:29.286Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-147.14528190229765",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "84e71dda-1c36-469c-b605-a28023c50dee",
					"created_datetime": "2022-03-17T01:00:29.290Z",
					"updated_datetime": "2022-03-17T01:00:29.290Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "132.23116285360527",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "cbf4ae3d-ce20-47d2-976a-0715b90c1a87",
					"created_datetime": "2022-03-17T01:00:29.293Z",
					"updated_datetime": "2022-03-17T01:00:29.293Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-60.54865695570345",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "c6621c8f-3a51-4458-8481-93a63af638c6",
					"created_datetime": "2022-03-17T01:00:29.296Z",
					"updated_datetime": "2022-03-17T01:00:29.296Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "127.7575726772414",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "15a1f6c5-c268-4d6a-a8ce-83d6dec66f89",
					"created_datetime": "2022-03-17T01:00:29.299Z",
					"updated_datetime": "2022-03-17T01:00:29.299Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "5.208026321989536",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "f3083168-7545-4db1-b37c-a837d3b1cbc5",
					"created_datetime": "2022-03-17T01:00:29.304Z",
					"updated_datetime": "2022-03-17T01:00:29.304Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-30.537539059620798",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "18dcd487-5cf5-49e4-bcd6-b0fabb95adfb",
					"created_datetime": "2022-03-17T01:00:29.307Z",
					"updated_datetime": "2022-03-17T01:00:29.307Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-150.44831221160058",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "830f30e0-af5e-486c-bf39-1e855b927ba2",
					"created_datetime": "2022-03-17T01:00:29.311Z",
					"updated_datetime": "2022-03-17T01:00:29.311Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-137.389730843831",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "d8b5d086-4bbe-439e-9852-7c3741ac7c9b",
					"created_datetime": "2022-03-17T01:00:29.314Z",
					"updated_datetime": "2022-03-17T01:00:29.314Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-15.035415640590628",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "92d776b4-8fde-4de4-8277-64eefead5229",
					"created_datetime": "2022-03-17T01:00:29.317Z",
					"updated_datetime": "2022-03-17T01:00:29.317Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-100.2375624449893",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "02ef17da-629f-4db1-b2fa-03d344f03b0f",
					"created_datetime": "2022-03-17T01:00:29.320Z",
					"updated_datetime": "2022-03-17T01:00:29.320Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_femur",
					"value_string": "57.92347856076583",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "aa47a61b-8a2c-4db8-b4a3-8d0cebe7b826",
					"created_datetime": "2022-03-17T01:00:29.324Z",
					"updated_datetime": "2022-03-17T01:00:29.324Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_knee",
					"value_string": "73.3837460349283",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "4daafc5b-d6f8-4a79-9ee6-abe5d116fb45",
					"created_datetime": "2022-03-17T01:00:29.329Z",
					"updated_datetime": "2022-03-17T01:00:29.329Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "69.14123831996565",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "dd293b2f-0fdd-4f68-a5bc-1df13a2f412b",
					"created_datetime": "2022-03-17T01:00:29.332Z",
					"updated_datetime": "2022-03-17T01:00:29.332Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_composite",
					"value_string": "0.10309331307738172",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "8dc5b2be-80bb-4517-9e18-9cb168fc2b16",
					"created_datetime": "2022-03-17T01:00:29.335Z",
					"updated_datetime": "2022-03-17T01:00:29.335Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility",
					"value_string": "0.29932646906016414",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "82907eb1-f011-432a-9885-6c53c9084081",
					"created_datetime": "2022-03-17T01:00:29.338Z",
					"updated_datetime": "2022-03-17T01:00:29.338Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability",
					"value_string": "0.8196367702522784",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "2ad5285b-0dfa-4c56-88b1-ca8aa7acbce4",
					"created_datetime": "2022-03-17T01:00:29.341Z",
					"updated_datetime": "2022-03-17T01:00:29.341Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_posture",
					"value_string": "0.9313302709792923",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "6a601624-85c0-4e3b-b5aa-9f33d4158698",
					"created_datetime": "2022-03-17T01:00:29.344Z",
					"updated_datetime": "2022-03-17T01:00:29.344Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry",
					"value_string": "0.44428111913213664",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "b9b5196c-a9c4-4f83-8f5a-ac6150dc42e5",
					"created_datetime": "2022-03-17T01:00:29.347Z",
					"updated_datetime": "2022-03-17T01:00:29.347Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.5763419564661459",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "f591f0ba-1842-4c79-b681-7f53d22113a3",
					"created_datetime": "2022-03-17T01:00:29.350Z",
					"updated_datetime": "2022-03-17T01:00:29.350Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.7757803927557092",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "82159e1b-0b82-4ee0-adc2-fea2a4615b84",
					"created_datetime": "2022-03-17T01:00:29.352Z",
					"updated_datetime": "2022-03-17T01:00:29.352Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8230289252010329",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "719b93fd-b70f-457b-9694-369a592d5315",
					"created_datetime": "2022-03-17T01:00:29.355Z",
					"updated_datetime": "2022-03-17T01:00:29.355Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.7510978780622458",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "6c66381e-f911-46ec-9dce-48257e91fedc",
					"created_datetime": "2022-03-17T01:00:29.357Z",
					"updated_datetime": "2022-03-17T01:00:29.357Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.3529638592417921",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "389a4306-6c63-4708-b851-7f9bcbdab39c",
					"created_datetime": "2022-03-17T01:00:29.360Z",
					"updated_datetime": "2022-03-17T01:00:29.360Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.6515905198818241",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "aeb49550-01a6-42b3-a099-840195931951",
					"created_datetime": "2022-03-17T01:00:29.363Z",
					"updated_datetime": "2022-03-17T01:00:29.363Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.940755154393042",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "b0bfa713-d082-4022-a919-dc5a71a87d96",
					"created_datetime": "2022-03-17T01:00:29.365Z",
					"updated_datetime": "2022-03-17T01:00:29.365Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8505185871098617",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "ec908d56-06c7-4889-bd7c-1b78482373bc",
					"created_datetime": "2022-03-17T01:00:29.368Z",
					"updated_datetime": "2022-03-17T01:00:29.368Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8987554482405388",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "1416abbb-2553-438e-80af-061ad839b35c",
					"created_datetime": "2022-03-17T01:00:29.370Z",
					"updated_datetime": "2022-03-17T01:00:29.370Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.5340632729140216",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "0dcc2c3a-0758-4525-a3cb-a44e60272553",
					"created_datetime": "2022-03-17T01:00:29.373Z",
					"updated_datetime": "2022-03-17T01:00:29.373Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.7429878818913868",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "9160428a-1257-4ec0-8627-f74f9285de9e",
					"created_datetime": "2022-03-17T01:00:29.376Z",
					"updated_datetime": "2022-03-17T01:00:29.376Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.5719863016202243",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "1a20a550-8803-4f21-9e4c-b83485168117",
					"created_datetime": "2022-03-17T01:00:29.378Z",
					"updated_datetime": "2022-03-17T01:00:29.378Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability_scap_left",
					"value_string": "0.6345230811913023",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "ec88726e-7604-4736-88b7-2e6167d8a761",
					"created_datetime": "2022-03-17T01:00:29.382Z",
					"updated_datetime": "2022-03-17T01:00:29.382Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability_scap_right",
					"value_string": "0.38104494410468037",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "f04cdebc-bf43-4ffd-a88d-fef58b780cf1",
					"created_datetime": "2022-03-17T01:00:29.384Z",
					"updated_datetime": "2022-03-17T01:00:29.384Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.6395246071921724",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "62a523bc-e227-4a9c-8544-b897a63be358",
					"created_datetime": "2022-03-17T01:00:29.387Z",
					"updated_datetime": "2022-03-17T01:00:29.387Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.586639712617523",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "f7078d00-6bad-4621-8f6e-2cd9e42c1572",
					"created_datetime": "2022-03-17T01:00:29.389Z",
					"updated_datetime": "2022-03-17T01:00:29.389Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.6513654395731248",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "c196ee46-e39e-4f47-bb34-26c2007e791a",
					"created_datetime": "2022-03-17T01:00:29.392Z",
					"updated_datetime": "2022-03-17T01:00:29.392Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.8036620382572417",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "54e2a457-e0ae-4872-ad49-007ac676e529",
					"created_datetime": "2022-03-17T01:00:29.394Z",
					"updated_datetime": "2022-03-17T01:00:29.394Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.322814193087288",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "8355d5cd-c190-4066-8a0b-b14281d7fdc7",
					"created_datetime": "2022-03-17T01:00:29.397Z",
					"updated_datetime": "2022-03-17T01:00:29.397Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.3306235966642851",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "aab4df82-219d-4ff7-92a3-eb43214dce90",
					"created_datetime": "2022-03-17T01:00:29.399Z",
					"updated_datetime": "2022-03-17T01:00:29.399Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.39878794195626344",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "5dad08d4-d2e8-4b7e-b5ba-9cf550ea2f91",
					"created_datetime": "2022-03-17T01:00:29.402Z",
					"updated_datetime": "2022-03-17T01:00:29.402Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.6346066108546091",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "6e3cb68d-af97-4631-9c3a-796a56450b64",
					"created_datetime": "2022-03-17T01:00:29.405Z",
					"updated_datetime": "2022-03-17T01:00:29.405Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_hip",
					"value_string": "0.37222999557537867",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "79f7206a-0503-4e79-b242-f8b3a7125389",
					"created_datetime": "2022-03-17T01:00:29.407Z",
					"updated_datetime": "2022-03-17T01:00:29.407Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9026501818127558",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "61f14fa4-33e8-42e2-a2ff-4b67fdd2ac8d",
					"created_datetime": "2022-03-17T01:00:29.410Z",
					"updated_datetime": "2022-03-17T01:00:29.410Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_knee",
					"value_string": "0.911970516302836",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "1cf2c8e2-300f-4ddf-aeae-e97ce3e10cce",
					"created_datetime": "2022-03-17T01:00:29.412Z",
					"updated_datetime": "2022-03-17T01:00:29.412Z",
					"assessment_id_guid": "da3dbd4f-0905-4ad4-bb2e-16efc4f2128b",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.06741384927469868",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
			"created_datetime": "2022-03-17T01:14:41.393Z",
			"updated_datetime": "2022-03-17T01:14:41.393Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"external_id_guid": "240fe874-7e18-4e17-9af3-dac76a4a69aa",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "e7b8fd99-9089-4229-a321-f4d40132d5f1",
					"created_datetime": "2022-03-17T01:14:41.398Z",
					"updated_datetime": "2022-03-17T01:14:41.398Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T01:17:30.177Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "9a79f3c8-7a9b-450c-95c4-0b8a7e922722",
					"created_datetime": "2022-03-17T01:14:41.401Z",
					"updated_datetime": "2022-03-17T01:14:41.401Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "a27b2003-4208-4afc-9c79-df20c7907dfd",
					"created_datetime": "2022-03-17T01:14:41.404Z",
					"updated_datetime": "2022-03-17T01:14:41.404Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-54.39027173772389",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "a5d84f70-8421-4d0d-ae28-60445235af54",
					"created_datetime": "2022-03-17T01:14:41.407Z",
					"updated_datetime": "2022-03-17T01:14:41.407Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-137.94267124865257",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "e8d5b3e8-1ff1-4eb7-9474-a39932ec848a",
					"created_datetime": "2022-03-17T01:14:41.410Z",
					"updated_datetime": "2022-03-17T01:14:41.410Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "98.84831679521477",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "a4b9df87-6563-4e37-b90e-c3f039a84622",
					"created_datetime": "2022-03-17T01:14:41.413Z",
					"updated_datetime": "2022-03-17T01:14:41.413Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "74.26037628900588",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "631d4771-7747-403b-8b96-32cf526495c1",
					"created_datetime": "2022-03-17T01:14:41.415Z",
					"updated_datetime": "2022-03-17T01:14:41.415Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-13.35412364271997",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "c7473468-f7db-461a-8ded-1aa90c5e7c76",
					"created_datetime": "2022-03-17T01:14:41.418Z",
					"updated_datetime": "2022-03-17T01:14:41.418Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-162.83004222128986",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "c91dd0da-ea0a-4eee-ace5-2a960b9742af",
					"created_datetime": "2022-03-17T01:14:41.421Z",
					"updated_datetime": "2022-03-17T01:14:41.421Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "46.71436094104857",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "ada533bd-a099-44f6-8aca-92c1315dd057",
					"created_datetime": "2022-03-17T01:14:41.426Z",
					"updated_datetime": "2022-03-17T01:14:41.426Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "153.27742906803172",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "2585bb9b-66e7-481a-841b-61703aa6b01c",
					"created_datetime": "2022-03-17T01:14:41.428Z",
					"updated_datetime": "2022-03-17T01:14:41.428Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "127.10232613540904",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "e33bfe4c-0eda-4a07-8c9d-a6589ff0bda7",
					"created_datetime": "2022-03-17T01:14:41.443Z",
					"updated_datetime": "2022-03-17T01:14:41.443Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "82.23649704988247",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "9f2f7a50-4dd2-417d-ab5c-5ee4face9944",
					"created_datetime": "2022-03-17T01:14:41.446Z",
					"updated_datetime": "2022-03-17T01:14:41.446Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "158.9285255342997",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "3b9aa6e3-4218-4ca9-89b0-35a2bf7ec1ec",
					"created_datetime": "2022-03-17T01:14:41.450Z",
					"updated_datetime": "2022-03-17T01:14:41.450Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "94.48961074575453",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "0553e5b7-7191-45c2-9874-058620ca1f25",
					"created_datetime": "2022-03-17T01:14:41.454Z",
					"updated_datetime": "2022-03-17T01:14:41.454Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-14.929689865023192",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "d646bcb4-d8c3-4be1-b3e7-d169e9cf7439",
					"created_datetime": "2022-03-17T01:14:41.457Z",
					"updated_datetime": "2022-03-17T01:14:41.457Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_stability_scap_right",
					"value_string": "31.96753137775579",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "e28ddee7-5730-4a58-adc4-18642c7d19de",
					"created_datetime": "2022-03-17T01:14:41.461Z",
					"updated_datetime": "2022-03-17T01:14:41.461Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "119.80078907130206",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "89e3cbee-c19f-4dce-b985-a61e3a0368d0",
					"created_datetime": "2022-03-17T01:14:41.464Z",
					"updated_datetime": "2022-03-17T01:14:41.464Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "3.841488841022084",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "47b81411-e19d-4085-a2cc-7dcf42705c48",
					"created_datetime": "2022-03-17T01:14:41.467Z",
					"updated_datetime": "2022-03-17T01:14:41.467Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-135.19438507750624",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "77814094-b2ce-4896-9f18-5d0dfa6aa407",
					"created_datetime": "2022-03-17T01:14:41.470Z",
					"updated_datetime": "2022-03-17T01:14:41.470Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-33.6188987698217",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "bddbfec5-49e5-44d6-997a-4072a88f3b0f",
					"created_datetime": "2022-03-17T01:14:41.474Z",
					"updated_datetime": "2022-03-17T01:14:41.474Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "11.808501741000498",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "5ad1b7fb-051f-4b6c-a5fe-18eb481dc224",
					"created_datetime": "2022-03-17T01:14:41.477Z",
					"updated_datetime": "2022-03-17T01:14:41.477Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "112.19080557757309",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "40ab6429-49c9-40fc-b9f9-f7d3b4a0a102",
					"created_datetime": "2022-03-17T01:14:41.481Z",
					"updated_datetime": "2022-03-17T01:14:41.481Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-145.42133544258274",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "a6df5e78-b4ff-4bf5-abdd-43b7d711255e",
					"created_datetime": "2022-03-17T01:14:41.484Z",
					"updated_datetime": "2022-03-17T01:14:41.484Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "32.32697436248253",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "c92b51c7-5023-460a-9141-8eb2cd9cc492",
					"created_datetime": "2022-03-17T01:14:41.489Z",
					"updated_datetime": "2022-03-17T01:14:41.489Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-76.16231325185773",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "47530c2b-7331-4b54-b70f-66ff4447d9e7",
					"created_datetime": "2022-03-17T01:14:41.493Z",
					"updated_datetime": "2022-03-17T01:14:41.493Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_femur",
					"value_string": "37.63176726907153",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "437d7b54-0d28-4c93-a34d-c4504e2dfd51",
					"created_datetime": "2022-03-17T01:14:41.497Z",
					"updated_datetime": "2022-03-17T01:14:41.497Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_knee",
					"value_string": "171.9120393196273",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "a01f4274-bd25-4a23-9e0d-ac96a2fec988",
					"created_datetime": "2022-03-17T01:14:41.500Z",
					"updated_datetime": "2022-03-17T01:14:41.500Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "1.9681481405079921",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "6bdaf488-6dca-4e5c-9e99-3da154a02844",
					"created_datetime": "2022-03-17T01:14:41.504Z",
					"updated_datetime": "2022-03-17T01:14:41.504Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_composite",
					"value_string": "0.5394409029011784",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "3a8b8ad9-a210-4b5c-b38b-167812cf1e33",
					"created_datetime": "2022-03-17T01:14:41.507Z",
					"updated_datetime": "2022-03-17T01:14:41.507Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility",
					"value_string": "0.8111325702566181",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "c845d0c5-af5c-4559-81fe-3035525db127",
					"created_datetime": "2022-03-17T01:14:41.510Z",
					"updated_datetime": "2022-03-17T01:14:41.510Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability",
					"value_string": "0.03771008224648602",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "a9c9c253-a95a-4812-bc9c-a15e81e16f54",
					"created_datetime": "2022-03-17T01:14:41.512Z",
					"updated_datetime": "2022-03-17T01:14:41.512Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_posture",
					"value_string": "0.8253959515404761",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "79ebc661-f70c-4526-81f1-02727c04a948",
					"created_datetime": "2022-03-17T01:14:41.515Z",
					"updated_datetime": "2022-03-17T01:14:41.515Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry",
					"value_string": "0.30365115968247414",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "15002e92-8da5-49d2-9d94-bedbf1f484e2",
					"created_datetime": "2022-03-17T01:14:41.519Z",
					"updated_datetime": "2022-03-17T01:14:41.519Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9215280555525546",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "b33c3052-51f1-43b8-8c76-b5a1a22cb440",
					"created_datetime": "2022-03-17T01:14:41.522Z",
					"updated_datetime": "2022-03-17T01:14:41.522Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6460696292172978",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "6c294f44-2a6c-4396-b165-27303468e720",
					"created_datetime": "2022-03-17T01:14:41.526Z",
					"updated_datetime": "2022-03-17T01:14:41.526Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8952627053613321",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "efa91719-4de2-414d-b465-b4a87187407a",
					"created_datetime": "2022-03-17T01:14:41.529Z",
					"updated_datetime": "2022-03-17T01:14:41.529Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.45901032111348444",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "bf899be9-c0b5-4769-8e5a-de1e7a923977",
					"created_datetime": "2022-03-17T01:14:41.532Z",
					"updated_datetime": "2022-03-17T01:14:41.532Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.08927355267859029",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "0befda82-afac-40c9-985b-e940988b841f",
					"created_datetime": "2022-03-17T01:14:41.535Z",
					"updated_datetime": "2022-03-17T01:14:41.535Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.4952693648519774",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "eeb809df-e010-4b93-a1c3-d5de6b502978",
					"created_datetime": "2022-03-17T01:14:41.538Z",
					"updated_datetime": "2022-03-17T01:14:41.538Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.06744495866382719",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "2f39335c-b6fd-4bfb-ba00-155b24d252c6",
					"created_datetime": "2022-03-17T01:14:41.541Z",
					"updated_datetime": "2022-03-17T01:14:41.541Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.979243897394312",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "990bd2a5-6135-4714-9447-13f05826565c",
					"created_datetime": "2022-03-17T01:14:41.544Z",
					"updated_datetime": "2022-03-17T01:14:41.544Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.2999327013927223",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "ca8ddd6b-3c50-49e4-bb82-96501efa4fc6",
					"created_datetime": "2022-03-17T01:14:41.547Z",
					"updated_datetime": "2022-03-17T01:14:41.547Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.9244351444396616",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "84967cd6-ccbd-4bb6-9f1a-f7314a73bd06",
					"created_datetime": "2022-03-17T01:14:41.551Z",
					"updated_datetime": "2022-03-17T01:14:41.551Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.917948555601298",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "095c56cc-4d99-475d-9838-d14a42794c01",
					"created_datetime": "2022-03-17T01:14:41.554Z",
					"updated_datetime": "2022-03-17T01:14:41.554Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.5985031139023476",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "b6c45e50-6326-4150-8681-fd421adda0fd",
					"created_datetime": "2022-03-17T01:14:41.563Z",
					"updated_datetime": "2022-03-17T01:14:41.563Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability_scap_left",
					"value_string": "0.5750978439472788",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "5e188dbc-7271-4a1f-bd22-0a53de223188",
					"created_datetime": "2022-03-17T01:14:41.567Z",
					"updated_datetime": "2022-03-17T01:14:41.567Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability_scap_right",
					"value_string": "0.2274549944355564",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "0e01729d-870d-4928-9ce0-d21ad6cf895d",
					"created_datetime": "2022-03-17T01:14:41.571Z",
					"updated_datetime": "2022-03-17T01:14:41.571Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.3196236705210283",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "3733cf53-ae25-42ab-a3d9-e809ef26ae2d",
					"created_datetime": "2022-03-17T01:14:41.574Z",
					"updated_datetime": "2022-03-17T01:14:41.574Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.4927697284232342",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "a1223ad2-ab48-4dff-89aa-06751c36cab1",
					"created_datetime": "2022-03-17T01:14:41.578Z",
					"updated_datetime": "2022-03-17T01:14:41.578Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.39692225513650325",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "651443e6-de21-43cf-b370-3e43e7386c38",
					"created_datetime": "2022-03-17T01:14:41.581Z",
					"updated_datetime": "2022-03-17T01:14:41.581Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.04197191568807941",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "59f1cc3d-4125-44d8-a7f4-428fc2245b0f",
					"created_datetime": "2022-03-17T01:14:41.584Z",
					"updated_datetime": "2022-03-17T01:14:41.584Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.8118961339793815",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "5bd6e400-0096-4cec-afe8-cf5b253eb418",
					"created_datetime": "2022-03-17T01:14:41.587Z",
					"updated_datetime": "2022-03-17T01:14:41.587Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.5401439639595598",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "b5914fc3-0f7f-4799-be2b-f916ea245bff",
					"created_datetime": "2022-03-17T01:14:41.589Z",
					"updated_datetime": "2022-03-17T01:14:41.589Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.6267144952510496",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "434db4d7-e01e-469a-888e-afec42c53f93",
					"created_datetime": "2022-03-17T01:14:41.592Z",
					"updated_datetime": "2022-03-17T01:14:41.592Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.5407269282778139",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "d57263ed-182f-4844-b81a-997d23ff1bc5",
					"created_datetime": "2022-03-17T01:14:41.596Z",
					"updated_datetime": "2022-03-17T01:14:41.596Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9677554585109569",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "25e95f30-905c-4ed4-9825-cd48ed4db177",
					"created_datetime": "2022-03-17T01:14:41.599Z",
					"updated_datetime": "2022-03-17T01:14:41.599Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_femur",
					"value_string": "0.19065937200024616",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "b5d39ca6-3b4f-4571-9e24-318036625bac",
					"created_datetime": "2022-03-17T01:14:41.602Z",
					"updated_datetime": "2022-03-17T01:14:41.602Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_knee",
					"value_string": "0.49054079786688737",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "6e47078d-c4f5-4d7f-a0ff-f964eabb8070",
					"created_datetime": "2022-03-17T01:14:41.605Z",
					"updated_datetime": "2022-03-17T01:14:41.605Z",
					"assessment_id_guid": "35490462-6582-4e37-adb5-472c5bfd0b9a",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.045989786374260815",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
			"created_datetime": "2022-03-17T01:19:45.657Z",
			"updated_datetime": "2022-03-17T01:19:45.657Z",
			"submitter_user_id_guid": "1bba48ad-d774-41fe-b868-b50d9d1788a1",
			"athlete_user_id_guid": "1e2f3941-95d0-48d0-be2a-2cf1cbddd635",
			"external_id_guid": "fbde1bac-bd9e-4bf8-97ed-086b972455e8",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "5c15f6ca-bb3f-478f-8eb4-58be283b82f3",
					"created_datetime": "2022-03-17T01:19:45.661Z",
					"updated_datetime": "2022-03-17T01:19:45.661Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T01:19:45.511Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "c547fa4d-44ca-4e0a-a556-8f5b56cf5d2e",
					"created_datetime": "2022-03-17T01:19:45.665Z",
					"updated_datetime": "2022-03-17T01:19:45.665Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "5d4f17d0-2fd3-4473-8955-3528afa251ae",
					"created_datetime": "2022-03-17T01:19:45.668Z",
					"updated_datetime": "2022-03-17T01:19:45.668Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "167.1009521484375",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "27d06c56-2543-4647-bf15-fe3649c5e9c3",
					"created_datetime": "2022-03-17T01:19:45.671Z",
					"updated_datetime": "2022-03-17T01:19:45.671Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "146.7847442626953",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "294365e1-9626-438e-8237-99ab515db57e",
					"created_datetime": "2022-03-17T01:19:45.675Z",
					"updated_datetime": "2022-03-17T01:19:45.675Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "159.71495056152344",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "d848beb4-d6e0-4777-851e-b8008906148b",
					"created_datetime": "2022-03-17T01:19:45.678Z",
					"updated_datetime": "2022-03-17T01:19:45.678Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "150.81736755371094",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "ade0c765-d97d-4b47-a025-65ba7fa6c5eb",
					"created_datetime": "2022-03-17T01:19:45.682Z",
					"updated_datetime": "2022-03-17T01:19:45.682Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "108.0068359375",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "a03a864b-7ec8-47a2-a5d9-9a6aaee2c5d4",
					"created_datetime": "2022-03-17T01:19:45.685Z",
					"updated_datetime": "2022-03-17T01:19:45.685Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "108.79278564453125",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "30db912e-c53d-457d-82fa-a6b134f4a36d",
					"created_datetime": "2022-03-17T01:19:45.688Z",
					"updated_datetime": "2022-03-17T01:19:45.688Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "64.79032135009766",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "30a323c3-8412-481f-bf1e-8b14dd99958f",
					"created_datetime": "2022-03-17T01:19:45.692Z",
					"updated_datetime": "2022-03-17T01:19:45.692Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "69.512939453125",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "347ee6be-17a0-4b30-99bf-d619fc8061d6",
					"created_datetime": "2022-03-17T01:19:45.695Z",
					"updated_datetime": "2022-03-17T01:19:45.695Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "105.03632354736328",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "247731e2-5329-4c11-af88-d1d928b96705",
					"created_datetime": "2022-03-17T01:19:45.698Z",
					"updated_datetime": "2022-03-17T01:19:45.698Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "105.87430572509766",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "f101a449-49c2-4f37-9d3e-c39c9b3e6047",
					"created_datetime": "2022-03-17T01:19:45.701Z",
					"updated_datetime": "2022-03-17T01:19:45.701Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "47.3809814453125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "a597f266-99a4-4b90-ba1b-e39c3a4c42a8",
					"created_datetime": "2022-03-17T01:19:45.705Z",
					"updated_datetime": "2022-03-17T01:19:45.705Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "38.20050811767578",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "eba9091c-c4af-4d99-b707-95e1406f378f",
					"created_datetime": "2022-03-17T01:19:45.708Z",
					"updated_datetime": "2022-03-17T01:19:45.708Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_stability_scap_left",
					"value_string": "12.644831657409668",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "8daa1133-b320-4f0d-899b-e4f881f62079",
					"created_datetime": "2022-03-17T01:19:45.711Z",
					"updated_datetime": "2022-03-17T01:19:45.711Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_stability_scap_right",
					"value_string": "6.3456573486328125",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "f6f57681-4c46-46c4-a4d9-6099bab7c6f7",
					"created_datetime": "2022-03-17T01:19:45.714Z",
					"updated_datetime": "2022-03-17T01:19:45.714Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "45.18318557739258",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "addeb992-ec51-463e-a714-fa042f7259bd",
					"created_datetime": "2022-03-17T01:19:45.717Z",
					"updated_datetime": "2022-03-17T01:19:45.717Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "24.130216598510742",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "8d8d703e-c759-495a-8c85-14e7f14fd30b",
					"created_datetime": "2022-03-17T01:19:45.721Z",
					"updated_datetime": "2022-03-17T01:19:45.721Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "30.585805892944336",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "4a029d48-6f81-4f93-abd2-b1ccef556e81",
					"created_datetime": "2022-03-17T01:19:45.725Z",
					"updated_datetime": "2022-03-17T01:19:45.725Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-3.252312183380127",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "94fb3e3a-e6c5-46b0-9ede-1bda230e0f2f",
					"created_datetime": "2022-03-17T01:19:45.728Z",
					"updated_datetime": "2022-03-17T01:19:45.728Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "2.2061192989349365",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "c8b59c19-9ad9-4f19-a2f2-37139f9f8455",
					"created_datetime": "2022-03-17T01:19:45.731Z",
					"updated_datetime": "2022-03-17T01:19:45.731Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "18.598726272583008",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "080af0e1-46ec-4619-b46f-c0a73b52574e",
					"created_datetime": "2022-03-17T01:19:45.735Z",
					"updated_datetime": "2022-03-17T01:19:45.735Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "9.414017677307129",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "41d8de43-bd9c-4ae6-a84c-354323f6580c",
					"created_datetime": "2022-03-17T01:19:45.738Z",
					"updated_datetime": "2022-03-17T01:19:45.738Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.2972434759140015",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "b2214719-93e7-47fe-b942-253d2e1f2ab5",
					"created_datetime": "2022-03-17T01:19:45.741Z",
					"updated_datetime": "2022-03-17T01:19:45.741Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_hip",
					"value_string": "4.170711040496826",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "2420f4ff-abee-441a-9d7a-779149c1057d",
					"created_datetime": "2022-03-17T01:19:45.743Z",
					"updated_datetime": "2022-03-17T01:19:45.743Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_femur",
					"value_string": "6.452764987945557",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "5b609fe5-48ef-456f-b8c5-c631f5e3efcd",
					"created_datetime": "2022-03-17T01:19:45.746Z",
					"updated_datetime": "2022-03-17T01:19:45.746Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-0.020471056923270226",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "fb110521-ec09-443c-97e8-c5500a410ebe",
					"created_datetime": "2022-03-17T01:19:45.752Z",
					"updated_datetime": "2022-03-17T01:19:45.752Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-8.264068603515625",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "5733b7be-3cc6-4be0-bd92-2b3a820567c1",
					"created_datetime": "2022-03-17T01:19:45.755Z",
					"updated_datetime": "2022-03-17T01:19:45.755Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_composite",
					"value_string": "0.42421936988830566",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "4a6adffb-38f5-478c-bc8a-d30d6ffcddc2",
					"created_datetime": "2022-03-17T01:19:45.757Z",
					"updated_datetime": "2022-03-17T01:19:45.757Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility",
					"value_string": "0.4386610686779022",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "743c4077-2b24-4046-b770-643b06de293d",
					"created_datetime": "2022-03-17T01:19:45.760Z",
					"updated_datetime": "2022-03-17T01:19:45.760Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability",
					"value_string": "0.5140570402145386",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "e00e3e32-882f-4920-928d-909672ac8f4a",
					"created_datetime": "2022-03-17T01:19:45.763Z",
					"updated_datetime": "2022-03-17T01:19:45.763Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_posture",
					"value_string": "0.050041377544403076",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "23da0053-f8c3-4016-bdd3-1bb9243fe3ca",
					"created_datetime": "2022-03-17T01:19:45.766Z",
					"updated_datetime": "2022-03-17T01:19:45.766Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry",
					"value_string": "0.35051342844963074",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "b606d7a8-7cdb-48c9-a763-3fa0d0a38fde",
					"created_datetime": "2022-03-17T01:19:45.770Z",
					"updated_datetime": "2022-03-17T01:19:45.770Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7900890111923218",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "898ebd46-2424-4f7f-8ab3-aca577b486fb",
					"created_datetime": "2022-03-17T01:19:45.772Z",
					"updated_datetime": "2022-03-17T01:19:45.772Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.29195454716682434",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "2cb81073-dff5-43f5-a8b7-25f24e93133f",
					"created_datetime": "2022-03-17T01:19:45.775Z",
					"updated_datetime": "2022-03-17T01:19:45.775Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.6233646869659424",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "d5d33cb5-2d2d-4b2e-b91e-965db7c74bb5",
					"created_datetime": "2022-03-17T01:19:45.777Z",
					"updated_datetime": "2022-03-17T01:19:45.777Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.3901830315589905",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "f2a8b2c0-9050-41fa-8fd8-b56ff9f707cb",
					"created_datetime": "2022-03-17T01:19:45.781Z",
					"updated_datetime": "2022-03-17T01:19:45.781Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.500109076499939",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "a5e06e98-8e24-4555-9cad-0fff8b682870",
					"created_datetime": "2022-03-17T01:19:45.783Z",
					"updated_datetime": "2022-03-17T01:19:45.783Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5126489400863647",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "6260ac3f-902d-48df-a4da-432a91ac86c1",
					"created_datetime": "2022-03-17T01:19:45.785Z",
					"updated_datetime": "2022-03-17T01:19:45.785Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.43251484632492065",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "0e873f93-30eb-49b8-98d0-9540bc83bae7",
					"created_datetime": "2022-03-17T01:19:45.788Z",
					"updated_datetime": "2022-03-17T01:19:45.788Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.5766390562057495",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "fbe26fc4-0434-496c-bfb4-a295076ccc2e",
					"created_datetime": "2022-03-17T01:19:45.790Z",
					"updated_datetime": "2022-03-17T01:19:45.790Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.3091772794723511",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "e42bde71-495a-4423-a8e6-3efb1f9790de",
					"created_datetime": "2022-03-17T01:19:45.793Z",
					"updated_datetime": "2022-03-17T01:19:45.793Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.32409265637397766",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "0f2ce52f-aac7-44f1-81c8-44ab5544dc98",
					"created_datetime": "2022-03-17T01:19:45.795Z",
					"updated_datetime": "2022-03-17T01:19:45.795Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.4136166572570801",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "9244d992-8076-4305-b331-60488773fa35",
					"created_datetime": "2022-03-17T01:19:45.804Z",
					"updated_datetime": "2022-03-17T01:19:45.804Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.16273212432861328",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "9fdddd65-de9d-49ca-b633-fe29b7712d6b",
					"created_datetime": "2022-03-17T01:19:45.807Z",
					"updated_datetime": "2022-03-17T01:19:45.807Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability_scap_left",
					"value_string": "0.7300389409065247",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "c6f42d9f-1383-41c5-9158-3ea8b62518bd",
					"created_datetime": "2022-03-17T01:19:45.813Z",
					"updated_datetime": "2022-03-17T01:19:45.813Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8724108934402466",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "5980a303-9087-401d-9876-7492f47c133a",
					"created_datetime": "2022-03-17T01:19:45.816Z",
					"updated_datetime": "2022-03-17T01:19:45.816Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.30211806297302246",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "6e510194-3441-42b3-ae0d-f10813509593",
					"created_datetime": "2022-03-17T01:19:45.819Z",
					"updated_datetime": "2022-03-17T01:19:45.819Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.4653443694114685",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "ac180548-f1f0-4a69-a40c-0f5a08c34b29",
					"created_datetime": "2022-03-17T01:19:45.823Z",
					"updated_datetime": "2022-03-17T01:19:45.823Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.711776077747345",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "c3f123c7-05e7-4491-a07b-b62e272e21d3",
					"created_datetime": "2022-03-17T01:19:45.826Z",
					"updated_datetime": "2022-03-17T01:19:45.826Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.053298354148864746",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "32bce1d0-1925-468f-babb-0b751a3d96cd",
					"created_datetime": "2022-03-17T01:19:45.829Z",
					"updated_datetime": "2022-03-17T01:19:45.829Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.046784400939941406",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "59a8c5ed-4e12-4256-92a0-304ba8f86a9d",
					"created_datetime": "2022-03-17T01:19:45.833Z",
					"updated_datetime": "2022-03-17T01:19:45.833Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "e79d8ee4-cca1-473b-a9b9-ae3139e254aa",
					"created_datetime": "2022-03-17T01:19:45.837Z",
					"updated_datetime": "2022-03-17T01:19:45.837Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.03873085975646973",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "6be0f872-c67d-4a74-9a6d-27467be604e6",
					"created_datetime": "2022-03-17T01:19:45.840Z",
					"updated_datetime": "2022-03-17T01:19:45.840Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9851891994476318",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "0dd9c2f0-8e8f-4088-94aa-a53fa4cced3f",
					"created_datetime": "2022-03-17T01:19:45.844Z",
					"updated_datetime": "2022-03-17T01:19:45.844Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8227193355560303",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "fd36b75e-90d4-490d-8165-f9c50d25eae0",
					"created_datetime": "2022-03-17T01:19:45.848Z",
					"updated_datetime": "2022-03-17T01:19:45.848Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_femur",
					"value_string": "0.039888978004455566",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "4f27e0c5-3add-472c-8585-8a10a8f3a6a3",
					"created_datetime": "2022-03-17T01:19:45.851Z",
					"updated_datetime": "2022-03-17T01:19:45.851Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9957534074783325",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "1a1b86fb-3bd5-4ea0-99b3-4376a7dc48b0",
					"created_datetime": "2022-03-17T01:19:45.854Z",
					"updated_datetime": "2022-03-17T01:19:45.854Z",
					"assessment_id_guid": "e392fead-9a2f-4fd2-8f3f-fc2091d95ad8",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.18072175979614258",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
			"created_datetime": "2022-03-17T01:21:55.718Z",
			"updated_datetime": "2022-03-17T01:21:55.718Z",
			"submitter_user_id_guid": "1bba48ad-d774-41fe-b868-b50d9d1788a1",
			"athlete_user_id_guid": "d2909f8d-4fa0-4332-8624-cfe66fc6a73a",
			"external_id_guid": "4d11830a-e389-44a3-811c-6621bc8052a6",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "4c469cd4-cc8e-4757-b26a-5521a3ac9e13",
					"created_datetime": "2022-03-17T01:21:55.722Z",
					"updated_datetime": "2022-03-17T01:21:55.722Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T01:21:55.542Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "4fcb0be3-fab4-47e6-8022-e2a2bea43f81",
					"created_datetime": "2022-03-17T01:21:55.726Z",
					"updated_datetime": "2022-03-17T01:21:55.726Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.5",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "6ebba759-1dba-4840-bb33-c0e933a35115",
					"created_datetime": "2022-03-17T01:21:55.729Z",
					"updated_datetime": "2022-03-17T01:21:55.729Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "174.8018035888672",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "53e3a2dc-69f1-4536-ba93-9f532c8f4334",
					"created_datetime": "2022-03-17T01:21:55.732Z",
					"updated_datetime": "2022-03-17T01:21:55.732Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "150.09500122070312",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "00ced6af-0f64-4caa-bd64-967d6595f7ae",
					"created_datetime": "2022-03-17T01:21:55.736Z",
					"updated_datetime": "2022-03-17T01:21:55.736Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "162.68927001953125",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "fa517411-3d5f-4cbc-87f7-5d583922efbc",
					"created_datetime": "2022-03-17T01:21:55.739Z",
					"updated_datetime": "2022-03-17T01:21:55.739Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "152.2341766357422",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "7ac9be1b-b537-496e-9bd5-454031029450",
					"created_datetime": "2022-03-17T01:21:55.743Z",
					"updated_datetime": "2022-03-17T01:21:55.743Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "109.96136474609375",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "030f6336-8021-4fde-b4f4-e4737741a9ce",
					"created_datetime": "2022-03-17T01:21:55.746Z",
					"updated_datetime": "2022-03-17T01:21:55.746Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "108.38924407958984",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "c110326e-fa64-496b-a668-00d869e5eced",
					"created_datetime": "2022-03-17T01:21:55.749Z",
					"updated_datetime": "2022-03-17T01:21:55.749Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "67.7894058227539",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "0df21733-148c-4782-8de5-71b683f054a6",
					"created_datetime": "2022-03-17T01:21:55.753Z",
					"updated_datetime": "2022-03-17T01:21:55.753Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "66.87813568115234",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "d04b19cb-9794-4fe5-8a16-e8f6eaaca2da",
					"created_datetime": "2022-03-17T01:21:55.756Z",
					"updated_datetime": "2022-03-17T01:21:55.756Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "99.19576263427734",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "29f14938-11d7-4886-9b78-4a1612afe3dc",
					"created_datetime": "2022-03-17T01:21:55.759Z",
					"updated_datetime": "2022-03-17T01:21:55.759Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "97.92339324951172",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "7e69d71f-ba5c-4b9c-bc07-179cfaf2a89b",
					"created_datetime": "2022-03-17T01:21:55.762Z",
					"updated_datetime": "2022-03-17T01:21:55.762Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "34.573631286621094",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "988f3e92-35d9-439a-8475-276a9b948b52",
					"created_datetime": "2022-03-17T01:21:55.767Z",
					"updated_datetime": "2022-03-17T01:21:55.767Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "32.5091438293457",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "c880926f-193a-464c-ab3e-f6e3acdfd635",
					"created_datetime": "2022-03-17T01:21:55.770Z",
					"updated_datetime": "2022-03-17T01:21:55.770Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_stability_scap_left",
					"value_string": "21.692550659179688",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "9523c787-aed9-47d9-832b-686bfe66fb98",
					"created_datetime": "2022-03-17T01:21:55.773Z",
					"updated_datetime": "2022-03-17T01:21:55.773Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_stability_scap_right",
					"value_string": "7.90881872177124",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "f36d4f56-8e75-4ae0-b508-2f8f096724c4",
					"created_datetime": "2022-03-17T01:21:55.777Z",
					"updated_datetime": "2022-03-17T01:21:55.777Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "44.541717529296875",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "cc63a75d-691e-4f3a-b986-4621bf6fc682",
					"created_datetime": "2022-03-17T01:21:55.780Z",
					"updated_datetime": "2022-03-17T01:21:55.780Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "20.004987716674805",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "021634ce-2229-4f74-bf92-3bda98f1a8ec",
					"created_datetime": "2022-03-17T01:21:55.783Z",
					"updated_datetime": "2022-03-17T01:21:55.783Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "25.265792846679688",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "8826291f-03f1-4754-a51a-4241d81cebef",
					"created_datetime": "2022-03-17T01:21:55.786Z",
					"updated_datetime": "2022-03-17T01:21:55.786Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-6.414819717407227",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "bc37f435-85a9-42d6-8e9d-a83bdb1089c8",
					"created_datetime": "2022-03-17T01:21:55.789Z",
					"updated_datetime": "2022-03-17T01:21:55.789Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.8069113492965698",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "92de768e-303f-4420-8936-560515f97ec2",
					"created_datetime": "2022-03-17T01:21:55.792Z",
					"updated_datetime": "2022-03-17T01:21:55.792Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "17.09990119934082",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "c090074d-5c21-437b-8da6-7b7357ec1535",
					"created_datetime": "2022-03-17T01:21:55.796Z",
					"updated_datetime": "2022-03-17T01:21:55.796Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "12.282862663269043",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "059b6e04-9e6f-4fb6-a4e8-5845654ab0c4",
					"created_datetime": "2022-03-17T01:21:55.799Z",
					"updated_datetime": "2022-03-17T01:21:55.799Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "2.3318045139312744",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "96443278-b6e4-4656-8de9-201752fbc69f",
					"created_datetime": "2022-03-17T01:21:55.802Z",
					"updated_datetime": "2022-03-17T01:21:55.802Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-0.42909014225006104",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "14eceb10-8866-47ed-a33c-f78a7733609a",
					"created_datetime": "2022-03-17T01:21:55.805Z",
					"updated_datetime": "2022-03-17T01:21:55.805Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_femur",
					"value_string": "0.09209427982568741",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "34681c6c-8284-45a3-b4cd-a01ce3043085",
					"created_datetime": "2022-03-17T01:21:55.809Z",
					"updated_datetime": "2022-03-17T01:21:55.809Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-2.501842737197876",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "72b26feb-3437-4812-81d3-23e2c2f78ba7",
					"created_datetime": "2022-03-17T01:21:55.813Z",
					"updated_datetime": "2022-03-17T01:21:55.813Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-3.2637240886688232",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "8e3ae824-d2fd-40c8-8bf2-2f6574a25067",
					"created_datetime": "2022-03-17T01:21:55.816Z",
					"updated_datetime": "2022-03-17T01:21:55.816Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_composite",
					"value_string": "0.463495135307312",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "332f8f50-6fe4-47a9-a4fd-0c5d663f7814",
					"created_datetime": "2022-03-17T01:21:55.819Z",
					"updated_datetime": "2022-03-17T01:21:55.819Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility",
					"value_string": "0.4058184325695038",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "a426bb2a-f62f-48a0-b8f8-12b219cd8dff",
					"created_datetime": "2022-03-17T01:21:55.824Z",
					"updated_datetime": "2022-03-17T01:21:55.824Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability",
					"value_string": "0.427482932806015",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "2688ea30-a3d2-4052-941e-51bb98ce2b3b",
					"created_datetime": "2022-03-17T01:21:55.827Z",
					"updated_datetime": "2022-03-17T01:21:55.827Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_posture",
					"value_string": "0.07794210314750671",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "8e18b9e5-6f6d-47c6-b32c-c7847f6e43ac",
					"created_datetime": "2022-03-17T01:21:55.830Z",
					"updated_datetime": "2022-03-17T01:21:55.830Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry",
					"value_string": "0.743674099445343",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "69bf1c43-742d-40bb-ae36-15662ae9fbfb",
					"created_datetime": "2022-03-17T01:21:55.833Z",
					"updated_datetime": "2022-03-17T01:21:55.833Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9066025018692017",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "cc00fd42-63f1-4662-91e5-b2a50e0a97c6",
					"created_datetime": "2022-03-17T01:21:55.836Z",
					"updated_datetime": "2022-03-17T01:21:55.836Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.37183406949043274",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "6bdffdeb-1256-40cd-a87d-682928de242c",
					"created_datetime": "2022-03-17T01:21:55.839Z",
					"updated_datetime": "2022-03-17T01:21:55.839Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.6958906650543213",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "b42b6e90-cfb4-4445-bf89-db2ce3e3aba1",
					"created_datetime": "2022-03-17T01:21:55.842Z",
					"updated_datetime": "2022-03-17T01:21:55.842Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.42685431241989136",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "db78e5a3-a4ff-4d3c-a7f4-45db19d0b903",
					"created_datetime": "2022-03-17T01:21:55.845Z",
					"updated_datetime": "2022-03-17T01:21:55.845Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5312668085098267",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "f5aeeab4-7465-4282-9a3b-13286aba5f06",
					"created_datetime": "2022-03-17T01:21:55.848Z",
					"updated_datetime": "2022-03-17T01:21:55.848Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5062111616134644",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "78cf203f-2a20-4715-a3ba-addd89b0a358",
					"created_datetime": "2022-03-17T01:21:55.851Z",
					"updated_datetime": "2022-03-17T01:21:55.851Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.5242103934288025",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "b1150b1d-f657-465b-aca0-8ee4d198fefd",
					"created_datetime": "2022-03-17T01:21:55.854Z",
					"updated_datetime": "2022-03-17T01:21:55.854Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.4962603747844696",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "77b8d374-bb0b-444f-b5b2-db4ac837cd8d",
					"created_datetime": "2022-03-17T01:21:55.858Z",
					"updated_datetime": "2022-03-17T01:21:55.858Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.21470201015472412",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "95dc8623-f985-4009-a6b1-f977174df596",
					"created_datetime": "2022-03-17T01:21:55.861Z",
					"updated_datetime": "2022-03-17T01:21:55.861Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.19659948348999023",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "e8afb0fc-2cfc-4474-b6d8-d5595b274681",
					"created_datetime": "2022-03-17T01:21:55.864Z",
					"updated_datetime": "2022-03-17T01:21:55.864Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.0993034839630127",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "a1565836-5e82-4c87-8724-5fcbcbb888bd",
					"created_datetime": "2022-03-17T01:21:55.867Z",
					"updated_datetime": "2022-03-17T01:21:55.867Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.07247939705848694",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "42a876f5-db1e-481e-add6-5740a8e9d5b1",
					"created_datetime": "2022-03-17T01:21:55.872Z",
					"updated_datetime": "2022-03-17T01:21:55.872Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability_scap_left",
					"value_string": "0.44391679763793945",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "16091311-2777-4c9c-89ee-a50ba0549069",
					"created_datetime": "2022-03-17T01:21:55.875Z",
					"updated_datetime": "2022-03-17T01:21:55.875Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8431763648986816",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "5bf9b8da-e78f-4d9c-b103-15883adfbee0",
					"created_datetime": "2022-03-17T01:21:55.879Z",
					"updated_datetime": "2022-03-17T01:21:55.879Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.3248526453971863",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "823ccaff-a4f7-4f0d-ab7e-9a466f26e511",
					"created_datetime": "2022-03-17T01:21:55.882Z",
					"updated_datetime": "2022-03-17T01:21:55.882Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.3087131977081299",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "19680454-19f9-4ca6-9d5d-3000e76d910e",
					"created_datetime": "2022-03-17T01:21:55.885Z",
					"updated_datetime": "2022-03-17T01:21:55.885Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.5106024742126465",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "4611ff63-fd6a-4795-9496-1fb4901c9773",
					"created_datetime": "2022-03-17T01:21:55.888Z",
					"updated_datetime": "2022-03-17T01:21:55.888Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_posture_shoulder_level",
					"value_string": "1.1920928955078125e-7",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "8eda45d2-b800-482f-a5ad-6ae8d0e8c295",
					"created_datetime": "2022-03-17T01:21:55.891Z",
					"updated_datetime": "2022-03-17T01:21:55.891Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.15588408708572388",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "e87e8692-c52b-4088-b0e5-01b09113a223",
					"created_datetime": "2022-03-17T01:21:55.895Z",
					"updated_datetime": "2022-03-17T01:21:55.895Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_elbow",
					"value_string": "5.960464477539063e-8",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "720c75e1-7b62-4663-8b0f-7eb88820960b",
					"created_datetime": "2022-03-17T01:21:55.898Z",
					"updated_datetime": "2022-03-17T01:21:55.898Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.001789093017578125",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "30e10750-e29a-441b-8511-cfb998c03025",
					"created_datetime": "2022-03-17T01:21:55.902Z",
					"updated_datetime": "2022-03-17T01:21:55.902Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9577635526657104",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "3e015de3-8146-4d1d-b76d-82768c995558",
					"created_datetime": "2022-03-17T01:21:55.905Z",
					"updated_datetime": "2022-03-17T01:21:55.905Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9946534037590027",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "62c409a0-c010-4dcd-9981-4aad7ebac7a1",
					"created_datetime": "2022-03-17T01:21:55.908Z",
					"updated_datetime": "2022-03-17T01:21:55.908Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9973756074905396",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "45758083-49e0-430b-8b53-02822dc15c9f",
					"created_datetime": "2022-03-17T01:21:55.911Z",
					"updated_datetime": "2022-03-17T01:21:55.911Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_knee",
					"value_string": "0.949420690536499",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "25fce00e-92fe-4009-b48f-042f5fc07c45",
					"created_datetime": "2022-03-17T01:21:55.913Z",
					"updated_datetime": "2022-03-17T01:21:55.913Z",
					"assessment_id_guid": "a34ddb1d-1379-426c-b7b6-c43c927e5f40",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.8262274265289307",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "542598ad-160f-49d2-b501-a5104461c927",
			"created_datetime": "2022-03-17T01:55:51.196Z",
			"updated_datetime": "2022-03-17T01:55:51.196Z",
			"submitter_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "449e736c-aa88-4fb4-a4d4-fd83caf732e8",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "e7203884-f00a-4aa0-89d8-59ac671ba146",
					"created_datetime": "2022-03-17T01:55:51.200Z",
					"updated_datetime": "2022-03-17T01:55:51.200Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T01:55:50.830Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "f654ee06-9fd7-4bae-8ffd-3ac5e00e27e3",
					"created_datetime": "2022-03-17T01:55:51.203Z",
					"updated_datetime": "2022-03-17T01:55:51.203Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "295cf59a-a0a8-4e4b-b872-34548c972b8c",
					"created_datetime": "2022-03-17T01:55:51.207Z",
					"updated_datetime": "2022-03-17T01:55:51.207Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "175.13636779785156",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "33ca83cf-b57e-4da7-bbb3-42ad89865285",
					"created_datetime": "2022-03-17T01:55:51.211Z",
					"updated_datetime": "2022-03-17T01:55:51.211Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "159.61984252929688",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "f4c153a3-c9fd-49b9-9c24-4951aecce770",
					"created_datetime": "2022-03-17T01:55:51.214Z",
					"updated_datetime": "2022-03-17T01:55:51.214Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "136.17201232910156",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "3b74f406-8227-457f-97d3-95cf514dbf89",
					"created_datetime": "2022-03-17T01:55:51.218Z",
					"updated_datetime": "2022-03-17T01:55:51.218Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "138.0288543701172",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "27d191c8-aa02-4af2-8e99-6e161e8f5814",
					"created_datetime": "2022-03-17T01:55:51.221Z",
					"updated_datetime": "2022-03-17T01:55:51.221Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "131.1521453857422",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "0db5e8c3-56f3-4133-afff-87c1d5e66bfd",
					"created_datetime": "2022-03-17T01:55:51.224Z",
					"updated_datetime": "2022-03-17T01:55:51.224Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "130.1785125732422",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "36fe7f6e-9ba0-4eac-ad88-4298b61c2221",
					"created_datetime": "2022-03-17T01:55:51.227Z",
					"updated_datetime": "2022-03-17T01:55:51.227Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "73.40948486328125",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "3a265111-6619-4f60-a36a-732c691d75af",
					"created_datetime": "2022-03-17T01:55:51.230Z",
					"updated_datetime": "2022-03-17T01:55:51.230Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "72.84989166259766",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "34813595-52a2-4f2d-bc1d-dafeda104081",
					"created_datetime": "2022-03-17T01:55:51.234Z",
					"updated_datetime": "2022-03-17T01:55:51.234Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "106.76465606689453",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "fee09f46-7ea2-45b3-8df7-f6e96ed1cfcf",
					"created_datetime": "2022-03-17T01:55:51.238Z",
					"updated_datetime": "2022-03-17T01:55:51.238Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "110.8036117553711",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "df2bc6d7-d844-44b0-a89e-f7ea40295455",
					"created_datetime": "2022-03-17T01:55:51.250Z",
					"updated_datetime": "2022-03-17T01:55:51.250Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "34.364349365234375",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "f825d9d4-3ef2-44e0-b6b2-dd53daa070a2",
					"created_datetime": "2022-03-17T01:55:51.253Z",
					"updated_datetime": "2022-03-17T01:55:51.253Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "42.35927963256836",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "26c0fc46-d35f-49c7-926a-90caf49aaeb9",
					"created_datetime": "2022-03-17T01:55:51.256Z",
					"updated_datetime": "2022-03-17T01:55:51.256Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_stability_scap_left",
					"value_string": "24.16313362121582",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "1e8bea20-9340-4985-ba97-65aa9ad34de7",
					"created_datetime": "2022-03-17T01:55:51.259Z",
					"updated_datetime": "2022-03-17T01:55:51.259Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_stability_scap_right",
					"value_string": "6.621180057525635",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "76806797-3241-4613-8a10-ef8f91b8c4f6",
					"created_datetime": "2022-03-17T01:55:51.262Z",
					"updated_datetime": "2022-03-17T01:55:51.262Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "60.58026123046875",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "3cb03cbe-dc07-430f-b5c6-4c155dd8573c",
					"created_datetime": "2022-03-17T01:55:51.266Z",
					"updated_datetime": "2022-03-17T01:55:51.266Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "31.055442810058594",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "8b9226f4-85c5-46ce-ac26-1e9d6da4c852",
					"created_datetime": "2022-03-17T01:55:51.270Z",
					"updated_datetime": "2022-03-17T01:55:51.270Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "26.424524307250977",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "887cb3cc-73f1-4982-b04c-1f7ca8ebceeb",
					"created_datetime": "2022-03-17T01:55:51.273Z",
					"updated_datetime": "2022-03-17T01:55:51.273Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.29597750306129456",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "b02d431c-8489-4313-a9b2-42d1d3c0472b",
					"created_datetime": "2022-03-17T01:55:51.276Z",
					"updated_datetime": "2022-03-17T01:55:51.276Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.31707870960235596",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "ef4a2ee2-0cdf-48d1-a364-af446822a8c7",
					"created_datetime": "2022-03-17T01:55:51.279Z",
					"updated_datetime": "2022-03-17T01:55:51.279Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "21.117319107055664",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "5ed979d4-94dc-4f2d-9c57-3447a6ef89bd",
					"created_datetime": "2022-03-17T01:55:51.283Z",
					"updated_datetime": "2022-03-17T01:55:51.283Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-7.93706750869751",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "afc580fe-3e32-403a-b7e8-442ac11bdc49",
					"created_datetime": "2022-03-17T01:55:51.286Z",
					"updated_datetime": "2022-03-17T01:55:51.286Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.7465477585792542",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "dbf702e5-8ae1-4ccb-aebd-ee1f226c8af6",
					"created_datetime": "2022-03-17T01:55:51.290Z",
					"updated_datetime": "2022-03-17T01:55:51.290Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-3.4378767013549805",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "1e668e8f-5eb9-4cb6-8dcb-d6795680da9f",
					"created_datetime": "2022-03-17T01:55:51.293Z",
					"updated_datetime": "2022-03-17T01:55:51.293Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-1.5968648195266724",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "5e097009-e2e2-4348-b966-c1fde5fe96ac",
					"created_datetime": "2022-03-17T01:55:51.296Z",
					"updated_datetime": "2022-03-17T01:55:51.296Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_knee",
					"value_string": "3.0723254680633545",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "fef9345e-d80a-4444-a32d-44dfd998e7b2",
					"created_datetime": "2022-03-17T01:55:51.299Z",
					"updated_datetime": "2022-03-17T01:55:51.299Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "4.623165130615234",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "edd765e7-ac83-447d-8dd2-902076824f9b",
					"created_datetime": "2022-03-17T01:55:51.303Z",
					"updated_datetime": "2022-03-17T01:55:51.303Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_composite",
					"value_string": "0.4947560429573059",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "f47d4e41-cda8-44bb-ac84-73455fc54850",
					"created_datetime": "2022-03-17T01:55:51.310Z",
					"updated_datetime": "2022-03-17T01:55:51.310Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility",
					"value_string": "0.43830394744873047",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "2e91cb65-dc68-4552-b7ab-92d4a8e57fc4",
					"created_datetime": "2022-03-17T01:55:51.313Z",
					"updated_datetime": "2022-03-17T01:55:51.313Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability",
					"value_string": "0.36633455753326416",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "c330ad7f-624b-4c41-ae19-07241fd42aeb",
					"created_datetime": "2022-03-17T01:55:51.315Z",
					"updated_datetime": "2022-03-17T01:55:51.315Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_posture",
					"value_string": "0.9410396814346313",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "a581b319-5253-4d9a-9818-bcac11395f7b",
					"created_datetime": "2022-03-17T01:55:51.318Z",
					"updated_datetime": "2022-03-17T01:55:51.318Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry",
					"value_string": "0.7028346657752991",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "ad3232c7-baa2-4672-af26-e0d7426d82d6",
					"created_datetime": "2022-03-17T01:55:51.323Z",
					"updated_datetime": "2022-03-17T01:55:51.323Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9102706909179688",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "4f3e46dc-5264-4cc4-bfac-ecb9dce3cf2a",
					"created_datetime": "2022-03-17T01:55:51.326Z",
					"updated_datetime": "2022-03-17T01:55:51.326Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6209548115730286",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "4c6c49d7-94fd-4b45-b600-f22937316854",
					"created_datetime": "2022-03-17T01:55:51.329Z",
					"updated_datetime": "2022-03-17T01:55:51.329Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.10470330715179443",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "a09ade8e-0f4e-4bbc-9639-85df1ee56663",
					"created_datetime": "2022-03-17T01:55:51.332Z",
					"updated_datetime": "2022-03-17T01:55:51.332Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.12894141674041748",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "0d9d3409-8036-4d32-bbda-3953e2e7ac62",
					"created_datetime": "2022-03-17T01:55:51.335Z",
					"updated_datetime": "2022-03-17T01:55:51.335Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.8227993249893188",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "8e3a3683-617c-40b9-a73b-48a873cedce1",
					"created_datetime": "2022-03-17T01:55:51.338Z",
					"updated_datetime": "2022-03-17T01:55:51.338Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.8124984502792358",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "5ea9549f-2e25-4334-a30a-0bb12fff6dfd",
					"created_datetime": "2022-03-17T01:55:51.342Z",
					"updated_datetime": "2022-03-17T01:55:51.342Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.6890068054199219",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "b0ea780e-40a5-4b2c-a2c7-b9845d84042d",
					"created_datetime": "2022-03-17T01:55:51.345Z",
					"updated_datetime": "2022-03-17T01:55:51.345Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.6736416816711426",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "252c0358-ab46-44cf-b507-9f551601545a",
					"created_datetime": "2022-03-17T01:55:51.348Z",
					"updated_datetime": "2022-03-17T01:55:51.348Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.3402549922466278",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "5a72bcec-0a3b-4fda-9bc1-96eb2db65894",
					"created_datetime": "2022-03-17T01:55:51.358Z",
					"updated_datetime": "2022-03-17T01:55:51.358Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.41690438985824585",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "60b92701-41cd-4ff5-a762-8a1832cab9ee",
					"created_datetime": "2022-03-17T01:55:51.361Z",
					"updated_datetime": "2022-03-17T01:55:51.361Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.0962924063205719",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "d1e80b63-5445-434b-af25-72e74e792ae7",
					"created_datetime": "2022-03-17T01:55:51.364Z",
					"updated_datetime": "2022-03-17T01:55:51.364Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.26215144991874695",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "68d97919-fd78-4044-90cf-e77699eba3d1",
					"created_datetime": "2022-03-17T01:55:51.367Z",
					"updated_datetime": "2022-03-17T01:55:51.367Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability_scap_left",
					"value_string": "0.3643227815628052",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "c309a523-25be-4c5d-b7f9-487b0e7bb311",
					"created_datetime": "2022-03-17T01:55:51.370Z",
					"updated_datetime": "2022-03-17T01:55:51.370Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8675536513328552",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "4b76a5fc-94ed-4408-b3e0-d7fe00fda637",
					"created_datetime": "2022-03-17T01:55:51.373Z",
					"updated_datetime": "2022-03-17T01:55:51.373Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.01979374885559082",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "7efb1575-e055-414d-9bc8-e24229f6c426",
					"created_datetime": "2022-03-17T01:55:51.376Z",
					"updated_datetime": "2022-03-17T01:55:51.376Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.7275913953781128",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "e72712d8-6d9c-4824-96f9-210d5a2b42a7",
					"created_datetime": "2022-03-17T01:55:51.379Z",
					"updated_datetime": "2022-03-17T01:55:51.379Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.5566387176513672",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "1609041a-9fd0-4ae8-9c94-cf0a070186a5",
					"created_datetime": "2022-03-17T01:55:51.382Z",
					"updated_datetime": "2022-03-17T01:55:51.382Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9526525735855103",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "d88aa2d4-8f61-44c8-beb8-e76f010bc0bc",
					"created_datetime": "2022-03-17T01:55:51.444Z",
					"updated_datetime": "2022-03-17T01:55:51.444Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.9294267892837524",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "e359384e-4274-42fa-babc-6b608b3eb8cb",
					"created_datetime": "2022-03-17T01:55:51.446Z",
					"updated_datetime": "2022-03-17T01:55:51.446Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "84b3b062-d2a5-47a9-9087-8c73afe04710",
					"created_datetime": "2022-03-17T01:55:51.449Z",
					"updated_datetime": "2022-03-17T01:55:51.449Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.12003207206726074",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "a0bddf12-6c45-45a2-9d96-9c1be7273051",
					"created_datetime": "2022-03-17T01:55:51.453Z",
					"updated_datetime": "2022-03-17T01:55:51.453Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9921225309371948",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "cd5d5c82-8e0a-4efc-a0d1-48b84ce072d6",
					"created_datetime": "2022-03-17T01:55:51.456Z",
					"updated_datetime": "2022-03-17T01:55:51.456Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8933240175247192",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "fe52872d-a8dc-4091-bbb5-e0742a1f50ca",
					"created_datetime": "2022-03-17T01:55:51.458Z",
					"updated_datetime": "2022-03-17T01:55:51.458Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9569670557975769",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "84793fb5-a5d1-46e0-8f58-47c664014232",
					"created_datetime": "2022-03-17T01:55:51.461Z",
					"updated_datetime": "2022-03-17T01:55:51.461Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9208879470825195",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "393d2d02-c452-4cc7-907d-d1769ee56405",
					"created_datetime": "2022-03-17T01:55:51.463Z",
					"updated_datetime": "2022-03-17T01:55:51.463Z",
					"assessment_id_guid": "542598ad-160f-49d2-b501-a5104461c927",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.6685326099395752",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
			"created_datetime": "2022-03-17T02:08:49.846Z",
			"updated_datetime": "2022-03-17T02:08:49.846Z",
			"submitter_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "20db29f7-c348-44e9-a585-303822cc06fd",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "0555fbd1-4d8e-4f6d-8772-68178eef65ce",
					"created_datetime": "2022-03-17T02:08:49.903Z",
					"updated_datetime": "2022-03-17T02:08:49.903Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T02:08:49.462Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "f68c1143-8d3f-4493-80d3-d0d6964e4628",
					"created_datetime": "2022-03-17T02:08:49.908Z",
					"updated_datetime": "2022-03-17T02:08:49.908Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f705a508-39b4-4277-9448-e394efef6e58",
					"created_datetime": "2022-03-17T02:08:49.913Z",
					"updated_datetime": "2022-03-17T02:08:49.913Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "169.99729919433594",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "08fb67fd-5b72-436c-9c98-1377446138fd",
					"created_datetime": "2022-03-17T02:08:49.916Z",
					"updated_datetime": "2022-03-17T02:08:49.916Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "158.0941925048828",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "82019a42-2b70-4e59-964a-b3304ecf3c84",
					"created_datetime": "2022-03-17T02:08:49.919Z",
					"updated_datetime": "2022-03-17T02:08:49.919Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "139.0867919921875",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "c23b17ac-b963-49aa-98e6-18a7dd619b5b",
					"created_datetime": "2022-03-17T02:08:49.922Z",
					"updated_datetime": "2022-03-17T02:08:49.922Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "136.9962615966797",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "b9b1fbe5-39c9-48c9-8227-89dd2777be64",
					"created_datetime": "2022-03-17T02:08:49.925Z",
					"updated_datetime": "2022-03-17T02:08:49.925Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "129.4785919189453",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "32af2abc-7208-452a-9f87-26285917099e",
					"created_datetime": "2022-03-17T02:08:49.928Z",
					"updated_datetime": "2022-03-17T02:08:49.928Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "128.4202880859375",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "d1007920-0701-4ebf-be0e-372bd78bbad3",
					"created_datetime": "2022-03-17T02:08:49.930Z",
					"updated_datetime": "2022-03-17T02:08:49.930Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "75.08683013916016",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "cf338121-641e-4a56-a2ed-60d9e904f4b4",
					"created_datetime": "2022-03-17T02:08:49.933Z",
					"updated_datetime": "2022-03-17T02:08:49.933Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "72.47614288330078",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "397e1856-30de-4bfb-84b8-39252c46dedf",
					"created_datetime": "2022-03-17T02:08:49.936Z",
					"updated_datetime": "2022-03-17T02:08:49.936Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "109.6338119506836",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "8f8c33e3-781b-437a-90a5-f81df1512667",
					"created_datetime": "2022-03-17T02:08:49.939Z",
					"updated_datetime": "2022-03-17T02:08:49.939Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "109.53985595703125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "a22fd6ac-c217-432a-a1a0-c88d4f5c6826",
					"created_datetime": "2022-03-17T02:08:49.941Z",
					"updated_datetime": "2022-03-17T02:08:49.941Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "36.54702377319336",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "d5aa0424-1507-41d5-8e3c-b36277d34187",
					"created_datetime": "2022-03-17T02:08:49.943Z",
					"updated_datetime": "2022-03-17T02:08:49.943Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "37.89522933959961",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6d60a9d0-1f83-4b98-b0bf-b4dd7d14dc67",
					"created_datetime": "2022-03-17T02:08:49.945Z",
					"updated_datetime": "2022-03-17T02:08:49.945Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_stability_scap_left",
					"value_string": "15.731663703918457",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "37cb7b08-63e1-4b55-9003-6511def8f594",
					"created_datetime": "2022-03-17T02:08:49.948Z",
					"updated_datetime": "2022-03-17T02:08:49.948Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_stability_scap_right",
					"value_string": "8.700854301452637",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7e61dbc8-ea99-467d-a200-380b9bce43cb",
					"created_datetime": "2022-03-17T02:08:49.950Z",
					"updated_datetime": "2022-03-17T02:08:49.950Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "58.05244827270508",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "898af604-92b9-44bf-8b7c-c12988b92f12",
					"created_datetime": "2022-03-17T02:08:49.952Z",
					"updated_datetime": "2022-03-17T02:08:49.952Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "33.81561279296875",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "177dd4b1-acee-4520-8d31-13ba6688c265",
					"created_datetime": "2022-03-17T02:08:49.972Z",
					"updated_datetime": "2022-03-17T02:08:49.972Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "23.169626235961914",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "cbc4ff98-1186-4fcb-a94c-7e904f36533b",
					"created_datetime": "2022-03-17T02:08:49.975Z",
					"updated_datetime": "2022-03-17T02:08:49.975Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.3855181634426117",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8505649d-aad0-4ea2-bc26-513fab437ee5",
					"created_datetime": "2022-03-17T02:08:49.978Z",
					"updated_datetime": "2022-03-17T02:08:49.978Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.4092111587524414",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "7a4cc3b0-70f3-4f84-9fba-ea19abd0702a",
					"created_datetime": "2022-03-17T02:08:49.983Z",
					"updated_datetime": "2022-03-17T02:08:49.983Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "15.331368446350098",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "f4a71bf8-4abe-4896-9438-906b4eb7b3ed",
					"created_datetime": "2022-03-17T02:08:49.986Z",
					"updated_datetime": "2022-03-17T02:08:49.986Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "0.18800227344036102",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "ea98cb9b-ef0e-429f-831d-17d5c9f425f0",
					"created_datetime": "2022-03-17T02:08:49.989Z",
					"updated_datetime": "2022-03-17T02:08:49.989Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "2.771005630493164",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "83fd21d3-1d30-456b-8eb1-1b849d447cd3",
					"created_datetime": "2022-03-17T02:08:49.992Z",
					"updated_datetime": "2022-03-17T02:08:49.992Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-0.766632080078125",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "4f68bbb2-1674-479b-8458-94ae3aab9399",
					"created_datetime": "2022-03-17T02:08:49.994Z",
					"updated_datetime": "2022-03-17T02:08:49.994Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-1.2626169919967651",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "5fec4515-1595-4b00-8823-8876cfdc234b",
					"created_datetime": "2022-03-17T02:08:49.997Z",
					"updated_datetime": "2022-03-17T02:08:49.997Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-1.9835882186889648",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "ad15e042-c0fa-4f67-92ac-c4a559e0d567",
					"created_datetime": "2022-03-17T02:08:50.000Z",
					"updated_datetime": "2022-03-17T02:08:50.000Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-0.7041203379631042",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "a19e4201-420b-4bd1-b739-99b380ec8049",
					"created_datetime": "2022-03-17T02:08:50.003Z",
					"updated_datetime": "2022-03-17T02:08:50.003Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_composite",
					"value_string": "0.5456974506378174",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "53eb7435-77c3-4a97-b776-e5db2abf3052",
					"created_datetime": "2022-03-17T02:08:50.005Z",
					"updated_datetime": "2022-03-17T02:08:50.005Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility",
					"value_string": "0.43824583292007446",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "22329723-4cf4-4e51-9c0c-7e2b87f1a471",
					"created_datetime": "2022-03-17T02:08:50.008Z",
					"updated_datetime": "2022-03-17T02:08:50.008Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability",
					"value_string": "0.39279335737228394",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "460f48fb-d2e3-496e-b1a9-7e1f425c9e0a",
					"created_datetime": "2022-03-17T02:08:50.010Z",
					"updated_datetime": "2022-03-17T02:08:50.010Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_posture",
					"value_string": "0.9241143465042114",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "855e3136-e5da-4757-a6d9-e3a51d821abc",
					"created_datetime": "2022-03-17T02:08:50.013Z",
					"updated_datetime": "2022-03-17T02:08:50.013Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry",
					"value_string": "0.9222153425216675",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "617bfdef-c48d-4c78-829d-5b2256d0cbc9",
					"created_datetime": "2022-03-17T02:08:50.015Z",
					"updated_datetime": "2022-03-17T02:08:50.015Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.8413012027740479",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "72d75611-1ae1-4c49-9042-da55d06ed7c4",
					"created_datetime": "2022-03-17T02:08:50.018Z",
					"updated_datetime": "2022-03-17T02:08:50.018Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5817137360572815",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "62acf5ae-d96a-496d-b539-c64d4c93d0d8",
					"created_datetime": "2022-03-17T02:08:50.020Z",
					"updated_datetime": "2022-03-17T02:08:50.020Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.14437216520309448",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "5dbaff54-de05-4826-93f4-b07eb7e94f88",
					"created_datetime": "2022-03-17T02:08:50.023Z",
					"updated_datetime": "2022-03-17T02:08:50.023Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.11502134799957275",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "6bd67143-8935-4837-a66d-4f04d349b531",
					"created_datetime": "2022-03-17T02:08:50.025Z",
					"updated_datetime": "2022-03-17T02:08:50.025Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.8048694133758545",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "3c2cefb6-850e-418b-96d5-0e650063f82b",
					"created_datetime": "2022-03-17T02:08:50.029Z",
					"updated_datetime": "2022-03-17T02:08:50.029Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.7929819822311401",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "d3e6b37a-d780-4eb8-bc4c-fb76e6f9c85f",
					"created_datetime": "2022-03-17T02:08:50.031Z",
					"updated_datetime": "2022-03-17T02:08:50.031Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.7330501079559326",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "0f44bac0-0824-4d7d-92f0-937c789db41e",
					"created_datetime": "2022-03-17T02:08:50.034Z",
					"updated_datetime": "2022-03-17T02:08:50.034Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.6632106304168701",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "0bfe4442-a16e-4577-896a-7ae15c3fdbc8",
					"created_datetime": "2022-03-17T02:08:50.036Z",
					"updated_datetime": "2022-03-17T02:08:50.036Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.3942306339740753",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "bdc236e5-ec0e-49fd-bb68-d9a1f652f899",
					"created_datetime": "2022-03-17T02:08:50.039Z",
					"updated_datetime": "2022-03-17T02:08:50.039Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.3924238681793213",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "60b98e80-9c22-451a-b4df-cb09274035d6",
					"created_datetime": "2022-03-17T02:08:50.041Z",
					"updated_datetime": "2022-03-17T02:08:50.041Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.13112670183181763",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "1af42a5d-8671-4ef2-be32-02fc5f3877e2",
					"created_datetime": "2022-03-17T02:08:50.044Z",
					"updated_datetime": "2022-03-17T02:08:50.044Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.15655189752578735",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "28e17459-c777-498c-9160-0304c83ddfea",
					"created_datetime": "2022-03-17T02:08:50.046Z",
					"updated_datetime": "2022-03-17T02:08:50.046Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability_scap_left",
					"value_string": "0.638965368270874",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "19fcd64e-de96-4fd0-b6fc-f8d1ab471eb8",
					"created_datetime": "2022-03-17T02:08:50.049Z",
					"updated_datetime": "2022-03-17T02:08:50.049Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8268001079559326",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "17a020ea-9c82-408a-ab77-032d2cfa3798",
					"created_datetime": "2022-03-17T02:08:50.051Z",
					"updated_datetime": "2022-03-17T02:08:50.051Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.03551816940307617",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "4083180b-bd51-4aee-9bdb-93e8d17434a5",
					"created_datetime": "2022-03-17T02:08:50.068Z",
					"updated_datetime": "2022-03-17T02:08:50.068Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.810992956161499",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "beff6845-46f4-4fba-83a4-cbdd82984e4b",
					"created_datetime": "2022-03-17T02:08:50.071Z",
					"updated_datetime": "2022-03-17T02:08:50.071Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.42738425731658936",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "3c889427-e51d-4856-8367-d8d9ea62e485",
					"created_datetime": "2022-03-17T02:08:50.073Z",
					"updated_datetime": "2022-03-17T02:08:50.073Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.941983699798584",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "74c02ad8-7e19-4eb7-9d4d-6f0d327e6949",
					"created_datetime": "2022-03-17T02:08:50.075Z",
					"updated_datetime": "2022-03-17T02:08:50.075Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.9062449932098389",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "d0889473-a7bd-4f13-9bc4-98a1b3a53b4d",
					"created_datetime": "2022-03-17T02:08:50.078Z",
					"updated_datetime": "2022-03-17T02:08:50.078Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.0000035762786865234375",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "f64cdcb3-8f04-4ef6-a622-0fc4dc4e5c20",
					"created_datetime": "2022-03-17T02:08:50.081Z",
					"updated_datetime": "2022-03-17T02:08:50.081Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9728728532791138",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "2a6c2ff4-f313-4c75-a18c-eecbeffdefd3",
					"created_datetime": "2022-03-17T02:08:50.085Z",
					"updated_datetime": "2022-03-17T02:08:50.085Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9375276565551758",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "fcaac1f4-ac71-41ed-a16a-5fd91a8821fb",
					"created_datetime": "2022-03-17T02:08:50.088Z",
					"updated_datetime": "2022-03-17T02:08:50.088Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9919317364692688",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "f2b60e5b-8994-4bec-8246-df739b908989",
					"created_datetime": "2022-03-17T02:08:50.090Z",
					"updated_datetime": "2022-03-17T02:08:50.090Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9747246503829956",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "205833f6-2c8c-4c41-b19c-3788448734ce",
					"created_datetime": "2022-03-17T02:08:50.093Z",
					"updated_datetime": "2022-03-17T02:08:50.093Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9675949215888977",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "725ce2cd-4c31-4549-9788-57d55c9eee00",
					"created_datetime": "2022-03-17T02:08:50.096Z",
					"updated_datetime": "2022-03-17T02:08:50.096Z",
					"assessment_id_guid": "aee27bcc-ec10-414c-8900-2f91faa07e41",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9704443216323853",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
			"created_datetime": "2022-03-17T02:17:54.636Z",
			"updated_datetime": "2022-03-17T02:17:54.636Z",
			"submitter_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"athlete_user_id_guid": "c385f18b-b843-470f-88fd-45541e4a4dd4",
			"external_id_guid": "42c98463-2a1e-4690-9462-c87ce77a8818",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "41e985b0-4652-4c3b-8376-aa2c750b391c",
					"created_datetime": "2022-03-17T02:17:54.642Z",
					"updated_datetime": "2022-03-17T02:17:54.642Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T02:17:54.413Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "734c57ad-bc28-4f5d-9ce8-8e9f9a9c1593",
					"created_datetime": "2022-03-17T02:17:54.646Z",
					"updated_datetime": "2022-03-17T02:17:54.646Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "bce04869-a2b1-4b28-be26-974264732e10",
					"created_datetime": "2022-03-17T02:17:54.649Z",
					"updated_datetime": "2022-03-17T02:17:54.649Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "175.332763671875",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "fc279ab7-8c7d-4163-bb7b-e2488e65865d",
					"created_datetime": "2022-03-17T02:17:54.653Z",
					"updated_datetime": "2022-03-17T02:17:54.653Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "168.9036865234375",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "d5097dde-18d5-4ebd-b044-4c960e86dbfd",
					"created_datetime": "2022-03-17T02:17:54.656Z",
					"updated_datetime": "2022-03-17T02:17:54.656Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "140.48341369628906",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "e989da33-7832-4d30-a9ec-d8129808179d",
					"created_datetime": "2022-03-17T02:17:54.660Z",
					"updated_datetime": "2022-03-17T02:17:54.660Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "144.17640686035156",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "63e0cbe2-fddc-415e-b419-c26a53a9adde",
					"created_datetime": "2022-03-17T02:17:54.663Z",
					"updated_datetime": "2022-03-17T02:17:54.663Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "129.7151336669922",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "28838a68-b242-47c0-b379-474aef1c10e1",
					"created_datetime": "2022-03-17T02:17:54.666Z",
					"updated_datetime": "2022-03-17T02:17:54.666Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "130.12669372558594",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "d8b6551b-22a2-442b-b4ca-f672b7d9312b",
					"created_datetime": "2022-03-17T02:17:54.670Z",
					"updated_datetime": "2022-03-17T02:17:54.670Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "76.70254516601562",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "38d7e051-1e33-4877-9b8b-67c888bd0140",
					"created_datetime": "2022-03-17T02:17:54.673Z",
					"updated_datetime": "2022-03-17T02:17:54.673Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "73.7714614868164",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "71a56c73-127b-4cc6-acbe-8907e80600c0",
					"created_datetime": "2022-03-17T02:17:54.676Z",
					"updated_datetime": "2022-03-17T02:17:54.676Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "108.5721435546875",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "165a0417-4e7f-437c-ae5b-1491afdc8e49",
					"created_datetime": "2022-03-17T02:17:54.680Z",
					"updated_datetime": "2022-03-17T02:17:54.680Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "107.30721282958984",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "fd37d2b0-cbca-4861-ac03-b2011fe25c42",
					"created_datetime": "2022-03-17T02:17:54.683Z",
					"updated_datetime": "2022-03-17T02:17:54.683Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "35.62894058227539",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "62c9f3b5-9e59-4d2e-9fca-e8b5259086ba",
					"created_datetime": "2022-03-17T02:17:54.686Z",
					"updated_datetime": "2022-03-17T02:17:54.686Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "34.83436584472656",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "604f71b6-3253-49d1-a247-c9d5861962e9",
					"created_datetime": "2022-03-17T02:17:54.689Z",
					"updated_datetime": "2022-03-17T02:17:54.689Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_stability_scap_left",
					"value_string": "9.837234497070312",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "eb5d859b-a043-47e1-8cb1-74399b3fc8ed",
					"created_datetime": "2022-03-17T02:17:54.692Z",
					"updated_datetime": "2022-03-17T02:17:54.692Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_stability_scap_right",
					"value_string": "16.817174911499023",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "3545a819-c4be-4d28-93d2-4db086c783c7",
					"created_datetime": "2022-03-17T02:17:54.696Z",
					"updated_datetime": "2022-03-17T02:17:54.696Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "57.54826736450195",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "487960a3-0148-493e-9389-40e8f8c28977",
					"created_datetime": "2022-03-17T02:17:54.699Z",
					"updated_datetime": "2022-03-17T02:17:54.699Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "27.58189582824707",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "ff0e216f-13f8-410b-87a6-b5c7fa176522",
					"created_datetime": "2022-03-17T02:17:54.703Z",
					"updated_datetime": "2022-03-17T02:17:54.703Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "23.7398681640625",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "cb70d860-6af3-4383-a20c-eb4b2838a1bf",
					"created_datetime": "2022-03-17T02:17:54.707Z",
					"updated_datetime": "2022-03-17T02:17:54.707Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.9345336556434631",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8b451c1a-d8a5-48bf-be1b-3cc1a54a7075",
					"created_datetime": "2022-03-17T02:17:54.711Z",
					"updated_datetime": "2022-03-17T02:17:54.711Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.2209746092557907",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "69221e76-5f6f-43cb-bf68-0100ea12b2c9",
					"created_datetime": "2022-03-17T02:17:54.714Z",
					"updated_datetime": "2022-03-17T02:17:54.714Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "14.673672676086426",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "841554c6-9d10-415c-881f-c7a32d5f2c70",
					"created_datetime": "2022-03-17T02:17:54.718Z",
					"updated_datetime": "2022-03-17T02:17:54.718Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "0.8846745491027832",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "80e15336-6405-464e-b0d6-e929b6209af4",
					"created_datetime": "2022-03-17T02:17:54.721Z",
					"updated_datetime": "2022-03-17T02:17:54.721Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.1192456483840942",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "ca2358ef-7d12-492d-a684-260965da8499",
					"created_datetime": "2022-03-17T02:17:54.724Z",
					"updated_datetime": "2022-03-17T02:17:54.724Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-1.3633559942245483",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "c434b778-1f1d-4362-b846-0b3fdf0da2c3",
					"created_datetime": "2022-03-17T02:17:54.728Z",
					"updated_datetime": "2022-03-17T02:17:54.728Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-3.395015001296997",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "7edc2edc-a02c-43ad-bbb2-37e05228f40b",
					"created_datetime": "2022-03-17T02:17:54.731Z",
					"updated_datetime": "2022-03-17T02:17:54.731Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_knee",
					"value_string": "1.2685844898223877",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "37859b7b-4333-4bd4-8298-15a00782b180",
					"created_datetime": "2022-03-17T02:17:54.734Z",
					"updated_datetime": "2022-03-17T02:17:54.734Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "3.77459979057312",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "a7370c38-3d2d-4d54-a616-b6dcd4aeb144",
					"created_datetime": "2022-03-17T02:17:54.738Z",
					"updated_datetime": "2022-03-17T02:17:54.738Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_composite",
					"value_string": "0.5202222466468811",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "5f515b90-099b-4e92-9b56-05bdbe9509a5",
					"created_datetime": "2022-03-17T02:17:54.741Z",
					"updated_datetime": "2022-03-17T02:17:54.741Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility",
					"value_string": "0.4648069739341736",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "0a4554f4-6709-438b-be50-f1a1bc86602c",
					"created_datetime": "2022-03-17T02:17:54.744Z",
					"updated_datetime": "2022-03-17T02:17:54.744Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability",
					"value_string": "0.35650795698165894",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "2369a0a9-058d-48bc-b4c0-38b699391615",
					"created_datetime": "2022-03-17T02:17:54.747Z",
					"updated_datetime": "2022-03-17T02:17:54.747Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_posture",
					"value_string": "0.890255868434906",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "1bf4fdba-d2d9-47a6-94b3-0cd8f903778e",
					"created_datetime": "2022-03-17T02:17:54.751Z",
					"updated_datetime": "2022-03-17T02:17:54.751Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry",
					"value_string": "0.7979696989059448",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "b45f1b60-713d-4777-810e-e19787d40383",
					"created_datetime": "2022-03-17T02:17:54.755Z",
					"updated_datetime": "2022-03-17T02:17:54.755Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9123735427856445",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "f2d1ea8d-a1b6-4457-b003-0abccec49fc1",
					"created_datetime": "2022-03-17T02:17:54.758Z",
					"updated_datetime": "2022-03-17T02:17:54.758Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.8230140209197998",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "32fcab16-be4f-4b1d-a708-5f063162fa9f",
					"created_datetime": "2022-03-17T02:17:54.761Z",
					"updated_datetime": "2022-03-17T02:17:54.761Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.16657903790473938",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "bd937ecd-71e7-4f1b-bed0-c8283dc1492d",
					"created_datetime": "2022-03-17T02:17:54.765Z",
					"updated_datetime": "2022-03-17T02:17:54.765Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.23527854681015015",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "29e5e508-e37b-47b2-bf9e-335b65d03efe",
					"created_datetime": "2022-03-17T02:17:54.768Z",
					"updated_datetime": "2022-03-17T02:17:54.768Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.8074684739112854",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "55017d2f-2cf4-4326-bc50-696e6e3653cc",
					"created_datetime": "2022-03-17T02:17:54.772Z",
					"updated_datetime": "2022-03-17T02:17:54.772Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.8119399547576904",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "c6b71a78-d7e3-4f38-81d0-a991b5626dea",
					"created_datetime": "2022-03-17T02:17:54.775Z",
					"updated_datetime": "2022-03-17T02:17:54.775Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.772271990776062",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "cba2681e-ae38-4d8e-892f-52af8458e3be",
					"created_datetime": "2022-03-17T02:17:54.778Z",
					"updated_datetime": "2022-03-17T02:17:54.778Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.6987754106521606",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "dd73668c-3edc-4506-a62c-cd3f17ffe9b5",
					"created_datetime": "2022-03-17T02:17:54.781Z",
					"updated_datetime": "2022-03-17T02:17:54.781Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.37395650148391724",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "5cf39161-f3f8-4b76-ad6f-2ff574f81eb8",
					"created_datetime": "2022-03-17T02:17:54.784Z",
					"updated_datetime": "2022-03-17T02:17:54.784Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.3502524197101593",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "2777627c-6a70-4e5a-970d-bfda67dd80ed",
					"created_datetime": "2022-03-17T02:17:54.787Z",
					"updated_datetime": "2022-03-17T02:17:54.787Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.11553871631622314",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "12631604-22ce-4fd2-bdaa-2a79bf036dcc",
					"created_datetime": "2022-03-17T02:17:54.790Z",
					"updated_datetime": "2022-03-17T02:17:54.790Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.10315045714378357",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "8eae242e-4a2f-4fb5-b560-99a0f3d8ec81",
					"created_datetime": "2022-03-17T02:17:54.793Z",
					"updated_datetime": "2022-03-17T02:17:54.793Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability_scap_left",
					"value_string": "0.80147385597229",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "6a1817fe-7b38-40a9-baf4-a48b6dac7dfd",
					"created_datetime": "2022-03-17T02:17:54.795Z",
					"updated_datetime": "2022-03-17T02:17:54.795Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability_scap_right",
					"value_string": "0.6045858860015869",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "ac243dc0-1817-463d-8f70-9bdbdec6ee4a",
					"created_datetime": "2022-03-17T02:17:54.798Z",
					"updated_datetime": "2022-03-17T02:17:54.798Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.03964442014694214",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "c13ff1a6-89ee-4e8d-9d3e-4ef82a7c659e",
					"created_datetime": "2022-03-17T02:17:54.801Z",
					"updated_datetime": "2022-03-17T02:17:54.801Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.6018695831298828",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "3f70df48-5404-4289-ba33-59190601faa0",
					"created_datetime": "2022-03-17T02:17:54.805Z",
					"updated_datetime": "2022-03-17T02:17:54.805Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.44986075162887573",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "0305e363-ff7b-4d94-a34d-c2f2ebea69f5",
					"created_datetime": "2022-03-17T02:17:54.807Z",
					"updated_datetime": "2022-03-17T02:17:54.807Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.831882119178772",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "f7bb319e-b6cc-4de2-b4f5-ca81b29a9376",
					"created_datetime": "2022-03-17T02:17:54.810Z",
					"updated_datetime": "2022-03-17T02:17:54.810Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.94862961769104",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "96c1fb10-435c-4312-9812-692bf95c7102",
					"created_datetime": "2022-03-17T02:17:54.812Z",
					"updated_datetime": "2022-03-17T02:17:54.812Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.000012993812561035156",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "08a85eae-ac85-42c9-8e9a-78541fca55cb",
					"created_datetime": "2022-03-17T02:17:54.815Z",
					"updated_datetime": "2022-03-17T02:17:54.815Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9501315355300903",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "4334a9c1-c6da-49b8-9034-dfb0d3107a61",
					"created_datetime": "2022-03-17T02:17:54.818Z",
					"updated_datetime": "2022-03-17T02:17:54.818Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9878547191619873",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "e4c7244d-9592-4b6b-915d-7164cbef253d",
					"created_datetime": "2022-03-17T02:17:54.820Z",
					"updated_datetime": "2022-03-17T02:17:54.820Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9840781688690186",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "7940f4db-bf36-41f5-8c7c-46fd22c7af02",
					"created_datetime": "2022-03-17T02:17:54.823Z",
					"updated_datetime": "2022-03-17T02:17:54.823Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_femur",
					"value_string": "0.6671773195266724",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "1459650c-ace9-4c12-89f7-71a28d808409",
					"created_datetime": "2022-03-17T02:17:54.825Z",
					"updated_datetime": "2022-03-17T02:17:54.825Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9835199117660522",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "570dadd2-53bd-4dc9-8dee-8d348bf09fcc",
					"created_datetime": "2022-03-17T02:17:54.828Z",
					"updated_datetime": "2022-03-17T02:17:54.828Z",
					"assessment_id_guid": "024a88fa-cf60-436d-b2be-a543b37b6a23",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.7734173536300659",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
			"created_datetime": "2022-03-17T18:06:40.655Z",
			"updated_datetime": "2022-03-17T18:06:40.655Z",
			"submitter_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"athlete_user_id_guid": "16f2011b-4f66-4d6b-9e40-84db11048d0d",
			"external_id_guid": "05f3c63a-54c2-4049-a53a-1367e1afa154",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "36fb04c2-e3e0-4994-8803-56247c203744",
					"created_datetime": "2022-03-17T18:06:40.660Z",
					"updated_datetime": "2022-03-17T18:06:40.660Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T18:06:40.470Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "7282533c-4b6e-44de-9323-636488551090",
					"created_datetime": "2022-03-17T18:06:40.664Z",
					"updated_datetime": "2022-03-17T18:06:40.664Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "778bd516-f39c-4815-ba1c-7267f1f505a9",
					"created_datetime": "2022-03-17T18:06:40.667Z",
					"updated_datetime": "2022-03-17T18:06:40.667Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "169.82574462890625",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "30991336-5ba0-4fa6-ae5c-03939cf75e70",
					"created_datetime": "2022-03-17T18:06:40.670Z",
					"updated_datetime": "2022-03-17T18:06:40.670Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "160.20846557617188",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "b2885785-77c7-4798-a29b-26696b08774d",
					"created_datetime": "2022-03-17T18:06:40.673Z",
					"updated_datetime": "2022-03-17T18:06:40.673Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "140.69725036621094",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "382416c4-bc33-4637-b0e5-33e53ed5a659",
					"created_datetime": "2022-03-17T18:06:40.676Z",
					"updated_datetime": "2022-03-17T18:06:40.676Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "136.36962890625",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "90a3db2a-6f3f-465a-9fe4-5b4577d4f6ea",
					"created_datetime": "2022-03-17T18:06:40.679Z",
					"updated_datetime": "2022-03-17T18:06:40.679Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "125.68527221679688",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "6443061e-73ef-4498-9f67-898afded449c",
					"created_datetime": "2022-03-17T18:06:40.686Z",
					"updated_datetime": "2022-03-17T18:06:40.686Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "126.2892837524414",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "2191fd08-db6d-45ce-bb76-d8d531cf2812",
					"created_datetime": "2022-03-17T18:06:40.689Z",
					"updated_datetime": "2022-03-17T18:06:40.689Z",
					"assessment_id_guid": "177e3142-e80d-43d9-ac69-47d3ee125b99",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "66.92125701904297",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
			"created_datetime": "2022-03-17T22:06:25.869Z",
			"updated_datetime": "2022-03-17T22:06:25.869Z",
			"submitter_user_id_guid": "f56a45bb-cdaf-4ff3-931c-7a100c0160e8",
			"athlete_user_id_guid": "b7f80cd2-ceca-4e37-8efa-35838ed6fd64",
			"external_id_guid": "4164758c-6b4f-4803-8fe4-de9bb4e9e826",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "8d1f2c98-a50c-4053-9ea2-1215e0aed60c",
					"created_datetime": "2022-03-17T22:06:25.873Z",
					"updated_datetime": "2022-03-17T22:06:25.873Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T22:09:14.700Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "6841df89-7e5b-4f85-8be2-b8e176291904",
					"created_datetime": "2022-03-17T22:06:25.876Z",
					"updated_datetime": "2022-03-17T22:06:25.876Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "2a9e229a-8380-4b9c-93e6-f66ad4da14ec",
					"created_datetime": "2022-03-17T22:06:25.880Z",
					"updated_datetime": "2022-03-17T22:06:25.880Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "109.76200748995115",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "58739761-8687-4420-b4f0-9affe00e9cc2",
					"created_datetime": "2022-03-17T22:06:25.883Z",
					"updated_datetime": "2022-03-17T22:06:25.883Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "5.825260485018276",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "d490ccd9-7bf7-4269-9708-1af068b8a630",
					"created_datetime": "2022-03-17T22:06:25.887Z",
					"updated_datetime": "2022-03-17T22:06:25.887Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "101.25259769774573",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "4f4229f7-d2e3-474a-a64d-6a64fcc749fd",
					"created_datetime": "2022-03-17T22:06:25.890Z",
					"updated_datetime": "2022-03-17T22:06:25.890Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-55.21769214040877",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "634736e9-f711-410f-ab02-eed44a7f6321",
					"created_datetime": "2022-03-17T22:06:25.893Z",
					"updated_datetime": "2022-03-17T22:06:25.893Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-30.518254499190732",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "2145ae25-0b8f-4eb6-95af-9bfc5c46b7ea",
					"created_datetime": "2022-03-17T22:06:25.896Z",
					"updated_datetime": "2022-03-17T22:06:25.896Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "22.22318786213151",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "7027a4e2-999b-4d4e-8079-f6caace0651b",
					"created_datetime": "2022-03-17T22:06:25.899Z",
					"updated_datetime": "2022-03-17T22:06:25.899Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "23.875090891592464",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "3fa877b8-9c42-462b-8cd8-3cabf3b9fc6f",
					"created_datetime": "2022-03-17T22:06:25.902Z",
					"updated_datetime": "2022-03-17T22:06:25.902Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "9.773459010388905",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "78548dc8-d50c-49d3-bcdb-2e24648c1fce",
					"created_datetime": "2022-03-17T22:06:25.905Z",
					"updated_datetime": "2022-03-17T22:06:25.905Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-43.01502819968201",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "3b5149cc-c2ae-45f8-bdcc-e4c3df261e7d",
					"created_datetime": "2022-03-17T22:06:25.911Z",
					"updated_datetime": "2022-03-17T22:06:25.911Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "37.25912043627565",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "36f65b38-b63f-4245-8302-fa14e350f21b",
					"created_datetime": "2022-03-17T22:06:25.918Z",
					"updated_datetime": "2022-03-17T22:06:25.918Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-124.4747570311364",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "06c56370-6772-4f37-9d6a-9f5e7ab08594",
					"created_datetime": "2022-03-17T22:06:25.921Z",
					"updated_datetime": "2022-03-17T22:06:25.921Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-113.17880475513698",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6fdc4bc2-ab9a-4698-a6fd-4796ac94e541",
					"created_datetime": "2022-03-17T22:06:25.924Z",
					"updated_datetime": "2022-03-17T22:06:25.924Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-117.85512575856787",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "b2bcc745-5298-4cf2-948d-95c8c0a41653",
					"created_datetime": "2022-03-17T22:06:25.927Z",
					"updated_datetime": "2022-03-17T22:06:25.927Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-77.71375432319314",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "b422028f-e170-4c32-ba54-4bb13e050604",
					"created_datetime": "2022-03-17T22:06:25.930Z",
					"updated_datetime": "2022-03-17T22:06:25.930Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-105.19747298835121",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "413d9c77-18ba-4959-a1be-b58b679721b0",
					"created_datetime": "2022-03-17T22:06:25.933Z",
					"updated_datetime": "2022-03-17T22:06:25.933Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-0.21350703626441714",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "97bb43fe-7e65-4a85-bc2a-458172c85f1b",
					"created_datetime": "2022-03-17T22:06:25.936Z",
					"updated_datetime": "2022-03-17T22:06:25.936Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "2.8133746938918875",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "72cf8b2a-1377-47d9-a44e-5c54ea1c56e7",
					"created_datetime": "2022-03-17T22:06:25.939Z",
					"updated_datetime": "2022-03-17T22:06:25.939Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-78.09454763044893",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "0311781e-348f-4f64-babc-17e558df12bc",
					"created_datetime": "2022-03-17T22:06:25.942Z",
					"updated_datetime": "2022-03-17T22:06:25.942Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-122.02951011907214",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "05725921-2ef4-410e-82a4-f60add972c6c",
					"created_datetime": "2022-03-17T22:06:25.945Z",
					"updated_datetime": "2022-03-17T22:06:25.945Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-69.26456926112253",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "7a803783-354a-4b2c-94f0-dbe6cda5a123",
					"created_datetime": "2022-03-17T22:06:25.948Z",
					"updated_datetime": "2022-03-17T22:06:25.948Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-6.349409308231458",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "ea59dd39-4f4d-46fb-899f-31ce9b3788a7",
					"created_datetime": "2022-03-17T22:06:25.951Z",
					"updated_datetime": "2022-03-17T22:06:25.951Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-128.96675724339903",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "8c25d77b-dc80-45ea-8efe-b67f132467a9",
					"created_datetime": "2022-03-17T22:06:25.954Z",
					"updated_datetime": "2022-03-17T22:06:25.954Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-77.2113666164025",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "940b2535-86ea-401c-b720-c73bb1bbc793",
					"created_datetime": "2022-03-17T22:06:25.956Z",
					"updated_datetime": "2022-03-17T22:06:25.956Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-81.9222489625961",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ade988aa-db84-4389-b7b5-62f736bce5b7",
					"created_datetime": "2022-03-17T22:06:25.963Z",
					"updated_datetime": "2022-03-17T22:06:25.963Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-137.01767226530936",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "451462be-f689-4417-857e-1fd90f27522e",
					"created_datetime": "2022-03-17T22:06:25.972Z",
					"updated_datetime": "2022-03-17T22:06:25.972Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-23.78565441540269",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "716fa558-2135-4892-96bc-127523632707",
					"created_datetime": "2022-03-17T22:06:25.976Z",
					"updated_datetime": "2022-03-17T22:06:25.976Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_composite",
					"value_string": "0.20002699561392231",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "761e9153-5340-410e-8617-d031bc6ac809",
					"created_datetime": "2022-03-17T22:06:25.979Z",
					"updated_datetime": "2022-03-17T22:06:25.979Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility",
					"value_string": "0.914041475914181",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "159da24e-d0e9-4568-ad07-8323687acd19",
					"created_datetime": "2022-03-17T22:06:25.982Z",
					"updated_datetime": "2022-03-17T22:06:25.982Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability",
					"value_string": "0.49100659518631906",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "ee7eff1d-47a6-4cb5-8c18-d381cd36f645",
					"created_datetime": "2022-03-17T22:06:25.985Z",
					"updated_datetime": "2022-03-17T22:06:25.985Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_posture",
					"value_string": "0.3055992299033925",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "7ad2dc05-d569-49bd-a262-642a785b5251",
					"created_datetime": "2022-03-17T22:06:25.987Z",
					"updated_datetime": "2022-03-17T22:06:25.987Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry",
					"value_string": "0.6304718243014162",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "14f7a260-2200-4b87-bed2-267cf92c30cf",
					"created_datetime": "2022-03-17T22:06:25.990Z",
					"updated_datetime": "2022-03-17T22:06:25.990Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.33076991420548785",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "3cca5c48-479b-4182-8f43-820697bcddf6",
					"created_datetime": "2022-03-17T22:06:25.994Z",
					"updated_datetime": "2022-03-17T22:06:25.994Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.20525912140745833",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "39b277d0-cb34-409e-8b25-8b8fc2711c30",
					"created_datetime": "2022-03-17T22:06:25.996Z",
					"updated_datetime": "2022-03-17T22:06:25.996Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.5289473460759204",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "956db771-be89-4903-b9dd-3a0496dce3b8",
					"created_datetime": "2022-03-17T22:06:25.999Z",
					"updated_datetime": "2022-03-17T22:06:25.999Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.04716748426971854",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "c55b0784-7b8d-4f58-9a83-15bce6976a3f",
					"created_datetime": "2022-03-17T22:06:26.002Z",
					"updated_datetime": "2022-03-17T22:06:26.002Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.48755353971751153",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "d82dc9ca-2ac9-4345-9b61-aee6c111e515",
					"created_datetime": "2022-03-17T22:06:26.006Z",
					"updated_datetime": "2022-03-17T22:06:26.006Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5750905252271072",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "536ecea7-3c30-4a2d-b8ef-9ecbf3845fe4",
					"created_datetime": "2022-03-17T22:06:26.011Z",
					"updated_datetime": "2022-03-17T22:06:26.011Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.8015261267117393",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "e345aa28-ef1c-4e2a-86c0-7370db2f0468",
					"created_datetime": "2022-03-17T22:06:26.015Z",
					"updated_datetime": "2022-03-17T22:06:26.015Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.4782092400709166",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "27f29e8b-e706-4736-a475-86a64316d647",
					"created_datetime": "2022-03-17T22:06:26.022Z",
					"updated_datetime": "2022-03-17T22:06:26.022Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8931793399521926",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "2affe206-af0c-410b-8e1f-587954a54bfc",
					"created_datetime": "2022-03-17T22:06:26.025Z",
					"updated_datetime": "2022-03-17T22:06:26.025Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8895499284640985",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "f2501a58-5455-4398-a6b4-baa25b09250a",
					"created_datetime": "2022-03-17T22:06:26.028Z",
					"updated_datetime": "2022-03-17T22:06:26.028Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.9756623643576754",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "6b71cf6f-fe30-406c-84db-cc5be5e04e56",
					"created_datetime": "2022-03-17T22:06:26.032Z",
					"updated_datetime": "2022-03-17T22:06:26.032Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.6500451118978379",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "7bf02a2f-0860-46a4-8a37-b71788828ad5",
					"created_datetime": "2022-03-17T22:06:26.034Z",
					"updated_datetime": "2022-03-17T22:06:26.034Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9004009636934178",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "b7c08692-04e4-4257-a3fd-7b3ec4c312f3",
					"created_datetime": "2022-03-17T22:06:26.038Z",
					"updated_datetime": "2022-03-17T22:06:26.038Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability_scap_right",
					"value_string": "0.033970270034041",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "2ea8b6bb-e612-4c01-b425-a5bd624335d3",
					"created_datetime": "2022-03-17T22:06:26.041Z",
					"updated_datetime": "2022-03-17T22:06:26.041Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.8758454133993713",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "c6185afd-6592-412a-b669-5b9a6819ee75",
					"created_datetime": "2022-03-17T22:06:26.044Z",
					"updated_datetime": "2022-03-17T22:06:26.044Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.1781804406444966",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "6da520d3-1585-4902-9601-1833594b0493",
					"created_datetime": "2022-03-17T22:06:26.047Z",
					"updated_datetime": "2022-03-17T22:06:26.047Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.913793510347935",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "76da9704-f151-41d6-85d1-a770c34fca59",
					"created_datetime": "2022-03-17T22:06:26.050Z",
					"updated_datetime": "2022-03-17T22:06:26.050Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.0015148149919767216",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "046616b9-6d9b-42b4-9742-7df41ff64a01",
					"created_datetime": "2022-03-17T22:06:26.053Z",
					"updated_datetime": "2022-03-17T22:06:26.053Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.2300020177763238",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "fb1dcdc1-b02c-436f-874e-46136f1bdee2",
					"created_datetime": "2022-03-17T22:06:26.056Z",
					"updated_datetime": "2022-03-17T22:06:26.056Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.24776403928548094",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "91fdd082-0897-4cdc-b008-f5528d2a39fb",
					"created_datetime": "2022-03-17T22:06:26.060Z",
					"updated_datetime": "2022-03-17T22:06:26.060Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.22931698034150833",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "cb986495-da34-4d78-8eb6-640e008a2126",
					"created_datetime": "2022-03-17T22:06:26.063Z",
					"updated_datetime": "2022-03-17T22:06:26.063Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.09474062844276061",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "58ebc621-2888-46ab-9fc8-49e5a8f23caa",
					"created_datetime": "2022-03-17T22:06:26.066Z",
					"updated_datetime": "2022-03-17T22:06:26.066Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_hip",
					"value_string": "0.2593997866994721",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "ce061b10-f0e9-407a-80a6-4583989bda38",
					"created_datetime": "2022-03-17T22:06:26.069Z",
					"updated_datetime": "2022-03-17T22:06:26.069Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_femur",
					"value_string": "0.906757355222433",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "af10c3e6-2e98-4e28-90b8-e3d7506974d5",
					"created_datetime": "2022-03-17T22:06:26.072Z",
					"updated_datetime": "2022-03-17T22:06:26.072Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_knee",
					"value_string": "0.07288453079017755",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "15eb1241-7820-4a56-9c8e-c8067b40e68f",
					"created_datetime": "2022-03-17T22:06:26.075Z",
					"updated_datetime": "2022-03-17T22:06:26.075Z",
					"assessment_id_guid": "373490df-9f76-4e81-a83a-e8026516234c",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.0983551984371359",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
			"created_datetime": "2022-03-17T22:08:16.362Z",
			"updated_datetime": "2022-03-17T22:08:16.362Z",
			"submitter_user_id_guid": "f56a45bb-cdaf-4ff3-931c-7a100c0160e8",
			"athlete_user_id_guid": "f6d8fac9-6d35-4d92-8a57-4e36a4bfc684",
			"external_id_guid": "0e325b30-b5d5-4248-997f-097a12d2a480",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c7531204-8c48-4af2-9a34-56dd474ab7cf",
					"created_datetime": "2022-03-17T22:08:16.367Z",
					"updated_datetime": "2022-03-17T22:08:16.367Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T22:11:05.180Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "73044b86-5593-40b0-baaa-ffc7db92a565",
					"created_datetime": "2022-03-17T22:08:16.370Z",
					"updated_datetime": "2022-03-17T22:08:16.370Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "4dcc220b-63a1-4228-9a5d-5d65bc4ef194",
					"created_datetime": "2022-03-17T22:08:16.374Z",
					"updated_datetime": "2022-03-17T22:08:16.374Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-19.91278873187065",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "7197ebb9-07a7-470d-bba6-3bc2df0f63cb",
					"created_datetime": "2022-03-17T22:08:16.377Z",
					"updated_datetime": "2022-03-17T22:08:16.377Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "131.92896524942728",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "516cb742-7bda-4f9c-a176-d3175e1204ab",
					"created_datetime": "2022-03-17T22:08:16.380Z",
					"updated_datetime": "2022-03-17T22:08:16.380Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-123.88795603585058",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "c8475dbb-9c7e-4655-81a2-099e23f43efd",
					"created_datetime": "2022-03-17T22:08:16.384Z",
					"updated_datetime": "2022-03-17T22:08:16.384Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "66.98812256400032",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "bc0c814b-2fb2-444f-8596-7b327dbe8d61",
					"created_datetime": "2022-03-17T22:08:16.387Z",
					"updated_datetime": "2022-03-17T22:08:16.387Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-50.64083734190919",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "b88ecf2e-9257-4647-882a-b8b2acef9f73",
					"created_datetime": "2022-03-17T22:08:16.390Z",
					"updated_datetime": "2022-03-17T22:08:16.390Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-0.9638622289306511",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "0ff457e3-577a-45d4-9c0f-7651b9a75d73",
					"created_datetime": "2022-03-17T22:08:16.393Z",
					"updated_datetime": "2022-03-17T22:08:16.393Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "110.32888394196465",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "7a7c67d3-346e-4c2d-9754-6cb609fbb971",
					"created_datetime": "2022-03-17T22:08:16.397Z",
					"updated_datetime": "2022-03-17T22:08:16.397Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "72.83512415909019",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "1084074e-a88b-4e50-850b-794cc0c35b4f",
					"created_datetime": "2022-03-17T22:08:16.400Z",
					"updated_datetime": "2022-03-17T22:08:16.400Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-118.39290860883469",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "a8cff46c-254d-4cec-954f-52123527b785",
					"created_datetime": "2022-03-17T22:08:16.403Z",
					"updated_datetime": "2022-03-17T22:08:16.403Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "64.68303458091165",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "6d5e230d-cc70-413a-9fa2-baad386e7104",
					"created_datetime": "2022-03-17T22:08:16.407Z",
					"updated_datetime": "2022-03-17T22:08:16.407Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "57.88207903781199",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "364d0721-8b48-4936-b92b-16cb441bb615",
					"created_datetime": "2022-03-17T22:08:16.411Z",
					"updated_datetime": "2022-03-17T22:08:16.411Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-80.04716189546566",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "1cfa25c7-f6f3-4237-8c71-4ede8b58b9d2",
					"created_datetime": "2022-03-17T22:08:16.414Z",
					"updated_datetime": "2022-03-17T22:08:16.414Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-109.84854675227551",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "8e9dfba9-f136-4b8a-81d6-c91fc55036b5",
					"created_datetime": "2022-03-17T22:08:16.417Z",
					"updated_datetime": "2022-03-17T22:08:16.417Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_stability_scap_right",
					"value_string": "88.9772512603185",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "060b50aa-8331-4077-84a1-e87fa4faa0e5",
					"created_datetime": "2022-03-17T22:08:16.423Z",
					"updated_datetime": "2022-03-17T22:08:16.423Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-47.91948734272074",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "c9c25098-b76f-4749-abd2-cb2eb2996216",
					"created_datetime": "2022-03-17T22:08:16.426Z",
					"updated_datetime": "2022-03-17T22:08:16.426Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "77.39440354850558",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "4e2af6f6-889d-4c67-a7f9-9604f163af44",
					"created_datetime": "2022-03-17T22:08:16.430Z",
					"updated_datetime": "2022-03-17T22:08:16.430Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "88.43646651523062",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "0d674e40-7a5e-415a-b744-44090a2f6fb4",
					"created_datetime": "2022-03-17T22:08:16.433Z",
					"updated_datetime": "2022-03-17T22:08:16.433Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "144.7819671618367",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "4ea3970b-3dec-431c-8078-c7eed471d8d4",
					"created_datetime": "2022-03-17T22:08:16.437Z",
					"updated_datetime": "2022-03-17T22:08:16.437Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "116.09791298188458",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "23b4e4a3-ac66-4cbe-9cf0-1ab6ddbac57d",
					"created_datetime": "2022-03-17T22:08:16.440Z",
					"updated_datetime": "2022-03-17T22:08:16.440Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "2.7275355847682192",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "7719167f-753f-4f5b-b2c2-9d4475d9b2c1",
					"created_datetime": "2022-03-17T22:08:16.443Z",
					"updated_datetime": "2022-03-17T22:08:16.443Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "75.29611125607695",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "738069ec-c757-4601-8aee-086cc1898387",
					"created_datetime": "2022-03-17T22:08:16.447Z",
					"updated_datetime": "2022-03-17T22:08:16.447Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "2.656233931535354",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "a7f67c5f-e836-4e21-9d3d-1bc51cadc129",
					"created_datetime": "2022-03-17T22:08:16.450Z",
					"updated_datetime": "2022-03-17T22:08:16.450Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-105.74622453278437",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "821b74a9-1cde-4807-9a12-8c8193d9398c",
					"created_datetime": "2022-03-17T22:08:16.454Z",
					"updated_datetime": "2022-03-17T22:08:16.454Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-0.17931031408733134",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "86ee5de7-694a-4443-bd33-fc0ba6bdaae4",
					"created_datetime": "2022-03-17T22:08:16.457Z",
					"updated_datetime": "2022-03-17T22:08:16.457Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-3.045538471403802",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "e40aae9c-cda0-4d57-805d-647a81b321fd",
					"created_datetime": "2022-03-17T22:08:16.461Z",
					"updated_datetime": "2022-03-17T22:08:16.461Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "2.5235550616170883",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "8f18e595-e4bb-4e1e-8e7a-b4885be0f07e",
					"created_datetime": "2022-03-17T22:08:16.464Z",
					"updated_datetime": "2022-03-17T22:08:16.464Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_composite",
					"value_string": "0.6290357986988642",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "7ed4e691-1dda-4937-8d1d-c5909b5a5d9a",
					"created_datetime": "2022-03-17T22:08:16.468Z",
					"updated_datetime": "2022-03-17T22:08:16.468Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility",
					"value_string": "0.40950470141485873",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "50a53009-5dd7-429b-9c26-5241754bbcc1",
					"created_datetime": "2022-03-17T22:08:16.471Z",
					"updated_datetime": "2022-03-17T22:08:16.471Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability",
					"value_string": "0.2982245989997721",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "0e3f0873-eb4d-4851-8a0a-241fa59d0b33",
					"created_datetime": "2022-03-17T22:08:16.475Z",
					"updated_datetime": "2022-03-17T22:08:16.475Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_posture",
					"value_string": "0.7938904988242107",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "ab1ad69e-accc-4104-8385-8ebb41cab3df",
					"created_datetime": "2022-03-17T22:08:16.478Z",
					"updated_datetime": "2022-03-17T22:08:16.478Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry",
					"value_string": "0.3795486597382825",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "e23880bd-c1d0-4acc-b70c-b5425008973d",
					"created_datetime": "2022-03-17T22:08:16.481Z",
					"updated_datetime": "2022-03-17T22:08:16.481Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.5582041571516996",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "8d7a4a79-eb5e-42d6-96a2-b95230ea2e96",
					"created_datetime": "2022-03-17T22:08:16.484Z",
					"updated_datetime": "2022-03-17T22:08:16.484Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5692704765830677",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "9d12827f-b042-440f-a235-77d138adc6c6",
					"created_datetime": "2022-03-17T22:08:16.489Z",
					"updated_datetime": "2022-03-17T22:08:16.489Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.22348652502422459",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "5e808c8d-5b78-49ad-9da3-1cada491e9a7",
					"created_datetime": "2022-03-17T22:08:16.492Z",
					"updated_datetime": "2022-03-17T22:08:16.492Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.7689553651970414",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "13887362-0515-495d-aa5e-d5e501f9e220",
					"created_datetime": "2022-03-17T22:08:16.495Z",
					"updated_datetime": "2022-03-17T22:08:16.495Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5679281836407741",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "5a9e6e13-bbe9-4ac8-b19e-8b917ce61b44",
					"created_datetime": "2022-03-17T22:08:16.499Z",
					"updated_datetime": "2022-03-17T22:08:16.499Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.7781126135405328",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "acc67b25-7bf7-4764-9c8a-98cb3cb93b5c",
					"created_datetime": "2022-03-17T22:08:16.502Z",
					"updated_datetime": "2022-03-17T22:08:16.502Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.21683904913540816",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "032e86fd-afd1-46f3-b28e-40cc02cd4b4d",
					"created_datetime": "2022-03-17T22:08:16.505Z",
					"updated_datetime": "2022-03-17T22:08:16.505Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.07175849402118396",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "e373a0cc-2daa-4d6b-b45d-97cf7f8ccaa5",
					"created_datetime": "2022-03-17T22:08:16.509Z",
					"updated_datetime": "2022-03-17T22:08:16.509Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9431249046272214",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "c99b5103-651e-4cdf-910e-56c67a76d9ba",
					"created_datetime": "2022-03-17T22:08:16.512Z",
					"updated_datetime": "2022-03-17T22:08:16.512Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.23164927857918127",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "064fec3a-d42d-4001-8699-d009f6f91763",
					"created_datetime": "2022-03-17T22:08:16.515Z",
					"updated_datetime": "2022-03-17T22:08:16.515Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.18296357487084525",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "749c93aa-2e0c-4c60-9525-82c007d8a38e",
					"created_datetime": "2022-03-17T22:08:16.518Z",
					"updated_datetime": "2022-03-17T22:08:16.518Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.6067748332545322",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "9f9c8b00-c048-4678-a79d-e68dd36b573a",
					"created_datetime": "2022-03-17T22:08:16.522Z",
					"updated_datetime": "2022-03-17T22:08:16.522Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability_scap_left",
					"value_string": "0.03803652318916427",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "4b2712bf-4fa1-4063-8b11-3329078c1d36",
					"created_datetime": "2022-03-17T22:08:16.525Z",
					"updated_datetime": "2022-03-17T22:08:16.525Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability_scap_right",
					"value_string": "0.9737128466061366",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "74d0a122-1465-40eb-aba0-4e878aabaaa3",
					"created_datetime": "2022-03-17T22:08:16.528Z",
					"updated_datetime": "2022-03-17T22:08:16.528Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.042687125472217244",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "d001b1fb-fb0b-48fc-b35c-7698a4a30182",
					"created_datetime": "2022-03-17T22:08:16.531Z",
					"updated_datetime": "2022-03-17T22:08:16.531Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.7145870783384338",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "c8259982-8009-418d-980b-24b42ea8a71d",
					"created_datetime": "2022-03-17T22:08:16.535Z",
					"updated_datetime": "2022-03-17T22:08:16.535Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.7924898723026956",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "cb7edc9d-711e-4f56-a98f-7693de3e66d1",
					"created_datetime": "2022-03-17T22:08:16.538Z",
					"updated_datetime": "2022-03-17T22:08:16.538Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.4551499759132308",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "1af1d2d7-eb3b-419a-8b51-28b5d541f803",
					"created_datetime": "2022-03-17T22:08:16.543Z",
					"updated_datetime": "2022-03-17T22:08:16.543Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.6741730905798492",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "225afb17-ff41-40dc-8be3-22f875897574",
					"created_datetime": "2022-03-17T22:08:16.547Z",
					"updated_datetime": "2022-03-17T22:08:16.547Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.4007674067941594",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "0574b9da-d6ae-4950-ac07-1924d3a56782",
					"created_datetime": "2022-03-17T22:08:16.550Z",
					"updated_datetime": "2022-03-17T22:08:16.550Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9666398194913884",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "eccdfd73-4165-48c2-932d-0cb708fe401b",
					"created_datetime": "2022-03-17T22:08:16.554Z",
					"updated_datetime": "2022-03-17T22:08:16.554Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.8016845404164643",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "b7c241b2-620e-417c-8df6-a03ef4a7b2cd",
					"created_datetime": "2022-03-17T22:08:16.557Z",
					"updated_datetime": "2022-03-17T22:08:16.557Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_hip",
					"value_string": "0.4443390368536727",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "d5db47f8-b84c-4186-85b0-eafada924a35",
					"created_datetime": "2022-03-17T22:08:16.560Z",
					"updated_datetime": "2022-03-17T22:08:16.560Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_femur",
					"value_string": "0.81255712615127",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "054ee728-d8f8-4eaf-b22e-0599fae98e08",
					"created_datetime": "2022-03-17T22:08:16.564Z",
					"updated_datetime": "2022-03-17T22:08:16.564Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_knee",
					"value_string": "0.00963853419932013",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "ce921a90-1bec-457e-8d5a-e921a51dff2c",
					"created_datetime": "2022-03-17T22:08:16.568Z",
					"updated_datetime": "2022-03-17T22:08:16.568Z",
					"assessment_id_guid": "91d07dfd-b6b1-40a4-8920-da9b03807f15",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.45953056907448336",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
			"created_datetime": "2022-03-17T22:32:34.150Z",
			"updated_datetime": "2022-03-17T22:32:34.150Z",
			"submitter_user_id_guid": "caa6138e-bfea-473b-9639-f6574b025376",
			"athlete_user_id_guid": "f50eefc8-7ef1-486a-ae87-32f667b05a12",
			"external_id_guid": "d3c1a0e4-969c-4439-98a5-1bfdb05980a6",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "0f6a0df5-78d7-48f8-bdbd-afcdeefc31e5",
					"created_datetime": "2022-03-17T22:32:34.153Z",
					"updated_datetime": "2022-03-17T22:32:34.153Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T22:32:34.006Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "e867ef5d-b581-4a1b-b6bf-35145b91c4f4",
					"created_datetime": "2022-03-17T22:32:34.156Z",
					"updated_datetime": "2022-03-17T22:32:34.156Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "da145a50-e1ee-4015-a76e-e83d8224c74a",
					"created_datetime": "2022-03-17T22:32:34.159Z",
					"updated_datetime": "2022-03-17T22:32:34.159Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "165.41773986816406",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "956255a0-0040-4fa2-a3a4-aebe61705c0c",
					"created_datetime": "2022-03-17T22:32:34.161Z",
					"updated_datetime": "2022-03-17T22:32:34.161Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "155.60992431640625",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "5eafd25b-10c2-458c-8c2b-e40babd1ee8f",
					"created_datetime": "2022-03-17T22:32:34.164Z",
					"updated_datetime": "2022-03-17T22:32:34.164Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "160.92601013183594",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "984b45c5-1bac-4e20-8ca0-e4cae67327f3",
					"created_datetime": "2022-03-17T22:32:34.169Z",
					"updated_datetime": "2022-03-17T22:32:34.169Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "154.4777374267578",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "787591fd-af9d-4d69-8505-a8031e3b2566",
					"created_datetime": "2022-03-17T22:32:34.172Z",
					"updated_datetime": "2022-03-17T22:32:34.172Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "112.9952621459961",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "1cb2ed5b-253b-48e2-a43c-a7c82b3ca65a",
					"created_datetime": "2022-03-17T22:32:34.175Z",
					"updated_datetime": "2022-03-17T22:32:34.175Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "113.00725555419922",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "8d3ef284-7a40-41d9-a319-9f6f9a7ecdab",
					"created_datetime": "2022-03-17T22:32:34.180Z",
					"updated_datetime": "2022-03-17T22:32:34.180Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "76.46663665771484",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "1512e449-ba5b-4d17-a043-7f64c56d98cc",
					"created_datetime": "2022-03-17T22:32:34.183Z",
					"updated_datetime": "2022-03-17T22:32:34.183Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "74.75900268554688",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "affd1c85-443c-4d2d-90b0-0640aa1bf70f",
					"created_datetime": "2022-03-17T22:32:34.187Z",
					"updated_datetime": "2022-03-17T22:32:34.187Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "88.11254119873047",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "8374b1e7-b490-497e-bf85-d6fb4e0b8828",
					"created_datetime": "2022-03-17T22:32:34.190Z",
					"updated_datetime": "2022-03-17T22:32:34.190Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "90.62598419189453",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "70d8d634-66d4-41f5-b113-f3c03dba0c3c",
					"created_datetime": "2022-03-17T22:32:34.193Z",
					"updated_datetime": "2022-03-17T22:32:34.193Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "23.171293258666992",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "1b026192-8546-4c30-8fec-a2af6bcac700",
					"created_datetime": "2022-03-17T22:32:34.195Z",
					"updated_datetime": "2022-03-17T22:32:34.195Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "25.30989646911621",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "5be5da8e-0e54-4e01-b8da-aff7db02d6a9",
					"created_datetime": "2022-03-17T22:32:34.197Z",
					"updated_datetime": "2022-03-17T22:32:34.197Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_stability_scap_left",
					"value_string": "5.821329593658447",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "d7b1e23f-df84-4375-b833-7aba7016b5f9",
					"created_datetime": "2022-03-17T22:32:34.200Z",
					"updated_datetime": "2022-03-17T22:32:34.200Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_stability_scap_right",
					"value_string": "6.899159908294678",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "db156b3d-9f5a-4d7e-86ba-ec27051095cf",
					"created_datetime": "2022-03-17T22:32:34.205Z",
					"updated_datetime": "2022-03-17T22:32:34.205Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "38.0578498840332",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "1889f6fd-b5d3-4dc3-93b6-5d40f3b0fd3d",
					"created_datetime": "2022-03-17T22:32:34.208Z",
					"updated_datetime": "2022-03-17T22:32:34.208Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "15.325889587402344",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "80aca217-0e85-45fd-a9cb-4a9778ad04bd",
					"created_datetime": "2022-03-17T22:32:34.210Z",
					"updated_datetime": "2022-03-17T22:32:34.210Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "13.230751037597656",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "17728ba2-0db1-457c-8841-f46a931391b6",
					"created_datetime": "2022-03-17T22:32:34.212Z",
					"updated_datetime": "2022-03-17T22:32:34.212Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "0.08350750058889389",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "96b4be8e-5bba-41a3-b62d-a6ee8537ffe6",
					"created_datetime": "2022-03-17T22:32:34.215Z",
					"updated_datetime": "2022-03-17T22:32:34.215Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.563217043876648",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "b6433d69-e421-43c9-8dfc-99e60522fcc7",
					"created_datetime": "2022-03-17T22:32:34.229Z",
					"updated_datetime": "2022-03-17T22:32:34.229Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "7.327997207641602",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "086425d7-c6af-4eb7-9b8d-4cf9fedd33fd",
					"created_datetime": "2022-03-17T22:32:34.231Z",
					"updated_datetime": "2022-03-17T22:32:34.231Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "8.373013496398926",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "5c99230e-cea8-4fa3-b0fc-3ca833a904b6",
					"created_datetime": "2022-03-17T22:32:34.234Z",
					"updated_datetime": "2022-03-17T22:32:34.234Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.5279344320297241",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "813cf68a-a23e-4b2f-96a4-f3a4edac9a58",
					"created_datetime": "2022-03-17T22:32:34.236Z",
					"updated_datetime": "2022-03-17T22:32:34.236Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_hip",
					"value_string": "1.9500328302383423",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "48c9852e-0a4c-423d-a9c2-849f22ca7309",
					"created_datetime": "2022-03-17T22:32:34.239Z",
					"updated_datetime": "2022-03-17T22:32:34.239Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-0.8447300791740417",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "f665f23a-7813-447f-94bf-20fb72a3dd2f",
					"created_datetime": "2022-03-17T22:32:34.242Z",
					"updated_datetime": "2022-03-17T22:32:34.242Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_knee",
					"value_string": "0.8907448649406433",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "e393665b-3399-40c5-9d41-fdd5223c13e6",
					"created_datetime": "2022-03-17T22:32:34.245Z",
					"updated_datetime": "2022-03-17T22:32:34.245Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "1.6597137451171875",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "7300dc36-5c94-474c-943c-1368507bd860",
					"created_datetime": "2022-03-17T22:32:34.247Z",
					"updated_datetime": "2022-03-17T22:32:34.247Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_composite",
					"value_string": "0.5465275049209595",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "ca65e238-bd92-43df-9897-2b7dd1c2e34b",
					"created_datetime": "2022-03-17T22:32:34.250Z",
					"updated_datetime": "2022-03-17T22:32:34.250Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility",
					"value_string": "0.45669546723365784",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "a0381071-07e1-41db-986e-a5fd95d7336e",
					"created_datetime": "2022-03-17T22:32:34.253Z",
					"updated_datetime": "2022-03-17T22:32:34.253Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability",
					"value_string": "0.5011927485466003",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "5d012ecd-9200-417d-a4ca-bc8a956b8f1c",
					"created_datetime": "2022-03-17T22:32:34.256Z",
					"updated_datetime": "2022-03-17T22:32:34.256Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_posture",
					"value_string": "0.622111439704895",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "de2ae399-9f02-456c-935b-10ced53a1117",
					"created_datetime": "2022-03-17T22:32:34.258Z",
					"updated_datetime": "2022-03-17T22:32:34.258Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry",
					"value_string": "0.7977557182312012",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "606544b0-4c24-424c-84da-6730283f92fb",
					"created_datetime": "2022-03-17T22:32:34.260Z",
					"updated_datetime": "2022-03-17T22:32:34.260Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7563207745552063",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "f8ee1469-9b54-401e-8df0-4a81207b7fc1",
					"created_datetime": "2022-03-17T22:32:34.263Z",
					"updated_datetime": "2022-03-17T22:32:34.263Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5162172317504883",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "fbc8afb5-bde4-471e-951e-46b79ddc6876",
					"created_datetime": "2022-03-17T22:32:34.265Z",
					"updated_datetime": "2022-03-17T22:32:34.265Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.6536033153533936",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "39a7f699-a9eb-4946-b7ef-5fe724e55602",
					"created_datetime": "2022-03-17T22:32:34.267Z",
					"updated_datetime": "2022-03-17T22:32:34.267Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.4861125349998474",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "4a32e24f-cd65-4d47-a433-e34437e4a0db",
					"created_datetime": "2022-03-17T22:32:34.270Z",
					"updated_datetime": "2022-03-17T22:32:34.270Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5791854858398438",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "adeacc71-b0e2-4861-83a3-8c28c352447a",
					"created_datetime": "2022-03-17T22:32:34.272Z",
					"updated_datetime": "2022-03-17T22:32:34.272Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5793732404708862",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "3ec5d1f0-9980-4bbb-81bb-821518f7ffdf",
					"created_datetime": "2022-03-17T22:32:34.274Z",
					"updated_datetime": "2022-03-17T22:32:34.274Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.7667552828788757",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "157a3148-7fec-4424-9d8a-a2e5c5095629",
					"created_datetime": "2022-03-17T22:32:34.285Z",
					"updated_datetime": "2022-03-17T22:32:34.285Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.724695086479187",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "690fae3c-2653-46d9-ba6e-b64f646e06ed",
					"created_datetime": "2022-03-17T22:32:34.288Z",
					"updated_datetime": "2022-03-17T22:32:34.288Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.08941400051116943",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "6369b350-72a6-4e63-9308-b19aa2fc97ac",
					"created_datetime": "2022-03-17T22:32:34.298Z",
					"updated_datetime": "2022-03-17T22:32:34.298Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.11147895455360413",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "de0e6cfa-ad61-40c6-9352-71499b8f6e5f",
					"created_datetime": "2022-03-17T22:32:34.303Z",
					"updated_datetime": "2022-03-17T22:32:34.303Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.012684822082519531",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "eb6e48f2-0eb5-4471-b5e2-40b80661c8a8",
					"created_datetime": "2022-03-17T22:32:34.305Z",
					"updated_datetime": "2022-03-17T22:32:34.305Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.019818603992462158",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "320b6ee6-4be8-436f-94e0-5b4069e25a61",
					"created_datetime": "2022-03-17T22:32:34.308Z",
					"updated_datetime": "2022-03-17T22:32:34.308Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability_scap_left",
					"value_string": "0.8813089728355408",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "f290aaf6-642a-4d49-87b6-5241c72c3952",
					"created_datetime": "2022-03-17T22:32:34.310Z",
					"updated_datetime": "2022-03-17T22:32:34.310Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8625255227088928",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "d6bb90f0-52f5-41a5-ba6a-ebc9140bd692",
					"created_datetime": "2022-03-17T22:32:34.313Z",
					"updated_datetime": "2022-03-17T22:32:34.313Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5769962072372437",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "e953840c-3442-491e-ab63-22a44112fe4f",
					"created_datetime": "2022-03-17T22:32:34.316Z",
					"updated_datetime": "2022-03-17T22:32:34.316Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.16666927933692932",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "a9fa0f10-2233-4f62-9448-75a244de9120",
					"created_datetime": "2022-03-17T22:32:34.318Z",
					"updated_datetime": "2022-03-17T22:32:34.318Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.11961281299591064",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "5cdb0fd9-173a-44fe-ba75-2a00ad8cbb48",
					"created_datetime": "2022-03-17T22:32:34.321Z",
					"updated_datetime": "2022-03-17T22:32:34.321Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9717535972595215",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "941efa9c-bf74-4d64-82cf-79b48da0a4a9",
					"created_datetime": "2022-03-17T22:32:34.324Z",
					"updated_datetime": "2022-03-17T22:32:34.324Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.27246928215026855",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "7f14c081-9d37-4f2b-a7b7-d73bd0c5d91d",
					"created_datetime": "2022-03-17T22:32:34.326Z",
					"updated_datetime": "2022-03-17T22:32:34.326Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.15572774410247803",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "d8ba9b3e-746d-4836-8446-caaa37237436",
					"created_datetime": "2022-03-17T22:32:34.329Z",
					"updated_datetime": "2022-03-17T22:32:34.329Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.08863556385040283",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "e0f60a98-d940-415d-9e34-8a166e9fff77",
					"created_datetime": "2022-03-17T22:32:34.331Z",
					"updated_datetime": "2022-03-17T22:32:34.331Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9809980392456055",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "74ce7176-c259-4105-8ea1-eebb18f40566",
					"created_datetime": "2022-03-17T22:32:34.334Z",
					"updated_datetime": "2022-03-17T22:32:34.334Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9707072377204895",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "0293f404-9e6a-435d-87ef-fcbe82ce2fe9",
					"created_datetime": "2022-03-17T22:32:34.337Z",
					"updated_datetime": "2022-03-17T22:32:34.337Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9878945350646973",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "60ed42ea-0ce4-44d1-b7a1-a76872c60bdb",
					"created_datetime": "2022-03-17T22:32:34.340Z",
					"updated_datetime": "2022-03-17T22:32:34.340Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9888055324554443",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "18016295-226f-4474-8739-300f78ff5d81",
					"created_datetime": "2022-03-17T22:32:34.343Z",
					"updated_datetime": "2022-03-17T22:32:34.343Z",
					"assessment_id_guid": "99d92948-184f-405e-8134-afda9a7f8165",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9374161958694458",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
			"created_datetime": "2022-03-17T22:33:11.919Z",
			"updated_datetime": "2022-03-17T22:33:11.919Z",
			"submitter_user_id_guid": "c5297205-49f3-4534-987f-86d62d0b2fa0",
			"athlete_user_id_guid": "cf0cca90-a418-4248-9ad6-0d732d761407",
			"external_id_guid": "69133c1c-aa24-4ea4-88c9-a2ef2bf5191f",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c59b5e56-a100-4887-93a3-09fe22ee4347",
					"created_datetime": "2022-03-17T22:33:12.195Z",
					"updated_datetime": "2022-03-17T22:33:12.195Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T22:33:11.664Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "f32de1fd-a6c5-4451-a960-b8cd22067418",
					"created_datetime": "2022-03-17T22:33:12.199Z",
					"updated_datetime": "2022-03-17T22:33:12.199Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "a1440d63-25a0-450e-839c-8161eecbb836",
					"created_datetime": "2022-03-17T22:33:12.202Z",
					"updated_datetime": "2022-03-17T22:33:12.202Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "151.0836639404297",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "a6c1ab91-6099-48e3-a211-4faaf0cde9ee",
					"created_datetime": "2022-03-17T22:33:12.205Z",
					"updated_datetime": "2022-03-17T22:33:12.205Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "163.02513122558594",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "08e33658-1d2c-4101-9efe-17b1fc0cf184",
					"created_datetime": "2022-03-17T22:33:12.207Z",
					"updated_datetime": "2022-03-17T22:33:12.207Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "160.9623565673828",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "65fd98ea-7a7e-4c5f-bc78-6ddbace98142",
					"created_datetime": "2022-03-17T22:33:12.210Z",
					"updated_datetime": "2022-03-17T22:33:12.210Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "151.79307556152344",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "06a13313-312d-45b3-9562-d73c98fc637e",
					"created_datetime": "2022-03-17T22:33:12.213Z",
					"updated_datetime": "2022-03-17T22:33:12.213Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "97.0720443725586",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "c1a47348-5fab-4854-a8ee-33f76720b9ee",
					"created_datetime": "2022-03-17T22:33:12.216Z",
					"updated_datetime": "2022-03-17T22:33:12.216Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "92.73980712890625",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "4e929ce3-148b-4e61-a505-39394f87d4f6",
					"created_datetime": "2022-03-17T22:33:12.219Z",
					"updated_datetime": "2022-03-17T22:33:12.219Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "66.6826171875",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "857943c6-5ff6-4343-9bee-84265529d246",
					"created_datetime": "2022-03-17T22:33:12.221Z",
					"updated_datetime": "2022-03-17T22:33:12.221Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "69.18011474609375",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "e7bd687a-ff4e-4b9b-bd3c-2b9e464389c2",
					"created_datetime": "2022-03-17T22:33:12.224Z",
					"updated_datetime": "2022-03-17T22:33:12.224Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "100.97457122802734",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "fbeee7f0-e5e2-4187-bf6e-9a886315d2e8",
					"created_datetime": "2022-03-17T22:33:12.226Z",
					"updated_datetime": "2022-03-17T22:33:12.226Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "97.97882080078125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "0a227326-80c6-4e27-b259-806ecb4e90c2",
					"created_datetime": "2022-03-17T22:33:12.229Z",
					"updated_datetime": "2022-03-17T22:33:12.229Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "39.35654830932617",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "5dc9aa19-9ca4-4df2-895b-ec0ed62cd636",
					"created_datetime": "2022-03-17T22:33:12.233Z",
					"updated_datetime": "2022-03-17T22:33:12.233Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "35.464813232421875",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "fb75f0ba-39c2-49e2-8a55-425224839582",
					"created_datetime": "2022-03-17T22:33:12.235Z",
					"updated_datetime": "2022-03-17T22:33:12.235Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_stability_scap_left",
					"value_string": "23.446868896484375",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "41bbc586-db23-446a-a22b-5a560303adf9",
					"created_datetime": "2022-03-17T22:33:12.238Z",
					"updated_datetime": "2022-03-17T22:33:12.238Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_stability_scap_right",
					"value_string": "19.603424072265625",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "028c5992-7348-4fce-b561-cf49a83439dd",
					"created_datetime": "2022-03-17T22:33:12.240Z",
					"updated_datetime": "2022-03-17T22:33:12.240Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "27.718109130859375",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "3b03958b-d6c2-4fa0-993b-3c6ea454f89a",
					"created_datetime": "2022-03-17T22:33:12.243Z",
					"updated_datetime": "2022-03-17T22:33:12.243Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "10.44436264038086",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "9aaabc43-70bf-4cda-93af-57bf26856a49",
					"created_datetime": "2022-03-17T22:33:12.245Z",
					"updated_datetime": "2022-03-17T22:33:12.245Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "16.764488220214844",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "26ef2723-ae8d-48c1-94b0-4176ee2ad92d",
					"created_datetime": "2022-03-17T22:33:12.247Z",
					"updated_datetime": "2022-03-17T22:33:12.247Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-1.2011873722076416",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "08a12c6a-f8d8-46f3-bc45-a960a537c084",
					"created_datetime": "2022-03-17T22:33:12.250Z",
					"updated_datetime": "2022-03-17T22:33:12.250Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.5949733257293701",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "df5ab674-b9bf-4ad7-a895-973347cb17b3",
					"created_datetime": "2022-03-17T22:33:12.252Z",
					"updated_datetime": "2022-03-17T22:33:12.252Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-10.44722843170166",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "73c662e0-2d46-460d-9956-5d83bbd58c95",
					"created_datetime": "2022-03-17T22:33:12.255Z",
					"updated_datetime": "2022-03-17T22:33:12.255Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "5.30893087387085",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "3af808e8-2207-42b5-9eff-8048799f7695",
					"created_datetime": "2022-03-17T22:33:12.258Z",
					"updated_datetime": "2022-03-17T22:33:12.258Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.3380603790283203",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "d821e95c-9436-46ff-a468-a25a615efbe7",
					"created_datetime": "2022-03-17T22:33:12.260Z",
					"updated_datetime": "2022-03-17T22:33:12.260Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-4.719115734100342",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "a6d18888-cd54-40da-983a-646b42431371",
					"created_datetime": "2022-03-17T22:33:12.265Z",
					"updated_datetime": "2022-03-17T22:33:12.265Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_femur",
					"value_string": "0.3488090932369232",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "9c730077-1fd7-4bad-9971-a5e128fa51a3",
					"created_datetime": "2022-03-17T22:33:12.268Z",
					"updated_datetime": "2022-03-17T22:33:12.268Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-3.5702552795410156",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "44243f0f-a8e4-4250-9283-56883c2ee9d7",
					"created_datetime": "2022-03-17T22:33:12.270Z",
					"updated_datetime": "2022-03-17T22:33:12.270Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-3.8186123371124268",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "b890e816-209a-46db-a1d4-7f69bb56420d",
					"created_datetime": "2022-03-17T22:33:12.272Z",
					"updated_datetime": "2022-03-17T22:33:12.272Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_composite",
					"value_string": "0.520754873752594",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "8e5b3e78-6f75-46e0-8736-0bf4c2fd62f2",
					"created_datetime": "2022-03-17T22:33:12.275Z",
					"updated_datetime": "2022-03-17T22:33:12.275Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility",
					"value_string": "0.3997218608856201",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "ee2d2f97-4f3c-4768-b69d-96eafea8c735",
					"created_datetime": "2022-03-17T22:33:12.278Z",
					"updated_datetime": "2022-03-17T22:33:12.278Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability",
					"value_string": "0.5371278524398804",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "ebb1ec5b-79c8-462e-b4ca-7a74e7e02665",
					"created_datetime": "2022-03-17T22:33:12.280Z",
					"updated_datetime": "2022-03-17T22:33:12.280Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_posture",
					"value_string": "0.5011310577392578",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "7bee622f-f5aa-49ec-b078-7ff107d028d6",
					"created_datetime": "2022-03-17T22:33:12.283Z",
					"updated_datetime": "2022-03-17T22:33:12.283Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry",
					"value_string": "0.7734257578849792",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "bd781640-7fe6-4f1f-aeac-e2182fb3f0a1",
					"created_datetime": "2022-03-17T22:33:12.285Z",
					"updated_datetime": "2022-03-17T22:33:12.285Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.39701199531555176",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "8b3155d7-ae3a-4c83-9908-17f6f109a0e9",
					"created_datetime": "2022-03-17T22:33:12.287Z",
					"updated_datetime": "2022-03-17T22:33:12.287Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.7036781907081604",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "133b6b3c-ae00-4dac-bc02-9bd68f3c7449",
					"created_datetime": "2022-03-17T22:33:12.290Z",
					"updated_datetime": "2022-03-17T22:33:12.290Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.6544971466064453",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "23579e07-9dca-4722-a28d-cede095fbbc6",
					"created_datetime": "2022-03-17T22:33:12.292Z",
					"updated_datetime": "2022-03-17T22:33:12.292Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.41535353660583496",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "d2d9d088-609b-4b01-bf54-cab298e18a8e",
					"created_datetime": "2022-03-17T22:33:12.295Z",
					"updated_datetime": "2022-03-17T22:33:12.295Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.3310127854347229",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "1e988ec1-f0d9-4acf-b109-a92feefd2e71",
					"created_datetime": "2022-03-17T22:33:12.297Z",
					"updated_datetime": "2022-03-17T22:33:12.297Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.2707957625389099",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "26ee15f2-4619-43c0-a3a0-8d5b053dc9e3",
					"created_datetime": "2022-03-17T22:33:12.300Z",
					"updated_datetime": "2022-03-17T22:33:12.300Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.4902612864971161",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "114c4652-44d6-4d4c-9802-b49a9e9bf8ff",
					"created_datetime": "2022-03-17T22:33:12.302Z",
					"updated_datetime": "2022-03-17T22:33:12.302Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.5665907859802246",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "f05d856d-697e-4e5f-a7cf-782d99e77bc6",
					"created_datetime": "2022-03-17T22:33:12.305Z",
					"updated_datetime": "2022-03-17T22:33:12.305Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.2415667474269867",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "02d38a73-37a8-4b3d-8878-f8fd8118bdfe",
					"created_datetime": "2022-03-17T22:33:12.307Z",
					"updated_datetime": "2022-03-17T22:33:12.307Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.19736823439598083",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "fd6a9720-756a-461a-91fb-7a5409dce4bb",
					"created_datetime": "2022-03-17T22:33:12.309Z",
					"updated_datetime": "2022-03-17T22:33:12.309Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.18755170702934265",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "575f998b-08dc-4ec9-a554-619606b9e15f",
					"created_datetime": "2022-03-17T22:33:12.312Z",
					"updated_datetime": "2022-03-17T22:33:12.312Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.1128968894481659",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "446ab0df-7405-4c03-94bb-87d75a874146",
					"created_datetime": "2022-03-17T22:33:12.314Z",
					"updated_datetime": "2022-03-17T22:33:12.314Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability_scap_left",
					"value_string": "0.3869646191596985",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "b668ec4b-ae62-4b3b-9676-c40f81a63ee3",
					"created_datetime": "2022-03-17T22:33:12.317Z",
					"updated_datetime": "2022-03-17T22:33:12.317Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability_scap_right",
					"value_string": "0.5131816864013672",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "b486c597-e19f-48a1-aff1-2ab0d42071cd",
					"created_datetime": "2022-03-17T22:33:12.320Z",
					"updated_datetime": "2022-03-17T22:33:12.320Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.8903119564056396",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "82aaf715-7547-4dca-882a-ef16ebb8a007",
					"created_datetime": "2022-03-17T22:33:12.323Z",
					"updated_datetime": "2022-03-17T22:33:12.323Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.07275667786598206",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "0b4808d2-c056-461c-b5c3-ec007d70f500",
					"created_datetime": "2022-03-17T22:33:12.325Z",
					"updated_datetime": "2022-03-17T22:33:12.325Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.2050972580909729",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "3affbb08-e11a-4bd9-9935-5151c9b83f7c",
					"created_datetime": "2022-03-17T22:33:12.328Z",
					"updated_datetime": "2022-03-17T22:33:12.328Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.7470859289169312",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "3fa5cabf-70a7-4a9e-bf17-b3a12b82b821",
					"created_datetime": "2022-03-17T22:33:12.330Z",
					"updated_datetime": "2022-03-17T22:33:12.330Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.2551761865615845",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "384694c5-4bb9-4542-9aa6-fde1cf1677e6",
					"created_datetime": "2022-03-17T22:33:12.332Z",
					"updated_datetime": "2022-03-17T22:33:12.332Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.008933544158935547",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "7c6b968d-12ff-46e0-9633-33b042bdfe33",
					"created_datetime": "2022-03-17T22:33:12.335Z",
					"updated_datetime": "2022-03-17T22:33:12.335Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.4508270025253296",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "40746df9-07f6-483b-8c28-7cd2a61911d4",
					"created_datetime": "2022-03-17T22:33:12.337Z",
					"updated_datetime": "2022-03-17T22:33:12.337Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9845114350318909",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "6a470ee3-30ca-4032-bc6f-be6003974850",
					"created_datetime": "2022-03-17T22:33:12.340Z",
					"updated_datetime": "2022-03-17T22:33:12.340Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7540662288665771",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "f8838617-c7cf-4d36-8ef7-af929a32ce80",
					"created_datetime": "2022-03-17T22:33:12.342Z",
					"updated_datetime": "2022-03-17T22:33:12.342Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9954462051391602",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "00c0111c-1cb1-4f5e-9e8b-90e0d79aa987",
					"created_datetime": "2022-03-17T22:33:12.344Z",
					"updated_datetime": "2022-03-17T22:33:12.344Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_knee",
					"value_string": "0.8872241973876953",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "f134fd6e-d9d7-40dd-8a9f-8dd1e0628635",
					"created_datetime": "2022-03-17T22:33:12.347Z",
					"updated_datetime": "2022-03-17T22:33:12.347Z",
					"assessment_id_guid": "ee3d841a-7fe6-4e3a-9e8c-3df957de2273",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.7684791684150696",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
			"created_datetime": "2022-03-17T22:35:06.933Z",
			"updated_datetime": "2022-03-17T22:35:06.933Z",
			"submitter_user_id_guid": "c5297205-49f3-4534-987f-86d62d0b2fa0",
			"athlete_user_id_guid": "29d2df78-0bbe-4733-8aff-611a8dc6d748",
			"external_id_guid": "e64bfc10-dd5f-4ea4-a95c-240faee05c9c",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "cac810ac-7d91-474a-97b8-802d136b6d4a",
					"created_datetime": "2022-03-17T22:35:06.937Z",
					"updated_datetime": "2022-03-17T22:35:06.937Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "ios_datetime",
					"value_string": "2022-03-17T22:35:06.655Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "65d4b761-d113-46dd-9edc-7643618b73c4",
					"created_datetime": "2022-03-17T22:35:06.940Z",
					"updated_datetime": "2022-03-17T22:35:06.940Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "554ca01a-f8bc-4d02-bc71-287d8ca7ef1e",
					"created_datetime": "2022-03-17T22:35:06.944Z",
					"updated_datetime": "2022-03-17T22:35:06.944Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "152.11842346191406",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "74f5f685-c380-46da-9d24-0e1d9b0f32eb",
					"created_datetime": "2022-03-17T22:35:06.947Z",
					"updated_datetime": "2022-03-17T22:35:06.947Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "162.2895965576172",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "2fec96af-bd9c-4910-9d45-47f4d01e5b05",
					"created_datetime": "2022-03-17T22:35:06.949Z",
					"updated_datetime": "2022-03-17T22:35:06.949Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "164.45513916015625",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "03782551-58e0-4032-a51c-86a4d9a373a9",
					"created_datetime": "2022-03-17T22:35:06.952Z",
					"updated_datetime": "2022-03-17T22:35:06.952Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "153.4438934326172",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "c68a7c5b-ce98-4da4-bc2c-a12730d06312",
					"created_datetime": "2022-03-17T22:35:06.955Z",
					"updated_datetime": "2022-03-17T22:35:06.955Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "84.69647979736328",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "963af611-af68-495e-a6f3-b090c517d7a4",
					"created_datetime": "2022-03-17T22:35:06.957Z",
					"updated_datetime": "2022-03-17T22:35:06.957Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "85.666015625",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "0ffd3608-8b33-4b10-8372-6481d91c6f43",
					"created_datetime": "2022-03-17T22:35:06.960Z",
					"updated_datetime": "2022-03-17T22:35:06.960Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "55.10050582885742",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "4ccfdf9e-6914-4442-adee-343cda4b365b",
					"created_datetime": "2022-03-17T22:35:06.963Z",
					"updated_datetime": "2022-03-17T22:35:06.963Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "50.788177490234375",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "69e4335e-f735-493b-afd6-3d7fbbf66018",
					"created_datetime": "2022-03-17T22:35:06.965Z",
					"updated_datetime": "2022-03-17T22:35:06.965Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "104.2254867553711",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "46388d14-bd3d-4c49-858e-d37ce8f206ae",
					"created_datetime": "2022-03-17T22:35:06.968Z",
					"updated_datetime": "2022-03-17T22:35:06.968Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "98.60250854492188",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "4d4caf89-97d7-4ac6-b10b-2b4e33b27ac9",
					"created_datetime": "2022-03-17T22:35:06.970Z",
					"updated_datetime": "2022-03-17T22:35:06.970Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "55.741455078125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "91de89ae-9118-4ac6-a00d-6e2c717233fc",
					"created_datetime": "2022-03-17T22:35:06.973Z",
					"updated_datetime": "2022-03-17T22:35:06.973Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "57.03074264526367",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "a1cf65b1-1b7d-407a-b001-a0709f5bb001",
					"created_datetime": "2022-03-17T22:35:06.975Z",
					"updated_datetime": "2022-03-17T22:35:06.975Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_stability_scap_left",
					"value_string": "16.533971786499023",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "a1269217-d651-45a0-9f2c-4083be042210",
					"created_datetime": "2022-03-17T22:35:06.978Z",
					"updated_datetime": "2022-03-17T22:35:06.978Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_stability_scap_right",
					"value_string": "17.393463134765625",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "8e8c1056-2979-481e-837b-e4d1d92a709a",
					"created_datetime": "2022-03-17T22:35:06.982Z",
					"updated_datetime": "2022-03-17T22:35:06.982Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "32.48725509643555",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "63a5a4e4-0bc1-46af-bbd9-195ce7987e69",
					"created_datetime": "2022-03-17T22:35:06.984Z",
					"updated_datetime": "2022-03-17T22:35:06.984Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "12.001495361328125",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "09dd679f-88fb-40c6-a531-ecbfde61c1a5",
					"created_datetime": "2022-03-17T22:35:06.987Z",
					"updated_datetime": "2022-03-17T22:35:06.987Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "14.895846366882324",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "bd7f527f-7078-44d8-8eef-5ab68480df30",
					"created_datetime": "2022-03-17T22:35:06.990Z",
					"updated_datetime": "2022-03-17T22:35:06.990Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-2.361009120941162",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "786c3d9a-48d5-4e35-856d-013b89c3f90e",
					"created_datetime": "2022-03-17T22:35:06.992Z",
					"updated_datetime": "2022-03-17T22:35:06.992Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.31669530272483826",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "b814de15-c0aa-49ee-a7a6-089321163480",
					"created_datetime": "2022-03-17T22:35:06.995Z",
					"updated_datetime": "2022-03-17T22:35:06.995Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-10.258771896362305",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "57340964-4a90-4d40-9d25-29a60aec32f5",
					"created_datetime": "2022-03-17T22:35:06.998Z",
					"updated_datetime": "2022-03-17T22:35:06.998Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "12.660454750061035",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "0ff91fc4-c6c5-4308-915d-4a01d7328160",
					"created_datetime": "2022-03-17T22:35:07.000Z",
					"updated_datetime": "2022-03-17T22:35:07.000Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "2.8325576782226562",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "c2260d25-90f1-4cac-974a-cb794021d878",
					"created_datetime": "2022-03-17T22:35:07.003Z",
					"updated_datetime": "2022-03-17T22:35:07.003Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_hip",
					"value_string": "1.1588901281356812",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "a2e9cb35-089c-452d-8c3d-d0565d6d9ce7",
					"created_datetime": "2022-03-17T22:35:07.006Z",
					"updated_datetime": "2022-03-17T22:35:07.006Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-6.553040981292725",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "307fe8fa-cc65-4c35-87b6-cf565592930e",
					"created_datetime": "2022-03-17T22:35:07.008Z",
					"updated_datetime": "2022-03-17T22:35:07.008Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-6.853818893432617",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "bdb23ae2-52f4-4ddf-ba9a-ec46d4e1ca75",
					"created_datetime": "2022-03-17T22:35:07.011Z",
					"updated_datetime": "2022-03-17T22:35:07.011Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-0.16786448657512665",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "8cdee6be-ffa6-4433-8272-60d1adf8db17",
					"created_datetime": "2022-03-17T22:35:07.014Z",
					"updated_datetime": "2022-03-17T22:35:07.014Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_composite",
					"value_string": "0.4455142915248871",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "6e9b2f30-466a-4ed9-b532-39f83eff7166",
					"created_datetime": "2022-03-17T22:35:07.019Z",
					"updated_datetime": "2022-03-17T22:35:07.019Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility",
					"value_string": "0.3819274306297302",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "61ee9f56-4c60-4b56-b7da-57d56f5399be",
					"created_datetime": "2022-03-17T22:35:07.022Z",
					"updated_datetime": "2022-03-17T22:35:07.022Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability",
					"value_string": "0.5174129605293274",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "3346b23b-604b-411b-822a-4e33d07d2de3",
					"created_datetime": "2022-03-17T22:35:07.028Z",
					"updated_datetime": "2022-03-17T22:35:07.028Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_posture",
					"value_string": "0.5980212092399597",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "c5ab500b-5d09-4f37-81d3-0186f3a530b7",
					"created_datetime": "2022-03-17T22:35:07.030Z",
					"updated_datetime": "2022-03-17T22:35:07.030Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry",
					"value_string": "0.4426100254058838",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "e47cd404-a687-4640-8c92-5b8ee5a36388",
					"created_datetime": "2022-03-17T22:35:07.035Z",
					"updated_datetime": "2022-03-17T22:35:07.035Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.4238298535346985",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "489e206f-929f-4a8f-af2a-fecae1bffd5e",
					"created_datetime": "2022-03-17T22:35:07.037Z",
					"updated_datetime": "2022-03-17T22:35:07.037Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6865068674087524",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "08486ec0-b6f9-4348-b23f-a9bb83493aef",
					"created_datetime": "2022-03-17T22:35:07.040Z",
					"updated_datetime": "2022-03-17T22:35:07.040Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.7357648015022278",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "5bb79c6b-184c-4ee1-af9a-b623bc7152f4",
					"created_datetime": "2022-03-17T22:35:07.043Z",
					"updated_datetime": "2022-03-17T22:35:07.043Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.45868757367134094",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "1b2c302b-54c2-4d89-b201-a5a6d02bc068",
					"created_datetime": "2022-03-17T22:35:07.045Z",
					"updated_datetime": "2022-03-17T22:35:07.045Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.17563188076019287",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "ed9d4abb-683c-4810-838f-9c69b987d5b9",
					"created_datetime": "2022-03-17T22:35:07.048Z",
					"updated_datetime": "2022-03-17T22:35:07.048Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.18583238124847412",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "18d7b64b-0dad-499e-ac75-0f4eac10b28a",
					"created_datetime": "2022-03-17T22:35:07.050Z",
					"updated_datetime": "2022-03-17T22:35:07.050Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.18000507354736328",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "8a213dc1-63fb-4818-83a2-ae9995101d87",
					"created_datetime": "2022-03-17T22:35:07.053Z",
					"updated_datetime": "2022-03-17T22:35:07.053Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.10618725419044495",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "308dd6d2-ec52-47ed-98c1-3e7c8b6e9aeb",
					"created_datetime": "2022-03-17T22:35:07.056Z",
					"updated_datetime": "2022-03-17T22:35:07.056Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.295038104057312",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "025764cb-f7f9-470b-858b-1658e102e27e",
					"created_datetime": "2022-03-17T22:35:07.058Z",
					"updated_datetime": "2022-03-17T22:35:07.058Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.20614370703697205",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "4629d7c8-f554-4e6b-bfbc-4e57907bf636",
					"created_datetime": "2022-03-17T22:35:07.061Z",
					"updated_datetime": "2022-03-17T22:35:07.061Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.6838365793228149",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "1e3fc16f-b28c-420c-b883-15e19b10f4b5",
					"created_datetime": "2022-03-17T22:35:07.063Z",
					"updated_datetime": "2022-03-17T22:35:07.063Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.7210270166397095",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "0ed17a0b-3e6a-4b17-9f64-976c4f2c5beb",
					"created_datetime": "2022-03-17T22:35:07.066Z",
					"updated_datetime": "2022-03-17T22:35:07.066Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability_scap_left",
					"value_string": "0.613646388053894",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "877dfb39-0776-4ae6-af33-c69a9e68a17d",
					"created_datetime": "2022-03-17T22:35:07.069Z",
					"updated_datetime": "2022-03-17T22:35:07.069Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability_scap_right",
					"value_string": "0.5859781503677368",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "d3aba169-15b5-4de7-b921-c2da0a3afa34",
					"created_datetime": "2022-03-17T22:35:07.071Z",
					"updated_datetime": "2022-03-17T22:35:07.071Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.7737563252449036",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "16d0a14b-4554-4c77-900c-1896aa704b96",
					"created_datetime": "2022-03-17T22:35:07.074Z",
					"updated_datetime": "2022-03-17T22:35:07.074Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.09682619571685791",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "89016e56-36b8-4c10-ba2e-85ef79179b58",
					"created_datetime": "2022-03-17T22:35:07.077Z",
					"updated_datetime": "2022-03-17T22:35:07.077Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.15614816546440125",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "d6916b7d-e61c-442d-ae79-e6491fe80046",
					"created_datetime": "2022-03-17T22:35:07.080Z",
					"updated_datetime": "2022-03-17T22:35:07.080Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.2665293216705322",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "f30757ea-a41e-4aae-b834-dba6cc99aa62",
					"created_datetime": "2022-03-17T22:35:07.082Z",
					"updated_datetime": "2022-03-17T22:35:07.082Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.9295130968093872",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "4b80d601-5426-4bbe-8180-ab50f2f89746",
					"created_datetime": "2022-03-17T22:35:07.085Z",
					"updated_datetime": "2022-03-17T22:35:07.085Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.011114716529846191",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "84a0e19d-2aa9-4dfb-b1b9-4f56f676b9db",
					"created_datetime": "2022-03-17T22:35:07.087Z",
					"updated_datetime": "2022-03-17T22:35:07.087Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.0010913610458374023",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "274fb2ae-1005-4c99-b3b7-f2b9bace6422",
					"created_datetime": "2022-03-17T22:35:07.090Z",
					"updated_datetime": "2022-03-17T22:35:07.090Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9341694116592407",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "741451b9-8924-4fd8-a7a4-a9863aae9326",
					"created_datetime": "2022-03-17T22:35:07.093Z",
					"updated_datetime": "2022-03-17T22:35:07.093Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9873000383377075",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "17605637-661b-4c68-8fb0-1f790fffa9a9",
					"created_datetime": "2022-03-17T22:35:07.095Z",
					"updated_datetime": "2022-03-17T22:35:07.095Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_femur",
					"value_string": "0.03410613536834717",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "65fab70f-5d81-4547-b7bd-2d450e690828",
					"created_datetime": "2022-03-17T22:35:07.098Z",
					"updated_datetime": "2022-03-17T22:35:07.098Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_knee",
					"value_string": "0.4595656991004944",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "b719bfab-1068-4071-81ad-3ad73b03b914",
					"created_datetime": "2022-03-17T22:35:07.100Z",
					"updated_datetime": "2022-03-17T22:35:07.100Z",
					"assessment_id_guid": "f5f99041-fa9a-4d42-8c66-e754a65705ea",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9815096855163574",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
			"created_datetime": "2022-03-18T00:40:48.844Z",
			"updated_datetime": "2022-03-18T00:40:48.844Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "26993e73-ecb9-478a-b610-56a62688556b",
			"external_id_guid": "aa7e68a6-3995-4888-8aa1-daaa86eaf95f",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "b8d24a3d-0a13-4134-8a71-8cda9e277220",
					"created_datetime": "2022-03-18T00:40:48.848Z",
					"updated_datetime": "2022-03-18T00:40:48.848Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T00:43:37.620Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "b0e62a56-8270-4887-86a9-a98bd1cb2d0f",
					"created_datetime": "2022-03-18T00:40:48.851Z",
					"updated_datetime": "2022-03-18T00:40:48.851Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "b0a8a493-6354-42d8-808b-32c1a72af8c1",
					"created_datetime": "2022-03-18T00:40:48.854Z",
					"updated_datetime": "2022-03-18T00:40:48.854Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "109.83521966374741",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "bbf46856-880d-4fb6-aa5d-56534f35814b",
					"created_datetime": "2022-03-18T00:40:48.857Z",
					"updated_datetime": "2022-03-18T00:40:48.857Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "61.768181778721726",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "d96a9137-18f8-4324-90d0-43ceec7db682",
					"created_datetime": "2022-03-18T00:40:48.860Z",
					"updated_datetime": "2022-03-18T00:40:48.860Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "101.79559260858002",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "773193e8-ef66-47aa-993a-0a001c6df390",
					"created_datetime": "2022-03-18T00:40:48.863Z",
					"updated_datetime": "2022-03-18T00:40:48.863Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-13.517583729355948",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "4330b8e2-1c74-4a50-b4da-fd78f243d110",
					"created_datetime": "2022-03-18T00:40:48.866Z",
					"updated_datetime": "2022-03-18T00:40:48.866Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "170.2258914688867",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "5f94c9e0-1669-4f80-ac69-732a5e345afb",
					"created_datetime": "2022-03-18T00:40:48.869Z",
					"updated_datetime": "2022-03-18T00:40:48.869Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "58.27951867403027",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "389d3101-aa9e-427d-aaac-1cf5bdcb9027",
					"created_datetime": "2022-03-18T00:40:48.872Z",
					"updated_datetime": "2022-03-18T00:40:48.872Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "105.4181097602812",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "33193e91-0de0-49a8-b63e-1a1b0f8e2912",
					"created_datetime": "2022-03-18T00:40:48.875Z",
					"updated_datetime": "2022-03-18T00:40:48.875Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "57.07732347772094",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "f3218f30-efed-4ef3-afc3-2aa7e951c5fb",
					"created_datetime": "2022-03-18T00:40:48.878Z",
					"updated_datetime": "2022-03-18T00:40:48.878Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-30.56663852332906",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "b47eb2d3-5fa4-4e4b-886e-1ff2a349545b",
					"created_datetime": "2022-03-18T00:40:48.881Z",
					"updated_datetime": "2022-03-18T00:40:48.881Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-105.48349479330524",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "b370f2c6-ee10-44b5-a90f-20689d995abe",
					"created_datetime": "2022-03-18T00:40:48.885Z",
					"updated_datetime": "2022-03-18T00:40:48.885Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "49.811738032181125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "8ebcd84a-366b-438c-9856-4d0ac6fbb62c",
					"created_datetime": "2022-03-18T00:40:48.888Z",
					"updated_datetime": "2022-03-18T00:40:48.888Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "21.859614169111723",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "a8672ee0-3fdd-4c70-b00e-4b6b897537ce",
					"created_datetime": "2022-03-18T00:40:48.890Z",
					"updated_datetime": "2022-03-18T00:40:48.890Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-126.73222742944523",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "32e4598f-a7f6-4d43-9d72-9b448eb73e51",
					"created_datetime": "2022-03-18T00:40:48.893Z",
					"updated_datetime": "2022-03-18T00:40:48.893Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_stability_scap_right",
					"value_string": "112.5185139687137",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "c1910d1a-4152-4cc7-900d-610529e5a974",
					"created_datetime": "2022-03-18T00:40:48.896Z",
					"updated_datetime": "2022-03-18T00:40:48.896Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-139.13513103292127",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "700172ed-c3fe-4b04-bd50-a2b27bbc919e",
					"created_datetime": "2022-03-18T00:40:48.898Z",
					"updated_datetime": "2022-03-18T00:40:48.898Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-153.02596874277933",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "f202e2cc-87c4-4f39-b83d-70959923b124",
					"created_datetime": "2022-03-18T00:40:48.900Z",
					"updated_datetime": "2022-03-18T00:40:48.900Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "97.10649320745625",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "bc1820cc-d07b-40c5-9691-08d00e93a707",
					"created_datetime": "2022-03-18T00:40:48.903Z",
					"updated_datetime": "2022-03-18T00:40:48.903Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "177.01089420599754",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "78b48e6c-c293-4e03-8561-ce1b28f994eb",
					"created_datetime": "2022-03-18T00:40:48.906Z",
					"updated_datetime": "2022-03-18T00:40:48.906Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "6.187593844611058",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "dbfc71a6-b7b5-4ccc-b5b2-279f310f5922",
					"created_datetime": "2022-03-18T00:40:48.908Z",
					"updated_datetime": "2022-03-18T00:40:48.908Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "45.49555546827003",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "b374b0a5-9503-489b-8b48-cfba2df898f5",
					"created_datetime": "2022-03-18T00:40:48.911Z",
					"updated_datetime": "2022-03-18T00:40:48.911Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-102.7172601129868",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "65ba13b7-3012-4565-ae80-915cdf6d68f1",
					"created_datetime": "2022-03-18T00:40:48.914Z",
					"updated_datetime": "2022-03-18T00:40:48.914Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-151.99675193028366",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "49aa5833-6470-483d-9b81-9391c02f6a8f",
					"created_datetime": "2022-03-18T00:40:48.916Z",
					"updated_datetime": "2022-03-18T00:40:48.916Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_hip",
					"value_string": "4.869704694923229",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "4d9ccc07-a106-4702-b5a3-a8f18f8af57c",
					"created_datetime": "2022-03-18T00:40:48.919Z",
					"updated_datetime": "2022-03-18T00:40:48.919Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_femur",
					"value_string": "70.54033692438188",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ff744fd8-ce63-447a-8ca9-a2b8940de12e",
					"created_datetime": "2022-03-18T00:40:48.922Z",
					"updated_datetime": "2022-03-18T00:40:48.922Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-179.04111738611863",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "98991a9f-6c94-4d97-a996-7a70e07fb828",
					"created_datetime": "2022-03-18T00:40:48.925Z",
					"updated_datetime": "2022-03-18T00:40:48.925Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-179.10719045042165",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "dbff09aa-c20e-44fb-b6d1-b9c161cbc322",
					"created_datetime": "2022-03-18T00:40:48.928Z",
					"updated_datetime": "2022-03-18T00:40:48.928Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_composite",
					"value_string": "0.04319931746714977",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "8c8cee53-559c-44ef-84c3-f08934fd8244",
					"created_datetime": "2022-03-18T00:40:48.931Z",
					"updated_datetime": "2022-03-18T00:40:48.931Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility",
					"value_string": "0.6713693269137405",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "17b78cde-6541-4a39-b7a3-313541ebc781",
					"created_datetime": "2022-03-18T00:40:48.934Z",
					"updated_datetime": "2022-03-18T00:40:48.934Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability",
					"value_string": "0.3048288295966578",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "9ff1dc31-5ab0-4a74-847b-2a4065866325",
					"created_datetime": "2022-03-18T00:40:48.937Z",
					"updated_datetime": "2022-03-18T00:40:48.937Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_posture",
					"value_string": "0.4511166565090295",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "09bb68a0-11fe-4d31-ad61-5d284ece277f",
					"created_datetime": "2022-03-18T00:40:48.939Z",
					"updated_datetime": "2022-03-18T00:40:48.939Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry",
					"value_string": "0.27180060791413835",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "cafdb792-8c4f-4c67-94f0-e32d50735b75",
					"created_datetime": "2022-03-18T00:40:48.942Z",
					"updated_datetime": "2022-03-18T00:40:48.942Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.29592218614281096",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "28e035e3-bcc4-4aab-83b3-e4b5f0ce7961",
					"created_datetime": "2022-03-18T00:40:48.945Z",
					"updated_datetime": "2022-03-18T00:40:48.945Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.04655668241610224",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "55b4ecc7-efab-4aa4-a9f9-c6da582debee",
					"created_datetime": "2022-03-18T00:40:48.948Z",
					"updated_datetime": "2022-03-18T00:40:48.948Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.21716141088353555",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "fc4b06cf-d752-4757-879a-8239a3b14c05",
					"created_datetime": "2022-03-18T00:40:48.951Z",
					"updated_datetime": "2022-03-18T00:40:48.951Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.8872291008640628",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "8c53300d-3122-4657-8213-942aaed7d195",
					"created_datetime": "2022-03-18T00:40:48.953Z",
					"updated_datetime": "2022-03-18T00:40:48.953Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.8078111953909876",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "bb4a107a-b55f-490c-b65e-7b48ed3ccab4",
					"created_datetime": "2022-03-18T00:40:48.956Z",
					"updated_datetime": "2022-03-18T00:40:48.956Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.8532393588604048",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "0b296ebf-a223-4f79-811d-dd7e9d4db2aa",
					"created_datetime": "2022-03-18T00:40:48.959Z",
					"updated_datetime": "2022-03-18T00:40:48.959Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.8944869365110026",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "dbe4e654-2516-4a6f-9e71-4ae9c57f0784",
					"created_datetime": "2022-03-18T00:40:48.962Z",
					"updated_datetime": "2022-03-18T00:40:48.962Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.10300630103036551",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "4a788958-48ad-48bc-aad7-0204b1c05289",
					"created_datetime": "2022-03-18T00:40:48.965Z",
					"updated_datetime": "2022-03-18T00:40:48.965Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.5652333868446635",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "62d5f4af-a139-496b-a616-ef8177538909",
					"created_datetime": "2022-03-18T00:40:48.972Z",
					"updated_datetime": "2022-03-18T00:40:48.972Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.5245981633615413",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "ee259636-8db0-438a-9d2e-a8e825cc8d54",
					"created_datetime": "2022-03-18T00:40:48.975Z",
					"updated_datetime": "2022-03-18T00:40:48.975Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.45949122902900863",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "1e9b30b3-3c9f-404a-8724-66012d95a6c5",
					"created_datetime": "2022-03-18T00:40:48.979Z",
					"updated_datetime": "2022-03-18T00:40:48.979Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.4725572517876193",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "c0c21e16-b1b4-4af9-ba97-8a131789a82a",
					"created_datetime": "2022-03-18T00:40:48.983Z",
					"updated_datetime": "2022-03-18T00:40:48.983Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9700983150476984",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "1af19292-171c-4934-86ba-60939b6a340d",
					"created_datetime": "2022-03-18T00:40:48.986Z",
					"updated_datetime": "2022-03-18T00:40:48.986Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability_scap_right",
					"value_string": "0.4335812061497991",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "7b204001-6e31-4d5c-af90-510511238bf2",
					"created_datetime": "2022-03-18T00:40:48.989Z",
					"updated_datetime": "2022-03-18T00:40:48.989Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.7567378198773101",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "24792810-649d-4b61-b81c-4d209cf02620",
					"created_datetime": "2022-03-18T00:40:48.992Z",
					"updated_datetime": "2022-03-18T00:40:48.992Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.3398076775803296",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "5cb6dbf6-fe19-4e1a-9977-71ea73293056",
					"created_datetime": "2022-03-18T00:40:48.994Z",
					"updated_datetime": "2022-03-18T00:40:48.994Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.45514527838564073",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "58e9cc0c-5f96-4e9a-811a-dc0bb0687df3",
					"created_datetime": "2022-03-18T00:40:48.997Z",
					"updated_datetime": "2022-03-18T00:40:48.997Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.7799222773583944",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "bd903ca9-8da8-4ab4-9933-f98e0a1838f4",
					"created_datetime": "2022-03-18T00:40:49.000Z",
					"updated_datetime": "2022-03-18T00:40:49.000Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.036413154281042324",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "dc2e3700-c12e-4e8f-982d-6263348c39e4",
					"created_datetime": "2022-03-18T00:40:49.003Z",
					"updated_datetime": "2022-03-18T00:40:49.003Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.4638343617939442",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "8baaf165-ad8d-402a-8ce6-2cdfdd095e12",
					"created_datetime": "2022-03-18T00:40:49.014Z",
					"updated_datetime": "2022-03-18T00:40:49.014Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.5193638178338833",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "0309f39d-0213-4c81-a144-9a28f5191c67",
					"created_datetime": "2022-03-18T00:40:49.017Z",
					"updated_datetime": "2022-03-18T00:40:49.017Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.6974034941457151",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "386caf90-f84e-4129-8c06-94fa2492f828",
					"created_datetime": "2022-03-18T00:40:49.020Z",
					"updated_datetime": "2022-03-18T00:40:49.020Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_hip",
					"value_string": "0.854519370189994",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "f253b445-fee0-4bc3-b008-04b511236dab",
					"created_datetime": "2022-03-18T00:40:49.023Z",
					"updated_datetime": "2022-03-18T00:40:49.023Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7313649404382184",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "006e51d4-abb8-4310-a4d2-5d95c54a6106",
					"created_datetime": "2022-03-18T00:40:49.026Z",
					"updated_datetime": "2022-03-18T00:40:49.026Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_knee",
					"value_string": "0.5627197539170311",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "e415c97a-9823-449e-baa5-e78c6a5c7b3d",
					"created_datetime": "2022-03-18T00:40:49.029Z",
					"updated_datetime": "2022-03-18T00:40:49.029Z",
					"assessment_id_guid": "72afaac8-3f1a-4902-a2ba-4682da6ed957",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.5431832630060165",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
			"created_datetime": "2022-03-18T01:52:46.298Z",
			"updated_datetime": "2022-03-18T01:52:46.298Z",
			"submitter_user_id_guid": "1832891c-39cb-43ce-9405-3be81fe54408",
			"athlete_user_id_guid": "c438e910-b4ef-4b74-9ab0-51476f177b8a",
			"external_id_guid": "65a2e27a-4b19-4b3e-a6d9-5e74f85a6274",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "49d91bb6-dab1-4fe5-b0a3-1de57930cc9e",
					"created_datetime": "2022-03-18T01:52:46.402Z",
					"updated_datetime": "2022-03-18T01:52:46.402Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T01:52:46.195Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "757533b2-8d6b-44b7-aa4a-25370b14330c",
					"created_datetime": "2022-03-18T01:52:46.481Z",
					"updated_datetime": "2022-03-18T01:52:46.481Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "9559e1bb-d4f0-47b1-919b-a88d35070b6e",
					"created_datetime": "2022-03-18T01:52:46.484Z",
					"updated_datetime": "2022-03-18T01:52:46.484Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "177.6370086669922",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "8db0f883-6913-4bfb-a247-9d22bd7c561b",
					"created_datetime": "2022-03-18T01:52:46.487Z",
					"updated_datetime": "2022-03-18T01:52:46.487Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "174.3346710205078",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "9ab3d4af-9ecb-4126-9294-e01335aa2113",
					"created_datetime": "2022-03-18T01:52:46.489Z",
					"updated_datetime": "2022-03-18T01:52:46.489Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "165.31463623046875",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "6f0e31c9-98bb-476a-8242-0ab64fa13c03",
					"created_datetime": "2022-03-18T01:52:46.492Z",
					"updated_datetime": "2022-03-18T01:52:46.492Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "165.13076782226562",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "654b2bd8-45cf-4ce2-b58d-63ff79e9225e",
					"created_datetime": "2022-03-18T01:52:46.495Z",
					"updated_datetime": "2022-03-18T01:52:46.495Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "115.6921157836914",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "b8ab75dd-af07-4a75-ba7d-11830f368b5e",
					"created_datetime": "2022-03-18T01:52:46.497Z",
					"updated_datetime": "2022-03-18T01:52:46.497Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "120.66399383544922",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "f7fe0ef3-76ac-49dc-b9b9-882f60fb582b",
					"created_datetime": "2022-03-18T01:52:46.516Z",
					"updated_datetime": "2022-03-18T01:52:46.516Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "73.8367691040039",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "b16379ae-f5a8-46e7-9754-a80d0b0944f4",
					"created_datetime": "2022-03-18T01:52:46.518Z",
					"updated_datetime": "2022-03-18T01:52:46.518Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "77.61981964111328",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "1553c3c8-a587-41da-8431-dcc8a4bbeb0c",
					"created_datetime": "2022-03-18T01:52:46.521Z",
					"updated_datetime": "2022-03-18T01:52:46.521Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "104.49715423583984",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "7fe9dfd7-5b7f-4aa6-b210-8016e7479d6c",
					"created_datetime": "2022-03-18T01:52:46.524Z",
					"updated_datetime": "2022-03-18T01:52:46.524Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "105.10165405273438",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "f41f3e73-0bf2-4df0-a3ca-4cb82d3c7777",
					"created_datetime": "2022-03-18T01:52:46.527Z",
					"updated_datetime": "2022-03-18T01:52:46.527Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "43.718048095703125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "2efa1c33-58cc-483c-9825-24b5a6e89104",
					"created_datetime": "2022-03-18T01:52:46.530Z",
					"updated_datetime": "2022-03-18T01:52:46.530Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "38.57267761230469",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "31dee11c-8313-4064-a2c6-f16262f3a93e",
					"created_datetime": "2022-03-18T01:52:46.533Z",
					"updated_datetime": "2022-03-18T01:52:46.533Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_stability_scap_left",
					"value_string": "29.919092178344727",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "0e9d20cf-577f-4107-ae81-1b974035c4b6",
					"created_datetime": "2022-03-18T01:52:46.536Z",
					"updated_datetime": "2022-03-18T01:52:46.536Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_stability_scap_right",
					"value_string": "23.045486450195312",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "772e6148-62c7-42f8-8236-bfccfd61bfb8",
					"created_datetime": "2022-03-18T01:52:46.539Z",
					"updated_datetime": "2022-03-18T01:52:46.539Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "43.32268142700195",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "fb477dd4-924e-4f85-87da-86b070738203",
					"created_datetime": "2022-03-18T01:52:46.542Z",
					"updated_datetime": "2022-03-18T01:52:46.542Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "7.881465911865234",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "c4508fe8-056a-4869-af66-9316b5a250c7",
					"created_datetime": "2022-03-18T01:52:46.545Z",
					"updated_datetime": "2022-03-18T01:52:46.545Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "7.508950710296631",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "b6873aa5-271c-42e1-a60b-ac2dd61bc7e4",
					"created_datetime": "2022-03-18T01:52:46.548Z",
					"updated_datetime": "2022-03-18T01:52:46.548Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "2.09591007232666",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "d2e77b40-1cd7-419f-9629-17d79180e832",
					"created_datetime": "2022-03-18T01:52:46.552Z",
					"updated_datetime": "2022-03-18T01:52:46.552Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.7328212261199951",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "d3ebc052-b460-4219-8ff0-59ccd070ff21",
					"created_datetime": "2022-03-18T01:52:46.555Z",
					"updated_datetime": "2022-03-18T01:52:46.555Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "5.270144462585449",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "be7d9b18-70a2-448d-88b5-bc21cab1d9aa",
					"created_datetime": "2022-03-18T01:52:46.558Z",
					"updated_datetime": "2022-03-18T01:52:46.558Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-11.239880561828613",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "fc81b3f2-e295-4cf1-b92f-c685ce804150",
					"created_datetime": "2022-03-18T01:52:46.561Z",
					"updated_datetime": "2022-03-18T01:52:46.561Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.6529650092124939",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "a2f9a5c4-1c40-49cb-89f3-c0710ed7faa1",
					"created_datetime": "2022-03-18T01:52:46.564Z",
					"updated_datetime": "2022-03-18T01:52:46.564Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_hip",
					"value_string": "5.978216648101807",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "ac7d15b0-3fc1-4ba1-81e1-cf6af1440134",
					"created_datetime": "2022-03-18T01:52:46.567Z",
					"updated_datetime": "2022-03-18T01:52:46.567Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_femur",
					"value_string": "3.2262916564941406",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "b4a7b3fa-b91d-462c-9417-8deb1ed1c1bb",
					"created_datetime": "2022-03-18T01:52:46.570Z",
					"updated_datetime": "2022-03-18T01:52:46.570Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_knee",
					"value_string": "1.058956265449524",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "9cc6831a-a599-4b5b-9b99-f7a894398781",
					"created_datetime": "2022-03-18T01:52:46.573Z",
					"updated_datetime": "2022-03-18T01:52:46.573Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-2.1585447788238525",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "8a1d68b8-ba7a-42bb-b4f2-1b8cd121016b",
					"created_datetime": "2022-03-18T01:52:46.576Z",
					"updated_datetime": "2022-03-18T01:52:46.576Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_composite",
					"value_string": "0.4939950704574585",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "d91f7d1e-a501-4ba9-9430-a6e160004235",
					"created_datetime": "2022-03-18T01:52:46.582Z",
					"updated_datetime": "2022-03-18T01:52:46.582Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility",
					"value_string": "0.5909061431884766",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "0937da4d-7443-4140-a9b2-6493d7ef0422",
					"created_datetime": "2022-03-18T01:52:46.586Z",
					"updated_datetime": "2022-03-18T01:52:46.586Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability",
					"value_string": "0.24467256665229797",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "99622dce-b361-41ea-aaa8-adc19aede9b3",
					"created_datetime": "2022-03-18T01:52:46.589Z",
					"updated_datetime": "2022-03-18T01:52:46.589Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_posture",
					"value_string": "0.27920931577682495",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "c3817a7e-fb98-4a47-8760-035f30fd5503",
					"created_datetime": "2022-03-18T01:52:46.592Z",
					"updated_datetime": "2022-03-18T01:52:46.592Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry",
					"value_string": "0.7036253809928894",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "11033be2-1581-41e8-8b3c-d4b7f6018a20",
					"created_datetime": "2022-03-18T01:52:46.595Z",
					"updated_datetime": "2022-03-18T01:52:46.595Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9343676567077637",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "c593740f-d39e-4671-b0a2-7c139c2bf100",
					"created_datetime": "2022-03-18T01:52:46.598Z",
					"updated_datetime": "2022-03-18T01:52:46.598Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.9012970924377441",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "856c21fc-2ccc-423c-80bf-99747266c7d3",
					"created_datetime": "2022-03-18T01:52:46.601Z",
					"updated_datetime": "2022-03-18T01:52:46.601Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.75416100025177",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "ede0c5a2-d3de-4cfb-8887-066ebb636cb2",
					"created_datetime": "2022-03-18T01:52:46.606Z",
					"updated_datetime": "2022-03-18T01:52:46.606Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.7502843737602234",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "0794ba0f-aee4-4fd7-a2cb-d25622d8843f",
					"created_datetime": "2022-03-18T01:52:46.610Z",
					"updated_datetime": "2022-03-18T01:52:46.610Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.6208387017250061",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "e0958902-12f6-41a4-867c-65ad0e9debbb",
					"created_datetime": "2022-03-18T01:52:46.613Z",
					"updated_datetime": "2022-03-18T01:52:46.613Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.6937680244445801",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "69b2245a-b495-472b-898a-60a6f4d3608d",
					"created_datetime": "2022-03-18T01:52:46.616Z",
					"updated_datetime": "2022-03-18T01:52:46.616Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.7005229592323303",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "7ce1f0e6-2c58-4f8f-9703-f2f1a1db069a",
					"created_datetime": "2022-03-18T01:52:46.619Z",
					"updated_datetime": "2022-03-18T01:52:46.619Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.7930099368095398",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "822090f2-68f1-4d5c-bf69-be34d032d45a",
					"created_datetime": "2022-03-18T01:52:46.622Z",
					"updated_datetime": "2022-03-18T01:52:46.622Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.29974207282066345",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "202cf38e-9b29-46c8-9599-20363137f270",
					"created_datetime": "2022-03-18T01:52:46.625Z",
					"updated_datetime": "2022-03-18T01:52:46.625Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.31032925844192505",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "3be07a77-a012-4331-8570-34a68e17fa47",
					"created_datetime": "2022-03-18T01:52:46.628Z",
					"updated_datetime": "2022-03-18T01:52:46.628Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.3003145754337311",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "55e109fc-8b01-4c76-b271-d39cb58e1c75",
					"created_datetime": "2022-03-18T01:52:46.631Z",
					"updated_datetime": "2022-03-18T01:52:46.631Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.17047825455665588",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "09102381-f02b-4fad-8761-09ed3a3a1ae6",
					"created_datetime": "2022-03-18T01:52:46.633Z",
					"updated_datetime": "2022-03-18T01:52:46.633Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability_scap_left",
					"value_string": "0.20423436164855957",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "1a7bec9d-9295-4ce6-ba1f-2d177751ddaf",
					"created_datetime": "2022-03-18T01:52:46.636Z",
					"updated_datetime": "2022-03-18T01:52:46.636Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability_scap_right",
					"value_string": "0.3998286724090576",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "508f1df9-2a36-4d51-9714-e3ae6b2605e7",
					"created_datetime": "2022-03-18T01:52:46.639Z",
					"updated_datetime": "2022-03-18T01:52:46.639Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.3698434829711914",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "9c1fea68-4c22-426b-8db8-579cece8b62e",
					"created_datetime": "2022-03-18T01:52:46.642Z",
					"updated_datetime": "2022-03-18T01:52:46.642Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.04346179962158203",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "97e951b2-4563-4206-b5e9-9b22544ae7c7",
					"created_datetime": "2022-03-18T01:52:46.645Z",
					"updated_datetime": "2022-03-18T01:52:46.645Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.0401364266872406",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "9e8d9e7b-89a4-42de-85ea-a42c01155afe",
					"created_datetime": "2022-03-18T01:52:46.648Z",
					"updated_datetime": "2022-03-18T01:52:46.648Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.37115776538848877",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "6aa68999-f18e-4140-874f-d29c228038d2",
					"created_datetime": "2022-03-18T01:52:46.651Z",
					"updated_datetime": "2022-03-18T01:52:46.651Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.18726086616516113",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "00ea910c-0a9d-444e-a926-66080ec19562",
					"created_datetime": "2022-03-18T01:52:46.654Z",
					"updated_datetime": "2022-03-18T01:52:46.654Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.45325011014938354",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "911606ff-6936-4e08-8099-aa2b13daf90a",
					"created_datetime": "2022-03-18T01:52:46.657Z",
					"updated_datetime": "2022-03-18T01:52:46.657Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.006281018257141113",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "ebd195bb-71cf-47e9-836c-69e5cdb61f52",
					"created_datetime": "2022-03-18T01:52:46.660Z",
					"updated_datetime": "2022-03-18T01:52:46.660Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9929603338241577",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "a7a2a65d-526d-4e4f-aa1e-a1529269d856",
					"created_datetime": "2022-03-18T01:52:46.663Z",
					"updated_datetime": "2022-03-18T01:52:46.663Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_hip",
					"value_string": "0.5556329488754272",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "d88e7476-d2f1-458f-a4fb-8551389ffb77",
					"created_datetime": "2022-03-18T01:52:46.666Z",
					"updated_datetime": "2022-03-18T01:52:46.666Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7097480297088623",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "7ec63a9e-d118-496b-8301-1225aac71889",
					"created_datetime": "2022-03-18T01:52:46.669Z",
					"updated_datetime": "2022-03-18T01:52:46.669Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_knee",
					"value_string": "0.986668586730957",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "48c5763a-210f-499a-9d1d-327f33582cba",
					"created_datetime": "2022-03-18T01:52:46.672Z",
					"updated_datetime": "2022-03-18T01:52:46.672Z",
					"assessment_id_guid": "bc2fd746-78b5-434f-8628-52edc39f3862",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.911281168460846",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
			"created_datetime": "2022-03-18T01:52:48.834Z",
			"updated_datetime": "2022-03-18T01:52:48.834Z",
			"submitter_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"athlete_user_id_guid": "46e50e0b-edaf-4419-9263-239eb068843b",
			"external_id_guid": "f3945176-af81-49a0-9e65-23469278c790",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "e48cf090-76ab-4d7d-acfc-5d2da1589453",
					"created_datetime": "2022-03-18T01:52:48.838Z",
					"updated_datetime": "2022-03-18T01:52:48.838Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T01:52:48.693Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "496d8403-14cb-4d25-af56-875a28798ad9",
					"created_datetime": "2022-03-18T01:52:48.842Z",
					"updated_datetime": "2022-03-18T01:52:48.842Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "dfcf683e-3b8f-4d69-bb0e-bd188df49d0d",
					"created_datetime": "2022-03-18T01:52:48.847Z",
					"updated_datetime": "2022-03-18T01:52:48.847Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "166.76954650878906",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "209268ee-683c-4394-a7f6-1b7eb6a1d146",
					"created_datetime": "2022-03-18T01:52:48.850Z",
					"updated_datetime": "2022-03-18T01:52:48.850Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "149.7327117919922",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "15e95e09-3389-4731-873c-d609d05a61d0",
					"created_datetime": "2022-03-18T01:52:48.853Z",
					"updated_datetime": "2022-03-18T01:52:48.853Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "168.333251953125",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "9d563545-e1d9-40a5-8948-5691edb7fb10",
					"created_datetime": "2022-03-18T01:52:48.856Z",
					"updated_datetime": "2022-03-18T01:52:48.856Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "168.7434844970703",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "17a47889-dbc0-4ca5-91bb-9b8d661169c4",
					"created_datetime": "2022-03-18T01:52:48.858Z",
					"updated_datetime": "2022-03-18T01:52:48.858Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "126.17415618896484",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "c09be584-5ef5-45eb-b5dc-66ac96e69e96",
					"created_datetime": "2022-03-18T01:52:48.861Z",
					"updated_datetime": "2022-03-18T01:52:48.861Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "120.62943267822266",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "76097d9e-904d-4111-b24a-6354a4e35aae",
					"created_datetime": "2022-03-18T01:52:48.864Z",
					"updated_datetime": "2022-03-18T01:52:48.864Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "87.65190887451172",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "3b08e598-ae3f-4b18-be22-291eb03a0df2",
					"created_datetime": "2022-03-18T01:52:48.867Z",
					"updated_datetime": "2022-03-18T01:52:48.867Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "82.07312774658203",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "ec8e6c58-0082-4281-89e2-5539a583d696",
					"created_datetime": "2022-03-18T01:52:48.871Z",
					"updated_datetime": "2022-03-18T01:52:48.871Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "130.1524200439453",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "d85041ec-3fe7-4eb5-b7e8-ad4037c28680",
					"created_datetime": "2022-03-18T01:52:48.874Z",
					"updated_datetime": "2022-03-18T01:52:48.874Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "135.43017578125",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "4c02033e-d6dd-4c18-94ae-40b20990d1d7",
					"created_datetime": "2022-03-18T01:52:48.877Z",
					"updated_datetime": "2022-03-18T01:52:48.877Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "47.99644088745117",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "5bc3c912-147f-453b-b588-c830bdae363a",
					"created_datetime": "2022-03-18T01:52:48.880Z",
					"updated_datetime": "2022-03-18T01:52:48.880Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "57.08967208862305",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "10cedc79-f720-441c-9d3a-31332fec72d4",
					"created_datetime": "2022-03-18T01:52:48.883Z",
					"updated_datetime": "2022-03-18T01:52:48.883Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_stability_scap_left",
					"value_string": "24.660888671875",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "d60bfc68-f326-43dd-9712-9f1c0461c875",
					"created_datetime": "2022-03-18T01:52:48.886Z",
					"updated_datetime": "2022-03-18T01:52:48.886Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_stability_scap_right",
					"value_string": "17.558813095092773",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "ecf62b35-062f-4718-ad16-0c1319d8c33a",
					"created_datetime": "2022-03-18T01:52:48.888Z",
					"updated_datetime": "2022-03-18T01:52:48.888Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "42.94987106323242",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "25f9ebfb-b25a-4a1a-a0c2-c18b0f6a29d2",
					"created_datetime": "2022-03-18T01:52:48.891Z",
					"updated_datetime": "2022-03-18T01:52:48.891Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "38.81914138793945",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "44740f33-586c-4cea-b3d1-1be60d7dec46",
					"created_datetime": "2022-03-18T01:52:48.894Z",
					"updated_datetime": "2022-03-18T01:52:48.894Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "43.26546096801758",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "ee36369d-66aa-44c8-b5d1-25a44e0239b3",
					"created_datetime": "2022-03-18T01:52:48.897Z",
					"updated_datetime": "2022-03-18T01:52:48.897Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.06456824392080307",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "787068bb-92b2-420e-a566-da712894773a",
					"created_datetime": "2022-03-18T01:52:48.900Z",
					"updated_datetime": "2022-03-18T01:52:48.900Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.398627758026123",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "655f9165-99f0-48e0-9888-94fa017cdc19",
					"created_datetime": "2022-03-18T01:52:48.903Z",
					"updated_datetime": "2022-03-18T01:52:48.903Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "2.419231653213501",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "c6d7a122-29c6-4167-b00e-243a5ea341a9",
					"created_datetime": "2022-03-18T01:52:48.905Z",
					"updated_datetime": "2022-03-18T01:52:48.905Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-4.633592128753662",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "75c25cc3-1118-4aa7-b4c9-c2e44b819149",
					"created_datetime": "2022-03-18T01:52:48.908Z",
					"updated_datetime": "2022-03-18T01:52:48.908Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.4684276580810547",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "d0eae3ed-556e-4e50-b479-164429856af8",
					"created_datetime": "2022-03-18T01:52:48.911Z",
					"updated_datetime": "2022-03-18T01:52:48.911Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-3.0893218517303467",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "11494a3a-279b-403e-b07e-1df7bb832b44",
					"created_datetime": "2022-03-18T01:52:48.914Z",
					"updated_datetime": "2022-03-18T01:52:48.914Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-3.315153121948242",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "fc2341b4-e24a-48c7-ad24-8af59f488ff3",
					"created_datetime": "2022-03-18T01:52:48.917Z",
					"updated_datetime": "2022-03-18T01:52:48.917Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_knee",
					"value_string": "4.69797945022583",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "d2595f00-e7fd-426d-bb6b-2710a4bc3dc5",
					"created_datetime": "2022-03-18T01:52:48.919Z",
					"updated_datetime": "2022-03-18T01:52:48.919Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "8.528736114501953",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "1f617a79-8b80-4bb7-b941-8cac1de54067",
					"created_datetime": "2022-03-18T01:52:48.922Z",
					"updated_datetime": "2022-03-18T01:52:48.922Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_composite",
					"value_string": "0.6807597279548645",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "54120888-84ca-4b4f-8278-b35ad688523a",
					"created_datetime": "2022-03-18T01:52:48.925Z",
					"updated_datetime": "2022-03-18T01:52:48.925Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility",
					"value_string": "0.783939003944397",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "151e56af-09c9-4f73-84e3-81685ce42e00",
					"created_datetime": "2022-03-18T01:52:48.928Z",
					"updated_datetime": "2022-03-18T01:52:48.928Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability",
					"value_string": "0.5886071920394897",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "d922a894-d79e-46ad-becd-1a89d86b716b",
					"created_datetime": "2022-03-18T01:52:48.930Z",
					"updated_datetime": "2022-03-18T01:52:48.930Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_posture",
					"value_string": "0.6716967225074768",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "ae198795-4acd-434c-8e73-6f1fbc32f505",
					"created_datetime": "2022-03-18T01:52:48.933Z",
					"updated_datetime": "2022-03-18T01:52:48.933Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry",
					"value_string": "0.5891010761260986",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "210649fb-6d64-49fd-93f5-26cb74490844",
					"created_datetime": "2022-03-18T01:52:48.936Z",
					"updated_datetime": "2022-03-18T01:52:48.936Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7836666107177734",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "d50040ff-3260-465a-84cd-49962df53407",
					"created_datetime": "2022-03-18T01:52:48.939Z",
					"updated_datetime": "2022-03-18T01:52:48.939Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.36273708939552307",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "587b578c-ac48-4bd2-8bb7-88ab798edabe",
					"created_datetime": "2022-03-18T01:52:48.942Z",
					"updated_datetime": "2022-03-18T01:52:48.942Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8129671812057495",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "855e7e2f-06de-4bd7-b3bc-c94bd35393b8",
					"created_datetime": "2022-03-18T01:52:48.945Z",
					"updated_datetime": "2022-03-18T01:52:48.945Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.8202275037765503",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "c28439a4-ab8f-4971-acef-76847ca62fd2",
					"created_datetime": "2022-03-18T01:52:48.947Z",
					"updated_datetime": "2022-03-18T01:52:48.947Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.7663767337799072",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "3904972c-a5cb-4ab5-b783-cb394e1f3bcc",
					"created_datetime": "2022-03-18T01:52:48.953Z",
					"updated_datetime": "2022-03-18T01:52:48.953Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.6932828426361084",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "787726c8-da2f-4092-a513-c52a4ec86ee9",
					"created_datetime": "2022-03-18T01:52:48.957Z",
					"updated_datetime": "2022-03-18T01:52:48.957Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.9439256191253662",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "dbafec0d-0647-40dd-ad23-311128fa58fa",
					"created_datetime": "2022-03-18T01:52:48.959Z",
					"updated_datetime": "2022-03-18T01:52:48.959Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8768678903579712",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "1053ffca-34fa-42b2-82ce-8cabc5d341db",
					"created_datetime": "2022-03-18T01:52:48.962Z",
					"updated_datetime": "2022-03-18T01:52:48.962Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.7756611704826355",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "af750f06-f0d4-41a0-95c3-4d5fc5e4a742",
					"created_datetime": "2022-03-18T01:52:48.964Z",
					"updated_datetime": "2022-03-18T01:52:48.964Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8464933037757874",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "cdc3df38-3acc-4703-bfc6-8f3252c23f77",
					"created_datetime": "2022-03-18T01:52:48.967Z",
					"updated_datetime": "2022-03-18T01:52:48.967Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.43369951844215393",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "df6676dc-7698-4688-b2f9-7a8d4ccec4ae",
					"created_datetime": "2022-03-18T01:52:48.969Z",
					"updated_datetime": "2022-03-18T01:52:48.969Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.7226748466491699",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "37df0d43-9d9a-4ae2-a014-f7f5bd3067b2",
					"created_datetime": "2022-03-18T01:52:48.975Z",
					"updated_datetime": "2022-03-18T01:52:48.975Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability_scap_left",
					"value_string": "0.3488573431968689",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "9748dfa7-fe6d-4f03-b0c9-24d9d34f1ecd",
					"created_datetime": "2022-03-18T01:52:48.978Z",
					"updated_datetime": "2022-03-18T01:52:48.978Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability_scap_right",
					"value_string": "0.5806013941764832",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "a5f9c202-a500-49c0-8cee-ddaba9f42f87",
					"created_datetime": "2022-03-18T01:52:48.980Z",
					"updated_datetime": "2022-03-18T01:52:48.980Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.3840019106864929",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "fb2f722d-db89-4765-b7f9-80e2be7c6527",
					"created_datetime": "2022-03-18T01:52:48.983Z",
					"updated_datetime": "2022-03-18T01:52:48.983Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.9165009260177612",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "1847ceac-e143-42ab-b417-ff8c7a34ebd7",
					"created_datetime": "2022-03-18T01:52:48.986Z",
					"updated_datetime": "2022-03-18T01:52:48.986Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9661160707473755",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "f24b9a21-6dce-49b5-814b-6a6eb1443fb9",
					"created_datetime": "2022-03-18T01:52:48.988Z",
					"updated_datetime": "2022-03-18T01:52:48.988Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9730885028839111",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "639e083a-ed34-4ef3-88f6-bd53069e1632",
					"created_datetime": "2022-03-18T01:52:48.993Z",
					"updated_datetime": "2022-03-18T01:52:48.993Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.3703049421310425",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "f85d9226-1a5b-4008-bbc3-3340dbebcf5e",
					"created_datetime": "2022-03-18T01:52:48.995Z",
					"updated_datetime": "2022-03-18T01:52:48.995Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.8690842986106873",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "4e724b4b-515c-452d-8d07-8e09cdc688d3",
					"created_datetime": "2022-03-18T01:52:48.998Z",
					"updated_datetime": "2022-03-18T01:52:48.998Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.5582616329193115",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "ffa826c5-f045-4a37-a127-07af87d4e7dc",
					"created_datetime": "2022-03-18T01:52:49.000Z",
					"updated_datetime": "2022-03-18T01:52:49.000Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9943851232528687",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "0b7abc1b-7195-402e-8c37-994523f47765",
					"created_datetime": "2022-03-18T01:52:49.003Z",
					"updated_datetime": "2022-03-18T01:52:49.003Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9186347126960754",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "59d3a01d-45b2-49fe-a353-378f9d5b47f6",
					"created_datetime": "2022-03-18T01:52:49.005Z",
					"updated_datetime": "2022-03-18T01:52:49.005Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_femur",
					"value_string": "0.6876415014266968",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "89269f8d-e4b8-46c8-94f0-05ebb5c67688",
					"created_datetime": "2022-03-18T01:52:49.008Z",
					"updated_datetime": "2022-03-18T01:52:49.008Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_knee",
					"value_string": "0.776614248752594",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "849f9ebe-a9ff-43fc-b740-0fdf7fddce50",
					"created_datetime": "2022-03-18T01:52:49.011Z",
					"updated_datetime": "2022-03-18T01:52:49.011Z",
					"assessment_id_guid": "825091f3-d4a5-4031-bafa-f6c7e744ef9b",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.15609371662139893",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
			"created_datetime": "2022-03-18T01:53:31.038Z",
			"updated_datetime": "2022-03-18T01:53:31.038Z",
			"submitter_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"athlete_user_id_guid": "fdbc3b28-1980-434f-80ff-c5d6d8377eec",
			"external_id_guid": "c5eb8e1f-18e1-4f19-81b9-4e8b5482eea3",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c2579cc7-d27e-46fc-9acb-6650ec7599b2",
					"created_datetime": "2022-03-18T01:53:31.042Z",
					"updated_datetime": "2022-03-18T01:53:31.042Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T01:53:30.745Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "38cbd36f-4513-429d-9ebc-59504404079d",
					"created_datetime": "2022-03-18T01:53:31.050Z",
					"updated_datetime": "2022-03-18T01:53:31.050Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "059d9d28-9364-4e18-8e2b-273f10a46d68",
					"created_datetime": "2022-03-18T01:53:31.054Z",
					"updated_datetime": "2022-03-18T01:53:31.054Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "164.96812438964844",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "8f2c325a-068e-4486-8309-0f336a9dd519",
					"created_datetime": "2022-03-18T01:53:31.073Z",
					"updated_datetime": "2022-03-18T01:53:31.073Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "160.78724670410156",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "bcc93e8f-d38d-4cbd-9ee5-caea64dfb768",
					"created_datetime": "2022-03-18T01:53:31.077Z",
					"updated_datetime": "2022-03-18T01:53:31.077Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "170.2943115234375",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "160a01ad-5c58-4860-a4ab-963a4792ed81",
					"created_datetime": "2022-03-18T01:53:31.079Z",
					"updated_datetime": "2022-03-18T01:53:31.079Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "170.73402404785156",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "0dda4ced-c27f-40a8-8eee-bc1804bf2ecc",
					"created_datetime": "2022-03-18T01:53:31.082Z",
					"updated_datetime": "2022-03-18T01:53:31.082Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "127.26737213134766",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "0215bb37-f01e-4970-a69f-6532b8334f2a",
					"created_datetime": "2022-03-18T01:53:31.084Z",
					"updated_datetime": "2022-03-18T01:53:31.084Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "128.91783142089844",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "4921dee7-37e4-4e50-bb06-38370cff8164",
					"created_datetime": "2022-03-18T01:53:31.087Z",
					"updated_datetime": "2022-03-18T01:53:31.087Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "95.64044189453125",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "2e5151fc-756a-4688-b72c-7988f5c745a6",
					"created_datetime": "2022-03-18T01:53:31.091Z",
					"updated_datetime": "2022-03-18T01:53:31.091Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "93.4040756225586",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "681beca7-90e2-46d2-ac84-e2fb0f18f9d1",
					"created_datetime": "2022-03-18T01:53:31.099Z",
					"updated_datetime": "2022-03-18T01:53:31.099Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "143.56202697753906",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "328da657-b235-46c1-a6c2-5ed21dc9bbe5",
					"created_datetime": "2022-03-18T01:53:31.102Z",
					"updated_datetime": "2022-03-18T01:53:31.102Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "140.83782958984375",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "791a6e81-f81e-4a6f-819a-1e74140d2167",
					"created_datetime": "2022-03-18T01:53:31.106Z",
					"updated_datetime": "2022-03-18T01:53:31.106Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "54.96153259277344",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "b7aa7dd8-1ca0-40b1-a813-ffd2b56aaa2a",
					"created_datetime": "2022-03-18T01:53:31.109Z",
					"updated_datetime": "2022-03-18T01:53:31.109Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "55.85201644897461",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "cb13e9ab-af18-4a5f-8ed3-910be865d053",
					"created_datetime": "2022-03-18T01:53:31.112Z",
					"updated_datetime": "2022-03-18T01:53:31.112Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_stability_scap_left",
					"value_string": "21.0064697265625",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "24dc5ce2-79e5-4b70-bde2-c08aba43dc19",
					"created_datetime": "2022-03-18T01:53:31.115Z",
					"updated_datetime": "2022-03-18T01:53:31.115Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_stability_scap_right",
					"value_string": "9.329127311706543",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "44f34656-4c32-43cc-a1fe-65c476273fcb",
					"created_datetime": "2022-03-18T01:53:31.118Z",
					"updated_datetime": "2022-03-18T01:53:31.118Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "42.014347076416016",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "96540cb0-d271-4858-b824-8939435256e4",
					"created_datetime": "2022-03-18T01:53:31.122Z",
					"updated_datetime": "2022-03-18T01:53:31.122Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "62.8637809753418",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "8ee70922-9854-4dd5-858c-e2ac4e13d332",
					"created_datetime": "2022-03-18T01:53:31.125Z",
					"updated_datetime": "2022-03-18T01:53:31.125Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "63.2294807434082",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "619a7bcb-2de2-4166-90af-819507ac61d4",
					"created_datetime": "2022-03-18T01:53:31.128Z",
					"updated_datetime": "2022-03-18T01:53:31.128Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "0.5524082779884338",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "215220f6-8b25-49db-99aa-e707a753f339",
					"created_datetime": "2022-03-18T01:53:31.131Z",
					"updated_datetime": "2022-03-18T01:53:31.131Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.8821698427200317",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "668ea8a8-83d7-453d-b3ae-e28a1626f379",
					"created_datetime": "2022-03-18T01:53:31.136Z",
					"updated_datetime": "2022-03-18T01:53:31.136Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "0.2441210001707077",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "4c6a7604-808f-47d3-8bb3-78f065fa2e2f",
					"created_datetime": "2022-03-18T01:53:31.139Z",
					"updated_datetime": "2022-03-18T01:53:31.139Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-1.6478055715560913",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "ae9ae758-659f-44d4-a4b7-dc405c0caa7b",
					"created_datetime": "2022-03-18T01:53:31.142Z",
					"updated_datetime": "2022-03-18T01:53:31.142Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.7727522850036621",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "f19ae7e9-2331-495c-b815-c8007c8f3ff4",
					"created_datetime": "2022-03-18T01:53:31.144Z",
					"updated_datetime": "2022-03-18T01:53:31.144Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_hip",
					"value_string": "0.08549962192773819",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "592ca8a3-6bee-49e4-8b14-d696f3af149f",
					"created_datetime": "2022-03-18T01:53:31.176Z",
					"updated_datetime": "2022-03-18T01:53:31.176Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-1.9277257919311523",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "35b8e721-81f9-4ab2-871e-8e6c385b70fb",
					"created_datetime": "2022-03-18T01:53:31.180Z",
					"updated_datetime": "2022-03-18T01:53:31.180Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-1.2418748140335083",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "bc5802e3-da56-4793-8d04-8f9af935650b",
					"created_datetime": "2022-03-18T01:53:31.193Z",
					"updated_datetime": "2022-03-18T01:53:31.193Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "1.8100370168685913",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "d744ca5e-b7d7-48bb-8fce-24d870481557",
					"created_datetime": "2022-03-18T01:53:31.224Z",
					"updated_datetime": "2022-03-18T01:53:31.224Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_composite",
					"value_string": "0.8015958666801453",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "a082a061-5c93-4a81-a078-42942c0e3862",
					"created_datetime": "2022-03-18T01:53:31.232Z",
					"updated_datetime": "2022-03-18T01:53:31.232Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility",
					"value_string": "0.8592807650566101",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "e426f7f6-bcb2-42ba-a430-94ee4d64e727",
					"created_datetime": "2022-03-18T01:53:31.236Z",
					"updated_datetime": "2022-03-18T01:53:31.236Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability",
					"value_string": "0.6646789312362671",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "73622350-e49c-46de-b429-8f78cd2f6f25",
					"created_datetime": "2022-03-18T01:53:31.257Z",
					"updated_datetime": "2022-03-18T01:53:31.257Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_posture",
					"value_string": "0.5224694013595581",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "b84cbdb7-6767-4211-b8c1-bc45236d3e1d",
					"created_datetime": "2022-03-18T01:53:31.260Z",
					"updated_datetime": "2022-03-18T01:53:31.260Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry",
					"value_string": "0.9469619393348694",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "35f5e9f7-d93c-4539-86b9-46e102c51742",
					"created_datetime": "2022-03-18T01:53:31.264Z",
					"updated_datetime": "2022-03-18T01:53:31.264Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7468281984329224",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "66b2f86c-cb35-418e-a160-8ab93b50bf47",
					"created_datetime": "2022-03-18T01:53:31.268Z",
					"updated_datetime": "2022-03-18T01:53:31.268Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6501835584640503",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "a408c14e-0681-47da-a689-a070bbd1f60c",
					"created_datetime": "2022-03-18T01:53:31.271Z",
					"updated_datetime": "2022-03-18T01:53:31.271Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8460457921028137",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "9643b707-6852-44f7-ad17-1de9edbc7fda",
					"created_datetime": "2022-03-18T01:53:31.298Z",
					"updated_datetime": "2022-03-18T01:53:31.298Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.8528959155082703",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "1d9708e3-b670-4b39-b371-aa9be6482432",
					"created_datetime": "2022-03-18T01:53:31.346Z",
					"updated_datetime": "2022-03-18T01:53:31.346Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.779556155204773",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "4f3b1e0a-7680-4573-8826-6566b0357ccf",
					"created_datetime": "2022-03-18T01:53:31.373Z",
					"updated_datetime": "2022-03-18T01:53:31.373Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.7986231446266174",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "eb55f117-83d2-4c67-b2df-78a7caca88e2",
					"created_datetime": "2022-03-18T01:53:31.377Z",
					"updated_datetime": "2022-03-18T01:53:31.377Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.986206591129303",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "38e04b67-9570-4a68-a8fe-fbebde71cd30",
					"created_datetime": "2022-03-18T01:53:31.381Z",
					"updated_datetime": "2022-03-18T01:53:31.381Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.9788767695426941",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "0867a4d3-834c-4560-ba49-0f86e5954b3e",
					"created_datetime": "2022-03-18T01:53:31.384Z",
					"updated_datetime": "2022-03-18T01:53:31.384Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9233685731887817",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "f747bf2f-a90b-4471-9e26-fcfd4ca85376",
					"created_datetime": "2022-03-18T01:53:31.387Z",
					"updated_datetime": "2022-03-18T01:53:31.387Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.9018025398254395",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "4f6f836e-fca7-46eb-8a07-74e1e0ca27cf",
					"created_datetime": "2022-03-18T01:53:31.390Z",
					"updated_datetime": "2022-03-18T01:53:31.390Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.6603654623031616",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "400c116f-ee98-4439-842c-35fe5801f96a",
					"created_datetime": "2022-03-18T01:53:31.393Z",
					"updated_datetime": "2022-03-18T01:53:31.393Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.687107503414154",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "9b2cdc2c-d7fd-47ea-a5c5-9bff6d72cede",
					"created_datetime": "2022-03-18T01:53:31.430Z",
					"updated_datetime": "2022-03-18T01:53:31.430Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability_scap_left",
					"value_string": "0.4665788412094116",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "523a350e-f276-4b0b-8207-c74fa94e8e50",
					"created_datetime": "2022-03-18T01:53:31.445Z",
					"updated_datetime": "2022-03-18T01:53:31.445Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability_scap_right",
					"value_string": "0.813062846660614",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "af3e21fd-46a6-4ef3-a6d5-0361a896dc69",
					"created_datetime": "2022-03-18T01:53:31.455Z",
					"updated_datetime": "2022-03-18T01:53:31.455Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.42017942667007446",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "d130223c-8096-4ea5-bd97-c0ca86ec62ef",
					"created_datetime": "2022-03-18T01:53:31.458Z",
					"updated_datetime": "2022-03-18T01:53:31.458Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.999923586845398",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "69458d14-25ed-45e4-ba04-4f6b703e32fc",
					"created_datetime": "2022-03-18T01:53:31.462Z",
					"updated_datetime": "2022-03-18T01:53:31.462Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9999340772628784",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "1271fd1e-8f4a-4525-8a00-6b12399c21a7",
					"created_datetime": "2022-03-18T01:53:31.510Z",
					"updated_datetime": "2022-03-18T01:53:31.510Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9171590209007263",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "cc861d82-2147-4224-8809-676c98ec4591",
					"created_datetime": "2022-03-18T01:53:31.514Z",
					"updated_datetime": "2022-03-18T01:53:31.514Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.12777972221374512",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "3ae49350-b72e-45f3-8e74-7cfb28b041d2",
					"created_datetime": "2022-03-18T01:53:31.518Z",
					"updated_datetime": "2022-03-18T01:53:31.518Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.980669379234314",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "cead5ce7-705b-4469-b8c2-84fad6dc5ce8",
					"created_datetime": "2022-03-18T01:53:31.521Z",
					"updated_datetime": "2022-03-18T01:53:31.521Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9100198745727539",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "c10110bd-73a5-48de-8d7f-554e8af5eacf",
					"created_datetime": "2022-03-18T01:53:31.524Z",
					"updated_datetime": "2022-03-18T01:53:31.524Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9918727874755859",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "036baaf0-f91a-4fd3-832c-1cbee88ea36f",
					"created_datetime": "2022-03-18T01:53:31.527Z",
					"updated_datetime": "2022-03-18T01:53:31.527Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9965533018112183",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "def2c953-bdcd-430c-8263-97361445030f",
					"created_datetime": "2022-03-18T01:53:31.532Z",
					"updated_datetime": "2022-03-18T01:53:31.532Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_femur",
					"value_string": "0.930589497089386",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "accde6cb-3e87-439e-b1e1-6fb0fc422d94",
					"created_datetime": "2022-03-18T01:53:31.535Z",
					"updated_datetime": "2022-03-18T01:53:31.535Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9839535355567932",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "7eaf8aee-71d6-42bf-ae38-25f21f900d43",
					"created_datetime": "2022-03-18T01:53:31.541Z",
					"updated_datetime": "2022-03-18T01:53:31.541Z",
					"assessment_id_guid": "fa09bcf1-7524-4f12-9fe9-c672b59f996d",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9302644729614258",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "c3412612-50d6-4423-bbd5-362904724602",
			"created_datetime": "2022-03-18T02:26:26.232Z",
			"updated_datetime": "2022-03-18T02:26:26.232Z",
			"submitter_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"athlete_user_id_guid": "5ccf55df-2289-43b4-b791-803155f6ce5e",
			"external_id_guid": "c62cfd38-dc91-4263-8cc1-286c4f4ff62f",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "d38bc3c9-3738-4a08-8914-a61409e9285b",
					"created_datetime": "2022-03-18T02:26:26.417Z",
					"updated_datetime": "2022-03-18T02:26:26.417Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T02:26:25.875Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "ca24e493-97e5-463a-9aaa-0a1c77251b35",
					"created_datetime": "2022-03-18T02:26:26.422Z",
					"updated_datetime": "2022-03-18T02:26:26.422Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f36c9153-d2d8-45c2-9b7d-fa7ff03f8bb2",
					"created_datetime": "2022-03-18T02:26:26.426Z",
					"updated_datetime": "2022-03-18T02:26:26.426Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "166.22752380371094",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "b745e6a2-3d26-4111-a69e-f48d631a9ee4",
					"created_datetime": "2022-03-18T02:26:26.429Z",
					"updated_datetime": "2022-03-18T02:26:26.429Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "162.3195037841797",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "e35abc88-bded-41c5-b736-e312313ddcb4",
					"created_datetime": "2022-03-18T02:26:26.432Z",
					"updated_datetime": "2022-03-18T02:26:26.432Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "166.382568359375",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "f327e3c1-7804-42d3-a826-1ceaa7d482c2",
					"created_datetime": "2022-03-18T02:26:26.436Z",
					"updated_datetime": "2022-03-18T02:26:26.436Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "168.32398986816406",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "000b9c6e-a9b0-4c39-b746-16fab0cc7dc7",
					"created_datetime": "2022-03-18T02:26:26.439Z",
					"updated_datetime": "2022-03-18T02:26:26.439Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "106.29169464111328",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "19d4cba9-f901-4af6-b891-e19db66b9f30",
					"created_datetime": "2022-03-18T02:26:26.442Z",
					"updated_datetime": "2022-03-18T02:26:26.442Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "102.42285919189453",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "72584190-a097-4710-9be9-e01bebf0e3e7",
					"created_datetime": "2022-03-18T02:26:26.445Z",
					"updated_datetime": "2022-03-18T02:26:26.445Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "85.31623077392578",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "9cab9564-fcf4-42a5-953a-91f706c64924",
					"created_datetime": "2022-03-18T02:26:26.448Z",
					"updated_datetime": "2022-03-18T02:26:26.448Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "80.45960235595703",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "57e1870c-b297-4d1e-90f1-4d58504aa50e",
					"created_datetime": "2022-03-18T02:26:26.454Z",
					"updated_datetime": "2022-03-18T02:26:26.454Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "147.12193298339844",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "379354be-e662-426e-b620-bd5c0e18f49a",
					"created_datetime": "2022-03-18T02:26:26.457Z",
					"updated_datetime": "2022-03-18T02:26:26.457Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "138.78770446777344",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "7710bf6b-8a57-4dab-9088-6fd1ff545030",
					"created_datetime": "2022-03-18T02:26:26.460Z",
					"updated_datetime": "2022-03-18T02:26:26.460Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "66.8940658569336",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "d8b47a24-394f-4c45-9a51-82d2c8c71afe",
					"created_datetime": "2022-03-18T02:26:26.463Z",
					"updated_datetime": "2022-03-18T02:26:26.463Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "65.77375030517578",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "161e6ca0-642d-41c6-b2f1-19d2e916ccdf",
					"created_datetime": "2022-03-18T02:26:26.466Z",
					"updated_datetime": "2022-03-18T02:26:26.466Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_stability_scap_left",
					"value_string": "22.473419189453125",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "0bcad181-e397-4d12-babe-45640f346df1",
					"created_datetime": "2022-03-18T02:26:26.469Z",
					"updated_datetime": "2022-03-18T02:26:26.469Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_stability_scap_right",
					"value_string": "17.503225326538086",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "ceaf6398-72ce-42cf-af04-abc2be1aac56",
					"created_datetime": "2022-03-18T02:26:26.472Z",
					"updated_datetime": "2022-03-18T02:26:26.472Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "30.71398162841797",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "b410dff3-5db0-4e09-a45a-5eef3f6a4007",
					"created_datetime": "2022-03-18T02:26:26.474Z",
					"updated_datetime": "2022-03-18T02:26:26.474Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "79.85506439208984",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "c5d3d533-c7f9-42cc-b01b-27b7eb33ab08",
					"created_datetime": "2022-03-18T02:26:26.477Z",
					"updated_datetime": "2022-03-18T02:26:26.477Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "80.11419677734375",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "a5746c0b-2d74-45fd-9115-2c9e21f4a2b5",
					"created_datetime": "2022-03-18T02:26:26.480Z",
					"updated_datetime": "2022-03-18T02:26:26.480Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.47895392775535583",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "14a0cdd0-5044-48c5-aa14-f9609d6fc9bb",
					"created_datetime": "2022-03-18T02:26:26.484Z",
					"updated_datetime": "2022-03-18T02:26:26.484Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.0442975759506226",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "bee1ec72-1539-4af8-aa03-250890234639",
					"created_datetime": "2022-03-18T02:26:26.486Z",
					"updated_datetime": "2022-03-18T02:26:26.486Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "5.897470951080322",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "2008a24c-1945-482e-8f19-bfded0ec699e",
					"created_datetime": "2022-03-18T02:26:26.488Z",
					"updated_datetime": "2022-03-18T02:26:26.488Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-0.3893984854221344",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "48e425b6-7712-4c5a-956f-9182bcad6e16",
					"created_datetime": "2022-03-18T02:26:26.491Z",
					"updated_datetime": "2022-03-18T02:26:26.491Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.7235041260719299",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "1db18a62-2453-4b84-9c6c-525268019214",
					"created_datetime": "2022-03-18T02:26:26.493Z",
					"updated_datetime": "2022-03-18T02:26:26.493Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-0.4747277796268463",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "2e455a34-1189-4114-b9af-5f4a0e1d66c7",
					"created_datetime": "2022-03-18T02:26:26.496Z",
					"updated_datetime": "2022-03-18T02:26:26.496Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-2.6299355030059814",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "7db16337-62fb-4814-9150-411e35705e2b",
					"created_datetime": "2022-03-18T02:26:26.499Z",
					"updated_datetime": "2022-03-18T02:26:26.499Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-3.983717679977417",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "dae6c0c7-03cd-4e44-aa04-9e918030c656",
					"created_datetime": "2022-03-18T02:26:26.501Z",
					"updated_datetime": "2022-03-18T02:26:26.501Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-1.2391690015792847",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "2013e8b0-d6c7-4aa6-9832-2e484d5e4e6e",
					"created_datetime": "2022-03-18T02:26:26.505Z",
					"updated_datetime": "2022-03-18T02:26:26.505Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_composite",
					"value_string": "0.8331679701805115",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "609ee591-e6b1-45d1-9809-4a44484fa3e0",
					"created_datetime": "2022-03-18T02:26:26.508Z",
					"updated_datetime": "2022-03-18T02:26:26.508Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility",
					"value_string": "0.8373973965644836",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "a53f1b9c-0f03-496d-94db-d8d3d41e3fd8",
					"created_datetime": "2022-03-18T02:26:26.511Z",
					"updated_datetime": "2022-03-18T02:26:26.511Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability",
					"value_string": "0.8068846464157104",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "5af727a8-ce5f-472f-842b-da554580e81f",
					"created_datetime": "2022-03-18T02:26:26.514Z",
					"updated_datetime": "2022-03-18T02:26:26.514Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_posture",
					"value_string": "0.7656506896018982",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "c63014e5-1215-47d7-97bb-84bf03289bdf",
					"created_datetime": "2022-03-18T02:26:26.516Z",
					"updated_datetime": "2022-03-18T02:26:26.516Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry",
					"value_string": "0.8799561262130737",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "91f05329-0332-42db-bb42-d4906058ed14",
					"created_datetime": "2022-03-18T02:26:26.519Z",
					"updated_datetime": "2022-03-18T02:26:26.519Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7729212641716003",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "daa22145-f20f-4034-9758-8a96108e50dc",
					"created_datetime": "2022-03-18T02:26:26.521Z",
					"updated_datetime": "2022-03-18T02:26:26.521Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6872133016586304",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "2fa072ef-f051-442c-9901-48cac3f9ddf8",
					"created_datetime": "2022-03-18T02:26:26.524Z",
					"updated_datetime": "2022-03-18T02:26:26.524Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.7760252952575684",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "749c19de-d714-43b4-a773-bb43347b977c",
					"created_datetime": "2022-03-18T02:26:26.526Z",
					"updated_datetime": "2022-03-18T02:26:26.526Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.8128012418746948",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "2a936fc2-68a5-4e56-ab82-9c784c15d48a",
					"created_datetime": "2022-03-18T02:26:26.538Z",
					"updated_datetime": "2022-03-18T02:26:26.538Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.47276049852371216",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "0f8ac787-7cf8-4937-a156-329b93fef8c2",
					"created_datetime": "2022-03-18T02:26:26.541Z",
					"updated_datetime": "2022-03-18T02:26:26.541Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.4117344319820404",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "b555bb2f-2188-4c1b-a57d-6e5f6dd9973e",
					"created_datetime": "2022-03-18T02:26:26.543Z",
					"updated_datetime": "2022-03-18T02:26:26.543Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.9205735921859741",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "72d90933-0106-443a-8ecd-c3562e340ae0",
					"created_datetime": "2022-03-18T02:26:26.546Z",
					"updated_datetime": "2022-03-18T02:26:26.546Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8497481346130371",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "cfbcc983-9c7f-41ad-a73e-9c1a19f04954",
					"created_datetime": "2022-03-18T02:26:26.549Z",
					"updated_datetime": "2022-03-18T02:26:26.549Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9458736777305603",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "b3c23ff1-14eb-4f5e-be18-a0f338c66b54",
					"created_datetime": "2022-03-18T02:26:26.552Z",
					"updated_datetime": "2022-03-18T02:26:26.552Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8828558921813965",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "2eb09135-7025-42fb-97f9-81fc0acff918",
					"created_datetime": "2022-03-18T02:26:26.560Z",
					"updated_datetime": "2022-03-18T02:26:26.560Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.9204105734825134",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "bc25ee68-80b2-4083-9188-a92aafe3d3c0",
					"created_datetime": "2022-03-18T02:26:26.563Z",
					"updated_datetime": "2022-03-18T02:26:26.563Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.9056574106216431",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "0f8b7395-40ac-4f06-ac3e-e303500ac4e4",
					"created_datetime": "2022-03-18T02:26:26.567Z",
					"updated_datetime": "2022-03-18T02:26:26.567Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability_scap_left",
					"value_string": "0.4183492660522461",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "dd54ca09-e470-4b83-b46b-0c7a3ae0f53a",
					"created_datetime": "2022-03-18T02:26:26.570Z",
					"updated_datetime": "2022-03-18T02:26:26.570Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability_scap_right",
					"value_string": "0.5824106335639954",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "2c2918b6-221e-4908-99b3-bd79ef046381",
					"created_datetime": "2022-03-18T02:26:26.573Z",
					"updated_datetime": "2022-03-18T02:26:26.573Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.823452353477478",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "530e26ba-4ec0-4fbb-9cae-015b9e8405ce",
					"created_datetime": "2022-03-18T02:26:26.576Z",
					"updated_datetime": "2022-03-18T02:26:26.576Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.9998999834060669",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "d8dc3eff-25b4-4576-bc58-92648f0cfcb7",
					"created_datetime": "2022-03-18T02:26:26.579Z",
					"updated_datetime": "2022-03-18T02:26:26.579Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9998999834060669",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "de14736f-16dc-4b4f-9f08-b61422c31f8b",
					"created_datetime": "2022-03-18T02:26:26.582Z",
					"updated_datetime": "2022-03-18T02:26:26.582Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9289246201515198",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "a06d3c79-396e-4050-98d2-860d7637cd32",
					"created_datetime": "2022-03-18T02:26:26.585Z",
					"updated_datetime": "2022-03-18T02:26:26.585Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.6023767590522766",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "61cf5dff-ce89-478e-b3e8-337e331be084",
					"created_datetime": "2022-03-18T02:26:26.588Z",
					"updated_datetime": "2022-03-18T02:26:26.588Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.34819257259368896",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "baddaef4-7e0b-4ceb-9240-a901d726ab0a",
					"created_datetime": "2022-03-18T02:26:26.591Z",
					"updated_datetime": "2022-03-18T02:26:26.591Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9674260020256042",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "9ce15852-bfbd-4ac9-8d67-a3565532ad3c",
					"created_datetime": "2022-03-18T02:26:26.594Z",
					"updated_datetime": "2022-03-18T02:26:26.594Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.992336630821228",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "fd36b79e-b698-49f4-94cf-eeeb88a8aca5",
					"created_datetime": "2022-03-18T02:26:26.596Z",
					"updated_datetime": "2022-03-18T02:26:26.596Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9943410158157349",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "f995f43b-d9b5-41ce-80dc-71ad9269ac0f",
					"created_datetime": "2022-03-18T02:26:26.599Z",
					"updated_datetime": "2022-03-18T02:26:26.599Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8361154794692993",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "b0fd4cf4-b6d4-4508-8f6c-fed8e202a4c0",
					"created_datetime": "2022-03-18T02:26:26.602Z",
					"updated_datetime": "2022-03-18T02:26:26.602Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_knee",
					"value_string": "0.8523378372192383",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "f977c991-c2ae-43a0-af22-0c631cfcfab4",
					"created_datetime": "2022-03-18T02:26:26.605Z",
					"updated_datetime": "2022-03-18T02:26:26.605Z",
					"assessment_id_guid": "c3412612-50d6-4423-bbd5-362904724602",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.954409122467041",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
			"created_datetime": "2022-03-18T02:29:58.367Z",
			"updated_datetime": "2022-03-18T02:29:58.367Z",
			"submitter_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"athlete_user_id_guid": "c0294105-1f92-4750-bc91-1ee3846a5e7b",
			"external_id_guid": "47ab2b72-9317-4bd7-8136-ec75569da2d1",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "692d63b9-ba8f-4f4c-baad-2d2fd00f1cc7",
					"created_datetime": "2022-03-18T02:29:58.374Z",
					"updated_datetime": "2022-03-18T02:29:58.374Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T02:29:58.077Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "2a28c370-e384-4b8d-82aa-908a264a49fc",
					"created_datetime": "2022-03-18T02:29:58.377Z",
					"updated_datetime": "2022-03-18T02:29:58.377Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "3624a3d0-8faf-41ac-b062-087f13e03872",
					"created_datetime": "2022-03-18T02:29:58.381Z",
					"updated_datetime": "2022-03-18T02:29:58.381Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "146.21849060058594",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "7d3d16e0-a86b-48a8-8519-e35455fbd827",
					"created_datetime": "2022-03-18T02:29:58.384Z",
					"updated_datetime": "2022-03-18T02:29:58.384Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "134.3067626953125",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "1768a5d2-0119-4a62-b670-67e953acab72",
					"created_datetime": "2022-03-18T02:29:58.388Z",
					"updated_datetime": "2022-03-18T02:29:58.388Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "113.75894927978516",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "1ec136a7-66ed-4b34-9ddf-53aff3276b2c",
					"created_datetime": "2022-03-18T02:29:58.391Z",
					"updated_datetime": "2022-03-18T02:29:58.391Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "105.00963592529297",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "2c6ed165-1453-4670-97c0-ec81bd916323",
					"created_datetime": "2022-03-18T02:29:58.394Z",
					"updated_datetime": "2022-03-18T02:29:58.394Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "102.7882308959961",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "fc4cc9dd-df5a-404e-943e-41bf766a8ef4",
					"created_datetime": "2022-03-18T02:29:58.397Z",
					"updated_datetime": "2022-03-18T02:29:58.397Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "112.31253814697266",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "0e20ea89-f0d9-4525-a315-ee9471a7610f",
					"created_datetime": "2022-03-18T02:29:58.400Z",
					"updated_datetime": "2022-03-18T02:29:58.400Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "49.18048095703125",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "940790b7-a32d-4885-bf0b-cfaa4cf7c49a",
					"created_datetime": "2022-03-18T02:29:58.403Z",
					"updated_datetime": "2022-03-18T02:29:58.403Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "48.58082580566406",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "99a6b636-f0c9-43ac-a3ee-49e7eda99013",
					"created_datetime": "2022-03-18T02:29:58.406Z",
					"updated_datetime": "2022-03-18T02:29:58.406Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "76.22179412841797",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "3ca3372a-022c-48d5-a165-3939d8352edc",
					"created_datetime": "2022-03-18T02:29:58.409Z",
					"updated_datetime": "2022-03-18T02:29:58.409Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "87.93538665771484",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "0f4337a0-8741-4606-ad47-aa7b573c52aa",
					"created_datetime": "2022-03-18T02:29:58.413Z",
					"updated_datetime": "2022-03-18T02:29:58.413Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "33.75704574584961",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "98f6e8b9-bdec-497b-bbac-f18f4681b517",
					"created_datetime": "2022-03-18T02:29:58.416Z",
					"updated_datetime": "2022-03-18T02:29:58.416Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "44.6223030090332",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "2f124d57-721a-4fe6-950f-5218a9f31713",
					"created_datetime": "2022-03-18T02:29:58.419Z",
					"updated_datetime": "2022-03-18T02:29:58.419Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_stability_scap_left",
					"value_string": "38.89925003051758",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "c93ca0d8-5ffd-4926-81f2-fc3e39f6b182",
					"created_datetime": "2022-03-18T02:29:58.423Z",
					"updated_datetime": "2022-03-18T02:29:58.423Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_stability_scap_right",
					"value_string": "31.887537002563477",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "ad2edd88-3c20-4b46-8080-4afaf873ac26",
					"created_datetime": "2022-03-18T02:29:58.426Z",
					"updated_datetime": "2022-03-18T02:29:58.426Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "62.89699172973633",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "c77e4fe8-3fe4-42ef-8b0e-2f84f8a368e7",
					"created_datetime": "2022-03-18T02:29:58.428Z",
					"updated_datetime": "2022-03-18T02:29:58.428Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "14.320256233215332",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "bbbbf9f0-d50b-4f81-a335-7282e766a9cd",
					"created_datetime": "2022-03-18T02:29:58.431Z",
					"updated_datetime": "2022-03-18T02:29:58.431Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "7.5886664390563965",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "9157f8be-6d40-4cc9-8886-36f6fdfa756e",
					"created_datetime": "2022-03-18T02:29:58.434Z",
					"updated_datetime": "2022-03-18T02:29:58.434Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "14.578946113586426",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "d10252b3-3c83-4f8f-9c2d-2c9f0d0880e0",
					"created_datetime": "2022-03-18T02:29:58.437Z",
					"updated_datetime": "2022-03-18T02:29:58.437Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "15.198112487792969",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "6cb4253f-6fa9-4bd9-87bd-2cac0e5ae2d7",
					"created_datetime": "2022-03-18T02:29:58.439Z",
					"updated_datetime": "2022-03-18T02:29:58.439Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "20.012832641601562",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "5a9726c7-0595-4082-98ca-f199a9b678c6",
					"created_datetime": "2022-03-18T02:29:58.442Z",
					"updated_datetime": "2022-03-18T02:29:58.442Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "3.353590965270996",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "6a46bdc6-d60e-49b2-8e2c-242a4343e849",
					"created_datetime": "2022-03-18T02:29:58.445Z",
					"updated_datetime": "2022-03-18T02:29:58.445Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "24.1928768157959",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "5e142f7d-6ac4-4735-a333-5674dfd2f7bb",
					"created_datetime": "2022-03-18T02:29:58.449Z",
					"updated_datetime": "2022-03-18T02:29:58.449Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_hip",
					"value_string": "5.0262532234191895",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "85cb56c4-fe81-45b3-9d63-70ea36329e34",
					"created_datetime": "2022-03-18T02:29:58.452Z",
					"updated_datetime": "2022-03-18T02:29:58.452Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-1.386693000793457",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "dd1e8243-b49d-4f7e-8375-ea106cdfa9bd",
					"created_datetime": "2022-03-18T02:29:58.457Z",
					"updated_datetime": "2022-03-18T02:29:58.457Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_knee",
					"value_string": "8.037372589111328",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "43e36438-3adc-4eb6-95f0-15c596f75ba8",
					"created_datetime": "2022-03-18T02:29:58.460Z",
					"updated_datetime": "2022-03-18T02:29:58.460Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "8.3089017868042",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "60607fb3-58a2-4af5-9b9f-ce486bb24ce9",
					"created_datetime": "2022-03-18T02:29:58.463Z",
					"updated_datetime": "2022-03-18T02:29:58.463Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_composite",
					"value_string": "0.16783872246742249",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "0e82b6cb-835a-46dc-8654-d5026b961691",
					"created_datetime": "2022-03-18T02:29:58.465Z",
					"updated_datetime": "2022-03-18T02:29:58.465Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility",
					"value_string": "0.11454135179519653",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "43346a75-271f-4191-aa4a-ade6a05e95b7",
					"created_datetime": "2022-03-18T02:29:58.468Z",
					"updated_datetime": "2022-03-18T02:29:58.468Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability",
					"value_string": "0.06087412312626839",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "8657aa32-559a-4679-8323-33b4e38931dc",
					"created_datetime": "2022-03-18T02:29:58.471Z",
					"updated_datetime": "2022-03-18T02:29:58.471Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_posture",
					"value_string": "0.00010001659393310547",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "4b8385b5-4886-490f-b193-500bab6c2d25",
					"created_datetime": "2022-03-18T02:29:58.475Z",
					"updated_datetime": "2022-03-18T02:29:58.475Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry",
					"value_string": "0.4901393949985504",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "5529eb02-ca0e-41f5-8975-a3f5c9c9bfe0",
					"created_datetime": "2022-03-18T02:29:58.478Z",
					"updated_datetime": "2022-03-18T02:29:58.478Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.27912792563438416",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "faa5355e-4887-4d53-9c6c-403508b90160",
					"created_datetime": "2022-03-18T02:29:58.480Z",
					"updated_datetime": "2022-03-18T02:29:58.480Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.08386281132698059",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "c8ce949d-ead6-46df-be32-7e0243c14d4e",
					"created_datetime": "2022-03-18T02:29:58.483Z",
					"updated_datetime": "2022-03-18T02:29:58.483Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.002985239028930664",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "8e60b162-f921-4225-ad1c-f0093ca8dcad",
					"created_datetime": "2022-03-18T02:29:58.486Z",
					"updated_datetime": "2022-03-18T02:29:58.486Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.00043010711669921875",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "c8ace565-ead7-424d-aabc-89f7a20579ee",
					"created_datetime": "2022-03-18T02:29:58.488Z",
					"updated_datetime": "2022-03-18T02:29:58.488Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.41743066906929016",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "37128b37-a2de-4624-adb8-fbe2ec10fdfa",
					"created_datetime": "2022-03-18T02:29:58.491Z",
					"updated_datetime": "2022-03-18T02:29:58.491Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5684783458709717",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "8e0471ee-5834-4458-9d1f-542a6446021f",
					"created_datetime": "2022-03-18T02:29:58.494Z",
					"updated_datetime": "2022-03-18T02:29:58.494Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.0852293074131012",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "8a36bd6d-d37b-4d0f-ac15-def5461c04e2",
					"created_datetime": "2022-03-18T02:29:58.497Z",
					"updated_datetime": "2022-03-18T02:29:58.497Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.07826206088066101",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "653fa419-4e9c-48af-873b-947ffd27fbfc",
					"created_datetime": "2022-03-18T02:29:58.499Z",
					"updated_datetime": "2022-03-18T02:29:58.499Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.026256054639816284",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "51a40928-b418-4ab5-b3ce-46c7dc0c78fa",
					"created_datetime": "2022-03-18T02:29:58.502Z",
					"updated_datetime": "2022-03-18T02:29:58.502Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.08799105882644653",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "b3cec5f6-7824-42fa-9c51-7f459620575c",
					"created_datetime": "2022-03-18T02:29:58.505Z",
					"updated_datetime": "2022-03-18T02:29:58.505Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.08793532848358154",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "e6f297d6-8ec4-41ef-89c6-5c80df11d537",
					"created_datetime": "2022-03-18T02:29:58.507Z",
					"updated_datetime": "2022-03-18T02:29:58.507Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.3270255923271179",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "210bac59-cb6c-4751-8ca6-da6754b31238",
					"created_datetime": "2022-03-18T02:29:58.510Z",
					"updated_datetime": "2022-03-18T02:29:58.510Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability_scap_left",
					"value_string": "0.05763542652130127",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "cdb02c31-f94f-4dfd-86c3-2579e3ebd981",
					"created_datetime": "2022-03-18T02:29:58.513Z",
					"updated_datetime": "2022-03-18T02:29:58.513Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability_scap_right",
					"value_string": "0.16093361377716064",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "d689ea31-0d67-4de5-882d-df31645fd318",
					"created_datetime": "2022-03-18T02:29:58.515Z",
					"updated_datetime": "2022-03-18T02:29:58.515Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.011019349098205566",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "ed6a8445-32bb-4ac9-b3db-e956eb3abc31",
					"created_datetime": "2022-03-18T02:29:58.519Z",
					"updated_datetime": "2022-03-18T02:29:58.519Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.14276602864265442",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "0ef24a74-a327-4a7b-b588-cd8f59b3f90d",
					"created_datetime": "2022-03-18T02:29:58.522Z",
					"updated_datetime": "2022-03-18T02:29:58.522Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.04083007574081421",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "60bc9e9b-63ed-4b2d-ab50-8b6d65817ee2",
					"created_datetime": "2022-03-18T02:29:58.525Z",
					"updated_datetime": "2022-03-18T02:29:58.525Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "33104277-3403-4557-800b-ef16afc17950",
					"created_datetime": "2022-03-18T02:29:58.527Z",
					"updated_datetime": "2022-03-18T02:29:58.527Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "701aeed9-f9b9-4819-932b-cabae8ba535e",
					"created_datetime": "2022-03-18T02:29:58.530Z",
					"updated_datetime": "2022-03-18T02:29:58.530Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "147c887e-9f61-44ad-b253-5fa1c0fe5e37",
					"created_datetime": "2022-03-18T02:29:58.533Z",
					"updated_datetime": "2022-03-18T02:29:58.533Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.7449120283126831",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "4717b4ac-5c23-42cf-a2c4-a10465852f9c",
					"created_datetime": "2022-03-18T02:29:58.535Z",
					"updated_datetime": "2022-03-18T02:29:58.535Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "15281907-dc21-4682-9533-1692b223e781",
					"created_datetime": "2022-03-18T02:29:58.538Z",
					"updated_datetime": "2022-03-18T02:29:58.538Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7101432085037231",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "66aa1148-0f2e-4ebf-9349-804575d2e792",
					"created_datetime": "2022-03-18T02:29:58.541Z",
					"updated_datetime": "2022-03-18T02:29:58.541Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_femur",
					"value_string": "0.969024658203125",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "ddeaf3bb-e5d3-4582-8d44-12d6ed92cad1",
					"created_datetime": "2022-03-18T02:29:58.543Z",
					"updated_datetime": "2022-03-18T02:29:58.543Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_knee",
					"value_string": "0.28266286849975586",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "71b83028-6656-425f-a0e0-623f74fae717",
					"created_datetime": "2022-03-18T02:29:58.546Z",
					"updated_datetime": "2022-03-18T02:29:58.546Z",
					"assessment_id_guid": "36747ff6-be4d-4232-8dfd-2d290d2f841e",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.17638683319091797",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
			"created_datetime": "2022-03-18T02:44:07.404Z",
			"updated_datetime": "2022-03-18T02:44:07.404Z",
			"submitter_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"athlete_user_id_guid": "c11cee63-5697-46ab-a2da-1f7e4542114f",
			"external_id_guid": "2c2d2df1-5910-40fc-b809-a15036989488",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "d0794baf-9d2c-4b19-b31a-23a33101fb47",
					"created_datetime": "2022-03-18T02:44:07.409Z",
					"updated_datetime": "2022-03-18T02:44:07.409Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T02:44:07.070Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "587fb2e4-32e5-43c0-8147-e7d77b398fe6",
					"created_datetime": "2022-03-18T02:44:07.412Z",
					"updated_datetime": "2022-03-18T02:44:07.412Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "bf69abdf-edd2-46fb-a802-677b0f6a0264",
					"created_datetime": "2022-03-18T02:44:07.416Z",
					"updated_datetime": "2022-03-18T02:44:07.416Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "163.888916015625",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "5ce8ca59-8e7b-43e8-88c3-574dfdc0741a",
					"created_datetime": "2022-03-18T02:44:07.419Z",
					"updated_datetime": "2022-03-18T02:44:07.419Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "152.35569763183594",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "0528b31e-e9ca-4469-a269-6f2cf4a59b64",
					"created_datetime": "2022-03-18T02:44:07.422Z",
					"updated_datetime": "2022-03-18T02:44:07.422Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "169.0154266357422",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "9c27989c-ec0a-47ae-8dd0-cac9dd089191",
					"created_datetime": "2022-03-18T02:44:07.425Z",
					"updated_datetime": "2022-03-18T02:44:07.425Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "168.0586700439453",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "42abdbf2-613d-421d-afb3-1f2be887aa5c",
					"created_datetime": "2022-03-18T02:44:07.428Z",
					"updated_datetime": "2022-03-18T02:44:07.428Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "119.82990264892578",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "8a4d87f0-23cb-4f44-8158-18060ed3506f",
					"created_datetime": "2022-03-18T02:44:07.431Z",
					"updated_datetime": "2022-03-18T02:44:07.431Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "119.58545684814453",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "24e6cb71-1f98-4aa3-a589-33137eecd4fb",
					"created_datetime": "2022-03-18T02:44:07.434Z",
					"updated_datetime": "2022-03-18T02:44:07.434Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "86.23346710205078",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "df537466-5b93-4395-9d09-5eca96c8f03e",
					"created_datetime": "2022-03-18T02:44:07.437Z",
					"updated_datetime": "2022-03-18T02:44:07.437Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "82.89946746826172",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "012e597d-5239-47b2-8809-e82f1ea2a477",
					"created_datetime": "2022-03-18T02:44:07.439Z",
					"updated_datetime": "2022-03-18T02:44:07.439Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "140.48199462890625",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "5fe330da-e823-4fbd-a278-e82202e50ae2",
					"created_datetime": "2022-03-18T02:44:07.443Z",
					"updated_datetime": "2022-03-18T02:44:07.443Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "137.6488494873047",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "9d8fcbc7-9bba-4bc4-a749-de3b50771ae3",
					"created_datetime": "2022-03-18T02:44:07.446Z",
					"updated_datetime": "2022-03-18T02:44:07.446Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "57.2186393737793",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "ebab1f46-e469-4169-ad73-e46b74b533e4",
					"created_datetime": "2022-03-18T02:44:07.449Z",
					"updated_datetime": "2022-03-18T02:44:07.449Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "58.349369049072266",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "23b3aa73-8369-48c1-b427-186e9d8b8bc1",
					"created_datetime": "2022-03-18T02:44:07.452Z",
					"updated_datetime": "2022-03-18T02:44:07.452Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_stability_scap_left",
					"value_string": "33.777000427246094",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "f4939f11-74b2-441c-9592-a77755245465",
					"created_datetime": "2022-03-18T02:44:07.454Z",
					"updated_datetime": "2022-03-18T02:44:07.454Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_stability_scap_right",
					"value_string": "26.11295509338379",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "c4e9a501-f0ef-4513-9dcc-718b5643e6c3",
					"created_datetime": "2022-03-18T02:44:07.457Z",
					"updated_datetime": "2022-03-18T02:44:07.457Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "39.048160552978516",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "0d0d6fda-f189-46d4-9e3a-d28d8ad6c01e",
					"created_datetime": "2022-03-18T02:44:07.460Z",
					"updated_datetime": "2022-03-18T02:44:07.460Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "49.746517181396484",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "14444086-bc60-4f45-9215-3d1f721a6c80",
					"created_datetime": "2022-03-18T02:44:07.462Z",
					"updated_datetime": "2022-03-18T02:44:07.462Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "48.391326904296875",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "5cc73d79-f71f-49da-ab76-d84d84c5b0dc",
					"created_datetime": "2022-03-18T02:44:07.465Z",
					"updated_datetime": "2022-03-18T02:44:07.465Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-0.22168943285942078",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "1716d771-4634-4651-b198-a9f225105b1e",
					"created_datetime": "2022-03-18T02:44:07.468Z",
					"updated_datetime": "2022-03-18T02:44:07.468Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.39421752095222473",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "1ab55088-a5e1-4028-8c4c-8f458977faf0",
					"created_datetime": "2022-03-18T02:44:07.471Z",
					"updated_datetime": "2022-03-18T02:44:07.471Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "5.530616760253906",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "ae7a5a75-bd9e-4706-bfb2-4472f522d4ed",
					"created_datetime": "2022-03-18T02:44:07.473Z",
					"updated_datetime": "2022-03-18T02:44:07.473Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-2.499202013015747",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "99d1cd38-7b7e-48d1-aa1d-e1ff4f34011d",
					"created_datetime": "2022-03-18T02:44:07.476Z",
					"updated_datetime": "2022-03-18T02:44:07.476Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "0.6595211029052734",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "2a3c3a8d-6750-4594-8ad3-3676a4ef2742",
					"created_datetime": "2022-03-18T02:44:07.479Z",
					"updated_datetime": "2022-03-18T02:44:07.479Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_hip",
					"value_string": "0.422309011220932",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "d8385a25-1c9d-4700-aadb-1d14afb6571b",
					"created_datetime": "2022-03-18T02:44:07.482Z",
					"updated_datetime": "2022-03-18T02:44:07.482Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-3.0176990032196045",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "f6c27d40-be9a-47ab-becb-297a15fb0a03",
					"created_datetime": "2022-03-18T02:44:07.486Z",
					"updated_datetime": "2022-03-18T02:44:07.486Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-2.555670738220215",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "ee83f955-1217-4ad3-8aa1-8ea96ecac0f1",
					"created_datetime": "2022-03-18T02:44:07.489Z",
					"updated_datetime": "2022-03-18T02:44:07.489Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "0.6420564651489258",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "855d8df3-e12f-4dc3-aeb2-18c19996fb21",
					"created_datetime": "2022-03-18T02:44:07.494Z",
					"updated_datetime": "2022-03-18T02:44:07.494Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_composite",
					"value_string": "0.7741048336029053",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "0b5e664d-d19d-4605-af20-8838c4f0a40e",
					"created_datetime": "2022-03-18T02:44:07.497Z",
					"updated_datetime": "2022-03-18T02:44:07.497Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility",
					"value_string": "0.8211896419525146",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "a03e6c67-578e-4b91-8e7c-5d64d2e668d9",
					"created_datetime": "2022-03-18T02:44:07.500Z",
					"updated_datetime": "2022-03-18T02:44:07.500Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability",
					"value_string": "0.6139561533927917",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "a9431b9d-dcf0-490d-8a7f-39663b68dd8c",
					"created_datetime": "2022-03-18T02:44:07.520Z",
					"updated_datetime": "2022-03-18T02:44:07.520Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_posture",
					"value_string": "0.9353082180023193",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "8b5ecab5-1dcf-4f51-a3ef-1a5ab6e79473",
					"created_datetime": "2022-03-18T02:44:07.523Z",
					"updated_datetime": "2022-03-18T02:44:07.523Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry",
					"value_string": "0.8680863380432129",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "9f62bfe3-975e-4f14-8eb2-416f497acd7e",
					"created_datetime": "2022-03-18T02:44:07.526Z",
					"updated_datetime": "2022-03-18T02:44:07.526Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.723273754119873",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "54906ed4-3964-4301-9ed2-35f6dd8a695d",
					"created_datetime": "2022-03-18T02:44:07.530Z",
					"updated_datetime": "2022-03-18T02:44:07.530Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.430034339427948",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "a6a7f911-6ca4-413c-99f2-1da562eaee14",
					"created_datetime": "2022-03-18T02:44:07.533Z",
					"updated_datetime": "2022-03-18T02:44:07.533Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8249413371086121",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "b6528b8d-3ebb-4e02-a538-cc599aba5927",
					"created_datetime": "2022-03-18T02:44:07.536Z",
					"updated_datetime": "2022-03-18T02:44:07.536Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.8080077171325684",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "393d032f-4075-4ba1-ad17-59db62599ea2",
					"created_datetime": "2022-03-18T02:44:07.540Z",
					"updated_datetime": "2022-03-18T02:44:07.540Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.681963324546814",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "913e02a0-cd43-44d0-8ae8-b6dbc813fe24",
					"created_datetime": "2022-03-18T02:44:07.543Z",
					"updated_datetime": "2022-03-18T02:44:07.543Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.6784677505493164",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "807f06dd-0071-4ab6-8220-55188252e5a7",
					"created_datetime": "2022-03-18T02:44:07.545Z",
					"updated_datetime": "2022-03-18T02:44:07.545Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.9304962158203125",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "346e4dad-6124-44ec-91e5-e036f475402b",
					"created_datetime": "2022-03-18T02:44:07.548Z",
					"updated_datetime": "2022-03-18T02:44:07.548Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8893418312072754",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "f26954ef-57dc-4cea-a7df-69f24984f45d",
					"created_datetime": "2022-03-18T02:44:07.551Z",
					"updated_datetime": "2022-03-18T02:44:07.551Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8986859321594238",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "54673d4e-89aa-43b8-95ec-73c723b76eae",
					"created_datetime": "2022-03-18T02:44:07.554Z",
					"updated_datetime": "2022-03-18T02:44:07.554Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8712757229804993",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "6d557b2d-8060-4d1c-a4b4-393e642dd6fd",
					"created_datetime": "2022-03-18T02:44:07.557Z",
					"updated_datetime": "2022-03-18T02:44:07.557Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.7262642979621887",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "33738cd4-ab4a-4cf7-97b5-3d2a253b947c",
					"created_datetime": "2022-03-18T02:44:07.559Z",
					"updated_datetime": "2022-03-18T02:44:07.559Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.7567169666290283",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "5e42e25d-38f0-4ee7-bdd5-2720f7eb5fce",
					"created_datetime": "2022-03-18T02:44:07.562Z",
					"updated_datetime": "2022-03-18T02:44:07.562Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability_scap_left",
					"value_string": "0.12546706199645996",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "5b1cd09f-70ae-4721-a690-087a2792a670",
					"created_datetime": "2022-03-18T02:44:07.565Z",
					"updated_datetime": "2022-03-18T02:44:07.565Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability_scap_right",
					"value_string": "0.30523139238357544",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "3feb6c96-c232-4765-b3b9-2eb67e5d0f3e",
					"created_datetime": "2022-03-18T02:44:07.568Z",
					"updated_datetime": "2022-03-18T02:44:07.568Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5379157066345215",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "80245529-1680-43ec-9712-18abd7651b28",
					"created_datetime": "2022-03-18T02:44:07.570Z",
					"updated_datetime": "2022-03-18T02:44:07.570Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.9933316707611084",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "4aa0e728-f199-4303-8ae8-314ab01e6677",
					"created_datetime": "2022-03-18T02:44:07.573Z",
					"updated_datetime": "2022-03-18T02:44:07.573Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9903357028961182",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "9f10900b-59d8-46a8-9ecb-c8f47db9768f",
					"created_datetime": "2022-03-18T02:44:07.576Z",
					"updated_datetime": "2022-03-18T02:44:07.576Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9602572321891785",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "2e2dd5ed-0d25-4b7a-9197-08d74c0e929a",
					"created_datetime": "2022-03-18T02:44:07.578Z",
					"updated_datetime": "2022-03-18T02:44:07.578Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.910359263420105",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "c477d1ee-1488-4f2d-aec4-af7e921a9786",
					"created_datetime": "2022-03-18T02:44:07.581Z",
					"updated_datetime": "2022-03-18T02:44:07.581Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.4087728261947632",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "d43cabfb-6443-4856-9506-0680860546b4",
					"created_datetime": "2022-03-18T02:44:07.584Z",
					"updated_datetime": "2022-03-18T02:44:07.584Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8414219617843628",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "6645912f-b3cd-4e42-b06f-a6e42bc7f382",
					"created_datetime": "2022-03-18T02:44:07.587Z",
					"updated_datetime": "2022-03-18T02:44:07.587Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9929043054580688",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "c8911ad4-36a8-4f1c-b92f-a241424abf2c",
					"created_datetime": "2022-03-18T02:44:07.590Z",
					"updated_datetime": "2022-03-18T02:44:07.590Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9946984648704529",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "5ef8f102-eddd-4dae-9c97-446484591e82",
					"created_datetime": "2022-03-18T02:44:07.593Z",
					"updated_datetime": "2022-03-18T02:44:07.593Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7585493326187134",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "21b5568f-dfb2-4625-aa04-0f78b3fd9587",
					"created_datetime": "2022-03-18T02:44:07.596Z",
					"updated_datetime": "2022-03-18T02:44:07.596Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9471397399902344",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "eb67ac47-b356-44e6-b4b2-99f96950e3ac",
					"created_datetime": "2022-03-18T02:44:07.599Z",
					"updated_datetime": "2022-03-18T02:44:07.599Z",
					"assessment_id_guid": "ebd64d5a-9e9e-47c1-83ab-d13f0a81bb67",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9719559550285339",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
			"created_datetime": "2022-03-18T03:16:15.574Z",
			"updated_datetime": "2022-03-18T03:16:15.574Z",
			"submitter_user_id_guid": "6f2314b7-fbb3-4ec4-9f83-72b46ee46ebe",
			"athlete_user_id_guid": "6918961f-1325-4e75-aa88-54b15ba28d5c",
			"external_id_guid": "61b46f76-a7c5-489f-9ae9-2f273e25bc0b",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "401a5259-8356-468b-956e-a7cef85249a5",
					"created_datetime": "2022-03-18T03:16:15.579Z",
					"updated_datetime": "2022-03-18T03:16:15.579Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T03:19:04.378Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "3f2eb107-b077-48f3-8054-4d09e186ef12",
					"created_datetime": "2022-03-18T03:16:15.584Z",
					"updated_datetime": "2022-03-18T03:16:15.584Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "49ce06f5-dad9-4eef-a532-6cb1687c501a",
					"created_datetime": "2022-03-18T03:16:15.587Z",
					"updated_datetime": "2022-03-18T03:16:15.587Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "102.11141735049455",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "4de541f3-e2a9-4648-8150-066664ca843d",
					"created_datetime": "2022-03-18T03:16:15.591Z",
					"updated_datetime": "2022-03-18T03:16:15.591Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "18.358493535178837",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "8f73a0fe-35c0-45f0-801c-9c6cd3d99fab",
					"created_datetime": "2022-03-18T03:16:15.594Z",
					"updated_datetime": "2022-03-18T03:16:15.594Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "19.713380379081713",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "2c197d5e-efee-42ff-a4e1-1f52ccdedebb",
					"created_datetime": "2022-03-18T03:16:15.598Z",
					"updated_datetime": "2022-03-18T03:16:15.598Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "139.08235562027664",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "cdc13f4e-2933-412b-a37a-4314cb85daff",
					"created_datetime": "2022-03-18T03:16:15.601Z",
					"updated_datetime": "2022-03-18T03:16:15.601Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "30.53243234451452",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "1b2fa676-42d0-4259-b41f-47a760506609",
					"created_datetime": "2022-03-18T03:16:15.604Z",
					"updated_datetime": "2022-03-18T03:16:15.604Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-118.73245455172356",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "f6fc9b1d-9587-45e5-b4ec-76927d5863a5",
					"created_datetime": "2022-03-18T03:16:15.607Z",
					"updated_datetime": "2022-03-18T03:16:15.607Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-170.4229113476708",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "b1506a96-ac10-4304-923b-a3b8c8a3a2e7",
					"created_datetime": "2022-03-18T03:16:15.610Z",
					"updated_datetime": "2022-03-18T03:16:15.610Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-153.30452160309952",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "42f445c8-9543-4e1f-b457-9e65d75567c6",
					"created_datetime": "2022-03-18T03:16:15.617Z",
					"updated_datetime": "2022-03-18T03:16:15.617Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-63.261747191327814",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "ab2c994b-0210-4d3b-9452-0f362509696f",
					"created_datetime": "2022-03-18T03:16:15.620Z",
					"updated_datetime": "2022-03-18T03:16:15.620Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "128.73871906538255",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "c90ddd60-add4-4486-8c73-b2359005b09c",
					"created_datetime": "2022-03-18T03:16:15.623Z",
					"updated_datetime": "2022-03-18T03:16:15.623Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "147.6336866554887",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "9de5a419-25b5-4d5b-8ab7-9e8bfc8087e9",
					"created_datetime": "2022-03-18T03:16:15.625Z",
					"updated_datetime": "2022-03-18T03:16:15.625Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "70.57926941030374",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6ec43fce-c6fb-4402-a7dd-e32f774d5125",
					"created_datetime": "2022-03-18T03:16:15.628Z",
					"updated_datetime": "2022-03-18T03:16:15.628Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_stability_scap_left",
					"value_string": "122.39628384086507",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "f1d94862-92a2-4fec-a42e-ec8829f9dd1a",
					"created_datetime": "2022-03-18T03:16:15.631Z",
					"updated_datetime": "2022-03-18T03:16:15.631Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_stability_scap_right",
					"value_string": "63.52599521841057",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7c6ce56f-0af7-45da-976d-b93952dc688c",
					"created_datetime": "2022-03-18T03:16:15.634Z",
					"updated_datetime": "2022-03-18T03:16:15.634Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-54.5676141730664",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "f36ec732-f4ff-4f45-be65-b36823e3fbf9",
					"created_datetime": "2022-03-18T03:16:15.637Z",
					"updated_datetime": "2022-03-18T03:16:15.637Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "12.890505982556164",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "f5428f4d-f8a5-4778-9549-59715eb41052",
					"created_datetime": "2022-03-18T03:16:15.640Z",
					"updated_datetime": "2022-03-18T03:16:15.640Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "20.002512045719698",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "95194d44-cd4f-4a18-8ad3-e311f4a82fc3",
					"created_datetime": "2022-03-18T03:16:15.643Z",
					"updated_datetime": "2022-03-18T03:16:15.643Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "70.14921534868",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "ea9a4953-1d8f-4b38-ad43-8f841c0f2e39",
					"created_datetime": "2022-03-18T03:16:15.646Z",
					"updated_datetime": "2022-03-18T03:16:15.646Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-20.99022256760432",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "7f268ca0-ea1d-4466-961e-79950a545861",
					"created_datetime": "2022-03-18T03:16:15.649Z",
					"updated_datetime": "2022-03-18T03:16:15.649Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "43.323531220043606",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "38776af9-799e-4366-88ff-a8bfec953d97",
					"created_datetime": "2022-03-18T03:16:15.652Z",
					"updated_datetime": "2022-03-18T03:16:15.652Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-167.6148561693408",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "fe512e90-51a6-490a-b389-a04c7a87680a",
					"created_datetime": "2022-03-18T03:16:15.654Z",
					"updated_datetime": "2022-03-18T03:16:15.655Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-102.40624095964853",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "23ec3cf7-7933-4b5e-b563-6e749f976580",
					"created_datetime": "2022-03-18T03:16:15.657Z",
					"updated_datetime": "2022-03-18T03:16:15.657Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_hip",
					"value_string": "45.66572142350017",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "b4da9877-4357-4f3f-a4bc-cd85855a1488",
					"created_datetime": "2022-03-18T03:16:15.660Z",
					"updated_datetime": "2022-03-18T03:16:15.660Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-164.90608857589828",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "aab74a7c-6fdf-4bd9-abe1-bd19665e2027",
					"created_datetime": "2022-03-18T03:16:15.663Z",
					"updated_datetime": "2022-03-18T03:16:15.663Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-53.393140507587276",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "13d74c44-9279-4684-8aab-7eee1c202ed4",
					"created_datetime": "2022-03-18T03:16:15.666Z",
					"updated_datetime": "2022-03-18T03:16:15.666Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "69.87727201626319",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "b36aee40-204a-4e1f-a10b-8d318c47c9bc",
					"created_datetime": "2022-03-18T03:16:15.669Z",
					"updated_datetime": "2022-03-18T03:16:15.669Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_composite",
					"value_string": "0.793539411169153",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "a21c79ca-67b7-48a6-83ac-21b8b988a39c",
					"created_datetime": "2022-03-18T03:16:15.672Z",
					"updated_datetime": "2022-03-18T03:16:15.672Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility",
					"value_string": "0.3257857307776934",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "637ef6a7-23b2-4e67-a6ab-577296dcc230",
					"created_datetime": "2022-03-18T03:16:15.674Z",
					"updated_datetime": "2022-03-18T03:16:15.674Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability",
					"value_string": "0.9553410118053164",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "ac17b4d7-9ebf-4fe9-91a4-33af253a6c59",
					"created_datetime": "2022-03-18T03:16:15.677Z",
					"updated_datetime": "2022-03-18T03:16:15.677Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_posture",
					"value_string": "0.07538016594236908",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "9db1dafd-0993-4e8c-bf54-45c4b542820c",
					"created_datetime": "2022-03-18T03:16:15.681Z",
					"updated_datetime": "2022-03-18T03:16:15.681Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry",
					"value_string": "0.44151876774062926",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "d6ca2b58-bcfa-4e17-8648-523440377cc7",
					"created_datetime": "2022-03-18T03:16:15.684Z",
					"updated_datetime": "2022-03-18T03:16:15.684Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.3008661232495404",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "b57853a1-0268-42a0-b380-2749b58c4580",
					"created_datetime": "2022-03-18T03:16:15.688Z",
					"updated_datetime": "2022-03-18T03:16:15.688Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.46949971389851003",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "612db155-4ef0-4e7e-9194-7097492b53c7",
					"created_datetime": "2022-03-18T03:16:15.691Z",
					"updated_datetime": "2022-03-18T03:16:15.691Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.783819222686257",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "1b59b800-958e-4108-a541-d53f933ebceb",
					"created_datetime": "2022-03-18T03:16:15.694Z",
					"updated_datetime": "2022-03-18T03:16:15.694Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.6550504376194993",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "4e41e363-55ab-4074-8d95-d65c52a51c30",
					"created_datetime": "2022-03-18T03:16:15.697Z",
					"updated_datetime": "2022-03-18T03:16:15.697Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.7594419335331555",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "da03df85-2e02-47f5-951b-0485e34366d6",
					"created_datetime": "2022-03-18T03:16:15.700Z",
					"updated_datetime": "2022-03-18T03:16:15.700Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.32383750346918627",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "25ac2a5f-e501-43ba-a0cb-6028af8053cc",
					"created_datetime": "2022-03-18T03:16:15.703Z",
					"updated_datetime": "2022-03-18T03:16:15.703Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.750930563152636",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "971eca5f-9add-49cd-80e1-5e449fbb0027",
					"created_datetime": "2022-03-18T03:16:15.706Z",
					"updated_datetime": "2022-03-18T03:16:15.706Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8277568752182559",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "6d153093-3556-4795-91da-c68c84896691",
					"created_datetime": "2022-03-18T03:16:15.708Z",
					"updated_datetime": "2022-03-18T03:16:15.708Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.6656361598818099",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "b1388da3-d564-4342-bb18-9dec9e6284d3",
					"created_datetime": "2022-03-18T03:16:15.723Z",
					"updated_datetime": "2022-03-18T03:16:15.723Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.7488364898180911",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "90db0f85-99d0-403e-a446-0bb8ad1487c3",
					"created_datetime": "2022-03-18T03:16:15.726Z",
					"updated_datetime": "2022-03-18T03:16:15.726Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.7675224565598241",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "1f8593a2-ec64-4483-908a-d3ad1644a20a",
					"created_datetime": "2022-03-18T03:16:15.730Z",
					"updated_datetime": "2022-03-18T03:16:15.730Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.28634087284187887",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "31a89372-964a-4527-8505-9a5cddeeafb5",
					"created_datetime": "2022-03-18T03:16:15.733Z",
					"updated_datetime": "2022-03-18T03:16:15.733Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability_scap_left",
					"value_string": "0.04435269687915727",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "20a81893-3f3c-4787-b05e-2149eb61f904",
					"created_datetime": "2022-03-18T03:16:15.736Z",
					"updated_datetime": "2022-03-18T03:16:15.736Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability_scap_right",
					"value_string": "0.40580124957578795",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "573eee90-bab7-48f6-992b-e71ca7446060",
					"created_datetime": "2022-03-18T03:16:15.739Z",
					"updated_datetime": "2022-03-18T03:16:15.739Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.909656267479949",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "12eb7b4b-5e51-47c7-843f-8df4f1c83db9",
					"created_datetime": "2022-03-18T03:16:15.742Z",
					"updated_datetime": "2022-03-18T03:16:15.742Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.2334972302295225",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "7dc0e34a-81e3-4c80-b0bd-5922f125ad5d",
					"created_datetime": "2022-03-18T03:16:15.745Z",
					"updated_datetime": "2022-03-18T03:16:15.745Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9386579540155106",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "30c5fdeb-f87e-4a04-9884-6e9193e471fb",
					"created_datetime": "2022-03-18T03:16:15.748Z",
					"updated_datetime": "2022-03-18T03:16:15.748Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.9849537665229721",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "1522e4e9-abd3-42a8-a33b-692a9f11b108",
					"created_datetime": "2022-03-18T03:16:15.752Z",
					"updated_datetime": "2022-03-18T03:16:15.752Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.3795119455343259",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "0ee7ddd7-d703-45f5-b993-edebc24a89d3",
					"created_datetime": "2022-03-18T03:16:15.755Z",
					"updated_datetime": "2022-03-18T03:16:15.755Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.7496966366706125",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "cb8b9a46-e190-4523-b031-5cd86e59a475",
					"created_datetime": "2022-03-18T03:16:15.758Z",
					"updated_datetime": "2022-03-18T03:16:15.758Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.47042179570034814",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "323e7697-fe2c-47a5-8881-62ee47e83ecb",
					"created_datetime": "2022-03-18T03:16:15.761Z",
					"updated_datetime": "2022-03-18T03:16:15.761Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.13982662851993313",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "a66106a3-dc93-4fa2-950c-aedcd08684e8",
					"created_datetime": "2022-03-18T03:16:15.764Z",
					"updated_datetime": "2022-03-18T03:16:15.764Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_hip",
					"value_string": "0.33944386471581633",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "08c7c0c1-303c-4eb9-8b38-a47e9e862d62",
					"created_datetime": "2022-03-18T03:16:15.767Z",
					"updated_datetime": "2022-03-18T03:16:15.767Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7094759742406249",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "55bd53c1-69df-48e8-b3e2-405f1ddb5f45",
					"created_datetime": "2022-03-18T03:16:15.770Z",
					"updated_datetime": "2022-03-18T03:16:15.770Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_knee",
					"value_string": "0.4331120515323935",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "a6b19118-c414-44e5-b3b3-f27e80df50a0",
					"created_datetime": "2022-03-18T03:16:15.773Z",
					"updated_datetime": "2022-03-18T03:16:15.773Z",
					"assessment_id_guid": "a31fe2ed-4077-481d-be47-db0cd1d9b1ef",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.5339292426464067",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
			"created_datetime": "2022-03-18T03:16:27.740Z",
			"updated_datetime": "2022-03-18T03:16:27.740Z",
			"submitter_user_id_guid": "6f2314b7-fbb3-4ec4-9f83-72b46ee46ebe",
			"athlete_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"external_id_guid": "e396ef0b-6f21-45e6-858f-0c5dd2b8e8ca",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "fe142896-ae2b-4741-83f9-63d21d8c2089",
					"created_datetime": "2022-03-18T03:16:27.744Z",
					"updated_datetime": "2022-03-18T03:16:27.744Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T03:19:16.562Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "14a25e24-ac15-4b4f-8b5a-844c3dc4147f",
					"created_datetime": "2022-03-18T03:16:27.749Z",
					"updated_datetime": "2022-03-18T03:16:27.749Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "ff55e48d-1fb0-45ae-be55-0faebe3326a1",
					"created_datetime": "2022-03-18T03:16:27.752Z",
					"updated_datetime": "2022-03-18T03:16:27.752Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-80.02343226235062",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "2d7c15ea-ec54-41d5-82ef-71564d7ce5a4",
					"created_datetime": "2022-03-18T03:16:27.755Z",
					"updated_datetime": "2022-03-18T03:16:27.755Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-9.727603035235774",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "0e98a3f6-6e94-404b-bbeb-ee62861fa614",
					"created_datetime": "2022-03-18T03:16:27.758Z",
					"updated_datetime": "2022-03-18T03:16:27.758Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-40.60060043687193",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "f804e7cf-c1f6-4327-909e-03e695cf3c1a",
					"created_datetime": "2022-03-18T03:16:27.761Z",
					"updated_datetime": "2022-03-18T03:16:27.761Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-156.9717028398276",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "d27b717b-9b2a-4f53-80b8-62fb93a4921f",
					"created_datetime": "2022-03-18T03:16:27.765Z",
					"updated_datetime": "2022-03-18T03:16:27.765Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "124.4202844248278",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "9fce0634-6c76-49df-9ff8-8990bb705b3b",
					"created_datetime": "2022-03-18T03:16:27.768Z",
					"updated_datetime": "2022-03-18T03:16:27.768Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-68.62222130818581",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "31ae8bfe-dc0d-486f-a9b5-0afe66bfcd70",
					"created_datetime": "2022-03-18T03:16:27.771Z",
					"updated_datetime": "2022-03-18T03:16:27.771Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "24.943898395683874",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "84e5998d-58da-46ee-a9a5-4a48eb38902e",
					"created_datetime": "2022-03-18T03:16:27.774Z",
					"updated_datetime": "2022-03-18T03:16:27.774Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-12.639807778933374",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "b7d52dda-43b6-4578-be65-6d7b40219d58",
					"created_datetime": "2022-03-18T03:16:27.777Z",
					"updated_datetime": "2022-03-18T03:16:27.777Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "97.07549307922602",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "3e57af3d-1a0a-4181-8912-ff84a3fa3117",
					"created_datetime": "2022-03-18T03:16:27.780Z",
					"updated_datetime": "2022-03-18T03:16:27.780Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-82.22956782063835",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "55526aeb-ab29-4085-825c-8ce1e12865a3",
					"created_datetime": "2022-03-18T03:16:27.783Z",
					"updated_datetime": "2022-03-18T03:16:27.783Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-58.62570739795022",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "fab93b99-8dcf-4f57-b1e1-a412eb614fee",
					"created_datetime": "2022-03-18T03:16:27.785Z",
					"updated_datetime": "2022-03-18T03:16:27.785Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-96.63521086488406",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "3e4d3fab-323e-40d0-b963-eb12992e38be",
					"created_datetime": "2022-03-18T03:16:27.788Z",
					"updated_datetime": "2022-03-18T03:16:27.788Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_stability_scap_left",
					"value_string": "82.1965061964554",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "aababcb6-5bc6-4929-bf15-463f3466e3c2",
					"created_datetime": "2022-03-18T03:16:27.791Z",
					"updated_datetime": "2022-03-18T03:16:27.791Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-71.9059487764357",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "a8e05a21-dfd1-4dcf-84f7-243592a99a51",
					"created_datetime": "2022-03-18T03:16:27.794Z",
					"updated_datetime": "2022-03-18T03:16:27.794Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-51.10961299939876",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "bad87adf-7ceb-4f70-8e45-6a4ec8b574a8",
					"created_datetime": "2022-03-18T03:16:27.797Z",
					"updated_datetime": "2022-03-18T03:16:27.797Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "139.86666131872676",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "8ecc5adf-25e9-42f2-bfc5-669439b39f5d",
					"created_datetime": "2022-03-18T03:16:27.800Z",
					"updated_datetime": "2022-03-18T03:16:27.800Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "77.06819096719767",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "a8c899e7-13e0-4120-995e-65a26434eeed",
					"created_datetime": "2022-03-18T03:16:27.803Z",
					"updated_datetime": "2022-03-18T03:16:27.803Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-62.11158269193457",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "affb709f-09cb-4d12-8b12-098421f00f91",
					"created_datetime": "2022-03-18T03:16:27.806Z",
					"updated_datetime": "2022-03-18T03:16:27.806Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-19.468447639998914",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "54e1575b-3783-41bb-a02a-d7df00d0342b",
					"created_datetime": "2022-03-18T03:16:27.809Z",
					"updated_datetime": "2022-03-18T03:16:27.809Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "12.082525798655098",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "711a61a0-25a7-4b66-8c74-b58b980c5294",
					"created_datetime": "2022-03-18T03:16:27.812Z",
					"updated_datetime": "2022-03-18T03:16:27.812Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "24.980266946889003",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "d8f389f1-7633-4582-b35e-5f9037398eb8",
					"created_datetime": "2022-03-18T03:16:27.815Z",
					"updated_datetime": "2022-03-18T03:16:27.815Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-20.84658471665466",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "4ab9c9af-f0b9-4f8e-805f-475af597b9aa",
					"created_datetime": "2022-03-18T03:16:27.817Z",
					"updated_datetime": "2022-03-18T03:16:27.817Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-0.971999247796191",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "1dbf0c2d-96fb-45d4-8f84-bd929dc2f873",
					"created_datetime": "2022-03-18T03:16:27.821Z",
					"updated_datetime": "2022-03-18T03:16:27.821Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_femur",
					"value_string": "92.94089488629606",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "3155b9d2-f6a3-4ae5-96ab-381f533a3803",
					"created_datetime": "2022-03-18T03:16:27.824Z",
					"updated_datetime": "2022-03-18T03:16:27.824Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-57.14258531474928",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "b53ef143-49b7-4a52-b63b-cc544210183c",
					"created_datetime": "2022-03-18T03:16:27.826Z",
					"updated_datetime": "2022-03-18T03:16:27.826Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "67.27332353509647",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "7febc183-846e-4a1d-8cd6-6c5162666e88",
					"created_datetime": "2022-03-18T03:16:27.830Z",
					"updated_datetime": "2022-03-18T03:16:27.830Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_composite",
					"value_string": "0.5420343458327759",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "2357ecb9-9658-4468-8ef2-57f3a71be562",
					"created_datetime": "2022-03-18T03:16:27.832Z",
					"updated_datetime": "2022-03-18T03:16:27.832Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility",
					"value_string": "0.8408438213791029",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "74648dfa-2230-4111-94bc-e759ddc2f62b",
					"created_datetime": "2022-03-18T03:16:27.835Z",
					"updated_datetime": "2022-03-18T03:16:27.835Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability",
					"value_string": "0.3600018191290619",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "5ed1488d-781e-481a-821a-36cf5eef90ee",
					"created_datetime": "2022-03-18T03:16:27.839Z",
					"updated_datetime": "2022-03-18T03:16:27.839Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_posture",
					"value_string": "0.794709209473441",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "ca8cca69-0db4-4b8e-baad-c4390a6b0e74",
					"created_datetime": "2022-03-18T03:16:27.843Z",
					"updated_datetime": "2022-03-18T03:16:27.843Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry",
					"value_string": "0.3179868480313235",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "eb2a63c1-21b4-4ee0-8a15-6488fc5e0bfe",
					"created_datetime": "2022-03-18T03:16:27.847Z",
					"updated_datetime": "2022-03-18T03:16:27.847Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.3729571129211811",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "5191fa8a-8760-4c45-9e9d-f02272018654",
					"created_datetime": "2022-03-18T03:16:27.853Z",
					"updated_datetime": "2022-03-18T03:16:27.853Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.3540612330340121",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "55babf37-878f-42c7-8420-01e77deeafd8",
					"created_datetime": "2022-03-18T03:16:27.856Z",
					"updated_datetime": "2022-03-18T03:16:27.856Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.8798320616956148",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "b25ab01d-030d-4852-8a09-e71fbb60b6f1",
					"created_datetime": "2022-03-18T03:16:27.860Z",
					"updated_datetime": "2022-03-18T03:16:27.860Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.943150159838704",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "8a178e26-5abb-4ca6-b369-f950b357cb29",
					"created_datetime": "2022-03-18T03:16:27.866Z",
					"updated_datetime": "2022-03-18T03:16:27.866Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.2052996288729183",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "18325251-0e94-4d12-a8a3-295e2f1382d8",
					"created_datetime": "2022-03-18T03:16:27.870Z",
					"updated_datetime": "2022-03-18T03:16:27.870Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.3267942135240594",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "b423de11-8157-4080-ad57-cd2555925d7d",
					"created_datetime": "2022-03-18T03:16:27.872Z",
					"updated_datetime": "2022-03-18T03:16:27.872Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.13007589940695005",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "16714d1e-c184-4b8f-b7c4-81294a756245",
					"created_datetime": "2022-03-18T03:16:27.875Z",
					"updated_datetime": "2022-03-18T03:16:27.875Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.12062037123319462",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "2c6a5438-f5bc-4aee-b5d0-85a36eb6c4f3",
					"created_datetime": "2022-03-18T03:16:27.878Z",
					"updated_datetime": "2022-03-18T03:16:27.878Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.1490552750141249",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "108aa922-cb3e-4a33-8a21-08f87f95fea9",
					"created_datetime": "2022-03-18T03:16:27.880Z",
					"updated_datetime": "2022-03-18T03:16:27.880Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.3063592586927484",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "7a509892-4f12-46a8-9601-67d083596f63",
					"created_datetime": "2022-03-18T03:16:27.883Z",
					"updated_datetime": "2022-03-18T03:16:27.883Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.9699188541542024",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "b748b98e-a49b-43e6-aa97-8a910ce562f6",
					"created_datetime": "2022-03-18T03:16:27.886Z",
					"updated_datetime": "2022-03-18T03:16:27.886Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.26368017306815245",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "08ee62b9-c9b7-4fbb-a93e-4d0ae0801016",
					"created_datetime": "2022-03-18T03:16:27.888Z",
					"updated_datetime": "2022-03-18T03:16:27.888Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9544990721755704",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "c7000393-e035-4d94-9bee-cf9ec8ac5bbe",
					"created_datetime": "2022-03-18T03:16:27.891Z",
					"updated_datetime": "2022-03-18T03:16:27.891Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability_scap_right",
					"value_string": "0.20681036414316106",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "0e5c9123-cdd1-4805-8b30-763f15768402",
					"created_datetime": "2022-03-18T03:16:27.894Z",
					"updated_datetime": "2022-03-18T03:16:27.894Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.859218094686013",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "8b6bd124-100f-4526-914a-2615e6bf0b32",
					"created_datetime": "2022-03-18T03:16:27.896Z",
					"updated_datetime": "2022-03-18T03:16:27.896Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.7346309057321219",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "9906775b-3f37-4dc8-97b7-bff70e87084e",
					"created_datetime": "2022-03-18T03:16:27.899Z",
					"updated_datetime": "2022-03-18T03:16:27.899Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.39909498901301466",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "cf83b983-92dc-4c9d-9f11-9e3a9a3bf6ee",
					"created_datetime": "2022-03-18T03:16:27.902Z",
					"updated_datetime": "2022-03-18T03:16:27.902Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.5332285813437662",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "c1198db1-ac48-427b-ae3d-00c81ad3438a",
					"created_datetime": "2022-03-18T03:16:27.905Z",
					"updated_datetime": "2022-03-18T03:16:27.905Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.5320021431482315",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "6258618d-c494-49c2-8b4a-75aa085d0d95",
					"created_datetime": "2022-03-18T03:16:27.908Z",
					"updated_datetime": "2022-03-18T03:16:27.909Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.760470207102528",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "8313035b-d837-4675-980c-2b8637244610",
					"created_datetime": "2022-03-18T03:16:27.912Z",
					"updated_datetime": "2022-03-18T03:16:27.912Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.6493430729635997",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "f1107872-5183-4b62-b5c6-2f70072b8017",
					"created_datetime": "2022-03-18T03:16:27.915Z",
					"updated_datetime": "2022-03-18T03:16:27.915Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.6696298457985737",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "53cd5ecb-1136-4dd4-adbc-c5163fc90436",
					"created_datetime": "2022-03-18T03:16:27.918Z",
					"updated_datetime": "2022-03-18T03:16:27.918Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_hip",
					"value_string": "0.0019842190160442087",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "68efcc74-8773-44b4-bbdd-550e16cb6a81",
					"created_datetime": "2022-03-18T03:16:27.921Z",
					"updated_datetime": "2022-03-18T03:16:27.921Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_femur",
					"value_string": "0.23072362752158182",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "614c22e9-825c-409b-82c5-a0f9fb4a198e",
					"created_datetime": "2022-03-18T03:16:27.924Z",
					"updated_datetime": "2022-03-18T03:16:27.924Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_knee",
					"value_string": "0.8569387920973423",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "e8efde01-2f8a-4281-a2bb-73a5227b94f0",
					"created_datetime": "2022-03-18T03:16:27.927Z",
					"updated_datetime": "2022-03-18T03:16:27.927Z",
					"assessment_id_guid": "7a1758ff-2ade-4082-a058-9b6912f007fa",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.37833963778906526",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
			"created_datetime": "2022-03-18T03:55:17.107Z",
			"updated_datetime": "2022-03-18T03:55:17.107Z",
			"submitter_user_id_guid": "123a1846-b493-4d2b-938d-ef8d1a73981e",
			"athlete_user_id_guid": "1e2f3941-95d0-48d0-be2a-2cf1cbddd635",
			"external_id_guid": "7761b8ff-83d3-4bae-9f40-645870c6602e",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "450ce06e-dc07-475f-bcc1-41218a0a5773",
					"created_datetime": "2022-03-18T03:55:17.113Z",
					"updated_datetime": "2022-03-18T03:55:17.113Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T03:55:16.896Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "9e83da77-30c6-4485-88bd-4791d876731f",
					"created_datetime": "2022-03-18T03:55:17.117Z",
					"updated_datetime": "2022-03-18T03:55:17.117Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "50460300-4c10-47dc-b8ad-8afb096f61bd",
					"created_datetime": "2022-03-18T03:55:17.120Z",
					"updated_datetime": "2022-03-18T03:55:17.120Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "156.3192596435547",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "b75affc7-91e9-49da-ad8d-5ffa76bf6a52",
					"created_datetime": "2022-03-18T03:55:17.123Z",
					"updated_datetime": "2022-03-18T03:55:17.123Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "157.6527557373047",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "4d022e54-1a0d-45d3-b092-6324aa377efb",
					"created_datetime": "2022-03-18T03:55:17.127Z",
					"updated_datetime": "2022-03-18T03:55:17.127Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "154.030517578125",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "5a52416b-45db-4ff8-abfb-6eff9e0761e2",
					"created_datetime": "2022-03-18T03:55:17.130Z",
					"updated_datetime": "2022-03-18T03:55:17.130Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "156.6110382080078",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "20eec31b-a7a3-44eb-bee7-828262456205",
					"created_datetime": "2022-03-18T03:55:17.133Z",
					"updated_datetime": "2022-03-18T03:55:17.133Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "90.21978759765625",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "dd4505a4-b34a-4b6d-a459-a99666b0dd6e",
					"created_datetime": "2022-03-18T03:55:17.136Z",
					"updated_datetime": "2022-03-18T03:55:17.136Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "89.57244873046875",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "2cb6d4da-56ba-414f-8826-0d00b0a9f77a",
					"created_datetime": "2022-03-18T03:55:17.140Z",
					"updated_datetime": "2022-03-18T03:55:17.140Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "51.43017578125",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "aa5feb0e-e036-4d0b-ac41-bfafc9049883",
					"created_datetime": "2022-03-18T03:55:17.143Z",
					"updated_datetime": "2022-03-18T03:55:17.143Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "52.845062255859375",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "ce121aed-e261-4ad8-93bd-ec9d689c824d",
					"created_datetime": "2022-03-18T03:55:17.146Z",
					"updated_datetime": "2022-03-18T03:55:17.146Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "90.93289184570312",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "88ce4cf6-5cdf-4f6c-bd53-2c41467bf4d6",
					"created_datetime": "2022-03-18T03:55:17.150Z",
					"updated_datetime": "2022-03-18T03:55:17.150Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "94.61003875732422",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "ca55c63c-0cf2-42f1-8c9f-f415586ccffc",
					"created_datetime": "2022-03-18T03:55:17.153Z",
					"updated_datetime": "2022-03-18T03:55:17.153Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "42.56356430053711",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "74ddaffa-5923-44c3-b2f4-9bf0b48edae1",
					"created_datetime": "2022-03-18T03:55:17.156Z",
					"updated_datetime": "2022-03-18T03:55:17.156Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "46.81051254272461",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "b01185a5-eb3a-4d5b-a82a-57c8b2d63fdf",
					"created_datetime": "2022-03-18T03:55:17.159Z",
					"updated_datetime": "2022-03-18T03:55:17.159Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_stability_scap_left",
					"value_string": "15.351094245910645",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "94552638-d007-42fe-b640-e0d03a97bc4a",
					"created_datetime": "2022-03-18T03:55:17.162Z",
					"updated_datetime": "2022-03-18T03:55:17.162Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_stability_scap_right",
					"value_string": "14.662943840026855",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "73c98818-08df-4af4-b8da-fca548138475",
					"created_datetime": "2022-03-18T03:55:17.165Z",
					"updated_datetime": "2022-03-18T03:55:17.165Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "38.357112884521484",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "2602442d-acff-4cd0-aa36-e9e5164bd5be",
					"created_datetime": "2022-03-18T03:55:17.169Z",
					"updated_datetime": "2022-03-18T03:55:17.169Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "12.204853057861328",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "935ef13b-f6e8-4779-9662-61401a0d1163",
					"created_datetime": "2022-03-18T03:55:17.172Z",
					"updated_datetime": "2022-03-18T03:55:17.172Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "13.580955505371094",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "01940c0a-5fe5-4503-8c17-cc0600252cfe",
					"created_datetime": "2022-03-18T03:55:17.175Z",
					"updated_datetime": "2022-03-18T03:55:17.175Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "2.720608711242676",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "39f14dd0-ff6f-47e8-afcb-c181c7dffecf",
					"created_datetime": "2022-03-18T03:55:17.185Z",
					"updated_datetime": "2022-03-18T03:55:17.185Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "1.7275480031967163",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "cd80666c-4b41-4667-a7be-7c3b69087207",
					"created_datetime": "2022-03-18T03:55:17.188Z",
					"updated_datetime": "2022-03-18T03:55:17.188Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-1.3832758665084839",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "318f0dda-90b9-40a8-8389-1b22e3a420a8",
					"created_datetime": "2022-03-18T03:55:17.192Z",
					"updated_datetime": "2022-03-18T03:55:17.192Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-3.2430734634399414",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "689b1191-e48f-4c8a-b7d4-d4015499a906",
					"created_datetime": "2022-03-18T03:55:17.194Z",
					"updated_datetime": "2022-03-18T03:55:17.194Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.6766186952590942",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "cefa9348-9ff1-4ba0-bcf1-632a09f0fc25",
					"created_datetime": "2022-03-18T03:55:17.198Z",
					"updated_datetime": "2022-03-18T03:55:17.198Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-4.676055908203125",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "ec75b22f-a46f-4f4a-a467-6cff8160d374",
					"created_datetime": "2022-03-18T03:55:17.201Z",
					"updated_datetime": "2022-03-18T03:55:17.201Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_femur",
					"value_string": "0.6662032008171082",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "cfa318ae-2ae0-4108-a61f-fd050ca8253d",
					"created_datetime": "2022-03-18T03:55:17.204Z",
					"updated_datetime": "2022-03-18T03:55:17.204Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_knee",
					"value_string": "5.894374847412109",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "30de3f94-9a71-4182-9401-6c2d08f8433c",
					"created_datetime": "2022-03-18T03:55:17.207Z",
					"updated_datetime": "2022-03-18T03:55:17.207Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "5.0909423828125",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "0791b9e9-bb17-4e1a-b08a-a230b1c27bcb",
					"created_datetime": "2022-03-18T03:55:17.210Z",
					"updated_datetime": "2022-03-18T03:55:17.210Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_composite",
					"value_string": "0.41862940788269043",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "7c6814d9-ed06-4e47-9697-4868cecfd65b",
					"created_datetime": "2022-03-18T03:55:17.214Z",
					"updated_datetime": "2022-03-18T03:55:17.214Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility",
					"value_string": "0.27816838026046753",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "316d434b-5b29-4df1-a0af-f8bfa2776a6e",
					"created_datetime": "2022-03-18T03:55:17.217Z",
					"updated_datetime": "2022-03-18T03:55:17.217Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability",
					"value_string": "0.43708616495132446",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "a81d41f1-7e2d-4609-b4ac-cba94e53c4d1",
					"created_datetime": "2022-03-18T03:55:17.220Z",
					"updated_datetime": "2022-03-18T03:55:17.220Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_posture",
					"value_string": "0.1714058518409729",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "0723f3c0-b36f-4090-9d52-513b69faee7f",
					"created_datetime": "2022-03-18T03:55:17.223Z",
					"updated_datetime": "2022-03-18T03:55:17.223Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry",
					"value_string": "0.7687873840332031",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "a8f8945f-cb74-4fa2-9f3f-a00be0a09012",
					"created_datetime": "2022-03-18T03:55:17.226Z",
					"updated_datetime": "2022-03-18T03:55:17.226Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.5350421667098999",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "8e85fa2c-f802-4b5e-9857-e9173f6cd152",
					"created_datetime": "2022-03-18T03:55:17.229Z",
					"updated_datetime": "2022-03-18T03:55:17.229Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5701869130134583",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "4e4c6f25-f310-4901-bac9-4f5706f88873",
					"created_datetime": "2022-03-18T03:55:17.232Z",
					"updated_datetime": "2022-03-18T03:55:17.232Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.47423338890075684",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "ff2dc8f2-016f-4f5b-afb8-50cbef6dd464",
					"created_datetime": "2022-03-18T03:55:17.235Z",
					"updated_datetime": "2022-03-18T03:55:17.235Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.5427653789520264",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "236f257f-4289-467e-9f1c-f149951805be",
					"created_datetime": "2022-03-18T03:55:17.238Z",
					"updated_datetime": "2022-03-18T03:55:17.238Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.23847746849060059",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "3327d2e3-4b94-4ae2-9e6b-eb4b955d3fe5",
					"created_datetime": "2022-03-18T03:55:17.241Z",
					"updated_datetime": "2022-03-18T03:55:17.241Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.23053008317947388",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "02758d1f-b352-4000-b11d-b1a991bd6e9c",
					"created_datetime": "2022-03-18T03:55:17.244Z",
					"updated_datetime": "2022-03-18T03:55:17.244Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.11552110314369202",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "24091ed9-1f02-4af1-a832-2499c5872e55",
					"created_datetime": "2022-03-18T03:55:17.247Z",
					"updated_datetime": "2022-03-18T03:55:17.247Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.13811194896697998",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "92ff68f7-e594-41c8-8fc1-68406b3d7eae",
					"created_datetime": "2022-03-18T03:55:17.250Z",
					"updated_datetime": "2022-03-18T03:55:17.250Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.11441946029663086",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "65d7f3dc-db1c-4e26-b681-216f10db46b3",
					"created_datetime": "2022-03-18T03:55:17.253Z",
					"updated_datetime": "2022-03-18T03:55:17.253Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.15398329496383667",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "4ba3940d-1301-420f-b6a1-52c78ce38f10",
					"created_datetime": "2022-03-18T03:55:17.256Z",
					"updated_datetime": "2022-03-18T03:55:17.256Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.2677266001701355",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "07e55072-a437-4ebc-a201-c47e139124c5",
					"created_datetime": "2022-03-18T03:55:17.259Z",
					"updated_datetime": "2022-03-18T03:55:17.259Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.395200252532959",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "2a57f41f-50b4-44a0-b4a0-5476ca0b4265",
					"created_datetime": "2022-03-18T03:55:17.262Z",
					"updated_datetime": "2022-03-18T03:55:17.262Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability_scap_left",
					"value_string": "0.6507731676101685",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "54ae632f-9099-4113-b5df-0c8c5ed43d0e",
					"created_datetime": "2022-03-18T03:55:17.265Z",
					"updated_datetime": "2022-03-18T03:55:17.265Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability_scap_right",
					"value_string": "0.6717513799667358",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "14ccdb0c-72f8-439f-ab29-7086abf1cecd",
					"created_datetime": "2022-03-18T03:55:17.268Z",
					"updated_datetime": "2022-03-18T03:55:17.268Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5652480125427246",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "66674d7c-5783-400a-a8a7-24718c3d5530",
					"created_datetime": "2022-03-18T03:55:17.271Z",
					"updated_datetime": "2022-03-18T03:55:17.271Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.1003580093383789",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "e255eb9c-3010-4e23-adb4-8b9748f98a51",
					"created_datetime": "2022-03-18T03:55:17.274Z",
					"updated_datetime": "2022-03-18T03:55:17.274Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.12674692273139954",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "57f5b271-38b1-4ae0-a5d7-ab3c73528e6c",
					"created_datetime": "2022-03-18T03:55:17.277Z",
					"updated_datetime": "2022-03-18T03:55:17.277Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.1531779170036316",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "e6f172e9-11b1-45dd-972a-01d3e9a06353",
					"created_datetime": "2022-03-18T03:55:17.280Z",
					"updated_datetime": "2022-03-18T03:55:17.280Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.1896337866783142",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "2e27db54-0c3f-4cd5-a28a-5f39bf0fa647",
					"created_datetime": "2022-03-18T03:55:17.284Z",
					"updated_datetime": "2022-03-18T03:55:17.284Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.9420813322067261",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "2c511b7b-9074-4fd8-82a3-de8c969c8876",
					"created_datetime": "2022-03-18T03:55:17.287Z",
					"updated_datetime": "2022-03-18T03:55:17.287Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.7589007616043091",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "592514d9-8d76-44fd-bcee-3a01d6c80948",
					"created_datetime": "2022-03-18T03:55:17.290Z",
					"updated_datetime": "2022-03-18T03:55:17.290Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9777932167053223",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "a9bc60ef-b8e0-4c5f-bf34-7ef61c1ed200",
					"created_datetime": "2022-03-18T03:55:17.292Z",
					"updated_datetime": "2022-03-18T03:55:17.292Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7599254846572876",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "2e30edbf-b103-4e6d-8a75-6e87740cb328",
					"created_datetime": "2022-03-18T03:55:17.296Z",
					"updated_datetime": "2022-03-18T03:55:17.296Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_femur",
					"value_string": "0.9913737773895264",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "4d7483f1-5c8f-42e6-bbe2-1dc95a5c81f8",
					"created_datetime": "2022-03-18T03:55:17.299Z",
					"updated_datetime": "2022-03-18T03:55:17.299Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_knee",
					"value_string": "0.6111240386962891",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "f9e07ac0-0f0c-41d4-b8d8-863cecf40548",
					"created_datetime": "2022-03-18T03:55:17.302Z",
					"updated_datetime": "2022-03-18T03:55:17.302Z",
					"assessment_id_guid": "e15dadb9-6965-4d4a-8fb8-5e4163ed367e",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.6035758852958679",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
			"created_datetime": "2022-03-18T03:59:03.888Z",
			"updated_datetime": "2022-03-18T03:59:03.888Z",
			"submitter_user_id_guid": "123a1846-b493-4d2b-938d-ef8d1a73981e",
			"athlete_user_id_guid": "d2909f8d-4fa0-4332-8624-cfe66fc6a73a",
			"external_id_guid": "05be81b0-1f82-4d3a-93d4-729068b3e452",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c7426788-52a1-4cb7-a79e-1bbf9ce001a5",
					"created_datetime": "2022-03-18T03:59:03.894Z",
					"updated_datetime": "2022-03-18T03:59:03.894Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T03:59:03.636Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "81000f0f-ee56-48c3-bf11-d03cd0aa8d1e",
					"created_datetime": "2022-03-18T03:59:03.898Z",
					"updated_datetime": "2022-03-18T03:59:03.898Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.6",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "7997ce42-8e89-4b9f-95dd-80d0e6937d0c",
					"created_datetime": "2022-03-18T03:59:03.904Z",
					"updated_datetime": "2022-03-18T03:59:03.904Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "177.8818817138672",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "fdc62ca7-aa3d-4c6b-aa31-3fe0955aad5a",
					"created_datetime": "2022-03-18T03:59:03.907Z",
					"updated_datetime": "2022-03-18T03:59:03.907Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "159.52182006835938",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "82e2391f-1191-4b20-b623-d184fda8356a",
					"created_datetime": "2022-03-18T03:59:03.911Z",
					"updated_datetime": "2022-03-18T03:59:03.911Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "141.8990936279297",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "d52ee8da-50bc-41ff-bdfb-763783df361d",
					"created_datetime": "2022-03-18T03:59:03.915Z",
					"updated_datetime": "2022-03-18T03:59:03.915Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "142.95550537109375",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "eda277b8-0d14-4485-97e3-78745bd7d118",
					"created_datetime": "2022-03-18T03:59:03.918Z",
					"updated_datetime": "2022-03-18T03:59:03.918Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "131.7828369140625",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "dbe4d1f2-63a6-4a67-91e2-baac3414967e",
					"created_datetime": "2022-03-18T03:59:03.922Z",
					"updated_datetime": "2022-03-18T03:59:03.922Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "129.56344604492188",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "fe44b7b1-6fa5-4b44-af5e-7280326e47f8",
					"created_datetime": "2022-03-18T03:59:03.925Z",
					"updated_datetime": "2022-03-18T03:59:03.925Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "80.11197662353516",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "0d466f09-0dc9-45d3-ba40-30fab2a099ca",
					"created_datetime": "2022-03-18T03:59:03.929Z",
					"updated_datetime": "2022-03-18T03:59:03.929Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "75.71720123291016",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "d1890bf3-1e9c-435f-a160-968da8f65e8d",
					"created_datetime": "2022-03-18T03:59:03.935Z",
					"updated_datetime": "2022-03-18T03:59:03.935Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "112.79154205322266",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "b701b27b-a4a7-4ae4-80cc-08d2bd628259",
					"created_datetime": "2022-03-18T03:59:03.939Z",
					"updated_datetime": "2022-03-18T03:59:03.939Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "119.60880279541016",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "d727e7a7-c8e5-421c-8b1e-db9ff1b86a58",
					"created_datetime": "2022-03-18T03:59:03.944Z",
					"updated_datetime": "2022-03-18T03:59:03.944Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "34.80474090576172",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "9037efd2-6d5c-4cde-91fe-9af4c53cd0e9",
					"created_datetime": "2022-03-18T03:59:03.951Z",
					"updated_datetime": "2022-03-18T03:59:03.951Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "44.977203369140625",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "d25c0e1e-f787-4985-9968-862c30b076e1",
					"created_datetime": "2022-03-18T03:59:03.954Z",
					"updated_datetime": "2022-03-18T03:59:03.954Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_stability_scap_left",
					"value_string": "14.468928337097168",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "11ece78a-98ca-42f9-92d1-c515ff39f723",
					"created_datetime": "2022-03-18T03:59:03.959Z",
					"updated_datetime": "2022-03-18T03:59:03.959Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_stability_scap_right",
					"value_string": "8.609013557434082",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "5e7aac80-725e-4270-a634-1a30a7c93bf6",
					"created_datetime": "2022-03-18T03:59:03.965Z",
					"updated_datetime": "2022-03-18T03:59:03.965Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "58.41844177246094",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "64caef60-b8ee-4d44-b438-accb46ce6666",
					"created_datetime": "2022-03-18T03:59:03.968Z",
					"updated_datetime": "2022-03-18T03:59:03.968Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "40.36082077026367",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "0a0ad7cf-1d26-4ec1-801e-6ceac0439ee1",
					"created_datetime": "2022-03-18T03:59:03.972Z",
					"updated_datetime": "2022-03-18T03:59:03.972Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "32.04413986206055",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "6952c6a7-a620-4844-90ed-4d9c0250f653",
					"created_datetime": "2022-03-18T03:59:03.975Z",
					"updated_datetime": "2022-03-18T03:59:03.975Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "0.3546055853366852",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "d859b36c-9a6f-4bd0-b501-2c732f90e840",
					"created_datetime": "2022-03-18T03:59:03.979Z",
					"updated_datetime": "2022-03-18T03:59:03.979Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "0.5049625635147095",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "73b0ea90-79c1-403c-af11-6c6f458ac729",
					"created_datetime": "2022-03-18T03:59:03.982Z",
					"updated_datetime": "2022-03-18T03:59:03.982Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "18.72422218322754",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "3deb4974-7b3d-4ccd-bf46-e3cad80d96fa",
					"created_datetime": "2022-03-18T03:59:03.985Z",
					"updated_datetime": "2022-03-18T03:59:03.985Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-2.447882890701294",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "29716cdf-0035-447b-b631-ae294364cc9c",
					"created_datetime": "2022-03-18T03:59:03.989Z",
					"updated_datetime": "2022-03-18T03:59:03.989Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "1.5232254266738892",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "3e486634-3491-4ea1-87f9-c8e4257f7055",
					"created_datetime": "2022-03-18T03:59:03.992Z",
					"updated_datetime": "2022-03-18T03:59:03.992Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-4.089958667755127",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "157356d8-a2c0-407c-b8c9-17a33414f956",
					"created_datetime": "2022-03-18T03:59:03.996Z",
					"updated_datetime": "2022-03-18T03:59:03.996Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-6.4369378089904785",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "632ce594-944e-4a0a-ac16-662d6d302d4e",
					"created_datetime": "2022-03-18T03:59:03.999Z",
					"updated_datetime": "2022-03-18T03:59:03.999Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_knee",
					"value_string": "8.308395385742188",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "1044a85c-cd75-4a94-a54a-4ca8eef36b4a",
					"created_datetime": "2022-03-18T03:59:04.002Z",
					"updated_datetime": "2022-03-18T03:59:04.002Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "13.695205688476562",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "2e77a36a-3056-4707-ba89-e59270ca8954",
					"created_datetime": "2022-03-18T03:59:04.006Z",
					"updated_datetime": "2022-03-18T03:59:04.006Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_composite",
					"value_string": "0.47930893301963806",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "4b98d8c9-cbfe-4a77-b4c1-8192403d0151",
					"created_datetime": "2022-03-18T03:59:04.009Z",
					"updated_datetime": "2022-03-18T03:59:04.009Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility",
					"value_string": "0.525273859500885",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "be750126-f2e4-45ce-90b6-206ac407a99c",
					"created_datetime": "2022-03-18T03:59:04.013Z",
					"updated_datetime": "2022-03-18T03:59:04.013Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability",
					"value_string": "0.47278812527656555",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "8508b25b-4342-4a59-b95b-d5892b37957a",
					"created_datetime": "2022-03-18T03:59:04.016Z",
					"updated_datetime": "2022-03-18T03:59:04.016Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_posture",
					"value_string": "0.9112554788589478",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "0cea95d3-2c56-47d4-878f-882fa0b2cab9",
					"created_datetime": "2022-03-18T03:59:04.019Z",
					"updated_datetime": "2022-03-18T03:59:04.019Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry",
					"value_string": "0.277682363986969",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "5620efe4-56d9-40fe-99e5-ff06cb00998c",
					"created_datetime": "2022-03-18T03:59:04.025Z",
					"updated_datetime": "2022-03-18T03:59:04.025Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9364275932312012",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "55c59dbc-1b21-4241-86a0-de1b93b81577",
					"created_datetime": "2022-03-18T03:59:04.033Z",
					"updated_datetime": "2022-03-18T03:59:04.033Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6184661388397217",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "5a496601-27da-4a40-84a5-08123d578e76",
					"created_datetime": "2022-03-18T03:59:04.036Z",
					"updated_datetime": "2022-03-18T03:59:04.036Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.19122415781021118",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "fc533ca7-8d77-4eb2-81b1-9480bdc5aca9",
					"created_datetime": "2022-03-18T03:59:04.040Z",
					"updated_datetime": "2022-03-18T03:59:04.040Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.210997074842453",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "dc1902df-a37b-4010-81d3-a088ff09a3d6",
					"created_datetime": "2022-03-18T03:59:04.047Z",
					"updated_datetime": "2022-03-18T03:59:04.047Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.8292773962020874",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "616e94c5-676b-4fa4-bdb3-b6679bd80e9b",
					"created_datetime": "2022-03-18T03:59:04.051Z",
					"updated_datetime": "2022-03-18T03:59:04.051Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.8058042526245117",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "56dfdcf0-cd0c-4730-8081-89c466f251d1",
					"created_datetime": "2022-03-18T03:59:04.054Z",
					"updated_datetime": "2022-03-18T03:59:04.054Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.8434199094772339",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "986d2af8-91ba-427f-91c8-08590bafbbac",
					"created_datetime": "2022-03-18T03:59:04.058Z",
					"updated_datetime": "2022-03-18T03:59:04.058Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.748747706413269",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "cae68551-ac44-4c37-8b68-257d5dd9790c",
					"created_datetime": "2022-03-18T03:59:04.062Z",
					"updated_datetime": "2022-03-18T03:59:04.062Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.45603686571121216",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "286852ac-887b-4065-b5ad-ff786573a0b5",
					"created_datetime": "2022-03-18T03:59:04.066Z",
					"updated_datetime": "2022-03-18T03:59:04.066Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.5911250114440918",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "0d7d2fd3-7d4f-4be8-8605-ed5204c3188c",
					"created_datetime": "2022-03-18T03:59:04.070Z",
					"updated_datetime": "2022-03-18T03:59:04.070Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.10270798206329346",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "ec731613-6e8a-4f97-980c-978a7cc16971",
					"created_datetime": "2022-03-18T03:59:04.074Z",
					"updated_datetime": "2022-03-18T03:59:04.074Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.33776646852493286",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "3965a002-7d24-41fc-be0d-8dc1444c17b8",
					"created_datetime": "2022-03-18T03:59:04.078Z",
					"updated_datetime": "2022-03-18T03:59:04.078Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability_scap_left",
					"value_string": "0.6775728464126587",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "5cdd91a9-7394-4c3c-b13e-6f2b372cf58c",
					"created_datetime": "2022-03-18T03:59:04.081Z",
					"updated_datetime": "2022-03-18T03:59:04.081Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8287530541419983",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "9bc1ddf5-757f-4f9c-84b8-01f7c769f434",
					"created_datetime": "2022-03-18T03:59:04.085Z",
					"updated_datetime": "2022-03-18T03:59:04.085Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.03274893760681152",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "5eada2e0-cd1e-42d9-9645-e8b4995542a3",
					"created_datetime": "2022-03-18T03:59:04.088Z",
					"updated_datetime": "2022-03-18T03:59:04.088Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.9377408027648926",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "a6456bcf-1b32-425d-ae76-abc6180b11f6",
					"created_datetime": "2022-03-18T03:59:04.091Z",
					"updated_datetime": "2022-03-18T03:59:04.091Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.7594125866889954",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "85c4a1c0-23ce-49d1-be52-b6fa1b278633",
					"created_datetime": "2022-03-18T03:59:04.094Z",
					"updated_datetime": "2022-03-18T03:59:04.094Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.945862352848053",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "d95eabff-f06a-48db-b7c9-f1e50d1c943a",
					"created_datetime": "2022-03-18T03:59:04.097Z",
					"updated_datetime": "2022-03-18T03:59:04.097Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.8766486644744873",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "fb0f624e-364f-4f64-9108-de0fe9a27cf2",
					"created_datetime": "2022-03-18T03:59:04.101Z",
					"updated_datetime": "2022-03-18T03:59:04.101Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.00010001659393310547",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "532a81a4-dd87-4d23-aa24-aef499081d65",
					"created_datetime": "2022-03-18T03:59:04.109Z",
					"updated_datetime": "2022-03-18T03:59:04.109Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8463364839553833",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "418da144-41e4-4436-b28d-5e020e61e38d",
					"created_datetime": "2022-03-18T03:59:04.113Z",
					"updated_datetime": "2022-03-18T03:59:04.113Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.9810927510261536",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "ca7f03b6-429d-4520-aa64-bf3c5c451996",
					"created_datetime": "2022-03-18T03:59:04.116Z",
					"updated_datetime": "2022-03-18T03:59:04.116Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8316956758499146",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "c3f63450-184a-4286-8f39-592770501c78",
					"created_datetime": "2022-03-18T03:59:04.119Z",
					"updated_datetime": "2022-03-18T03:59:04.119Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_femur",
					"value_string": "0.04087066650390625",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "202ebbb9-2cd4-4c38-8ba5-f060e518884d",
					"created_datetime": "2022-03-18T03:59:04.123Z",
					"updated_datetime": "2022-03-18T03:59:04.123Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_knee",
					"value_string": "0.2471901774406433",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "18b9bcaa-6fcb-42cc-8671-288ef8f0b05f",
					"created_datetime": "2022-03-18T03:59:04.126Z",
					"updated_datetime": "2022-03-18T03:59:04.126Z",
					"assessment_id_guid": "85ef1e87-13d5-4573-bfc7-60f52d854b59",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.0017270445823669434",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
			"created_datetime": "2022-03-18T04:43:45.419Z",
			"updated_datetime": "2022-03-18T04:43:45.419Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "5f9cfef6-bd88-4843-87e4-fd796003723f",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "f8d215ce-f401-4926-97fa-58cf5e157306",
					"created_datetime": "2022-03-18T04:43:45.426Z",
					"updated_datetime": "2022-03-18T04:43:45.426Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "ios_datetime",
					"value_string": "2022-03-13T03:57:05.676Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "e8818219-a551-4028-b79c-378b6fe109dd",
					"created_datetime": "2022-03-18T04:43:45.431Z",
					"updated_datetime": "2022-03-18T04:43:45.431Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.0",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "e21ac534-9ae2-46b4-bf91-31839ac1f263",
					"created_datetime": "2022-03-18T04:43:45.436Z",
					"updated_datetime": "2022-03-18T04:43:45.436Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "1",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "07b41289-9159-4398-9b90-d9a5be5ca2fa",
					"created_datetime": "2022-03-18T04:43:45.439Z",
					"updated_datetime": "2022-03-18T04:43:45.439Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "2",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "f9c48b33-4361-4a79-abf9-c9491b0c9130",
					"created_datetime": "2022-03-18T04:43:45.443Z",
					"updated_datetime": "2022-03-18T04:43:45.443Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "3",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "8b705239-0d8c-450d-b2cf-fd96519cdfd6",
					"created_datetime": "2022-03-18T04:43:45.447Z",
					"updated_datetime": "2022-03-18T04:43:45.447Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "4",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "4bff1ffe-7c72-4d6a-b879-8d874787b327",
					"created_datetime": "2022-03-18T04:43:45.451Z",
					"updated_datetime": "2022-03-18T04:43:45.451Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "5",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "e7afaed5-8959-49d7-9842-df78a9cc35a5",
					"created_datetime": "2022-03-18T04:43:45.455Z",
					"updated_datetime": "2022-03-18T04:43:45.455Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "6",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "92346b7d-5c8c-421d-ac2b-a93dfd7b6c6a",
					"created_datetime": "2022-03-18T04:43:45.458Z",
					"updated_datetime": "2022-03-18T04:43:45.458Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "7",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "cda0cc99-7e64-43d0-9588-a08d238c69b9",
					"created_datetime": "2022-03-18T04:43:45.462Z",
					"updated_datetime": "2022-03-18T04:43:45.462Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "8",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "9dc50804-c5cc-4c5f-b315-df800a7e8e5b",
					"created_datetime": "2022-03-18T04:43:45.466Z",
					"updated_datetime": "2022-03-18T04:43:45.466Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "9",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "f8878309-7c1f-4d3b-85d2-11ed767167da",
					"created_datetime": "2022-03-18T04:43:45.469Z",
					"updated_datetime": "2022-03-18T04:43:45.469Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "10",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "d96be739-d741-4bfd-9755-1ce4a8257bec",
					"created_datetime": "2022-03-18T04:43:45.473Z",
					"updated_datetime": "2022-03-18T04:43:45.473Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "11",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "884c47d6-5037-416d-b727-a49f41ff18b5",
					"created_datetime": "2022-03-18T04:43:45.476Z",
					"updated_datetime": "2022-03-18T04:43:45.476Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "1a3ba096-7569-486d-a23c-7a50e75403c0",
					"created_datetime": "2022-03-18T04:43:45.479Z",
					"updated_datetime": "2022-03-18T04:43:45.479Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_stability_scap_left",
					"value_string": "13",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "e7e184fe-1ebc-4f2b-8d6e-60ac257b3596",
					"created_datetime": "2022-03-18T04:43:45.483Z",
					"updated_datetime": "2022-03-18T04:43:45.483Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_stability_scap_right",
					"value_string": "14",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7254a383-5390-4d8d-9e7e-9c48d0ef8308",
					"created_datetime": "2022-03-18T04:43:45.487Z",
					"updated_datetime": "2022-03-18T04:43:45.487Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "15",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "f917f78e-3859-438e-9806-fbcd3cec16b1",
					"created_datetime": "2022-03-18T04:43:45.491Z",
					"updated_datetime": "2022-03-18T04:43:45.491Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "16",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "b1510670-98fa-454e-89cb-2ca5f768e0c4",
					"created_datetime": "2022-03-18T04:43:45.494Z",
					"updated_datetime": "2022-03-18T04:43:45.494Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "17",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "70bc4ed5-d46f-4cf2-aded-83c9111f24f7",
					"created_datetime": "2022-03-18T04:43:45.498Z",
					"updated_datetime": "2022-03-18T04:43:45.498Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "18",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8d8489a3-291f-4cb8-863e-f0f0cf48db57",
					"created_datetime": "2022-03-18T04:43:45.502Z",
					"updated_datetime": "2022-03-18T04:43:45.502Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "19",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "118c2329-796f-49eb-858b-6d988420ea3c",
					"created_datetime": "2022-03-18T04:43:45.505Z",
					"updated_datetime": "2022-03-18T04:43:45.505Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "20",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "5ec97b60-d9ad-48a4-9308-c5417646e7e7",
					"created_datetime": "2022-03-18T04:43:45.509Z",
					"updated_datetime": "2022-03-18T04:43:45.509Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "21",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "e7a2f1f1-166b-4564-8bf2-7b8d19a10af2",
					"created_datetime": "2022-03-18T04:43:45.512Z",
					"updated_datetime": "2022-03-18T04:43:45.512Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "023bbb07-0edf-4152-b5b5-5235cf982945",
					"created_datetime": "2022-03-18T04:43:45.515Z",
					"updated_datetime": "2022-03-18T04:43:45.515Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_hip",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "cfaa20e2-c748-4a89-aafa-7f1d7b39a882",
					"created_datetime": "2022-03-18T04:43:45.519Z",
					"updated_datetime": "2022-03-18T04:43:45.519Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_femur",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "707f239a-66e2-466c-b9f2-c8af3d9c50d5",
					"created_datetime": "2022-03-18T04:43:45.522Z",
					"updated_datetime": "2022-03-18T04:43:45.522Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_knee",
					"value_string": "25",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "6b91471c-a7a6-4aac-97f2-6f400e7b0773",
					"created_datetime": "2022-03-18T04:43:45.525Z",
					"updated_datetime": "2022-03-18T04:43:45.525Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "26",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "6792c983-1d12-4a20-97f4-fc6a37a1994a",
					"created_datetime": "2022-03-18T04:43:45.528Z",
					"updated_datetime": "2022-03-18T04:43:45.528Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_composite",
					"value_string": "27",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "aa1c20e2-2400-4185-ad6e-9ccd392027f1",
					"created_datetime": "2022-03-18T04:43:45.531Z",
					"updated_datetime": "2022-03-18T04:43:45.531Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility",
					"value_string": "28",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "e31af921-cc4c-4a06-8cc4-78614eb40265",
					"created_datetime": "2022-03-18T04:43:45.535Z",
					"updated_datetime": "2022-03-18T04:43:45.535Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability",
					"value_string": "29",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "0995c8a6-69ae-4733-b9c8-0509c617dd3c",
					"created_datetime": "2022-03-18T04:43:45.538Z",
					"updated_datetime": "2022-03-18T04:43:45.538Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_posture",
					"value_string": "30",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "92edffbf-cdad-4b79-a1b7-696bdc53e912",
					"created_datetime": "2022-03-18T04:43:45.542Z",
					"updated_datetime": "2022-03-18T04:43:45.542Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry",
					"value_string": "31",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "7402aff2-055e-4741-93e1-7304f2f6d2b0",
					"created_datetime": "2022-03-18T04:43:45.545Z",
					"updated_datetime": "2022-03-18T04:43:45.545Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_elbow_left",
					"value_string": "32",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "c4ec3251-19e6-4a14-8336-75197894320c",
					"created_datetime": "2022-03-18T04:43:45.548Z",
					"updated_datetime": "2022-03-18T04:43:45.548Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_elbow_right",
					"value_string": "33",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "f022850a-6c42-4bb6-b3bd-256f47e08c20",
					"created_datetime": "2022-03-18T04:43:45.552Z",
					"updated_datetime": "2022-03-18T04:43:45.552Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "34",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "fb9d4d27-242a-4a12-85ba-d407c8feed39",
					"created_datetime": "2022-03-18T04:43:45.555Z",
					"updated_datetime": "2022-03-18T04:43:45.555Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "35",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "d43c4e18-f1c1-45e7-be1c-1efca594b9e8",
					"created_datetime": "2022-03-18T04:43:45.558Z",
					"updated_datetime": "2022-03-18T04:43:45.558Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_hip_left",
					"value_string": "36",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "e8709647-8a9c-4304-b0c0-cfee35ee6f78",
					"created_datetime": "2022-03-18T04:43:45.561Z",
					"updated_datetime": "2022-03-18T04:43:45.561Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_hip_right",
					"value_string": "37",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "8cdd08ee-947d-4d95-9362-56b21d9ff490",
					"created_datetime": "2022-03-18T04:43:45.565Z",
					"updated_datetime": "2022-03-18T04:43:45.565Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_femur_left",
					"value_string": "38",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "38a92847-e5ff-41b6-99e7-e750bf2bc184",
					"created_datetime": "2022-03-18T04:43:45.626Z",
					"updated_datetime": "2022-03-18T04:43:45.626Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_femur_right",
					"value_string": "39",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "500b5e9e-1025-4cdf-b2b1-653408112bbd",
					"created_datetime": "2022-03-18T04:43:45.633Z",
					"updated_datetime": "2022-03-18T04:43:45.633Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_knee_left",
					"value_string": "40",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "db22badb-44e9-4bf8-b8c2-18bbf7946be3",
					"created_datetime": "2022-03-18T04:43:45.638Z",
					"updated_datetime": "2022-03-18T04:43:45.638Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_knee_right",
					"value_string": "41",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "b491ac20-d078-4fae-a657-ace6fc4737ea",
					"created_datetime": "2022-03-18T04:43:45.642Z",
					"updated_datetime": "2022-03-18T04:43:45.642Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_tibia_left",
					"value_string": "42",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "86727a35-bba6-4cce-a525-d25f11218362",
					"created_datetime": "2022-03-18T04:43:45.646Z",
					"updated_datetime": "2022-03-18T04:43:45.646Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_mobility_tibia_right",
					"value_string": "43",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "92d4bb38-5550-4432-a3fe-db9af3d2c6fb",
					"created_datetime": "2022-03-18T04:43:45.649Z",
					"updated_datetime": "2022-03-18T04:43:45.649Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability_scap_left",
					"value_string": "44",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "e2d3f433-19a5-47c9-a975-bc3237adcbd7",
					"created_datetime": "2022-03-18T04:43:45.652Z",
					"updated_datetime": "2022-03-18T04:43:45.652Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability_scap_right",
					"value_string": "45",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "02ae606c-4bc2-4e80-8bed-0b6e6d5fc0b2",
					"created_datetime": "2022-03-18T04:43:45.656Z",
					"updated_datetime": "2022-03-18T04:43:45.656Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "46",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "80184b41-d5ba-453a-96ae-0cc7d24af89c",
					"created_datetime": "2022-03-18T04:43:45.659Z",
					"updated_datetime": "2022-03-18T04:43:45.659Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "47",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "70472e0d-a084-4c59-9257-88d1977ef21b",
					"created_datetime": "2022-03-18T04:43:45.662Z",
					"updated_datetime": "2022-03-18T04:43:45.662Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "48",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "cb6bdb91-8bd7-4e79-8fa1-55214760b0dc",
					"created_datetime": "2022-03-18T04:43:45.666Z",
					"updated_datetime": "2022-03-18T04:43:45.666Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_posture_shoulder_level",
					"value_string": "49",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "20050677-3567-44f1-8d88-74b39342642a",
					"created_datetime": "2022-03-18T04:43:45.669Z",
					"updated_datetime": "2022-03-18T04:43:45.669Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "50",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "7ef1e0af-61e9-4f79-be13-aad2d74774b4",
					"created_datetime": "2022-03-18T04:43:45.672Z",
					"updated_datetime": "2022-03-18T04:43:45.672Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_elbow",
					"value_string": "51",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "ecff59de-adf0-4dcf-a0db-de49d8b1786d",
					"created_datetime": "2022-03-18T04:43:45.675Z",
					"updated_datetime": "2022-03-18T04:43:45.675Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_shoulder",
					"value_string": "52",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "0edf8720-be74-4b31-9364-97cd87e4dd75",
					"created_datetime": "2022-03-18T04:43:45.679Z",
					"updated_datetime": "2022-03-18T04:43:45.679Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "53",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "46e29f04-0116-491b-b599-4f5f7d1b99a7",
					"created_datetime": "2022-03-18T04:43:45.682Z",
					"updated_datetime": "2022-03-18T04:43:45.682Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_hip",
					"value_string": "54",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "5a920524-f840-425f-9891-dbbbbcd1360d",
					"created_datetime": "2022-03-18T04:43:45.685Z",
					"updated_datetime": "2022-03-18T04:43:45.685Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_femur",
					"value_string": "55",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "da3be99c-b3f6-479f-9ce3-aa4e8b06d808",
					"created_datetime": "2022-03-18T04:43:45.688Z",
					"updated_datetime": "2022-03-18T04:43:45.688Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_knee",
					"value_string": "56",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "c3cc0f7b-9cea-46ae-809a-22df267237d4",
					"created_datetime": "2022-03-18T04:43:45.692Z",
					"updated_datetime": "2022-03-18T04:43:45.692Z",
					"assessment_id_guid": "dada67be-8c5a-468d-be25-3f77543ebef1",
					"name_string": "score_symmetry_tibia",
					"value_string": "57",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
			"created_datetime": "2022-03-18T04:48:55.773Z",
			"updated_datetime": "2022-03-18T04:48:55.773Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "9ceaa298-e793-4d99-87a6-ce3b4538e12e",
			"external_id_guid": "00834c54-0ea0-44d9-a510-1d9a4eaff81f",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "44e09fe3-a478-4b40-aa44-b1fd46582ca1",
					"created_datetime": "2022-03-18T04:48:55.793Z",
					"updated_datetime": "2022-03-18T04:48:55.793Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:51:44.581Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "64a2a694-706d-4ece-a8c9-27fa932e5cd6",
					"created_datetime": "2022-03-18T04:48:55.800Z",
					"updated_datetime": "2022-03-18T04:48:55.800Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f0a7700b-a895-46ff-9533-337936615ab2",
					"created_datetime": "2022-03-18T04:48:55.804Z",
					"updated_datetime": "2022-03-18T04:48:55.804Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "23.15905439203172",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "74673759-77ed-4633-96de-a22196b62bf5",
					"created_datetime": "2022-03-18T04:48:55.808Z",
					"updated_datetime": "2022-03-18T04:48:55.808Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "58.44330194689911",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "2083bdde-68f5-4969-92c8-3d5d60dda100",
					"created_datetime": "2022-03-18T04:48:55.812Z",
					"updated_datetime": "2022-03-18T04:48:55.812Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "172.58888078248043",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "d911540c-7a24-43b3-87e9-cd0d43d3aa46",
					"created_datetime": "2022-03-18T04:48:55.816Z",
					"updated_datetime": "2022-03-18T04:48:55.816Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-132.36571730135302",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "c63a26ef-7b9e-45dd-ad28-6a6a7f299407",
					"created_datetime": "2022-03-18T04:48:55.820Z",
					"updated_datetime": "2022-03-18T04:48:55.820Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-103.76405364783027",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "842709ec-da70-4be9-8548-dc724a98606a",
					"created_datetime": "2022-03-18T04:48:55.823Z",
					"updated_datetime": "2022-03-18T04:48:55.823Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-51.04145496597971",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "3e24b4a3-628f-4709-a428-376ae59133fd",
					"created_datetime": "2022-03-18T04:48:55.827Z",
					"updated_datetime": "2022-03-18T04:48:55.827Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-9.43198771186374",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "affb0c8a-2cad-495c-97de-f872242f36f9",
					"created_datetime": "2022-03-18T04:48:55.831Z",
					"updated_datetime": "2022-03-18T04:48:55.831Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "124.79972823908372",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "000fff1c-ab07-4b29-a32c-6e9882d5f69c",
					"created_datetime": "2022-03-18T04:48:55.834Z",
					"updated_datetime": "2022-03-18T04:48:55.834Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "50.012175780818524",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "62c60214-5a88-4a36-a6ee-7e133d68e3a8",
					"created_datetime": "2022-03-18T04:48:55.838Z",
					"updated_datetime": "2022-03-18T04:48:55.838Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "162.87538347958997",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "34e9d34d-5e26-4379-bd10-485f095d1ee7",
					"created_datetime": "2022-03-18T04:48:55.841Z",
					"updated_datetime": "2022-03-18T04:48:55.841Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "7.86149237523037",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "14d459bc-fdd4-4538-b645-0eff01702ecd",
					"created_datetime": "2022-03-18T04:48:55.845Z",
					"updated_datetime": "2022-03-18T04:48:55.845Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "90.08894803677168",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "e403b9d5-0a6f-459a-aa31-2f3b2a8e532b",
					"created_datetime": "2022-03-18T04:48:55.849Z",
					"updated_datetime": "2022-03-18T04:48:55.849Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_stability_scap_left",
					"value_string": "97.20212881544575",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "b6a5b892-aada-454b-9bae-ed313a4535b8",
					"created_datetime": "2022-03-18T04:48:55.853Z",
					"updated_datetime": "2022-03-18T04:48:55.853Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_stability_scap_right",
					"value_string": "166.7616468593955",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7d2c0986-de73-4e8e-82d7-c3f1ad59b5ff",
					"created_datetime": "2022-03-18T04:48:55.856Z",
					"updated_datetime": "2022-03-18T04:48:55.856Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "152.4928822973922",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "acd41ad9-fa4e-4634-96f2-52141aabd531",
					"created_datetime": "2022-03-18T04:48:55.860Z",
					"updated_datetime": "2022-03-18T04:48:55.860Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "10.93664692191507",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "fc169d4a-b6b0-45af-bd19-7016c7b22186",
					"created_datetime": "2022-03-18T04:48:55.863Z",
					"updated_datetime": "2022-03-18T04:48:55.863Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "30.086184617583115",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "a19e3f79-4273-4d9f-a22b-ce8a02d9bf1d",
					"created_datetime": "2022-03-18T04:48:55.866Z",
					"updated_datetime": "2022-03-18T04:48:55.866Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "153.0689669743656",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "808feb27-dd5a-4f78-b783-e7cbf8136951",
					"created_datetime": "2022-03-18T04:48:55.871Z",
					"updated_datetime": "2022-03-18T04:48:55.871Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "163.37269897594933",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "035bc477-3e44-43df-aa24-6e8f942fa3f0",
					"created_datetime": "2022-03-18T04:48:55.874Z",
					"updated_datetime": "2022-03-18T04:48:55.874Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-177.59796721479543",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "3e08071a-c6be-4afb-8d51-b568afff943f",
					"created_datetime": "2022-03-18T04:48:55.877Z",
					"updated_datetime": "2022-03-18T04:48:55.877Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-102.95680161891455",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "795106f3-e3a9-4a4b-86d6-8465f4550610",
					"created_datetime": "2022-03-18T04:48:55.881Z",
					"updated_datetime": "2022-03-18T04:48:55.881Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-43.302538704457675",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "550bf371-b439-4f0e-9086-d8f4677c8a03",
					"created_datetime": "2022-03-18T04:48:55.885Z",
					"updated_datetime": "2022-03-18T04:48:55.885Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-147.45658028592845",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "127d415d-5ef9-4a8b-9a59-b9cac46e9e0d",
					"created_datetime": "2022-03-18T04:48:55.888Z",
					"updated_datetime": "2022-03-18T04:48:55.888Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-150.10836456439375",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "a552b94a-b586-4379-b163-a20df4f37049",
					"created_datetime": "2022-03-18T04:48:55.892Z",
					"updated_datetime": "2022-03-18T04:48:55.892Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-76.10579848536528",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "b4aa9234-6b5b-412d-b9c3-c7ac90e3d458",
					"created_datetime": "2022-03-18T04:48:55.895Z",
					"updated_datetime": "2022-03-18T04:48:55.895Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "178.06621210807344",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "89bcfabf-c283-43f5-8f9b-e0399262552f",
					"created_datetime": "2022-03-18T04:48:55.898Z",
					"updated_datetime": "2022-03-18T04:48:55.898Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_composite",
					"value_string": "0.4336496389746775",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "8467dd19-2c34-4ad2-b16e-29dd7bb3e783",
					"created_datetime": "2022-03-18T04:48:55.902Z",
					"updated_datetime": "2022-03-18T04:48:55.902Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility",
					"value_string": "0.2643357310028416",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "c190b3dc-804d-45db-9e97-25ff7fc58277",
					"created_datetime": "2022-03-18T04:48:55.906Z",
					"updated_datetime": "2022-03-18T04:48:55.906Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability",
					"value_string": "0.8452247087512692",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "bf1e4d0f-6fbf-4075-9818-aefebd1616e6",
					"created_datetime": "2022-03-18T04:48:55.909Z",
					"updated_datetime": "2022-03-18T04:48:55.909Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_posture",
					"value_string": "0.5884487647731049",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "8b9fc4f6-522b-40fd-a700-22fceb46ade8",
					"created_datetime": "2022-03-18T04:48:55.912Z",
					"updated_datetime": "2022-03-18T04:48:55.912Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry",
					"value_string": "0.9483305573559802",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "a567dc03-5e58-43f9-8b29-df0a83d9dc43",
					"created_datetime": "2022-03-18T04:48:55.916Z",
					"updated_datetime": "2022-03-18T04:48:55.916Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.08578707235874682",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "49e53746-9ab1-4665-80a2-c5eba399dcfe",
					"created_datetime": "2022-03-18T04:48:55.920Z",
					"updated_datetime": "2022-03-18T04:48:55.920Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.022568429599082812",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "730db43e-08ca-4c8e-93e8-feb0da6d5acc",
					"created_datetime": "2022-03-18T04:48:55.924Z",
					"updated_datetime": "2022-03-18T04:48:55.924Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.525591280951169",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "e35cffcc-9ba1-4d29-a56c-3d0ebda4129c",
					"created_datetime": "2022-03-18T04:48:55.927Z",
					"updated_datetime": "2022-03-18T04:48:55.927Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.6036616610602418",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "4cf1f180-5b25-4f1e-bbbc-bcebc1d7e4b8",
					"created_datetime": "2022-03-18T04:48:55.931Z",
					"updated_datetime": "2022-03-18T04:48:55.931Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.9848434888607331",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "d4c53394-7788-4a9a-b9fb-484cfe996298",
					"created_datetime": "2022-03-18T04:48:55.935Z",
					"updated_datetime": "2022-03-18T04:48:55.935Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5453126429914441",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "c9926593-7eb1-42ea-acf1-55a79855f3ed",
					"created_datetime": "2022-03-18T04:48:55.939Z",
					"updated_datetime": "2022-03-18T04:48:55.939Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.5041506801441586",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "d43ac6bb-2ce7-4916-b47b-1e87a3695473",
					"created_datetime": "2022-03-18T04:48:55.942Z",
					"updated_datetime": "2022-03-18T04:48:55.942Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.5566368152605979",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "6f040288-77d4-492f-bac5-7477d02f5f0c",
					"created_datetime": "2022-03-18T04:48:55.945Z",
					"updated_datetime": "2022-03-18T04:48:55.945Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.7436162561915168",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "05209832-9b7d-4504-8a99-6213b688f64b",
					"created_datetime": "2022-03-18T04:48:55.949Z",
					"updated_datetime": "2022-03-18T04:48:55.949Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8742971127730876",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "ffd0d796-06cb-4754-b803-e978e55386cd",
					"created_datetime": "2022-03-18T04:48:55.952Z",
					"updated_datetime": "2022-03-18T04:48:55.952Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.30948409747002414",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "f3ec6ca6-3d71-4868-a0b2-8991ec296ad9",
					"created_datetime": "2022-03-18T04:48:55.957Z",
					"updated_datetime": "2022-03-18T04:48:55.957Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.35582021277919323",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "0be2f331-a1af-4457-95b8-5294eb9acbdf",
					"created_datetime": "2022-03-18T04:48:55.960Z",
					"updated_datetime": "2022-03-18T04:48:55.960Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability_scap_left",
					"value_string": "0.33665185644631795",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "01d4026b-764d-4531-84f5-69179dcf85db",
					"created_datetime": "2022-03-18T04:48:55.964Z",
					"updated_datetime": "2022-03-18T04:48:55.964Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability_scap_right",
					"value_string": "0.6783914505461168",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "83a94aba-a937-462c-919d-79759f30c874",
					"created_datetime": "2022-03-18T04:48:55.970Z",
					"updated_datetime": "2022-03-18T04:48:55.970Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5522262590579806",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "b26abdc0-6008-4641-b2e3-6aa1785d13df",
					"created_datetime": "2022-03-18T04:48:55.973Z",
					"updated_datetime": "2022-03-18T04:48:55.973Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.5467763878224465",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "81862c54-2e92-47ff-a75a-32abee16891f",
					"created_datetime": "2022-03-18T04:48:55.977Z",
					"updated_datetime": "2022-03-18T04:48:55.977Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.3877491986069666",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "a2f8aeae-c480-4056-8ffb-2b65f612fc31",
					"created_datetime": "2022-03-18T04:48:55.980Z",
					"updated_datetime": "2022-03-18T04:48:55.980Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.7596392692721028",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "7e3ac1b0-f0b9-4c1e-9936-d68033365552",
					"created_datetime": "2022-03-18T04:48:55.984Z",
					"updated_datetime": "2022-03-18T04:48:55.984Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.17472042545771402",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "8f3ac877-c16f-4614-9fed-c9143b540b4b",
					"created_datetime": "2022-03-18T04:48:55.987Z",
					"updated_datetime": "2022-03-18T04:48:55.987Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.8234978031409849",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "393fb98a-4b57-4ea7-85c3-39a82a77e3e1",
					"created_datetime": "2022-03-18T04:48:55.991Z",
					"updated_datetime": "2022-03-18T04:48:55.991Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.5750950983848001",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "897e719d-72d8-4d41-a2b4-19eb672627a5",
					"created_datetime": "2022-03-18T04:48:55.995Z",
					"updated_datetime": "2022-03-18T04:48:55.995Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.1404561344882285",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "f41cf550-6439-46e7-90d5-07083aabdbed",
					"created_datetime": "2022-03-18T04:48:55.999Z",
					"updated_datetime": "2022-03-18T04:48:55.999Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7429062033481276",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "c4e9d20b-845f-45f3-975c-88adedc1fd81",
					"created_datetime": "2022-03-18T04:48:56.003Z",
					"updated_datetime": "2022-03-18T04:48:56.003Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8982311029190329",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "32802ae1-790b-4add-ab04-0f685325f97b",
					"created_datetime": "2022-03-18T04:48:56.006Z",
					"updated_datetime": "2022-03-18T04:48:56.006Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_knee",
					"value_string": "0.5229752778498784",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "ca4bde77-4ba5-4212-898b-a2ccf028165e",
					"created_datetime": "2022-03-18T04:48:56.011Z",
					"updated_datetime": "2022-03-18T04:48:56.011Z",
					"assessment_id_guid": "18434733-d9e9-42c1-b089-927b37c538bb",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.47837979795066127",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
			"created_datetime": "2022-03-18T04:49:06.854Z",
			"updated_datetime": "2022-03-18T04:49:06.854Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "c385f18b-b843-470f-88fd-45541e4a4dd4",
			"external_id_guid": "ffa2b100-5754-4c98-a13b-d8230a9d7787",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "1becf7c8-407b-468a-86d6-8d1db5118c2e",
					"created_datetime": "2022-03-18T04:49:06.859Z",
					"updated_datetime": "2022-03-18T04:49:06.859Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:51:55.674Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "7d3f8190-8622-4020-bff1-d9335c492f4d",
					"created_datetime": "2022-03-18T04:49:06.863Z",
					"updated_datetime": "2022-03-18T04:49:06.863Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "839009dd-d252-42ae-82d1-2889fd803c7b",
					"created_datetime": "2022-03-18T04:49:06.867Z",
					"updated_datetime": "2022-03-18T04:49:06.867Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "112.56617492638992",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "9f5f90cf-0332-4a25-8ba0-997fb83c830c",
					"created_datetime": "2022-03-18T04:49:06.872Z",
					"updated_datetime": "2022-03-18T04:49:06.872Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-40.131912251761776",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "f8a49c0b-0ca2-4b03-8fe7-c47a792e8d2c",
					"created_datetime": "2022-03-18T04:49:06.875Z",
					"updated_datetime": "2022-03-18T04:49:06.875Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-137.21554997336347",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "75ad12be-dc4d-4741-8506-ce2f097ab037",
					"created_datetime": "2022-03-18T04:49:06.879Z",
					"updated_datetime": "2022-03-18T04:49:06.879Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "12.34849713100624",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "24adafbd-6c23-4a8e-bb0b-4185dfd4b5eb",
					"created_datetime": "2022-03-18T04:49:06.882Z",
					"updated_datetime": "2022-03-18T04:49:06.882Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "26.13155125172213",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "79b76f59-1125-45d6-9949-8559d4b58b9d",
					"created_datetime": "2022-03-18T04:49:06.885Z",
					"updated_datetime": "2022-03-18T04:49:06.885Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-74.10113923330181",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "e12d22fa-aa63-4045-a242-9c393c145876",
					"created_datetime": "2022-03-18T04:49:06.889Z",
					"updated_datetime": "2022-03-18T04:49:06.889Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-137.94676266340304",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "b36372dc-2b26-40df-a70b-058d0a1f5292",
					"created_datetime": "2022-03-18T04:49:06.892Z",
					"updated_datetime": "2022-03-18T04:49:06.892Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "13.521531752187599",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "b6fff906-daa1-4a72-bf6b-cd6154d560b3",
					"created_datetime": "2022-03-18T04:49:06.896Z",
					"updated_datetime": "2022-03-18T04:49:06.896Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "37.38668599808656",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "1a2e944f-20b9-4736-b20b-f839cbb129b4",
					"created_datetime": "2022-03-18T04:49:06.899Z",
					"updated_datetime": "2022-03-18T04:49:06.899Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "140.55880982190178",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "e05947c3-8eb9-4c2e-b4d6-595cf3e69e94",
					"created_datetime": "2022-03-18T04:49:06.902Z",
					"updated_datetime": "2022-03-18T04:49:06.902Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-95.14208711829146",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "308c2667-3695-4852-a033-428539cdbb5f",
					"created_datetime": "2022-03-18T04:49:06.905Z",
					"updated_datetime": "2022-03-18T04:49:06.905Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "48.68801350147052",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "9d0050d1-131c-42b7-bf5d-0e654ccd0384",
					"created_datetime": "2022-03-18T04:49:06.908Z",
					"updated_datetime": "2022-03-18T04:49:06.908Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_stability_scap_left",
					"value_string": "18.9438518652577",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "46be4e13-a45e-442e-89b6-99ebbecf6253",
					"created_datetime": "2022-03-18T04:49:06.912Z",
					"updated_datetime": "2022-03-18T04:49:06.912Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-96.79662131717407",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "567e66fc-b3e2-4cee-a9b2-5c0477be2f80",
					"created_datetime": "2022-03-18T04:49:06.916Z",
					"updated_datetime": "2022-03-18T04:49:06.916Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "53.274007559224856",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "7ae8e9f6-bb55-4b07-be10-73d186e549de",
					"created_datetime": "2022-03-18T04:49:06.920Z",
					"updated_datetime": "2022-03-18T04:49:06.920Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-177.08407448859853",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "d68fa005-5cc3-4942-9db2-cc61e3a0a8ef",
					"created_datetime": "2022-03-18T04:49:06.925Z",
					"updated_datetime": "2022-03-18T04:49:06.925Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "22.06515084653489",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "e82167aa-4aaf-47e0-a4b2-7bf66104a537",
					"created_datetime": "2022-03-18T04:49:06.928Z",
					"updated_datetime": "2022-03-18T04:49:06.928Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "49.48174071992017",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "4cdeb81a-457a-4112-af2a-c4bfaa202e7d",
					"created_datetime": "2022-03-18T04:49:06.931Z",
					"updated_datetime": "2022-03-18T04:49:06.931Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "121.0477093384261",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "eca55b08-a471-4fbd-98fa-b473c6bc8e51",
					"created_datetime": "2022-03-18T04:49:06.936Z",
					"updated_datetime": "2022-03-18T04:49:06.936Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "74.1333302397255",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "d79b2e27-4d48-4a36-8651-82d2fd11a941",
					"created_datetime": "2022-03-18T04:49:06.939Z",
					"updated_datetime": "2022-03-18T04:49:06.939Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "106.0915311587305",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "a72f4d65-fa44-42f0-b3d1-9aa043814190",
					"created_datetime": "2022-03-18T04:49:06.943Z",
					"updated_datetime": "2022-03-18T04:49:06.943Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "179.85806551649767",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "7df06608-1762-4f8b-bf1c-9c822dbca11c",
					"created_datetime": "2022-03-18T04:49:06.946Z",
					"updated_datetime": "2022-03-18T04:49:06.946Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-41.76656178538326",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "b1ac6b1e-e7c7-4f58-9c04-008818f32365",
					"created_datetime": "2022-03-18T04:49:06.949Z",
					"updated_datetime": "2022-03-18T04:49:06.949Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_femur",
					"value_string": "69.70066986921049",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "69a28c8e-b1b8-48a7-8120-748e67020619",
					"created_datetime": "2022-03-18T04:49:06.952Z",
					"updated_datetime": "2022-03-18T04:49:06.952Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_knee",
					"value_string": "143.71470727687517",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "3bdcf897-fdd5-4584-8e43-dc212a2f38f1",
					"created_datetime": "2022-03-18T04:49:06.955Z",
					"updated_datetime": "2022-03-18T04:49:06.955Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-55.3550988640404",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "33af6606-0130-4b9c-8f27-6911fafbe350",
					"created_datetime": "2022-03-18T04:49:06.958Z",
					"updated_datetime": "2022-03-18T04:49:06.958Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_composite",
					"value_string": "0.2073424234735925",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "3d7a3426-0ed6-4d3a-94f8-626ede6668ca",
					"created_datetime": "2022-03-18T04:49:06.961Z",
					"updated_datetime": "2022-03-18T04:49:06.961Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility",
					"value_string": "0.8222701816518261",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "43292679-26df-4b79-bd26-2ff8ab27964d",
					"created_datetime": "2022-03-18T04:49:06.964Z",
					"updated_datetime": "2022-03-18T04:49:06.964Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability",
					"value_string": "0.6461726858286296",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "daf124dd-eb14-4a3c-aee1-ac654ac92e83",
					"created_datetime": "2022-03-18T04:49:06.968Z",
					"updated_datetime": "2022-03-18T04:49:06.968Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_posture",
					"value_string": "0.865576445048147",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "857310a7-01ea-4826-80b0-89ab0a567e19",
					"created_datetime": "2022-03-18T04:49:06.972Z",
					"updated_datetime": "2022-03-18T04:49:06.972Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry",
					"value_string": "0.5339199782036708",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "aa58681c-0f81-473c-a211-bd844181253c",
					"created_datetime": "2022-03-18T04:49:06.975Z",
					"updated_datetime": "2022-03-18T04:49:06.975Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.6125756378547238",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "e4007dd7-1231-4eb6-b7ab-f21c6279d396",
					"created_datetime": "2022-03-18T04:49:06.978Z",
					"updated_datetime": "2022-03-18T04:49:06.978Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.22799751502567675",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "e4fa88a7-d6bd-4518-a7ee-b4fb6afa744a",
					"created_datetime": "2022-03-18T04:49:06.982Z",
					"updated_datetime": "2022-03-18T04:49:06.982Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.788636085402066",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "f07461c2-7ff9-4524-9870-f534ff5891be",
					"created_datetime": "2022-03-18T04:49:06.985Z",
					"updated_datetime": "2022-03-18T04:49:06.985Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.856289251751579",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "e9701c53-4bc5-4ba7-a8f1-2197630b3599",
					"created_datetime": "2022-03-18T04:49:06.988Z",
					"updated_datetime": "2022-03-18T04:49:06.988Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.20888973174012765",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "54d81dea-6087-43c2-a017-5538e5346714",
					"created_datetime": "2022-03-18T04:49:06.992Z",
					"updated_datetime": "2022-03-18T04:49:06.992Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.9556808274038426",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "a3c93f61-42f1-4558-bf18-4f5da12af580",
					"created_datetime": "2022-03-18T04:49:06.995Z",
					"updated_datetime": "2022-03-18T04:49:06.995Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.23838751462249855",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "79bdd721-441a-48ce-acac-9771fcab96f9",
					"created_datetime": "2022-03-18T04:49:06.999Z",
					"updated_datetime": "2022-03-18T04:49:06.999Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.32227443409349804",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "8a6058b3-8edc-411a-8a6a-279d82b041ae",
					"created_datetime": "2022-03-18T04:49:07.002Z",
					"updated_datetime": "2022-03-18T04:49:07.002Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.5088190776081432",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "a056baaf-af02-458d-9fd7-d12a8684cc8a",
					"created_datetime": "2022-03-18T04:49:07.008Z",
					"updated_datetime": "2022-03-18T04:49:07.008Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.529344496275819",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "897b68df-5e73-4261-92ba-7d92436e9c67",
					"created_datetime": "2022-03-18T04:49:07.014Z",
					"updated_datetime": "2022-03-18T04:49:07.014Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.12131939266787256",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "e12bc3dc-ffc4-4430-bf2f-64a9fc8a2d99",
					"created_datetime": "2022-03-18T04:49:07.018Z",
					"updated_datetime": "2022-03-18T04:49:07.018Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.8989994267379717",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "227fdfff-3d86-4803-b7a5-d712c90ba36c",
					"created_datetime": "2022-03-18T04:49:07.022Z",
					"updated_datetime": "2022-03-18T04:49:07.022Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9440588261569194",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "0ba02c39-f87e-485d-8761-159b3fec9138",
					"created_datetime": "2022-03-18T04:49:07.026Z",
					"updated_datetime": "2022-03-18T04:49:07.026Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability_scap_right",
					"value_string": "0.14912043061556637",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "22c5fe3a-4a3d-467e-bebf-aa4c7c19e2d5",
					"created_datetime": "2022-03-18T04:49:07.029Z",
					"updated_datetime": "2022-03-18T04:49:07.029Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.48976602835999683",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "837d7f3d-0939-410a-b095-ef461927a8d1",
					"created_datetime": "2022-03-18T04:49:07.032Z",
					"updated_datetime": "2022-03-18T04:49:07.032Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.6839256564790195",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "025a3676-b7a2-4bdb-9406-694e7078ebbe",
					"created_datetime": "2022-03-18T04:49:07.035Z",
					"updated_datetime": "2022-03-18T04:49:07.035Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.16901699028043196",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "8e3494d6-0879-422b-a6c8-07ae1447920d",
					"created_datetime": "2022-03-18T04:49:07.038Z",
					"updated_datetime": "2022-03-18T04:49:07.038Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.17580771273678727",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "140c6d4d-0a89-49b0-ba67-008a3f247f5c",
					"created_datetime": "2022-03-18T04:49:07.041Z",
					"updated_datetime": "2022-03-18T04:49:07.041Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.17629557047049774",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "03a0c973-bb7a-4704-8de6-10293d14b2b8",
					"created_datetime": "2022-03-18T04:49:07.044Z",
					"updated_datetime": "2022-03-18T04:49:07.044Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.8313673960744267",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "bbb0a36b-f4ee-44f4-a8b7-e3123bac74a0",
					"created_datetime": "2022-03-18T04:49:07.048Z",
					"updated_datetime": "2022-03-18T04:49:07.048Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.569446689558859",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "faea5e8d-5719-4c53-b344-eb6fb64531f7",
					"created_datetime": "2022-03-18T04:49:07.052Z",
					"updated_datetime": "2022-03-18T04:49:07.052Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.4539993192128753",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "10c0834e-f5f5-4c57-912d-4f5e2c92d32c",
					"created_datetime": "2022-03-18T04:49:07.056Z",
					"updated_datetime": "2022-03-18T04:49:07.056Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8024926572705638",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "fa684284-1a53-4850-bc70-550d1f52deef",
					"created_datetime": "2022-03-18T04:49:07.059Z",
					"updated_datetime": "2022-03-18T04:49:07.059Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8362254271087356",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "808cd0e4-12d3-4a19-ace7-29b9f50ec348",
					"created_datetime": "2022-03-18T04:49:07.062Z",
					"updated_datetime": "2022-03-18T04:49:07.062Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_knee",
					"value_string": "0.04936262624206574",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "cf0c2e79-c768-4ef9-a41c-fbd101d2e2a8",
					"created_datetime": "2022-03-18T04:49:07.066Z",
					"updated_datetime": "2022-03-18T04:49:07.066Z",
					"assessment_id_guid": "6ea715ac-8135-4218-aaa1-a81022dff6a5",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.07435514759601754",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
			"created_datetime": "2022-03-18T04:49:58.721Z",
			"updated_datetime": "2022-03-18T04:49:58.721Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "16f2011b-4f66-4d6b-9e40-84db11048d0d",
			"external_id_guid": "84cda821-3431-4ee2-a1c7-fb63af34ae10",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "eb5d7972-6b62-4381-b0b6-4c0cfab4db75",
					"created_datetime": "2022-03-18T04:49:58.726Z",
					"updated_datetime": "2022-03-18T04:49:58.726Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:52:47.541Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "2d9c05ba-ee93-49d8-9d88-88ad19128036",
					"created_datetime": "2022-03-18T04:49:58.729Z",
					"updated_datetime": "2022-03-18T04:49:58.729Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "229c81b9-15c4-41a3-aa9a-d8e0f8e3b30d",
					"created_datetime": "2022-03-18T04:49:58.733Z",
					"updated_datetime": "2022-03-18T04:49:58.733Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-36.96099365775032",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "737a995f-c08e-4efd-9c20-d0bf39df6ee1",
					"created_datetime": "2022-03-18T04:49:58.736Z",
					"updated_datetime": "2022-03-18T04:49:58.736Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "69.67009437069126",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "b36ce890-1cc7-4191-bb9d-8fe7e04b28d2",
					"created_datetime": "2022-03-18T04:49:58.741Z",
					"updated_datetime": "2022-03-18T04:49:58.741Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-149.00972316342182",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "fdd86f89-8eef-4131-a054-94f5d873d205",
					"created_datetime": "2022-03-18T04:49:58.744Z",
					"updated_datetime": "2022-03-18T04:49:58.744Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-68.19847143141229",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "0d0131fc-929a-4b99-a07e-96a730b68df2",
					"created_datetime": "2022-03-18T04:49:58.747Z",
					"updated_datetime": "2022-03-18T04:49:58.747Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "120.61860555163639",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "0ec1a810-9ce9-44f0-a633-63ab5ff51fd7",
					"created_datetime": "2022-03-18T04:49:58.751Z",
					"updated_datetime": "2022-03-18T04:49:58.751Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "117.17702162175624",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "b0d7910f-95f2-484e-861d-aef50c6070dc",
					"created_datetime": "2022-03-18T04:49:58.754Z",
					"updated_datetime": "2022-03-18T04:49:58.754Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-164.0138544094603",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "17ec6e7f-30ea-4fc7-82e6-cfe597f74c02",
					"created_datetime": "2022-03-18T04:49:58.757Z",
					"updated_datetime": "2022-03-18T04:49:58.757Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "158.64136982784373",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "14230284-d824-4fce-9c1a-0d9db8b571b3",
					"created_datetime": "2022-03-18T04:49:58.760Z",
					"updated_datetime": "2022-03-18T04:49:58.760Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "3.4140496443732786",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "ac64fe92-9093-4e62-bcb5-93bdd7bdeb46",
					"created_datetime": "2022-03-18T04:49:58.763Z",
					"updated_datetime": "2022-03-18T04:49:58.763Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "67.9621003838501",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "6b70e1a1-9387-45da-9f3e-e32a8ffdf82b",
					"created_datetime": "2022-03-18T04:49:58.768Z",
					"updated_datetime": "2022-03-18T04:49:58.768Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-13.265889292994103",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "b7fbf449-19bb-4915-a5c5-74535304d44a",
					"created_datetime": "2022-03-18T04:49:58.773Z",
					"updated_datetime": "2022-03-18T04:49:58.773Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "130.03217293610464",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6ae11dbc-3392-48ec-929b-0ad8c426b6f7",
					"created_datetime": "2022-03-18T04:49:58.776Z",
					"updated_datetime": "2022-03-18T04:49:58.776Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_stability_scap_left",
					"value_string": "77.46894615627576",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "30744161-b320-4e0c-b732-2cced1ff7dbc",
					"created_datetime": "2022-03-18T04:49:58.782Z",
					"updated_datetime": "2022-03-18T04:49:58.782Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_stability_scap_right",
					"value_string": "108.81462793969843",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "5f2fd99f-788e-42ed-b2b6-aacd2d5ff505",
					"created_datetime": "2022-03-18T04:49:58.785Z",
					"updated_datetime": "2022-03-18T04:49:58.785Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "119.23309221401502",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "8699f956-93ac-4e12-a1eb-f8a52d5817ee",
					"created_datetime": "2022-03-18T04:49:58.789Z",
					"updated_datetime": "2022-03-18T04:49:58.789Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "119.9535240998593",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "ed945f9e-00bf-4140-b6cb-dbbec02df3a0",
					"created_datetime": "2022-03-18T04:49:58.794Z",
					"updated_datetime": "2022-03-18T04:49:58.794Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "63.12172883680236",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "fff86350-8eac-43c7-ac58-d342d96766b2",
					"created_datetime": "2022-03-18T04:49:58.797Z",
					"updated_datetime": "2022-03-18T04:49:58.797Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-109.76919596123973",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "8b31ae9b-f31a-43ea-8bfc-554d12f21d4e",
					"created_datetime": "2022-03-18T04:49:58.800Z",
					"updated_datetime": "2022-03-18T04:49:58.800Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "72.02054105495102",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "b3b6145b-dcfc-468d-a627-2a0dc48e0c46",
					"created_datetime": "2022-03-18T04:49:58.804Z",
					"updated_datetime": "2022-03-18T04:49:58.804Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-164.08298575784139",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "88de1f1c-a8e5-42ce-a022-4bd7cc2a53b4",
					"created_datetime": "2022-03-18T04:49:58.807Z",
					"updated_datetime": "2022-03-18T04:49:58.807Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-171.86375578143276",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "1ff1b0bd-e7e8-496b-b641-3883bff5d6d1",
					"created_datetime": "2022-03-18T04:49:58.810Z",
					"updated_datetime": "2022-03-18T04:49:58.810Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-87.50666855084425",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "5ce4540a-44e5-4c04-8370-46a036d051d2",
					"created_datetime": "2022-03-18T04:49:58.813Z",
					"updated_datetime": "2022-03-18T04:49:58.813Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-97.64373444580644",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "8e89f8d1-7111-46ca-b84f-4f96f112320a",
					"created_datetime": "2022-03-18T04:49:58.816Z",
					"updated_datetime": "2022-03-18T04:49:58.816Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_femur",
					"value_string": "143.74508677915713",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "d9c2d741-7e14-42c7-9bbf-6a7601321f53",
					"created_datetime": "2022-03-18T04:49:58.828Z",
					"updated_datetime": "2022-03-18T04:49:58.828Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-42.875007068281434",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "c1b6a17d-e4a2-4540-8828-d0347deb1d53",
					"created_datetime": "2022-03-18T04:49:58.832Z",
					"updated_datetime": "2022-03-18T04:49:58.832Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "55.55771384189532",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "9cdbe3d9-0fdb-40e9-85c7-81c7bd06a691",
					"created_datetime": "2022-03-18T04:49:58.835Z",
					"updated_datetime": "2022-03-18T04:49:58.835Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_composite",
					"value_string": "0.43968647479431205",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "948e587a-b982-40ce-b767-6e8e635edab3",
					"created_datetime": "2022-03-18T04:49:58.838Z",
					"updated_datetime": "2022-03-18T04:49:58.838Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility",
					"value_string": "0.5203290904526838",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "472f9b5b-236e-4727-9097-34f7f236949f",
					"created_datetime": "2022-03-18T04:49:58.842Z",
					"updated_datetime": "2022-03-18T04:49:58.842Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability",
					"value_string": "0.9224505622374617",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "9aa6ec7b-ab7c-4add-a4e9-b63450e95e14",
					"created_datetime": "2022-03-18T04:49:58.845Z",
					"updated_datetime": "2022-03-18T04:49:58.845Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_posture",
					"value_string": "0.7027419863952562",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "9654863d-6a01-4d15-9575-f0bc98363f07",
					"created_datetime": "2022-03-18T04:49:58.848Z",
					"updated_datetime": "2022-03-18T04:49:58.848Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry",
					"value_string": "0.4484218749316378",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "31117163-d6a4-4582-a1ab-dff543ae8ce9",
					"created_datetime": "2022-03-18T04:49:58.862Z",
					"updated_datetime": "2022-03-18T04:49:58.862Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.1975294047131964",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "9580f3c3-d0ce-4263-b39b-8b7fed496b19",
					"created_datetime": "2022-03-18T04:49:58.867Z",
					"updated_datetime": "2022-03-18T04:49:58.867Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.009072537946567991",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "fdc82371-d354-4f97-b8ff-066477f89e83",
					"created_datetime": "2022-03-18T04:49:58.871Z",
					"updated_datetime": "2022-03-18T04:49:58.871Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.28941162980072316",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "d62455e8-85ee-4d77-90f2-a3f569463f3d",
					"created_datetime": "2022-03-18T04:49:58.875Z",
					"updated_datetime": "2022-03-18T04:49:58.875Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.9596315912614427",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "64cdbcc2-bb33-43e7-966d-6078e61f1a3a",
					"created_datetime": "2022-03-18T04:49:58.880Z",
					"updated_datetime": "2022-03-18T04:49:58.880Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.21233088223049634",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "48dc3084-a07f-4b17-b85f-0e7b15930528",
					"created_datetime": "2022-03-18T04:49:58.883Z",
					"updated_datetime": "2022-03-18T04:49:58.883Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.5649322390739678",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "747c8f04-0e5e-40f3-9439-5773fd5bd8e6",
					"created_datetime": "2022-03-18T04:49:58.886Z",
					"updated_datetime": "2022-03-18T04:49:58.886Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.4894082463651416",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "33a82314-f267-4cb7-b645-b44ab41ff7d1",
					"created_datetime": "2022-03-18T04:49:58.890Z",
					"updated_datetime": "2022-03-18T04:49:58.890Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.317299884917865",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "74dc0eb7-434f-49a8-ab0b-bd0f9a842de5",
					"created_datetime": "2022-03-18T04:49:58.894Z",
					"updated_datetime": "2022-03-18T04:49:58.894Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.6562951404697183",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "0b199a63-bbb2-46ad-979b-ab29bccff4ab",
					"created_datetime": "2022-03-18T04:49:58.897Z",
					"updated_datetime": "2022-03-18T04:49:58.897Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.7110963013003229",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "f3093df3-c442-495f-95fc-ee2eccc5fb52",
					"created_datetime": "2022-03-18T04:49:58.901Z",
					"updated_datetime": "2022-03-18T04:49:58.901Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.17151764279155446",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "bb7af2fb-2f50-4153-8b4a-4ad787235b41",
					"created_datetime": "2022-03-18T04:49:58.904Z",
					"updated_datetime": "2022-03-18T04:49:58.904Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.7112358239856986",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "fc99f464-bc5f-4eb0-a114-44ec4a7808ec",
					"created_datetime": "2022-03-18T04:49:58.908Z",
					"updated_datetime": "2022-03-18T04:49:58.908Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability_scap_left",
					"value_string": "0.4993442986718147",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "6364a8cc-eb14-4882-92c4-1e532c41e8ed",
					"created_datetime": "2022-03-18T04:49:58.912Z",
					"updated_datetime": "2022-03-18T04:49:58.912Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability_scap_right",
					"value_string": "0.551320767431136",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "8d267e77-5ee3-4947-a519-61e62f240f72",
					"created_datetime": "2022-03-18T04:49:58.915Z",
					"updated_datetime": "2022-03-18T04:49:58.915Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.7638484187805171",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "5aeb5e2b-4f89-464f-ac0b-382f5a42295e",
					"created_datetime": "2022-03-18T04:49:58.919Z",
					"updated_datetime": "2022-03-18T04:49:58.919Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.2801913171097704",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "9bb64990-e2c6-463c-a042-e53becb97ca0",
					"created_datetime": "2022-03-18T04:49:58.922Z",
					"updated_datetime": "2022-03-18T04:49:58.922Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.36049697582610957",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "d70f8a48-38a1-4179-89ae-9c3a3cba8816",
					"created_datetime": "2022-03-18T04:49:58.925Z",
					"updated_datetime": "2022-03-18T04:49:58.925Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.38206878754932144",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "d153a66f-348a-4645-8a75-2a0038a3f0c8",
					"created_datetime": "2022-03-18T04:49:58.928Z",
					"updated_datetime": "2022-03-18T04:49:58.928Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.82861032399716",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "b4a763cc-f609-4188-becc-0909195ca4f8",
					"created_datetime": "2022-03-18T04:49:58.933Z",
					"updated_datetime": "2022-03-18T04:49:58.933Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.4291527588237927",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "6fccbac3-4a27-4f4c-b8eb-d00232cc5a1c",
					"created_datetime": "2022-03-18T04:49:58.936Z",
					"updated_datetime": "2022-03-18T04:49:58.936Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.2207253937970694",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "64fabfcf-e074-4810-a75f-c048aa602aae",
					"created_datetime": "2022-03-18T04:49:58.939Z",
					"updated_datetime": "2022-03-18T04:49:58.939Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.8689492196508479",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "4bdfaee5-46b9-462c-8b99-59da4b3b8d91",
					"created_datetime": "2022-03-18T04:49:58.942Z",
					"updated_datetime": "2022-03-18T04:49:58.942Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_hip",
					"value_string": "0.8406416649816122",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "8503f978-e4b9-4c1b-8044-21327bde4c10",
					"created_datetime": "2022-03-18T04:49:58.945Z",
					"updated_datetime": "2022-03-18T04:49:58.945Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_femur",
					"value_string": "0.8905890121772537",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "00c443d3-2387-4d58-b27f-dfa5c819e331",
					"created_datetime": "2022-03-18T04:49:58.949Z",
					"updated_datetime": "2022-03-18T04:49:58.949Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_knee",
					"value_string": "0.26635740577777406",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "c6966754-4224-4f85-82c0-25cbe706194b",
					"created_datetime": "2022-03-18T04:49:58.952Z",
					"updated_datetime": "2022-03-18T04:49:58.952Z",
					"assessment_id_guid": "22d7b369-1e21-4e46-bf8c-84319b4c0cfa",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.3342079169080737",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
			"created_datetime": "2022-03-18T04:50:18.547Z",
			"updated_datetime": "2022-03-18T04:50:18.547Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "b7f80cd2-ceca-4e37-8efa-35838ed6fd64",
			"external_id_guid": "8f1d2e4d-d168-427e-ae6c-cb3118d5a35a",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "ddb7aa20-c509-4715-a564-003560cb37fc",
					"created_datetime": "2022-03-18T04:50:18.553Z",
					"updated_datetime": "2022-03-18T04:50:18.553Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:53:07.374Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "cfcc48dd-df7d-440d-a760-4e048c2d7d23",
					"created_datetime": "2022-03-18T04:50:18.556Z",
					"updated_datetime": "2022-03-18T04:50:18.556Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "c4c528f3-7c6b-4d25-90c7-d71b6a18281a",
					"created_datetime": "2022-03-18T04:50:18.561Z",
					"updated_datetime": "2022-03-18T04:50:18.561Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "65.21459168025308",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "6c4cde17-477d-43f4-8320-6d2d206a39e6",
					"created_datetime": "2022-03-18T04:50:18.565Z",
					"updated_datetime": "2022-03-18T04:50:18.565Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "31.62601472271919",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "92649836-3e99-4eba-9fab-3cb7d2eea527",
					"created_datetime": "2022-03-18T04:50:18.570Z",
					"updated_datetime": "2022-03-18T04:50:18.570Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "174.5459302415265",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "1ce2b560-3f07-46d0-81ed-7783c4fcf364",
					"created_datetime": "2022-03-18T04:50:18.574Z",
					"updated_datetime": "2022-03-18T04:50:18.574Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "102.06840807859487",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "285ad267-8660-498b-a263-07cfa1e1b427",
					"created_datetime": "2022-03-18T04:50:18.578Z",
					"updated_datetime": "2022-03-18T04:50:18.578Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-65.84650506636605",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "d92e9cb5-b837-4cd7-9aeb-ca082a48ece8",
					"created_datetime": "2022-03-18T04:50:18.582Z",
					"updated_datetime": "2022-03-18T04:50:18.582Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-179.5639286331247",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "466c1f86-904b-480b-999d-665cc2eb0d70",
					"created_datetime": "2022-03-18T04:50:18.586Z",
					"updated_datetime": "2022-03-18T04:50:18.586Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-6.1522313501954216",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "1bfd3b69-409e-4d0b-bcbc-94d85cd262c0",
					"created_datetime": "2022-03-18T04:50:18.590Z",
					"updated_datetime": "2022-03-18T04:50:18.590Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "112.76822339584851",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "e39f54ea-08d8-4b8e-a27a-0d0a9fabcc46",
					"created_datetime": "2022-03-18T04:50:18.595Z",
					"updated_datetime": "2022-03-18T04:50:18.595Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "75.08967370687597",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "557ead99-ed54-4630-9270-f41cb7991b19",
					"created_datetime": "2022-03-18T04:50:18.598Z",
					"updated_datetime": "2022-03-18T04:50:18.598Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "120.85595283664998",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "1c9fe3bb-a314-40f5-b757-fac5aab24f77",
					"created_datetime": "2022-03-18T04:50:18.605Z",
					"updated_datetime": "2022-03-18T04:50:18.605Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "121.41985190042942",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "b145a4d6-71fb-4cf7-b19c-025b6d778d2c",
					"created_datetime": "2022-03-18T04:50:18.608Z",
					"updated_datetime": "2022-03-18T04:50:18.608Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "152.091066338686",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "640a9c3d-21c8-4a68-8958-d29c4e0c07d5",
					"created_datetime": "2022-03-18T04:50:18.612Z",
					"updated_datetime": "2022-03-18T04:50:18.612Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_stability_scap_left",
					"value_string": "-108.72196711982585",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "04c12578-3658-4c5f-9f32-36221f17243f",
					"created_datetime": "2022-03-18T04:50:18.615Z",
					"updated_datetime": "2022-03-18T04:50:18.615Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_stability_scap_right",
					"value_string": "131.3125177395521",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "32b04840-4712-429b-82d1-2eac40e769a7",
					"created_datetime": "2022-03-18T04:50:18.619Z",
					"updated_datetime": "2022-03-18T04:50:18.619Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-3.471409715432742",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "19ac92e9-49e4-4966-8aef-388564c11da1",
					"created_datetime": "2022-03-18T04:50:18.623Z",
					"updated_datetime": "2022-03-18T04:50:18.623Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-120.91950547581209",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "7290881b-5238-40e6-9928-d15a1a701634",
					"created_datetime": "2022-03-18T04:50:18.626Z",
					"updated_datetime": "2022-03-18T04:50:18.626Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "66.54124165435084",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "21c3b286-e6fb-4a6a-b1ba-b58e4284ead5",
					"created_datetime": "2022-03-18T04:50:18.631Z",
					"updated_datetime": "2022-03-18T04:50:18.631Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "128.23475432613185",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "b1910f72-1fcf-4fa3-ac43-fb692cf8b754",
					"created_datetime": "2022-03-18T04:50:18.635Z",
					"updated_datetime": "2022-03-18T04:50:18.635Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "12.320307915605241",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "7e394e52-fa42-4c47-ab06-4cea076eea72",
					"created_datetime": "2022-03-18T04:50:18.639Z",
					"updated_datetime": "2022-03-18T04:50:18.639Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "81.15281585227063",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "e45f8609-a6c7-42b2-90d3-0d69381e5ad3",
					"created_datetime": "2022-03-18T04:50:18.642Z",
					"updated_datetime": "2022-03-18T04:50:18.642Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "81.72928180843695",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "853f6ed1-df91-46a2-8fd7-16d55db67a77",
					"created_datetime": "2022-03-18T04:50:18.648Z",
					"updated_datetime": "2022-03-18T04:50:18.648Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "31.550638247247264",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "6066ba8d-fd57-4ca7-96ec-00ad8f5ded5e",
					"created_datetime": "2022-03-18T04:50:18.651Z",
					"updated_datetime": "2022-03-18T04:50:18.651Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_hip",
					"value_string": "161.0343940234498",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "123e9044-a058-4713-86cb-32abd26b1009",
					"created_datetime": "2022-03-18T04:50:18.655Z",
					"updated_datetime": "2022-03-18T04:50:18.655Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_femur",
					"value_string": "58.02757826675273",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "814f269b-13e0-48cd-8075-04a26d936176",
					"created_datetime": "2022-03-18T04:50:18.658Z",
					"updated_datetime": "2022-03-18T04:50:18.658Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_knee",
					"value_string": "12.221455818816821",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "6db73e7d-5092-4d34-aaaa-3ae4d9466cb1",
					"created_datetime": "2022-03-18T04:50:18.663Z",
					"updated_datetime": "2022-03-18T04:50:18.663Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-92.99674727501954",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "8fc93498-c96e-40bd-b273-912b084425ba",
					"created_datetime": "2022-03-18T04:50:18.667Z",
					"updated_datetime": "2022-03-18T04:50:18.667Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_composite",
					"value_string": "0.8726332401908556",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "ebf0405c-6ba5-4873-a095-9b1583e59f9a",
					"created_datetime": "2022-03-18T04:50:18.670Z",
					"updated_datetime": "2022-03-18T04:50:18.670Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility",
					"value_string": "0.8318092624670836",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "e76cf68a-9307-4ff8-993a-90d7bf61673a",
					"created_datetime": "2022-03-18T04:50:18.674Z",
					"updated_datetime": "2022-03-18T04:50:18.674Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability",
					"value_string": "0.9655396790923628",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "9d0bffa5-c57d-44c3-aa79-fad8d3cb1a26",
					"created_datetime": "2022-03-18T04:50:18.677Z",
					"updated_datetime": "2022-03-18T04:50:18.677Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_posture",
					"value_string": "0.8942921136208631",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "bb94688d-7abf-489c-8ac1-98a44b96e65b",
					"created_datetime": "2022-03-18T04:50:18.681Z",
					"updated_datetime": "2022-03-18T04:50:18.681Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry",
					"value_string": "0.9800647257375662",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "7ebec78d-9893-41be-966c-550e19a70e32",
					"created_datetime": "2022-03-18T04:50:18.685Z",
					"updated_datetime": "2022-03-18T04:50:18.685Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.6102129216720689",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "e5959e84-3c87-47f4-b993-571141f78522",
					"created_datetime": "2022-03-18T04:50:18.688Z",
					"updated_datetime": "2022-03-18T04:50:18.688Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.9704227357603931",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "a9b93b08-9049-48b4-9013-48c3be8c5290",
					"created_datetime": "2022-03-18T04:50:18.692Z",
					"updated_datetime": "2022-03-18T04:50:18.692Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.11639083578305202",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "9da17324-120b-4eb4-8a89-0386c6e9d5b5",
					"created_datetime": "2022-03-18T04:50:18.707Z",
					"updated_datetime": "2022-03-18T04:50:18.707Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.6111987914599595",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "0a16ffa3-6b58-49a7-992e-f95c75bd41b1",
					"created_datetime": "2022-03-18T04:50:18.711Z",
					"updated_datetime": "2022-03-18T04:50:18.711Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.7740432479800945",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "5a022565-1c44-40fa-bb86-87d29488f356",
					"created_datetime": "2022-03-18T04:50:18.715Z",
					"updated_datetime": "2022-03-18T04:50:18.715Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.6592624358103835",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "dd609242-734e-440a-a2a1-74f0b92321a8",
					"created_datetime": "2022-03-18T04:50:18.718Z",
					"updated_datetime": "2022-03-18T04:50:18.718Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.7958726980651147",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "0d805386-8b9e-4932-8467-0119b391f12b",
					"created_datetime": "2022-03-18T04:50:18.722Z",
					"updated_datetime": "2022-03-18T04:50:18.722Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.7537098937762381",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "a44191b6-e548-4772-a6a1-5af50dfa371b",
					"created_datetime": "2022-03-18T04:50:18.725Z",
					"updated_datetime": "2022-03-18T04:50:18.725Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.3940869504503156",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "4cea9fc8-9dd6-446e-9d14-c523f051d9e2",
					"created_datetime": "2022-03-18T04:50:18.728Z",
					"updated_datetime": "2022-03-18T04:50:18.728Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.5343288041375902",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "042d42a2-b867-4cc9-bf7d-0956bfc2bf71",
					"created_datetime": "2022-03-18T04:50:18.733Z",
					"updated_datetime": "2022-03-18T04:50:18.733Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.3742934698176971",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "c63f9405-a823-4802-b8d4-347bec353a9b",
					"created_datetime": "2022-03-18T04:50:18.737Z",
					"updated_datetime": "2022-03-18T04:50:18.737Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.7179363197124085",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "40d8b32f-7a48-444c-b31d-bdd0184a84ca",
					"created_datetime": "2022-03-18T04:50:18.742Z",
					"updated_datetime": "2022-03-18T04:50:18.742Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability_scap_left",
					"value_string": "0.7775501382272264",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "791f0c06-dcdd-4387-b2a6-6ebac23c0109",
					"created_datetime": "2022-03-18T04:50:18.745Z",
					"updated_datetime": "2022-03-18T04:50:18.745Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability_scap_right",
					"value_string": "0.915085144837468",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "a04e761b-c503-44ec-9de4-8bc7fc2793cf",
					"created_datetime": "2022-03-18T04:50:18.751Z",
					"updated_datetime": "2022-03-18T04:50:18.751Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5595824842858249",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "e9950a71-0d52-43e0-b94c-6db512c3f253",
					"created_datetime": "2022-03-18T04:50:18.754Z",
					"updated_datetime": "2022-03-18T04:50:18.754Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.02350692860042252",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "7323b46e-3e9d-4d0f-b84e-bb905b166081",
					"created_datetime": "2022-03-18T04:50:18.758Z",
					"updated_datetime": "2022-03-18T04:50:18.758Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.7803532571141825",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "5825b82b-6561-43a8-bfca-486f27055b62",
					"created_datetime": "2022-03-18T04:50:18.761Z",
					"updated_datetime": "2022-03-18T04:50:18.761Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.887131941503819",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "bc75ba7e-6f6e-4524-b9b5-332a1e02c405",
					"created_datetime": "2022-03-18T04:50:18.776Z",
					"updated_datetime": "2022-03-18T04:50:18.776Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.4288108853982171",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "ecf30dae-184e-4d64-8843-955201fe187c",
					"created_datetime": "2022-03-18T04:50:18.779Z",
					"updated_datetime": "2022-03-18T04:50:18.779Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.29311992877347354",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "bb73f52a-0cbe-4058-9640-be906089b205",
					"created_datetime": "2022-03-18T04:50:18.782Z",
					"updated_datetime": "2022-03-18T04:50:18.782Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8388323580094258",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "99ed4652-0fa7-4690-a778-cbda3835e465",
					"created_datetime": "2022-03-18T04:50:18.786Z",
					"updated_datetime": "2022-03-18T04:50:18.786Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.17648514549227964",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "09e90d45-bed1-477d-8a13-961ce36280e9",
					"created_datetime": "2022-03-18T04:50:18.790Z",
					"updated_datetime": "2022-03-18T04:50:18.790Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_hip",
					"value_string": "0.9774463176707062",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "c45593de-affd-497f-a300-b478f3d95958",
					"created_datetime": "2022-03-18T04:50:18.793Z",
					"updated_datetime": "2022-03-18T04:50:18.793Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_femur",
					"value_string": "0.17213612827723135",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "7b5734db-3a45-41e5-9624-2b171a9b2d00",
					"created_datetime": "2022-03-18T04:50:18.796Z",
					"updated_datetime": "2022-03-18T04:50:18.796Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_knee",
					"value_string": "0.9768862657364066",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "358df56b-f4f2-42d9-8227-09f96e7284f7",
					"created_datetime": "2022-03-18T04:50:18.801Z",
					"updated_datetime": "2022-03-18T04:50:18.801Z",
					"assessment_id_guid": "9bdf9d70-0992-404e-884f-351e3e8503d8",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.9453742391891267",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
			"created_datetime": "2022-03-18T04:50:28.221Z",
			"updated_datetime": "2022-03-18T04:50:28.221Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "f6d8fac9-6d35-4d92-8a57-4e36a4bfc684",
			"external_id_guid": "ccf1d5a7-a344-420a-bac2-087fa3cf2b60",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "42c2eaf8-eff7-4722-b244-18fe6af3d24f",
					"created_datetime": "2022-03-18T04:50:28.226Z",
					"updated_datetime": "2022-03-18T04:50:28.226Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:53:17.040Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "2e7f9bad-b245-49f7-a5d7-9281dd5fef36",
					"created_datetime": "2022-03-18T04:50:28.230Z",
					"updated_datetime": "2022-03-18T04:50:28.230Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "42152716-0d46-43e1-a091-12903423c1a3",
					"created_datetime": "2022-03-18T04:50:28.233Z",
					"updated_datetime": "2022-03-18T04:50:28.233Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "142.52073451183315",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "583b5611-3e5f-4ccf-bc2c-928ccef9e41f",
					"created_datetime": "2022-03-18T04:50:28.236Z",
					"updated_datetime": "2022-03-18T04:50:28.236Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "67.7890345655924",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "d87788ab-a1f4-4e33-bc99-ec69bd8710e3",
					"created_datetime": "2022-03-18T04:50:28.241Z",
					"updated_datetime": "2022-03-18T04:50:28.241Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "122.98341918957749",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "b04668f1-d79a-4cd4-9e4b-02b1bfdfa53d",
					"created_datetime": "2022-03-18T04:50:28.244Z",
					"updated_datetime": "2022-03-18T04:50:28.244Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "110.67468654278491",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "d306bada-db75-41c1-8974-b79e8bff6b96",
					"created_datetime": "2022-03-18T04:50:28.248Z",
					"updated_datetime": "2022-03-18T04:50:28.248Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-93.8739256588365",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "dc4e2c44-7871-4347-ab60-369f49f4d229",
					"created_datetime": "2022-03-18T04:50:28.251Z",
					"updated_datetime": "2022-03-18T04:50:28.251Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-53.42222485954264",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "cf3ff9d8-c24a-4475-9f5e-b5d3f2bf4056",
					"created_datetime": "2022-03-18T04:50:28.254Z",
					"updated_datetime": "2022-03-18T04:50:28.254Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-172.7039881167474",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "5bb608f9-7d7c-493c-a04f-efc88f4c47f2",
					"created_datetime": "2022-03-18T04:50:28.258Z",
					"updated_datetime": "2022-03-18T04:50:28.258Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "45.6789241847801",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "8d5561be-0101-4a96-b2e2-eba4dfd9e05d",
					"created_datetime": "2022-03-18T04:50:28.264Z",
					"updated_datetime": "2022-03-18T04:50:28.264Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-118.24348322890651",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "8ad444af-6768-46c1-b696-8d567cd1b6c3",
					"created_datetime": "2022-03-18T04:50:28.267Z",
					"updated_datetime": "2022-03-18T04:50:28.267Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-141.1946615060524",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "2b876c37-6838-4c50-ac16-cc2d48697411",
					"created_datetime": "2022-03-18T04:50:28.270Z",
					"updated_datetime": "2022-03-18T04:50:28.270Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "79.91495996281645",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "71208324-ba26-4be9-a34f-ee02270b725f",
					"created_datetime": "2022-03-18T04:50:28.274Z",
					"updated_datetime": "2022-03-18T04:50:28.274Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-17.572862925286586",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "6983c3b3-2034-4030-9b67-f3a22a759f00",
					"created_datetime": "2022-03-18T04:50:28.277Z",
					"updated_datetime": "2022-03-18T04:50:28.277Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_stability_scap_left",
					"value_string": "69.83186939000043",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "bb041f3d-0a2f-426d-b193-22dbcde147d3",
					"created_datetime": "2022-03-18T04:50:28.286Z",
					"updated_datetime": "2022-03-18T04:50:28.286Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_stability_scap_right",
					"value_string": "142.57630532406682",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "e5d9a66a-c605-4e1a-a08f-b8718948d6ef",
					"created_datetime": "2022-03-18T04:50:28.290Z",
					"updated_datetime": "2022-03-18T04:50:28.290Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "111.50839723743388",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "76298588-d951-4d56-a670-53feea90fc73",
					"created_datetime": "2022-03-18T04:50:28.294Z",
					"updated_datetime": "2022-03-18T04:50:28.294Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "176.97727397734326",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "5c4b332e-3909-4e42-9a6c-e3095b692a9e",
					"created_datetime": "2022-03-18T04:50:28.298Z",
					"updated_datetime": "2022-03-18T04:50:28.298Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "35.980605407604116",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "ac75c7ec-e470-49c0-aa64-f2b6657a3245",
					"created_datetime": "2022-03-18T04:50:28.303Z",
					"updated_datetime": "2022-03-18T04:50:28.303Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-37.703857182054094",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "4a862076-8848-44e1-b050-172d22bd6c81",
					"created_datetime": "2022-03-18T04:50:28.306Z",
					"updated_datetime": "2022-03-18T04:50:28.306Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-130.8142813067152",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "871755c0-45bd-4ec6-ae4c-5f1d7087dfff",
					"created_datetime": "2022-03-18T04:50:28.310Z",
					"updated_datetime": "2022-03-18T04:50:28.310Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "53.3440306420612",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "e53c8f07-2c92-4bd0-91cf-03bb5f386ef8",
					"created_datetime": "2022-03-18T04:50:28.313Z",
					"updated_datetime": "2022-03-18T04:50:28.313Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-142.68752609497986",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "23c10a2f-0881-41f4-8f97-bc4946cb67c3",
					"created_datetime": "2022-03-18T04:50:28.317Z",
					"updated_datetime": "2022-03-18T04:50:28.317Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-110.96311420401213",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "d678f479-2af1-43b3-943e-e35b942e6cfb",
					"created_datetime": "2022-03-18T04:50:28.321Z",
					"updated_datetime": "2022-03-18T04:50:28.321Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_hip",
					"value_string": "98.69879462094093",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "aeaf76b8-f084-479d-bfee-80af120869a8",
					"created_datetime": "2022-03-18T04:50:28.325Z",
					"updated_datetime": "2022-03-18T04:50:28.325Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-102.4941397881955",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "f2f1a54d-0366-48a5-840b-805c0ef2ee37",
					"created_datetime": "2022-03-18T04:50:28.328Z",
					"updated_datetime": "2022-03-18T04:50:28.328Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-177.9188421019736",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "23d41d4f-a90a-4262-aed4-73344cb5ef09",
					"created_datetime": "2022-03-18T04:50:28.331Z",
					"updated_datetime": "2022-03-18T04:50:28.331Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-166.07167090720702",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "290841e7-6906-4634-83cd-c62415f5502c",
					"created_datetime": "2022-03-18T04:50:28.334Z",
					"updated_datetime": "2022-03-18T04:50:28.334Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_composite",
					"value_string": "0.37143834813888443",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "0c005bbe-4978-4a56-a825-6c66c296cb2c",
					"created_datetime": "2022-03-18T04:50:28.338Z",
					"updated_datetime": "2022-03-18T04:50:28.338Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility",
					"value_string": "0.5634911715724896",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "05d6e7e9-c9a1-4043-81ed-75bd3f7905c5",
					"created_datetime": "2022-03-18T04:50:28.341Z",
					"updated_datetime": "2022-03-18T04:50:28.341Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability",
					"value_string": "0.4541494105370877",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "0a888489-7ed4-4a9e-91a8-a5919795b4c7",
					"created_datetime": "2022-03-18T04:50:28.344Z",
					"updated_datetime": "2022-03-18T04:50:28.344Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_posture",
					"value_string": "0.8532575373038224",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "4349fd49-331f-4d37-9161-da08734b2141",
					"created_datetime": "2022-03-18T04:50:28.346Z",
					"updated_datetime": "2022-03-18T04:50:28.346Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry",
					"value_string": "0.22366782316962663",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "4d99d6e0-e51a-4197-8283-a49314b0b72a",
					"created_datetime": "2022-03-18T04:50:28.350Z",
					"updated_datetime": "2022-03-18T04:50:28.350Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.49197476604249046",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "9c47a2fc-350e-4890-8634-e8ebf571a9ef",
					"created_datetime": "2022-03-18T04:50:28.352Z",
					"updated_datetime": "2022-03-18T04:50:28.352Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.33033828155357353",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "2dfd11b9-83ea-4103-9060-527085130537",
					"created_datetime": "2022-03-18T04:50:28.356Z",
					"updated_datetime": "2022-03-18T04:50:28.356Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.08140065487689363",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "f7ee68d5-6d63-42e1-aa33-3e4deee9112b",
					"created_datetime": "2022-03-18T04:50:28.359Z",
					"updated_datetime": "2022-03-18T04:50:28.359Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.9518274655573142",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "7503904a-ecf0-44f0-bbd0-00915e126363",
					"created_datetime": "2022-03-18T04:50:28.362Z",
					"updated_datetime": "2022-03-18T04:50:28.362Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.9192194556353278",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "15281315-37bd-49f1-847d-66d5b742537c",
					"created_datetime": "2022-03-18T04:50:28.365Z",
					"updated_datetime": "2022-03-18T04:50:28.365Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.17801403122178233",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "f50b62ae-81a6-4fe1-938d-9c5b2ffafbfc",
					"created_datetime": "2022-03-18T04:50:28.368Z",
					"updated_datetime": "2022-03-18T04:50:28.368Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.9103576834861872",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "8971328e-5f88-4c0e-81d3-e9dde13c09d7",
					"created_datetime": "2022-03-18T04:50:28.371Z",
					"updated_datetime": "2022-03-18T04:50:28.371Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.683700661799055",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "e7a4099e-e811-4be4-9289-c885815e759f",
					"created_datetime": "2022-03-18T04:50:28.374Z",
					"updated_datetime": "2022-03-18T04:50:28.374Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.5599027647189871",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "f86b5d72-073a-40a1-9c57-b289c56214c5",
					"created_datetime": "2022-03-18T04:50:28.378Z",
					"updated_datetime": "2022-03-18T04:50:28.378Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.7267030682252169",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "d8be89a9-ced9-4c8d-9265-e417267bc578",
					"created_datetime": "2022-03-18T04:50:28.381Z",
					"updated_datetime": "2022-03-18T04:50:28.381Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.49476989257656734",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "4864f91f-06ec-49cc-92c7-202e1d55c6bd",
					"created_datetime": "2022-03-18T04:50:28.384Z",
					"updated_datetime": "2022-03-18T04:50:28.384Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.9729146622721612",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "7cf4885b-ba33-4e10-9efb-ba0d8c142015",
					"created_datetime": "2022-03-18T04:50:28.387Z",
					"updated_datetime": "2022-03-18T04:50:28.387Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability_scap_left",
					"value_string": "0.8013448394840952",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "07e1f3a9-a373-40a7-9c90-9ea1fac0aa2c",
					"created_datetime": "2022-03-18T04:50:28.390Z",
					"updated_datetime": "2022-03-18T04:50:28.390Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability_scap_right",
					"value_string": "0.3662048576010725",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "cc9ba32a-19f5-42e6-810f-72f57bc874bc",
					"created_datetime": "2022-03-18T04:50:28.393Z",
					"updated_datetime": "2022-03-18T04:50:28.393Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.7092147631490137",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "7357b357-0e62-4296-9bf6-756f65046653",
					"created_datetime": "2022-03-18T04:50:28.396Z",
					"updated_datetime": "2022-03-18T04:50:28.396Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.0919291533301135",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "a3ccfde4-bdf7-4611-a36c-742602627e1f",
					"created_datetime": "2022-03-18T04:50:28.399Z",
					"updated_datetime": "2022-03-18T04:50:28.399Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9565308652584149",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "d4d95acb-3056-491b-92f1-644b96ee0988",
					"created_datetime": "2022-03-18T04:50:28.402Z",
					"updated_datetime": "2022-03-18T04:50:28.402Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.772522186477401",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "788977ab-ee0e-4952-9c58-f214ede89334",
					"created_datetime": "2022-03-18T04:50:28.405Z",
					"updated_datetime": "2022-03-18T04:50:28.405Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.6020748118074284",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "50f5ea29-382a-4884-8300-673e04319b5c",
					"created_datetime": "2022-03-18T04:50:28.408Z",
					"updated_datetime": "2022-03-18T04:50:28.408Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.5078181347013463",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "0c5d302b-5f13-4efb-94f4-435a9aadcbad",
					"created_datetime": "2022-03-18T04:50:28.411Z",
					"updated_datetime": "2022-03-18T04:50:28.411Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8411402164339972",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "b830fa60-4577-428e-933b-ae40b31b83fb",
					"created_datetime": "2022-03-18T04:50:28.414Z",
					"updated_datetime": "2022-03-18T04:50:28.414Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.17210738643070955",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "1f48781a-cefc-4431-81e1-518b912298e6",
					"created_datetime": "2022-03-18T04:50:28.417Z",
					"updated_datetime": "2022-03-18T04:50:28.417Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7493933656007451",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "564d2850-9efc-4109-a2fe-cb4dfc1a5d59",
					"created_datetime": "2022-03-18T04:50:28.420Z",
					"updated_datetime": "2022-03-18T04:50:28.420Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_femur",
					"value_string": "0.30904288201488606",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "a3d2862b-417b-4f17-b268-308f90c5a3ae",
					"created_datetime": "2022-03-18T04:50:28.423Z",
					"updated_datetime": "2022-03-18T04:50:28.423Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_knee",
					"value_string": "0.5148621873133231",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "4ba2a0e7-833a-455e-9ad3-829c23f0480d",
					"created_datetime": "2022-03-18T04:50:28.426Z",
					"updated_datetime": "2022-03-18T04:50:28.426Z",
					"assessment_id_guid": "58e4eced-fc0c-4750-8004-93859ce4fbb8",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.8731151343516849",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
			"created_datetime": "2022-03-18T04:56:17.504Z",
			"updated_datetime": "2022-03-18T04:56:17.504Z",
			"submitter_user_id_guid": "d30a875e-cdf1-464e-9c2b-fbfd677a0d90",
			"athlete_user_id_guid": "f50eefc8-7ef1-486a-ae87-32f667b05a12",
			"external_id_guid": "b9d55c05-a57f-4b02-9108-43a578da30c0",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "33a3f847-3ca4-4814-91a6-b4fd53aa5383",
					"created_datetime": "2022-03-18T04:56:17.509Z",
					"updated_datetime": "2022-03-18T04:56:17.509Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T04:59:06.330Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "e5fda9fc-db30-4c60-854c-40284383b87d",
					"created_datetime": "2022-03-18T04:56:17.514Z",
					"updated_datetime": "2022-03-18T04:56:17.514Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "5eabbeaf-5a40-4360-b6fa-67280a2625bc",
					"created_datetime": "2022-03-18T04:56:17.516Z",
					"updated_datetime": "2022-03-18T04:56:17.517Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "104.66042562748828",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "95700b7e-b828-4579-baab-7a60eb8ea73c",
					"created_datetime": "2022-03-18T04:56:17.520Z",
					"updated_datetime": "2022-03-18T04:56:17.520Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "19.595190184941657",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "18b8ee62-6bad-4230-9ab1-d295afa64950",
					"created_datetime": "2022-03-18T04:56:17.523Z",
					"updated_datetime": "2022-03-18T04:56:17.523Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "139.9665566381883",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "33e8bdf7-27a1-4e98-9d5b-547125b2aa74",
					"created_datetime": "2022-03-18T04:56:17.526Z",
					"updated_datetime": "2022-03-18T04:56:17.526Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "80.01521756741079",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "e203b6d3-bd0f-46b5-a235-3d6c1747b8b1",
					"created_datetime": "2022-03-18T04:56:17.529Z",
					"updated_datetime": "2022-03-18T04:56:17.529Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "170.14194531712343",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "1749bb96-5e4f-4c34-a001-a8fb11e57fe7",
					"created_datetime": "2022-03-18T04:56:17.532Z",
					"updated_datetime": "2022-03-18T04:56:17.532Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-14.961404989421936",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "b88ec28f-ebc3-4422-83ca-7d7b1d616ecb",
					"created_datetime": "2022-03-18T04:56:17.535Z",
					"updated_datetime": "2022-03-18T04:56:17.535Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-114.38540255125237",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "a14e20bc-a18d-4f6b-974c-1a4894538cdf",
					"created_datetime": "2022-03-18T04:56:17.539Z",
					"updated_datetime": "2022-03-18T04:56:17.539Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-83.52034383592205",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "6bfdd53b-946b-4a04-847c-9d9cb0696de4",
					"created_datetime": "2022-03-18T04:56:17.542Z",
					"updated_datetime": "2022-03-18T04:56:17.542Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-175.9743303920688",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "c0d8b158-1eb4-45ab-8c7e-1f0c03f1972b",
					"created_datetime": "2022-03-18T04:56:17.545Z",
					"updated_datetime": "2022-03-18T04:56:17.545Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-162.97518713205406",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "842e793f-ec64-4d7b-8c6b-f9e43e414d35",
					"created_datetime": "2022-03-18T04:56:17.549Z",
					"updated_datetime": "2022-03-18T04:56:17.549Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "95.1698486338783",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "31fdc22d-dfbe-4bd2-9e39-23fdc3ccf867",
					"created_datetime": "2022-03-18T04:56:17.553Z",
					"updated_datetime": "2022-03-18T04:56:17.553Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-144.54878865959768",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "a4b80fa1-8037-48a6-a772-5f83215b636f",
					"created_datetime": "2022-03-18T04:56:17.556Z",
					"updated_datetime": "2022-03-18T04:56:17.556Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_stability_scap_left",
					"value_string": "58.42015358350491",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "dc212c59-83fe-4607-813a-1723928113df",
					"created_datetime": "2022-03-18T04:56:17.559Z",
					"updated_datetime": "2022-03-18T04:56:17.559Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_stability_scap_right",
					"value_string": "166.37770738178784",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "3073cda5-f058-4171-b959-cf4530617dbc",
					"created_datetime": "2022-03-18T04:56:17.562Z",
					"updated_datetime": "2022-03-18T04:56:17.562Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "14.206145732252281",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "088404bf-5dee-42d0-b3a6-023e49add526",
					"created_datetime": "2022-03-18T04:56:17.565Z",
					"updated_datetime": "2022-03-18T04:56:17.565Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "25.47107227990378",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "46cc5d8f-20f6-4071-8c0d-bf0996fb846c",
					"created_datetime": "2022-03-18T04:56:17.568Z",
					"updated_datetime": "2022-03-18T04:56:17.568Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-79.75512055720824",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "08741401-fa3f-47e2-b3aa-771cc023a243",
					"created_datetime": "2022-03-18T04:56:17.571Z",
					"updated_datetime": "2022-03-18T04:56:17.571Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "63.719543740287804",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "51ce3e90-094e-4747-85b6-5db43ee0ad71",
					"created_datetime": "2022-03-18T04:56:17.574Z",
					"updated_datetime": "2022-03-18T04:56:17.574Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "136.49295707805697",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "50cff6cb-348e-4c5a-9e63-0328b9b46d15",
					"created_datetime": "2022-03-18T04:56:17.577Z",
					"updated_datetime": "2022-03-18T04:56:17.577Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "104.65836116992136",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "3794a421-62fe-49f6-8972-94816eafd427",
					"created_datetime": "2022-03-18T04:56:17.580Z",
					"updated_datetime": "2022-03-18T04:56:17.580Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "96.84284118363638",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "c95ca3ab-03cc-4607-a765-456078322553",
					"created_datetime": "2022-03-18T04:56:17.583Z",
					"updated_datetime": "2022-03-18T04:56:17.583Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-67.47936581271733",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "cf601193-c67b-4801-97cb-de791e2eda46",
					"created_datetime": "2022-03-18T04:56:17.587Z",
					"updated_datetime": "2022-03-18T04:56:17.587Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-132.54693041923804",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "075f956a-db18-4c85-8133-9b73db962139",
					"created_datetime": "2022-03-18T04:56:17.590Z",
					"updated_datetime": "2022-03-18T04:56:17.590Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-134.63827006310387",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "d60abe30-1f54-4d36-999a-66d198be6de0",
					"created_datetime": "2022-03-18T04:56:17.594Z",
					"updated_datetime": "2022-03-18T04:56:17.594Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_knee",
					"value_string": "98.61698120873757",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "7b15140d-ea83-422f-a332-8b81d29f1c29",
					"created_datetime": "2022-03-18T04:56:17.598Z",
					"updated_datetime": "2022-03-18T04:56:17.598Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-51.95754006145975",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "f8d71845-49be-447f-913f-3db5163b8b31",
					"created_datetime": "2022-03-18T04:56:17.601Z",
					"updated_datetime": "2022-03-18T04:56:17.601Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_composite",
					"value_string": "0.8523959911350614",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "0914a3a6-4321-4198-895d-fe47862ab2a6",
					"created_datetime": "2022-03-18T04:56:17.604Z",
					"updated_datetime": "2022-03-18T04:56:17.604Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility",
					"value_string": "0.2980002629882325",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "2150ef55-0748-49c9-bce6-6ee033918545",
					"created_datetime": "2022-03-18T04:56:17.607Z",
					"updated_datetime": "2022-03-18T04:56:17.607Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability",
					"value_string": "0.049640271808540117",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "60c434d2-b793-4eb1-8aea-f4058e4b9c04",
					"created_datetime": "2022-03-18T04:56:17.610Z",
					"updated_datetime": "2022-03-18T04:56:17.610Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_posture",
					"value_string": "0.29350029604737327",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "780e2191-8b74-442d-a54e-f3ba78042c56",
					"created_datetime": "2022-03-18T04:56:17.612Z",
					"updated_datetime": "2022-03-18T04:56:17.612Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry",
					"value_string": "0.6654739221030863",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "ff08afb2-4e58-4418-82bb-b0aae6cf994b",
					"created_datetime": "2022-03-18T04:56:17.615Z",
					"updated_datetime": "2022-03-18T04:56:17.615Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.4591039638556956",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "28d9ff51-120b-4a22-8e4d-a91ab1fee522",
					"created_datetime": "2022-03-18T04:56:17.617Z",
					"updated_datetime": "2022-03-18T04:56:17.617Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5600309164568549",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "d31b56e5-7d44-462c-a173-4babe2025c99",
					"created_datetime": "2022-03-18T04:56:17.620Z",
					"updated_datetime": "2022-03-18T04:56:17.620Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.6807374381619298",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "979a38e3-f2bb-4b02-897a-dc17f92320e6",
					"created_datetime": "2022-03-18T04:56:17.622Z",
					"updated_datetime": "2022-03-18T04:56:17.622Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.7982171120670669",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "ecfb7903-8712-4c54-85e1-8447f3cff0a9",
					"created_datetime": "2022-03-18T04:56:17.625Z",
					"updated_datetime": "2022-03-18T04:56:17.625Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5929402439912514",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "aa0d2922-d171-48b0-8486-a6d6146d051b",
					"created_datetime": "2022-03-18T04:56:17.628Z",
					"updated_datetime": "2022-03-18T04:56:17.628Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.9206671478287489",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "bc731226-61bc-4fb0-a3be-1572ff8f6e2d",
					"created_datetime": "2022-03-18T04:56:17.631Z",
					"updated_datetime": "2022-03-18T04:56:17.631Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.8518484800109484",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "4537cf51-5db5-4f6f-bd28-8efeb89a495f",
					"created_datetime": "2022-03-18T04:56:17.634Z",
					"updated_datetime": "2022-03-18T04:56:17.634Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8049832384435724",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "5cfd7039-f4fe-44d8-b8be-dd22bd4cfb5e",
					"created_datetime": "2022-03-18T04:56:17.637Z",
					"updated_datetime": "2022-03-18T04:56:17.637Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.6189443223069768",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "40606590-3e1b-43d1-97d4-4e0fd9a1f86c",
					"created_datetime": "2022-03-18T04:56:17.640Z",
					"updated_datetime": "2022-03-18T04:56:17.640Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.696238142992256",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "93a21c8b-55ab-4848-85d4-6d3142791eab",
					"created_datetime": "2022-03-18T04:56:17.643Z",
					"updated_datetime": "2022-03-18T04:56:17.643Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.858486017933213",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "5a7b497a-c33c-45bc-91cf-ba9d539d3e22",
					"created_datetime": "2022-03-18T04:56:17.646Z",
					"updated_datetime": "2022-03-18T04:56:17.646Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.9313951492823925",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "9ee9356b-fd42-4512-a871-8b307e48ea00",
					"created_datetime": "2022-03-18T04:56:17.649Z",
					"updated_datetime": "2022-03-18T04:56:17.649Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability_scap_left",
					"value_string": "0.25341255236077576",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "8b710031-c6f4-476f-8778-1ec48333732b",
					"created_datetime": "2022-03-18T04:56:17.651Z",
					"updated_datetime": "2022-03-18T04:56:17.651Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability_scap_right",
					"value_string": "0.3955753814928625",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "432a3a00-4fa5-485c-9f74-d51e95ee0164",
					"created_datetime": "2022-03-18T04:56:17.654Z",
					"updated_datetime": "2022-03-18T04:56:17.654Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.1256056230746373",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "61c2e6be-f07b-46ec-a14c-5c3e7a1d55d1",
					"created_datetime": "2022-03-18T04:56:17.657Z",
					"updated_datetime": "2022-03-18T04:56:17.657Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.5355297592801155",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "5bd205ee-557b-4284-a5f6-645a366e5b91",
					"created_datetime": "2022-03-18T04:56:17.660Z",
					"updated_datetime": "2022-03-18T04:56:17.660Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.6543390001820097",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "39c02873-c41a-4094-944d-d9e2208cf09e",
					"created_datetime": "2022-03-18T04:56:17.663Z",
					"updated_datetime": "2022-03-18T04:56:17.663Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.5464805545431826",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "615c64d2-775d-4b4b-95d5-0f9f4307bad8",
					"created_datetime": "2022-03-18T04:56:17.666Z",
					"updated_datetime": "2022-03-18T04:56:17.666Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.48289138483289606",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "60db42f3-66dc-4781-b32f-3e4a6200831b",
					"created_datetime": "2022-03-18T04:56:17.669Z",
					"updated_datetime": "2022-03-18T04:56:17.669Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.7830433309425434",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "3a492a37-3891-4b28-ad45-ca01a8214027",
					"created_datetime": "2022-03-18T04:56:17.671Z",
					"updated_datetime": "2022-03-18T04:56:17.671Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.4364461326078763",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "637ed1b1-8751-426f-ad79-6c83a553a98d",
					"created_datetime": "2022-03-18T04:56:17.674Z",
					"updated_datetime": "2022-03-18T04:56:17.674Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.7146416487533889",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "c56ee137-f0b5-42a9-8210-080e6ee30caa",
					"created_datetime": "2022-03-18T04:56:17.677Z",
					"updated_datetime": "2022-03-18T04:56:17.677Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_hip",
					"value_string": "0.06291954631921556",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "691d2501-dd22-4aa5-bb48-90736ebe1c9e",
					"created_datetime": "2022-03-18T04:56:17.680Z",
					"updated_datetime": "2022-03-18T04:56:17.680Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_femur",
					"value_string": "0.5600638131063767",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "3988f624-4747-40d5-b5fb-1e18f29879c3",
					"created_datetime": "2022-03-18T04:56:17.683Z",
					"updated_datetime": "2022-03-18T04:56:17.683Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_knee",
					"value_string": "0.18661418559500972",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "9cbd557e-eb4d-48df-bbf1-0f4928acbae9",
					"created_datetime": "2022-03-18T04:56:17.686Z",
					"updated_datetime": "2022-03-18T04:56:17.686Z",
					"assessment_id_guid": "a97c20d2-a76e-4a6f-bc42-157f2198f022",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.7564280261333989",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
			"created_datetime": "2022-03-18T04:57:55.199Z",
			"updated_datetime": "2022-03-18T04:57:55.199Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "cf0cca90-a418-4248-9ad6-0d732d761407",
			"external_id_guid": "b1ac2275-9c57-49de-9e9e-74b1c21961d8",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "748b8614-de34-44a6-9f81-5473e61e3936",
					"created_datetime": "2022-03-18T04:57:55.203Z",
					"updated_datetime": "2022-03-18T04:57:55.203Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:00:44.047Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "7255cf88-6998-4625-9ed9-33e64317f742",
					"created_datetime": "2022-03-18T04:57:55.207Z",
					"updated_datetime": "2022-03-18T04:57:55.207Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "fe4269d0-5cfd-4b30-a63a-ba5dd8d56e7b",
					"created_datetime": "2022-03-18T04:57:55.210Z",
					"updated_datetime": "2022-03-18T04:57:55.210Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "82.48725694634055",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "4e6e28c5-428c-4a03-86be-c18eee72e96e",
					"created_datetime": "2022-03-18T04:57:55.213Z",
					"updated_datetime": "2022-03-18T04:57:55.213Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "13.882042710009898",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "070249df-2187-45d8-b49c-9da6417daaa9",
					"created_datetime": "2022-03-18T04:57:55.217Z",
					"updated_datetime": "2022-03-18T04:57:55.217Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "11.586309509673498",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "9e1c7cb3-9f79-41b5-9fbf-100f249c178d",
					"created_datetime": "2022-03-18T04:57:55.220Z",
					"updated_datetime": "2022-03-18T04:57:55.220Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "86.6434454340444",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "ae025ac9-5a78-444a-8c93-fd97a99bdb62",
					"created_datetime": "2022-03-18T04:57:55.223Z",
					"updated_datetime": "2022-03-18T04:57:55.223Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-12.662159754267634",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "3b6a4b63-31f8-41e5-9298-8da4126215c6",
					"created_datetime": "2022-03-18T04:57:55.226Z",
					"updated_datetime": "2022-03-18T04:57:55.226Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-62.73756096098367",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "d12e2e09-0ae6-4c05-b153-6a47ee6b3f7e",
					"created_datetime": "2022-03-18T04:57:55.229Z",
					"updated_datetime": "2022-03-18T04:57:55.229Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "107.50912409948188",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "81fbbbdd-2ba2-423d-83e0-4fa54add0b6c",
					"created_datetime": "2022-03-18T04:57:55.232Z",
					"updated_datetime": "2022-03-18T04:57:55.232Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-104.69150139083793",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "5c642266-8355-400d-a46c-149a1f5b3282",
					"created_datetime": "2022-03-18T04:57:55.235Z",
					"updated_datetime": "2022-03-18T04:57:55.235Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "170.74888183900606",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "3d2262da-53fe-4c56-bb41-0cd99f6d2939",
					"created_datetime": "2022-03-18T04:57:55.239Z",
					"updated_datetime": "2022-03-18T04:57:55.239Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-125.16312236972149",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "96add29c-1e04-49fd-ba65-7eb44784b200",
					"created_datetime": "2022-03-18T04:57:55.243Z",
					"updated_datetime": "2022-03-18T04:57:55.243Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-120.32589829928523",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "d52e2135-e787-48fc-9f43-16ceeaa7e0f7",
					"created_datetime": "2022-03-18T04:57:55.246Z",
					"updated_datetime": "2022-03-18T04:57:55.246Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "59.44622289108918",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "8e3f66a1-b8c8-40a0-a201-357a8d8b0b79",
					"created_datetime": "2022-03-18T04:57:55.249Z",
					"updated_datetime": "2022-03-18T04:57:55.249Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_stability_scap_left",
					"value_string": "17.461098852672762",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "43007286-1c7a-433b-a1b4-9b0a420b9951",
					"created_datetime": "2022-03-18T04:57:55.252Z",
					"updated_datetime": "2022-03-18T04:57:55.252Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-118.39248455817014",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "43714d59-0343-4dda-a675-558dfe4066c3",
					"created_datetime": "2022-03-18T04:57:55.255Z",
					"updated_datetime": "2022-03-18T04:57:55.255Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-78.01934745886844",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "85e0eec0-b18a-484c-848f-8b6fd7d1f4a2",
					"created_datetime": "2022-03-18T04:57:55.258Z",
					"updated_datetime": "2022-03-18T04:57:55.258Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-136.9558281499038",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "df8d3196-0b8b-4f8b-966d-aa1b89635cdb",
					"created_datetime": "2022-03-18T04:57:55.261Z",
					"updated_datetime": "2022-03-18T04:57:55.261Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-69.00823818410781",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "85942f5b-693f-41f7-8d59-4eb52564fba3",
					"created_datetime": "2022-03-18T04:57:55.264Z",
					"updated_datetime": "2022-03-18T04:57:55.264Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "155.91427524696968",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "70a4ae80-6c58-4252-a1db-3bf6f3815014",
					"created_datetime": "2022-03-18T04:57:55.267Z",
					"updated_datetime": "2022-03-18T04:57:55.267Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-138.28951056115125",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "69409c4c-6c6a-492c-9689-03b84398a1b0",
					"created_datetime": "2022-03-18T04:57:55.270Z",
					"updated_datetime": "2022-03-18T04:57:55.270Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "43.76094290582216",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "868ca787-05f5-4b78-9523-4b8164941799",
					"created_datetime": "2022-03-18T04:57:55.273Z",
					"updated_datetime": "2022-03-18T04:57:55.273Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-56.52702917406802",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "02092a2f-126d-44bc-858c-1e2331cbcba7",
					"created_datetime": "2022-03-18T04:57:55.275Z",
					"updated_datetime": "2022-03-18T04:57:55.275Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-141.89040306110138",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "81829fc5-2257-49b7-8263-58dab99548f1",
					"created_datetime": "2022-03-18T04:57:55.278Z",
					"updated_datetime": "2022-03-18T04:57:55.278Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-20.986082665297317",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "c962bcc2-e976-4d85-96b4-079f355a01a3",
					"created_datetime": "2022-03-18T04:57:55.281Z",
					"updated_datetime": "2022-03-18T04:57:55.281Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-49.88407309326931",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ea57984e-6157-4ffe-b785-2aca0afb4d47",
					"created_datetime": "2022-03-18T04:57:55.284Z",
					"updated_datetime": "2022-03-18T04:57:55.284Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_knee",
					"value_string": "136.33240575186363",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "3b67f926-4331-4603-927c-167f20dabedc",
					"created_datetime": "2022-03-18T04:57:55.287Z",
					"updated_datetime": "2022-03-18T04:57:55.287Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "127.67418949187675",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "caf0079d-afff-4f65-8ad6-81c4b8dad0de",
					"created_datetime": "2022-03-18T04:57:55.290Z",
					"updated_datetime": "2022-03-18T04:57:55.290Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_composite",
					"value_string": "0.555991549403518",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "71e9abcc-8fb2-4410-b88e-3a3f7abb8a20",
					"created_datetime": "2022-03-18T04:57:55.293Z",
					"updated_datetime": "2022-03-18T04:57:55.293Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility",
					"value_string": "0.12164419538712848",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "c80eedfa-68ab-4726-8c54-f6a0eabc4e88",
					"created_datetime": "2022-03-18T04:57:55.296Z",
					"updated_datetime": "2022-03-18T04:57:55.296Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability",
					"value_string": "0.8375280219596364",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "679657fa-4c10-4f99-ba2f-4f390e4eae66",
					"created_datetime": "2022-03-18T04:57:55.300Z",
					"updated_datetime": "2022-03-18T04:57:55.300Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_posture",
					"value_string": "0.46587224201560706",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "89e26ade-9366-46f3-b76c-32202333d57a",
					"created_datetime": "2022-03-18T04:57:55.303Z",
					"updated_datetime": "2022-03-18T04:57:55.303Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry",
					"value_string": "0.5938583789390444",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "cfce7a07-17cd-47ac-972d-e090f09d7d20",
					"created_datetime": "2022-03-18T04:57:55.317Z",
					"updated_datetime": "2022-03-18T04:57:55.317Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.9707258485228762",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "0cbc5480-8d69-4928-8039-a91e5c580051",
					"created_datetime": "2022-03-18T04:57:55.320Z",
					"updated_datetime": "2022-03-18T04:57:55.320Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.484368766796628",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "ff4d7f20-467c-4a10-9606-b03eb898c53b",
					"created_datetime": "2022-03-18T04:57:55.324Z",
					"updated_datetime": "2022-03-18T04:57:55.324Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.5113203845352218",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "7c2f20a2-f944-48cd-82ce-061eec966297",
					"created_datetime": "2022-03-18T04:57:55.327Z",
					"updated_datetime": "2022-03-18T04:57:55.327Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.32299475230865005",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "2bc73388-0b5a-40e0-accf-0f26ea6c0b47",
					"created_datetime": "2022-03-18T04:57:55.333Z",
					"updated_datetime": "2022-03-18T04:57:55.333Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.039062877584209676",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "537e899c-cb50-4fb5-a739-0271406c38d6",
					"created_datetime": "2022-03-18T04:57:55.336Z",
					"updated_datetime": "2022-03-18T04:57:55.336Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.305690798189007",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "f4e73b91-ae4d-4a12-ab35-70f81e0483df",
					"created_datetime": "2022-03-18T04:57:55.339Z",
					"updated_datetime": "2022-03-18T04:57:55.339Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.06589844226786587",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "28efbd7e-fd69-43d2-8d07-781a891b01ce",
					"created_datetime": "2022-03-18T04:57:55.342Z",
					"updated_datetime": "2022-03-18T04:57:55.342Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.48741336620341036",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "d5b27890-996c-4b45-9e6d-b35db581988f",
					"created_datetime": "2022-03-18T04:57:55.345Z",
					"updated_datetime": "2022-03-18T04:57:55.345Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9466786597524324",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "d248e455-7112-4851-acc5-2afb51f17f22",
					"created_datetime": "2022-03-18T04:57:55.348Z",
					"updated_datetime": "2022-03-18T04:57:55.348Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.7233447072583701",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "f39396e3-82d3-4f42-b4c6-f52a5da62367",
					"created_datetime": "2022-03-18T04:57:55.350Z",
					"updated_datetime": "2022-03-18T04:57:55.350Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.0028573200862791934",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "544ac59e-3b43-4ba3-8e50-d6adaa1adfc3",
					"created_datetime": "2022-03-18T04:57:55.353Z",
					"updated_datetime": "2022-03-18T04:57:55.353Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.333613536818747",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "82eaaf99-e632-4c2d-8d35-1fb16a98b9e1",
					"created_datetime": "2022-03-18T04:57:55.356Z",
					"updated_datetime": "2022-03-18T04:57:55.356Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability_scap_left",
					"value_string": "0.6597672078803815",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "76523ed9-c6cb-4301-8f5d-d7cd8849057a",
					"created_datetime": "2022-03-18T04:57:55.359Z",
					"updated_datetime": "2022-03-18T04:57:55.359Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability_scap_right",
					"value_string": "0.4082034781598911",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "bea479ce-6b06-44c0-86a4-f7d6bbf3fe78",
					"created_datetime": "2022-03-18T04:57:55.362Z",
					"updated_datetime": "2022-03-18T04:57:55.362Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.37424158344347014",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "3ccb7579-f2b7-455d-ac19-6861d6853b70",
					"created_datetime": "2022-03-18T04:57:55.364Z",
					"updated_datetime": "2022-03-18T04:57:55.364Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.14116652167139274",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "5f387820-08d9-4610-85e0-1c20ff8f659b",
					"created_datetime": "2022-03-18T04:57:55.367Z",
					"updated_datetime": "2022-03-18T04:57:55.367Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.38524544585090853",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "cef1a072-ea8e-437e-9236-68fd0f2a6412",
					"created_datetime": "2022-03-18T04:57:55.369Z",
					"updated_datetime": "2022-03-18T04:57:55.369Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.8658500362583433",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "8750cce3-fec4-4141-9d8f-e041876bec58",
					"created_datetime": "2022-03-18T04:57:55.372Z",
					"updated_datetime": "2022-03-18T04:57:55.372Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.30377060061208383",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "69bc6e99-dbd7-4342-b773-d2beb79b3aba",
					"created_datetime": "2022-03-18T04:57:55.375Z",
					"updated_datetime": "2022-03-18T04:57:55.375Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.3036561108539711",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "ea83068e-0679-4469-95a5-052eb941a676",
					"created_datetime": "2022-03-18T04:57:55.378Z",
					"updated_datetime": "2022-03-18T04:57:55.378Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.0023256795319258126",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "9c63d2ec-d5eb-472f-b3db-a979855c417d",
					"created_datetime": "2022-03-18T04:57:55.380Z",
					"updated_datetime": "2022-03-18T04:57:55.380Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.5874826640453408",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "b9adad12-8dfd-44f2-96d4-11f920118b00",
					"created_datetime": "2022-03-18T04:57:55.383Z",
					"updated_datetime": "2022-03-18T04:57:55.383Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_hip",
					"value_string": "0.2002163083852236",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "054d31d7-64da-42d8-9653-4c044c5ce154",
					"created_datetime": "2022-03-18T04:57:55.386Z",
					"updated_datetime": "2022-03-18T04:57:55.386Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_femur",
					"value_string": "0.13094957841401583",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "07a5118c-8f82-41c6-b09e-62f5ccefd3d8",
					"created_datetime": "2022-03-18T04:57:55.389Z",
					"updated_datetime": "2022-03-18T04:57:55.389Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_knee",
					"value_string": "0.48296636246477787",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "915a625b-8e7b-4871-a01d-cda332e4e593",
					"created_datetime": "2022-03-18T04:57:55.392Z",
					"updated_datetime": "2022-03-18T04:57:55.392Z",
					"assessment_id_guid": "ad405368-4dae-442d-a6ef-efc740c5d676",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.12154387843450512",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
			"created_datetime": "2022-03-18T04:59:30.647Z",
			"updated_datetime": "2022-03-18T04:59:30.647Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "29d2df78-0bbe-4733-8aff-611a8dc6d748",
			"external_id_guid": "d5e9b166-d12e-4acb-92c5-025b2eaaf0a1",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "fade1693-508c-4cb9-b133-4ad567db85b8",
					"created_datetime": "2022-03-18T04:59:30.651Z",
					"updated_datetime": "2022-03-18T04:59:30.651Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:02:19.477Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "24263dfd-eea0-4a95-bbe5-6b23b1bd3bd5",
					"created_datetime": "2022-03-18T04:59:30.655Z",
					"updated_datetime": "2022-03-18T04:59:30.655Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "b3acf620-a0ab-47a6-8760-279eb4ca84ef",
					"created_datetime": "2022-03-18T04:59:30.658Z",
					"updated_datetime": "2022-03-18T04:59:30.658Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "45.00053241569765",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "6cade040-ec76-41b5-9b08-f54f4625a52e",
					"created_datetime": "2022-03-18T04:59:30.662Z",
					"updated_datetime": "2022-03-18T04:59:30.662Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-11.864060523555423",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "c91e0fae-ad77-4b50-9558-cdfcd477dd1c",
					"created_datetime": "2022-03-18T04:59:30.665Z",
					"updated_datetime": "2022-03-18T04:59:30.665Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "77.88990570716373",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "58d213be-15ca-48df-ae26-9de5b2079cca",
					"created_datetime": "2022-03-18T04:59:30.668Z",
					"updated_datetime": "2022-03-18T04:59:30.668Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "17.434234534436968",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "80da058d-ce20-49f0-90e3-2a67003b4bf8",
					"created_datetime": "2022-03-18T04:59:30.671Z",
					"updated_datetime": "2022-03-18T04:59:30.671Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "179.20222659389344",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "c45c4f03-9e3d-4bd4-9222-6edfd47e2422",
					"created_datetime": "2022-03-18T04:59:30.675Z",
					"updated_datetime": "2022-03-18T04:59:30.675Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "147.30458779083773",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "3edf21d7-4561-4250-b190-15b9d8625d68",
					"created_datetime": "2022-03-18T04:59:30.678Z",
					"updated_datetime": "2022-03-18T04:59:30.678Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-67.38110390054874",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "99c0d343-b216-44d8-b220-7ba948d82071",
					"created_datetime": "2022-03-18T04:59:30.681Z",
					"updated_datetime": "2022-03-18T04:59:30.681Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-123.84491935599834",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "013a7de9-5a52-47d0-b64f-ca262cefce0c",
					"created_datetime": "2022-03-18T04:59:30.685Z",
					"updated_datetime": "2022-03-18T04:59:30.685Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "42.035313111062635",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "72e2ad67-b3d4-4212-9277-8ce690e80049",
					"created_datetime": "2022-03-18T04:59:30.690Z",
					"updated_datetime": "2022-03-18T04:59:30.690Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "71.41885230555701",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "8a045f13-bcf4-42f1-addf-1e65713884d7",
					"created_datetime": "2022-03-18T04:59:30.693Z",
					"updated_datetime": "2022-03-18T04:59:30.693Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "22.081439920991244",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "6325d528-3122-408c-b08e-f71fe8df9f8d",
					"created_datetime": "2022-03-18T04:59:30.698Z",
					"updated_datetime": "2022-03-18T04:59:30.698Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "26.653631178582742",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "1b38a517-a0e1-4590-971d-bcc04feb44af",
					"created_datetime": "2022-03-18T04:59:30.701Z",
					"updated_datetime": "2022-03-18T04:59:30.701Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_stability_scap_left",
					"value_string": "55.12944300463147",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "bc3fa20a-3bc5-4afb-a305-09cd49681a24",
					"created_datetime": "2022-03-18T04:59:30.704Z",
					"updated_datetime": "2022-03-18T04:59:30.704Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_stability_scap_right",
					"value_string": "10.434166675936297",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "772709c6-ef32-4356-9bad-5b11810112d3",
					"created_datetime": "2022-03-18T04:59:30.707Z",
					"updated_datetime": "2022-03-18T04:59:30.707Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "123.25619307665232",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "f369d3a8-e21f-41ae-8643-75ab9e8016ff",
					"created_datetime": "2022-03-18T04:59:30.710Z",
					"updated_datetime": "2022-03-18T04:59:30.710Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-101.95459424950398",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "1fdfe7b1-3394-4105-8e66-f3727bf2d064",
					"created_datetime": "2022-03-18T04:59:30.713Z",
					"updated_datetime": "2022-03-18T04:59:30.713Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-87.65982814380395",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "725765d4-6b27-4e13-9603-e2d1c5ecc87f",
					"created_datetime": "2022-03-18T04:59:30.716Z",
					"updated_datetime": "2022-03-18T04:59:30.716Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "-76.87607725156893",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "189b4ed3-bb59-450a-94af-15c7a6d073a3",
					"created_datetime": "2022-03-18T04:59:30.719Z",
					"updated_datetime": "2022-03-18T04:59:30.719Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-147.6168594153195",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "1ab94def-5513-4cd4-b461-731880078562",
					"created_datetime": "2022-03-18T04:59:30.722Z",
					"updated_datetime": "2022-03-18T04:59:30.722Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "156.51063726373377",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "d72166df-024c-4322-b6d9-ab9667435d33",
					"created_datetime": "2022-03-18T04:59:30.725Z",
					"updated_datetime": "2022-03-18T04:59:30.725Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "66.46875464634721",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "6f078f2a-9a14-4727-ba42-1f106f18dea4",
					"created_datetime": "2022-03-18T04:59:30.728Z",
					"updated_datetime": "2022-03-18T04:59:30.728Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-30.840880783583117",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "bd157312-0f3e-42c7-96be-68765e0ee71e",
					"created_datetime": "2022-03-18T04:59:30.732Z",
					"updated_datetime": "2022-03-18T04:59:30.732Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-70.78826753737813",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "e9f3520c-263d-4713-afaa-349ee7fe2a1b",
					"created_datetime": "2022-03-18T04:59:30.736Z",
					"updated_datetime": "2022-03-18T04:59:30.736Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_femur",
					"value_string": "139.70068833906868",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "263c9e2a-f2da-4b5e-95e3-1ef20314b88d",
					"created_datetime": "2022-03-18T04:59:30.741Z",
					"updated_datetime": "2022-03-18T04:59:30.741Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_knee",
					"value_string": "173.13220077300355",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "08772b14-aa19-47f3-89db-0f66c71c8ff3",
					"created_datetime": "2022-03-18T04:59:30.744Z",
					"updated_datetime": "2022-03-18T04:59:30.744Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-176.07512162086516",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "5fbf5b40-b6c3-44e0-befd-a26f8a13c6b0",
					"created_datetime": "2022-03-18T04:59:30.747Z",
					"updated_datetime": "2022-03-18T04:59:30.747Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_composite",
					"value_string": "0.5145447706635172",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "362f9e54-d874-48f3-9ed0-ecc66301d44a",
					"created_datetime": "2022-03-18T04:59:30.751Z",
					"updated_datetime": "2022-03-18T04:59:30.751Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility",
					"value_string": "0.47022665597822566",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "a2e672c6-e906-44f0-856a-4c6fb306fbe7",
					"created_datetime": "2022-03-18T04:59:30.754Z",
					"updated_datetime": "2022-03-18T04:59:30.754Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability",
					"value_string": "0.6173485309304805",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "5b717c2a-f24b-42b5-b015-8bbda37c3119",
					"created_datetime": "2022-03-18T04:59:30.757Z",
					"updated_datetime": "2022-03-18T04:59:30.757Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_posture",
					"value_string": "0.3031084911196947",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "f7145f01-f424-480f-8cdb-34c06a8dda3d",
					"created_datetime": "2022-03-18T04:59:30.760Z",
					"updated_datetime": "2022-03-18T04:59:30.760Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry",
					"value_string": "0.597627612046016",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "757b6c25-271d-4563-8a7c-79d7f4b71284",
					"created_datetime": "2022-03-18T04:59:30.763Z",
					"updated_datetime": "2022-03-18T04:59:30.763Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.42391333906296697",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "df14bc7b-79e3-414f-9fb2-6f787ed01c50",
					"created_datetime": "2022-03-18T04:59:30.767Z",
					"updated_datetime": "2022-03-18T04:59:30.767Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.23349201858779728",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "6e1f9246-c256-4d8a-83fa-e6f5f89a7766",
					"created_datetime": "2022-03-18T04:59:30.770Z",
					"updated_datetime": "2022-03-18T04:59:30.770Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.13359263773860194",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "e96841d2-623a-49b3-93aa-08cbfd92596e",
					"created_datetime": "2022-03-18T04:59:30.773Z",
					"updated_datetime": "2022-03-18T04:59:30.773Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.4058269882190982",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "0171e0f8-f66c-438b-aa6b-dfc1bbfeca33",
					"created_datetime": "2022-03-18T04:59:30.776Z",
					"updated_datetime": "2022-03-18T04:59:30.776Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.6126971176592164",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "9ad2bb82-19db-4760-b8e5-4776fbb88c49",
					"created_datetime": "2022-03-18T04:59:30.779Z",
					"updated_datetime": "2022-03-18T04:59:30.779Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.7363699656561676",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "aa39f83b-1a0b-4ce7-8e1e-ffbf9bc01cba",
					"created_datetime": "2022-03-18T04:59:30.782Z",
					"updated_datetime": "2022-03-18T04:59:30.782Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.4434104067606112",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "47e51f9c-8e42-4900-8bb4-9aa30ab166c1",
					"created_datetime": "2022-03-18T04:59:30.785Z",
					"updated_datetime": "2022-03-18T04:59:30.785Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.8337531633402786",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "d6d28767-3829-48ef-9e0d-548c10a57384",
					"created_datetime": "2022-03-18T04:59:30.789Z",
					"updated_datetime": "2022-03-18T04:59:30.789Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8588834996986641",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "47dbe67f-e3c9-4010-aa6b-4b901b94aeec",
					"created_datetime": "2022-03-18T04:59:30.792Z",
					"updated_datetime": "2022-03-18T04:59:30.792Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.6102630374269479",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "e25a0820-a94d-4f77-b933-ce0532b0e33d",
					"created_datetime": "2022-03-18T04:59:30.796Z",
					"updated_datetime": "2022-03-18T04:59:30.796Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.6835279259725007",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "b84102f5-24df-4557-af4e-0ad5c72a5239",
					"created_datetime": "2022-03-18T04:59:30.799Z",
					"updated_datetime": "2022-03-18T04:59:30.799Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.6507289405465255",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "5f8f8600-4cf2-4406-83f5-1ead9f045f50",
					"created_datetime": "2022-03-18T04:59:30.802Z",
					"updated_datetime": "2022-03-18T04:59:30.802Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9041541319984524",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "19e4fa62-25e8-462d-b5f0-bb8fc33d96b9",
					"created_datetime": "2022-03-18T04:59:30.805Z",
					"updated_datetime": "2022-03-18T04:59:30.805Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability_scap_right",
					"value_string": "0.6365982686850997",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "e56bac4d-be06-442f-8f23-4e79749207b4",
					"created_datetime": "2022-03-18T04:59:30.808Z",
					"updated_datetime": "2022-03-18T04:59:30.808Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.2612791189479324",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "2d0c16ca-7844-4dc3-ab8f-8178476fc5b4",
					"created_datetime": "2022-03-18T04:59:30.811Z",
					"updated_datetime": "2022-03-18T04:59:30.811Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.6210993800952193",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "23978ba0-949a-4317-95c4-a6c15f5b00ac",
					"created_datetime": "2022-03-18T04:59:30.814Z",
					"updated_datetime": "2022-03-18T04:59:30.814Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9642613506550062",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "ffe0843f-9f3b-4598-ae22-eada30b41a22",
					"created_datetime": "2022-03-18T04:59:30.817Z",
					"updated_datetime": "2022-03-18T04:59:30.817Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.1777238644249817",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "1c57ab4b-befe-4595-9022-9ad0fd1bd046",
					"created_datetime": "2022-03-18T04:59:30.821Z",
					"updated_datetime": "2022-03-18T04:59:30.821Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.8032548358075139",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "74c5dfd4-19b4-4787-9dd1-1697c284896d",
					"created_datetime": "2022-03-18T04:59:30.824Z",
					"updated_datetime": "2022-03-18T04:59:30.824Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.7915182469214845",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "5893dae0-0990-44ad-a056-559b058ae92a",
					"created_datetime": "2022-03-18T04:59:30.827Z",
					"updated_datetime": "2022-03-18T04:59:30.827Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.6125068675233732",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "11887660-c50f-4512-a5a1-c11c782e4669",
					"created_datetime": "2022-03-18T04:59:30.830Z",
					"updated_datetime": "2022-03-18T04:59:30.830Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.23930660357961242",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "dd2a59a2-8994-4197-a504-79ac509334da",
					"created_datetime": "2022-03-18T04:59:30.833Z",
					"updated_datetime": "2022-03-18T04:59:30.833Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_hip",
					"value_string": "0.6226572234296794",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "5e79ad4f-84f5-47e6-900e-5a34af6cdfdc",
					"created_datetime": "2022-03-18T04:59:30.838Z",
					"updated_datetime": "2022-03-18T04:59:30.838Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_femur",
					"value_string": "0.5345381603716455",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "a94030f1-960f-43d2-b048-ea3b6374b8f3",
					"created_datetime": "2022-03-18T04:59:30.841Z",
					"updated_datetime": "2022-03-18T04:59:30.841Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_knee",
					"value_string": "0.8649324822030316",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "695e919c-3405-409c-aeb6-85dd38e73405",
					"created_datetime": "2022-03-18T04:59:30.844Z",
					"updated_datetime": "2022-03-18T04:59:30.844Z",
					"assessment_id_guid": "ea21b1ae-b3e4-4731-8316-889e043ff199",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.5264309239248103",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
			"created_datetime": "2022-03-18T05:14:38.444Z",
			"updated_datetime": "2022-03-18T05:14:38.444Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "26993e73-ecb9-478a-b610-56a62688556b",
			"external_id_guid": "6f61055b-0ed6-4608-b62e-8fc4d305b1a1",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "668bf9cd-6a36-4e56-8a3e-f0dd7e598ba0",
					"created_datetime": "2022-03-18T05:14:38.449Z",
					"updated_datetime": "2022-03-18T05:14:38.449Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:17:27.282Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "ada208bd-7d60-4607-8172-276ae7bcad90",
					"created_datetime": "2022-03-18T05:14:38.453Z",
					"updated_datetime": "2022-03-18T05:14:38.453Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "d81b81a3-ed2f-434b-813f-96822cf9b98c",
					"created_datetime": "2022-03-18T05:14:38.456Z",
					"updated_datetime": "2022-03-18T05:14:38.456Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-152.65972524153952",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "20b0017e-fe4e-4f80-80d1-9809da519172",
					"created_datetime": "2022-03-18T05:14:38.459Z",
					"updated_datetime": "2022-03-18T05:14:38.459Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "41.695816553935515",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "9d4238b9-0c17-41d7-b1fc-7420ed1c724a",
					"created_datetime": "2022-03-18T05:14:38.463Z",
					"updated_datetime": "2022-03-18T05:14:38.463Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "58.31776285269058",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "c86e0d2e-9c23-43dd-a1ad-f54a511b23a9",
					"created_datetime": "2022-03-18T05:14:38.466Z",
					"updated_datetime": "2022-03-18T05:14:38.466Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "35.3801207865462",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "8c4f7f85-6a93-4320-8bf1-ab8c84e0a6ac",
					"created_datetime": "2022-03-18T05:14:38.469Z",
					"updated_datetime": "2022-03-18T05:14:38.469Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "60.45407148095521",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "66d4ed1a-690a-4602-b706-fbe7dfc774d1",
					"created_datetime": "2022-03-18T05:14:38.473Z",
					"updated_datetime": "2022-03-18T05:14:38.473Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-83.74944149189238",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "160d7b38-ebaf-4b5c-9d30-c275aae537cf",
					"created_datetime": "2022-03-18T05:14:38.476Z",
					"updated_datetime": "2022-03-18T05:14:38.476Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-137.62682309415698",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "36beffc4-0b49-457e-956d-05f03a9d7f8f",
					"created_datetime": "2022-03-18T05:14:38.479Z",
					"updated_datetime": "2022-03-18T05:14:38.479Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "-171.4174735576939",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "857b2c74-c6ec-4012-b332-0fd88a8ce38b",
					"created_datetime": "2022-03-18T05:14:38.482Z",
					"updated_datetime": "2022-03-18T05:14:38.482Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-168.23506067194552",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "d710ac20-cf68-4661-9d4f-cbe8f4f0fd72",
					"created_datetime": "2022-03-18T05:14:38.485Z",
					"updated_datetime": "2022-03-18T05:14:38.485Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-87.71935061506335",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "b32bb5cc-28e4-4acf-8121-1b9db2f3bcce",
					"created_datetime": "2022-03-18T05:14:38.491Z",
					"updated_datetime": "2022-03-18T05:14:38.491Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-52.10071488412713",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "c92a9754-632a-4fb5-a95b-4f721c27aa1e",
					"created_datetime": "2022-03-18T05:14:38.495Z",
					"updated_datetime": "2022-03-18T05:14:38.495Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-86.78686928710918",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "93a31792-68f5-47f2-88dd-b31682a48ae8",
					"created_datetime": "2022-03-18T05:14:38.498Z",
					"updated_datetime": "2022-03-18T05:14:38.498Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_stability_scap_left",
					"value_string": "33.98736597500846",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "3145705d-fee1-4555-b1bd-108529699670",
					"created_datetime": "2022-03-18T05:14:38.501Z",
					"updated_datetime": "2022-03-18T05:14:38.501Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-43.0286230626792",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "4e33ad8e-6975-4791-95c3-923bcdc7907e",
					"created_datetime": "2022-03-18T05:14:38.504Z",
					"updated_datetime": "2022-03-18T05:14:38.504Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "78.43816334368432",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "b668f7a1-fa1b-4b55-a097-8b7992274aac",
					"created_datetime": "2022-03-18T05:14:38.507Z",
					"updated_datetime": "2022-03-18T05:14:38.507Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "168.40586103943969",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "f9bde14d-d5f1-49c6-bc07-5467ec970ccf",
					"created_datetime": "2022-03-18T05:14:38.511Z",
					"updated_datetime": "2022-03-18T05:14:38.511Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "161.5424354612593",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "bb9ef9d4-5146-4881-b419-86898ad50828",
					"created_datetime": "2022-03-18T05:14:38.526Z",
					"updated_datetime": "2022-03-18T05:14:38.526Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "135.16689388489203",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "fa9e646e-2922-46bc-b7be-3947d3a2d83d",
					"created_datetime": "2022-03-18T05:14:38.531Z",
					"updated_datetime": "2022-03-18T05:14:38.531Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "166.41085681464",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "21e74cbc-1a2a-4b19-90b1-16f1dceb6bb5",
					"created_datetime": "2022-03-18T05:14:38.535Z",
					"updated_datetime": "2022-03-18T05:14:38.535Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "153.51064010289173",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "850f7d24-1316-437b-8868-59bed823e2f6",
					"created_datetime": "2022-03-18T05:14:38.538Z",
					"updated_datetime": "2022-03-18T05:14:38.538Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "138.61515558254428",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "b10ccc99-fe61-46df-8c9b-2ed25588c8a3",
					"created_datetime": "2022-03-18T05:14:38.541Z",
					"updated_datetime": "2022-03-18T05:14:38.541Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-161.7806983904379",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "2bc65b3d-9208-4ba2-8c4a-4818e91789f4",
					"created_datetime": "2022-03-18T05:14:38.544Z",
					"updated_datetime": "2022-03-18T05:14:38.544Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_hip",
					"value_string": "64.19353891961",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "1bf0544d-3bfa-445c-9cce-4c41a062443d",
					"created_datetime": "2022-03-18T05:14:38.547Z",
					"updated_datetime": "2022-03-18T05:14:38.547Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_femur",
					"value_string": "87.73515683242636",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "d36a5735-688a-4c78-bd15-9918752ba0ea",
					"created_datetime": "2022-03-18T05:14:38.550Z",
					"updated_datetime": "2022-03-18T05:14:38.550Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-157.85865808185582",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "e8943b44-73d1-4de8-8c62-7f6d54e87544",
					"created_datetime": "2022-03-18T05:14:38.555Z",
					"updated_datetime": "2022-03-18T05:14:38.555Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "125.9088786897371",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "ab12430f-8ed1-4dc3-b514-48189be3eae8",
					"created_datetime": "2022-03-18T05:14:38.558Z",
					"updated_datetime": "2022-03-18T05:14:38.558Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_composite",
					"value_string": "0.37509282546808714",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "998a263f-b233-448c-9513-5e88bd81fbc6",
					"created_datetime": "2022-03-18T05:14:38.561Z",
					"updated_datetime": "2022-03-18T05:14:38.561Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility",
					"value_string": "0.6041281306201287",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "36625839-8081-46b9-bd29-21a11fd76823",
					"created_datetime": "2022-03-18T05:14:38.565Z",
					"updated_datetime": "2022-03-18T05:14:38.565Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability",
					"value_string": "0.022760298311186776",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "da9a6971-0607-473e-acaa-66b579018b0e",
					"created_datetime": "2022-03-18T05:14:38.568Z",
					"updated_datetime": "2022-03-18T05:14:38.568Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_posture",
					"value_string": "0.5195830945140392",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "09edb2ec-7fc4-4031-99fb-446cd7d2226b",
					"created_datetime": "2022-03-18T05:14:38.574Z",
					"updated_datetime": "2022-03-18T05:14:38.574Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry",
					"value_string": "0.1302188080116891",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "3dcf6835-dfd4-4150-b6ba-1dfc2ba4b5a7",
					"created_datetime": "2022-03-18T05:14:38.577Z",
					"updated_datetime": "2022-03-18T05:14:38.577Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.015416724708427892",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "0b11ec6b-f7b1-4486-b9be-5510beb2121e",
					"created_datetime": "2022-03-18T05:14:38.580Z",
					"updated_datetime": "2022-03-18T05:14:38.580Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.5055305932616012",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "da8800cc-6c78-4e32-a888-29fc322e0668",
					"created_datetime": "2022-03-18T05:14:38.583Z",
					"updated_datetime": "2022-03-18T05:14:38.583Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.23509690728015759",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "d5393ca4-70e2-41eb-ae99-e010e57c3b7f",
					"created_datetime": "2022-03-18T05:14:38.586Z",
					"updated_datetime": "2022-03-18T05:14:38.586Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.49426230136655236",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "bdcc192c-7cce-4318-80dc-a0e9a719436a",
					"created_datetime": "2022-03-18T05:14:38.589Z",
					"updated_datetime": "2022-03-18T05:14:38.589Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.2614654588007379",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "ab92ed34-be88-40fc-a19b-34fefd25bb6c",
					"created_datetime": "2022-03-18T05:14:38.592Z",
					"updated_datetime": "2022-03-18T05:14:38.592Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.24059571150522796",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "485d54f6-5d3e-4ef7-afbb-956c71ecbf05",
					"created_datetime": "2022-03-18T05:14:38.597Z",
					"updated_datetime": "2022-03-18T05:14:38.597Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.1582095537346049",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "ac6c10b8-18f5-45fe-bd01-6f3fbda6a086",
					"created_datetime": "2022-03-18T05:14:38.600Z",
					"updated_datetime": "2022-03-18T05:14:38.600Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.0999444758336509",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "d61c29fd-14dc-4f17-b1cd-c941cfb72e7b",
					"created_datetime": "2022-03-18T05:14:38.603Z",
					"updated_datetime": "2022-03-18T05:14:38.603Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.9169310982236143",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "789fe448-b995-4417-81ce-570e6511d21f",
					"created_datetime": "2022-03-18T05:14:38.606Z",
					"updated_datetime": "2022-03-18T05:14:38.606Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.07197721716397532",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "652f0fbb-5c33-4e7d-a1c6-ee144d828be8",
					"created_datetime": "2022-03-18T05:14:38.610Z",
					"updated_datetime": "2022-03-18T05:14:38.610Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.8196050096185468",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "3e30be63-3acf-4576-b64a-1c3950b978cd",
					"created_datetime": "2022-03-18T05:14:38.613Z",
					"updated_datetime": "2022-03-18T05:14:38.613Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.3557899665850673",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "2e565763-bf25-4f16-987c-a8e5b2de6bef",
					"created_datetime": "2022-03-18T05:14:38.616Z",
					"updated_datetime": "2022-03-18T05:14:38.616Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability_scap_left",
					"value_string": "0.162281377225031",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "a81eee66-53a8-4cc9-84e4-157b57090386",
					"created_datetime": "2022-03-18T05:14:38.619Z",
					"updated_datetime": "2022-03-18T05:14:38.619Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8390445056824967",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "75daf1a6-49eb-4ea6-99b4-ee828e68661d",
					"created_datetime": "2022-03-18T05:14:38.622Z",
					"updated_datetime": "2022-03-18T05:14:38.622Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.013280818717073626",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "60f36bf6-aeff-44ae-aea7-7f9d61284d26",
					"created_datetime": "2022-03-18T05:14:38.626Z",
					"updated_datetime": "2022-03-18T05:14:38.626Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.9404580326752702",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "093ab829-553b-4f5d-aa29-60c84548de8f",
					"created_datetime": "2022-03-18T05:14:38.629Z",
					"updated_datetime": "2022-03-18T05:14:38.629Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.9633152588392339",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "bca7773a-c192-4b85-97e1-6b55a4c83895",
					"created_datetime": "2022-03-18T05:14:38.632Z",
					"updated_datetime": "2022-03-18T05:14:38.632Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.020543164817285117",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "142a5d4e-fa04-4479-bc51-a6d06e22ae18",
					"created_datetime": "2022-03-18T05:14:38.635Z",
					"updated_datetime": "2022-03-18T05:14:38.635Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.8475596791078973",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "9c99061f-1bf3-4b04-9487-6b41b20ff117",
					"created_datetime": "2022-03-18T05:14:38.639Z",
					"updated_datetime": "2022-03-18T05:14:38.639Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.639333370710232",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "304ab495-9169-4b5c-a9ac-63e63048da34",
					"created_datetime": "2022-03-18T05:14:38.642Z",
					"updated_datetime": "2022-03-18T05:14:38.642Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.5228100835579446",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "1c012f5e-db37-4208-a963-c74a036830d4",
					"created_datetime": "2022-03-18T05:14:38.645Z",
					"updated_datetime": "2022-03-18T05:14:38.645Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.6706788880410447",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "5cccb2cc-99b1-446f-8585-aba60a9018dc",
					"created_datetime": "2022-03-18T05:14:38.648Z",
					"updated_datetime": "2022-03-18T05:14:38.648Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_hip",
					"value_string": "0.7766855264786054",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "a8dc9a13-7509-42bc-b4c9-f62af68f500a",
					"created_datetime": "2022-03-18T05:14:38.651Z",
					"updated_datetime": "2022-03-18T05:14:38.651Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_femur",
					"value_string": "0.7145897618781931",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "21074e4a-b203-4f01-8680-d519be3cc306",
					"created_datetime": "2022-03-18T05:14:38.654Z",
					"updated_datetime": "2022-03-18T05:14:38.654Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_knee",
					"value_string": "0.5185848335540284",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "e95ed157-9e42-437d-81d2-c107d26c75fc",
					"created_datetime": "2022-03-18T05:14:38.657Z",
					"updated_datetime": "2022-03-18T05:14:38.657Z",
					"assessment_id_guid": "e93dfdca-001e-4657-a83a-75e58745adb9",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.19129957108674248",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
			"created_datetime": "2022-03-18T05:18:06.503Z",
			"updated_datetime": "2022-03-18T05:18:06.503Z",
			"submitter_user_id_guid": "4a41b855-e44e-4ea1-beca-c32a15032a68",
			"athlete_user_id_guid": "c438e910-b4ef-4b74-9ab0-51476f177b8a",
			"external_id_guid": "61e8e374-522d-4b1f-abdc-42833ef596c3",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "f8de84b1-e0ae-4cb8-941d-69636d95bae8",
					"created_datetime": "2022-03-18T05:18:06.509Z",
					"updated_datetime": "2022-03-18T05:18:06.509Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:20:55.378Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "7fee71d5-e5b1-478e-9368-1657f48a40a1",
					"created_datetime": "2022-03-18T05:18:06.514Z",
					"updated_datetime": "2022-03-18T05:18:06.514Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "0319d357-681a-4b8d-92c3-f3d91a1ef4d6",
					"created_datetime": "2022-03-18T05:18:06.519Z",
					"updated_datetime": "2022-03-18T05:18:06.519Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-55.149902707651336",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "4148354f-b1fb-438e-84f1-f4b446197974",
					"created_datetime": "2022-03-18T05:18:06.524Z",
					"updated_datetime": "2022-03-18T05:18:06.524Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "-47.55161569873499",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "6c0ea31e-8a28-4b22-af3b-eba25642c204",
					"created_datetime": "2022-03-18T05:18:06.528Z",
					"updated_datetime": "2022-03-18T05:18:06.528Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-127.45854950503399",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "f702106d-ca32-4a4d-a044-40773f13732b",
					"created_datetime": "2022-03-18T05:18:06.539Z",
					"updated_datetime": "2022-03-18T05:18:06.539Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "76.06475837595826",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "a378cf11-6a78-4b92-84ab-ac5bf2f69ad0",
					"created_datetime": "2022-03-18T05:18:06.542Z",
					"updated_datetime": "2022-03-18T05:18:06.542Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "112.64007073146894",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "8903fcb7-d254-49cc-a6c1-21ebccbb9903",
					"created_datetime": "2022-03-18T05:18:06.545Z",
					"updated_datetime": "2022-03-18T05:18:06.545Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "168.03469417241263",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "9fab7152-d6a3-4bef-8ea6-1c62b9baf436",
					"created_datetime": "2022-03-18T05:18:06.548Z",
					"updated_datetime": "2022-03-18T05:18:06.548Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "172.31436215570318",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "9e51be40-fddb-40c6-b366-77f50a737cab",
					"created_datetime": "2022-03-18T05:18:06.551Z",
					"updated_datetime": "2022-03-18T05:18:06.551Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "127.92133853453049",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "c5146473-28ef-4881-8798-66ec6c1ef18c",
					"created_datetime": "2022-03-18T05:18:06.554Z",
					"updated_datetime": "2022-03-18T05:18:06.554Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-77.66435951664685",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "5bc5dfad-103d-4de4-bbdf-78606805d251",
					"created_datetime": "2022-03-18T05:18:06.558Z",
					"updated_datetime": "2022-03-18T05:18:06.558Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-54.61383911874444",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "7e33a011-cff6-4dbc-97c4-060997bbf517",
					"created_datetime": "2022-03-18T05:18:06.561Z",
					"updated_datetime": "2022-03-18T05:18:06.561Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-83.58994229243753",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "3001b7d6-3359-4899-b147-aa0c6410028c",
					"created_datetime": "2022-03-18T05:18:06.564Z",
					"updated_datetime": "2022-03-18T05:18:06.564Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "71.34459283893747",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "4483d7d2-6618-4422-af22-7cc865606511",
					"created_datetime": "2022-03-18T05:18:06.567Z",
					"updated_datetime": "2022-03-18T05:18:06.567Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_stability_scap_left",
					"value_string": "146.78958440791513",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "4ef946fc-bb07-4ff2-81c5-8307d000b618",
					"created_datetime": "2022-03-18T05:18:06.569Z",
					"updated_datetime": "2022-03-18T05:18:06.569Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-116.74936003535394",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "39b2d1fc-5e00-4f2c-aa5b-00c7da86bcab",
					"created_datetime": "2022-03-18T05:18:06.572Z",
					"updated_datetime": "2022-03-18T05:18:06.572Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-14.920510664119547",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "83e36357-d1c3-4d4b-bbc1-5f7a3f9b0e70",
					"created_datetime": "2022-03-18T05:18:06.574Z",
					"updated_datetime": "2022-03-18T05:18:06.574Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-154.96107328589866",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "5d4f2313-d970-4507-bec6-40f555032e4d",
					"created_datetime": "2022-03-18T05:18:06.577Z",
					"updated_datetime": "2022-03-18T05:18:06.577Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-37.86165744259691",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "a96d41d0-a920-4c0f-8a5f-582b74efc8d3",
					"created_datetime": "2022-03-18T05:18:06.580Z",
					"updated_datetime": "2022-03-18T05:18:06.580Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "139.88385888383215",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "9fdd6a2a-bf8e-4f70-8157-aa6c117dca70",
					"created_datetime": "2022-03-18T05:18:06.582Z",
					"updated_datetime": "2022-03-18T05:18:06.582Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "-135.503767096713",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "e3dca8d2-7976-4c8f-80e2-083ee4ba05c0",
					"created_datetime": "2022-03-18T05:18:06.585Z",
					"updated_datetime": "2022-03-18T05:18:06.585Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-171.41970080329233",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "8889fd6b-540c-4e2d-b819-ad484e36dad8",
					"created_datetime": "2022-03-18T05:18:06.591Z",
					"updated_datetime": "2022-03-18T05:18:06.591Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "-35.15335589219055",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "db6e59a7-8dee-4f1d-9683-29b9d16c7b5c",
					"created_datetime": "2022-03-18T05:18:06.595Z",
					"updated_datetime": "2022-03-18T05:18:06.595Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-33.626348497791696",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "f0779e25-349d-46f3-a62d-7aa80ec737e7",
					"created_datetime": "2022-03-18T05:18:06.598Z",
					"updated_datetime": "2022-03-18T05:18:06.598Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_hip",
					"value_string": "15.940016624460242",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "ea1c2f8b-0003-4e7f-a3cd-311c6b99964e",
					"created_datetime": "2022-03-18T05:18:06.609Z",
					"updated_datetime": "2022-03-18T05:18:06.609Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_femur",
					"value_string": "99.15606331537384",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ffdc2a54-e048-491b-936a-f827ff688594",
					"created_datetime": "2022-03-18T05:18:06.613Z",
					"updated_datetime": "2022-03-18T05:18:06.613Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_knee",
					"value_string": "1.6562132727191852",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "2723be42-e724-466b-bba7-fd61384b76fe",
					"created_datetime": "2022-03-18T05:18:06.617Z",
					"updated_datetime": "2022-03-18T05:18:06.617Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "75.22567772585106",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "12a9d437-4dfd-4730-a6e8-62b63fbd6c03",
					"created_datetime": "2022-03-18T05:18:06.620Z",
					"updated_datetime": "2022-03-18T05:18:06.620Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_composite",
					"value_string": "0.8946633505583022",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "29a19d79-09ff-4976-95b9-1e1186d9a400",
					"created_datetime": "2022-03-18T05:18:06.622Z",
					"updated_datetime": "2022-03-18T05:18:06.622Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility",
					"value_string": "0.14309492279474062",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "749a6e39-7789-4603-a65f-968b43757cdd",
					"created_datetime": "2022-03-18T05:18:06.625Z",
					"updated_datetime": "2022-03-18T05:18:06.625Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability",
					"value_string": "0.22049894490651037",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "d246ee8b-0f23-4e77-b88a-d43c9d158f6f",
					"created_datetime": "2022-03-18T05:18:06.628Z",
					"updated_datetime": "2022-03-18T05:18:06.628Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_posture",
					"value_string": "0.4852621048017747",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "376ff546-dc2b-4643-b3e2-929896d926c2",
					"created_datetime": "2022-03-18T05:18:06.634Z",
					"updated_datetime": "2022-03-18T05:18:06.634Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry",
					"value_string": "0.773029138143254",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "a0155f69-c937-4427-a59c-bdb3302285c4",
					"created_datetime": "2022-03-18T05:18:06.637Z",
					"updated_datetime": "2022-03-18T05:18:06.637Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.43689629803754015",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "5eab8130-c93d-467a-b8a7-2c665848c305",
					"created_datetime": "2022-03-18T05:18:06.640Z",
					"updated_datetime": "2022-03-18T05:18:06.640Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.45919068938323987",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "d313ab94-0e98-48f5-966a-adc04ad90be5",
					"created_datetime": "2022-03-18T05:18:06.643Z",
					"updated_datetime": "2022-03-18T05:18:06.643Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.44733662721641465",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "317f02db-bf9a-41c8-a4ce-0ee7a0983170",
					"created_datetime": "2022-03-18T05:18:06.646Z",
					"updated_datetime": "2022-03-18T05:18:06.646Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.38492175441862087",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "b69f998d-87a6-405d-8626-8555634deaba",
					"created_datetime": "2022-03-18T05:18:06.650Z",
					"updated_datetime": "2022-03-18T05:18:06.650Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5657117832947178",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "7df3fcfe-03f8-463b-aa69-3ea43ccf006d",
					"created_datetime": "2022-03-18T05:18:06.653Z",
					"updated_datetime": "2022-03-18T05:18:06.653Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.4597258211749999",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "f53b5dbc-216d-4801-97b0-f673344e8768",
					"created_datetime": "2022-03-18T05:18:06.656Z",
					"updated_datetime": "2022-03-18T05:18:06.656Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.24307964001078888",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "2e62802e-4951-4e37-bf48-eb6b81bba47d",
					"created_datetime": "2022-03-18T05:18:06.660Z",
					"updated_datetime": "2022-03-18T05:18:06.660Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.04571931297808168",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "a62f1d21-8acd-473e-bbc0-a65d26008491",
					"created_datetime": "2022-03-18T05:18:06.663Z",
					"updated_datetime": "2022-03-18T05:18:06.663Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.28709361263734573",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "b9ad35ab-18ee-49c5-b243-0a81256359c0",
					"created_datetime": "2022-03-18T05:18:06.666Z",
					"updated_datetime": "2022-03-18T05:18:06.666Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.8368855571180602",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "7a36515d-06c8-4234-9d9b-299af19bf3cc",
					"created_datetime": "2022-03-18T05:18:06.670Z",
					"updated_datetime": "2022-03-18T05:18:06.670Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.05312359673462437",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "123733e3-3b4e-493f-9639-6ca21c907088",
					"created_datetime": "2022-03-18T05:18:06.673Z",
					"updated_datetime": "2022-03-18T05:18:06.673Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.6099261817563866",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "4470f8d1-4de5-4e1f-9ff9-76343c93f9a8",
					"created_datetime": "2022-03-18T05:18:06.676Z",
					"updated_datetime": "2022-03-18T05:18:06.676Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability_scap_left",
					"value_string": "0.9665981704576598",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "0c37cd35-49d7-400a-814e-0ad4ded80d80",
					"created_datetime": "2022-03-18T05:18:06.679Z",
					"updated_datetime": "2022-03-18T05:18:06.679Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability_scap_right",
					"value_string": "0.388404139367708",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "53c5d4f2-6b2e-4877-af46-429362791960",
					"created_datetime": "2022-03-18T05:18:06.683Z",
					"updated_datetime": "2022-03-18T05:18:06.683Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.6438265715308167",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "f5787756-5acf-4407-bb20-85bef009cb87",
					"created_datetime": "2022-03-18T05:18:06.686Z",
					"updated_datetime": "2022-03-18T05:18:06.686Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.1550212552956522",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "4489bcf8-9dce-484a-bb2a-a3ebd43182f7",
					"created_datetime": "2022-03-18T05:18:06.689Z",
					"updated_datetime": "2022-03-18T05:18:06.689Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.4966002501379485",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "2bbc29a7-a5ef-4ad4-98d6-0694dd425f6b",
					"created_datetime": "2022-03-18T05:18:06.692Z",
					"updated_datetime": "2022-03-18T05:18:06.692Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.7025620186640245",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "82252e1f-48ad-4741-8d32-83804af693d4",
					"created_datetime": "2022-03-18T05:18:06.886Z",
					"updated_datetime": "2022-03-18T05:18:06.886Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.9607686575547915",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "a508e246-7c37-45fe-9ed9-9604eb1dddd1",
					"created_datetime": "2022-03-18T05:18:06.892Z",
					"updated_datetime": "2022-03-18T05:18:06.892Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.9324539397079556",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "de193135-2eba-4719-b374-95d50f6efed7",
					"created_datetime": "2022-03-18T05:18:06.896Z",
					"updated_datetime": "2022-03-18T05:18:06.896Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.9526618582193911",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "a1a8d765-ffe6-4307-be99-cff423ed1b8a",
					"created_datetime": "2022-03-18T05:18:06.899Z",
					"updated_datetime": "2022-03-18T05:18:06.899Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.16986675955937788",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "512eb626-2ab5-4192-aa34-8f12237572e4",
					"created_datetime": "2022-03-18T05:18:06.902Z",
					"updated_datetime": "2022-03-18T05:18:06.902Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_hip",
					"value_string": "0.296097197948033",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "7d68076d-f5aa-4e15-aeb8-6aab449cb84a",
					"created_datetime": "2022-03-18T05:18:06.905Z",
					"updated_datetime": "2022-03-18T05:18:06.905Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_femur",
					"value_string": "0.030216131518627717",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "d3b008f7-450a-4cb6-a70d-5bdc3c6c1fdd",
					"created_datetime": "2022-03-18T05:18:06.908Z",
					"updated_datetime": "2022-03-18T05:18:06.908Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_knee",
					"value_string": "0.042883037289590226",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "8b46c623-eaeb-4c61-8360-d0d5a53b86bd",
					"created_datetime": "2022-03-18T05:18:06.912Z",
					"updated_datetime": "2022-03-18T05:18:06.912Z",
					"assessment_id_guid": "4693f9de-942f-41b4-bb23-8f471e4ff30d",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.2830007424268037",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
			"created_datetime": "2022-03-18T05:30:28.114Z",
			"updated_datetime": "2022-03-18T05:30:28.114Z",
			"submitter_user_id_guid": "1b48ed3b-3c6f-471d-8862-0c11d092176e",
			"athlete_user_id_guid": "46e50e0b-edaf-4419-9263-239eb068843b",
			"external_id_guid": "5f9cfef6-bd88-4843-87e4-fd79600372ce",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "f0f03a85-dc52-48cf-a021-7a864c6ac322",
					"created_datetime": "2022-03-18T05:30:28.119Z",
					"updated_datetime": "2022-03-18T05:30:28.119Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "ios_datetime",
					"value_string": "2022-03-13T03:57:05.676Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "886ed3c6-0196-42e0-9fbc-efd1449d2c00",
					"created_datetime": "2022-03-18T05:30:28.123Z",
					"updated_datetime": "2022-03-18T05:30:28.123Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.0",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f81727ce-01cf-4c5a-b8c3-0682c7a21766",
					"created_datetime": "2022-03-18T05:30:28.126Z",
					"updated_datetime": "2022-03-18T05:30:28.126Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "1",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "798400a1-e554-4c1e-8200-f4332bb8576a",
					"created_datetime": "2022-03-18T05:30:28.129Z",
					"updated_datetime": "2022-03-18T05:30:28.129Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "2",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "b3ba2656-5263-4cad-9698-ad119d054839",
					"created_datetime": "2022-03-18T05:30:28.132Z",
					"updated_datetime": "2022-03-18T05:30:28.132Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "3",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "2e73541c-3495-4374-8713-25d0125ced06",
					"created_datetime": "2022-03-18T05:30:28.135Z",
					"updated_datetime": "2022-03-18T05:30:28.135Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "4",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "6b6c4b7e-c738-42ae-beac-2425d73e98ac",
					"created_datetime": "2022-03-18T05:30:28.138Z",
					"updated_datetime": "2022-03-18T05:30:28.138Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "5",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "ea6c9401-0cff-46f8-bae8-5db8885d4805",
					"created_datetime": "2022-03-18T05:30:28.141Z",
					"updated_datetime": "2022-03-18T05:30:28.141Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "6",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "a61d0b97-f180-47fe-8fba-72a6a29ece8d",
					"created_datetime": "2022-03-18T05:30:28.146Z",
					"updated_datetime": "2022-03-18T05:30:28.146Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "7",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "5840b536-2be7-48a3-a2d0-71b60b964451",
					"created_datetime": "2022-03-18T05:30:28.149Z",
					"updated_datetime": "2022-03-18T05:30:28.149Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "8",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "f6bb4d46-07f9-4738-bf5d-9312620722a3",
					"created_datetime": "2022-03-18T05:30:28.152Z",
					"updated_datetime": "2022-03-18T05:30:28.152Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "9",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "c21ea3b2-239a-43aa-8cff-ad3f1e206e6e",
					"created_datetime": "2022-03-18T05:30:28.155Z",
					"updated_datetime": "2022-03-18T05:30:28.155Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "10",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "21d2f099-8068-4eb4-8b08-0479e015e7ca",
					"created_datetime": "2022-03-18T05:30:28.161Z",
					"updated_datetime": "2022-03-18T05:30:28.161Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "11",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "95d7501c-839e-48c6-b10a-2e4db1000bd2",
					"created_datetime": "2022-03-18T05:30:28.164Z",
					"updated_datetime": "2022-03-18T05:30:28.164Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "801a7492-17de-4352-a6d6-733eafe892da",
					"created_datetime": "2022-03-18T05:30:28.167Z",
					"updated_datetime": "2022-03-18T05:30:28.167Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_stability_scap_left",
					"value_string": "13",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "98af7eed-5767-4390-a2e0-cad27cfdea27",
					"created_datetime": "2022-03-18T05:30:28.172Z",
					"updated_datetime": "2022-03-18T05:30:28.172Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_stability_scap_right",
					"value_string": "14",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7d8b72f9-0f2b-4e7b-883a-38bd0dedaa1f",
					"created_datetime": "2022-03-18T05:30:28.175Z",
					"updated_datetime": "2022-03-18T05:30:28.175Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "15",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "49256680-0628-489b-ae73-f20bd533ca31",
					"created_datetime": "2022-03-18T05:30:28.178Z",
					"updated_datetime": "2022-03-18T05:30:28.178Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "16",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "a22814b6-6163-46e5-b1f6-763226242a43",
					"created_datetime": "2022-03-18T05:30:28.181Z",
					"updated_datetime": "2022-03-18T05:30:28.181Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "17",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "a5f49409-a452-47b9-8701-b38d40d99a61",
					"created_datetime": "2022-03-18T05:30:28.185Z",
					"updated_datetime": "2022-03-18T05:30:28.185Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "18",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "94cfa45e-7dfb-46e8-baf6-930013f8dbb1",
					"created_datetime": "2022-03-18T05:30:28.188Z",
					"updated_datetime": "2022-03-18T05:30:28.188Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "19",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "e50d5629-ef14-4cd3-93f3-b75446506e66",
					"created_datetime": "2022-03-18T05:30:28.191Z",
					"updated_datetime": "2022-03-18T05:30:28.191Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "20",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "7b71a81a-2cfe-4129-b77c-c47edff89e2f",
					"created_datetime": "2022-03-18T05:30:28.194Z",
					"updated_datetime": "2022-03-18T05:30:28.194Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "21",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "e12280a9-8993-498c-b6f6-ba46e2f75ce0",
					"created_datetime": "2022-03-18T05:30:28.197Z",
					"updated_datetime": "2022-03-18T05:30:28.197Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "ce443055-57cb-4add-a862-af616d12d698",
					"created_datetime": "2022-03-18T05:30:28.201Z",
					"updated_datetime": "2022-03-18T05:30:28.201Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_hip",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "f100e8a5-faf9-47ac-81b8-f9349e9eb5f5",
					"created_datetime": "2022-03-18T05:30:28.204Z",
					"updated_datetime": "2022-03-18T05:30:28.204Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_femur",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "ae5f7e48-ddfa-47e1-b871-0543513c3137",
					"created_datetime": "2022-03-18T05:30:28.207Z",
					"updated_datetime": "2022-03-18T05:30:28.207Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_knee",
					"value_string": "25",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "dfba832e-e2cf-4a6d-9f77-6acdde9d9831",
					"created_datetime": "2022-03-18T05:30:28.211Z",
					"updated_datetime": "2022-03-18T05:30:28.211Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "26",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "db54b348-5933-4e2c-b144-03f1644cb757",
					"created_datetime": "2022-03-18T05:30:28.214Z",
					"updated_datetime": "2022-03-18T05:30:28.214Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_composite",
					"value_string": "27",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "6aeb69b7-0198-4bd4-8f50-69840cbea0df",
					"created_datetime": "2022-03-18T05:30:28.217Z",
					"updated_datetime": "2022-03-18T05:30:28.217Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility",
					"value_string": "28",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "e6c3bfc3-86d4-4803-8b60-ea0362e3f6cb",
					"created_datetime": "2022-03-18T05:30:28.220Z",
					"updated_datetime": "2022-03-18T05:30:28.220Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability",
					"value_string": "29",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "e5903f7b-5846-4b74-9b56-d82efe0d3168",
					"created_datetime": "2022-03-18T05:30:28.223Z",
					"updated_datetime": "2022-03-18T05:30:28.223Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_posture",
					"value_string": "30",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "493d2ffd-5623-415e-b388-4fa2f1815225",
					"created_datetime": "2022-03-18T05:30:28.226Z",
					"updated_datetime": "2022-03-18T05:30:28.226Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry",
					"value_string": "31",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "e16e1aa1-282d-44cb-873f-665eaa15edf1",
					"created_datetime": "2022-03-18T05:30:28.230Z",
					"updated_datetime": "2022-03-18T05:30:28.230Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_elbow_left",
					"value_string": "32",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "75a2a57d-e0ca-4e5f-a1e1-d3bc9505f89e",
					"created_datetime": "2022-03-18T05:30:28.233Z",
					"updated_datetime": "2022-03-18T05:30:28.233Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_elbow_right",
					"value_string": "33",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "9a6b71fd-fe5b-4b5f-9a98-e3afb4603ea6",
					"created_datetime": "2022-03-18T05:30:28.236Z",
					"updated_datetime": "2022-03-18T05:30:28.236Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "34",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "76088024-3cf3-4756-85db-fe672ec312ae",
					"created_datetime": "2022-03-18T05:30:28.239Z",
					"updated_datetime": "2022-03-18T05:30:28.239Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "35",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "70b78676-1dee-4ca1-9721-a69d2994edb9",
					"created_datetime": "2022-03-18T05:30:28.242Z",
					"updated_datetime": "2022-03-18T05:30:28.242Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_hip_left",
					"value_string": "36",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "450aaa53-4856-4340-87ad-9d6122fd2283",
					"created_datetime": "2022-03-18T05:30:28.245Z",
					"updated_datetime": "2022-03-18T05:30:28.245Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_hip_right",
					"value_string": "37",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "470300d9-8656-444d-b455-d1d3b8955853",
					"created_datetime": "2022-03-18T05:30:28.248Z",
					"updated_datetime": "2022-03-18T05:30:28.248Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_femur_left",
					"value_string": "38",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "57f1b332-c2c2-4305-947d-2d70692982d2",
					"created_datetime": "2022-03-18T05:30:28.251Z",
					"updated_datetime": "2022-03-18T05:30:28.251Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_femur_right",
					"value_string": "39",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "e82d7518-5613-4857-a590-b91fe53ac9dd",
					"created_datetime": "2022-03-18T05:30:28.256Z",
					"updated_datetime": "2022-03-18T05:30:28.256Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_knee_left",
					"value_string": "40",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "79d4cc60-6d4f-442f-a0c6-2aba38d02139",
					"created_datetime": "2022-03-18T05:30:28.259Z",
					"updated_datetime": "2022-03-18T05:30:28.259Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_knee_right",
					"value_string": "41",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "baee380e-487b-40b2-83a3-2ac5c4aa3135",
					"created_datetime": "2022-03-18T05:30:28.262Z",
					"updated_datetime": "2022-03-18T05:30:28.262Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_tibia_left",
					"value_string": "42",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "f0fb3c0c-de92-4c6b-9046-407e66b5ed88",
					"created_datetime": "2022-03-18T05:30:28.265Z",
					"updated_datetime": "2022-03-18T05:30:28.265Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_mobility_tibia_right",
					"value_string": "43",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "97f0dbbd-8d07-46dd-a0d5-c2b2becbbbac",
					"created_datetime": "2022-03-18T05:30:28.268Z",
					"updated_datetime": "2022-03-18T05:30:28.268Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability_scap_left",
					"value_string": "44",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "bf9e4a19-b6d8-4f23-bd62-1b18f1cc31c5",
					"created_datetime": "2022-03-18T05:30:28.271Z",
					"updated_datetime": "2022-03-18T05:30:28.271Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability_scap_right",
					"value_string": "45",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "36f7d543-4c8d-414d-9e1a-834a31540a40",
					"created_datetime": "2022-03-18T05:30:28.275Z",
					"updated_datetime": "2022-03-18T05:30:28.275Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "46",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "928fc712-e286-49d9-a9cc-d606c457ccbc",
					"created_datetime": "2022-03-18T05:30:28.278Z",
					"updated_datetime": "2022-03-18T05:30:28.278Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "47",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "6bb8aa3a-97f6-4a1d-9b15-8a6e48763871",
					"created_datetime": "2022-03-18T05:30:28.281Z",
					"updated_datetime": "2022-03-18T05:30:28.281Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "48",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "5334cc29-a802-4a97-a230-cff313fb2691",
					"created_datetime": "2022-03-18T05:30:28.284Z",
					"updated_datetime": "2022-03-18T05:30:28.284Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_posture_shoulder_level",
					"value_string": "49",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "b060ba13-0bb0-4af5-bfc8-32cc8e8461e4",
					"created_datetime": "2022-03-18T05:30:28.288Z",
					"updated_datetime": "2022-03-18T05:30:28.288Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "50",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "cd35f612-3df7-41ec-9c5f-63fd5bd84599",
					"created_datetime": "2022-03-18T05:30:28.291Z",
					"updated_datetime": "2022-03-18T05:30:28.291Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_elbow",
					"value_string": "51",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "7e1ed65d-f1d2-458b-a745-ff1d3369fb8a",
					"created_datetime": "2022-03-18T05:30:28.294Z",
					"updated_datetime": "2022-03-18T05:30:28.294Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_shoulder",
					"value_string": "52",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "fba071b3-c8ee-48e3-aa97-82532512141f",
					"created_datetime": "2022-03-18T05:30:28.297Z",
					"updated_datetime": "2022-03-18T05:30:28.297Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "53",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "23b003f8-d820-4f45-84d7-8da51dabf979",
					"created_datetime": "2022-03-18T05:30:28.300Z",
					"updated_datetime": "2022-03-18T05:30:28.300Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_hip",
					"value_string": "54",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "008e673f-5063-4dc5-9e9c-78823031d99c",
					"created_datetime": "2022-03-18T05:30:28.303Z",
					"updated_datetime": "2022-03-18T05:30:28.303Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_femur",
					"value_string": "55",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "322d16fc-2e46-4a83-a797-11cccc6831b1",
					"created_datetime": "2022-03-18T05:30:28.306Z",
					"updated_datetime": "2022-03-18T05:30:28.306Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_knee",
					"value_string": "56",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "7654d3df-9aac-4116-92e7-8e8db6fb9cd2",
					"created_datetime": "2022-03-18T05:30:28.309Z",
					"updated_datetime": "2022-03-18T05:30:28.309Z",
					"assessment_id_guid": "97d6e7fa-6ef8-4984-ba5e-4ff42f3c9cd4",
					"name_string": "score_symmetry_tibia",
					"value_string": "57",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
			"created_datetime": "2022-03-18T05:31:45.492Z",
			"updated_datetime": "2022-03-18T05:31:45.492Z",
			"submitter_user_id_guid": "1b48ed3b-3c6f-471d-8862-0c11d092176e",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "5f9cfef6-bd88-4843-87e4-fd79600372cf",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "c18f6cd6-6fea-46a4-81d4-01327a4e8a88",
					"created_datetime": "2022-03-18T05:31:45.497Z",
					"updated_datetime": "2022-03-18T05:31:45.497Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "ios_datetime",
					"value_string": "2022-03-13T03:57:05.876Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "ef3e0215-9bd8-4091-830d-efcdc2b60894",
					"created_datetime": "2022-03-18T05:31:45.501Z",
					"updated_datetime": "2022-03-18T05:31:45.501Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.0",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "5f3576db-b5ec-4158-9306-9c3f4bc86fbf",
					"created_datetime": "2022-03-18T05:31:45.504Z",
					"updated_datetime": "2022-03-18T05:31:45.504Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "0fe8958a-0d8e-4641-9f04-581e4a455330",
					"created_datetime": "2022-03-18T05:31:45.509Z",
					"updated_datetime": "2022-03-18T05:31:45.509Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "939cf872-f93e-4903-be33-4b19b6bc17db",
					"created_datetime": "2022-03-18T05:31:45.513Z",
					"updated_datetime": "2022-03-18T05:31:45.513Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "841175fd-cea2-4014-885d-91a6fefb99a0",
					"created_datetime": "2022-03-18T05:31:45.518Z",
					"updated_datetime": "2022-03-18T05:31:45.518Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "6a3d4658-7247-4c86-ac6f-b7f14d61e646",
					"created_datetime": "2022-03-18T05:31:45.521Z",
					"updated_datetime": "2022-03-18T05:31:45.521Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "52",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "f266a82c-2333-498f-91d1-dafc8c59f6c5",
					"created_datetime": "2022-03-18T05:31:45.524Z",
					"updated_datetime": "2022-03-18T05:31:45.524Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "62",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "ef6426ac-6b77-4df8-80e2-90c82d04607d",
					"created_datetime": "2022-03-18T05:31:45.528Z",
					"updated_datetime": "2022-03-18T05:31:45.528Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "72",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "c479aa80-8378-40b9-bd0b-02ea3fbda3f4",
					"created_datetime": "2022-03-18T05:31:45.531Z",
					"updated_datetime": "2022-03-18T05:31:45.531Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "28",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "49bb0120-27cd-4646-ac44-4a71b4b6b801",
					"created_datetime": "2022-03-18T05:31:45.534Z",
					"updated_datetime": "2022-03-18T05:31:45.534Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "92",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "21f6cbfc-250d-472b-9f55-c0b39dc6b296",
					"created_datetime": "2022-03-18T05:31:45.537Z",
					"updated_datetime": "2022-03-18T05:31:45.537Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "10",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "68839242-2823-446c-812b-ea318fc9886b",
					"created_datetime": "2022-03-18T05:31:45.540Z",
					"updated_datetime": "2022-03-18T05:31:45.540Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "11",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "66a30c9d-0100-47ed-9835-2961fc0b0a8a",
					"created_datetime": "2022-03-18T05:31:45.543Z",
					"updated_datetime": "2022-03-18T05:31:45.543Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "a099bbe1-3138-4b91-9648-23dbec0486d7",
					"created_datetime": "2022-03-18T05:31:45.546Z",
					"updated_datetime": "2022-03-18T05:31:45.546Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_stability_scap_left",
					"value_string": "13",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "3906bf35-f848-4aff-b7f7-92d9dffe0f1d",
					"created_datetime": "2022-03-18T05:31:45.550Z",
					"updated_datetime": "2022-03-18T05:31:45.550Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_stability_scap_right",
					"value_string": "14",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "671da0ba-a59e-41d9-8649-02e091ca2ce1",
					"created_datetime": "2022-03-18T05:31:45.555Z",
					"updated_datetime": "2022-03-18T05:31:45.555Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "15",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "dc758a43-ac03-4a61-9bbf-0376015ebdbe",
					"created_datetime": "2022-03-18T05:31:45.558Z",
					"updated_datetime": "2022-03-18T05:31:45.558Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "16",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "9e853791-57ae-48a8-88fa-22b41583e424",
					"created_datetime": "2022-03-18T05:31:45.561Z",
					"updated_datetime": "2022-03-18T05:31:45.561Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "17",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "cb8c0889-c1bc-4006-8c7f-59146a4756e9",
					"created_datetime": "2022-03-18T05:31:45.564Z",
					"updated_datetime": "2022-03-18T05:31:45.564Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "18",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "66740127-3c7b-42ed-9e63-acd78b7d20d0",
					"created_datetime": "2022-03-18T05:31:45.567Z",
					"updated_datetime": "2022-03-18T05:31:45.567Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "19",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "48a6ba8a-caf3-431c-b6ba-3259163820af",
					"created_datetime": "2022-03-18T05:31:45.570Z",
					"updated_datetime": "2022-03-18T05:31:45.570Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "20",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "d3a8fb34-2033-4968-85d0-dcfd2f62cde0",
					"created_datetime": "2022-03-18T05:31:45.576Z",
					"updated_datetime": "2022-03-18T05:31:45.576Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "21",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "3ded3a9c-b42a-4c52-917d-7236b78adb04",
					"created_datetime": "2022-03-18T05:31:45.579Z",
					"updated_datetime": "2022-03-18T05:31:45.579Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "ab61dc54-54ed-4c32-b85b-443d484d6d99",
					"created_datetime": "2022-03-18T05:31:45.582Z",
					"updated_datetime": "2022-03-18T05:31:45.582Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_hip",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "c9542c9f-8ee2-4d35-8a21-cec76ef8881d",
					"created_datetime": "2022-03-18T05:31:45.587Z",
					"updated_datetime": "2022-03-18T05:31:45.587Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_femur",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "8c74dc79-f64e-44a8-b01b-62702f583e0c",
					"created_datetime": "2022-03-18T05:31:45.591Z",
					"updated_datetime": "2022-03-18T05:31:45.591Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_knee",
					"value_string": "25",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "deddcb5b-5400-46ae-a041-36d250a9ce83",
					"created_datetime": "2022-03-18T05:31:45.594Z",
					"updated_datetime": "2022-03-18T05:31:45.594Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "26",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "d662b8b4-c56d-4e8c-b8d2-1967dcb520a5",
					"created_datetime": "2022-03-18T05:31:45.597Z",
					"updated_datetime": "2022-03-18T05:31:45.597Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_composite",
					"value_string": "27",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "47d17af9-3404-4b07-9df7-8c4070eae1aa",
					"created_datetime": "2022-03-18T05:31:45.601Z",
					"updated_datetime": "2022-03-18T05:31:45.601Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility",
					"value_string": "28",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "f4c2bfbd-94a2-4a63-8946-ae01578803b8",
					"created_datetime": "2022-03-18T05:31:45.605Z",
					"updated_datetime": "2022-03-18T05:31:45.605Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability",
					"value_string": "29",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "b9d8a2e3-de65-41af-b9b2-d16666e4998e",
					"created_datetime": "2022-03-18T05:31:45.609Z",
					"updated_datetime": "2022-03-18T05:31:45.609Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_posture",
					"value_string": "30",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "69a4da5d-3c6f-4728-bda7-0e46ca4cb989",
					"created_datetime": "2022-03-18T05:31:45.612Z",
					"updated_datetime": "2022-03-18T05:31:45.612Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry",
					"value_string": "31",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "07ff3307-60a2-4f82-a110-4b9117aa50d7",
					"created_datetime": "2022-03-18T05:31:45.616Z",
					"updated_datetime": "2022-03-18T05:31:45.616Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_elbow_left",
					"value_string": "32",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "0e9ac72f-ad1e-4ba3-93be-2684cc477a37",
					"created_datetime": "2022-03-18T05:31:45.619Z",
					"updated_datetime": "2022-03-18T05:31:45.619Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_elbow_right",
					"value_string": "33",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "76eee7c9-973a-4307-afcf-e89debc25d6f",
					"created_datetime": "2022-03-18T05:31:45.622Z",
					"updated_datetime": "2022-03-18T05:31:45.622Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "34",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "374a15ef-20f9-4d94-bec8-179a8ecabd0a",
					"created_datetime": "2022-03-18T05:31:45.625Z",
					"updated_datetime": "2022-03-18T05:31:45.625Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "35",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "0b9779b7-ad3d-44c5-817e-4be654e8d8c6",
					"created_datetime": "2022-03-18T05:31:45.628Z",
					"updated_datetime": "2022-03-18T05:31:45.628Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_hip_left",
					"value_string": "36",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "78ef0602-eaef-4791-a8f3-c5f205d6226a",
					"created_datetime": "2022-03-18T05:31:45.631Z",
					"updated_datetime": "2022-03-18T05:31:45.631Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_hip_right",
					"value_string": "37",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "369484a1-951b-431c-a134-1c73a95041c7",
					"created_datetime": "2022-03-18T05:31:45.634Z",
					"updated_datetime": "2022-03-18T05:31:45.634Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_femur_left",
					"value_string": "38",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "1fde9c63-c727-4a42-829b-b33f3530a77d",
					"created_datetime": "2022-03-18T05:31:45.637Z",
					"updated_datetime": "2022-03-18T05:31:45.637Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_femur_right",
					"value_string": "39",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "7a67a7f0-d712-4c9a-9d3b-e78ec5913e26",
					"created_datetime": "2022-03-18T05:31:45.640Z",
					"updated_datetime": "2022-03-18T05:31:45.640Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_knee_left",
					"value_string": "40",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "9a628843-75b7-4f8c-9972-fe9dbe093bac",
					"created_datetime": "2022-03-18T05:31:45.643Z",
					"updated_datetime": "2022-03-18T05:31:45.643Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_knee_right",
					"value_string": "41",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "c29a0157-c7e7-4b5f-aeb8-3aa837482701",
					"created_datetime": "2022-03-18T05:31:45.645Z",
					"updated_datetime": "2022-03-18T05:31:45.645Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_tibia_left",
					"value_string": "42",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "ac08a040-6e79-4a48-b80e-fbf507a0c62a",
					"created_datetime": "2022-03-18T05:31:45.648Z",
					"updated_datetime": "2022-03-18T05:31:45.648Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_mobility_tibia_right",
					"value_string": "43",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "5295fbc0-d6c5-4c2e-80e2-0ca5467e31af",
					"created_datetime": "2022-03-18T05:31:45.651Z",
					"updated_datetime": "2022-03-18T05:31:45.651Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability_scap_left",
					"value_string": "44",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "5c16bfe2-85de-443e-8d57-ad58f980cd00",
					"created_datetime": "2022-03-18T05:31:45.654Z",
					"updated_datetime": "2022-03-18T05:31:45.654Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability_scap_right",
					"value_string": "45",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "08553841-eb98-4ba6-a356-5b6b93077f2a",
					"created_datetime": "2022-03-18T05:31:45.656Z",
					"updated_datetime": "2022-03-18T05:31:45.656Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "46",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "174bd13e-8087-4c40-9858-3f5543c7eff5",
					"created_datetime": "2022-03-18T05:31:45.659Z",
					"updated_datetime": "2022-03-18T05:31:45.659Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "47",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "484401a0-ebf1-4cd8-a63e-c33118bf5aac",
					"created_datetime": "2022-03-18T05:31:45.663Z",
					"updated_datetime": "2022-03-18T05:31:45.663Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "48",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "ae99669a-73ae-43f7-aebe-e6dc83d54ae2",
					"created_datetime": "2022-03-18T05:31:45.666Z",
					"updated_datetime": "2022-03-18T05:31:45.666Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_posture_shoulder_level",
					"value_string": "49",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "92910428-5526-444d-a895-d8012f221bd6",
					"created_datetime": "2022-03-18T05:31:45.669Z",
					"updated_datetime": "2022-03-18T05:31:45.669Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "50",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "2ae1e890-89a5-4f66-86dc-ad7ad8d3a9a9",
					"created_datetime": "2022-03-18T05:31:45.672Z",
					"updated_datetime": "2022-03-18T05:31:45.672Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_elbow",
					"value_string": "51",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "ed8a54f6-0936-46e6-9de4-d207fff82a0b",
					"created_datetime": "2022-03-18T05:31:45.675Z",
					"updated_datetime": "2022-03-18T05:31:45.675Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_shoulder",
					"value_string": "52",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "01915392-651c-4d42-9b37-5d895f811688",
					"created_datetime": "2022-03-18T05:31:45.678Z",
					"updated_datetime": "2022-03-18T05:31:45.678Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "53",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "5a0c0f1c-4905-4c83-8dfa-be6c92543d02",
					"created_datetime": "2022-03-18T05:31:45.681Z",
					"updated_datetime": "2022-03-18T05:31:45.681Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_hip",
					"value_string": "54",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "fb6a2a38-c00c-4e15-9e5c-788f8f7c6cbd",
					"created_datetime": "2022-03-18T05:31:45.683Z",
					"updated_datetime": "2022-03-18T05:31:45.683Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_femur",
					"value_string": "55",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "5606a49b-ff47-4d68-ace5-aa01a3021dd5",
					"created_datetime": "2022-03-18T05:31:45.686Z",
					"updated_datetime": "2022-03-18T05:31:45.686Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_knee",
					"value_string": "56",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "28bc82e9-c8e8-4b39-bd94-d70601b3651f",
					"created_datetime": "2022-03-18T05:31:45.688Z",
					"updated_datetime": "2022-03-18T05:31:45.688Z",
					"assessment_id_guid": "a8d1e572-4212-4ad2-b0ae-9f5a224cd2c7",
					"name_string": "score_symmetry_tibia",
					"value_string": "57",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
			"created_datetime": "2022-03-18T05:33:35.848Z",
			"updated_datetime": "2022-03-18T05:33:35.848Z",
			"submitter_user_id_guid": "1b48ed3b-3c6f-471d-8862-0c11d092176e",
			"athlete_user_id_guid": "9ceaa298-e793-4d99-87a6-ce3b4538e12e",
			"external_id_guid": "5f9cfef6-bd88-4843-87e4-fd79600372c1",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "847bf35a-226e-47af-ad7d-858c528f0dfd",
					"created_datetime": "2022-03-18T05:33:35.852Z",
					"updated_datetime": "2022-03-18T05:33:35.852Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "ios_datetime",
					"value_string": "2022-03-13T03:57:05.976Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "a1665fb9-a8f0-404c-8ca7-f8529c3bd288",
					"created_datetime": "2022-03-18T05:33:35.856Z",
					"updated_datetime": "2022-03-18T05:33:35.856Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.0",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "7d8d64a7-6bb8-4d5e-90b0-5063f4171925",
					"created_datetime": "2022-03-18T05:33:35.859Z",
					"updated_datetime": "2022-03-18T05:33:35.859Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "c52ec744-d6d9-4c7b-b5c2-c81c3b50d1a5",
					"created_datetime": "2022-03-18T05:33:35.862Z",
					"updated_datetime": "2022-03-18T05:33:35.862Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "2f1ecde6-d29f-4ccf-bf0e-b775274800e7",
					"created_datetime": "2022-03-18T05:33:35.865Z",
					"updated_datetime": "2022-03-18T05:33:35.865Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "b819b7a9-d560-4cb0-af3f-ecb536151713",
					"created_datetime": "2022-03-18T05:33:35.868Z",
					"updated_datetime": "2022-03-18T05:33:35.868Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "a6fd162c-1f85-4cf7-ad93-3f55c053f008",
					"created_datetime": "2022-03-18T05:33:35.872Z",
					"updated_datetime": "2022-03-18T05:33:35.872Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "52",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "2a9d5010-59b3-4ffb-93ad-761c85b621d9",
					"created_datetime": "2022-03-18T05:33:35.875Z",
					"updated_datetime": "2022-03-18T05:33:35.875Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "62",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "34b3c9e0-6826-4d32-9349-22094faaff09",
					"created_datetime": "2022-03-18T05:33:35.879Z",
					"updated_datetime": "2022-03-18T05:33:35.879Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "72",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "6f30e06f-8626-47d5-a072-94e538975a2f",
					"created_datetime": "2022-03-18T05:33:35.882Z",
					"updated_datetime": "2022-03-18T05:33:35.882Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "28",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "b57fab16-cac5-42d7-b098-f8ba3e46c1c3",
					"created_datetime": "2022-03-18T05:33:35.885Z",
					"updated_datetime": "2022-03-18T05:33:35.885Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "92",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "3a23d50e-8625-481c-b11b-b4eee67510f0",
					"created_datetime": "2022-03-18T05:33:35.888Z",
					"updated_datetime": "2022-03-18T05:33:35.888Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "10",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "24dbd144-3e48-4a13-964a-f3824947d964",
					"created_datetime": "2022-03-18T05:33:35.891Z",
					"updated_datetime": "2022-03-18T05:33:35.891Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "11",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "2ebc97cb-cb8a-4510-8549-abf018077f5a",
					"created_datetime": "2022-03-18T05:33:35.895Z",
					"updated_datetime": "2022-03-18T05:33:35.895Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "12",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "de5380f8-333d-4332-aa77-10cf87818a12",
					"created_datetime": "2022-03-18T05:33:35.904Z",
					"updated_datetime": "2022-03-18T05:33:35.904Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_stability_scap_left",
					"value_string": "13",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "2d75c843-fc66-4e3b-81a3-971d7d9bec1b",
					"created_datetime": "2022-03-18T05:33:35.907Z",
					"updated_datetime": "2022-03-18T05:33:35.907Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_stability_scap_right",
					"value_string": "14",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "7f4ecaf8-aa79-4b18-a95e-325c6bce38f1",
					"created_datetime": "2022-03-18T05:33:35.910Z",
					"updated_datetime": "2022-03-18T05:33:35.910Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "15",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "b616bff6-8144-4807-a6bf-4abb070738c1",
					"created_datetime": "2022-03-18T05:33:35.913Z",
					"updated_datetime": "2022-03-18T05:33:35.913Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "16",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "83093e29-6314-4b30-90f5-0719b250ff96",
					"created_datetime": "2022-03-18T05:33:35.916Z",
					"updated_datetime": "2022-03-18T05:33:35.916Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "17",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "75009a78-c604-4b43-8f58-ef7168449224",
					"created_datetime": "2022-03-18T05:33:35.919Z",
					"updated_datetime": "2022-03-18T05:33:35.919Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "18",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "c8531f22-da13-42d5-a87c-0d8ab0906932",
					"created_datetime": "2022-03-18T05:33:35.922Z",
					"updated_datetime": "2022-03-18T05:33:35.922Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "19",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "91112f21-4707-475b-ac1d-631cf6dfdd31",
					"created_datetime": "2022-03-18T05:33:35.925Z",
					"updated_datetime": "2022-03-18T05:33:35.925Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "20",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "352c3d3c-b23c-400e-9f1c-27af05daefd6",
					"created_datetime": "2022-03-18T05:33:35.929Z",
					"updated_datetime": "2022-03-18T05:33:35.929Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "21",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "d0dbec3f-9c63-4312-8621-f087bc211120",
					"created_datetime": "2022-03-18T05:33:35.932Z",
					"updated_datetime": "2022-03-18T05:33:35.932Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "22",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "9c25ca70-4a1f-4466-881f-127fa95b3662",
					"created_datetime": "2022-03-18T05:33:35.934Z",
					"updated_datetime": "2022-03-18T05:33:35.934Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_hip",
					"value_string": "23",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "a71d881d-a24b-48ce-8754-65d22b56aa5a",
					"created_datetime": "2022-03-18T05:33:35.937Z",
					"updated_datetime": "2022-03-18T05:33:35.937Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_femur",
					"value_string": "24",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "0e546dc5-63ec-4350-88db-1433731e804d",
					"created_datetime": "2022-03-18T05:33:35.940Z",
					"updated_datetime": "2022-03-18T05:33:35.940Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_knee",
					"value_string": "25",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "aa6f258e-0d91-4f36-a8e2-12ed3b0eb912",
					"created_datetime": "2022-03-18T05:33:35.952Z",
					"updated_datetime": "2022-03-18T05:33:35.952Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "26",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "2c4461c5-9825-4dff-a1e5-9c86388e9a55",
					"created_datetime": "2022-03-18T05:33:35.955Z",
					"updated_datetime": "2022-03-18T05:33:35.955Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_composite",
					"value_string": "27",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "6d8043ad-edd5-44f9-b3fb-b81ba59cd883",
					"created_datetime": "2022-03-18T05:33:35.959Z",
					"updated_datetime": "2022-03-18T05:33:35.959Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility",
					"value_string": "28",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "1a69cd27-129e-495d-8b5a-7d13b04f4111",
					"created_datetime": "2022-03-18T05:33:35.962Z",
					"updated_datetime": "2022-03-18T05:33:35.962Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability",
					"value_string": "29",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "1055fe76-1081-443f-aa1a-9bcace41cf64",
					"created_datetime": "2022-03-18T05:33:35.965Z",
					"updated_datetime": "2022-03-18T05:33:35.965Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_posture",
					"value_string": "30",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "40dd344d-d580-4f68-aed9-59271fd75c7f",
					"created_datetime": "2022-03-18T05:33:35.968Z",
					"updated_datetime": "2022-03-18T05:33:35.968Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry",
					"value_string": "31",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "41296c4f-77b6-4086-b36f-055ca9ee83c0",
					"created_datetime": "2022-03-18T05:33:35.972Z",
					"updated_datetime": "2022-03-18T05:33:35.972Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_elbow_left",
					"value_string": "32",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "be43993e-3c35-4707-b70c-fdfa2c9780b9",
					"created_datetime": "2022-03-18T05:33:35.974Z",
					"updated_datetime": "2022-03-18T05:33:35.974Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_elbow_right",
					"value_string": "33",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "26afba0c-04ec-4a35-a3d6-47b18f83b9ca",
					"created_datetime": "2022-03-18T05:33:35.977Z",
					"updated_datetime": "2022-03-18T05:33:35.977Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "34",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "669150f9-473b-463c-bc9b-9f6bf4ae92ba",
					"created_datetime": "2022-03-18T05:33:35.980Z",
					"updated_datetime": "2022-03-18T05:33:35.980Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "35",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "16e32836-0770-4585-872e-d2515da3e64f",
					"created_datetime": "2022-03-18T05:33:35.983Z",
					"updated_datetime": "2022-03-18T05:33:35.983Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_hip_left",
					"value_string": "36",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "c3ba821c-7e33-4ffc-8704-6bd6e7936805",
					"created_datetime": "2022-03-18T05:33:35.986Z",
					"updated_datetime": "2022-03-18T05:33:35.986Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_hip_right",
					"value_string": "37",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "dfb33708-116f-4026-a846-4316549e3b35",
					"created_datetime": "2022-03-18T05:33:35.992Z",
					"updated_datetime": "2022-03-18T05:33:35.992Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_femur_left",
					"value_string": "38",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "5587afc6-4c9b-40c6-84c9-e8b89e7f2cdf",
					"created_datetime": "2022-03-18T05:33:35.995Z",
					"updated_datetime": "2022-03-18T05:33:35.995Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_femur_right",
					"value_string": "39",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "3f2a992c-722e-46ae-bc1c-995af3044342",
					"created_datetime": "2022-03-18T05:33:35.998Z",
					"updated_datetime": "2022-03-18T05:33:35.998Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_knee_left",
					"value_string": "40",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "ba1a7876-ed1c-44bf-9abe-f7bda4c06fb9",
					"created_datetime": "2022-03-18T05:33:36.002Z",
					"updated_datetime": "2022-03-18T05:33:36.002Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_knee_right",
					"value_string": "41",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "fabf14c8-1bd0-4028-8203-4ee523dcf55c",
					"created_datetime": "2022-03-18T05:33:36.005Z",
					"updated_datetime": "2022-03-18T05:33:36.005Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_tibia_left",
					"value_string": "42",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "f43d07af-b26c-4d8f-a2c1-fc02f96a0e66",
					"created_datetime": "2022-03-18T05:33:36.008Z",
					"updated_datetime": "2022-03-18T05:33:36.008Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_mobility_tibia_right",
					"value_string": "43",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "35c6fb94-4a23-4f5c-871d-95860fa6e3b1",
					"created_datetime": "2022-03-18T05:33:36.011Z",
					"updated_datetime": "2022-03-18T05:33:36.011Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability_scap_left",
					"value_string": "44",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "4c5f8d82-cc2d-43ee-9943-1b5ea8242d0d",
					"created_datetime": "2022-03-18T05:33:36.014Z",
					"updated_datetime": "2022-03-18T05:33:36.014Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability_scap_right",
					"value_string": "45",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "4e9c4963-a81f-4580-adaa-7a48d5cdb190",
					"created_datetime": "2022-03-18T05:33:36.020Z",
					"updated_datetime": "2022-03-18T05:33:36.020Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "46",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "60b82781-0cec-49e1-abf5-e995d5ef9aed",
					"created_datetime": "2022-03-18T05:33:36.033Z",
					"updated_datetime": "2022-03-18T05:33:36.033Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "47",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "f7bb779f-fb3a-40a4-8393-6efc0f5634b2",
					"created_datetime": "2022-03-18T05:33:36.036Z",
					"updated_datetime": "2022-03-18T05:33:36.036Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "48",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "99a75833-0677-4e7a-8366-c3d7a3fc2cd7",
					"created_datetime": "2022-03-18T05:33:36.039Z",
					"updated_datetime": "2022-03-18T05:33:36.039Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_posture_shoulder_level",
					"value_string": "49",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "a5471668-90e3-45b7-b932-be4ebe8d930d",
					"created_datetime": "2022-03-18T05:33:36.042Z",
					"updated_datetime": "2022-03-18T05:33:36.042Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "50",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "d64e5ffc-a3fc-44fd-9738-9052143a0a28",
					"created_datetime": "2022-03-18T05:33:36.045Z",
					"updated_datetime": "2022-03-18T05:33:36.045Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_elbow",
					"value_string": "51",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "eb2d71bf-1bc8-4951-879a-2c32d05c01ca",
					"created_datetime": "2022-03-18T05:33:36.049Z",
					"updated_datetime": "2022-03-18T05:33:36.049Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_shoulder",
					"value_string": "52",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "a8214606-7c71-43e8-b6c8-14f0b0920d7e",
					"created_datetime": "2022-03-18T05:33:36.052Z",
					"updated_datetime": "2022-03-18T05:33:36.052Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "53",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "51f7cccf-a677-4c1a-a0e8-2f11252c3923",
					"created_datetime": "2022-03-18T05:33:36.055Z",
					"updated_datetime": "2022-03-18T05:33:36.055Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_hip",
					"value_string": "54",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "c04720dd-bf25-483c-b603-d020e0ecf389",
					"created_datetime": "2022-03-18T05:33:36.058Z",
					"updated_datetime": "2022-03-18T05:33:36.058Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_femur",
					"value_string": "55",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "6abf944b-913f-4922-9c6c-deca1cd2b645",
					"created_datetime": "2022-03-18T05:33:36.061Z",
					"updated_datetime": "2022-03-18T05:33:36.061Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_knee",
					"value_string": "56",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "c7ca8f3f-3de2-4964-b4bc-998421beba50",
					"created_datetime": "2022-03-18T05:33:36.064Z",
					"updated_datetime": "2022-03-18T05:33:36.064Z",
					"assessment_id_guid": "0351395e-ba4c-4782-af91-6262dd10b36f",
					"name_string": "score_symmetry_tibia",
					"value_string": "57",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
			"created_datetime": "2022-03-18T05:40:33.720Z",
			"updated_datetime": "2022-03-18T05:40:33.720Z",
			"submitter_user_id_guid": "52a45f65-42ce-45fd-9626-2df0fe387b00",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "0cdbd661-af00-4559-80ad-9e433e94605c",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "5e565195-b804-4fa2-9edd-bbeaa43f71ba",
					"created_datetime": "2022-03-18T05:40:33.775Z",
					"updated_datetime": "2022-03-18T05:40:33.775Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:43:22.606Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "0d859587-c201-4880-8bac-11201e01a3cf",
					"created_datetime": "2022-03-18T05:40:33.779Z",
					"updated_datetime": "2022-03-18T05:40:33.779Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "e54bbb92-df7c-4959-b826-48d2be5a3e13",
					"created_datetime": "2022-03-18T05:40:33.783Z",
					"updated_datetime": "2022-03-18T05:40:33.783Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "-132.4118327595527",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "22440c42-2208-4e24-b17e-8cf0e64ed2d4",
					"created_datetime": "2022-03-18T05:40:33.786Z",
					"updated_datetime": "2022-03-18T05:40:33.786Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "122.89967256956135",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "f9e8be90-8e13-43a2-b4b3-4a7eeee85e70",
					"created_datetime": "2022-03-18T05:40:33.789Z",
					"updated_datetime": "2022-03-18T05:40:33.789Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-149.4217524360138",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "d1d8e21e-f085-4e67-a835-b8616b8c8ad1",
					"created_datetime": "2022-03-18T05:40:33.792Z",
					"updated_datetime": "2022-03-18T05:40:33.792Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-82.35382790087587",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "50984f7f-e62e-461c-9b72-5c14598690a2",
					"created_datetime": "2022-03-18T05:40:33.795Z",
					"updated_datetime": "2022-03-18T05:40:33.795Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-50.46546782684473",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "e44ad635-85ab-4ea5-8e92-01e0f5f8e33e",
					"created_datetime": "2022-03-18T05:40:33.798Z",
					"updated_datetime": "2022-03-18T05:40:33.798Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "-7.47607111182333",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "1f1b1e15-467a-4b09-a1a9-e4de174c00ad",
					"created_datetime": "2022-03-18T05:40:33.803Z",
					"updated_datetime": "2022-03-18T05:40:33.803Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "-40.207268271684",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "5529050a-aadd-4cff-800d-29d1b25c4e3a",
					"created_datetime": "2022-03-18T05:40:33.808Z",
					"updated_datetime": "2022-03-18T05:40:33.808Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "22.027812154175223",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "738464da-f4ff-492e-88bd-0bb00c6a8747",
					"created_datetime": "2022-03-18T05:40:33.811Z",
					"updated_datetime": "2022-03-18T05:40:33.811Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-106.01248681581372",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "006e694d-bc2e-4b60-a81c-b793efb00d73",
					"created_datetime": "2022-03-18T05:40:33.814Z",
					"updated_datetime": "2022-03-18T05:40:33.814Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-154.45876404704052",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "ec1e7dc8-f41b-4a37-bc76-eaef3c97a7ef",
					"created_datetime": "2022-03-18T05:40:33.816Z",
					"updated_datetime": "2022-03-18T05:40:33.816Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "2.600658105891057",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "ce57c73a-731d-4080-b9e8-29f31006e8ab",
					"created_datetime": "2022-03-18T05:40:33.819Z",
					"updated_datetime": "2022-03-18T05:40:33.819Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "73.20700426118208",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "8bb68d83-6f7d-4854-acc9-768937b820f3",
					"created_datetime": "2022-03-18T05:40:33.821Z",
					"updated_datetime": "2022-03-18T05:40:33.821Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_stability_scap_left",
					"value_string": "74.97825921993501",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "d8fdf8e8-4ca3-4f7d-b64b-ee98e81764c7",
					"created_datetime": "2022-03-18T05:40:33.823Z",
					"updated_datetime": "2022-03-18T05:40:33.823Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-61.871825323373855",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "0a7c20f5-47df-4145-9865-39488b34b57b",
					"created_datetime": "2022-03-18T05:40:33.826Z",
					"updated_datetime": "2022-03-18T05:40:33.826Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-27.531743102015554",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "9db79534-7e0a-4228-a63f-11ffe3f640cf",
					"created_datetime": "2022-03-18T05:40:33.828Z",
					"updated_datetime": "2022-03-18T05:40:33.828Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-171.00913890151062",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "3a01dbe7-11ec-433f-81e6-27cd71e49f4c",
					"created_datetime": "2022-03-18T05:40:33.831Z",
					"updated_datetime": "2022-03-18T05:40:33.831Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "46.254340120097254",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "9d12a28d-4516-456c-9fc0-41b125b874a3",
					"created_datetime": "2022-03-18T05:40:33.833Z",
					"updated_datetime": "2022-03-18T05:40:33.833Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "156.63387356787098",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "c3df5f7c-6927-479d-b176-c7e3813166ac",
					"created_datetime": "2022-03-18T05:40:33.836Z",
					"updated_datetime": "2022-03-18T05:40:33.836Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "164.62536492702498",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "5b93e91e-1ccc-4585-95c5-858e6d051a7b",
					"created_datetime": "2022-03-18T05:40:33.839Z",
					"updated_datetime": "2022-03-18T05:40:33.839Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "31.80667732174703",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "412b13ad-3152-4874-a1b0-7079870db0dc",
					"created_datetime": "2022-03-18T05:40:33.841Z",
					"updated_datetime": "2022-03-18T05:40:33.841Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "27.598984766492265",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "10551f05-87db-40e5-89d0-63a259941004",
					"created_datetime": "2022-03-18T05:40:33.844Z",
					"updated_datetime": "2022-03-18T05:40:33.844Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-78.81699802980431",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "fa4484a9-42e9-4512-91ff-0f3fe3adcb29",
					"created_datetime": "2022-03-18T05:40:33.846Z",
					"updated_datetime": "2022-03-18T05:40:33.846Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-32.83737892635324",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "b005e914-b857-4f1f-89c1-27e934fa2f82",
					"created_datetime": "2022-03-18T05:40:33.849Z",
					"updated_datetime": "2022-03-18T05:40:33.849Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-145.76410767920322",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "87827991-1dbf-4058-8e50-b30bb9cb338d",
					"created_datetime": "2022-03-18T05:40:33.852Z",
					"updated_datetime": "2022-03-18T05:40:33.852Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_knee",
					"value_string": "151.02346982432908",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "2027f7a4-920d-4db2-bded-0a777bb3649c",
					"created_datetime": "2022-03-18T05:40:33.854Z",
					"updated_datetime": "2022-03-18T05:40:33.854Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "134.9429548040892",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "42c9a33b-4411-485a-ac00-e0145ce9b73a",
					"created_datetime": "2022-03-18T05:40:33.857Z",
					"updated_datetime": "2022-03-18T05:40:33.857Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_composite",
					"value_string": "0.4912077455659794",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "df201138-d9f5-4f3d-8415-cae3ee8436a6",
					"created_datetime": "2022-03-18T05:40:33.859Z",
					"updated_datetime": "2022-03-18T05:40:33.859Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility",
					"value_string": "0.013075450300298344",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "b7c0b696-f029-4599-a9df-b6d37e388de4",
					"created_datetime": "2022-03-18T05:40:33.863Z",
					"updated_datetime": "2022-03-18T05:40:33.863Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability",
					"value_string": "0.6124269964733429",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "9e7f4b3e-91e9-4c0e-9c58-184476dc9687",
					"created_datetime": "2022-03-18T05:40:33.866Z",
					"updated_datetime": "2022-03-18T05:40:33.866Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_posture",
					"value_string": "0.18680213106502505",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "f4a63a58-9550-4b98-aa73-543cb42cc10f",
					"created_datetime": "2022-03-18T05:40:33.868Z",
					"updated_datetime": "2022-03-18T05:40:33.868Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry",
					"value_string": "0.6617045134936038",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "77a9a0a2-9c68-4452-8458-97bc9afe61c7",
					"created_datetime": "2022-03-18T05:40:33.871Z",
					"updated_datetime": "2022-03-18T05:40:33.871Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.0014534475966447445",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "a1b541ee-9123-47dc-a326-0594e3731710",
					"created_datetime": "2022-03-18T05:40:33.873Z",
					"updated_datetime": "2022-03-18T05:40:33.873Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.6667286617406807",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "989cc8f7-ab2b-493e-83f4-38f793aa47e8",
					"created_datetime": "2022-03-18T05:40:33.876Z",
					"updated_datetime": "2022-03-18T05:40:33.876Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.10023498131564917",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "0d794670-266f-4390-b74c-d89ac4f1044f",
					"created_datetime": "2022-03-18T05:40:33.879Z",
					"updated_datetime": "2022-03-18T05:40:33.879Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.2143718265785443",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "98bde8f6-d3ac-41e6-ba55-f8e736d3ebc3",
					"created_datetime": "2022-03-18T05:40:33.881Z",
					"updated_datetime": "2022-03-18T05:40:33.881Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.4567854744390033",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "180e974c-256d-4bb0-a128-950acdca7713",
					"created_datetime": "2022-03-18T05:40:33.884Z",
					"updated_datetime": "2022-03-18T05:40:33.884Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.19023566125757302",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "f96a39ca-b94c-4103-b009-c5bd5c4434f5",
					"created_datetime": "2022-03-18T05:40:33.887Z",
					"updated_datetime": "2022-03-18T05:40:33.887Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.7209950859826101",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "dec29c2f-b00d-4603-a1ba-da849c604f09",
					"created_datetime": "2022-03-18T05:40:33.890Z",
					"updated_datetime": "2022-03-18T05:40:33.890Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.4360116172307439",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "4e1af120-c6e0-4e23-91f0-e5502c03c178",
					"created_datetime": "2022-03-18T05:40:33.892Z",
					"updated_datetime": "2022-03-18T05:40:33.892Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.10551085222733093",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "dde6ddf9-3d0b-45da-8603-74abf9775d43",
					"created_datetime": "2022-03-18T05:40:33.896Z",
					"updated_datetime": "2022-03-18T05:40:33.896Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.1600276786394961",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "8abbc5f9-c9f1-40a0-ae2d-0a29f0589c30",
					"created_datetime": "2022-03-18T05:40:33.898Z",
					"updated_datetime": "2022-03-18T05:40:33.898Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.8326810898834099",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "feaa83ca-cb62-45da-bf23-292f92e31a95",
					"created_datetime": "2022-03-18T05:40:33.901Z",
					"updated_datetime": "2022-03-18T05:40:33.901Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.1969297107548377",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "bfb82b27-9c79-4957-9d6c-13a1bc370c65",
					"created_datetime": "2022-03-18T05:40:33.903Z",
					"updated_datetime": "2022-03-18T05:40:33.903Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability_scap_left",
					"value_string": "0.8243590978000006",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "7f16df4f-6b17-41d7-8d3f-7d435bc72d63",
					"created_datetime": "2022-03-18T05:40:33.906Z",
					"updated_datetime": "2022-03-18T05:40:33.906Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability_scap_right",
					"value_string": "0.8156038008114904",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "9e71c2f8-7f02-4bcb-8a55-5ce11f6f6b63",
					"created_datetime": "2022-03-18T05:40:33.908Z",
					"updated_datetime": "2022-03-18T05:40:33.908Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.5522944816178126",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "1887453d-bc84-4a27-89fa-7962bd497dca",
					"created_datetime": "2022-03-18T05:40:33.911Z",
					"updated_datetime": "2022-03-18T05:40:33.911Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.024621044582248168",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "4d2aeee9-fd6e-49ee-8484-54ef390cc488",
					"created_datetime": "2022-03-18T05:40:33.913Z",
					"updated_datetime": "2022-03-18T05:40:33.913Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.7038666101593813",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "6bb701c4-e81e-4f37-ad54-312cc2fe05ae",
					"created_datetime": "2022-03-18T05:40:33.916Z",
					"updated_datetime": "2022-03-18T05:40:33.916Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.8053046844805754",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "d8b4625a-fdc0-46a6-80fe-5d31d43fdfdc",
					"created_datetime": "2022-03-18T05:40:33.919Z",
					"updated_datetime": "2022-03-18T05:40:33.919Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.7859974633099049",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "ddf1c4ac-2885-46d6-967d-415962b65b82",
					"created_datetime": "2022-03-18T05:40:33.921Z",
					"updated_datetime": "2022-03-18T05:40:33.921Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.6306027742507101",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "d4daa2d4-0b54-4c14-85f6-bcb5ee11f034",
					"created_datetime": "2022-03-18T05:40:33.924Z",
					"updated_datetime": "2022-03-18T05:40:33.924Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.4682950935902796",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "e346e79d-4195-4c0e-93ff-f4ff581ec300",
					"created_datetime": "2022-03-18T05:40:33.926Z",
					"updated_datetime": "2022-03-18T05:40:33.926Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.5366744422005023",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "83270f5b-d6b2-4bf2-9c53-8983dddb7883",
					"created_datetime": "2022-03-18T05:40:33.929Z",
					"updated_datetime": "2022-03-18T05:40:33.929Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_hip",
					"value_string": "0.21374283475464664",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "395e6615-79ea-41bb-881a-07388b3e29de",
					"created_datetime": "2022-03-18T05:40:33.932Z",
					"updated_datetime": "2022-03-18T05:40:33.932Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_femur",
					"value_string": "0.46899254314325173",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "55358923-133e-4ad9-8051-529723783b70",
					"created_datetime": "2022-03-18T05:40:33.936Z",
					"updated_datetime": "2022-03-18T05:40:33.936Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_knee",
					"value_string": "0.17624870138637289",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "5dc89c90-dd6d-43fe-b66d-d23564eeecf1",
					"created_datetime": "2022-03-18T05:40:33.939Z",
					"updated_datetime": "2022-03-18T05:40:33.939Z",
					"assessment_id_guid": "0ce3c461-05da-43bc-8b94-e9bc3c64485b",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.15826166742112766",
					"unit_string": "°"
				}
			}
		},
		{
			"id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
			"created_datetime": "2022-03-18T05:40:43.527Z",
			"updated_datetime": "2022-03-18T05:40:43.527Z",
			"submitter_user_id_guid": "52a45f65-42ce-45fd-9626-2df0fe387b00",
			"athlete_user_id_guid": "7785c215-2f1a-4346-beaf-f7d3adb7f4b0",
			"external_id_guid": "b13e094b-92c0-4c8a-897a-e381b6dda3bd",
			"attribute_hash": {
				"ios_datetime": {
					"id_guid": "1ac7f64b-033f-4a94-925a-4fa827801808",
					"created_datetime": "2022-03-18T05:40:43.532Z",
					"updated_datetime": "2022-03-18T05:40:43.532Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "ios_datetime",
					"value_string": "2022-03-18T05:43:32.416Z",
					"unit_string": null
				},
				"ios_app_version": {
					"id_guid": "da5d1522-8254-45a9-a461-03d2e2e38deb",
					"created_datetime": "2022-03-18T05:40:43.535Z",
					"updated_datetime": "2022-03-18T05:40:43.535Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "ios_app_version",
					"value_string": "iOS v1.0.7",
					"unit_string": null
				},
				"measurement_mobility_elbow_left": {
					"id_guid": "f9d866e0-7285-4afe-b034-dff1ade6bb15",
					"created_datetime": "2022-03-18T05:40:43.538Z",
					"updated_datetime": "2022-03-18T05:40:43.538Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_elbow_left",
					"value_string": "84.37231989323197",
					"unit_string": "°"
				},
				"measurement_mobility_elbow_right": {
					"id_guid": "8566aaa4-001b-4b8b-9bb5-d6267165d6db",
					"created_datetime": "2022-03-18T05:40:43.542Z",
					"updated_datetime": "2022-03-18T05:40:43.542Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_elbow_right",
					"value_string": "61.25549233893199",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_left": {
					"id_guid": "03e3fdfa-8f1d-4a85-9302-7aca524e23d4",
					"created_datetime": "2022-03-18T05:40:43.544Z",
					"updated_datetime": "2022-03-18T05:40:43.544Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_shoulder_left",
					"value_string": "-81.11978350321695",
					"unit_string": "°"
				},
				"measurement_mobility_shoulder_right": {
					"id_guid": "ede3d08f-3321-4b0d-8b9d-775147aeaa1c",
					"created_datetime": "2022-03-18T05:40:43.549Z",
					"updated_datetime": "2022-03-18T05:40:43.549Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_shoulder_right",
					"value_string": "-64.22751896510658",
					"unit_string": "°"
				},
				"measurement_mobility_hip_left": {
					"id_guid": "8d0275a0-a378-4ba7-b70e-138ae9ca89e9",
					"created_datetime": "2022-03-18T05:40:43.553Z",
					"updated_datetime": "2022-03-18T05:40:43.553Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_hip_left",
					"value_string": "-104.65534629004725",
					"unit_string": "°"
				},
				"measurement_mobility_hip_right": {
					"id_guid": "b27f3c5b-467d-4e1a-a334-55203c5b888a",
					"created_datetime": "2022-03-18T05:40:43.556Z",
					"updated_datetime": "2022-03-18T05:40:43.556Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_hip_right",
					"value_string": "83.56244731152998",
					"unit_string": "°"
				},
				"measurement_mobility_femur_left": {
					"id_guid": "3e0280bd-b84f-4fa7-8588-3dc22b747466",
					"created_datetime": "2022-03-18T05:40:43.559Z",
					"updated_datetime": "2022-03-18T05:40:43.559Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_femur_left",
					"value_string": "144.3050928042365",
					"unit_string": "°"
				},
				"measurement_mobility_femur_right": {
					"id_guid": "625df2cd-4e8c-47b6-b04b-28ebda045afc",
					"created_datetime": "2022-03-18T05:40:43.561Z",
					"updated_datetime": "2022-03-18T05:40:43.561Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_femur_right",
					"value_string": "49.965968927989024",
					"unit_string": "°"
				},
				"measurement_mobility_knee_left": {
					"id_guid": "fa7a4899-d8d0-4c86-b956-da4bc2d0f1ea",
					"created_datetime": "2022-03-18T05:40:43.647Z",
					"updated_datetime": "2022-03-18T05:40:43.647Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_knee_left",
					"value_string": "-170.53974042164327",
					"unit_string": "°"
				},
				"measurement_mobility_knee_right": {
					"id_guid": "eb657c31-734b-451b-83eb-ae1b3bc5a0fc",
					"created_datetime": "2022-03-18T05:40:43.651Z",
					"updated_datetime": "2022-03-18T05:40:43.651Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_knee_right",
					"value_string": "-26.18901704227605",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_left": {
					"id_guid": "d439cf32-19d4-4d5e-aa56-539b10582755",
					"created_datetime": "2022-03-18T05:40:43.654Z",
					"updated_datetime": "2022-03-18T05:40:43.654Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_tibia_left",
					"value_string": "-59.781027761759006",
					"unit_string": "°"
				},
				"measurement_mobility_tibia_right": {
					"id_guid": "ad54d0fd-ed16-48fc-bf4d-86e5ec92464c",
					"created_datetime": "2022-03-18T05:40:43.658Z",
					"updated_datetime": "2022-03-18T05:40:43.658Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_mobility_tibia_right",
					"value_string": "-170.4713415917889",
					"unit_string": "°"
				},
				"measurement_stability_scap_left": {
					"id_guid": "71309f2c-fc6d-4cc3-967b-ed149d1389cd",
					"created_datetime": "2022-03-18T05:40:43.662Z",
					"updated_datetime": "2022-03-18T05:40:43.662Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_stability_scap_left",
					"value_string": "42.46140550166609",
					"unit_string": "°"
				},
				"measurement_stability_scap_right": {
					"id_guid": "34850624-04d3-4408-a2f1-3b24f41afdbd",
					"created_datetime": "2022-03-18T05:40:43.666Z",
					"updated_datetime": "2022-03-18T05:40:43.666Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_stability_scap_right",
					"value_string": "-128.88452450346546",
					"unit_string": "°"
				},
				"measurement_stability_spine_center_yz": {
					"id_guid": "3cfc158a-df98-4be3-9587-79746d172361",
					"created_datetime": "2022-03-18T05:40:43.669Z",
					"updated_datetime": "2022-03-18T05:40:43.669Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_stability_spine_center_yz",
					"value_string": "-61.439210961029985",
					"unit_string": "°"
				},
				"measurement_stability_tibia_left_xy": {
					"id_guid": "7f82fd83-ac80-4bcb-908e-5eb888a9ade8",
					"created_datetime": "2022-03-18T05:40:43.672Z",
					"updated_datetime": "2022-03-18T05:40:43.672Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_stability_tibia_left_xy",
					"value_string": "-72.43901183441295",
					"unit_string": "°"
				},
				"measurement_stability_tibia_right_xy": {
					"id_guid": "242a785f-8772-4a9c-9104-8adeac271ee0",
					"created_datetime": "2022-03-18T05:40:43.674Z",
					"updated_datetime": "2022-03-18T05:40:43.674Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_stability_tibia_right_xy",
					"value_string": "-88.89095853068119",
					"unit_string": "°"
				},
				"measurement_posture_shoulder_level": {
					"id_guid": "021ba210-8a4b-47a8-b830-c60e44d13be2",
					"created_datetime": "2022-03-18T05:40:43.680Z",
					"updated_datetime": "2022-03-18T05:40:43.680Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_posture_shoulder_level",
					"value_string": "76.87317528153272",
					"unit_string": "°"
				},
				"measurement_posture_spine_center_xy": {
					"id_guid": "89187451-2cf2-4611-b211-f9e32d5d00cf",
					"created_datetime": "2022-03-18T05:40:43.683Z",
					"updated_datetime": "2022-03-18T05:40:43.683Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_posture_spine_center_xy",
					"value_string": "99.95447924067702",
					"unit_string": "°"
				},
				"measurement_symmetry_elbow": {
					"id_guid": "da3f315b-4034-4a27-9d06-6b114002517f",
					"created_datetime": "2022-03-18T05:40:43.686Z",
					"updated_datetime": "2022-03-18T05:40:43.686Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_elbow",
					"value_string": "-98.1730764376711",
					"unit_string": "°"
				},
				"measurement_symmetry_shoulder": {
					"id_guid": "5a4f9e2c-1089-4df0-8a63-2f1b68260df5",
					"created_datetime": "2022-03-18T05:40:43.689Z",
					"updated_datetime": "2022-03-18T05:40:43.689Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_shoulder",
					"value_string": "41.989593475472475",
					"unit_string": "°"
				},
				"measurement_symmetry_spine_center_xy": {
					"id_guid": "268981d7-2ef2-40a9-9ed9-d3cd39e3ef98",
					"created_datetime": "2022-03-18T05:40:43.692Z",
					"updated_datetime": "2022-03-18T05:40:43.692Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_spine_center_xy",
					"value_string": "-105.27138620389226",
					"unit_string": "°"
				},
				"measurement_symmetry_hip": {
					"id_guid": "3d9efb1a-e349-4e57-b236-ee0470d276da",
					"created_datetime": "2022-03-18T05:40:43.695Z",
					"updated_datetime": "2022-03-18T05:40:43.695Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_hip",
					"value_string": "-65.62346286825034",
					"unit_string": "°"
				},
				"measurement_symmetry_femur": {
					"id_guid": "9d6f98f1-a77b-459f-a65b-9eb4f3acf397",
					"created_datetime": "2022-03-18T05:40:43.698Z",
					"updated_datetime": "2022-03-18T05:40:43.698Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_femur",
					"value_string": "-156.64429458232712",
					"unit_string": "°"
				},
				"measurement_symmetry_knee": {
					"id_guid": "dee90984-4747-4a6f-af7c-ef45c76c62b2",
					"created_datetime": "2022-03-18T05:40:43.701Z",
					"updated_datetime": "2022-03-18T05:40:43.701Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_knee",
					"value_string": "-90.50855209535597",
					"unit_string": "°"
				},
				"measurement_symmetry_tibia": {
					"id_guid": "cca36029-3cd0-4479-b07b-f9a2de1e86d7",
					"created_datetime": "2022-03-18T05:40:43.704Z",
					"updated_datetime": "2022-03-18T05:40:43.704Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "measurement_symmetry_tibia",
					"value_string": "-88.6740710836647",
					"unit_string": "°"
				},
				"score_composite": {
					"id_guid": "6d6b23c6-0368-4470-8a37-327031dd5767",
					"created_datetime": "2022-03-18T05:40:43.707Z",
					"updated_datetime": "2022-03-18T05:40:43.707Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_composite",
					"value_string": "0.3993203366921518",
					"unit_string": null
				},
				"score_mobility": {
					"id_guid": "6023450b-bc89-4fbb-a1ce-3f34232be781",
					"created_datetime": "2022-03-18T05:40:43.710Z",
					"updated_datetime": "2022-03-18T05:40:43.710Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility",
					"value_string": "0.14466444943805876",
					"unit_string": null
				},
				"score_stability": {
					"id_guid": "ee53ebe8-c949-4368-8837-2925c8bbd0fc",
					"created_datetime": "2022-03-18T05:40:43.712Z",
					"updated_datetime": "2022-03-18T05:40:43.712Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability",
					"value_string": "0.25885077520560745",
					"unit_string": null
				},
				"score_posture": {
					"id_guid": "a4771569-d855-4340-beff-6ab28d7512c4",
					"created_datetime": "2022-03-18T05:40:43.715Z",
					"updated_datetime": "2022-03-18T05:40:43.715Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_posture",
					"value_string": "0.4364916313480397",
					"unit_string": null
				},
				"score_symmetry": {
					"id_guid": "215a18fd-47f3-464a-b378-a94fe7f7b991",
					"created_datetime": "2022-03-18T05:40:43.733Z",
					"updated_datetime": "2022-03-18T05:40:43.733Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry",
					"value_string": "0.7074678166532884",
					"unit_string": null
				},
				"score_mobility_elbow_left": {
					"id_guid": "2e328491-11cc-47f1-a5b1-27c471c5af85",
					"created_datetime": "2022-03-18T05:40:43.735Z",
					"updated_datetime": "2022-03-18T05:40:43.735Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_elbow_left",
					"value_string": "0.7468521987700385",
					"unit_string": "°"
				},
				"score_mobility_elbow_right": {
					"id_guid": "b4fd6361-5a0e-4d90-a752-e4b4d7de712a",
					"created_datetime": "2022-03-18T05:40:43.738Z",
					"updated_datetime": "2022-03-18T05:40:43.738Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_elbow_right",
					"value_string": "0.7985864991967243",
					"unit_string": "°"
				},
				"score_mobility_shoulder_left": {
					"id_guid": "66e8cefc-e4cc-4ac0-ad2c-013bbad1d81f",
					"created_datetime": "2022-03-18T05:40:43.742Z",
					"updated_datetime": "2022-03-18T05:40:43.742Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_shoulder_left",
					"value_string": "0.5355849073039115",
					"unit_string": "°"
				},
				"score_mobility_shoulder_right": {
					"id_guid": "21b0251d-6beb-4deb-ba61-5cc40129010a",
					"created_datetime": "2022-03-18T05:40:43.745Z",
					"updated_datetime": "2022-03-18T05:40:43.745Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_shoulder_right",
					"value_string": "0.3257781840790115",
					"unit_string": "°"
				},
				"score_mobility_hip_left": {
					"id_guid": "1d4182c3-d43f-482a-bfc8-e7fb0c8985fc",
					"created_datetime": "2022-03-18T05:40:43.748Z",
					"updated_datetime": "2022-03-18T05:40:43.748Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_hip_left",
					"value_string": "0.5038281029456341",
					"unit_string": "°"
				},
				"score_mobility_hip_right": {
					"id_guid": "1ad3cff3-7adf-4b47-8f82-9152a4b0673a",
					"created_datetime": "2022-03-18T05:40:43.750Z",
					"updated_datetime": "2022-03-18T05:40:43.750Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_hip_right",
					"value_string": "0.8398017273839201",
					"unit_string": "°"
				},
				"score_mobility_femur_left": {
					"id_guid": "4c92a36e-458d-4a50-bda1-8a7132479454",
					"created_datetime": "2022-03-18T05:40:43.753Z",
					"updated_datetime": "2022-03-18T05:40:43.753Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_femur_left",
					"value_string": "0.8965952286502716",
					"unit_string": "°"
				},
				"score_mobility_femur_right": {
					"id_guid": "d9296458-2703-46e9-97dd-0b3627fb7b69",
					"created_datetime": "2022-03-18T05:40:43.756Z",
					"updated_datetime": "2022-03-18T05:40:43.756Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_femur_right",
					"value_string": "0.04894446295536126",
					"unit_string": "°"
				},
				"score_mobility_knee_left": {
					"id_guid": "8388cafa-b2ca-48db-b2d0-3e44bb5d8f4b",
					"created_datetime": "2022-03-18T05:40:43.759Z",
					"updated_datetime": "2022-03-18T05:40:43.759Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_knee_left",
					"value_string": "0.8483641527354939",
					"unit_string": "°"
				},
				"score_mobility_knee_right": {
					"id_guid": "557ad72a-3da4-444d-ad61-36c15d9672b1",
					"created_datetime": "2022-03-18T05:40:43.762Z",
					"updated_datetime": "2022-03-18T05:40:43.762Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_knee_right",
					"value_string": "0.4614899000093192",
					"unit_string": "°"
				},
				"score_mobility_tibia_left": {
					"id_guid": "f7f67fba-be17-4f71-a330-460751d532af",
					"created_datetime": "2022-03-18T05:40:43.764Z",
					"updated_datetime": "2022-03-18T05:40:43.764Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_tibia_left",
					"value_string": "0.7406539949032814",
					"unit_string": "°"
				},
				"score_mobility_tibia_right": {
					"id_guid": "5bca002b-b862-4904-bc51-2b04d8872257",
					"created_datetime": "2022-03-18T05:40:43.767Z",
					"updated_datetime": "2022-03-18T05:40:43.767Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_mobility_tibia_right",
					"value_string": "0.60362274115433",
					"unit_string": "°"
				},
				"score_stability_scap_left": {
					"id_guid": "33ac7a04-01c8-4b3e-bcc7-25cc2d4b49b4",
					"created_datetime": "2022-03-18T05:40:43.770Z",
					"updated_datetime": "2022-03-18T05:40:43.770Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability_scap_left",
					"value_string": "0.39175586938323975",
					"unit_string": "°"
				},
				"score_stability_scap_right": {
					"id_guid": "2f33ba08-0be2-4168-901e-801e12fe48d9",
					"created_datetime": "2022-03-18T05:40:43.772Z",
					"updated_datetime": "2022-03-18T05:40:43.772Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability_scap_right",
					"value_string": "0.27288285118748024",
					"unit_string": "°"
				},
				"score_stability_spine_center_yz": {
					"id_guid": "79965a45-c0f3-4fb3-bbc3-92f5ea237581",
					"created_datetime": "2022-03-18T05:40:43.775Z",
					"updated_datetime": "2022-03-18T05:40:43.775Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability_spine_center_yz",
					"value_string": "0.0884262577104252",
					"unit_string": "°"
				},
				"score_stability_tibia_left_xy": {
					"id_guid": "76a3cbd3-ae28-4ccd-9158-b2c05bea5e07",
					"created_datetime": "2022-03-18T05:40:43.778Z",
					"updated_datetime": "2022-03-18T05:40:43.778Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability_tibia_left_xy",
					"value_string": "0.7562165432047201",
					"unit_string": "°"
				},
				"score_stability_tibia_right_xy": {
					"id_guid": "1c0fb2ce-c36d-48d9-b14b-a68fe8e7d343",
					"created_datetime": "2022-03-18T05:40:43.780Z",
					"updated_datetime": "2022-03-18T05:40:43.780Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_stability_tibia_right_xy",
					"value_string": "0.11755827773923604",
					"unit_string": "°"
				},
				"score_posture_shoulder_level": {
					"id_guid": "a742889c-041a-4137-9655-f151b7168cfe",
					"created_datetime": "2022-03-18T05:40:43.783Z",
					"updated_datetime": "2022-03-18T05:40:43.783Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_posture_shoulder_level",
					"value_string": "0.6180043019559863",
					"unit_string": "°"
				},
				"score_posture_spine_center_xy": {
					"id_guid": "084bb50c-21cf-4881-ac4b-fc9520aef3db",
					"created_datetime": "2022-03-18T05:40:43.786Z",
					"updated_datetime": "2022-03-18T05:40:43.786Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_posture_spine_center_xy",
					"value_string": "0.9284833986818438",
					"unit_string": "°"
				},
				"score_symmetry_elbow": {
					"id_guid": "640f0808-7921-4bb0-8096-f754f32192cd",
					"created_datetime": "2022-03-18T05:40:43.788Z",
					"updated_datetime": "2022-03-18T05:40:43.788Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_elbow",
					"value_string": "0.2085927070879234",
					"unit_string": "°"
				},
				"score_symmetry_shoulder": {
					"id_guid": "f1d301e1-0844-464f-8863-1d27b5301464",
					"created_datetime": "2022-03-18T05:40:43.791Z",
					"updated_datetime": "2022-03-18T05:40:43.791Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_shoulder",
					"value_string": "0.8058742292014311",
					"unit_string": "°"
				},
				"score_symmetry_spine_center_xy": {
					"id_guid": "b264d734-f292-4e7d-a3b9-faf9e206160d",
					"created_datetime": "2022-03-18T05:40:43.793Z",
					"updated_datetime": "2022-03-18T05:40:43.793Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_spine_center_xy",
					"value_string": "0.797191017411511",
					"unit_string": "°"
				},
				"score_symmetry_hip": {
					"id_guid": "acb6eb46-b617-47fd-b68d-ebd8d6d4636d",
					"created_datetime": "2022-03-18T05:40:43.797Z",
					"updated_datetime": "2022-03-18T05:40:43.797Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_hip",
					"value_string": "0.47054305924494094",
					"unit_string": "°"
				},
				"score_symmetry_femur": {
					"id_guid": "a642fee2-3dd4-4f54-a0df-50ee6f0be8a3",
					"created_datetime": "2022-03-18T05:40:43.800Z",
					"updated_datetime": "2022-03-18T05:40:43.800Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_femur",
					"value_string": "0.022342405789768822",
					"unit_string": "°"
				},
				"score_symmetry_knee": {
					"id_guid": "4cecf0f7-bbd1-44ea-802f-293e21e0c2dc",
					"created_datetime": "2022-03-18T05:40:43.802Z",
					"updated_datetime": "2022-03-18T05:40:43.802Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_knee",
					"value_string": "0.7204741993466287",
					"unit_string": "°"
				},
				"score_symmetry_tibia": {
					"id_guid": "facd4baa-d64f-4031-a028-f22a659dc72a",
					"created_datetime": "2022-03-18T05:40:43.805Z",
					"updated_datetime": "2022-03-18T05:40:43.805Z",
					"assessment_id_guid": "4117f718-623d-460a-8e2d-26ecc18a8b4f",
					"name_string": "score_symmetry_tibia",
					"value_string": "0.7659633197693416",
					"unit_string": "°"
				}
			}
		}
	]