import React from 'react';

import { format } from 'date-fns';

import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedLineSeries,
  AnimatedAreaSeries,
  XYChart,
  Tooltip,
} from '@visx/xychart';
import {
  LegendOrdinal,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

const stabilityDataKey = isStretchLab ? 'Activation' : 'Stability'

function capitalize(word) { // word => Word,  wORd => Word, WORD => Word
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

const ordinalColorScale = scaleOrdinal({
  domain: ['Composite', 'Mobility', isStretchLab ? 'Activation' : 'Stability', 'Posture', 'Symmetry'],
  range: ['#0c7285', '#66d9e8', '#fcc419', '#ff8787', '#9c36b5'],
});

const legendGlyphSize = 15;

// Define the graph dimensions and margins
const width = parseInt(0.9 * 0.8 * window.innerWidth); // TODO: parseInt?
const height = parseInt(0.9 * 0.2 * window.innerWidth); // TODO: parseInt?
// const margin = { top: 30, bottom: 30, left: 10, right: 20 };

function MemberRecentScansChart(props) {
  let compositeScores = [];
  let mobilityScores = [];
  let stabilityScores = [];
  let postureScores = [];
  let symmetryScores = [];

  // earliest scans will appear nearest the x-origin, dateTime increasing with x 
  const sortedScans = props.data.slice().sort(function(firstScan, secondScan) {
    if(firstScan.dateTime > secondScan.dateTime) return 1;
    if(firstScan.dateTime < secondScan.dateTime) return -1;
    return 0;
   });

   sortedScans.map(scanMetas => {
    compositeScores.push( { x: scanMetas.dateTime, y: parseInt(scanMetas.scoreComposite, 10) });
    mobilityScores.push( { x: scanMetas.dateTime, y: parseInt(scanMetas.scoreMobility, 10) });
    stabilityScores.push( { x: scanMetas.dateTime, y: parseInt(scanMetas.scoreStability, 10) });
    postureScores.push( { x: scanMetas.dateTime, y: parseInt(scanMetas.scorePosture, 10) });
    symmetryScores.push( { x: scanMetas.dateTime, y: parseInt(scanMetas.scoreSymmetry, 10) });
    return true; // expected to return something from an arrow function...
  })

  const accessors = {
    xAccessor: d => d.x,
    yAccessor: d => d.y,
  };

  return (
    <>
      <XYChart width={width} height={height} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}> 
        <AnimatedAxis orientation="left" />
        <AnimatedAxis orientation="bottom">
          {props => {
            const tickLabelSize = 10;
            const tickRotate = -45;
            const tickColor = '#888888';
            const axisCenter =
              (props.axisToPoint.x - props.axisFromPoint.x) / 2;
            return (
              <g className="my-custom-bottom-axis">
                {props.ticks.map((tick, i) => {
                  const tickX = tick.to.x;
                  const tickY =
                    tick.to.y + tickLabelSize + props.tickLength;
                  return (
                    <Group
                      key={`vx-tick-${tick.value}-${i}`}
                      className={'vx-axis-tick'}
                    >
                      <text
                        transform={`translate(${tickX -15}, ${tickY + 10}) rotate(${tickRotate})`}
                        fontSize={tickLabelSize}
                        textAnchor="middle"
                        fill={tickColor}
                      >
                        {format(new Date(tick.value), 'MM/dd/yyyy')}{/* tick.formattedValue */}
                      </text>
                    </Group>
                  );
                })}
                <text
                  textAnchor="middle"
                  transform={`translate(${axisCenter}, 50)`}
                  fontSize="8"
                >
                  {props.label}
                </text>
              </g>
            );
          }}
        </AnimatedAxis>
        <AnimatedGrid columns={false} numTicks={4} />
        <AnimatedAreaSeries dataKey="Composite" data={compositeScores} {...accessors} style={{ opacity: "0.1", fill: "#00acba" }}/>
        <AnimatedLineSeries dataKey="Mobility" data={mobilityScores} {...accessors} />
        <AnimatedLineSeries dataKey={stabilityDataKey} data={stabilityScores} {...accessors} />
        <AnimatedLineSeries dataKey="Posture" data={postureScores} {...accessors} />
        <AnimatedLineSeries dataKey="Symmetry" data={symmetryScores} {...accessors} />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => (
            <div>
              <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                {tooltipData.nearestDatum.key}
              </div>
              {accessors.xAccessor(tooltipData.nearestDatum.datum)}
              {', '}
              {accessors.yAccessor(tooltipData.nearestDatum.datum)}
            </div>
          )}
        />
      </XYChart>
      <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label.toUpperCase()}`}>
        {(labels) => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                margin="20px 0px 0px 40px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} radius={legendGlyphSize / 5} />
                </svg>
                <LegendLabel align="left" margin="0 0 0 10px">
                  {capitalize(label.text.toLowerCase())}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </>
  );
}

export default MemberRecentScansChart;