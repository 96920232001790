import React, { useState } from 'react';

import styled from 'styled-components';
import ScoreCircle from './ScoreCircle';

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

const lightGreyText = "#b4b4b4";

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`;

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
    accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead>
    <tr style={{ borderBottom: "1px solid #e9e9e9" }}>
     {columns.map(({ label, accessor, sortable }) => {
      return (
        <th 
          key={accessor}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{ fontSize: "1vw", color: "#b4b4b4", fontWeight: "400", paddingRight: "2.2vw", paddingLeft: "2.2vw" }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
 };

 const TableBody = ({ tableData, columns, onRowClick }) => {
  return (
   <tbody>
    {tableData.map((data) => {
     return (
      <SortableTableRow key={data.id_guid}  onClick={function(){onRowClick(data)}}>
       {columns.map(({ accessor, align, color }) => {
         if (accessor === "date" || accessor === "time") {
          const tData = data[accessor] ? data[accessor] : "——";
          return <td key={accessor} align={align} style={{ color: color, fontSize: "1vw", padding: 0 }}>
            {tData}
          </td>;
         } else {
           const trendAccessor = accessor + "_trend";
          return <td key={accessor} align={align} style={{ color: color, fontSize: "1vw", padding: 0 }}>
            <ScoreCircle
              size={window.innerWidth*0.045}
              score={data[accessor]}
              trend={data[trendAccessor]}
            />
          </td>;
         }

       })}
      </SortableTableRow>
     );
    })}
   </tbody>
  );
 };

function AssessmentsTable(props) {
  // const {
  //   assessments,
  //   handleClick
  // } = props;

  const [tableData, setTableData] = useState(props.assessments); // TODO

  // console.log('~~~~~ tableData.length: ' + tableData.length);
  // console.log('~~~~~ props.assessments.length: ' + props.assessments.length);

  const columns = [
    { label: "Date", accessor: "date", align: "center", color: lightGreyText, sortable: true },
    { label: "Time", accessor: "time", align: "center", color: lightGreyText, sortable: false },
    { label: "Overall Score", accessor: "scoreComposite", align: "center", color: lightGreyText, sortable: true },
    { label: "Mobility", accessor: "scoreMobility", align: "center", color: lightGreyText, sortable: true },
    { label: isStretchLab ? "Activation" : "Stability", accessor: "scoreStability", align: "center", color: lightGreyText, sortable: true },
    { label: "Posture", accessor: "scorePosture", align: "center", color: lightGreyText, sortable: true },
    { label: "Symmetry", accessor: "scoreSymmetry", align: "center", color: lightGreyText, sortable: true }
  ];

   const handleSorting = (sortField, sortOrder) => {
    setTableData(props.assessments);
    console.log('~~~~~ handleSorting() - tableData.length: ' + tableData.length);
    
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
       return (
        a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
         numeric: true,
        }) * (sortOrder === "asc" ? 1 : -1)
       );
      });
      setTableData(sorted);
     }
   };

  return (
    <>
      <table cellPadding={0}>
        {/* <TableHead columns={columns} handleSorting={handleSorting} /> */}
        <TableHead {...{ columns, handleSorting }} />
        <TableBody columns={columns} tableData={props.assessments} onRowClick={props.handleClick} />
      </table>
    </>
  );
}

export default AssessmentsTable;
