// models/exercise.js

import { iconSrc, colors } from '../constants';

const displayFields = [
  { // name
    type: "text",                           // could be "text", "textarea", "association", "image"?, "video"?
    displayName: "Name",                   // any string up to a maxLength of 20 characters
    placeholder: "Enter the name or title of the Exercise",                        // a string that will show in the text input until something is entered there
    accessor: "name_string",                // attribute name when item delivered from API
    default: "",                            // value of this attribute for a brand new 'blank' item
    maxChars: "20",                         // an integer as a string, if we are going to try to limit this...
    minChars: "2",                          // an integer as a string, if we are going to try to limit this, or use as empty check
    pickListInfo: "",                       // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: true                          // should this field be submitted to the api for a create or update access?
  },
  // { // subtitle
  //   type: "text",
  //   displayName: "Subtitle",
  //   placeholder: "Enter a subtitle, if desired", 
  //   accessor: "subtitle_string",
  //   default: "",
  //   maxChars: "20",
  //   minChars: "2",
  //   pickListInfo: "",
  //   required: false
  // },
  // { // video
  //   type: "video",
  //   displayName: "Video (URL) [mouseover video to play, once URL entered]",
  //   placeholder: "Enter something like https://ourdomain.com/videos/move_1.mp4",
  //   accessor: "video_url_string",
  //   default: iconSrc.exercises,             // TODO: put an asset in src/public to show when no url provided
  //   maxChars: "2000",
  //   minChars: "",
  //   pickListInfo: "",
  //   required: true 
  // },
  // { // thumbnail
  //   type: "image",
  //   displayName: "Thumbnail (URL)",
  //   placeholder: "Enter something like https://ordomain.com/thumbnails/move_1.png",
  //   accessor: "thumbnail_url_string",
  //   default: iconSrc.exercises_active,      // TODO: put an asset in src/public to show when no url provided
  //   maxChars: "2000",
  //   minChars: "",
  //   pickListInfo: "",
  //   required: true 
  // },
  // { // tags
  //   type: "text",
  //   displayName: "Tags",
  //   placeholder: "Enter tags, separated by commas",
  //   accessor: "tag_list_string",
  //   default: "",
  //   maxChars: "64",
  //   minChars: "2",
  //   pickListInfo: "", 
  //   required: true
  // },
  { // movement_id_guid
    type: "movement",
    displayName: "Video",
    placeholder: "Choose the Exercise Movement",  // words that might explain or prompt for picklist?
    accessor: "movement_id_guid",
    default: "",
    maxChars: "64",
    minChars: "2",
    pickListInfo: "movement", 
    required: true
  },
  { // duration_seconds_integer
    type: "text", // how about "text_integer", so we can provide a picker or validate the string provided?
    displayName: "Duration, in seconds",
    placeholder: "Enter required duration, if applicable",
    accessor: "duration_seconds_integer",
    default: "",
    maxChars: "64",
    minChars: "2",
    pickListInfo: "", // maybe csv list of integers? "5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,105,110,115,120"
    required: true
  },
  { // repetition_integer
    type: "text", // how about "text_integer", so we can provide a picker or validate the string provided?
    displayName: "Repetitions",
    placeholder: "Enter required repetitions, if applicable",
    accessor: "repetition_integer",
    default: "",
    maxChars: "64",
    minChars: "2",
    pickListInfo: "",  // maybe csv list of integers? "1,2,3,4,..."
    required: true
  },
  { // note
    type: "textarea",
    displayName: "Notes",
    placeholder: "Visible to Athletes, and can explain specifics regarding this Exercise",
    accessor: "note_string",
    default: "",
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: true 
  },
  { // id
    type: "hidden",
    displayName: "ID",
    accessor: "id_guid",
    default: "",                            // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-id_guid")
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  },
  { // user_id
    type: "hidden",
    displayName: "User ID",
    accessor: "user_id_guid",               // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-user_id_guid")
    default: "",                        
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  }
];

const listColumns = [ // the columns list for the main table for this itemType
  { label: "Name", accessor: "name_string", align: "left", type: "text", color: colors.darkGreyText, sortable: true, minWidth: "150px" },
  { label: "Thumbnail", accessor: "thumbnail_url_string", align: "left", type: "tnail", color: colors.darkGreyText, sortable: false, minWidth: "100px", maxWidth: "100px" },
  // { label: "Public", accessor: "public_boolean", align: "center", type: "check", color: colors.lightGreyText, sortable: true, title: "This Exercise is Public", iconSrc: iconSrc.checked },
  { label: "Remove", accessor: "removeLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Delete this Exercise, remove it from your lists", iconSrc: iconSrc.remove_active }
];

const attributes = [ // these are the attributes for an exercise
  "id_guid",
  "user_id_guid",
  "video_url_string",
  "thumbnail_url_string",     // list column 2 -> show the image from the string URL as 20x30px image (rollover/hover larger?)
  "name_string",              // list column 1 -> show the string as HTML text  WHICH??
  "title_string",             // list column 1 -> show the string as HTML text  WHICH??
  "subtitle_string",
  "tag_list_string",
  "movement_id_guid",
  "duration_seconds_integer",
  "repetition_integer",
  "note_string",
  "public_boolean",           // list column 3 -> show a "true" boolean value with a checkmark icon, or blank for false
  "created_datetime",
  "updated_datetime"
];

/* // example of a exercise object retrieved from the server via an api request:
		{
			"id_guid": "abb27b9a-3418-4c21-8044-6e2849ec5333",
      "user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
			"video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a0f30563f11f637d96de3c5499b3c0e8ab841c75/92.mp4",
			"thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
			"name_string": "Pike Toe Touch",
			"title_string": "Pike Toe Touch",
      "subtitle_string": null,
      "tag_list_string": "Pike, Toe, Touch",
      "movement_id_guid": "785e7498-fe87-4812-8b0e-e75376aeba93",
      "duration_seconds_integer": 90,
			"repetition_integer": 3,
			"note_string": "This is a note",
			"public_boolean": true,
      "created_datetime": "2023-06-21T03:47:14.474Z",
			"updated_datetime": "2023-06-21T03:47:14.474Z",
		},
*/

const defaultSort = {
  field: "name_string",
  order: "asc"
}

const strings = {
  noItemsMsg: "You have no Exercises yet - create some! (or talk to an Admin who can)",
  noFilteredItemsMsg: "",
  itemClickEnabledBooleanAccessor: "",
  itemClickDisabledMessage: ""
}

export default {
  type: "exercise",
  attributes,
  listColumns,
  displayFields,
  defaultSort,
  strings
}