// Signup.js

import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import packageJson from '../../package.json';

import '../App.css'

import LoadingDivLayer  from './styled/LoadingDivLayer';
import { logoSrc, northsideLogoSrc } from '../constants';
import { submitAthleteEmail } from '../services';

import {
  Title,
  EZSpacer,
  ContentWrapper,
  PrettyInput,
  PrettyInputLabel
} from './styled/title';


function Signup(props) {
  const [loaded, setLoaded] = useState(true); // TODO: start this as false and then set to true when ready to display...?
  const [submitted, setSubmitted] = useState(false);
  const [athleteEmail, setAthleteEmail] = useState('');
  
  const isMobile = props.isMobile;
  const mounted = useRef(true);

  let errorMsg = "";
  let fullErrorMsg = "";
  let nextSteps = "\nPlease contact the coach directly to get a new invitation";
  let queryParams = "";
  let searchString = window.location.search;
  let user_id_guid = "";


  console.log('~~~~~ searchString: ' + searchString)

  if (searchString.length < 2 || !searchString.includes('user_id_guid=')) {
    errorMsg = "Unfortunately the link was malformed and was missing your coach's information.";
    console.log('~~~~~ 1');
  } else {
    console.log('~~~~~ 2');
    queryParams = new URLSearchParams(window.location.search);
    user_id_guid = queryParams.get("user_id_guid");

    console.log('~~~~~ 3');
  }

  console.log('~~~~~ errorMsg: ' + errorMsg);

  if (errorMsg.length > 5) {
    fullErrorMsg = errorMsg + nextSteps;
  }

  console.log('~~~~~ fullErrorMsg: ' + fullErrorMsg);

  function handleEmailSubmit() {
    console.log('~~~~~ Signup.js, Signup() - user_id_guid is + *' + user_id_guid + '*, and athleteEmail is *' + athleteEmail + '*');

    submitAthleteEmail(user_id_guid, athleteEmail);
    setSubmitted(true);
  }

  // function handleEmailFocus(event) {
  //   console.log('~~~~~ email focused');

  // }

  // function handleEmailBlur(event) {
  //   setAthleteEmail(event.target.value);
  //   console.log('~~~~~ email lost focus');

  // }

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper  loaded={loaded}>
        <EZSpacer height={"10vh"} />
        <div style={{ align: "center" }}>
          {/* <Title className="d-flex justify-content-center" style={{ opacity: "0.0", marginBottom: "5vh" }}>Signup Page Content</Title> */}
          <div className="d-flex justify-content-center" style={{ marginBottom: isMobile ? "7vh" : "3vh" }}>
            {/* <img src={logoSrc} alt="Physmodo logo" style={{ width: "24vw" }}/> */}
            <img src={northsideLogoSrc} alt="Northside logo" style={{ width: isMobile ? "70vw" : "34vw" }}/>
          </div>

          {errorMsg.length < 5 ? (
            <div style={{ width: isMobile ? "90vw" : "80vw", marginLeft: "auto", marginRight: "auto", marginTop: "4vh", textAlign: "center" }}>
              { submitted === false ? (
                <>
                  <Modal.Body style={{ height: "100px", paddingTop: "20px", width: "100%" }}>
                    <PrettyInputLabel style={{ width: isMobile ? "100%" : "500px", marginBottom: "0px", paddingLeft: "0px", marginLeft: "auto", marginRight: "auto" }}>
                      <PrettyInput 
                        id="email_string_input" 
                        name="email" 
                        type="text" 
                        placeholder="Enter your Email" 
                        style={{ width: "90%", height: isMobile ? "90px" : "60px", fontSize: isMobile ? "32px" : "16px", marginLeft: "5%" }} 
                        onBlur={e => setAthleteEmail(e.target.value)}
                        // onBlur={handleEmailBlur}
                        // onFocus={handleEmailFocus} 
                      />
                    </PrettyInputLabel>
                  </Modal.Body>
                  <Modal.Footer style={{ width: "100%", borderTop: "none", marginTop: isMobile ? "2vh" : "auto" }}>
                    <Button variant="primary" style={{ width: isMobile ? "400px" : "200px", height: isMobile ? "80px" : "50px", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px", fontSize: isMobile ? "32px" : "16px" }} onClick={handleEmailSubmit}>Submit Email</Button>
                  </Modal.Footer>
                  <p style={{ fontSize: isMobile ? "26px" : "13px", opacity: 0.7, marginTop: "4vh", padding: "60px" }} className="d-flex justify-content-center">
                    When you submit your email you will be sent an Invitation Email with a link to verify your email address.<br />
                    Your use of that link will effectively validate that the email address provided here is valid for you.
                  </p>
                </>
              ) : (
                <Title style={{ fontSize: isMobile ? "26px" : "13px", padding: "40px" }}>Thanks! Check your email for the Invite, and follow the link to the intake form!</Title>
              )}

            </div>
          ) : (
            <div style={{ width: isMobile ? "95vw" : "80vw", marginLeft: "auto", marginRight: "auto", marginTop: "7vh" }}>
              <p style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.8, marginBottom: "4vh", padding: "40px" }} className="d-flex justify-content-center">
                {fullErrorMsg}
              </p>
            </div>
          )}

        </div>
        { isMobile &&
          <p style={{ textAlign: "center", width: "100vw", height: "7vw", fontSize: "1.6vw", padding: "3.0vw", opacity: "0.3" }}>
            Version: {packageJson.version}
          </p>
        } 
      </ContentWrapper>
    </div>
  );
}

export default Signup;