async function loginUser(credentials) {
  try {
    console.log("~~~~~ loginUser() ~~~~~");
    // const response = await fetch('https://data.physmodo.net/api/v202203/user/authentication/jwt', {
    const response = await fetch('https://data.physmodo.net/api/v202203/app/dashboard/user/authentication', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })

    console.log("~~~~~ loginUser() - after");

    const bodyMsg = response && response.body ? "response body exists" : "no body";
    const statusMsg = response && response.status === 200 ? "response status 200" : "bad response status - " + response.status;
    
    console.log("~~~~~ loginUser() - response.body: " + bodyMsg);
    console.log("~~~~~ loginUser() - response.status: " + statusMsg);

    // localStorage.setItem('loggedInUser', 'fdbc3b28-1980-434f-80ff-c5d6d8377eec');

    let authFailObject = {
      token: "authFail",
      user: {name: "authFail", email: "authFail", id_guid: "ffffffff-1111-4444-8888-cccccccccccc"}
    }
    
    if (response && response.headers.get('authorization')) {
      return {
        token: response.headers.get('authorization'),
        user: await response.json()
      }
    } else {
      return authFailObject
    }
    
 
  }
  catch (e) {
    console.log(e)
  }
}

async function forgotPassword(email) {
  try {
    const response = await fetch('https://data.physmodo.net/api/v202203/user/authentication/forgot', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify(credentials)
      body: JSON.stringify(email)
    })
    return response.headers.get('authorization');
  }
  catch (e) {
    console.log(e)
  }
}

export {
  loginUser,
  forgotPassword
}