import React from 'react';
import packageJson from '../../../package.json';

import styled from 'styled-components'
import NavButton from './NavButton';
import { 
  colors,
  logoSrc,
  northsideLogoSrc,
  overviewSrc,
  athletesSrc,
  groupsSrc,
  programsSrc,
  exercisesSrc,
  movementsSrc
 } from '../../constants';
 import { storageAvailable } from '../../helpers';

const isCoach = localStorage.getItem('coach') === 'true' ? true : false;
const isAdmin = localStorage.getItem('administrator') === 'true' ? true : false;
const isAthlete = localStorage.getItem('athlete') === 'true' ? true : false;
const isEnterprise = localStorage.getItem('enterprise') === 'true' ? true : false;

const SideBarDiv = styled.div`
  padding: 10px;
  padding-top: 4vh;
  width: 17.7vw;
  min-height: 100vh;
  border-right: 1px solid #e9e9e9; /* TODO: decide if we should use colors.lightGreyDividers ? */
`;

const SideBarInnerDiv = styled.div`
  text-align: center;
`;

const SideBarFooterDiv = styled.div`
  height: 7vw;
  width: 15vw;
  position: fixed;
  bottom: 1vw;
  border-top: 1px solid #e9e9e9;
  margin: 0;
  margin-left: 0.8vw;
`;

// { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
function SideBar(props) { // props: logout Function, deviceInfo Object
  const localStorageExists = storageAvailable("localStorage");
  
  function handleLogoClick() {
    if (localStorageExists) {
      if (localStorage.getItem('theme') !== "alternate") {
        localStorage.setItem('theme', '');
        window.location.reload();
      } else {
        localStorage.setItem('theme', 'alternate');
        window.location.reload();
      }
    }
    else
    {
      console.log("~~~~~ handkeLogoClick() - localStorage does not Exist :-("); // TODO: possibly annunciate this? "get a modern browser"?
    }
  }
  
  return (
    <SideBarDiv>
      <SideBarInnerDiv>
        <img src={northsideLogoSrc} alt="logo" style={{width: "15vw" }} onClick={handleLogoClick} />
        <ul className="navbar-nav mr-auto" style={{ width: "15vw", marginLeft: "auto", marginRight: "auto", marginTop: "6vh", textAlign: "left" }}>
          { (isEnterprise && !isCoach) && 
            <li><NavButton linkTo={'/'} icon={overviewSrc} title={'Overview'} /></li>
          }
          { (isEnterprise || isCoach) &&
            <li><NavButton linkTo={'/members'} icon={athletesSrc} title={isCoach?'Athletes':'Members'} /></li>
          }
          { isCoach && 
          <>
            <li><NavButton linkTo={'/groups'} icon={groupsSrc} title={'Groups'} /></li>
            <li><NavButton linkTo={'/programs'} icon={programsSrc} title={'Workouts'} /></li>
            <li><NavButton linkTo={'/exercises'} icon={exercisesSrc} title={'Exercises'} /></li>
          </>
          }
          { isAdmin &&
            <li><NavButton linkTo={'/movements'} icon={movementsSrc} title={'Movements'} /></li>
          }
          { (isAthlete && !isCoach) &&
          <>
            <li><NavButton linkTo={'/workouts'} icon={programsSrc} title={'My Workouts'} /></li>
          </>
          }
        </ul>
      </SideBarInnerDiv>
      <SideBarFooterDiv onClick={props.logout}>
        <NavButton linkTo={'/#'} icon={"Logout.svg"} title={"Logout"} />
        <p style={{ textAlign: "center", fontSize: "0.8vw", paddingTop: "0px", color: colors.lightGreyText }}>
          v{packageJson.version}
        </p> 
      </SideBarFooterDiv>
    </SideBarDiv>
  );
}

export default SideBar;