// Groups.js

import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { deleteGroup } from '../services';
import { iconSrc } from '../constants';

import {
  AreaTitle,
  SplitTabContentWrapper,
  TabSectionLeft,
  TabSectionRight,
  EZSpacer 
} from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import ItemsTable from './styled/ItemsTable';
import ViewEditAssociatedItems from './styled/ViewEditAssociatedItems';
import SiteButton from './styled/SiteButton';
import ViewEditItem from './styled/ViewEditItem';

// let ViewEditItem; // just an example of a conditional import...
// if(true) { ViewEditItem = require('./styled/ViewEditItem').default; }

function Groups() {
  const [items, setItems] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [itemName, setItemName] = useState([]);
  const [itemNotes, setItemNotes] = useState([]);
  const [viewEditItem, setViewEditItem] = useState(true);
  const [editingItem, setEditingItem] = useState(false); // this means there might be a submittal, so there should be a button
  const [newItem, setNewItem] = useState(true);         // this means there might be a submittal, so there should be a button

  const [showRemoveItemConfirm, setShowRemoveItemConfirm] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [filter, setFilter] = useState("All Groups"); // don't know what we might filter by later...
  const [filterType, setFilterType] = useState("Program"); // don't know what we might filter by later...
  const [filterId, setFilterId] = useState(); // the default empty filterId will result in no filtering
  // const [itemType, setItemType] = useState("Group"); // set below by code that parses the route...

  const [searchParams, setSearchParams] = useSearchParams();
  const [canShowAthleteGroups, setCanShowAthleteGroups] = useState(true); // this will only be true when first opening tab

  const mounted = useRef(true);

  const urlPieces = window.location.toString().split("/");
  const lastPiece = urlPieces[urlPieces.length - 1];  // the last piece might have a queryString e.g groups?email=greg@physmodo
  
  const athleteEmail = searchParams.get("athleteEmail");
  const athleteName = searchParams.get("athleteName");
  const athleteId = searchParams.get("athleteId");
  const athleteGroups = (searchParams.get("athleteEmail") != undefined) ? true : false;

  // console.log('~~~~~ Groups.js - searchParams.get("athleteEmail"): ' + searchParams.get("athleteEmail"));

  const tabReference = lastPiece.split('?')[0]; // should be singular...
  const itemType = tabReference.charAt(0).toUpperCase() + tabReference.slice(1).substring(0, tabReference.length - 2);
  
  const noLongerAbleText = "to assign Athletes or Workouts to it"; // TODO: have this assigned by itemType, somewhere...

  console.log('~~~~~ Groups.js - itemType: ' + itemType);
  console.log('~~~~~ Groups.js - tabReference: ' + tabReference);

  const itemInfo = require('./../models/' + itemType.toLowerCase()).default;

  console.log('~~~~~ Groups.js, Groups() - itemInfo.type = *' + itemInfo.type + '*');

  function setItemAttributes(itemid) {  // TODO: is there any point in making this type-agnostic? (ie parse from displayFields?)
    const matchingObjsArray = items.filter((item) => {
      return item.id_guid === itemid;
    });

    // replace this next code section with something that might key off off data in the models/[itemname].js file
    // ideally, if we are using separate useState variables for each attribute, we automaticaly declare appropriate
    // names and values for those State variables depending on what is conditionally imported in the models/[itemname].js file
    // -- alternately, could we set an Object as the value of a state var itemObj, and then use attribute names from the model
    // in the form itemObj[attributeName] to iterate though them as required? or itemObj[keys]...?

    if (itemid.length >5) { // there was a valid guid provided, not the word "new"
      // console.log('~~~~~ Groups.js - ID for Group is: ' + matchingObjsArray[0].id_guid);
      // console.log('~~~~~ Groups.js - Name for Group is: ' + matchingObjsArray[0].name_string);
      // console.log('~~~~~ Groups.js - Notes for Group are: ' + matchingObjsArray[0].note_string);
      console.log('~~~~~ Groups.js - setItemAttributes() for Item ' + matchingObjsArray[0].name_string);
      setItemId(matchingObjsArray[0].id_guid);
      setItemName(matchingObjsArray[0].name_string);
      setItemNotes(matchingObjsArray[0].note_string);
    } else {
      setItemId("");
      setItemName("");
      setItemNotes("");
    }
  }

  function itemFromId(id_guid) {
    console.log('~~~~~ Groups.js - itemFromId() for id_guid ' + id_guid);

    const matchingObjsArray = items.filter((item) => {
      return item.id_guid === id_guid;
    });

    return matchingObjsArray[0];
  }

  function handleAddItemClick(event) {
    console.log('~~~~~ Groups.js - handleAddItemClick()');
    setItemAttributes("new"); // if no groupid is provided here, fields are set to blank
    setCanShowAthleteGroups(false);
    setViewEditItem(true);
    setNewItem(true);
    // code to strip member email from window.location - but leave from?
    if (athleteGroups) {
      if (searchParams.has('athleteEmail')) {
        searchParams.delete('athleteEmail');
        setSearchParams(searchParams);
      }
    }
  }
  
  function handleRemoveClick(event, itemid) {
    event.stopPropagation();
    setItemAttributes(itemid); // need to set these here and use them in the Modals...

    console.log('~~~~~ Groups.js - handleRemoveClick() for Item ' + itemName);
    
    setShowRemoveItemConfirm(true); 
  }
  const handleCloseRemoveItemConfirm = () => setShowRemoveItemConfirm(false);

  function handleRowClick(event, itemid) {
    console.log('~~~~~ Groups.js - handleRowClick() - open Item View/Edit panel in right tab section for itemid ' + itemid);

    setItemAttributes(itemid);
    setCanShowAthleteGroups(false); // only required here on Groups tab so far, because of setting groups for Athlete
    setViewEditItem(true);
    setNewItem(false);          // no longer in create item mode
    setEditingItem(false);      // not yet actually editing, since no data has been changed...
    // code to strip member email from window.location - but leave from? 
    if (athleteGroups) {
      if (searchParams.has('athleteEmail')) {
        searchParams.delete('athleteEmail');
        setSearchParams(searchParams);
      }
    }
  }

  function sortItemsByName(itemsArray) {
    itemsArray.sort(function(a,b){
      return b.name_string - a.name_string;
    });
  
    return itemsArray;
  }

  function removeItem(event, itemId) {
    console.log('~~~~~ Groups.js - removeItem() - remove Item ' + itemName);

    deleteGroup(itemId) // should return respStatus 204 = delete worked, 200 = edit worked, 422 = bad request
      .then(respStatus => {
        let alertMsg = '';

        if (respStatus === 204) {
          // alertMsg = itemType + ' ' + itemName + ', with id_guid ' + itemId + ' has been deleted and removed from your list';
          alertMsg = itemType + ' ' + itemName + ' has been deleted and removed from your list';
        } else {
          alertMsg = 'There was a problem deleting ' + itemType + ' ' + itemName + ', with id_guid ' + itemId + ', Response Status Code ' + respStatus;
        }
        
        setTimeout(function() {
          window.location.reload();
          // alert(alertMsg);
        }, 500);
      })
  }

  function handleRemoveItemConfirmClick(event) {
    removeItem();
    handleCloseRemoveItemConfirm();
  }

  function handleAddAssociationClick(event) {
    alert('Not yet implemented :-(');
  }

  function handleItemClick(event) {
    console.log('~~~~~ Groups.js, Groups() - handleItemClick()');
    alert('~~~~~ Groups.js, Groups() - handleItemClick()');
  }

  function handleEditClick(event) {
    console.log('~~~~~ Groups.js, Groups() - handleEditClick()');
    alert('~~~~~ Groups.js, Groups() - handleEditClick()');
  }

  function updateItems(itemsArray) {
    console.log('~~~~~ Groups.js, Groups() - updateItems(), ' + itemsArray.length + ' items were passed to parent State');
    setItems(itemsArray);
    setLoaded(true); // TODO: chicken & egg?  if !loaded, ItemsTable will not mount, so updateItems() will not be run...
                      // - if updateItems() does not run, loaded will never be set to true, and ItemsTable will never mount :-(
    // alert('~~~~~ Groups.js, Groups() - updateItems(), ' + itemsArray.length + ' items were passed to parent State');
  }

  const columnClick = {
    removeLink: removeItem
  }

  const handleEvents = { // TODO: imagine how this will work internally when different tables have different events to handle
    itemClick: handleRowClick, // rename local fn() to HandleItemClick? maybe include some sort of mapping in models/[type] ?
    editClick: handleEditClick,
    itemsUpdate: updateItems,
    columnClick: columnClick
  }

  console.log('~~~~~ Groups.js, Groups() - just before render(), itemInfo.type is *' + itemInfo.type + '*');
  console.log('~~~~~ Groups.js, Groups() - just before render(), itemInfo.displayFields is *' + itemInfo.displayFields + '*');
  
  return ( // need to make LoadingDivLayer lay over the top of <SplitTabContentWrapper>, NOT be rendered *instead* of it...
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <SplitTabContentWrapper className="wrapper" loaded={loaded}>
        <TabSectionLeft>
          <AreaTitle>Your {itemType}s</AreaTitle>
          <ItemsTable 
            itemType={itemType}
            filterType={filterType}
            filterId={filterId}
            handleEvents={handleEvents}
            modelInfo={itemInfo}
          /> 
          { !newItem &&
            <>
              <EZSpacer height="20px" />
              <SiteButton 
                icon={iconSrc[itemType.toLowerCase() + 's_active']}
                title={'Add Another ' + itemType} 
                onClick={handleAddItemClick}    // find a way to key off of type...
              />
            </>
          }     
        </TabSectionLeft>

        <TabSectionRight>
          <ViewEditItem 
            visible={!athleteGroups || !canShowAthleteGroups} // what should this be? - here in groups tabSectionRight it should be the opposite of
            create={newItem} // default value is true
            edit={editingItem} // default value is false
            item={itemFromId(itemId)}          // this assumes itemFromId is synchronous
            itemId={itemId} 
            modelInfo={itemInfo}
            fields={itemInfo.displayFields} // get inside, from modelInfo.displayFields
          />
           
          <div style={{ display: (athleteGroups && canShowAthleteGroups) ? "flex" : "none", flexDirection: "column", alignItems: "center" }}>
            <AreaTitle>{itemType}s for {athleteName}</AreaTitle>
            {/* <AreaSubtitle></AreaSubtitle> */}
            <div style={{ display: "inline-block", fontSize: "smaller", color: "#BBBBBB", marginTop: "-20px" }} >{athleteEmail}</div>
            <ViewEditAssociatedItems
              itemType="group"          // this is the itemType that the list will show
              assocType="athlete"       // this is the itemType the association record will be filtered on to make the listArray
              assocId={athleteId}       // this is the item.id_guid that will be used to filter the association records
              reload={true}     
            />
          </div>

        </TabSectionRight>
        
        <Modal
          show={showRemoveItemConfirm}
          onHide={handleCloseRemoveItemConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Remove {itemType}  <br /><b>{itemName}</b>?</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
            This will remove the {itemType} <b>{itemName}</b> from your list, and you will no longer be able {noLongerAbleText}.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleRemoveItemConfirmClick}>Remove {itemType}</Button>
          </Modal.Footer>
        </Modal>

      </SplitTabContentWrapper>
    </div>
  );
}

export default Groups;