// movements.js

const token = localStorage.getItem('token');
const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

export function getMovements() {
  return fetch(fizzy_data_url + '/api/v202203/app/movement/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getAthleteMovements(athleteId) {
  return fetch(fizzy_data_url + '/api/v202203/app/user/' + athleteId + '/movement/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getMovement(id_guid) {
  return fetch(fizzy_data_url + '/api/v202203/app/movement/' + id_guid, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function createMovement(movementObj) {
  console.log('~~~~~ services/movements.js, createMovement() - title_string is *' + movementObj.title_string + '*');
  // console.log('~~~~~ services/movements.js, createMovement() - note_string is *' + movementObj.note_string + '*');
  // console.log('~~~~~ services/movements.js, createMovement() - id_guid is *' + movementObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/movement/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: movementObj.title_string, 
        note_string: movementObj.note_string ? movementObj.note_string : ""
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/movements, createMovement() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/movements.js, createMovement() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function updateMovement(movementObj) {
  console.log('~~~~~ services/movements.js, updateMovement() - name_string is *' + movementObj.name_string + '*');
  console.log('~~~~~ services/movements.js, updateMovement() - note_string is *' + movementObj.note_string + '*');
  console.log('~~~~~ services/movements.js, updateMovement() - id_guid is *' + movementObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/movement/' + movementObj.id_guid, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: movementObj.name_string, 
        note_string: movementObj.note_string
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/members.js, updateMovement() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/members.js, updateMovement() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function deleteMovement(id_guid) {
  console.log('~~~~~ services/movements.js, deleteMovement() - id_guid is *' + id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/movement/' + id_guid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    // .then(data => data.json())
    .then(response => { if (response.status === 204) {
      console.log('~~~~~ services/movements.js, deleteMovement() - response status code was 200 or 201 or 204!');
      // return response.json(); 
      return response.status; 
    } else {
      console.log('~~~~~ services/movements.js, deleteMovement() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}
