// models/program.js

import { iconSrc, colors } from '../constants';

const displayFields = [
  { // name
    type: "text",                           // could be "text", "textarea", "association", "image"?, "video"?
    displayName: "Name",                   // any string up to a maxLength of 20 characters
    placeholder: "Enter the name or title of the Workout",                        // a string that will show in the text input until something is entered there
    accessor: "name_string",                // attribute name when item delivered from API
    default: "",                            // value of this attribute for a brand new 'blank' item
    maxChars: "20",                         // an integer as a string, if we are going to try to limit this...
    minChars: "2",                          // an integer as a string, if we are going to try to limit this, or use as empty check
    pickListInfo: "",                       // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: true                          // should this field be submitted to the api for a create or update access?
  },
  { // interval
    type: "text",
    displayName: "Interval, in Decimal Days",
    placeholder: "0.5 = twice per day, 2.0 = every two days", 
    accessor: "interval_day_decimal",
    default: "",
    maxChars: "20",
    minChars: "2",
    pickListInfo: "",
    required: true
  },
  { // note
    type: "textarea",
    displayName: "Notes",
    placeholder: "Visible to Athletes, and can explain specifics regarding this Workout",
    accessor: "note_string",
    default: "",
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: false 
  },
  // { // video
  //   type: "video",
  //   displayName: "Video (URL) [mouseover video to play, once URL entered]",
  //   placeholder: "Enter something like https://ourdomain.com/videos/move_1.mp4",
  //   accessor: "video_url_string",
  //   default: iconSrc.programs,             // TODO: put an asset in src/public to show when no url provided
  //   maxChars: "2000",
  //   minChars: "",
  //   pickListInfo: "",
  //   required: true 
  // },
  // { // thumbnail
  //   type: "image",
  //   displayName: "Thumbnail (URL)",
  //   placeholder: "Enter something like https://ordomain.com/thumbnails/move_1.png",
  //   accessor: "thumbnail_url_string",
  //   default: iconSrc.programs_active,      // TODO: put an asset in src/public to show when no url provided
  //   maxChars: "2000",
  //   minChars: "",
  //   pickListInfo: "",
  //   required: true 
  // },
  { // movement_id_guid
    type: "exercise_array", // this will be a special type of 'association'
    displayName: "Exercises in this Workout",
    placeholder: "Choose a Workout Exercise",  // words that might explain or prompt for picklist?
    accessor: "exercise_id_guid",
    default: "",
    maxChars: "64",
    minChars: "2",
    pickListInfo: "exercise", 
    required: true
  },
  { // id
    type: "hidden",
    displayName: "ID",
    accessor: "id_guid",
    default: "",                            // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-id_guid")
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  },
  { // user_id
    type: "hidden",
    displayName: "User ID",
    accessor: "user_id_guid",               // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-user_id_guid")
    default: "",                        
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  }
];

const listColumns = [ // the columns list for the main table for this itemType
  { label: "Name", accessor: "name_string", align: "left", type: "text", color: colors.darkGreyText, sortable: true, minWidth: "150px" },
  { label: "Thumbnail", accessor: "movement_thumbnail_url_string_array", align: "left", type: "tnail-array", color: colors.darkGreyText, sortable: false, minWidth: "100px", maxWidth: "100px" },
  { label: "Athletes", accessor: "user_count_integer", align: "center", color: colors.lightGreyText, sortable: true },
  { label: "Groups", accessor: "group_count_integer", align: "center", color: colors.lightGreyText, sortable: true },  
  { label: "Remove", accessor: "removeLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Delete this Workout, remove it from your lists", iconSrc: iconSrc.remove_active }
];

const attributes = [ // these are the attributes for a program
"id_guid",
"created_datetime",
"updated_datetime",
"user_id_guid",
"name_string",
"interval_day_decimal",
"note_string",
"public_boolean",
"exercise_array",
"movement_thumbnail_url_list_string",
"thumbnail_url_string_array"
];

/* // example of a program object retrieved from the server via an api request: - NOTE there is no exercise_array attribute
		{
			"id_guid": "4838140d-32a2-4b9e-8b2b-2467a19b0f43",
			"created_datetime": "2023-06-21T05:02:38.559Z",
			"updated_datetime": "2023-06-21T05:02:48.019Z",
			"user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
			"name_string": "Best Program Ever",
			"interval_day_decimal": "7.00",
			"note_string": "This is the best program ever!",
			"public_boolean": null,
			"movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a77f7f41befbe1f57af1df32e17e990a4ead6b8/Quadruped%20Rock.png",
			"movement_thumbnail_url_string_array": [
				"https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
				"https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png",
				"https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png",
				"https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png",
				"https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0a77f7f41befbe1f57af1df32e17e990a4ead6b8/Quadruped%20Rock.png"
			]
		},
*/

const defaultSort = {
  field: "name_string",
  order: "asc"
}

const strings = {
  noItemsMsg: "You have no Workouts yet - create some!",
  noFilteredItemsMsg: "",
  itemClickEnabledBooleanAccessor: "",
  itemClickDisabledMessage: ""
}

export default {
  type: "program", // add a displayName, or title - which for this model would be 'workout' ?
  attributes,
  listColumns,
  displayFields,
  defaultSort,
  strings
}