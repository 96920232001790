// TestList.js

import React, { useEffect, useRef, useState, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import {
  DndContext,
  closestCenter
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
// import { useSearchParams } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

// import { getAthletePrograms, getAthleteMovements, getWorkoutExercises } from '../services'; // sort/filter Workouts by day/date on client side
// import { iconSrc } from '../constants';

import {
  AreaTitle,
  ListContentRow,
  ListContentBox
  // SplitTabContentWrapper,
  // TabSectionLeft,
  // TabSectionRight,
  // TabContentWithHeaderWrapper,
  // TabContentHeader,
  // EZSpacer 
} from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';  
import { SortableItem } from './styled/SortableItem';

// install antd react-dnd react-dnd-html5-backend immutability-helper




function TestList() {
  // the guts of this needs to be the basis for the OrderedItemList component
  // - the list will be populated from an array, which should be empty for a CREATE NEW Workout operation,
  // - for an UPDATE Workout operation, the array should be a collection of 'exercise' objects (note_string & exercise_id_guid) 
  // > inside the OrderedItemList component an array needs to exist with an additional, integer, attribute indexInt (1-based)
  // - the objects in the array passed in to the OrderedItemList component (if any) will NOT contain that attribute, and
  // - the objects in the array submitted to the API with a POST or PUT request MUST NOT contain that attribute
  // >> Ideally the actual array of exercise objects will be passed in to the OrderedItemList component as a prop, and
  // - every onDragEnd or addToList operation inside the OrderedItemList component will trigger an external function in the 
  //    OrderedItemList parent component that will update the actual array of exercise objects
  // - in that way the world OUTSIDE the OrderedItemList component will not have any awareness of the indexInt attribute

  const [width, setWidth] = useState(window.innerWidth); // useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const fakeItemsArray = [
    {
      exercise_id_guid: "fake-exercise-xyz-id_guid",
      indexInt: 1,
      note_string: "Notes for exercise XYZ"
    },
    {
      exercise_id_guid: "fake-exercise-abc-id_guid",
      indexInt: 2,
      note_string: "Notes for exercise ABC"
    },
    {
      exercise_id_guid: "fake-exercise-pqr-id_guid",
      indexInt: 3,
      note_string: "Notes for exercise PQR"
    }
  ]

  const [items, setItems] = useState(fakeItemsArray);
  // const exerciseArray = (() => items.map(({indexInt, ...keepAttrs}) => keepAttrs), [items]); //??
  const exerciseArray = items.map(({indexInt, ...keepAttrs}) => keepAttrs);
  const itemIds = useMemo(() => items.map((item) => item.indexInt), [items]);

  console.log('~~~~~ TestList() - exerciseArray is *' + exerciseArray[0].exercise_id_guid + ', ' + exerciseArray[1].exercise_id_guid + ', ' + exerciseArray[2].exercise_id_guid + '*');

  return ( 
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Container className="p-3" style={{"width": "80%"}} align="center">
          <AreaTitle>TestList Area (dnd-kit)</AreaTitle>
          <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
          >
            {items.map(item => <SortableItem key={item.exercise_id_guid.toString()} id={item.indexInt} item={item} /> )}
          </SortableContext>
        </Container>
      </DndContext>
  );

  function handleDragEnd(event) {
    const eCTgt = event.activatorEvent.srcElement; // this element is a div child of <Card />
    const cardTgtId = event.activatorEvent.srcElement.parentElement.id; // this returns the id of the <Card /> element
    
    const {active, over} = event;
    if (active.id === over.id) {
      return;
    }
    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.indexInt === active.id);
      const newIndex = items.findIndex((item) => item.indexInt === over.id);
      return arrayMove( items, oldIndex, newIndex);
    });
  }
}

export default TestList;