// src/constants/themes_and_colors.js

import { storageAvailable } from '../helpers';

const localStorageExists = storageAvailable("localStorage");
/*
const StretchLab_red = "#ee4b54";
const StretchLab_blue = "#003348";
const StretchLab_green = "#00acba";

const StretchLab_logo_src = "sl-logo-full-color.png";
const StretchLab_loading_src = "SL_loading.svg";
*/
const Physmodo_purple = "#516efe";
const Physmodo_blue = "#3c9bff";

let Physmodo_accent_color = Physmodo_purple;
let Physmodo_logo_src = "Physmodo_logo.svg";
let northsideLogoSrc = "northside_logo_gold.jpg";
let Physmodo_loading_src = "Physmodo_loading_1.svg";
let trashcanSrc = "trashCanGrey_24.png"; // "trashCanGrey_48.png" - these are #909090
let trashcanSrc_active = "trashCanBlack_24.png"; // "trashCanDarkGrey_48.png" - greys are #555555, blacks are #000000
let overviewSrc = "tab_svg_icons/Overview.svg"; // - these are #696969
let overviewSrc_active = "tab_svg_icons/Overview_active.svg"; // - these are #FFFFFF
let athletesSrc = "tab_svg_icons/Members.svg"; // - these are #696969
let athletesSrc_active = "tab_svg_icons/Members_active.svg"; // - these are #FFFFFF
let groupsSrc = "tab_svg_icons/Groups.svg"; // - these are #696969
let groupsSrc_active = "tab_svg_icons/Groups_active.svg"; // - these are #FFFFFF
let programsSrc = "tab_svg_icons/Programs.svg"; // - these are #696969
let programsSrc_active = "tab_svg_icons/Programs_active.svg"; // - these are #FFFFFF
let exercisesSrc = "tab_svg_icons/Exercises.svg"; // - these are #696969
let exercisesSrc_active = "tab_svg_icons/Exercises_active.svg"; // - these are #FFFFFF
let movementsSrc = "tab_svg_icons/Movements.svg"; // - these are #696969
let movementsSrc_active = "tab_svg_icons/Movements_active.svg"; // - these are #FFFFFF
let addUserSrc = "addUser_64.png"; // this is white #FFFFFF to go in Invite Athlete button
let checkedSrc = "checkmark_icon.svg"; // - these are #696969
let checkedSrc_active = "checkmark_icon_active.svg"; // - these are #FFFFFF

const iconSrc = {
  remove: trashcanSrc, // "trashCanGrey_48.png" - these are #909090
  remove_active: trashcanSrc_active,
  athletes: athletesSrc,
  athletes_active: athletesSrc_active,
  groups: groupsSrc,
  groups_active: groupsSrc_active,
  programs: programsSrc,
  programs_active: programsSrc_active,
  exercises: exercisesSrc,
  exercises_active: exercisesSrc_active,
  movements: movementsSrc,
  movements_active: movementsSrc_active,
  addUser_active: addUserSrc,
  checked: checkedSrc,
  checked_active: checkedSrc_active
}

if (localStorageExists && localStorage.getItem('theme') === "alternate") {
  Physmodo_accent_color = Physmodo_blue;
  Physmodo_logo_src = "Physmodo_logo_2.png";
  Physmodo_loading_src = "Physmodo_loading_2.svg";
}

const accentColor = Physmodo_accent_color;
const logoSrc = Physmodo_logo_src;
const loadingSrc = Physmodo_loading_src;

const lightGrey = "#e9e9e9";

const colors = {
  background: "#ffffff",
  lightGreyDividers: lightGrey,
  blackText: "#000000",
  greyText: "#7a7c8d", // TODO: this is not really a grey shade...
  greyTitleText: "#888888",
  lightGreyText: "#b4b4b4",
  darkGreyText: "#555555",
  scoreGreen: "#7cea62",
  scoreYellow: "#f4f436",
  scoreRed: "#f2a2a",
  accent: accentColor
}

export {
  accentColor,
  logoSrc,
  northsideLogoSrc,
  iconSrc,
  loadingSrc,
  trashcanSrc,
  trashcanSrc_active,
  overviewSrc,
  overviewSrc_active,
  athletesSrc,
  athletesSrc_active,
  groupsSrc,
  groupsSrc_active,
  programsSrc,
  programsSrc_active,
  exercisesSrc,
  exercisesSrc_active,
  movementsSrc,
  movementsSrc_active,
  addUserSrc,
  colors
}

