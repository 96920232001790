// Accept.js

import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../../package.json';
import '../App.css'

import { Title, EZSpacer, ContentWrapper } from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import { logoSrc, northsideLogoSrc } from '../constants';
import { acceptCoachLink } from '../services';

// http://localhost:3000/accept?user_authentication_jwt_token_string=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9ndWlkIjoiMDQxMTQ1OTgtYzI3My00MDk1LTk1NDYtMmM1YzVhZjNmOWFlIiwiY3JlYXRlZF9kYXRldGltZSI6IjIwMjMtMDgtMTRUMDU6MTM6MTcuMjcxWiIsInVwZGF0ZWRfZGF0ZXRpbWUiOiIyMDIzLTA4LTE0VDA1OjEzOjE3LjI3MVoiLCJ1c2VyX2lkX2d1aWQiOiJmNDQwNzRkOS04YzlmLTQ5NjAtYTA2My1iZmM2YTQxNTUyNGEiLCJ2YWxpZF9ib29sZWFuIjp0cnVlLCJleHBpcmF0aW9uX2RhdGV0aW1lIjoiMjAyMy0wOS0xM1QwNToxMzoxNy4yNzFaIiwidXNlcl9hdXRob3JpemF0aW9uX3R5cGVfaWRfZ3VpZCI6ImE3OWY0MDhmLWNmNjQtNGNmMy04MWFjLTc0MDEzODg0YTkyYSIsImlhdCI6MTY5MTk4OTk5N30.GxDcCNmzBQXBtfrEgBHiitFsE0zT6l4BTf9RoibOHY4&user_association_id_guid=1755ec72-a433-4543-85e5-657a45434b6e&athlete_user_id_guid=f44074d9-8c9f-4960-a063-bfc6a415524a&user_name_string=John%20Physmodo


function Accept(props) {
  const [loaded, setLoaded ] = useState(false);
  const mounted = useRef(true);
  const isMobile = props.isMobile;

  let errorMsg = "";
  let fullErrorMsg = "";
  let nextSteps = "\nPlease contact the coach directly to get a new invitation";
  let queryParams = "";
  let searchString = window.location.search;
  let coachName = "";
  let coachLastName = "";
  let user_authentication_jwt_token_string = "";
  let user_association_id_guid = "";
  let athlete_user_id_guid = "";
  let inviteToken = "";

  console.log('~~~~~ searchString: ' + searchString)

  if (searchString.length < 2 || !searchString.includes('user_name_string=') || !searchString.includes('%20')) {
    errorMsg = "Unfortunately the link in your invitation was malformed and was missing your coach's information.";
    console.log('~~~~~ 1');
  } else {
    console.log('~~~~~ 2');
    queryParams = new URLSearchParams(window.location.search);
    
    user_authentication_jwt_token_string = queryParams.get("user_authentication_jwt_token_string");
    user_association_id_guid = queryParams.get("user_association_id_guid");
    athlete_user_id_guid = queryParams.get("athlete_user_id_guid");
    coachName = queryParams.get("user_name_string");
    inviteToken = queryParams.get("user_authentication_jwt_token_string");

    console.log('~~~~~ coachName: ' + coachName) // e.g. Andrew Menter
    console.log('~~~~~ coachName.length: ' + coachName.length);
    const missingSpace = !coachName.includes(' ');
    console.log('~~~~~ missingSpace: ' + missingSpace);
    
    if(coachName.length < 3 || missingSpace) {
      console.log('~~~~~ 3');
      errorMsg = "Unfortunately the link in your invitation was malformed and was missing your coach's information.";
    } else {
      console.log('~~~~~ 4');
      coachLastName = coachName.split(' ')[1];
      console.log('~~~~~ coachLastName: ' + coachLastName); // e.g. Menter
    }

    console.log('~~~~~ 5');

    acceptCoachLink(inviteToken, athlete_user_id_guid, user_association_id_guid) // token, athleteId, assocId
    .then(acceptResp => {
      setLoaded(true);
      let coach_name = (acceptResp.user_name_string && acceptResp.user_name_string.length > 5) ? acceptResp.user_name_string : "Coach";
      console.log('~~~~~ Accept.js, Accept() - Coach name is *' + coach_name + '*');
    });

    console.log('~~~~~ 6');
    
    if(coachName.length < 3 || missingSpace) {
      console.log('~~~~~ 7');
      errorMsg = "Unfortunately the link in your invitation was malformed and was missing your coach's information.";
    } else {
      console.log('~~~~~ 8');
      coachLastName = coachName.split(' ')[1];
      console.log('~~~~~ coachLastName: ' + coachLastName); // e.g. Menter
    }
  }

  console.log('~~~~~ errorMsg: ' + errorMsg);

  if (errorMsg.length > 5) {
    fullErrorMsg = errorMsg + nextSteps;
  }

  console.log('~~~~~ fullErrorMsg: ' + fullErrorMsg);

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper  loaded={loaded}>
        <EZSpacer height={"10vh"} />
        <div style={{ align: "center" }}> {/*  align-items-center */}
          <Title className="d-flex justify-content-center" style={{ opacity: "0.0", marginBottom: "5vh" }}>Accept Page Content</Title>
          <div className="d-flex justify-content-center" style={{ marginBottom: isMobile ? "18vh" : "3vh" }}>
            {/* <img src={logoSrc} alt="Physmodo logo" style={{ width: "24vw" }}/> */}
            <img src={northsideLogoSrc} alt="Northside logo" style={{ width: isMobile ? "80vw" : "34vw" }}/>
          </div>

          {errorMsg.length < 5 ? (
            <div style={{ width:  isMobile ? "95vw" : "80vw", marginLeft: "auto", marginRight: "auto", marginTop: "7vh" }}>
              <p className="d-flex justify-content-center" style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.8, marginBottom: "4vh" }}>
                You have successfully linked your Physmodo assessment results to Coach {coachName}!
              </p>
              <p style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.751 }} className="d-flex justify-content-center">
                Coach {coachLastName} will be able to monitor your progress as you perform the Physmodo Movement Assessment during the year, 

                and provide you with specific guidance and programming to achieve your goals.
              </p>
            </div>
          ) : (
            <div style={{ width: "80vw", marginLeft: "auto", marginRight: "auto", marginTop: "7vh" }}>
              <p style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.8, marginBottom: "4vh" }} className="d-flex justify-content-center">
                {fullErrorMsg}
              </p>
            </div>
          )}

        </div>
        { isMobile &&
          <p style={{ textAlign: "center", width: "100vw", height: "7vw", fontSize: "1.6vw", padding: "3.0vw", opacity: "0.3" }}>
            Version: {packageJson.version}
          </p>
        } 
      </ContentWrapper>
    </div>
  );
}

export default Accept;