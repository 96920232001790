import React from 'react';
import styled from 'styled-components'

import ScoreCircle from './ScoreCircle';
import { colors } from '../../constants';

const isPhysmodo = localStorage.getItem('physmodo') === 'true' ?
  true : false;

/* 6vw if we are showing measurement, otherwise 5vw (or 4.5vw if we never have subtitles) */
const scoreDisplayHeight = isPhysmodo ? "5.5vw" : "5vw";

const TopName = styled.h3`
  font-size: 1.35vw;
  color: black;
  white-space: nowrap;
`;

const BottomName = styled.h4`
  font-size: 1.0vw;
  color: black;
  white-space: nowrap;
`;

const ScoreDisplayDiv = styled.div`
  min-width: 20vw;
  height: ${scoreDisplayHeight};
  margin-left: 1vw;
  padding-right: 2vw;
  padding-bottom: 2vw; /* only needed if we are showing measurement */
  border-bottom: 1px solid #b6b6b6;
`;

const ScoreCircleDiv = styled.div`
  clear: both;
  float: left;
  padding-left: 2.5vw;
  padding-top: 1.0vw;
`;

const MeasurementDiv = styled.div`
  clear: both;
  padding: 0;
  margin-top: 0;
  color: ${colors.lightGreyText};
  font-size: 0.8vw;
  text-align: center;
`;

const ScoreTitlesDiv = styled.div`
  float: left;
  padding-left: 2.5vw;
  padding-top: 0.75vw;
`;


function ScoreDisplay(props) { // this takes a single score and displays it
  const {                      // the parent component will map the totality
    data     // scoreObject    // of the available scores & filter by domain
  } = props;                   // this component will receive a scoreObject

  /* // the format of the score object ( data[index] )will be this:
    {
      "id": "e4bfd335-8c78-45a5-8c56-083bb7e81b11",
      "domain": "stability",
      "title": "Scap Right",
      "subtitle": "Scap Right words",
      "score": "45",
      "measurement": "126"
		}
  */
  
  const score = parseInt(data.score, 10);
  const measurement = parseFloat(data.measurement).toFixed(2);
  const title = data.title;
  const subtitle = data.subtitle;
  const trend = data.trend;


  return (
    <>
      <ScoreDisplayDiv>
        <ScoreCircleDiv>
          <ScoreCircle
            size={window.innerWidth*0.025}
            score={score}
            trend={trend}
            simple={true}
            bigNum={true}
            style={{ margin: 0, marginTop: "1vw" }}
          />
          { isPhysmodo &&
            <MeasurementDiv>
              {measurement}
            </MeasurementDiv>
          }

        </ScoreCircleDiv>
        <ScoreTitlesDiv>
          <TopName>{title}</TopName>
          <BottomName>{subtitle}</BottomName>
        </ScoreTitlesDiv>
      </ScoreDisplayDiv>
    </>
  );
}

export default ScoreDisplay;
