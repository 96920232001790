// LoadingDivLayer.js

import React from 'react';
import { loadingSrc, accentColor } from '../../constants';

import styled from 'styled-components';

const LoadingDiv = styled.div`
  display: block;
  background: #ffffff;
  min-width: 100%;
  min-height: 100%;
  padding-top: 40vh;
  text-align: center; 
`;

const LoadingImage = styled.img`
  width: 5vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vw;
`;

const LoadingText = styled.div`
  width: 10vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.5vw;
  color: ${accentColor};
`;

function LoadingDivLayer() {
  return (
    <LoadingDiv>
      <LoadingImage src={loadingSrc} />
      <LoadingText>Loading...</LoadingText>
    </LoadingDiv>
  );
}

export default LoadingDivLayer;