// groups.js

const token = localStorage.getItem('token');
const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

export function getGroups() {
  return fetch(fizzy_data_url + '/api/v202203/app/group/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function createGroup(groupObj) {
  console.log('~~~~~ services/groups.js, createGroup() - name_string is *' + groupObj.name_string + '*');
  console.log('~~~~~ services/groups.js, createGroup() - note_string is *' + groupObj.note_string + '*');
  console.log('~~~~~ services/groups.js, createGroup() - id_guid is *' + groupObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/group/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: groupObj.name_string, 
        note_string: groupObj.note_string ? groupObj.note_string : ""
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/groups.js, createGroup() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/groups.js, createGroup() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function updateGroup(groupObj) {
  console.log('~~~~~ services/groups.js, updateGroup() - name_string is *' + groupObj.name_string + '*');
  console.log('~~~~~ services/groups.js, updateGroup() - note_string is *' + groupObj.note_string + '*');
  console.log('~~~~~ services/groups.js, updateGroup() - id_guid is *' + groupObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/group/' + groupObj.id_guid, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: groupObj.name_string, 
        note_string: groupObj.note_string
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/groups.js, createGroup() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/groups.js, createGroup() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function deleteGroup(id_guid) {
  console.log('~~~~~ services/groups.js, deleteGroup() - id_guid is *' + id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/group/' + id_guid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    // .then(data => data.json())
    .then(response => { if (response.status === 204) {
      console.log('~~~~~ services/groups.js, deleteGroup() - response status code was 200 or 201 or 204!');
      // return response.json(); 
      return response.status; 
    } else {
      console.log('~~~~~ services/groups.js, createGroup() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export function addAthleteToGroup(user_id, group_id) {
  console.log(`~~~~~ services/groups.js - addAthleteToGroup() - group_id: *` + group_id + `*, user_id: *` + user_id + `*`);

  return fetch(fizzy_data_url + '/api/v202203/app/group/user', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json()) 
}


export function removeAthleteFromGroup(user_id, group_id) {

  console.log(`~~~~~ services/groups.js - removeAthleteFromGroup() - group_id: *` + group_id + `*, user_id: *` + user_id + `*`);

  return fetch(fizzy_data_url + '/api/v202203/app/group/user', {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json()) 
}


export function addProgramToGroup(user_id, group_id) {
  console.log(`~~~~~ services/groups.js - addProgramToGroup() - group_id: *` + group_id + `*, user_id: *` + user_id + `*`);

  return fetch(fizzy_data_url + '/api/v202203/app/group/program', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json()) 
}


export function removeProgramFromGroup(user_id, group_id) {

  console.log(`~~~~~ services/groups.js - removeProgramFromGroup() - group_id: *` + group_id + `*, user_id: *` + user_id + `*`);

  return fetch(fizzy_data_url + '/api/v202203/app/group/program', {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json()) 
}






// api endpoint for GET is [fizzy_data_url]/api/v202203/app/group/

// response data from get all looks like this:

// Status Code: 200 OK

// {
// 	"data": [
    // {
    //   "id_guid": "4162e958-c80c-48d5-a505-a790ebfa8cda",
    //   "email_string": "athlete2@physmodo.com",
    //   "user_association_user_approval_boolean": true,
    //   "user_association_athlete_user_approval_boolean": true,
    //   "user_association_athlete_user_name_string": "Athlete2 Name",
    //   "first_name_string": null,
    //   "last_name_string": null,
    //   "enterprise_assessment_total_count_integer": null,
    //   "enterprise_assessment_last_month_count_integer": null,
    //   "enterprise_assessment_last_datetime": null,
    //   "athlete_assessment_total_count_integer": null,
    //   "athlete_assessment_last_month_count_integer": null,
    //   "athlete_assessment_last_datetime": null,
    //   "assessment_total_count_integer": null,
    //   "assessment_last_month_count_integer": null,
    //   "assessment_last_datetime": null,
    //   "group_list_string": "fakegroup0, fakegroup1, fakegroup2",
    //   "program_list_string": "fakeprogram0, fakeprogram1, fakeprogram2",
    //   "program_count_integer": "100"
    // },
    // {
    //   "id_guid": "7c2af4db-5fa1-4f90-9e39-0653ad741ccf",
    //   "email_string": "athlete4@physmodo.com",
    //   "user_association_user_approval_boolean": true,
    //   "user_association_athlete_user_approval_boolean": true,
    //   "user_association_athlete_user_name_string": "Athlete4 Name",
    //   "first_name_string": null,
    //   "last_name_string": null,
    //   "enterprise_assessment_total_count_integer": null,
    //   "enterprise_assessment_last_month_count_integer": null,
    //   "enterprise_assessment_last_datetime": null,
    //   "athlete_assessment_total_count_integer": null,
    //   "athlete_assessment_last_month_count_integer": null,
    //   "athlete_assessment_last_datetime": null,
    //   "assessment_total_count_integer": null,
    //   "assessment_last_month_count_integer": null,
    //   "assessment_last_datetime": null,
    //   "group_list_string": "fakegroup0, fakegroup1, fakegroup2",
    //   "program_list_string": "fakeprogram0, fakeprogram1, fakeprogram2",
    //   "program_count_integer": "100"
    // },
    // {
    //   "id_guid": "c18d835a-74dd-428e-8641-69bfe46d5ce3",
    //   "email_string": "athlete1@physmodo.com",
    //   "user_association_user_approval_boolean": true,
    //   "user_association_athlete_user_approval_boolean": true,
    //   "user_association_athlete_user_name_string": "Athlete1 Name",
    //   "first_name_string": null,
    //   "last_name_string": null,
    //   "enterprise_assessment_total_count_integer": null,
    //   "enterprise_assessment_last_month_count_integer": null,
    //   "enterprise_assessment_last_datetime": null,
    //   "athlete_assessment_total_count_integer": null,
    //   "athlete_assessment_last_month_count_integer": null,
    //   "athlete_assessment_last_datetime": null,
    //   "assessment_total_count_integer": null,
    //   "assessment_last_month_count_integer": null,
    //   "assessment_last_datetime": null,
    //   "group_list_string": "fakegroup0, fakegroup1, fakegroup2",
    //   "program_list_string": "fakeprogram0, fakeprogram1, fakeprogram2",
    //   "program_count_integer": "100"
    // }
// 	],
// 	"pagination": {
// 		"page_current": 1,
// 		"page_total": 1,
// 		"rows_current": 0,
// 		"rows_total": 3
// 	}
// }



// api endpoint for POST is [fizzy_data_url]/api/v202203/app/group/

// request body for POST looks like this:
// {
// 	"name_string": "Team 3",
// 	"note_string": "Team 3 Notes"
// }

// response data from POST looks like this:

// Status Code: 201 Created

// {
    //   "id_guid": "c18d835a-74dd-428e-8641-69bfe46d5ce3",
    //   "email_string": "athlete1@physmodo.com",
    //   "user_association_user_approval_boolean": true,
    //   "user_association_athlete_user_approval_boolean": true,
    //   "user_association_athlete_user_name_string": "Athlete1 Name",
    //   "first_name_string": null,
    //   "last_name_string": null,
    //   "enterprise_assessment_total_count_integer": null,
    //   "enterprise_assessment_last_month_count_integer": null,
    //   "enterprise_assessment_last_datetime": null,
    //   "athlete_assessment_total_count_integer": null,
    //   "athlete_assessment_last_month_count_integer": null,
    //   "athlete_assessment_last_datetime": null,
    //   "assessment_total_count_integer": null,
    //   "assessment_last_month_count_integer": null,
    //   "assessment_last_datetime": null,
    //   "group_list_string": "fakegroup0, fakegroup1, fakegroup2",
    //   "program_list_string": "fakeprogram0, fakeprogram1, fakeprogram2",
    //   "program_count_integer": "100"
// }



// api endpoint for PUT is [fizzy_data_url]/api/v202203/app/group/[group_id_guid]

// request body for PUT looks like this:
// {
// 	"name_string": "Team 3 Group",
// 	"note_string": "Team 3 Group Notes"
// }

// response data from PUT looks like this:

// Status Code: 201 Created - we would prefer something else?  maybe just a 200, maybe...?

// {
// 	"id_guid": "6da601e1-9b21-453d-9026-7cb4d6a7bbb7",
// 	"created_datetime": "2023-06-16T18:32:08.273Z",
// 	"updated_datetime": "2023-06-16T18:32:08.273Z",
// 	"user_id_guid": "fdbc3b28-1980-434f-80ff-c5d6d8377eec",
// 	"name_string": "Team 3",
// 	"note_string": "Team 3 Notes"
// }



// api endpoint for DELETE is [fizzy_data_url]/api/v202203/group/[group_id_guid]

// request body for DELETE is empty

// response data from DELETE looks like this:

// Status Code: 202 Accepted - we would prefer something else?  maybe 204 No Content, maybe...?

// {
// 	"id_guid": "6da601e1-9b21-453d-9026-7cb4d6a7bbb7"
// }




// // will parse users to generate members array
// function makeMembersFromUsers(respJson) { // { data: [ {user}, {user}, ..., {user} ] }

//   const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  
//   let fakeMember = {
//     id_guid: "unknown_user",
//     email: "fake_user@websmith.us",
//     lastAssessmentDate: randomAssessmentDate,
//     AssessmentsCountThisMonth: 15,
//     AssessmentsCountAllTime: 117
//   }
  
//   let memberStats = { };

//   let allMembersArray = [fakeMember];

//   if (respJson && respJson.data && Array.isArray(respJson.data) && respJson.data.length > 0) {
    
//     allMembersArray.pop(); // there are members, remove the fake user record

//     respJson.data.forEach((user) => {
//       const monthsSinceJoined = getRandomInt(24);

//       let thisMember = {
//         id_guid: "unknown_user",
//         email: "unknown_email",
//         lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()),
//         AssessmentsCountThisMonth: getRandomInt(14),
//         AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14))
//       };

//       for (const [key, value] of Object.entries(user)) {
//         // console.log("~~~~~ makeMembersFromUsers() - key = " + key);
//         if (key === "id_guid" || key === "user_id_guid") {
//           // console.log("~~~~~ makeMembersFromUsers() - id_guid = " + value);
//           thisMember.id_guid = value;
//         } else if (key === "email_string") {
//           // console.log("~~~~~ makeMembersFromUsers() - email_string = " + value);
//           thisMember.email = value;
//         } else if (key === "assessment_last_datetime" || key === "assessment_last_datatime") {
//           // console.log("~~~~~ makeMembersFromUsers() - assessment_last_datetime = " + value);
//           thisMember.lastAssessmentDate = new Date(value).toLocaleString();
//         } else if (key === "assessment_total_count_integer") {
//           // console.log("~~~~~ makeMembersFromUsers() - assessment_total_count_integer = " + value);
//           thisMember.AssessmentsCountAllTime = value;
//         } else if (key === "assessment_month_count_integer") {
//           // console.log("~~~~~ makeMembersFromUsers() - assessment_month_count_integer = " + value);
//           thisMember.AssessmentsCountThisMonth = value;
//         }
//       };

//       // console.log("~~~~~ makeMembersFromUsers() - thisMember.lastAssessmentDate: " + thisMember.lastAssessmentDate);

//       if (!membersWithoutAssessments.includes(thisMember.email)) {
//         allMembersArray.push(thisMember);

//         if (thisMember.email.includes("stretchlab")) {
//           console.log("~~~~~ " + thisMember.email);
//         }
//       }
//     });

//     // console.log("~~~~~ makeMembersFromUsers() - respJson.statistics: " + respJson.statistics);
//     // console.log("~~~~~ makeMembersFromUsers() - respJson.data: " + respJson.data);
    
//     memberStats = {
//       totalUsers: respJson.pagination && respJson.pagination.rows_total ?
//        respJson.pagination.rows_total : 777,
//       activeUsers: respJson.statistics && respJson.statistics.count_active_integer ?
//        respJson.statistics.count_active_integer : 77,
//       newUsers: respJson.statistics && respJson.statistics.count_this_week_integer ?
//        respJson.statistics.count_this_week_integer :7,
//       thisWeekScans: 84,  // TODO: move to KioskStats?
//       lastWeekScans: 81   // TODO: move to KioskStats?
//     }
//   } 
    
//   return {
//     members: allMembersArray,
//     memberStats: memberStats
//   }
// }